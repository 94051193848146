<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{careerCornerIntro.header}}
    </h4>
  </div>
  <div class="container">
    <div class="career-activity career-corner_activity content-box">
      <div class="carrer-frame-block" flex v-center h-center>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[0]?.image" (click)="modalOpenCareerCorner(careerCornerResp[0]?.occId, careerCornerResp[0]?.attributes)">
              <img src="{{careerCornerResp[0]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[0]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
              <!-- <img *ngIf="careerCornerResp[0]?.hasRibbon === false" src="./assets/Svg/badge-fade-out_1.svg" alt="badge-image" /> -->
            </div>
            <img src="{{assetsSVG_URL}}career-corner-frame-1.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[1]?.image">
              <img src="{{careerCornerResp[1]?.image}}" (click)="modalOpenCareerCorner(careerCornerResp[1]?.occId, careerCornerResp[1]?.attributes)">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[1]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img class="frame-small" src="{{assetsSVG_URL}}career-corner-frame-2.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[2]?.image" (click)="modalOpenCareerCorner(careerCornerResp[2]?.occId, careerCornerResp[2]?.attributes)">
              <img src="{{careerCornerResp[2]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[2]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img src="{{assetsSVG_URL}}career-corner-frame-3.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[3]?.image" (click)="modalOpenCareerCorner(careerCornerResp[3]?.occId, careerCornerResp[3]?.attributes)">
              <img src="{{careerCornerResp[3]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[3]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img src="{{assetsSVG_URL}}career-corner-frame-4.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[4]?.image" (click)="modalOpenCareerCorner(careerCornerResp[4]?.occId,careerCornerResp[4]?.attributes)">
              <img src="{{careerCornerResp[4]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[4]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img src="{{assetsSVG_URL}}career-corner-frame-5.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[5]?.image" (click)="modalOpenCareerCorner(careerCornerResp[5]?.occId,careerCornerResp[5]?.attributes)">
              <img src="{{careerCornerResp[5]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[5]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img src="{{assetsSVG_URL}}career-corner-frame-6.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[6]?.image" (click)="modalOpenCareerCorner(careerCornerResp[6]?.occId, careerCornerResp[6]?.attributes)">
              <img src="{{careerCornerResp[6]?.image}}">
            </div>
            <div class="career-badge">
              <img *ngIf="careerCornerResp[6]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div>
            <img class="frame-small" src="{{assetsSVG_URL}}career-corner-frame-7.svg" alt="">
          </div>
        </div>
        <div class="carrer-frame" pointer>
          <div class="frame-card">
            <div class="career-image" *ngIf="careerCornerResp[7]?.image" (click)="modalOpenCareerCorner(careerCornerResp[7]?.occId, careerCornerResp[7]?.attributes)">
              <img src="{{careerCornerResp[7]?.image}}">
            </div> 
            <div class="career-badge">
              <img *ngIf="careerCornerResp[7]?.hasRibbon" src="./assets/Svg/badge.svg" alt="badge-image" />
            </div> 
            <img src="{{assetsSVG_URL}}career-corner-frame-8.svg" alt="">
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>