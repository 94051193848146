import { Component, Inject, OnInit } from '@angular/core';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, skip, takeWhile } from 'rxjs/operators';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { AudioState, OccThumbState, OwnOccListState, PageDataState, ScoutOccViewedCareerState, ScoutOccViewedState, UserProfileState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-scout-results',
  templateUrl: './scout-results.component.html'
})
export class ScoutResultsComponent implements OnInit {
  CreateOwnOccs = [];
  assetsUrl: string;
  pathFinderModalpopupPayload: string;
  occUrl: string;
  compActive = true;
  category = 0;
  listOfOccs = [];
  reset = true;
  favourate = { 'occs': { 'evaluated': [] } };
  thumbFalse = [];
  thumbTrue = [];
  careerItem: any;
  assetsURL: string;
  profileData: any;
  viewedOccs = [];
  popupSelectDataIndex: number;
  images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
  buttonsText: any = [];
  categoryTypes = ['People', 'Ideas', 'Things'];
  scoutResultHeading: any;
  scout: any = [];
  lang: string;
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  theme_name: string;
  pagesData: any;
  scoutPageData: any;
  headerAudioState = 'stoped';
  headerAudionotfound = false;
  subHeaderAudioState = 'stoped';
  subHeaderAudionotfound = false;
  curAudio: string;
  theme_Color_num: number;

  constructor(public eventService: EventdispatchService,
    public activatedRoute: ActivatedRoute,
    private scoutOccViewedListStore: Store<ScoutOccViewedState>,
    public dialogService: DialogopenService,
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private store: Store<PageDataState>,
    private userProfileStore: Store<UserProfileState>,
    private occThumbStateStore: Store<OccThumbState>,
    private scoutOccViewedStore: Store<ScoutOccViewedCareerState>,
    private ownOccstore: Store<OwnOccListState>,
    private utils: Utilities,
    private oninitpopupservice: OnLoadPopUpService,
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    @Inject('OCC_CARD_URL') private occCardUrl: string,
    private titleService: Title) {
    this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[7]);
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.ownOccList();

    eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
      if (sessionStorage.getItem('pathurl') === ('/app/scout/results')) {
        if (e.type === 'languageChanged') {
          this.lang = sessionStorage.getItem('session_token');
          this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
          this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
          this.ngOnInit();
        }
        if (e.type === 'themeChanged') {
          this.theme_name = sessionStorage.getItem('theme_color');
        }
        if (e.type === 'infoModal') {
          if (sessionStorage.getItem('infopopup') == 'true') {
            this.infopopup();
            sessionStorage.setItem('infopopup', 'false');
          }
        }
        if (e.type === 'backClicked') {
          this.unsavedChanges();
        }
      }
    });
    
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
            this.subHeaderAudioState = "stoped";
            this.subHeaderAudionotfound = false;
          } else if (this.curAudio === "subheader") {
            this.subHeaderAudioState = result.audioState;
            this.subHeaderAudionotfound = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
          }
        }
      });

    this.assetsURL = assetsMediaUrl;
    this.eventService.listenPathFinderThumbUpDownEvent().pipe(
      debounceTime(1000),
      takeWhile(() => this.compActive)).subscribe((e) => {

        this.pathFinderModalpopupPayload = e.detail;

        if (this.pathFinderModalpopupPayload) {
          this.setmodalpopupData(this.pathFinderModalpopupPayload);
        }
      })

    this.assetsUrl = assetsMediaUrl;
    this.occUrl = occCardUrl;



    this.scoutOccViewedListStore.dispatch({
      type: 'GET_SCOUT_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'scout ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/scout '
      }
    });
    this.userProfileStore.dispatch({
      type: 'GET_USER_PROFILE_DATA', payload: {
        methodVal: 'GET', module_Name: 'trekker ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: '/user/profile'
      }
    });
    //In the below store subscription using skip operator because as per my observation the store gets subscribed twice everytime it is triggered. 
    //While navigating to previous page and going forward(as mentioned the store 'userProfileData' gets subscribed twice evrytime it is triggered) thus making the infinitescroll to not function properly
    //The skip(1) operator will not accept the first subscription and will make sure that the second subscription only is considered.
    this.userProfileStore.select('userProfileData').pipe(skip(1)).subscribe((respdata) => {
      this.profileData = respdata.userProfileData.occs;
      if (this.profileData) {
        this.favourate.occs.evaluated = this.profileData.evaluated;
        this.thumbFalse = [];
        this.thumbTrue = [];
        for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
          if (this.favourate.occs.evaluated[j].like == true) {
            this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
          } else if (this.favourate.occs.evaluated[j].like == false) {
            this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
          }
        }
      }
    });

    this.occThumbStateStore.select('OccThumbList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
      if (respdata.OccThumbList.occs) {
        this.favourate.occs.evaluated = respdata.OccThumbList.occs.evaluated;
        this.thumbFalse = [];
        this.thumbTrue = [];
        if (this.favourate) {
          for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
            if (this.favourate.occs.evaluated[j].like == true) {
              this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
            } else if (this.favourate.occs.evaluated[j].like == false) {
              this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
            }
          }
        }
      }
    });

    this.scoutOccViewedStore.select('scoutOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
      if (respdata["scoutOccViewedCareers"]) {
      }
    });
    this.ownOccstore
    .select("ownOccList")
    .pipe(takeWhile(() => this.compActive))
    .subscribe((ownOccListResp) => {
      if (ownOccListResp && ownOccListResp.ownOccList.length > 0) {
        try {
          this.CreateOwnOccs = ownOccListResp.ownOccList;
        } catch (e) {
          this.utils.handleError(`scout-results.component.ts constructor ownOccList: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      }
      this.dialogService.hideLoading();
    });

    this.scoutOccViewedListStore.select('scoutOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
      if (respdata["scoutOccViewedList"]["modules"]) {
        this.dialogService.showLoading();
        this.viewedOccs = respdata["scoutOccViewedList"];

      }
    });

    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.category = parseInt(params["category"]) + 1;
        this.apiModel.moduleName = 'scout';
        this.apiModel.endUrl = `occs/category/random/${this.category}/4`;
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'GET';


        try {
          this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
            if (resp) {
              const list = JSON.parse(resp);
              if (list) {
                this.showData(list);
              }
            }
          });
        } catch (e) {
          this.utils.handleError(`scout-results.component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      });
  }
  showData(list) {
    if (list && this.viewedOccs) {
      this.listOfOccs = list;
      this.listOfOccs.forEach(element => {

        const occIndex = this.viewedOccs['modules']['SCOUT']['occs']['reviewed'].
          findIndex(x => x.occId === element.occId);
        if (occIndex >= 0 && (this.thumbTrue.indexOf(element.occId) < 0) && (this.thumbFalse.indexOf(element.occId) < 0)) {
          element['viewed'] = true;
        } else {
          element['viewed'] = false;
        }
      });
      this.dialogService.hideLoading();
    }
  }

  ngOnInit() {
    this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
      if (resp.pages) {
        const data = (resp.pages);
        this.pagesData = resp.pages;
        this.scoutResultHeading = data.find((obj => obj.pageCode === 'SCOUT_SHARED'));
        let index = this.getIndexOfCategory(this.category);
        this.scoutPageData = this.pagesData[index];
      }
    });

    this.setScoutResultTitle();
  }

  ngAfterViewInit() {
    this.infopopup();
  }

  getIndexOfCategory(id) {
    let index = -1;
    if (id === 1)
      index = this.pagesData.findIndex(x => x.pageCode === 'SCOUT_SHARED_PEOPLE');
    else if (id === 2)
      index = this.pagesData.findIndex(x => x.pageCode === 'SCOUT_SHARED_IDEAS');
    else if (id === 3)
      index = this.pagesData.findIndex(x => x.pageCode === 'SCOUT_SHARED_THINGS');

    return index;
  }

  modalOpen(item, index) {
    this.stopAudio();
    sessionStorage.setItem('isModalActive', 'true');
    this.popupSelectDataIndex = index;
    this.careerItem = item;
    item['module'] = "scout";
    let like = '';

    if (this.thumbTrue.indexOf(item.occId) >= 0) {
      like = 'like';
    }
    else if (this.thumbFalse.indexOf(item.occId) >= 0) {
      like = 'unlike';
    }
    else {
      like = 'undecided';
    }
    this.dialogService.scoutOccsPopUp(item, like);
    this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
      sessionStorage.setItem('isModalActive', 'false');

      if (result === '') {

        this.setmodalpopupData('');
      }
    });
  }

  // this is for getting customOccOwn data from store by using store
  ownOccList() {
      this.store.dispatch({
        type: "GET_OWN_OCC_TEXT",
        payload: {
          methodVal: "GET",
          module_Name: "explorer",
          path_params: [],
          query_params: {},
          sessionID: sessionStorage.getItem("session_token"),
          body_Params: {},
          endUrlVal: "/user/custom-occs/list",
        },
      });
  }

  careerCreatorModelOpen(module, themeColourNumber) {
    this.theme_Color_num = 7;
    this.dialogService.landingCreatePopup(
      module.toLowerCase(),
      this.CreateOwnOccs,
      this.theme_Color_num
    );
  }

  setmodalpopupData(result) {
    let endurl = '';
    if (result) {
      if (result === 'thumb_up' || result === 'thumb_down') {
        if ((this.thumbTrue.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_up')) {
          endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
        }
        if ((this.thumbFalse.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_down')) {
          endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
        }
      }
      if (result === 'thumb_up_deselect' || result === 'thumb_down_deselect') {
        if ((this.thumbTrue.indexOf(this.careerItem.occId) >= 0) || (this.thumbFalse.indexOf(this.careerItem.occId) >= 0)) {
          if (result === 'thumb_up_deselect') {
            endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
          } else {
            endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
          }
        } else {
          this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
        }
      }
      if (endurl != '') {
        this.saveThumbStatus(endurl, result, this.careerItem);
      } else {
        this.dialogService.hideLoading();
      }
    }
    else {
      this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
    }
  }
  saveThumbStatus(endurl, status, item) {
    this.occThumbStateStore.dispatch({
      type: 'GET_OCC_THUMB', payload: {
        methodVal: 'GET', module_Name: 'pathfinder',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: endurl
      }
    });
    if (status === 'thumb_down_deselect' || status === 'thumb_up_deselect') {
      this.setCheckboxCareer(item, this.popupSelectDataIndex);
    }
  }

  setCheckboxCareer(item, index) {
    this.listOfOccs[index]['viewed'] = true;
    this.scoutOccViewedStore.dispatch({
      type: 'GET_SCOUT_OCC_VIEWED_CAREER', payload: {
        methodVal: 'PUT', module_Name: 'Scout',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/' + item.occId + '/scout'
      }
    });

  }

  infopopup() {
    this.stopAudio();
    let infoCategory : string;
		switch (this.category) {
			case 1:
				infoCategory = 'SCOUT_SHARED_PEOPLE'
				break;
			case 2:
				infoCategory = 'SCOUT_SHARED_IDEAS'
				break;
			case 3:
				infoCategory = 'SCOUT_SHARED_THINGS'
				break;
		}
    Promise.resolve().then(function () {
      this.oninitpopupservice.getOnLoadPopUp(infoCategory);
    }.bind(this));
  }

  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  headeraudioClick(text, data) {
    this.stopAudio();
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  subheaderAudioClick(text, data) {
    this.stopAudio();
    this.curAudio = "subheader";
    this.audioService.audioFunction(text, data, true);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.subHeaderAudioState = "stoped";
    this.subHeaderAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setScoutResultTitle(){
    this.titleService.setTitle("Scout Results - CareerTrek");
  }
}
