<div class="Career-trek-content">
  <div class="container" *ngIf="modeldata!=undefined">
    <h4 class="landing-page-heading">
      {{modeldata?.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" (click)="audioClick('inside',modeldata)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="explorer-intro content-box">
      <div fxLayout="row" fxLayout.xs="column" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px">
        <div fxFlex="" *ngFor="let a of cards;let i = index">
    
          <mat-card class="card d-table w-100" style="z-index:99;display: table" pointer (click)="modalOpen(a.title,a.categoryId,multipleScoresActive[i])">
            <mat-card-title class="card-header desktop-design-show" text-color="primary">{{a?.title}}</mat-card-title>
            <mat-card-content class=" card-body text-center w-100" flex v-center h-center ngStyle.xs="position:relative;">
              <!-- <img class="mobile-design-show" src="assets/explorer/MOBILE/{{a.categoryId}}.png" alt="{{a.title}}"> -->
              <img src="assets/explorer/svg/{{a.pageCode}}.svg" alt="{{a.pageCode}}" class="box-img">
              <mat-card-footer class="card-footer mobile-design-show" font-bold text-color="primary">{{a?.title}}</mat-card-footer>
            </mat-card-content>
            <div class="mobile-design-show animation-X button-card d-table-cell" pointer>
              <div class="go-button" flex v-center h-center *ngIf="multipleScoresActive[i]==false">
    
                <button mat-raised-button class="go-button-text" flex v-center h-center pointer>
                  {{keywords?.dictionary?.readySetGo[2]}}</button>
              </div>
              <div class="mobile-score " *ngIf="(multipleScoresActive[i]==true)">
                <div class="card-body-text star-icons" flex h-center p-5>
                  <div *ngFor="let score of multipleScores[i].score">
                    <img *ngIf="score!=0" src="./assets/images/completed.png" alt="badge-image" />
                    <!-- <img *ngIf="zscore==0" src="./assets/images/not-completed.png" alt="badge-image" /> -->
                  </div>
    
                </div>
                <!-- <p class="text-center">points</p> -->
                <div class="go-button" flex v-center h-center>
                  <button mat-raised-button flex v-center h-center class="m-auto playagain-button-text" color="primary">{{keywords?.dictionary?.playAgain}}</button>
                </div>
              </div> 
            </div>
          </mat-card>
          <div class="animation-Y desktop-design-show button-card text-center d-table w-100" pointer (click)="modalOpen(a.title,a.categoryId,multipleScoresActive[i])">
            <div class="card-body card-go d-table-cell">
              <!-- *ngIf="!toogleState"> -->
              <div class="card-body-text" flex v-center h-center *ngIf="multipleScoresActive[i]==false">
                <span h-center p-5 *ngIf="a.categoryId==1">{{keywords?.dictionary?.buttonPrePeople}}</span>
                <span h-center p-5 *ngIf="a.categoryId==2">{{keywords?.dictionary?.buttonPreIdeas}}</span>
                <span h-center p-5 *ngIf="a.categoryId==3">{{keywords?.dictionary?.buttonPreThings}}</span>
              </div>
              <div class="go-button" *ngIf="multipleScoresActive[i]==false">
                <!-- <div class="go-button-text" flex v-center h-center >{{keywords.dictionary.readySetGo[2]}}</div> -->
                <button mat-raised-button class="go-button-text">{{keywords?.dictionary?.readySetGo[2]}}</button>
              </div>
              <div class="mobile-score" *ngIf="(multipleScoresActive[i]==true)">
                <!-- <div class="card-body-text" flex v-center h-center *ngFor="let multiple of multipleScores[i].score ;let inx = index">
                  <span class="text-center" font-bold>{{keywords.dictionary.score}} {{inx+1}} : {{multiple.score}}/54</span>
    
                </div> -->
                <div class="card-body-text star-icons" p-5 flex h-center>
                  <div *ngFor="let score of multipleScores[i].score">
                    <img *ngIf="score!=0" src="./assets/images/completed.png" alt="badge-image" />
                    <!-- <img *ngIf="score==0" src="./assets/images/not-completed.png" alt="badge-image" /> -->
                  </div>
                </div>
                <div class="go-button">
                  <button mat-raised-button class="m-auto playagain-button-text" background-color="primary" p-0>{{keywords?.dictionary?.playAgain}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>