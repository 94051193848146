<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{scoutHeader}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="headeraudioClick('inside',pageData)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="scout-intro content-box">
      <div class="intro-block-header" *ngIf="categories">
        <div class="text-center" *ngIf="categories[0]?.iconImage">
          <img src="{{assetsUrl}}{{categories[0]?.iconImage}}.svg" alt="{{categories[0]?.iconImage}}" [ngClass]="categories[0]?.category?.title === 'Ideas' ? 'ideaimg' : ''">
          <h5 class="page-title" mb-0>{{categories[0]?.category?.title}}</h5>
        </div>
        <p text-color="primary">{{scoutSubHeader[0]}}.<br>{{scoutSubHeader[1]}}.
          <app-audio-button [audionotfound]="subHeaderAudionotfound" [audioState]="subHeaderAudioState"
            (click)="subheaderAudioClick('inside',pageData)">
          </app-audio-button>
        </p>
      </div>
      <div class="container" *ngIf="categories">
        <div class="mobile-design-show" *ngIf="completed">
          <button mat-fab color="primary" (click)="clickRoute(routeSelection)" class="next-level"
            [@flipAnimation]="completed">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
        <div fxLayout.gt-xs="row" fxLayoutAlign="center" *ngIf="categories" class="scout-intro-activity-block">
          <div fxFlex.gt-sm="30%" fxFlex.gt-xs="40%" class="scout-intro-activity-cards"
            *ngFor="let category of categories;let inx = index;">
            <mat-card class="card">
              <mat-card-content class="scout-activity-card-body" flex v-center h-center>
                <div class="active-image" pointer (click)="scoutCareerDetailView(category.occId)">
                  <img class="w-100" src="{{occCardUrl}}{{category?.media?.images?.card}}" alt="">
                  <div class="opacity-text">
                    <p m-0 flex v-center>
                      <span class="w-100">{{category?.title}}</span>
                    </p>
                  </div>
                  <div class="active-check" *ngIf="noOfSelection[inx].select===1">
                    <img src="/assets/images/cardclick.png" alt="">
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button class="btn-yes" [ngClass]="(noOfSelection[inx].select===1)? 'active-set':''"
                  (click)="actionButton('yes',inx,category)">{{keywords?.dictionary?.yes}}</button>
                <button mat-button class="btn-no" [ngClass]="(noOfSelection[inx].select===2)? 'active-set':''"
                  (click)="actionButton('no',inx,category)">{{keywords?.dictionary?.no}}</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="desktop-design-show" *ngIf="completed">
        <button mat-fab color="primary" (click)="clickRoute(routeSelection)" class="next-level"
          [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>