
/**Import angular core packages */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-explorer',
	template: `<app-static-header></app-static-header>
	<router-outlet></router-outlet>
	<app-static-footer></app-static-footer>`
})

export class PathFinderComponent implements OnInit {


	constructor() {

	}

	ngOnInit() {

	}
}
