<div class="Career-trek-content" *ngIf="categories">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{scoutAssessText?.header}}
      <app-audio-button
      [audionotfound]="headerAudionotfound"
      [audioState]="headerAudioState"
      (click)="headerAudioClick('inside',scoutAssessText)"
    ></app-audio-button>
    </h4>
  </div> 
  <div class="container" *ngIf="indexValue!==categories.length">
    <div class="scout-activity content-box">
      <h1 text-color="primary" class="activity-header">
        {{categories[0]?.question}}
        <app-audio-button [audionotfound]="quesAudionotfound" [audioState]="quesAudioState"
        (click)="quesAudioClick('inside',categories[0])"></app-audio-button>
      </h1>
      <div class="mobile-heading mobile-design-show" *ngIf="nextIcon == 'yes'">
        <div flex v-center justify-end>
          <button mat-fab color="primary" (click)="clickRoute()" [@flipAnimation]="completed">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
      <div class="activity-cards-body">
        <mat-radio-group [(ngModel)]="selectedRadio">
          <div class="activity-cards inner-block" *ngFor="let option of categoryOption.options;let inx = index;">
            <div>
              <mat-radio-button  [value]="option.optionKey" (click)="addActivity(indexValue,option.optionKey)"></mat-radio-button>
              <mat-card>
                <mat-card-content pointer class="activity-body" (click)="addActivity(indexValue,option.optionKey)">
                  <img class="w-100" src="{{option?.image}}" alt="">
                </mat-card-content>
              </mat-card>
              <mat-card-footer>
                {{option?.answer}}
                <app-audio-button [audionotfound]="ansAudionotfound[inx]" [audioState]="ansAudioState[inx]"
                (click)="ansAudioClick('inside',option.audio, inx)">
              </app-audio-button>
              </mat-card-footer>
            </div>
            <div class="or-block" [class.or-text-spanish] = "languageChanged =='es'">
              <h1 text-color="primary">{{keywords?.dictionary?.or}}</h1>
            </div>
          </div>
        </mat-radio-group>
      </div>
      <div class="desktop-design-show" *ngIf="nextIcon == 'yes'">
        <button mat-fab color="primary" (click)="clickRoute()" class="next-level" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>