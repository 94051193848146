import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Inject,
} from "@angular/core";
import { Location } from "@angular/common";
import { RouterModule, Router, NavigationEnd } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  MatCardModule,
} from "@angular/material/card";
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatToolbarModule,
} from '@angular/material/toolbar';
import { DialogopenService } from "../common-modal/modalpopup.service";
import { Subscription } from "rxjs";
import { EventdispatchService } from "../common-modal/eventdispach.service";
import { Store } from "@ngrx/store";
import {
  SiteDataState,
  PageDataState,
  ProfileDataState,
  AttributeDataState,
  AudioState,
  completionListState,
  ModulesDataState,
} from "../../state-management/state/main-state";
import { ApiCallClass } from "../../shared/common-services/api-call-model";
import { ApiCallService } from "../../shared/common-services/api-call-service";
import { environment } from "../../../environments/environment";
import { StoreService } from "../../state-management/services/store-service";
import { OnLoadPopUpService } from "../common-services/onloadpopup.service";
import { Utilities } from "../../shared/common-services/utilities.service";
import { SIDEMENUAnimation } from "../common-animations/CT_animations";
import { skip, take, takeWhile } from "rxjs/operators";
import { ProfileObject } from "../../state-management/model/main-model";
const baseurl = environment.API_BASE_URL;

@Component({
  selector: "app-static-header",
  templateUrl: "./header-layout.html",
  animations: [SIDEMENUAnimation],
})
export class StaticHeaderComponent implements OnInit, OnDestroy {
  SidemenuButton = false;
  Sidemenuanimation = false;
  urlpath: string;
  langkey = "";
  languageOptions: any;
  siteText: any;
  defaultLang = "";
  title = "";
  cnt = 0;
  path;
  displayLang = "";
  keywords: any;
  pageTitle = [];
  pageText;
  tokenInfo;
  defaultHelp = "helpOff";
  profileTitle: ProfileObject;
  myPic: any;
  pagesCall = false;
  compActive = true;
  assetsSVG_URL: string;
  assetsPNGUrl: string;
  themeColor;
  enabledModulesList: any = [];
  pageTabNames: any = [];
  audioState = 'stoped';
	audionotfound = false;
  constructor(
    private _location: Location,
    private router: Router,
    public dialogService: DialogopenService,
    private oninitpopupservice: OnLoadPopUpService,
    public eventService: EventdispatchService,
    private utils: Utilities,
    private store: Store<SiteDataState>,
    private audioStore: Store<AudioState>,
    private store1: Store<PageDataState>,
    private store2: Store<AttributeDataState>,
    private profileStore: Store<ProfileDataState>,
    private http: HttpClient,
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private storeService: StoreService,
    private moduleCompletionstore: Store<completionListState>,
    @Inject("ASSETS_MEDIA_URL") private assetsSvg_URL: string,
    @Inject("ASSETS_MEDIA_PNG_URL") private assetsPNG_URL: string, private moduleStore: Store<ModulesDataState>,
  ) {
    this.moduleStore.dispatch({
      type: 'GET_MODULES', payload: {
        methodVal: 'GET', module_Name: '',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/components'
      }
    });
    /* */
    //subscribe the obserable from profile component
    this.assetsSVG_URL = assetsSvg_URL;
    this.assetsPNGUrl = assetsPNG_URL;
    this.eventService
      .listenImg()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((message) => {
        if (message.text === "imageChanged") {
          this.ngOnInit();
        }
      });

    this.profileStore
      .select("profileImage")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((profileState) => {
        if (profileState && profileState.profileImage) {
          try {
            const reader = new FileReader();
            reader.readAsDataURL(profileState.profileImage);
            reader.onloadend = () => {
              this.myPic = reader.result;
            };
          } catch (e) {
            this.utils.handleError(`header.component.ts constructor profileImage: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
    this.tokenInfo = this.apiCall.getDecodedAccessToken(
      sessionStorage.getItem("session_token")
    );
    sessionStorage.setItem("languageSet", this.tokenInfo.language);
    this.menuTitle();

    const pathUrl = this._location.path().split("/");
    const pathUrl1 = this._location.path();
    for (let i = 0; i < pathUrl.length; i++) {
      this.path = pathUrl[i].split(",");
    }
    this.store.dispatch({
      type: "SITE_PROFILE_TEXT",
      payload: {
        methodVal: "GET",
        module_Name: "siteprofile",
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem("session_token"),
        body_Params: {},
        endUrlVal: "site/profile",
      },
    });

    //Dispatching profile data
    this.store.dispatch({
      type: "PROFILE_IMAGE",
      payload: {
        methodVal: "GET",
        module_Name: "avatar",
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem("session_token"),
        body_Params: {},
        endUrlVal: "user/avatar/generate/me.png",
      },
    });
  }
  stopAudio() {  //to stop current playing audio
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
  sideMenu() {
    this.stopAudio();
    document.body.classList.add(
      'lang-scroll'
    );
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    if (!this.profileTitle) {
      this.profileTitle = this.oninitpopupservice.getKeyWordData("PROFILE");
    }
    if (this.SidemenuButton) {
      setTimeout(() => {
        this.SidemenuButton = !this.SidemenuButton;
      }, 200);
      document.body.classList.remove(
        'lang-scroll'
      );
    } else {
      this.SidemenuButton = !this.SidemenuButton;
    }
  }
  profile() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    setTimeout(() => {
      this.SidemenuButton = !this.SidemenuButton;
      document.body.classList.remove(
        'lang-scroll'
      );
      this.router.navigateByUrl("/app/profile");
    }, 200);
  }
  menuTitle() {
    this.themeColor = sessionStorage.getItem("theme_color");
    this.store1
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages != undefined && resp.pages[0] != null) {
          this.profileTitle = this.oninitpopupservice.getKeyWordData("PROFILE");

          const dictionaryDataIndex = resp.pages.findIndex(
            (x) => x.pageCode === "GLOBAL_SETTINGS"
          );
          this.keywords = resp.pages[dictionaryDataIndex].dictionary;
          if (this.pagesCall) {
            this.pagesCall = false;
          }

          this.pageTitle = [];
          // pageTitleFunction must be called here otherwise the library filter tabs will not display expected text.
          this.pageTitle = this.utils.pageTitleFunc(resp.pages);
          this.pageText = resp.pages.find((val) => val.selected);
          if (
            this.pageText &&
            this.pageText.pageText &&
            (this.pageText.pageText.intro && this.pageText.subModule ||
              this.pageText.pageText.intro !== "" ||
              (this.pageText.pageText.expressions && this.pageText.pageText.expressions.items.length > 0) ||
              this.pageText.pageText.helper.length > 0)
          ) {
            //without, this.pageText.pageText.helper the helpon button is not displaying while landing page
            this.urlpath = "active";
          } else {
            this.urlpath = "not-active";
          }
          sessionStorage.setItem(
            "formobileheading",
            this.pageText ? this.pageText.menuTitle : ""
          );
          if (JSON.parse(sessionStorage.getItem("infoPopupState"))) {
            this.defaultHelp = this.keywords.helpOn;
          } else {
            this.defaultHelp = this.keywords.helpOff;
          }
        }
      });
    this.pagesCall = true;
  }
  ngOnInit() {
    this.moduleStore.select('modules').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
      // As the subscription was happening twice and for the first time we get empty value, to access only when the data is available we are skipping once here.
      this.enabledModulesList = [];
      if (result) {
        this.enabledModulesList = Object.assign({}, result);
        // pageTabsFunction must be called here otherwise the library filter tabs will not display expected text.
        this.pageTabNames = this.utils.pageTabsFunc(this.enabledModulesList.modules);
        if (this.pageTabNames) {
          sessionStorage.setItem(
            "librarytabnames",
            JSON.stringify(this.pageTabNames)
          );
        }
      }
    });
    const ref = this;
    ref.store.select("sites").subscribe((resp) => {
      if (resp.languageOptions != undefined) {
        const languageKey = this.tokenInfo.language;
        ref.languageOptions = resp.languageOptions;
        ref.languageOptions.forEach(function (obj, inx) {
          if (obj.language == languageKey) {
            ref.displayLang = obj.display;
            ref.langkey = obj.language;
          }
        });
        sessionStorage.setItem("assetsData", JSON.stringify(resp.assets));
      }
    });
  }
  backTriggered() {
    if (sessionStorage.getItem("backButtonPage")) {
      const pathurl = this._location.path().split("?");
      sessionStorage.setItem("pathurl", pathurl[0]);
      if (
        sessionStorage.getItem("pathurl").includes("/app/intro") ||
        sessionStorage.getItem("pathurl") == "/app/create" ||
        sessionStorage.getItem("pathurl") == "/app/library-occupations"
      ) {
        sessionStorage.setItem("theme_color", "");
        const event = document.createEvent("CustomEvent");
        event.initEvent("themeChanged", true, true);
        this.eventService.dispatch(event);
      }
      const evnt = document.createEvent("CustomEvent");
      evnt.initEvent("backClicked", true, true);
      this.eventService.dispatch(evnt);
    }
  }

  logout() {
    setTimeout(() => {
      this.SidemenuButton = !this.SidemenuButton;
      sessionStorage.removeItem("session_token");
      window.location.href = "/logout";
      sessionStorage.setItem("theme_color", "");
      const event = document.createEvent("CustomEvent");
      event.initEvent("themeChanged", true, true);
      this.eventService.dispatch(event);
      window.sessionStorage.clear();
    }, 200);
  }
  themechange() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    const pathurl = this._location.path().split("?");
    sessionStorage.setItem("pathurl", pathurl[0]);
    sessionStorage.setItem("theme_color", "");
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
    this.router.navigateByUrl("/app/landing");
  }
  selectLang(currentLang) {
    sessionStorage.removeItem("languageSet");
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    if (this.langkey != currentLang) {
      const pathurl = this._location.path().split("?");
      sessionStorage.setItem("pathurl", pathurl[0]);
      this.dialogService.showLoading();
      const ref = this;
      this.languageOptions.forEach(function (obj, inx) {
        if (obj.language == currentLang) {
          ref.displayLang = obj.display;
          ref.langkey = obj.language;
          sessionStorage.setItem("languageSet", obj.language);
        }
      });
      try {
        this.apiModel.method = "GET";
        this.apiModel.moduleName = "USER";
        this.apiModel.endUrl = "user/language/" + currentLang;
        this.apiModel.sessionID = sessionStorage.getItem("session_token");
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe(
            (data) => {
              sessionStorage.removeItem("session_token");
              sessionStorage.removeItem("language");
              sessionStorage.removeItem("attributesData");
              sessionStorage.removeItem("formobileheading");
              const tokenData = JSON.parse(data);
              if (tokenData["token"] != null && tokenData["token"] !== "") {
                sessionStorage.setItem("language", tokenData["language"]);
                sessionStorage.setItem("session_token", tokenData["token"]);
                const payloajson = {
                  type: "OCC_LIST_TEXT",
                  payload: {
                    methodVal: "GET",
                    module_Name: "occs",
                    path_params: [],
                    query_params: {},
                    sessionID: sessionStorage.getItem("session_token"),
                    body_Params: {},
                    endUrlVal: "occs",
                  },
                };
                const languageKey = this.tokenInfo.language;
                this.storeService.commonLanguageChange(
                  languageKey,
                  "OCC_index_list",
                  payloajson
                );
                const evnt = document.createEvent("CustomEvent");
                evnt.initEvent("languageChanged", true, true);
                this.eventService.dispatch(evnt);
                this.oninitpopupservice.pageCallText(
                  sessionStorage.getItem("session_token"),
                  "pages"
                );
                this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
                this.moduleStore.dispatch({
                  type: 'GET_MODULES', payload: {
                    methodVal: 'GET', module_Name: '',
                    path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
                    body_Params: {}, endUrlVal: 'user/components'
                  }
                });
                this.menuTitle();
              }
              this.dialogService.hideLoading();
            },
            (e: HttpErrorResponse) => {
              this.utils.handleError(`header.component.ts selectLang: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);            }
          );
      } catch (e) {
        this.utils.handleError(`header.component.ts selectLang exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    }
  }

  reload() {
    window.location.reload();
  }

  popupHelp(accessibility) {
    this.stopAudio();
    this.apiModel.moduleName = "Help";
    this.apiModel.endUrl = "user/accessibility";
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "PUT";
    this.apiModel.data = {
      accessibility: accessibility,
    };
    try {
      this.apiCall
        .getImage([this.apiModel])
        .pipe(take(1))
        .subscribe((resp) => {
          if (resp) {
            if (accessibility) {
              this.defaultHelp = this.keywords.helpOn;
            } else {
              this.defaultHelp = this.keywords.helpOff;
            }
            //Need this store updation when popup help is On or Off.
            this.moduleCompletionstore.dispatch({
              type: "GET_MODULE_COMPLETION_DATA",
              payload: {
                methodVal: "GET",
                module_Name: "profile",
                path_params: [],
                query_params: {},
                sessionID: sessionStorage.getItem("session_token"),
                body_Params: {},
                endUrlVal: "/user/profile/provide/portfolio",
              },
            });

            sessionStorage.setItem(
              "infoPopupState",
              JSON.stringify(accessibility)
            );
          }
        });
    } catch (e) {
      this.utils.handleError(`header.component.ts popupHelp: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  callInfoModal() {
    this.stopAudio();
    const pathurl = this._location.path().split("?");
    sessionStorage.setItem("pathurl", pathurl[0]);
    sessionStorage.setItem("popupactive", "true");
    sessionStorage.setItem("infopopup", "true");
    const evnt = document.createEvent("CustomEvent");
    evnt.initEvent("infoModal", true, true);
    this.eventService.dispatch(evnt);
  }
  @HostListener("window:mousedown", ["$event"])
  onmousedown(event) {
    const currentDate = new Date();
    const currentSeconds = currentDate.getTime();
    sessionStorage.setItem("currentSec", currentSeconds + "");
  }

  @HostListener("window:keydown", ["$event"])
  keyboardInput(event: any) {
    this.onmousedown(event);
  }
  ngOnDestroy() {
    this.compActive = false;
  }
}
