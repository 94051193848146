import { Component, OnInit, Inject } from '@angular/core';
import { Next } from '../../../shared/common-animations/CT_animations';
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import {
  AttributeDataState,
  PageDataState,
  AudioState,
  NavigatorChallengeState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { sample, takeWhile } from "rxjs/operators";
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { OccThumbState, NavigatorOccViewedState, NavigatorOccViewedCareerState, NavigatorResultStoreState, UserProfileState } from '../../../state-management/state/main-state';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { fakeAsync } from '@angular/core/testing';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-navigator-results',
  templateUrl: './navigator-result.component.html'
})
export class NavigatorResultsComponent implements OnInit {
  completed = true;
  compActive = true;
  pageData: any;
  attributeData: any;
  pageCode: string;
  keywords: any;
  theme_name: string;
  valuesData: any;
  lang = "";
  careerItem: any;
  popupSelectDataIndex: number;

  assetsURL: string;
  topTwoGroups = [];
  overAllvalues: any;
  occCardAPIUrl: string;
  thumbFalse = [];
  thumbTrue = [];
  tabname: string;
  occPayload: any;

  viewedOccs = [];
  valueIndex: number;

  headerAudioState = "stoped";
  headerAudionotfound = false;
  curAudio: string;
  isModalActive: boolean;


  constructor(public router: Router,
    private store: Store<PageDataState>,
    public dialogService: DialogopenService,
    private attrStore: Store<AttributeDataState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    public activatedRoute: ActivatedRoute,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private navigatorOccsViewedCareerStore: Store<NavigatorOccViewedCareerState>,
    private navigatorOccsViewedListStore: Store<NavigatorOccViewedState>,
    private navigatorResultStore: Store<NavigatorResultStoreState>,
    private navigatorChallengeState: Store<NavigatorChallengeState>,
    private occThumbStateStore: Store<OccThumbState>,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject('OCC_CARD_URL') private occCardUrl: string,
    private titleService: Title) {
    this.assetsURL = assetsUrl;
    this.occCardAPIUrl = occCardUrl;

    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_SHARED" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/results") {
          if (e.type === "languageChanged") {
            // this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
            this.lang = sessionStorage.getItem("session_token");
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');

            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });

    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header" && !this.isModalActive) {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });
      this.navigatorOccsViewedListStore.dispatch({
        type: 'GET_NAVIGATOR_OCC_VIEWED_LIST', payload: {
          methodVal: 'GET', module_Name: 'navigator ',
          path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
          body_Params: {}, endUrlVal: 'user/occ/viewed/list/navigator '
        }
      });
      this.navigatorOccsViewedCareerStore.select('navigatorOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe();
      this.navigatorOccsViewedListStore.select('navigatorOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
        if (respdata.navigatorOccViewedList.modules.NAVIGATOR.occs.reviewed) {
          this.viewedOccs = respdata.navigatorOccViewedList.modules.NAVIGATOR.occs.reviewed;
        }
      });

    if (sessionStorage.getItem('GLOBAL_SETTINGS')) {
      this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    }
  }

  ngOnInit() {
    this.dialogService.showLoading();
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.keywords = JSON.parse(
            sessionStorage.getItem("GLOBAL_SETTINGS")
          );
          this.pageData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_SHARED"
          );
          this.overAllvalues = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_ASSESS"
          );
        }
      });
    this.attributeDataCall();
    this.getTopTwoGroups();
    this.setNavigatorResultTitle();
  }

  ngAfterViewInit() {
    this.infopopup();
  }
  attributeDataCall() {
    this.oninitpopupservice.pageCallText(this.lang, "attributes/value");
    this.attrStore
      .select("attribute")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((res) => {
        const data = res.attribute;
        if (data[0]) {
          this.valuesData = [];
          this.valuesData = data.filter(val => val.type === 'value');
        }
      });
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_SHARED");
      }.bind(this)
    );
  }

  getTopTwoGroups() {
    this.apiModel.moduleName = 'navigator';
    this.apiModel.endUrl = 'user/values/results';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
        if (resp != undefined && resp != null) {
          const data = JSON.parse(resp);
          var topTwo = []
          data.forEach(element => {
            topTwo.push(element.value);
          });

          this.topTwoGroups = this.valuesData.filter(ele1 => topTwo.indexOf(ele1.name) != -1);
          this.navigatorResultStore.select('navigatorResult').pipe(takeWhile(() => this.compActive)).subscribe(resultResp => {
            if (resultResp['navigatorResult'].value1List.length > 0 && resultResp['navigatorResult'].value2List.length) {
              this.topTwoGroups[0]['list'] = resultResp['navigatorResult'].value1List;
              this.topTwoGroups[1]['list'] = resultResp['navigatorResult'].value2List;
              this.dialogService.hideLoading();
            } else {
              topTwo.forEach((ele, i) => {
                this.getOccValues(ele, i);
              });
            }
          });
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-result.component.ts getTopTwoGroups: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  getOccValues(valueName, i) {
    this.apiModel.moduleName = 'navigator';
    this.apiModel.endUrl = 'occs/value/random/' + valueName + '?numbItems=3&lang=en';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
        if (resp != undefined && resp != null) {
          this.topTwoGroups[i]['list'] = JSON.parse(resp);
          const list = JSON.parse(resp);
          if (list) {
            this.showData(list, i);
          }
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-result.component.ts getOccValues: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  showData(list, i) {
    if (list && this.viewedOccs) {
      this.topTwoGroups[i]['list'] = list;
      this.topTwoGroups[i]['list'].forEach(element => {
        const occIndex = this.viewedOccs.findIndex(x => x.occId === element.occId);
        if (occIndex >= 0 && (this.thumbTrue.indexOf(element.occId) < 0) && (this.thumbFalse.indexOf(element.occId) < 0)) {
          element['viewed'] = true;
        } else {
          element['viewed'] = false;
        }

      });
      this.dialogService.hideLoading();
    }
  }

  modalOpen(item, valueIndex, listIndex) {
    this, this.stopAudio();
    this.isModalActive = true;
    this.careerItem = item;
    this.popupSelectDataIndex = listIndex
    this.valueIndex = valueIndex
    this.tabname = "value"
    let like = "";
    if (this.thumbTrue.indexOf(item.occId) >= 0) {
      like = "like";
    } else if (this.thumbFalse.indexOf(item.occId) >= 0) {
      like = "unlike";
    } else {
      like = "undesided";
    }
    this.theme_name = sessionStorage.getItem("theme_color");
    this.dialogService.explorerOccsPopup(
      item,
      this.tabname,
      this.valuesData,
      like,
      this.theme_name
    );
    this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
      this.isModalActive = false;
      if (result === '') {
        this.setmodalpopupData('');
      } else {
        this.setmodalpopupData(result);
      }
    });

  }

  setmodalpopupData(result) {
    let endurl = '';
    if (result) {
      if (result === 'thumb_up' || result === 'thumb_down') {
        if ((this.thumbTrue.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_up')) {
          endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
          this.thumbTrue.push(this.careerItem.occId);
          let index = this.thumbFalse.findIndex(x => x === this.careerItem.occId);
          if (index != -1) {
            this.thumbFalse.splice(index, 1);
          }
        }

        if ((this.thumbFalse.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_down')) {
          endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
          this.thumbFalse.push(this.careerItem.occId);
          let index = this.thumbTrue.findIndex(x => x === this.careerItem.occId);
          if (index != -1) {
            this.thumbTrue.splice(index, 1);
          }
        }
        this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
      }

      if (result === 'thumb_up_deselect' || result === 'thumb_down_deselect') {
        if ((this.thumbTrue.indexOf(this.careerItem.occId) >= 0) || (this.thumbFalse.indexOf(this.careerItem.occId) >= 0)) {

          if (result === 'thumb_up_deselect') {
            endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
          } else {
            endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
          }
          let index = this.thumbTrue.findIndex(x => x === this.careerItem.occId);
          if (index != -1) {
            this.thumbTrue.splice(index, 1);
          }
        } else {

          this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
        }
      }
      if (endurl != '') {
        this.saveThumbStatus(endurl, result, this.careerItem);
      } else {
        this.dialogService.hideLoading();
      }
    } else {
      this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
    }
  }
  saveThumbStatus(endurl, status, item) {
    this.occThumbStateStore.dispatch({
      type: 'GET_OCC_THUMB', payload: {
        methodVal: 'GET', module_Name: 'navigator',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: endurl
      }
    });
    if (status === 'thumb_down_deselect' || status === 'thumb_up_deselect') {
      this.setCheckboxCareer(item, this.popupSelectDataIndex);
    }
  }


  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }


  setCheckboxCareer(item, index) {
    this.topTwoGroups[this.valueIndex]['list'][index]['viewed'] = true;
    this.navigatorOccsViewedCareerStore.dispatch({
      type: 'GET_NAVIGATOR_OCC_VIEWED_CAREER', payload: {
        methodVal: 'PUT', module_Name: 'Navigator',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/' + item.occId + '/navigator'
      }
    });

  }
  navigateToChallenge() {
    this.navigatorResultStore.dispatch({ type: 'NAVIGATOR_RESULT_STORE_VAL', payload: { navigatorResult: { value1List: this.topTwoGroups[0].list, value2List: this.topTwoGroups[1].list } } });
    this.navigatorChallengeState.dispatch({
      type: 'NAVIGATOR_CHALLENGE_VAL'
      , payload: {
        navigatorChallengeData: {
          storedData: [],
          challengeQuestions: []
        }
      }
    });
    this.router.navigate(["../challenge"], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setNavigatorResultTitle(){
    this.titleService.setTitle("Navigator Results - CareerTrek");
  }
}