/**Angular core packages */
import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import { RouteUrls } from '../../shared/common-constants/route.constants';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';


/* dialog is reference of loading model template.*/
@Injectable()
export class Utilities implements ErrorHandler {
/** When there are more than one error occuring while API response proportion to that number of  the error dialog instance is created. 
    Inorder restrict the unwanted dialog instances, the limit value is used **/
 limit = 0;
  /** This function is for logging out of the modules. */

constructor(private dialogPopUp: DialogopenService, private router: Router, private http: HttpClient) {
  // subscribing for the AsmntCommonText and FrameworkConfigReducer

}
handleError = function (error: any) {
  if(error){
    this.limit++; 
  }
  // In this function checking the conditions for messages for HttpErrorResponse:
    try {
      if (error.name === 'HttpErrorResponse') {
        if (this.limit === 1) {
          this.dialogPopUp.errorPopup(JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS')));
        }
      }
      this.dialogPopUp.hideLoading();
      this.log(error, 'error').subscribe();
    } catch (e) {
      this.log(`utilities.service.ts handleError exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`, 'error');
    }
  }.bind(this);

  log(error: any, level: string): Observable<any> {
    try {
      let errObj = {};
      if (typeof error === 'object') {
        errObj = {
          error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
          level: level,
        };
      } else {
        errObj = { error: error, level: level };
      }
      return this.http.post('/logError', errObj);
    } catch (e) {
      console.log('log error exception:' + e.message);
    }
  }

  /** This function is for application back navigation as mentioned in the API */
  backRoute() {
    window.history.back();
  }
  pageTitleFunc(pagesData) {
    let value = [];
    pagesData.forEach(function (obj, inx) {
      if (value.indexOf(obj.menuTitle) === -1) {
        if (obj.module === 'EXPLORER') {
          value[0] = obj.menuTitle;
        } else if (obj.module === 'PATHFINDER') {
          value[1] = obj.menuTitle;
        } else if (obj.module === 'CAREER_TREKKER') {
          value[2] = obj.menuTitle;
        } else if (obj.module === 'QUICKPIC') {
          value[3] = obj.menuTitle;
        } else if (obj.module === 'CREATE_OWN_OCCUPATION') {
          value[4] = obj.menuTitle;
        } else if (obj.module === 'PROFILE') {
          value[5] = obj.menuTitle;
        } else if (obj.module === 'CAREERCORNER') {
          value[6] = obj.menuTitle;
        } else if (obj.module === 'NAVIGATOR') {
          value[7] = obj.menuTitle;
        } else if (obj.module === 'PROFILE') {
          value[8] = obj.menuTitle;
        } else if (obj.module === 'DISCOVERER') {
          value[8] = obj.menuTitle;
        }
        
      }
    });

    return value;
  }

  pageTabsFunc(result) {
    let value = [];
    result.forEach(function (obj, inx) {
        if (obj.moduleName === 'Explorer') {
          value[0] = obj.displayName;
        } else if (obj.moduleName === 'Pathfinder') {
          value[1] = obj.displayName;
        } else if (obj.moduleName === 'Trekker') {
          value[2] = obj.displayName;
        } else if (obj.moduleName === 'QuickPic') {
          value[3] = obj.displayName;
        } else if (obj.moduleName === 'CareerLibrary') {
          value[4] = obj.displayName;
        } else if (obj.moduleName === 'CareerCorner') {
          value[5] = obj.displayName;
        } else if (obj.moduleName === 'Navigator') {
          value[6] = obj.displayName;
        }  else if (obj.moduleName === 'Discoverer') {
          value[7] = obj.displayName;
        }
        else if (obj.moduleName === 'Scout') {
          value[8] = obj.displayName;
        }
        
    });
    return value;
  }

  capitalizeFirstLetter(str: string)
  {
    if(str && str.length>0){
      return str[0].toUpperCase() + str.slice(1);
    }
    return "";
  }
  /**setAuthKey method for setting the auth_key using sessionStorageSet method */
  // setAuthKey(key) {
  //     return this.storageService.sessionStorageSet('auth_key', key);
  // }

  //  /**getAuthKey method for setting the auth_key using sessionStorageGet method */
  // getAuthKey() {
  //     return this.storageService.sessionStorageGet('auth_key');
  // }
}
