import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AudioState, PageDataState, QuickpicQuesIdState } from '../../../state-management/state/main-state';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { Next } from '../../../shared/common-animations/CT_animations';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-quickpic-intro',
    templateUrl: './quickpic-intro-layout.html',
    styles: [],
    animations: [Next]
})


export class QuickpicIntroComponent implements OnInit, OnDestroy {
    keyWords: any = [];
    pagesText: any;
    theme_name: string;
    lang: string;
    modalData: any;
    restoreActive = false;
    quickPicIntroHeading;
    // The completed variable to set to true, to turn on the flip animation of the button
    completed: boolean;
    compActive = true;
    quickDataPages = [];
    audioState = 'stoped';
	audionotfound = false;
    constructor(
        private router: Router,
        public store: Store<PageDataState>,
        private activatedRoute: ActivatedRoute,
        private apiCall: ApiCallService,
        private apiModel: ApiCallClass,
        public dialogService: DialogopenService,
        private utils: Utilities,
        public quesIdStore: Store<QuickpicQuesIdState>,
        public eventService: EventdispatchService,
        private oninitpopupservice: OnLoadPopUpService,
        private audioStore: Store<AudioState>,
        private audioService: AudioService,
        private titleService: Title
    ) {
        const colors = environment.THEME_COLORS;
        sessionStorage.setItem('theme_color', colors[4]);
        this.store.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'QUICKPIC', pageCode: 'QUICKPIC_INTRO' } });

        const event = document.createEvent('CustomEvent');
        event.initEvent('themeChanged', true, true);
        this.eventService.dispatch(event);
        eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
            if (sessionStorage.getItem('pathurl') == ('/app/quickpic/intro')) {
                if (e.type === 'languageChanged') {
                    this.lang = sessionStorage.getItem('session_token');
                    this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
                    this.ngOnInit();
                }
                if (e.type === 'themeChanged') {
                    this.theme_name = sessionStorage.getItem('theme_color');
                }
                if (e.type === 'infoModal') {
                    if (sessionStorage.getItem('infopopup') == 'true') {
                        sessionStorage.setItem('infopopup', 'false');
                        this.infopopup();
                    }
                }
                if (e.type === 'backClicked') {
                    const evnt = document.createEvent('CustomEvent');
                    this.eventService.dispatchAudioStop(evnt);
                    this.unsavedChanges();
                }
            }
        });

        if (this.lang === '' || this.lang == null) {
            this.lang = sessionStorage.getItem('session_token');
        }
        this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
        this.quickListData();
    }
    unsavedChanges() {
        this.utils.backRoute();
    }
    infopopup() {
        this.stopAudio();
        Promise.resolve().then(function () {
            this.oninitpopupservice.getOnLoadPopUp('QUICKPIC_INTRO');
        }.bind(this));

    }
    ngAfterViewInit() { 
		this.infopopup();
		}
    stopAudio() {  //to stop current playing audio
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
    ngOnInit() {
        this.quesIdStore.dispatch({type:'QuickPIC_QuestionsID',payload:{quickpicAssessment:{questionsId:0,answers:[],totalqueslength:0}}}); 
        
        this.completed = !this.completed;
        this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                if (resp.pages && resp.pages[0]) {
                    this.quickDataPages = (resp.pages);
                    this.quickPicIntroHeading =  this.quickDataPages.find((obj => obj.pageCode === 'QUICKPIC_INTRO')); //To get the quickpic intro page data.
                    this.modalData = [];
                    this.keyWords = this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');

                    for (let i = 0; i < this.quickDataPages.length; i++) {
                        if (this.quickDataPages[i].pageCode === 'QUICKPIC_INTRO') {
                            this.modalData = this.quickDataPages[i];
                            this.getData();
                        }
                    }


                }
        });
        this.setQuickPicIntroTitle();
    }

    getData() {    
        this.apiModel.moduleName = 'quick-pic';
        this.apiModel.endUrl = '/quick-pic/questions';
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'GET';
        try {
            this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                sessionStorage.setItem("questions", resp);
            });
        } catch (e) {
            this.utils.handleError(`quickpic-intro-component.ts getData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    //Created the method for calling to know the quick pic list data.
    quickListData() {
         this.dialogService.showLoading();
        try {
            this.apiModel.moduleName = 'quick-pic';
            this.apiModel.endUrl = 'quick-pic/list';
            this.apiModel.sessionID = sessionStorage.getItem('session_token');
            this.apiModel.method = 'GET';
            this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                if (JSON.parse(resp)[0]) {
                    this.restoreActive = true;
                  
                } 
                this.dialogService.hideLoading();
            });
        } catch (e) {
            this.utils.handleError(`quickpic-intro-component.ts quickListData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }
    audioClick(text, data) {
		this.audioService.audioFunction(text, data);

    }
    navigateToAssessment(){
        const evnt = document.createEvent('CustomEvent');
        this.eventService.dispatchAudioStop(evnt);
        this.router.navigateByUrl('/app/quickpic/assessment');

    }
    restore() {
        try {
            const evnt = document.createEvent('CustomEvent');
            this.eventService.dispatchAudioStop(evnt);
            this.router.navigate(['/app/quickpic/restore'], {
                relativeTo: this.activatedRoute,
                queryParams: {
                    completed: 'list'
                }
            });
        } catch (e) {
            this.utils.handleError(`quickpic-intro-component.ts restore: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }
    ngOnDestroy() {
        this.compActive = false;
    }

    private setQuickPicIntroTitle(){
        this.titleService.setTitle("QuickPic Intro - CareerTrek");
    }
}
