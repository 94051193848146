<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{ pageData.header }}
      <app-audio-button [audionotfound]="headerAudioNotFound" [audioState]="headerAudioState"
        (click)="audioClick('inside',pageData)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div *ngIf="firstSelectionStage" class="navigator-activity content-box">
      <div class="mobile-heading mobile-design-show" flex v-center justify-end>
        <button *ngIf="isItemSelected" class="next-level-button" mat-fab color="primary" [@flipAnimation]="completed"
          (click)="nextStage()">
          {{ pageData?.dictionary?.more }}
        </button>
      </div>
      <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutWrap="wrap">
        <div fxFlex.gt-sm="30%" class="navigator-activity-block">
          <div class="work-activity">
            <span font-bold mx-1>{{ page1List[0].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[0]" [audioState]="audioState[0]"
              (click)="listAudioClick('inside',page1List[0],0)"></app-audio-button>
            <div [ngClass]="{'activity-image': page1List[0].selected === true}" (click)="selectOption('page1',0)">
              <img src="{{ page1List[0].src }}" />
            </div>
          </div>
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page1List[1].selected === true}" (click)="selectOption('page1', 1)">
              <img src="{{ page1List[1].src }}" />
            </div>
            <span font-bold mx-1>{{ page1List[1].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[1]" [audioState]="audioState[1]"
              (click)="listAudioClick('inside',page1List[1],1)"></app-audio-button>
          </div>
          <div class="work-activity">
            <span font-bold mx-1>{{ page1List[2].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[2]" [audioState]="audioState[2]"
              (click)="listAudioClick('inside',page1List[2],2)"></app-audio-button>
            <div [ngClass]="{'activity-image':page1List[2].selected === true}" (click)="selectOption('page1', 2)">
              <img src="{{ page1List[2].src }}" />
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="40%" class="navigator-activity-block">
          <div class="emoji-outer">
            <div class="activity-emoji">
              <img src="{{emoji}}" alt="profile-emoji" />
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="30%" class="navigator-activity-block">
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page1List[3].selected === true}" (click)="selectOption('page1',3)">
              <img src="{{ page1List[3].src }}" />
            </div>
            <span font-bold mx-1>{{ page1List[3].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[3]" [audioState]="audioState[3]"
              (click)="listAudioClick('inside',page1List[3],3)"></app-audio-button>
          </div>
          <div class="work-activity">
            <span font-bold mx-1>{{ page1List[4].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[4]" [audioState]="audioState[4]"
              (click)="listAudioClick('inside',page1List[4],4)"></app-audio-button>
            <div [ngClass]="{'activity-image':page1List[4].selected === true}" (click)="selectOption('page1',4)">
              <img src="{{ page1List[4].src }}" />
            </div>
          </div>
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page1List[5].selected === true}" (click)="selectOption('page1',5)">
              <img src="{{ page1List[5].src }}" />
            </div>
            <span font-bold mx-1>{{ page1List[5].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[5]" [audioState]="audioState[5]"
              (click)="listAudioClick('inside',page1List[5],5)"></app-audio-button>
          </div>
        </div>
      </div>
      <div class="desktop-design-show">
        <button *ngIf="isItemSelected" mat-fab color="primary" class="next-level next-level-button"
          [@flipAnimation]="completed" (click)="nextStage()">
          {{ pageData?.dictionary?.more }}
        </button>
      </div>
    </div>

    <div *ngIf="!firstSelectionStage" class="navigator-activity content-box">
      <div class="navigator-activity-two">
        <div class="desktop-design-show">
          <button mat-fab color="primary" class="next-level next-level-button" [@flipAnimation]="completed"
            (click)="previous()">
            {{ pageData?.dictionary?.back }}
          </button>
        </div>
        <div class="mobile-heading mobile-design-show" flex v-center justify-end>
          <button mat-fab class="next-level-button" color="primary" [@flipAnimation]="completed" (click)="previous()">
            {{ pageData?.dictionary?.back }}
          </button>
        </div>
        <div class="mobile-heading mobile-design-show right-arrow" flex v-center justify-end>
          <button [ngClass]="isItemSelected?'setDisplay':'deSelectDisplay'"  mat-fab color="primary" [@flipAnimation]="completed" (click)="next()">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
      <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutWrap="wrap" class="navigator-activity-two-main">
        <div fxFlex.gt-sm="30%" class="navigator-activity-block">
          <div class="work-activity">
            <span font-bold mx-1>{{ page2List[0].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[0]" [audioState]="audioState[0]"
              (click)="listAudioClick('inside',page2List[0],0)"></app-audio-button>
            <div [ngClass]="{'activity-image':page2List[0].selected === true}" (click)="selectOption('page2',0)">
              <img src="{{ page2List[0].src }}" />
            </div>
          </div>
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page2List[1].selected === true}" (click)="selectOption('page2',1)">
              <img src="{{ page2List[1].src }}" />
            </div>
            <span font-bold mx-1>{{ page2List[1].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[1]" [audioState]="audioState[1]"
              (click)="listAudioClick('inside',page2List[1],1)"></app-audio-button>
          </div>
          <div class="work-activity">
            <span font-bold mx-1>{{ page2List[2].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[2]" [audioState]="audioState[2]"
              (click)="listAudioClick('inside',page2List[2],2)"></app-audio-button>
            <div [ngClass]="{'activity-image':page2List[2].selected === true}" (click)="selectOption('page2',2)">
              <img src="{{ page2List[2].src }}" />
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="40%" class="navigator-activity-block">
          <div class="emoji-outer">
            <div class="activity-emoji">
              <img src="{{emoji}}" alt="profile-emoji" />
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="30%" class="navigator-activity-block">
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page2List[3].selected === true}" (click)="selectOption('page2',3)">
              <img src="{{ page2List[3].src }}" />
            </div>
            <span font-bold mx-1>{{ page2List[3].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[3]" [audioState]="audioState[3]"
              (click)="listAudioClick('inside',page2List[3],3)"></app-audio-button>
          </div>
          <div class="work-activity">
            <span font-bold mx-1>{{ page2List[4].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[4]" [audioState]="audioState[4]"
              (click)="listAudioClick('inside',page2List[4],4)"></app-audio-button>
            <div [ngClass]="{'activity-image':page2List[4].selected === true}" (click)="selectOption('page2',4)">
              <img src="{{ page2List[4].src }}" />
            </div>
          </div>
          <div class="work-activity">
            <div [ngClass]="{'activity-image':page2List[5].selected === true}" (click)="selectOption('page2',5)">
              <img src="{{ page2List[5].src }}" />
            </div>
            <span font-bold mx-1>{{ page2List[5].title }}</span>
            <app-audio-button [audionotfound]="audioNotFound[5]" [audioState]="audioState[5]"
              (click)="listAudioClick('inside',page2List[5],5)"></app-audio-button>
          </div>
        </div>
      </div>
      <div class="desktop-design-show">
        <button [ngClass]="isItemSelected?'setDisplay':'deSelectDisplay'" mat-fab color="primary" class="next-level" [@flipAnimation]="completed"
          (click)="next()">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>