import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ExplorerComponent } from './explorer-component';
import { StaticHeaderComponent } from '../../shared/common-header-footer/header.component';
import { StaticFooterComponent } from '../../shared/common-header-footer/footer.component';
import { ExplorerIntroComponent } from './intro/explorer-intro-component';
import { ExplorerChallengeComponent } from './challenge/challenge-component';
import { ExplorerTaskComponent } from './about-occupations-task/about-occupations-component';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ExplorerInformationComponent } from './information/explorer-information.component';


const routes: Routes = [{
  path: 'app/explorer', component: ExplorerComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: ExplorerIntroComponent },
    { path: 'information', component: ExplorerInformationComponent },
    { path: 'task', component: ExplorerTaskComponent },
    { path: 'challenge', component: ExplorerChallengeComponent }


  ]
}];
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    SharedModule.forRoot(),
    FlexLayoutModule
  ],

  declarations: [
    ExplorerComponent,
    ExplorerIntroComponent,
    ExplorerTaskComponent,
    ExplorerInformationComponent,
    ExplorerChallengeComponent
  ],
  providers: [],
  exports: [RouterModule]

})



export class ExplorerModule { }
