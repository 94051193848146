<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{scoutSharedTie.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="headeraudioClick('inside',scoutSharedTie)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="scout-activity scout-tie content-box">
       <!-- Scout: Self-Survery Tie process Start -->
      <h1 text-color="primary" class="activity-header">
        {{scoutSharedTie.subHeader}}
        <app-audio-button [audionotfound]="subHeaderAudionotfound" [audioState]="subHeaderAudioState"
          (click)="subheaderAudioClick('inside',scoutSharedTie)">
        </app-audio-button>
      </h1>
      <div fxLayout.gt-sm="row" fxLayoutWrap="wrap" fxLayoutAlign="center">
        <div fxFlex.gt-sm="20%" class="activity-cards"  pointer *ngFor="let category of categories;let inx = index;">
          <div class="self-survery-block" (click)="routeClick(category.title,inx)">
            <img src="{{assetsUrl}}{{category.icon}}.svg" alt="{{category.title}}">
            <h2 text-color="primary" mt-15 mb-0>{{category.title}}</h2>
          </div>
        </div>
        
      </div>
      </div>
      <!-- Scout: Self-Survery Tie process End -->
    </div>
  
</div>