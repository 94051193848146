import { Component, OnInit } from '@angular/core';
import { Next } from '../../../shared/common-animations/CT_animations';
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";
import {
  AttributeDataState,
  PageDataState,
  AudioState,
  ProfileDataState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { sample, takeWhile } from "rxjs/operators";
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-navigator-toptwo-groups',
  templateUrl: './navigator-toptwo-groups.component.html',
  animations: [Next]
})
export class NavigatorTopTwoGroupsComponent implements OnInit {
  completed = true;
  compActive = true;
  pageData: any;
  attributeData: any;
  pageCode: string;
  keywords: any;
  theme_name: string;
  valuesData: any;
  lang = "";
  overAllvalues: any;
  topTwoGroups = [];
  emoji: any;
  headerAudioState = "stoped";
  headerAudionotfound = false;
  curAudio: string;

  constructor(public router: Router,
    private store: Store<PageDataState>,
    private attrStore: Store<AttributeDataState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public activatedRoute: ActivatedRoute,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    public dialogService: DialogopenService,
    private profileStore: Store<ProfileDataState>,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_TOP" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/topTwoGroups") {
          if (e.type === "languageChanged") {
            this.lang = sessionStorage.getItem("session_token");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });

    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });
    this.profileStore
      .select("profileImage")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((profileState) => {
        if (profileState && profileState.profileImage) {
          try {
            const reader = new FileReader();
            reader.readAsDataURL(profileState.profileImage);
            reader.onloadend = () => {
              this.emoji = reader.result;
            };
          } catch (e) {
            this.utils.handleError(`navigator-toptwo-groups.component.ts constructor: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }

  ngOnInit() {
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.pageData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_TOP"
          );
          this.overAllvalues = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_ASSESS"
          );
        }
      });
    this.attributeDataCall();
    this.getTopTwoGroups();
    this.setNavigatorTopTwoTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }
  getTopTwoGroups() {
    this.dialogService.showLoading();
    this.apiModel.moduleName = 'navigator';
    this.apiModel.endUrl = 'user/values/results';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
        if (resp != undefined && resp != null) {
          const data = JSON.parse(resp);
          var topTwo = []
          data.forEach(element => {
            topTwo.push(element.value);
          });
          this.topTwoGroups = this.valuesData.filter(ele1 => topTwo.indexOf(ele1.name) != -1);
        }
        if (this.topTwoGroups.length > 0) {
          this.dialogService.hideLoading();
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-toptwo-groups.component.ts getTopTwoGroups: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  attributeDataCall() {
    this.oninitpopupservice.pageCallText(this.lang, "attributes/value");
    this.attrStore
      .select("attribute")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((res) => {
        const data = res.attribute;
        if (data[0]) {
          this.valuesData = [];
          this.valuesData = data.filter(val => val.type === 'value');
        }
      });
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_TOP");
      }.bind(this)
    );
  }

  navigateToResult() {
    this.router.navigate(["../results"], { relativeTo: this.activatedRoute });
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  /*end of logic  for buttons two show and to add those to array*/

  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setNavigatorTopTwoTitle(){
    this.titleService.setTitle("Navigator Top Two Values - CareerTrek");
  }
}