import { Injectable, OnDestroy } from '@angular/core';
import { DialogopenService } from '../common-modal/modalpopup.service';
import { EventdispatchService } from '../common-modal/eventdispach.service';
import { ApiCallClass } from '../../shared/common-services/api-call-model';
import { ApiCallService } from './api-call-service';
import { Store } from '@ngrx/store';
import { PageDataState, AttributeDataState } from '../../state-management/state/main-state';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Utilities } from './utilities.service';

@Injectable()
export class OnLoadPopUpService implements OnDestroy {
    respdatalanguage: any;
    subscription: Subscription;
    pagesText: Subscription;
    languageKey: any;

    pagesData: any;
    attriutesData: any;
    compActive: boolean = true;

    constructor(public dialogService: DialogopenService,
        private store: Store<PageDataState>,
        private attriutestore: Store<AttributeDataState>,
        private apiCall: ApiCallService,
        private apiJson: ApiCallClass,
        private apiJson1: ApiCallClass,
        private utils: Utilities,
        public eventService: EventdispatchService,
    ) {
        this.pageCallText(sessionStorage.getItem('session_token'), 'pages');
        this.pageCallText(sessionStorage.getItem('session_token'), 'attributes');

        this.pagesText = this.store.select('pages').subscribe((resp) => {
            this.pagesData = resp;
        });
        this.subscription = this.attriutestore.select('attribute').subscribe((res) => {
            this.attriutesData = res;
        });

        this.subscription = eventService.listen().subscribe((e) => {
            if (e.type === 'languageChanged') {
                this.pageCallText(sessionStorage.getItem('session_token'), 'pages');
                this.pageCallText(sessionStorage.getItem('session_token'), 'attributes');
                this.getOnLoadPopUp('GLOBAL_SETTINGS');
            }
        });
    }

    // Made changes in this method for separating launch popup and combined occ details with EXPLORER_IDEAS,EXPLORER_PEOPLE,EXPLORER_THINGS 
    getOnLoadPopUp(pageCode1, module?) {
        if (this.pagesData != undefined && this.pagesData != null) {
            const data = (this.pagesData.pages);
            let addPageOccData = [];
            let pageData: any;
            let pageDataExpDetailOcc: any;

            for (let i = 0; i < data.length; i++) {
                if (data[i].pageCode === 'GLOBAL_SETTINGS') {
                    sessionStorage.removeItem('GLOBAL_SETTINGS');
                    sessionStorage.setItem('GLOBAL_SETTINGS', JSON.stringify(data[i]));
                }
                if (data[i].pageCode === pageCode1 && pageCode1 !== 'GLOBAL_SETTINGS') {
                    if (data[i]) {
                        pageData = data[i];
                        addPageOccData.push(pageData.pageText);
                        //Added Module to this condition to remove console error for pageText change based on module
                        if (pageCode1 === 'CUSTOM_OCCUPATION_REPORT' || pageCode1 === 'CUSTOM_OCCUPATION' && module) {
                            pageData.pageText = pageData?.subModule[module]?.pageText
                        }
                        //Added Module to this condition to remove console error for pageText change based on module
                        if (pageCode1 === 'CUSTOM_OCCUPATION' && module) {
                            let pathfinderData = [];
                            let trekkerData = [];
                            let explorerData = [];
                            this.attriutesData.attribute.forEach(element => {
                                if (element.type === 'characteristic') {
                                    var desArr = element.description.split('.');
                                    explorerData.push({
                                        text: desArr[0],
                                        icon: element.icon,
                                    });
                                }
                                if (element.type === 'hollandcode') {
                                    var desArr = element.description.split('.');
                                    pathfinderData.push({
                                        text: desArr[0],
                                        icon: element.icon,
                                    });
                                }
                                if (element.type === 'skill') {
                                    // itemsData = [];
                                    var desArr = element.description.split('.');
                                    trekkerData.push({
                                        text: desArr[0],
                                        icon: element.icon,

                                    });
                                }
                            });
                            pageData.pageText = pageData?.subModule[module]?.pageText

                            if (module == 'pathfinder') {
                                pageData.pageText.expressions.items = pathfinderData
                                pageData['subMod'] = module
                            } else if (module == 'careertrekker') {
                                pageData.pageText.expressions.items = trekkerData
                            } else if (module == 'explorer') {
                                pageData.pageText.expressions.items = explorerData
                            }
                        }

                        if (pageCode1 === 'LIBRARY_PATHFINDER') {
                            if (this.attriutesData != undefined && this.attriutesData != null) {
                                let itemsData = [];
                                this.attriutesData.attribute.forEach(element => {
                                    if (element.type === 'hollandcode') {
                                        var desArr = element.description.split('.');
                                        itemsData.push({
                                            text: desArr[0],
                                            icon: element.icon,
                                        });
                                    }
                                });
                                itemsData.sort((a, b) => a.text.localeCompare(b.text));
                                pageData["module"] = "library_pathfinder"
                                pageData.pageText.expressions.items = itemsData;
                            }
                        }
                    }
                }
                if (data[i].pageCode === 'EXPLORER_DETAIL_OCC') {
                    if (pageCode1 === 'EXPLORER_IDEAS' || pageCode1 === 'EXPLORER_PEOPLE' || pageCode1 === 'EXPLORER_THINGS') {
                        pageDataExpDetailOcc = data[i];
                    }
                }
            }
            if (pageDataExpDetailOcc) {
                addPageOccData.push(pageDataExpDetailOcc.pageText);
                pageData.pageText["helper"] = addPageOccData;
            }
            pageCode1 = '';
            this.popUpDateCall(pageData);
        }

    }

    popUpDateCall(data) {
        if (data) {
            sessionStorage.removeItem('backButtonPage');
            if (data.backButtonPage) {
                sessionStorage.setItem('backButtonPage', data.backButtonPage);
            }
            if (JSON.parse(sessionStorage.getItem('infoPopupState')) === true ||
                sessionStorage.getItem('popupactive') === 'true' ||
                sessionStorage.getItem('popupType') === 'help') {
                sessionStorage.setItem('popupactive', 'false');
                sessionStorage.setItem('isModalActive', 'true');

                this.dialogService.commonPopup(data);
                //after closing help popup doing modal active false
                this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
                    sessionStorage.setItem('isModalActive', 'false');
                });
            }
        }
    }
    getKeyWordData(pageCode1): any {
        //This function may return multiple types of objects based on the parameters that is the reason we placed return types as any.
        let value = [];
        this.pagesText = this.store.select('pages').subscribe((resp) => {
            if (resp.pages != undefined && resp.pages[0] != null) {
                const data = resp.pages;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].pageCode == pageCode1) {
                        sessionStorage.removeItem('backButtonPage');
                        if (data[i]) {
                            if (data[i].backButtonPage) {
                                sessionStorage.setItem('backButtonPage', data[i].backButtonPage);
                            }
                            if (pageCode1 === 'EXPLORER_PEOPLE' || pageCode1 === 'EXPLORER_IDEAS' || pageCode1 === 'EXPLORER_THINGS') {
                                sessionStorage.setItem(pageCode1, data[i]);
                            } else {
                                sessionStorage.setItem(pageCode1, JSON.stringify(data[i]));
                            }
                            value = data[i];
                            pageCode1 = '';
                            break;
                        }
                    }
                }
            }
        });
        return value;
    }
    pageCallText(token, common_api) {
        let type;
        try {
            if (common_api == 'pages') {
                type = 'PAGES_PROFILE_TEXT';

            } else {
                type = 'ATTRIBUTES_TEXT';
            }
            this.store.dispatch({
                type: type, payload: {
                    methodVal: 'GET', module_Name: common_api,
                    path_params: [], query_params: {}, sessionID: token,
                    body_Params: {}, endUrlVal: common_api
                }
            });

        } catch (e) {
            this.utils.handleError(`onloadpopup.services.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }
    ngOnDestroy() {
        this.pagesText.unsubscribe();
        this.subscription.unsubscribe();
        this.compActive = false;
    }
}
