/**Import angular core packages */
import {
  Component,
  OnInit,
  Input,
  Inject,
  OnDestroy,
  HostListener,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DialogopenService } from "../../shared/common-modal/modalpopup.service";
import {
  AttributesState,
  AudioState,
  CategoriesState,
  completionListState,
  ExplorerOccsViewedCareerStore,
  ExplorerOccsViewedListStore,
  NavigatorOccViewedCareerState,
  NavigatorOccViewedState,
  OccsDataState,
  OwnOccListState,
  PageDataState,
  PathwayOccViewedCareerState,
  PathwayOccViewedState,
  ScoutOccViewedCareerState,
  ScoutOccViewedState,
  TrekkerOccViewedCareerState,
  TrekkerOccViewedState,
} from "../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { OnLoadPopUpService } from "../../shared/common-services/onloadpopup.service";
import { ApiCallService } from "../../shared/common-services/api-call-service";
import { ApiCallClass } from "../../shared/common-services/api-call-model";

import { StoreService } from "../../state-management/services/store-service";
import { EventdispatchService } from "../../shared/common-modal/eventdispach.service";
import { Router, ActivatedRoute } from "@angular/router";
import { RouteUrls } from "../../shared/common-constants/route.constants";
import { environment } from "../../../environments/environment";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTooltip } from "@angular/material/tooltip";
import { Subscription } from "rxjs";
import { Utilities } from "../../shared/common-services/utilities.service";
import { AudioService } from "../../shared/common-constants/audioLoading.service";
import { Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-explorer",
  templateUrl: "./explore-occs-layout.html",
})
export class ExploreOccupationsComponent implements OnInit, OnDestroy {
  allOption; //To store the value of 'all' in eng/spanish so that on lang change 'all' can be shown per lang choosen. This is needed because except the option name 'all' other three names we GET from 'occs/categories' API call.
  CreateOwnOccs: any = [];
  initialValue = true;
  forFirstTime = true;
  careerTrekkerComp: any = [];
  quickpicCompletion: any = [];
  pathFinderComp: any = [];
  ideaScores = false;
  peopleScores = false;
  thingsScores = false;
  UserDetails: any = [];
  theme_Color_num: number;
  occCardAPIUrl: string;
  explorerPopup = true;
  pathfinderPopup = true;
  careerTrekkerPopup = true;
  scoutPopup = true;
  navigatorPopup = true;
  @Input() ariaLabelledby = "materialtag";
  tabSelectedIndex: number;
  tabnames: void;
  filterreturnData: any;
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  languageKey: any;
  tabview: true;
  filterdata: any;
  thumbFalse = [];
  thumbTrue = [];
  selector2 = ".infinte-scroll-height-mobile";
  //Initalizing filterTumb with all to show all careers
  filterThumb = "all";
  favourate = { occs: { evaluated: [] } };
  tabname = "group";
  onload: String = "first";
  attributesData: any;
  weightarray2 = []; // For careerTrekker saving prev weight
  modalarray2 = []; // For careerTrekker saving present weight according to html
  pathfinderfilterarray = [];
  scoutfilterarray = [];
  navigatorFilterArray = [];
  unmodifieddata = [];
  finalSortFilter = [];
  prevWeight = "";
  respdata = [];
  careertrekker: any;
  pathcheck = "true";
  pathfinder: any;
  explorer: any;
  scout: any;
  navigatorData: any;
  modalarray = []; // For explorer saving present weight according to html
  weightarray = []; // For explorer saving prev weight
  occgroup2 = 0; // only for the purpose of html default all condition
  resp: any;
  filterArray = [];
  occgroup = 0;
  canScroll: boolean;
  selector = ".infinet-scroll-height";
  i = 0;
  j = 0;
  list = ["All", "High", "Medium", "Low"];
  list1: any = [];
  list2; //Made it global as the options name array values are set in a separate method now.
  arr = ["1"];
  cards = [];
  occList;
  @ViewChildren("tooltip") manualTooltip: QueryList<MatTooltip>;
  path = 0; // for counting the length of pathfinders filter loop
  themeColor = "scout";
  module = "All";
  assetsURL: string;
  theme_name: string;
  colors: any = [];
  commonLibraryHeading: any;
  deviceRes: string;
  occItem; // for storing the occ when the occCard is clicked in careerLibrary
  occPayload; // for storing the thumb state on clicking the thumb
  viewedCareerList = [];
  filteredData = [];
  changeTheme(event) {
    this.themeColor = event;
  }
  compActive = true;
  audioState = "stoped";
  audionotfound = false;
  instructionText: any;
  valueInTheTextBox: string;
  customInput: Subject<string> = new Subject();
  saveThoughtBoxText: any = [];
  moduleName: string;
  scoutTitleName: string;
  images = ['EXPLORER_IDEAS', 'EXPLORER_PEOPLE', 'EXPLORER_THINGS'];
  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    private store: Store<OccsDataState>,
    private store1: Store<PageDataState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private attributeStore: Store<AttributesState>,
    private categoriesStore: Store<CategoriesState>,
    private moduleCompletionstore: Store<completionListState>,
    private explorerOccsViewedListStore: Store<ExplorerOccsViewedListStore>,
    private pathfinderOccsViewedListStore: Store<PathwayOccViewedState>,
    private trekkerOccsViewedListStore: Store<TrekkerOccViewedState>,
    private scoutOccsViewedListStore: Store<ScoutOccViewedState>,
    private navigatorOccsViewedListStore: Store<NavigatorOccViewedState>,

    private explorerOccsViewedCareerStore: Store<ExplorerOccsViewedCareerStore>,
    private pathfinderOccsViewedCareerStore: Store<PathwayOccViewedCareerState>,
    private trekkerOccsViewedCareerStore: Store<TrekkerOccViewedCareerState>,
    private scoutOccsViewedCareerStore: Store<ScoutOccViewedCareerState>,
    private navigatorOccsViewedCareerStore: Store<NavigatorOccViewedCareerState>,

    private utils: Utilities,
    public eventService: EventdispatchService,
    private router: Router,
    private oninitpopupservice: OnLoadPopUpService,
    private storeService: StoreService,
    private ownOccstore: Store<OwnOccListState>,
    public snackBar: MatSnackBar,
    @Inject("OCC_CARD_URL") private occCardUrl: string,
    @Inject("ASSETS_MEDIA_URL") private assetsUrl: string,
    private titleService: Title
  ) {
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.tabnames = JSON.parse(sessionStorage.getItem("librarytabnames"));
    this.occCardAPIUrl = occCardUrl;
    this.assetsURL = assetsUrl;
    this.colors = environment.THEME_COLORS;
    this.customInput.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(value => {
      if (value && value.trim()) {
        this.saveThoughtBox(value);
      }
    });
    this.attributeStore.dispatch({
      type: "GET_ATTRIBUTES_DATA",
      payload: {
        methodVal: "GET",
        module_Name: "explorer",
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem("session_token"),
        body_Params: {},
        endUrlVal: "attributes",
      },
    });
    this.categoriesStore.dispatch({
      type: "GET_CATEGORIES_DATA",
      payload: {
        methodVal: "GET",
        module_Name: "explorer",
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem("session_token"),
        body_Params: {},
        endUrlVal: "occs/categories",
      },
    });
    this.store1.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "CAREER_LIBRARY", pageCode: "LIBRARY_CAREER" },
    });
    
    // Created store for getting the own occ list
    this.store.dispatch({
      type: "GET_OWN_OCC_TEXT",
      payload: {
        methodVal: "GET",
        module_Name: "explorer",
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem("session_token"),
        body_Params: {},
        endUrlVal: "/user/custom-occs/list",
      },
    });
    this.occList = JSON.parse(window.sessionStorage.getItem("occupationList")); // Here, initially we will get all OccupationList and we will assign it to respdata.
    this.respdata = JSON.parse(this.occList.occs);
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.module = params["module"];
        this.deviceRes = params["deviceRes"];
        if (this.module === "All") {
          sessionStorage.setItem("theme_color", this.colors[3]);
          this.moduleName = 'CareerLibrary';
          this.store.dispatch({
            type: "TRIGGER_TEXT_CHANGE_EVENT",
            payload: { module: "CAREER_LIBRARY", pageCode: "LIBRARY_CAREER" },
          });
        } else if (this.module === "Explorer") {
          sessionStorage.setItem("theme_color", this.colors[0]);
          this.moduleName = this.module;
          this.store.dispatch({
            type: "TRIGGER_TEXT_CHANGE_EVENT",
            payload: { module: "EXPLORER", pageCode: "LIBRARY_EXPLORER" },
          });
        } else if (this.module === "Pathfinder") {
          sessionStorage.setItem("theme_color", this.colors[1]);
          this.moduleName = this.module;
          this.store.dispatch({
            type: "TRIGGER_TEXT_CHANGE_EVENT",
            payload: { module: "PATHFINDER", pageCode: "LIBRARY_PATHFINDER" },
          });
        } else if (this.module === "Scout") {
          this.moduleName = this.module;
          sessionStorage.setItem("theme_color", this.colors[7]);
        } else if (this.module === "Navigator") {
          this.moduleName = this.module;
          sessionStorage.setItem("theme_color", this.colors[8]);
          this.store.dispatch({
            type: "TRIGGER_TEXT_CHANGE_EVENT",
            payload: { module: "NAVIGATOR", pageCode: "LIBRARY_NAVIGATOR" },
          });
        } else {
          sessionStorage.setItem("theme_color", this.colors[2]);
          this.moduleName = 'Trekker';
          this.store.dispatch({
            type: "TRIGGER_TEXT_CHANGE_EVENT",
            payload: { module: "CAREER_TREKKER", pageCode: "LIBRARY_TREKKER" },
          });
        }
        const event = document.createEvent("CustomEvent");
        event.initEvent("themeChanged", true, true);
        this.eventService.dispatch(event);
        sessionStorage.setItem('isModalActive', 'false');
      });
    this.getProfileData();
    this.getAttribitesCategoriesData();
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") == "/app/library-occupations") {
          if (e.type === "languageChanged") {
            this.explorerPopup = true;
            this.pathfinderPopup = true;
            this.careerTrekkerPopup = true;
            this.scoutPopup = true;
            this.navigatorPopup = true;
            this.languageKey = this.resp[0].language;
            this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
            this.getInstructionalText();

            if (
              sessionStorage.getItem("OccIndexReducerText") != null &&
              sessionStorage.getItem("OccIndexReducerText") != undefined
            ) {
              this.attributesData = null;
              this.dataforParticular(this.tabname);
              const occData = JSON.parse(
                sessionStorage.getItem("OccIndexReducerText")
              );
              this.resp = JSON.parse(occData.commonText);
              this.respdata = JSON.parse(occData.commonText);
              this.unmodifieddata = JSON.parse(occData.commonText);
              this.showData();
            } else {
              this.occsCallText();
            }
            this.getSaveThoughtBoxValue(this.moduleName);
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") === "true") {
              if (this.tabname === "characteristic") {
                this.explorerPopup = true;
              } else if (this.tabname === "hollandcode") {
                this.pathfinderPopup = true;
              } else if (this.tabname === "skill") {
                this.careerTrekkerPopup = true;
              } else if (this.tabname === "group") {
                this.scoutPopup = true;
              }
              else if (this.tabname === "value") {
                this.navigatorPopup = true;
              }
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
        }
      });
    //listening to the customeEvent from explorer-occupation-popup thumbs clicks
    this.eventService
      .listenThumbUpEvent()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        this.occPayload = e.detail;
        if (this.occPayload) {
          this.thumbEvntMethod();
        }
      });
    this.occsCallText();
    /* start for getting the occs data */
    this.explorerOccsViewedListStore.dispatch({
      type: 'GET_EXPLORER_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'explorer ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/explorer '
      }
    });
    this.pathfinderOccsViewedListStore.dispatch({
      type: 'GET_PATHWAY_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'pathfinder ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/pathfinder '
      }
    });
    this.trekkerOccsViewedListStore.dispatch({
      type: 'GET_TREKER_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'trekker ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/trekker '
      }
    });
    this.scoutOccsViewedListStore.dispatch({
      type: 'GET_SCOUT_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'scout ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/scout '
      }
    });
    this.navigatorOccsViewedListStore.dispatch({
      type: 'GET_NAVIGATOR_OCC_VIEWED_LIST', payload: {
        methodVal: 'GET', module_Name: 'navigator ',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/occ/viewed/list/navigator '
      }
    });
    /*end for getting the occs data */

    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    this.getSaveThoughtBoxValue(this.moduleName);
    this.getViewedCareerList(this.moduleName);

  }

  getSaveThoughtBoxValue(moduleName) {
    this.apiModel.moduleName = 'newThoughtsGet';
    this.apiModel.endUrl = 'user/Thoughts/' + moduleName;
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
        if (resp) {
          this.valueInTheTextBox = JSON.parse(resp).thoughts;
        }
      });
    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts getSaveThoughtBoxValue: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  saveThoughtBox(text) {
    let textValue = [];
    textValue.push(text);
    this.apiModel.moduleName = 'newThoughts';
    this.apiModel.endUrl = 'user/Thoughts/' + this.moduleName;
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'POST';
    this.apiModel.data = textValue;
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
        if (resp) {
          this.snackBar.open(resp, '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        }
      });
    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts saveThoughtBox: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  updateTheText($event: string) {
    this.customInput.next($event);
  }
  //The images in the cards take more time than any other api call occuring in this component so the below method triggers when all the cards a populated with the images from the server. Thus it will not give access to the user till all the images are loaded.So, commenting the show and hide loading in other places.
  hideLoadingSpinner() {
    this.dialogService.hideLoading();
  }
  infopopup() {
    this.stopAudio();
    if (
      this.module === "Explorer" ||
      (this.module === "All" && this.tabname === "characteristic")
    ) {
      if (this.explorerPopup === true) {
        this.oninitpopupservice.getOnLoadPopUp("LIBRARY_EXPLORER");
        this.explorerPopup = false;
      }
    } else if (
      this.module === "Pathfinder" ||
      (this.module === "All" && this.tabname === "hollandcode")
    ) {
      if (this.pathfinderPopup === true) {
        this.oninitpopupservice.getOnLoadPopUp("LIBRARY_PATHFINDER");
        this.pathfinderPopup = false;
      }
    } else if (
      this.module === "Careertrekker" ||
      (this.module === "All" && this.tabname === "skill")
    ) {
      if (this.careerTrekkerPopup === true) {
        this.oninitpopupservice.getOnLoadPopUp("LIBRARY_TREKKER");
        this.careerTrekkerPopup = false;
      }
    } else if (
      this.module === "scout" ||
      (this.module === "All" && this.tabname === "group")
    ) {
      if (this.scoutPopup === true) {
        this.oninitpopupservice.getOnLoadPopUp("LIBRARY_SCOUT");
        this.scoutPopup = false;
      }
    }
    else if (
      this.module === "Navigator" ||
      (this.module === "All" && this.tabname === "value")
    ) {
      if (this.navigatorPopup === true) {
        this.oninitpopupservice.getOnLoadPopUp("LIBRARY_NAVIGATOR");
        this.navigatorPopup = false;
      }
    }
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  occsCallText() {
    try {
      //Removed the store subscription and hitting the API directly to load the images appropriately on Language change
      let resp;
      this.apiModel.method = "GET";
      this.apiModel.moduleName = "explorer";
      this.apiModel.endUrl = "occs";
      this.apiModel.sessionID = sessionStorage.getItem("session_token");
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((data) => {
          resp = JSON.parse(data[0]);
          if (resp) {
            this.resp = resp;
            this.unmodifieddata = resp;
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.tabnames = JSON.parse(
              sessionStorage.getItem("librarytabnames")
            );
            //'dictionaryObj' and for in loop to set the value to 'all' option with 'selectAll' value from dictionary of Global_Settings on language change.
            const dictionaryObj = this.keywords.dictionary;
            for (let key in dictionaryObj) {
              if (key === "selectAll") {
                this.allOption = dictionaryObj[key];
              }
            }
            this.optionNamesMethod();
            this.ngOnInit();
            this.getAttribitesCategoriesData();
            this.respdata = this.resp;
            this.showData();
          }
        });

      this.respdata = this.resp; //By adding this line all careers will not be shown initially in Trekker, Pathfinder Career Libraries.
      const val = JSON.parse(window.sessionStorage.getItem("occupationList"));
    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts occsCallText: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  getAttribitesCategoriesData() {
    this.apiModel.moduleName = "explorer";
    this.apiModel.endUrl = "attributes";
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            this.attributesData = JSON.parse(resp);
            this.dataforParticular(this.tabname); //this is called here as it is supposed to be called after receiving the respons from attributes api call. If not here then on lang change the text change was not happening.
          }
        });

    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts getAttribitesCategoriesData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
    this.apiModel.endUrl = "occs/categories";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((respdata) => {
          if (respdata) {
            this.list2 = JSON.parse(respdata);
            this.optionNamesMethod();
          }
        });
    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  //The below method for viewing the options names in the ui per the language choosen i:e eng/spanish
  optionNamesMethod() {
    if (this.list2 && this.list2.length) {
      this.list1 = [];
      this.list1.push({
        categoryId: 0,
        title: this.allOption,
      });
      for (let i = 0; i < this.list2.length; i++) {
        this.list1.push({
          categoryId: this.list2[i].categoryId,
          title: this.list2[i].title,
        });
      }
    }
  }

  dataforParticular(sessionname) {
    if (this.attributesData) {
      const resp = this.attributesData.filter((val) => val.type == sessionname);
      if (sessionname === "characteristic") {
        this.explorer = resp;
        this.modalarray.length = this.explorer.length;
        this.filterArray.length = this.explorer.length;

        for (let i = 0; i < this.modalarray.length; i++) {
          this.modalarray[i] = "All";
          this.filterArray[i] = "";
        }
        this.weightarray = this.modalarray;
        this.respdata = this.resp;
        this.showData();
        this.explorerClick("All", this.explorer[0].name, 0);
        if (this.module == "Explorer") {
          this.changeTheme("explorer");
        }
        if (this.explorer[0] != undefined && this.onload == "first") {
          this.onload = "";
        }
      } else if (sessionname === "group") {
        this.scout = resp;
        this.scoutfilterarray = []; // for reassigning the data

        this.modalarray.length = this.scout.length;
        for (let i = 0; i < this.modalarray.length; i++) {
          this.scoutfilterarray.push(this.scout[i].title);
          this.modalarray[i] = "";
          this.filterArray[i] = "";
          if (this.scout) {
            this.scout.forEach((element, inx) => {
              element["icon"] = this.images[inx];
            });
          }
        }
        for (let i = 0; i < this.modalarray.length; i++) {
          this.modalarray[i] = "All";
          this.filterArray[i] = "";
          this.scoutClick(this.scout[i].title, i);
        }
        if (this.scout[0] != undefined && this.onload == "first") {
          this.onload = "";
        }
        this.respdata = this.resp;
        this.showData();
      } else if (sessionname === "hollandcode") {
        this.pathfinder = resp;
        this.pathfinderfilterarray = []; // for reassigning the data
        this.pathcheck = "true";
        this.modalarray = [];
        this.modalarray.length = this.pathfinder.length;
        this.filterArray.length = this.pathfinder.length;
        if (
          sessionStorage.getItem("pathfinderlike") != undefined &&
          sessionStorage.getItem("pathfinderlike") != null &&
          sessionStorage.getItem("pathfinderlike") != "" &&
          this.module == "Pathfinder"
        ) {
          this.changeTheme("pathfinder");
          const like = JSON.parse(sessionStorage.getItem("pathfinderlike"));
          for (let i = 0; i < this.modalarray.length; i++) {
            this.pathfinderfilterarray.push(this.pathfinder[i].hollandCode);
            this.modalarray[i] = "";
            this.filterArray[i] = "";
          }
          for (let i = 0; i < this.modalarray.length; i++) {
            if (like.indexOf(this.pathfinder[i].name) >= 0) {
              this.modalarray[i] = "All";
              this.pathFinder(this.pathfinder[i].hollandCode, i);
            }
          }
        } else {
          for (let i = 0; i < this.modalarray.length; i++) {
            this.modalarray[i] = "All";
            this.filterArray[i] = "";
          }
          this.respdata = this.resp;
          this.showData();
        }
        this.weightarray = this.modalarray;
        if (this.pathfinder[0] != undefined && this.onload == "first") {
          this.onload = "";
        }
      }
      else if (sessionname === "value") {
        this.changeTheme("navigator");
        this.navigatorData = resp;
        this.navigatorFilterArray = []; // for reassigning the data

        this.modalarray.length = this.navigatorData.length;
        for (let i = 0; i < this.modalarray.length; i++) {
          this.navigatorFilterArray.push(this.navigatorData[i].title);
          this.modalarray[i] = "";
          this.filterArray[i] = "";
        }
        for (let i = 0; i < this.modalarray.length; i++) {
          this.modalarray[i] = "All";
          this.filterArray[i] = "";
          this.navigatorClick(this.navigatorData[i].title, i);
        }
        if (this.navigatorData[0] != undefined && this.onload == "first") {
          this.onload = "";
        }
        this.respdata = this.resp;
        this.showData();
      }
      else if (sessionname === "skill") {
        this.careertrekker = resp;
        this.modalarray2.length = this.careertrekker.length;
        this.filterArray.length = this.careertrekker.length;
        if (
          sessionStorage.getItem("Careertrekkerlike") != undefined &&
          sessionStorage.getItem("Careertrekkerlike") != null &&
          sessionStorage.getItem("Careertrekkerlike") != "" &&
          this.module == "Careertrekker"
        ) {
          this.changeTheme("careertrekker");
          const likearray = JSON.parse(
            sessionStorage.getItem("Careertrekkerlike")
          );
          const like = [likearray[0].name, likearray[1].name];
          for (let i = 0; i < this.modalarray2.length; i++) {
            this.modalarray2[i] = "All";
            this.weightarray2[i] = "All";
            this.filterArray[i] = "";
          }
          for (let i = 0; i < this.careertrekker.length; i++) {
            if (like.indexOf(this.careertrekker[i].name) >= 0) {
              this.modalarray2[i] = "High";
              this.weightarray2[i] = "High";
              this.careerTrekker("High", this.careertrekker[i].name, i);
            }
          }
        } else {
          for (let i = 0; i < this.modalarray2.length; i++) {
            this.modalarray2[i] = "All";
            this.filterArray[i] = "";
          }
          this.respdata = this.resp;
          this.showData();
        }
        this.careerTrekker(this.modalarray2[0], this.careertrekker[0].name, 0);
        if (this.careertrekker[0] != undefined && this.onload == "first") {
          this.onload = "";
        }
      }
    }
  }

  ngOnInit() {
    //As the images in the cards to load take more time than any other api call response in this component so showing the loading spinner on component birth.
    this.dialogService.showLoading();
    this.ownOccstore
      .select("ownOccList")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((ownOccListResp) => {
        if (ownOccListResp && ownOccListResp.ownOccList.length > 0) {
          // If we check ownOccListResp.ownOccList.length > 0 then Career Creator modal will have created careers after completing challenge of component like Pathfinder.
          try {
            this.CreateOwnOccs = ownOccListResp.ownOccList;
          } catch (e) {
            this.utils.handleError(`explore-occs-component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });

    /* While Selecting the tab, choosing the modal and closing it. Again, In same tab  choosing other 
  modal the attributes values changing i.e, explorer modules are displaying always. So, based on forFirstTime
   val executing code only once*/
    this.getInstructionalText();

    /* Here, ngoninit calling multiple times so tabs are not being consistent i.e while clicking on tab and
     selecting the model then cancelling, the control was navigating to intial tab. so based on the 'initialValue'
     executing the below code */

    if (this.initialValue === true) {
      if (this.tabname === "group") {
        this.tabSelectedIndex = 0;
      }
      else if (this.tabname === "value") {
        this.tabSelectedIndex = 1;
      } else if (this.tabname === "characteristic") {
        this.tabSelectedIndex = 2;
      } else if (this.tabname === "hollandcode") {
        this.tabSelectedIndex = 3;
      } else {
        this.tabSelectedIndex = 4;
      }
    }
    this.initialValue = false;
    this.userCompletionDetails();
    this.setOccupationLibraryTitle(this.moduleName);
  }

  getInstructionalText() {
    this.forFirstTime = true;
    this.commonLibraryHeading =
      this.oninitpopupservice.getKeyWordData("LIBRARY_CAREER");
    if (this.forFirstTime === true) {
      if (this.module === "All") {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_CAREER");
        this.dataforParticular(this.tabname);
      } else if (this.module === "scout") {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_SCOUT");
        this.dataforParticular("group");
        this.tabname = "group";
      } else if (this.module === "Explorer") {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_EXPLORER");
        this.dataforParticular("characteristic");
        this.tabname = "characteristic";
      } else if (this.module === "Pathfinder") {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_PATHFINDER");
        this.dataforParticular("hollandcode");
        this.tabname = "hollandcode";
      }
      else if (this.module === "Navigator") {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_NAVIGATOR");
        this.dataforParticular("value");
        this.tabname = "value";
      } else {
        this.instructionText =
          this.oninitpopupservice.getKeyWordData("LIBRARY_TREKKER");
        this.dataforParticular("skill");
        this.tabname = "skill";
      }
    }
    this.forFirstTime = false;
  }

  getViewedCareerList(moduleName) {
    switch (moduleName) {
      case 'Explorer': {
        this.explorerOccsViewedCareerStore.select('explorerOccsViewedCareer').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata["explorerOccsViewedCareer"]) {
            // this.dialogService.hideLoading();
          }
        });
        this.explorerOccsViewedListStore.select('explorerOccsViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata.explorerOccsViewedList.modules.EXPLORER.occs.reviewed) {
            this.viewedCareerList = respdata.explorerOccsViewedList.modules.EXPLORER.occs.reviewed;
          }
        });
        break;
      }
      case 'Pathfinder': {
        this.pathfinderOccsViewedCareerStore.select('pathwayOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe();
        this.pathfinderOccsViewedListStore.select('pathwayOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata.pathwayOccViewedList.modules.PATHFINDER.occs.reviewed) {
            this.viewedCareerList = respdata.pathwayOccViewedList.modules.PATHFINDER.occs.reviewed;
          }
        });
        break;
      }
      case 'Trekker': {
        this.trekkerOccsViewedCareerStore.select('trekkerOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe();
        this.trekkerOccsViewedListStore.select('trekkerOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata.trekkerOccViewedList.modules.TREKKER.occs.reviewed) {
            this.viewedCareerList = respdata.trekkerOccViewedList.modules.TREKKER.occs.reviewed;
          }
        });
        break;
      }
      case 'Scout': {
        this.scoutOccsViewedCareerStore.select('scoutOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe();
        this.scoutOccsViewedListStore.select('scoutOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata.scoutOccViewedList.modules.SCOUT.occs.reviewed) {
            this.viewedCareerList = respdata.scoutOccViewedList.modules.SCOUT.occs.reviewed;
          }
        });
        break;
      }
      case 'Navigator': {
        this.navigatorOccsViewedCareerStore.select('navigatorOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe();
        this.navigatorOccsViewedListStore.select('navigatorOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
          if (respdata.navigatorOccViewedList.modules.NAVIGATOR.occs.reviewed) {
            this.viewedCareerList = respdata.navigatorOccViewedList.modules.NAVIGATOR.occs.reviewed;
          }
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  postViewedCareer(moduleName, item) {
    switch (moduleName) {
      case 'Explorer': {
        this.explorerOccsViewedCareerStore.dispatch({
          type: 'GET_EXPLORER_OCC_VIEWED_CAREER', payload: {
            methodVal: 'PUT', module_Name: 'explorer',
            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
            body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/explorer'
          }
        });
        break;
      }
      case 'Pathfinder': {
        this.pathfinderOccsViewedCareerStore.dispatch({
          type: 'GET_PATHFINDER_OCC_VIEWED_CAREER', payload: {
            methodVal: 'PUT', module_Name: 'pathfinder',
            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
            body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/pathfinder'
          }
        });
        break;
      }
      case 'Trekker': {
        this.trekkerOccsViewedCareerStore.dispatch({
          type: 'GET_TREKKER_OCC_VIEWED_CAREER', payload: {
            methodVal: 'PUT', module_Name: 'trekker',
            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
            body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/trekker'
          }
        });
        break;
      }
      case 'Scout': {
        this.scoutOccsViewedCareerStore.dispatch({
          type: 'GET_SCOUT_OCC_VIEWED_CAREER', payload: {
            methodVal: 'PUT', module_Name: 'scout',
            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
            body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/scout'
          }
        });
        break;
      }
      case 'Navigator': {
        this.navigatorOccsViewedCareerStore.dispatch({
          type: 'GET_NAVIGATOR_OCC_VIEWED_CAREER', payload: {
            methodVal: 'PUT', module_Name: 'navigator',
            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
            body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/navigator'
          }
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  userCompletionDetails() {
    this.moduleCompletionstore
      .select("completionList")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((moduleCompListResp) => {
        if (moduleCompListResp && moduleCompListResp.completionList) {
          try {
            this.UserDetails = moduleCompListResp.completionList;
            if (this.UserDetails) {
              const data =
                this.UserDetails.modules.assessments.EXPLORER.secondary.data;
              if (data.length >= 1 && data[0] !== undefined) {
                for (let i = 0; i < data.length; i++) {
                  if (
                    data[i].name == "EXPLORER_IDEAS" &&
                    this.ideaScores == false &&
                    data[i].completions.length > 0
                  ) {
                    this.ideaScores = true;
                  } else if (
                    data[i].name == "EXPLORER_PEOPLE" &&
                    this.peopleScores == false &&
                    data[i].completions.length > 0
                  ) {
                    this.peopleScores = true;
                  } else if (
                    data[i].name == "EXPLORER_THINGS" &&
                    this.thingsScores == false &&
                    data[i].completions.length > 0
                  ) {
                    this.thingsScores = true;
                  } else if (
                    this.ideaScores == false &&
                    this.peopleScores == false &&
                    this.thingsScores == false
                  ) {
                    break;
                  }
                }
              }
              this.pathFinderComp =
                this.UserDetails.modules.assessments.PATHFINDER.completions;
              this.careerTrekkerComp =
                this.UserDetails.modules.assessments.TREKKER.completions;
              this.quickpicCompletion =
                this.UserDetails.modules.assessments.QUICKPIC.completions;
              if (
                this.UserDetails &&
                this.UserDetails.user &&
                this.UserDetails.user.accessibility
              ) {
                sessionStorage.setItem(
                  "infoPopupState",
                  JSON.stringify(this.UserDetails.user.accessibility)
                );
              }
            }
          } catch (e) {
            this.utils.handleError(`explore-occs-component.ts userCompletionDetails: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  showData() {
    this.cards = [];
    if (this.respdata && this.respdata.length > 0) {
      for (let i = 0; i < 60; i++) {
        // The below condition for making sure that empty is not pushed when the items in array are less than the looping value i:e 60 as this method is called multiple times and there are chances of getting values in the "respdata" less than the looping value.
        if (this.respdata[i] != null) {
          if (this.cards.indexOf(this.respdata[i]) < 0) {
            const occIndex = this.viewedCareerList.findIndex(x => x.occId === this.respdata[i].occId);
            if (occIndex != -1) {
              this.respdata[i]['viewed'] = true;
            } else {
              this.respdata[i]['viewed'] = false;
            }
            this.cards.push(this.respdata[i]);
          }
        }
      }
      this.j = this.cards.length;
      this.onScroll();
    }
  }
  getProfileData() {
    // For adjusting the scroll to initial state when we change the data in view mode
    window.scroll(0, 0);

    this.apiModel.moduleName = "explorer";
    this.apiModel.endUrl = "user/profile";
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          const data = JSON.parse(resp);
          this.favourate = data;
          this.thumbFalse = [];
          this.thumbTrue = [];
          for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
            if (this.favourate.occs.evaluated[j].like == true) {
              this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
            } else if (this.favourate.occs.evaluated[j].like == false) {
              this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
            }
          }
          this.showData();
        });
    } catch (e) {
      this.utils.handleError(`explore-occs-component.ts getProfileData3: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  @HostListener("scrollHandler", [])
  scrollHandler() {
    this.manualTooltip.forEach((ele, inx) => ele.hide());
  }

  tabClick(url) {
    if (url == "group") {
      this.tabSelectedIndex = 0;
      this.instructionText =
        this.oninitpopupservice.getKeyWordData("LIBRARY_SCOUT");
    } else if (url == "value") {
      this.tabSelectedIndex = 1;
      this.instructionText =
        this.oninitpopupservice.getKeyWordData("LIBRARY_NAVIGATOR");
    } else if (url == "characteristic") {
      this.tabSelectedIndex = 2;
      this.instructionText =
        this.oninitpopupservice.getKeyWordData("LIBRARY_EXPLORER");
    } else if (url == "hollandcode") {
      this.tabSelectedIndex = 3;
      this.instructionText =
        this.oninitpopupservice.getKeyWordData("LIBRARY_PATHFINDER");
    } else if (url == "skill") {
      this.tabSelectedIndex = 4;
      this.instructionText =
        this.oninitpopupservice.getKeyWordData("LIBRARY_TREKKER");
    }
    //Initalizing filterTumb with all to show all careers
    this.filterThumb = "all";
    this.tabname = url;
    this.canScroll = true;
    this.infopopup();
    this.dataforParticular(url);
    this.respdata = this.resp;
    this.occgroup = 0;
    this.occgroup2 = 0;
  }
  filter(type, name, weight, resp) {
    const respdata1 = [];
    this.canScroll = false;
    let data = [];
    if (resp != null && resp != "") {
      data = resp;
    }
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].attributes.length; j++) {
        if (data[i].attributes[j].type == type) {
          if (data[i].attributes[j].name == name) {
            if (
              data[i].attributes[j].weight == weight.toLowerCase() ||
              weight == "All"
            ) {
              respdata1.push(data[i]);
            }
          }
        }
      }
    }
    this.finalSortFilter = respdata1;
    this.prevWeight = weight;
    return this.finalSortFilter;
  }
  occGroupClick(category) {
    if (this.occgroup != category) {
      this.canScroll = true;
      this.respdata = [];
      for (let i = 0; i < this.resp.length; i++) {
        if (this.resp[i].category.categoryId == category || category == 0) {
          this.respdata.push(this.resp[i]);
        }
      }
      this.unmodifieddata = this.respdata;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          this.explorerClick(this.weightarray[i], this.filterArray[i], i);
        }
      }
      this.occgroup = category;
      // this.showData();
    }
  }
  explorerClick(weight, occgroup, inx) {
    //Initalizing filterTumb with all to show all careers
    this.filterThumb = "all";
    this.weightarray[inx] = weight;
    this.canScroll = true;
    if (this.filterArray.indexOf(occgroup) < 0) {
      this.filterArray[inx] = occgroup;
      let data;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              this.unmodifieddata
            );
          } else {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    } else {
      let data;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              this.unmodifieddata
            );
          } else {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    }
    this.showData();
  }
  scoutClick(holland, inx) {
    this.filterThumb = "all";
    this.canScroll = true;
    const respdata1 = [];
    if (this.scoutfilterarray.indexOf(holland) < 0) {
      this.scoutfilterarray.push(holland);
    } else {
      const a = this.scoutfilterarray.indexOf(holland);
      this.scoutfilterarray.splice(a, 1);
    }
    if (this.resp && this.resp.length >= 0) {
      for (let i = 0; i < this.resp.length; i++) {
        for (let j = 0; j < this.resp[i].attributes.length; j++) {
          if (this.scoutfilterarray.indexOf(this.resp[i].category.title) < 0) {
            respdata1.push(this.resp[i]);
            break;
          }
        }
      }
    }

    this.respdata = respdata1;
    this.showData();
  }

  navigatorClick(title, inx) {
    this.filterThumb = "all";
    this.canScroll = true;
    const respdata1 = [];
    if (this.navigatorFilterArray.indexOf(title) < 0) {
      this.navigatorFilterArray.push(title);
    } else {
      const a = this.navigatorFilterArray.indexOf(title);
      this.navigatorFilterArray.splice(a, 1);
    }
    if (this.resp && this.resp.length > 0) {
      for (let i = 0; i < this.resp.length; i++) {
        for (let j = 0; j < this.resp[i].attributes.length; j++) {
          if (this.resp[i].attributes[j].type == "value") {
            if (
              this.navigatorFilterArray.indexOf(
                this.resp[i].attributes[j].name
              ) < 0
            ) {
              respdata1.push(this.resp[i]);
              break;
            }
          }
        }
      }
    }
    this.respdata = respdata1;
    this.showData();
  }
  pathFinder(holland, inx) {
    //Initalizing filterTumb with all to show all careers
    this.filterThumb = "all";
    this.canScroll = true;
    const respdata1 = [];
    if (this.pathfinderfilterarray.indexOf(holland) < 0) {
      this.pathfinderfilterarray.push(holland);
    } else {
      const a = this.pathfinderfilterarray.indexOf(holland);
      this.pathfinderfilterarray.splice(a, 1);
    }
    if (this.resp && this.resp.length > 0) {
      for (let i = 0; i < this.resp.length; i++) {
        for (let j = 0; j < this.resp[i].attributes.length; j++) {
          if (this.resp[i].attributes[j].type == "hollandcode") {
            if (
              this.pathfinderfilterarray.indexOf(
                this.resp[i].attributes[j].name
              ) < 0
            ) {
              respdata1.push(this.resp[i]);
              break;
            }
          }
        }
      }
    }

    this.respdata = respdata1;
    this.showData();
  }
  careerTrekker(weight, skill, inx) {
    //Initalizing filterTumb with all to show all careers
    this.filterThumb = "all";
    this.unmodifieddata = this.resp;
    this.canScroll = true;
    this.weightarray2[inx] = weight;
    if (this.filterArray.indexOf(skill) < 0) {
      this.filterArray[inx] = skill;
      let data;
      for (let i = 0; i < this.weightarray2.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              this.resp
            );
          } else {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              data
            );
          }
        }
      }
      this.respdata = data;
      this.showData();
    } else {
      let data;
      for (let i = 0; i < this.weightarray2.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              this.resp
            );
          } else {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              data
            );
          }
        }
      }
      this.respdata = data;
      this.showData();
    }
  }
  filterPopup() {
    this.filterdata = [
      {
        attributesData: this.attributesData,
        themeColor: this.themeColor,
        module: this.module,
        arr: this.arr,
        tabname: this.tabname,
        occgroup2: this.occgroup2,
        explorer: this.explorer,
        pathfinder: this.pathfinder,
        careertrekker: this.careertrekker,
        scout: this.scout,
        modalarray: this.modalarray,
        modalarray2: this.modalarray2,
        pathfinderfilterarray: this.pathfinderfilterarray,
        scoutfilterarray: this.scoutfilterarray,
        resp: this.resp,
        respdata: this.respdata,
        unmodifieddata: this.unmodifieddata,
        filterArray: this.filterArray,
        list1: this.list1,
        commonLibraryHeading: this.commonLibraryHeading,
        navigatorData: this.navigatorData,
        navigatorfilter: this.navigatorFilterArray,
      },
    ];
    this.dialogService.occupationsFilter(this.filterdata);
    this.dialogService.filterDialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        this.filterreturnData = result;
        if (Array.isArray(this.filterreturnData[0].weightarray) && this.filterreturnData[0].weightarray.length>0) {
          this.weightarray = this.filterreturnData[0].weightarray;
        }
        if (
          Array.isArray(this.filterreturnData[0].filterArray) &&
          this.filterreturnData[0].filterArray.length>0
        ) {
          this.filterArray = this.filterreturnData[0].filterArray;
        }
        if (
          Array.isArray(this.filterreturnData[0].modalarray2) &&
          this.filterreturnData[0].modalarray2.length>0
        ) {
          this.modalarray2 = this.filterreturnData[0].modalarray2;
        }
        if (
          Array.isArray(this.filterreturnData[0].weightarray2) &&
          this.filterreturnData[0].weightarray2
        .length>0) {
          this.weightarray2 = this.filterreturnData[0].weightarray2;
        }
        if (
          Array.isArray(this.filterreturnData[0].modalarray) &&
          this.filterreturnData[0].modalarray.length>0
        ) {
          this.modalarray = this.filterreturnData[0].modalarray;
        }
        if (
          Array.isArray(this.filterreturnData[0].themeColor) &&
          this.filterreturnData[0].themeColor.length>0
        ) {
          this.themeColor = this.filterreturnData[0].themeColor;
        }
        if (
          Array.isArray(this.filterreturnData[0].tabname) &&
          this.filterreturnData[0].tabname.length>0
        ) {
          this.tabname = this.filterreturnData[0].tabname;
        }
        if (
          Array.isArray(this.filterreturnData[0].occgroup2) &&
          this.filterreturnData[0].occgroup2.length>0
        ) {
          this.occgroup2 = this.filterreturnData[0].occgroup2;
        }
        if (Array.isArray(this.filterreturnData[0].pathfinderfilterarray) && 
          this.filterreturnData[0].pathfinderfilterarray.length>0) {
          this.pathfinderfilterarray =
            this.filterreturnData[0].pathfinderfilterarray;
        }
        if (
          Array.isArray(this.filterreturnData[0].explorer) &&
          this.filterreturnData[0].explorer.length>0
        ) {
          this.explorer = this.filterreturnData[0].explorer;
        }
        if (Array.isArray(this.filterreturnData[0].scout) && 
          this.filterreturnData[0].scout.length>0) {
          this.scout = this.filterreturnData[0].scout;
        }
        if (
          Array.isArray(this.filterreturnData[0].navigatorData) &&
          this.filterreturnData[0].navigatorData
        .length>0) {
          this.navigatorData = this.filterreturnData[0].navigatorData;
        }
        if (
          Array.isArray(this.filterreturnData[0].pathfinder) &&
          this.filterreturnData[0].pathfinder.length>0
        ) {
          this.pathfinder = this.filterreturnData[0].pathfinder;
        }
        if (
          Array.isArray(this.filterreturnData[0].careertrekker) &&
          this.filterreturnData[0].careertrekker.length>0
        ) {
          this.careertrekker = this.filterreturnData[0].careertrekker;
        }
        if (
          Array.isArray(this.filterreturnData[0].scoutfilterarray) &&
          this.filterreturnData[0].scoutfilterarray.length>0
        ) {
          this.scoutfilterarray = this.filterreturnData[0].scoutfilterarray;
        }
        if (
          Array.isArray(this.filterreturnData[0].navigatorfilter) &&
          this.filterreturnData[0].navigatorfilter.length>0
        ) {
          this.navigatorFilterArray = this.filterreturnData[0].navigatorfilter;
        }
        if (
          Array.isArray(this.filterreturnData[0].module) &&
          this.filterreturnData[0].module.length>0
        ) {
          this.module = this.filterreturnData[0].module;
        }
        if (this.filterreturnData[0].tabSelectedIndex) {
          this.tabSelectedIndex = this.filterreturnData[0].tabSelectedIndex;
        }
        this.respdata = this.filterreturnData[0].respdata;
        this.showData();
      });
  }
  modalOpen(item, i) {
    this.stopAudio();
    sessionStorage.setItem('isModalActive', 'true');

    this.occItem = item;
    document.body.classList.add(
      'body-scroll'
    );
    let like = "";
    if (this.thumbTrue.indexOf(item.occId) >= 0) {
      like = "like";
    } else if (this.thumbFalse.indexOf(item.occId) >= 0) {
      like = "unlike";
    } else {
      like = "undesided";
    }
    if (this.attributesData != undefined) {
      if (this.tabname === "characteristic") {
        this.theme_name = this.colors[0];
      } else if (this.tabname === "hollandcode") {
        this.theme_name = this.colors[1];
      } else if (this.tabname === "skill") {
        this.theme_name = this.colors[2];
      } else if (this.tabname === "group") {
        this.theme_name = this.colors[7];
      }
      else if (this.tabname === "value") {
        this.theme_name = this.colors[8];
      }
      this.dialogService.explorerOccsPopup(
        item,
        this.tabname,
        this.attributesData,
        like,
        this.theme_name
      );
      this.respdata[this.respdata.findIndex(x => x.occId == item.occId)]['viewed'] = true
    }
    this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
      document.body.classList.remove(
        'body-scroll'
      );
      sessionStorage.setItem('isModalActive', 'false');

      const occIndex = this.viewedCareerList.findIndex(x => x.occId === item.occId);
      if (occIndex != -1 && item.viewed) {
        this.respdata[i]['viewed'] = true;
      } else {
        this.respdata[i]['viewed'] = true;
        this.viewedCareerList.push(item.occId);
        this.postViewedCareer(this.moduleName, item);
      }
    });
  }
  // this is used to set viewed paramter for viewed occs
  setViewedOcc() {
    this.viewedCareerList.map(obj => {
      let index = this.cards.findIndex(x => x.occId === obj.occId)
      if (index == 0) {
        this.cards[index]['viewed'] = true;
      }

    })
  }
  //The below method is for calling api on the thumb state change in the occs dialog box in career library
  thumbEvntMethod() {
    let endurl = "";
    let item = this.occItem;
    if (this.occPayload) {
      if (this.thumbTrue.indexOf(item.occId) >= 0) {
        this.thumbTrue.splice(this.thumbTrue.indexOf(item.occId), 1);
      }
      if (this.thumbFalse.indexOf(item.occId) >= 0) {
        this.thumbFalse.splice(this.thumbFalse.indexOf(item.occId), 1);
      }
      if (this.occPayload === "thumb_up") {
        endurl = "user/occ/rate/" + item.occId + "/true";
        this.thumbTrue.push(item.occId);
      } else if (this.occPayload === "thumb_up_deselect") {
        endurl = "user/occ/rate/" + item.occId + "/true";
        this.thumbFalse.splice(this.thumbFalse.indexOf(item.occId), 1);
      } else if (this.occPayload === "thumb_down") {
        endurl = "user/occ/rate/" + item.occId + "/false";
        this.thumbFalse.push(item.occId);
      } else if (this.occPayload === "thumb_down_deselect") {
        endurl = "user/occ/rate/" + item.occId + "/false";
        this.thumbFalse.splice(this.thumbFalse.indexOf(item.occId), 1);
      }
      if (endurl != "") {
        this.apiModel.moduleName = "explorer";
        this.apiModel.endUrl = endurl;
        this.apiModel.sessionID = sessionStorage.getItem("session_token");
        this.apiModel.method = "GET";
        try {
          this.apiCall
            .getData([this.apiModel])
            .pipe(takeWhile(() => this.compActive))
            .subscribe((res) => {
              this.favourate = JSON.parse(res);
              this.thumbFalse = [];
              this.thumbTrue = [];
              for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
                if (this.favourate.occs.evaluated[j].like == true) {
                  this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
                } else if (this.favourate.occs.evaluated[j].like == false) {
                  this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
                }
              }
            });
        } catch (e) {
          this.utils.handleError(`explore-occs-component.ts thumbEvntMethod: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      }
    }
  }
  favouriteOccupationFilter(thumbchoosen) {
    if (this.filterThumb != thumbchoosen) {
      if (this.filteredData.length > 0) {
        this.cards = this.filteredData;
        this.respdata = this.unmodifieddata;
      }
      this.filterThumb = thumbchoosen;
      this.filteredData = this.cards
      const favouratedata = [];
      for (let i = 0; i < this.respdata.length; i++) {
        if (thumbchoosen == "thumb_up") {
          if (this.thumbTrue.indexOf(this.respdata[i].occId) >= 0) {
            favouratedata.push(this.respdata[i]);
          }
        }
        if (thumbchoosen == "undesided") {
          if (
            this.thumbTrue.indexOf(this.respdata[i].occId) < 0 &&
            this.thumbFalse.indexOf(this.respdata[i].occId) < 0
          ) {
            favouratedata.push(this.respdata[i]);
          }
        }
        if (thumbchoosen == "all") {
          favouratedata.push(this.cards[i]);
        }
      }
      this.respdata = favouratedata;
      if (thumbchoosen == "undesided" && (Array.isArray(this.favourate.occs.evaluated) && this.favourate.occs.evaluated.length == 0)) {
        this.respdata = this.cards;
      }
      this.showData();
    } else if (this.filterThumb == thumbchoosen) {
      // assigned filterdata to response data to get current occs while deselecting thumbs and undesided filters
      this.respdata = this.unmodifieddata;
      this.filterThumb = '';
      this.filteredData = [];
      this.showData();
    }
  }
  onScroll() {
    if (this.respdata) {
      if (this.j <= this.respdata.length) {
        for (let i = 0; i < 80 && this.j < this.respdata.length; i++) {
          this.cards.indexOf(this.respdata[this.j]) < 0
            ? this.cards.push(this.respdata[this.j])
            : this.cards;
          this.j++;
          this.canScroll = true;
        }
      } else {
        this.canScroll = false;
      }
      this.setViewedOcc()
    }
  }

  navigateToChallenge() {
    this.router.navigate(["../careertrekker/challenge"], {
      relativeTo: this.activatedRoute,
    });
  }

  navigateToCreateOcc(module) {
    this.stopAudio();
    if (this.viewedCareerList.length > 0) {
      if (module == "Careertrekker") {
        this.theme_Color_num = 2;
      } else if (module == "Explorer") {
        this.theme_Color_num = 0;
      } else if (module == "Pathfinder") {
        this.theme_Color_num = 1;
      } else if (module == "scout") {
        this.theme_Color_num = 7;
      } else if (module == 'Navigator') {
        this.theme_Color_num = 8
      } else {
        this.theme_Color_num = 6;
      }
      this.dialogService.landingCreatePopup(
        module.toLowerCase(),
        this.CreateOwnOccs,
        this.theme_Color_num
      );
    } else {
      this.snackBar.open("Find Careers", "", {
        duration: 5000,
        panelClass: ["failure-snackbar"],
      });
    }
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }
  getThemeColorForActiveBar(item) {
    // Explorer, Pathfinder, Trekker, Scout, Navigator
    if (this.moduleName === 'Explorer') {
      return { 'explorer': !((this.thumbTrue.indexOf(item.occId) === -1) && (this.thumbFalse.indexOf(item.occId) === -1)) || item.viewed }
    }
    if (this.moduleName === 'Pathfinder') {
      return { 'pathfinder': !((this.thumbTrue.indexOf(item.occId) === -1) && (this.thumbFalse.indexOf(item.occId) === -1)) || item.viewed }
    }
    if (this.moduleName === 'Trekker') {
      return { 'careertrekker': !((this.thumbTrue.indexOf(item.occId) === -1) && (this.thumbFalse.indexOf(item.occId) === -1)) || item.viewed }
    }
    if (this.moduleName === 'Scout') {
      return { 'scout': !((this.thumbTrue.indexOf(item.occId) === -1) && (this.thumbFalse.indexOf(item.occId) === -1)) || item.viewed }
    }
    if (this.moduleName === 'Navigator') {
      return { 'navigator': !((this.thumbTrue.indexOf(item.occId) === -1) && (this.thumbFalse.indexOf(item.occId) === -1)) || item.viewed }
    }
  }
  // Stop audio event method
  stopAudio() {
    this.audioState = "stoped";
    this.audionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }
  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }
  private setOccupationLibraryTitle(mName: string) {
    if (mName.toLocaleLowerCase() == "careerlibrary") {
      this.titleService.setTitle("Career Library - CareerTrek"
      );
    }
    else {
      this.titleService.setTitle(this.utils.capitalizeFirstLetter(mName) + " Occupation Library - CareerTrek");
    }
  }

}
