<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{explorerChallengeText?.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="audioClick('inside',explorerChallengeText)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="explorer-occuptation explorer-task content-box" [@pointerstate]="selectedIndex" *ngIf="data!=undefined">
      <mat-card class="card questions-section">
        <div class="image-content">
          <img src="{{occCardAPIUrl}}{{data.media?.images.card}}" alt="{{data.title}}" class="w-100" />
          <div class="opacity-text mobile-design-show">
            <p m-0 text-color="primary">
              <span>
                <b>{{data?.title}}</b>
              </span>
              <app-audio-button [audionotfound]="careerAudionotfound" [audioState]="careerAudioState"
                (click)="indCareerAudioClick('inside', data)"></app-audio-button>
            </p>
          </div>
          <div class="pagecode-image">
            <img src="assets/explorer/svg/{{iconName}}.svg" alt="" />
          </div>
        </div>
        <div class="task-section text-center">
          <div class="desktop-design-show" mb-10 pt-12>
            <h2 m-0 text-color="primary">
              <span mr-10>{{data?.title}}</span>
            </h2>
            <p font-bold>{{data?.description}}
              <app-audio-button [audionotfound]="careerAudionotfound" [audioState]="careerAudioState"
                (click)="indCareerAudioClick('inside', data)"></app-audio-button>
            </p>
          </div>
         <div class="question-sec-body">
          <div>
            <div flex v-center *ngIf="!(!highbutton&&!lowbutton&&!mediumbutton)">
              <div class="icon-sec question-block w-100" background-color="primary" flex v-center>
                <div flex-shrink-zero class="circle-icon" flex v-center h-center>
                  <!-- <div class="{{icon}}"></div> -->
                  <img src="./assets/Svg/{{icon}}.svg" alt="{{icon}}-icon" />
                </div>
                <div class="w-100 question-text">
                  <span font-bold>{{present | titlecase}}</span>
                  <app-audio-button [audionotfound]="attributeAudionotfound" [audioState]="attributeAudioState"
                    (click)="indAttribAudioClick('inside',audioSelected)"></app-audio-button>
                </div>
                <div class="animate mobile-design-show">
                  <button mat-fab color="accent" class="light-red" *ngIf="nextQues" (click)="nextquestion()"
                    [ngStyle]="{'position':'relative','left':'16px'}">
                    <i class="material-icons">arrow_forward </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- status block start -->
          <div fxLayout="row" *ngIf="!highbutton&&!lowbutton&&!mediumbutton" d-block-mobile fxLayout.xs="column"
            fxLayoutAlign="center">
            <div fxFlex="100%">
              <div class="icon-sec result-block w-100" flex v-center>
                <div class="custom-question-text" *ngIf="(attempts+1)<activeIds.length && nextId">
                  <span font-normal>{{keywords?.dictionary?.occComplete | nextOccPipe :
                    nextId?.title }}</span>
                </div>
                <div class="custom-question-text" *ngIf="(attempts+1)>=activeIds.length">
                  <span font-normal>{{keywords?.dictionary?.completedChallenge}}</span>
                </div>
                <div [ngStyle]="{'margin-left':'auto'}" *ngIf="completed=='completed'">
                  <button mat-fab color="accent" class="light-red" [@flipAnimation]="completed" (click)="next()"
                    id="next" mat-fab color="accent">
                    <i class="material-icons">arrow_forward </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- status block end -->
          <div>
            <div fxLayout="row" fxFlexFill class="common-row-sub rating-cards challenge-principle" fxLayoutGap="10px">
              <div class="circle-rating-card" fxFlex="" *ngIf="highdata.length != 0">
                <mat-card class="card green-shade-high">
                  <mat-card-title class="card-header green">
                    {{keywords?.dictionary?.high}}</mat-card-title>
                  <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                    <li class="status-circle question-icon high-icon-bg"
                      *ngFor="let highdata1 of highdata; let i = index">
                      <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons">
                        <img *ngIf="highdata1!=undefined||highdata1!=null" src="./assets/Svg/{{highdata1}}.svg"
                          alt="{{highdata1}}" />
                        <!-- <div *ngIf="highdata1!=undefined||highdata1!=null" class="{{highdata1}}"></div> -->
                        <div flex v-center h-center *ngIf="highdata1==undefined||highdata1==null" pointer
                          class="occ-question-icon" (click)="onQuesClick(data.occId,'high', i);">
                          <img src="./assets/Svg/question-sign-green.svg" alt="question icon in high circle" />
                        </div>
                      </div>

                      <div class="mobile-design-show" (click)="onQuesClick(data.occId,'high', i);">
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true"
                          *ngIf="highdata1!=undefined||highdata1!=null">
                        </mat-checkbox>
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                          *ngIf="highdata1==undefined||highdata1==null">
                          <img class="sign-icon" src="./assets/Svg/question-sign-green.svg"
                            alt="question icon in high circle" />
                        </mat-checkbox>
                      </div>
                    </li>
                  </ul>
                </mat-card>
              </div>
              <div class="circle-rating-card" fxFlex="" *ngIf="mediumdata.length != 0">
                <mat-card class="card orange-shade-medium">
                  <mat-card-title class="card-header orange">
                    {{keywords?.dictionary?.medium}}</mat-card-title>
                  <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                    <li class="status-circle question-icon medium-icon-bg"
                      *ngFor="let mediumdata1 of mediumdata; let i = index">
                      <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons">
                        <img *ngIf="mediumdata1!=undefined||mediumdata1!=null" src="./assets/Svg/{{mediumdata1}}.svg"
                          alt="{{mediumdata1}}" />
                        <!-- <div *ngIf="mediumdata1!=undefined||mediumdata1!=null" class="{{mediumdata1}}"></div> -->
                        <div flex v-center h-center *ngIf="mediumdata1==undefined||mediumdata1==null" pointer
                          class="occ-question-icon" (click)="onQuesClick(data.occId,'medium', i);">
                          <img src="./assets/Svg/question-sign-orange.svg" alt="question icon in medium circle" />
                        </div>
                      </div>
                      <div class="mobile-design-show" (click)="onQuesClick(data.occId,'medium',i);">
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                          *ngIf="mediumdata1!=undefined||mediumdata1!=null" [checked]="true">
                        </mat-checkbox>
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                          *ngIf="mediumdata1==undefined||mediumdata1==null">
                          <img class="sign-icon" src="./assets/Svg/question-sign-orange.svg"
                            alt="question icon in medium circle" />
                        </mat-checkbox>
                      </div>
                    </li>
                  </ul>
                </mat-card>
              </div>
              <div class="circle-rating-card" fxFlex="" *ngIf="lowdata.length != 0">
                <mat-card class="card purple-shade-low">
                  <mat-card-title class="card-header purple">
                    {{keywords.dictionary.low}}</mat-card-title>
                  <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                    <li class="status-circle question-icon low-icon-bg" *ngFor="let lowdata1 of lowdata; let i = index">
                      <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons">
                        <img *ngIf="lowdata1!=undefined||lowdata1!=null" src="./assets/Svg/{{lowdata1}}.svg"
                          alt="{{lowdata1}}" />
                        <!-- <div *ngIf="lowdata1!=undefined||lowdata1!=null" class="{{lowdata1}}"></div> -->
                        <div flex v-center h-center *ngIf="lowdata1==undefined||lowdata1==null" pointer
                          class="occ-question-icon" (click)="onQuesClick(data.occId,'low', i);">
                          <img src="./assets/Svg/question-sign-purple.svg" alt="question icon in low circle" />
                        </div>
                      </div>
                      <div class="mobile-design-show" (click)="onQuesClick(data.occId,'low',i);">
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                          *ngIf="lowdata1!=undefined||lowdata1!=null" [checked]="true">
                        </mat-checkbox>
                        <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                          *ngIf="lowdata1==undefined||lowdata1==null">
                          <img class="sign-icon" src="./assets/Svg/question-sign-purple.svg"
                            alt="question icon in low circle" />
                        </mat-checkbox>
                      </div>
                    </li>
                  </ul>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="desktop-design-show">
            <ul class="score-data list-style-none" flex v-center h-center p-0>
              <li>
                <div class="score" flex v-center h-center>0</div>
              </li>
              &nbsp;
              <li class="progress-bar">
                <mat-progress-bar mode="determinate" [value]="barProgress"></mat-progress-bar>
              </li>
              &nbsp;
              <li>
                <div class="score" flex v-center h-center>54</div>
                <!-- <p>{{keywords.dictionary.score}}</p> -->
              </li>
            </ul>
            <span class="progress-bar-title" font-bold>{{keywords?.dictionary?.score}} {{score}}</span>
          </div>
          <ul class="score-data list-style-none mobile-design-show text-center" p-0 m-0>
            <li>
              <div flex v-center justify-between>
                <div class="score" flex v-center h-center>0</div>
                <div class="progress-bar">
                  <mat-progress-bar mode="determinate" [value]="barProgress"></mat-progress-bar>
                </div>
                <div class="score" flex v-center h-center>54</div>
              </div>
              <span class="progress-bar-title" font-bold>{{keywords?.dictionary?.score}} {{score}}</span>
            </li>
          </ul>
         </div>
        </div>
      </mat-card>
      <!-- <div class="desktop-design-show" *ngIf="completed=='completed'">
        <button class="next-level yellow" [@flipAnimation]="completed" (click)="next()" id="next" mat-fab color="accent">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div> -->
    </div>
  </div>
</div>