import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogopenService } from "../common-modal/modalpopup.service";
import { EventdispatchService } from "../common-modal/eventdispach.service";
import { interval } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
/*** On It Welcome Popup Start */
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddRibbonToCareerState, AudioState, CareerCreatorState, DeleteRibbonToCareerState, GetThoughtBoxState, SaveThoughtBoxState } from '../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { AudioService } from '../common-constants/audioLoading.service';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { Subject } from "rxjs";
import { ApiCallClass } from "../common-services/api-call-model";
import { ApiCallService } from "../common-services/api-call-service";
import { Utilities } from "../common-services/utilities.service";
@Component({
  selector: "app-oninit-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="common-popup">
        <div class="common-popup-header" flex v-center h-center>
          <h2
            m-0
            class="w-100"
            flex
            v-center
            h-center
            background-color="primary"
          >
            <button
              mat-fab
              flex
              v-center
              h-center
              mat-dialog-close
              class="close-icon-button volume-up"
              (click)="initpopupClose()"
            >
              <i flex v-center class="material-icons">close</i>
            </button>
            <span class="header-text" flex v-center h-center>{{
              data.dialog_data.title
            }}</span>
            <app-audio-button
                [audionotfound]="headerAudioNotFound"
                [audioState]="headerAudioState"
                [styleClass]="'ct-vol-btn'"
                (click)="headerAudioClick('inside')">
              </app-audio-button>
          </h2>
        </div>
        <div class="common-popup-body">
          <div
            *ngIf="
              data?.dialog_data?.pageCode === 'LAUNCH' ||
              data?.dialog_data?.pageCode === 'EXPLORER_IDEAS' ||
              data?.dialog_data?.pageCode === 'EXPLORER_PEOPLE' ||
              data?.dialog_data?.pageCode === 'EXPLORER_THINGS'
            "
          >
            <div *ngFor="let helperData of data.dialog_data.pageText?.helper">
              <p font-bold m-0 *ngIf="helperData?.intro !== 'Quick Pic'">
                {{ helperData?.intro }}
              </p>
              <ul class="list-style-none common-popup-list">
                <li *ngFor="let item of helperData?.expressions?.items" flex>
                  <i
                    text-color="primary"
                    [ngStyle]="{ 'align-items': item.icon ? 'center' : '' }"
                    class="material-icons"
                    flex
                    >done</i
                  >
                  <img
                    *ngIf="item.icon"
                    src="assets/Svg/{{
                      item.icon.split('-')[1].split(' ')[0]
                    }}.svg"
                    width="40px"
                    height="40px"
                    class="explorer-icons-popup desktop-design-show"
                  />
                  <span flex v-center class="w-100">{{ item.text }}</span>
                </li>
                <li *ngIf="!data.dialog_data.pageText" flex>
                  <i
                    text-color="primary"
                    [ngStyle]="{ 'align-items': 'center' }"
                    class="material-icons"
                    flex
                    >done</i
                  >
                  <span flex v-center class="w-100">
                    {{ data.dialog_data.subHeader }}
                  </span>
                </li>
                <li *ngIf="!data.dialog_data.pageText" flex>
                  <i
                    text-color="primary"
                    [ngStyle]="{ 'align-items': 'center' }"
                    class="material-icons"
                    flex
                    >done</i
                  >
                  <span flex v-center class="w-100">
                    {{ data.dialog_data.description }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            *ngIf="
              data?.dialog_data?.pageCode !== 'LAUNCH' &&
              data?.dialog_data?.pageCode !== 'EXPLORER_IDEAS' &&
              data?.dialog_data?.pageCode !== 'EXPLORER_PEOPLE' &&
              data?.dialog_data?.pageCode !== 'EXPLORER_THINGS'
            "
          >
            <p m-0 font-bold>{{ data.dialog_data.pageText?.intro }}</p>
            <ul *ngIf= "data.dialog_data.pageText?.expressions?.items"
             class="list-style-none common-popup-list"
             [ngClass]="(data.dialog_data.pageCode === 'PATHFINDER_SHARED' || data.dialog_data.pageCode === 'NAVIGATOR_TOP')? 'custom-scroll':''"
             >
              <li
                *ngFor="
                  let item of data.dialog_data.pageText?.expressions?.items; let idx = index
                "
                flex v-center
              >
                <i
                  *ngIf="!item.media"
                  class="material-icons"
                  text-color="primary"
                >
                  done</i
                >
                <i
                class="material-icons smileys"
                text-color="primary"
                *ngIf="data.dialog_data.pageCode === 'QUICKPIC_ASSESSMENT'"
              >
                {{item.icon}}
              </i>
              
                <img
                  *ngIf="item.icon && data.dialog_data.pageCode !== 'QUICKPIC_ASSESSMENT'"
                  [src]="getImageUrl(item, data)"
                  width="40px"
                  height="40px"
                  class="explorer-icons-popup icon-align"
                />
                <span class="text-spacing" mr-10>{{ item.text }}</span>
                <span *ngIf="item.media">
                <app-audio-button
                [audionotfound]="audionotfound[idx]"
                [audioState]="audioState[idx]"
                [styleClass]="'d-block'"
                (click)="audioClick('inside', item, idx)">
              </app-audio-button>
                </span>
              </li>
              <li *ngIf="!data.dialog_data.pageText" flex>
                <i
                  text-color="primary"
                  [ngStyle]="{ 'align-items': 'center' }"
                  class="material-icons"
                  flex
                  >done</i
                >
                <span flex v-center class="w-100">
                  {{ data.dialog_data.subHeader }}
                </span>
              </li>
              <li *ngIf="!data.dialog_data.pageText" flex>
                <i
                  text-color="primary"
                  [ngStyle]="{ 'align-items': 'center' }"
                  class="material-icons"
                  flex
                  >done</i
                >
                <span flex v-center class="w-100">
                  {{ data.dialog_data.description }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [],
})
export class OnInitPopUpComponent {
  playPromise: any;
  audio = undefined;
  audionotfound = [];
  audioState = [];
  buttonText: string;
  assetsUrls = JSON.parse(sessionStorage.getItem("assetsData"));
  audioSubscription: any;
  assetsUrl: string;
  visible = true;
  checkText = "";
  prevText = "";
  curAudio = "";
  headerAudioState = "stoped";
  headerAudioNotFound = false;
  audioButtonIndex = -1;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string,
    private audiostopevent: EventdispatchService
  ) {
    this.assetsUrl = assetsMediaUrl;
    const keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.buttonText = keywords.dictionary.start;
    this.audioSubscription = this.audioStore
      .select("audio")
      .subscribe((result) => {
        if (result) {
          if (this.curAudio == "header") {
            this.headerAudioState = result.audioState;
            this.headerAudioNotFound = result.audionotfound
            this.setAudioStatesAsDefault(data)
          }
          else if (this.curAudio == "custom") {
            this.audioState[this.audioButtonIndex] = result.audioState;
            this.audionotfound[this.audioButtonIndex] = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudioNotFound = false;
          }
        }
      });
    // If media is not present then hiding the Quick Pic Landing Page audio button
    if (!this.data.dialog_data.media) {
      this.visible = false;
    }
    this.setAudioStatesAsDefault(data)
  }
  setAudioStatesAsDefault(data) {
    if (data) {
      if (data.dialog_data) {
        if (data.dialog_data.pageText) {
          if (data.dialog_data.pageText.expressions) {
            for (let i = 0; i < data.dialog_data.pageText.expressions.items.length; i++) {
              this.audionotfound[i] = false
              this.audioState[i] = "stoped"
            }
          }
        }
      }
    }
  }
  initpopupClose() {
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
  }
  getImageUrl(item, data) {
    let imageUrl: string;
    if (
      data.dialog_data.module === "pathfinder" ||
      data.dialog_data.pageCode === "PATHFINDER_SHARED" ||
      data.dialog_data.module === "library_pathfinder" ||
      data.dialog_data.subMod === "pathfinder"
    ) {
      imageUrl = `assets/pathfinder/holland/${item.icon}.svg`;
    } else if (data.dialog_data.pageCode === "NAVIGATOR_TOP" || data.dialog_data.pageCode === "NAVIGATOR_INTRO") {
      imageUrl = `assets/Svg/${item.icon}.svg`;
    } else if (data.dialog_data.module === "careertrekker" ||
      data.dialog_data.pageCode === "SCOUT_INTRO" ||
      data.dialog_data.pageCode === "SCOUT_SHARED_PEOPLE" ||
      data.dialog_data.pageCode === "SCOUT_SHARED_IDEAS" ||
      data.dialog_data.pageCode === "SCOUT_SHARED_THINGS" ||
      data.dialog_data.pageCode === "SCOUT_SHARED_TIE" ||
      data.dialog_data.pageCode === "NAVIGATOR_TIE" ||
      (data.dialog_data.pageCode === "CUSTOM_OCCUPATION" && data.dialog_data.module !== "explorer") ||
      data.dialog_data.pageCode === "NAVIGATOR_SHARED") {
      imageUrl = `${this.assetsUrl}${item.icon}.svg`;
    }
    else if (data.dialog_data.pageCode === "QUICKPIC_ASSESSMENT") {
      imageUrl = ``;
    } else {
      const itemIcon = item.icon.split("-")[1].split(" ")[0];
      imageUrl = `assets/Svg/${itemIcon}.svg`;
    }
    return imageUrl;
  }

  audioClick(text, mediaData?, index?) {
    this.curAudio = "custom"
    this.audioButtonIndex = index;
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
    if (mediaData) {
      this.checkText = mediaData.text;
      if (this.prevText === this.checkText && this.audioState[this.audioButtonIndex] !== "stoped") {
        this.audioState[this.audioButtonIndex] = "stoped";
      } else {
        this.audioService.audioFunction(text, mediaData);
        this.prevText = mediaData.text;
        this.setAudioStatesAsDefault(this.data)
      }
    } else {
      this.checkText = "";
      if (this.prevText === this.checkText && this.audioState[this.audioButtonIndex] !== "stoped") {
        this.audioState[this.audioButtonIndex] = "stoped";
      } else {
        this.audioService.audioFunction(text, this.data.dialog_data.pageText);
        this.prevText = "";
      }
    }
  }

  headerAudioClick(text, mediaData?) {
    this.curAudio = "header"
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
    if (mediaData) {
      if (this.headerAudioState !== "stoped") {
        this.stopAudio()
      }
      else {
        this.audioService.audioFunction(text, mediaData);
      }
    } else {
      if (this.headerAudioState !== "stoped") {
        this.stopAudio()
      }
      else {
        this.audioService.audioFunction(text, this.data.dialog_data.pageText);
      }
    }
  }
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudioNotFound = false;
    this.setAudioStatesAsDefault(this.data)
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
  }
  ngOnDestroy() {
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    this.audioSubscription.unsubscribe();
  }
}
/***On It Welcome Popup End */

/*** PathFinder Occupation Popup Start  ***/
@Component({
  selector: "app-pathfinder-occupation-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="common-popup">
        <div class="common-popup-header" flex v-center h-center>
          <h2
            m-0
            class="w-100"
            flex
            v-center
            h-center
            background-color="primary"
          >
            <button
              mat-fab
              color="primary"
              class="volume-up"
              flex
              v-center
              h-center
              [mat-dialog-close]="thumb"
              (click)="pathfinderPopupClose()"
            >
              <i class="material-icons">clear</i>
            </button>
            <span class="header-text w-100" flex v-center h-center>{{
              data.dialog_data.title
            }}</span>
            <button
              mat-fab
              class="ct-vol-btn"
              [disabled]="audionotfound == true && audioState == 'warning'"
              flex
              v-center
              h-center
              (click)="audioClick('inside')"
            >
              <i
                class="material-icons"
                *ngIf="audionotfound == false && audioState == 'stoped'"
                >volume_up</i
              >
              <i
                class="material-icons"
                *ngIf="audionotfound == false && audioState == 'playing'"
                >stop</i
              >
              <div
                class="audio-loading"
                *ngIf="audionotfound == false && audioState == 'loading'"
              ></div>
              <i
                class="material-icons"
                *ngIf="audionotfound == true && audioState == 'warning'"
                >warning</i
              >
            </button>
          </h2>
        </div>
        <div class="common-occ-popup">
          <div
            class="common-occupation-popup"
            *ngIf="data.dialog_data != undefined"
          >
            <div
              class="common-occ-popup-image desktop-design-show"
              *ngIf="!videostatus"
            >
              <img
                src="{{ occLargeCardAPIUrl }}{{
                  data.dialog_data.media?.images.card
                }}"
                alt="{{ data.dialog_data.title }}"
                class="w-100"
              />
              <div class="opacity-text" *ngIf="(videoData)">
                <p m-0>
                  <span font-bold>{{ data.dialog_data.title }}</span>
                  <img
                    pointer
                    src="assets/images/video-icon.png"
                    alt="video-icon"
                    (click)="videoPlay()"
                  />
                </p>
              </div>
            </div>
            <div *ngIf="videostatus">
              <div role="document">
                <div>
                  <div>
                    <button
                      type="button"
                      class="closevideo"
                      (click)="videostop()"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div>
                    <video
                      *ngIf="videoData.textSrc; else notrack"
                      crossorigin="anonymous"
                      disablePictureInPicture
                      class="videobox"
                      controls
                      controlsList="nodownload"
                    >
                      <source
                        src="{{ videoData?.videoSrc }}"
                        type="video/mp4"
                      />
                      <track
                        src="{{ videoData?.textSrc }}"
                        type="text/vtt"
                        kind="subtitles"
                        srclang="en"
                        label="English"
                        default
                      />
                    </video>
                    <ng-template #notrack>
                      <video
                        disablePictureInPicture
                        class="videobox"
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src="{{ videoData?.videoSrc }}"
                          type="video/mp4"
                        />
                      </video>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxFlexFill
              fxLayoutWrap="wrap"
              fxLayoutAlign="center"
              d-block-mobile
              class="occ-popup-body"
              flex
              v-center
            >
              <div fxFlex="" class="common-occ-text text-center">
                <p m-0 class="common-text custom-occ-text">
                  {{ data.dialog_data.description }}
                </p>
                <mat-card class="card box-shadow-none scout-brdr custom-personality-type-card" 
                *ngIf="data.dialog_data.module==='scout'">
                <div class="card-body text-center" flex v-center h-center>
                  <img class="scout-img" src="{{assetsURL}}{{iconName}}.svg"> 
                  <span v-center m-0>{{data.dialog_data.category.title}}</span> 
                </div>
              </mat-card>
                <mat-card
                  class="card desktop-design-show pf-blue-brdr box-shadow-none" *ngIf="data.dialog_data.module!=='scout'"
                >
                  <div class="card-body text-center" flex v-center h-center>
                    <p v-center m-0>
                      {{ keywords.dictionary.personalityTypes }}:
                    </p>
                    <div *ngFor="let icon of data.dialog_data.icons" >
                      <div class="icon-hovertitle">
                        <img
                          *ngIf="icon.hollandCode"
                          src="./assets/pathfinder/holland/hc_{{
                            icon.hollandCode
                          }}.svg"
                          alt="{{ icon.hollandCode }}"
                        />
                        <span>{{ icon.title }}</span>
                      </div>
                    </div>
                  </div>
                </mat-card>
                <div class="mobile-design-show" *ngIf="data.dialog_data.module!=='scout'">
                  <mat-card
                    class="card mobile-design-show box-shadow-none pf-blue-brdr"
                  >
                    <mat-card-title
                      class="card-header"
                      m-0
                      background-color="primary"
                    >
                      <p m-0 class="text-center">
                        {{ keywords.dictionary.personalityTypes }} :
                      </p>
                    </mat-card-title>
                    <mat-card-content
                      flex
                      v-center
                      h-center
                      class="mobile-popup-image"
                    >
                      <div *ngFor="let icon of data.dialog_data.icons" >
                        <div class="icon-hovertitle">
                          <img
                            *ngIf="icon.hollandCode"
                            src="./assets/pathfinder/holland/hc_{{
                              icon.hollandCode
                            }}.svg"
                            alt="{{ icon.hollandCode }}"
                          />
                          <span>{{ icon.title }}</span>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div fxFlex="" class="common-occ-actor-text custom-actor-text">
                <p m-0 text-color="primary">
                  {{ data.dialog_data.tasks.title }}
                </p>
                <ul class="list-style-none" p-0 m-0>
                  <li flex *ngFor="let item of data.dialog_data.tasks.items">
                    <i class="material-icons" text-color="primary"> done</i>
                    {{ item }}
                  </li>
                </ul>
              </div>
               
            </div>
          </div>
          <div class="common-occ-popup-footer" flex v-center *ngIf="data.dialog_data.module==='scout'">
                  <div flex v-center class="w-100 mobile-text-center" pl-2>
                      <p m-0 flex v-center font-bold>
                          {{ keywords.dictionary.rateOcc }}:
                      </p>
                      <img
                          src="{{ assetsURL }}thumbs-up-outline-grey.svg"
                          pointer 
                          alt="thumbup"
                          *ngIf="thumb != 'thumb_up'"
                          (click)="thumb = 'thumb_up'; pathFinderThumb('thumb_up')"
                          tabindex="0"
                          (keypress)="thumb = 'thumb_up'; pathFinderThumb('thumb_up')"
                          class="ng-star-inserted"
                          flex
                          v-center
                          pointer
                      />
                      <img
                          src="{{ assetsURL }}thumbs-up-hover-mob.svg"
                          pointer 
                          (click)="
                thumb = 'thumb_up_deselect';
                pathFinderThumb('thumb_up_deselect')
              "
                          tabindex="0"
                          (keypress)="
                thumb = 'thumb_up_deselect';
                pathFinderThumb('thumb_up_deselect')
              "
                          alt="thumbup-filled-icon"
                          *ngIf="thumb == 'thumb_up'"
                      />
                  </div>
              </div>
  

          </div>
      </div>
    </div>
  `,
  styleUrls: [],
})
export class PathfinderOccupationsPopUpComponent {
  assetsUrls = JSON.parse(sessionStorage.getItem("assetsData"));
  buttonText: String;
  thumb = "";
  imageurl = this.assetsUrls.images;
  keywords: any;
  occLargeCardAPIUrl: any;
  audionotfound = false;
  audioState = "stoped";
  compActive = true;
  assetsURL: string;
  videostatus = false;
  videoData: any;
  iconName: string;

  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("ASSETS_MEDIA_URL") private assetsUrl: string,
    @Inject("OCC_LARGE_CARD_URL") private occCardUrl: string,
    public eventDispatcher: EventdispatchService,
    private audiostopevent: EventdispatchService
  ) {
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result) {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    this.occLargeCardAPIUrl = occCardUrl;
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.buttonText = this.keywords.dictionary.close;
    if (parseInt(data.dialog_data.category.categoryId) === 1) {
      this.iconName = 'EXPLORER_PEOPLE';
    } else if (parseInt(data.dialog_data.category.categoryId) === 2) {
      this.iconName = 'EXPLORER_IDEAS';
    } else if (parseInt(data.dialog_data.category.categoryId) === 3) {
      this.iconName = 'EXPLORER_THINGS';
    }
    this.assetsURL = assetsUrl;
    if (this.data.like === "like") {
      this.thumb = "thumb_up";
    } else if (this.data.like === "unlike") {
      this.thumb = "thumb_down";
    } else {
      this.thumb = "";
    }
    if (data.dialog_data.media && data.dialog_data.media.video && data.dialog_data.media.video[0]) {
      this.videoData = data.dialog_data.media.video[0];
    }
  }
  pathfinderPopupClose() {
    //Function to stop audio by clicking close button in pathfinder modal popup.
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
  }
  audioClick(text) {
    //while clicking on audio to stop video
    this.videostatus = false;
    this.audioService.audioFunction(text, this.data.dialog_data);
  }
  videostop() {
    this.videostatus = false;
  }
  videoPlay() {
    this.stopAudio();
    this.videostatus = true;
  }
  stopAudio() {  //to stop current playing audio
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventDispatcher.dispatchAudioStop(evnt);
		  }
  ngOnDestroy() {
    this.compActive = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
  }



  pathFinderThumb(data) {
    var evnt = document.createEvent("CustomEvent");
    evnt.initCustomEvent("pathfinderthumbEvnt", true, true, data);
    this.eventDispatcher.dispatchPathFinderThumbUpDownEvent(evnt);
  }
}

/*** PathFinder Occupation Popup End  ***/

/*** Explorer Occupation Popup Start  ***/

@Component({
  selector: "app-explorer-occupation-popup",
  template: `<div class="{{ data.page_theme_color }}">
  <div class="common-popup">
  <div class="common-popup-header" flex v-center h-center>
      <h2 m-0 class="w-100" flex v-center h-center background-color="primary">
          <button mat-fab color="primary" class="volume-up" flex v-center h-center [mat-dialog-close]="thumb"
              (click)="explorerPopupClose()">
              <i class="material-icons">clear</i>
          </button>
          <span class="header-text w-100" flex v-center h-center font-bold>{{
              data.dialog_data.title
              }}</span>
          <button mat-fab class="ct-vol-btn" [disabled]="audionotfound == true && audioState == 'warning'" flex
              v-center h-center (click)="audioClick('inside')">
              <i class="material-icons" *ngIf="audionotfound == false && audioState == 'stoped'">volume_up</i>
              <i class="material-icons" *ngIf="audionotfound == false && audioState == 'playing'">stop</i>
              <div class="audio-loading" *ngIf="audionotfound == false && audioState == 'loading'"></div>
              <i class="material-icons" *ngIf="audionotfound == true && audioState == 'warning'">warning</i>
          </button>
      </h2>
  </div>
  <div class="explorer-occ-popup">
      <div class="common-occupation-popup" *ngIf="data.dialog_data != undefined" p-0>
          <div class="common-occ-popup-image desktop-design-show" *ngIf="!videostatus">
              <img src="{{ occLargeCardAPIUrl }}{{
            data.dialog_data.media?.images.card
          }}" alt="{{ data.dialog_data.title }}" class="w-100" />
              <div class="opacity-text" *ngIf="(videoData)">
                  <p>
                      <img pointer src="assets/images/video-icon.png" alt="video-icon" (click)="videoPlay()" />
                  </p>
              </div>
          </div>
          <div *ngIf="videostatus">
              <div role="document">
                  <div>
                      <div>
                          <button type="button" class="closevideo" (click)="videostop()">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div>
                          <video *ngIf="videoData.textSrc; else notrack" crossorigin="anonymous"
                              disablePictureInPicture class="videobox" controls controlsList="nodownload">
                              <source src="{{ videoData?.videoSrc }}" type="video/mp4" />
                              <track src="{{ videoData?.textSrc }}" type="text/vtt" kind="subtitles" srclang="en"
                                  label="English" default />
                          </video>
                          <ng-template #notrack>
                              <video disablePictureInPicture class="videobox" controls controlsList="nodownload">
                                  <source src="{{ videoData?.videoSrc }}" type="video/mp4" />
                              </video>
                          </ng-template>
                      </div>
                  </div>
              </div>
          </div>
          <div class="custom-scroll library-career_popup">
          <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutWrap="wrap" fxLayoutAlign="center"
              d-block-mobile class="occ-popup-body" flex v-center>
              <div fxFlex="" flex v-center h-center flex-column class="common-occ-text text-center">
                  <h1 color="primary" class="desktop-design-show" m-0 text-color="primary">
                      {{ data.dialog_data.title }}
                  </h1>
                  <p m-0 class="custom-occ-text text-left">
                      {{ data.dialog_data.description }}
                  </p>
                  <mat-card class="card box-shadow-none scout-brdr custom-personality-type-card"
                      *ngIf="data.tabname== 'group'">
                      <div *ngIf="iconName !== ''" class="card-body text-center" flex v-center h-center>
                          <img class="scout-img" src="{{assetsURL}}{{iconName}}.svg" />
                          <span v-center m-0>{{data.dialog_data.category.title}} </span>
                      </div>
                  </mat-card>
                  
                  <mat-card class="card desktop-design-show box-shadow-none navigator-brdr custom-personality-type-card"
                      *ngIf="data.tabname== 'value'">
                      <div flex v-center h-center>
                          <span m-0>{{keywords?.dictionary?.workValues}}:</span>
                          <div class="card-body text-center" flex v-center h-center
                              *ngFor="let item of navigatorWorkVaues">
                              <div class="icon-hovertitle hover-title">
                                  <img class="navigator-img" src="./assets/Svg/{{item?.icon}}.svg" />

                                  <span>{{item?.displayName}}</span>
                              </div>
                          </div>
                      </div>
                  </mat-card>
                  <div class="mobile-design-show custom-personality-type-card" *ngIf="data.tabname== 'value'">
                      <mat-card flex v-center h-center
                          class="card box-shadow-none navigator-brdr custom-personality-type-card">
                          <div flex v-center h-center>
                              <span m-0>{{keywords?.dictionary?.workValues}}:</span>
                              <div class="card-body text-center" flex v-center h-center
                                  *ngFor="let item of navigatorWorkVaues">

                                  <img class="navigator-img" src="./assets/Svg/{{item?.icon}}.svg" />

                              </div>
                          </div>
                      </mat-card>
                  </div>
                  <mat-card class="card desktop-design-show box-shadow-none pf-blue-brdr custom-personality-type-card"
                      *ngIf="data.tabname == 'hollandcode'">
                      <div class="card-body text-center" flex v-center h-center>
                          <p v-center m-0>
                              {{ keywords.dictionary.personalityTypes }}:
                          </p>
                          <div *ngFor="let icon of iconsList">
                              <div class="icon-hovertitle hover-title">
                                  <img *ngIf="icon.hollandCode" src="./assets/pathfinder/holland/hc_{{
                      icon.hollandCode
                    }}.svg" alt="{{ icon.hollandCode }}" />
                                  <span>{{ icon.title }}</span>
                              </div>
                          </div>
                      </div>
                  </mat-card>
                  <div class="mobile-design-show custom-personality-type-card">
                      <mat-card class="card box-shadow-none" *ngIf="data.tabname == 'hollandcode'">
                          <mat-card-title class="card-header" m-0 background-color="primary">
                              <p m-0 class="text-center">
                                  {{ keywords.dictionary.personalityTypes }} :
                              </p>
                          </mat-card-title>
                          <mat-card-content flex v-center h-center class="mobile-popup-image">
                              <div *ngFor="let icon of iconsList">
                                  <div class="icon-hovertitle hover-title">
                                      <img *ngIf="icon.hollandCode" src="./assets/pathfinder/holland/hc_{{
                        icon.hollandCode
                      }}.svg" alt="{{ icon.hollandCode }}" />
                                      <span>{{ icon.title }}</span>
                                  </div>
                              </div>
                          </mat-card-content>
                      </mat-card>
                  </div>
              </div>
              <div fxFlex="" class="common-occ-actor-text">
                  <p m-0 text-color="primary">
                      {{ data.dialog_data.tasks.title }}
                  </p>
                  <ul class="list-style-none" p-0 m-0>
                      <li flex *ngFor="let item of data.dialog_data.tasks.items">
                          <i class="material-icons" text-color="primary"> done</i>
                          {{ item }}
                      </li>
                  </ul>
              </div>
              <div [ngClass]="(data.tabname == 'value')? 'd-none':'d-block'">
                  <div class="common-occ-popup-card mobile-design-show w-100" *ngIf="(data.tabname != 'hollandcode') && (data.tabname == 'value')"
                      [ngClass]="
            (attributesDatahigh.length != 0 &&
              attributesDatamedium.length != 0 &&
              attributesDatalow.length == 0) ||
            (attributesDatahigh.length != 0 &&
              attributesDatamedium.length == 0 &&
              attributesDatalow.length != 0) ||
            (attributesDatahigh.length == 0 &&
              attributesDatamedium.length != 0 &&
              attributesDatalow.length != 0)
              ? 'tabhHalfWidth'
              : (attributesDatamedium.length != 0 &&
                  attributesDatamedium.length == 0 &&
                  attributesDatalow.length == 0) ||
                (attributesDatamedium.length == 0 &&
                  attributesDatamedium.length != 0 &&
                  attributesDatalow.length == 0) ||
                (attributesDatamedium.length == 0 &&
                  attributesDatamedium.length == 0 &&
                  attributesDatalow.length != 0)
              ? ''
              : 'tabFullwidth'
          ">
                      <mat-card class="tabs-card m-auto">
                          <mat-tab-group>
                              <mat-tab label="Tab 1" *ngIf="attributesDatahigh.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button green" flex v-center h-center>
                                          <span>{{ keywords.dictionary.high }}</span>
                                      </div>
                                  </ng-template>
                                  <!--used ng class to get the justify end and start for the high,medium and low buttons with respective to their positions -->
                                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                      fxLayoutGap.xs="2px" [ngClass]="
                    (attributesDatahigh.length != 0 &&
                      attributesDatamedium.length != 0 &&
                      attributesDatalow.length != 0) ||
                    (attributesDatahigh.length != 0 &&
                      attributesDatamedium.length != 0) ||
                    (attributesDatahigh.length != 0 &&
                      attributesDatalow.length != 0)
                      ? 'hightab-class'
                      : ''
                  ">
                                      <div fxFlex="" *ngFor="let item of attributesDatahigh" flex-basis flex h-center
                                          flex-grow-inherit>
                                          <div class="circle-img green">
                                              <div class="icon-hovertitle" #tooltip11="matTooltip"
                                                  (click)="tooltip11.show()" (blur)="tooltip11.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 2" *ngIf="attributesDatamedium.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button red" flex v-center h-center>
                                          <span>{{ keywords.dictionary.medium }}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                      fxLayoutGap.xs="2px" [ngClass]="
                    attributesDatahigh.length != 0 &&
                    attributesDatamedium.length != 0 &&
                    attributesDatalow.length != 0
                      ? 'justify-center'
                      : attributesDatahigh.length != 0 &&
                        attributesDatamedium.length != 0
                      ? 'lowtab-class'
                      : 'hightab-class'
                  ">
                                      <div fxFlex="" *ngFor="let item of attributesDatamedium" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img orange">
                                              <div class="icon-hovertitle" #tooltip12="matTooltip"
                                                  (click)="tooltip12.show()" (blur)="tooltip12.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 3" *ngIf="attributesDatalow.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button purple" flex v-center h-center>
                                          <span>{{ keywords.dictionary.low }}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                      fxLayoutGap.xs="5px" [ngClass]="
                    (attributesDatahigh.length != 0 &&
                      attributesDatamedium.length != 0 &&
                      attributesDatalow.length != 0) ||
                    (attributesDatalow.length != 0 &&
                      attributesDatamedium.length != 0) ||
                    (attributesDatahigh.length != 0 &&
                      attributesDatalow.length != 0)
                      ? 'lowtab-class'
                      : ''
                  " justify-end>
                                      <div fxFlex="" *ngFor="let item of attributesDatalow" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img purple">
                                              <div class="icon-hovertitle" #tooltip13="matTooltip"
                                                  (click)="tooltip13.show()" (blur)="tooltip13.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                          </mat-tab-group>
                      </mat-card>
                  </div>
              </div>
              
          </div>
          <div class="common-occ-popup-card desktop-design-show task-section" flex v-center h-center>
              <div fxLayout="row" fxFlexFill class="common-row-sub rating-cards desktop-design-show"
                  fxLayoutGap="10px" fxLayoutGap.sm="5px">
                  <div fxFlex="" flex-basis *ngIf="attributesDatahigh.length != 0">
                      <mat-card class="card box-shadow-none">
                          <mat-card-title class="card-header green" flex v-center h-center>{{
                              keywords.dictionary.high }}</mat-card-title>
                          <ul class=" list-style-none icon-sec status-block" p-0>
                              <li class="status-circle green">
                                  <div class="desktop-design-show" fxLayout="row">
                                      <div class="circle-icon-block" fxFlex="" flex-basis
                                          *ngFor="let item of attributesDatahigh" flex h-center>
                                          <div class="circle-img green">
                                              <div class="icon-hovertitle" #tooltip14="matTooltip"
                                                  (click)="tooltip14.show()" (blur)="tooltip14.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </mat-card>
                  </div>
                  <div fxFlex="" flex-basis *ngIf="attributesDatamedium.length != 0">
                      <mat-card class="card box-shadow-none">
                          <mat-card-title class="card-header orange" flex v-center h-center
                              ngStyle.xs="background-color:#00000030;">
                              {{ keywords.dictionary.medium }}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" p-0>
                              <li class="status-circle orange">
                                  <div class="desktop-design-show" fxLayout="row">
                                      <div class="circle-icon-block" fxFlex="" flex-basis
                                          *ngFor="let item of attributesDatamedium" flex h-center>
                                          <div class="circle-img orange">
                                              <div class="icon-hovertitle" #tooltip15="matTooltip"
                                                  (click)="tooltip15.show()" (blur)="tooltip15.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </mat-card>
                  </div>
                  <div fxFlex="" flex-basis *ngIf="attributesDatalow.length != 0">
                      <mat-card class="card box-shadow-none">
                          <mat-card-title class="card-header purple" flex v-center h-center
                              ngStyle.xs="background-color:#00000030;">
                              {{ keywords.dictionary.low }}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" start p-0>
                              <li class="status-circle purple">
                                  <div class="desktop-design-show" fxLayout="row">
                                      <div class="circle-icon-block" fxFlex="" flex-basis
                                          *ngFor="let item of attributesDatalow" flex h-center>
                                          <div class="circle-img purple">
                                              <div class="icon-hovertitle" #tooltip16="matTooltip"
                                                  (click)="tooltip16.show()" (blur)="tooltip16.hide()"
                                                  matTooltip="{{ item.name | titlecase }}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img *ngIf="data.tabname === 'skill'" class="w-100"
                                                      src="{{ assetsURL }}{{ item.icon }}.svg" />
                                                  <img *ngIf="data.tabname != 'skill'" class="w-100"
                                                      src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </mat-card>
                  </div>
              </div>
          </div>
      </div>
    </div>
      <div class="common-occ-popup-footer" flex v-center>
          <div flex v-center class="w-100 mobile-text-center">
              <p m-0 flex v-center font-bold>
                  {{ keywords.dictionary.rateOcc }}:
              </p>
              <img src="{{ assetsURL }}thumbs-up-outline-grey.svg" pointer alt="thumbup" *ngIf="thumb != 'thumb_up'"
                  (click)="thumb = 'thumb_up'; thumbUpAndDown('thumb_up')" tabindex="0"
                  (keypress)="thumb = 'thumb_up'; thumbUpAndDown('thumb_up')" class="ng-star-inserted" flex v-center
                  pointer />
              <img src="{{ assetsURL }}thumbs-up-hover-mob.svg" (click)="
            thumb = 'thumb_up_deselect';
            thumbUpAndDown('thumb_up_deselect')
          " tabindex="0" (keypress)="
            thumb = 'thumb_up_deselect';
            thumbUpAndDown('thumb_up_deselect')
          " alt="thumbup-filled-icon" *ngIf="thumb == 'thumb_up'" />
          </div>
      </div>
  </div>
</div>
</div>

`,
  styleUrls: [],
})
export class ExplorerOccupationsPopUpComponent {
  videostatus = false;
  videoData: any;
  attributesDatalow: any = [];
  attributesDatamedium: any = [];
  attributesDatahigh: any = [];
  iconsList: any = [];
  occLargeCardAPIUrl: any;
  assetsURL: string;
  audionotfound = false;
  audioState = "stoped";
  compActive = true;
  navigatorWorkVaues: any = [];
  // assetsUrls = JSON.parse(sessionStorage.getItem('assetsData'));
  // imageurl = this.assetsUrls.images;
  // buttonText: any;
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  iconName: string;
  // routerLink="/explorer-occs/assesment"
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("OCC_LARGE_CARD_URL") private occCardUrl: string,
    @Inject("ASSETS_MEDIA_URL") private assetsUrl: string,
    public eventDispatcher: EventdispatchService,
    private audiostopevent: EventdispatchService
  ) {
    this.occLargeCardAPIUrl = occCardUrl;
    this.assetsURL = assetsUrl;
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result) {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    if (this.data.like === "like") {
      this.thumb = "thumb_up";
    } else if (this.data.like === "unlike") {
      this.thumb = "thumb_down";
    } else {
      this.thumb = "";
    }
    if (
      data.dialog_data.attributes != undefined &&
      data.attributes != undefined
    ) {
      for (let i = 0; i < data.dialog_data.attributes.length; i++) {
        for (let j = 0; j < data.attributes.length; j++) {
          if (data.dialog_data.attributes[i].type == "group") {
            if (
              data.dialog_data.category.title
            ) {
              if (parseInt(data.dialog_data.category.categoryId) === 1) {
                this.iconName = "EXPLORER_PEOPLE"
              }
              else if (parseInt(data.dialog_data.category.categoryId) === 2) {
                this.iconName = "EXPLORER_IDEAS"
              }
              else if (parseInt(data.dialog_data.category.categoryId) === 3) {
                this.iconName = "EXPLORER_THINGS"
              }
            }
          }
          if (data.dialog_data.attributes[i].type == "value") {
            if (
              data.attributes[j].name === data.dialog_data.attributes[i].name && data.attributes[j].type == "value"
            ) {
              this.navigatorWorkVaues.push(data.attributes[j]);
            }

          }
          if (data.dialog_data.attributes[i].type == data.tabname) {
            if (
              data.attributes[j].name == data.dialog_data.attributes[i].name &&
              data.attributes[j].type == data.tabname
            ) {
              if (data.dialog_data.attributes[i].weight == "high") {
                this.attributesDatahigh.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "medium") {
                this.attributesDatamedium.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "low") {
                this.attributesDatalow.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              }
            } else {
              if (
                data.dialog_data.attributes[i].name ===
                data.attributes[j].hollandCode
              ) {
                this.iconsList.push({
                  hollandCode: data.dialog_data.attributes[i].name,
                  title: data.attributes[j].displayName,
                });
              }
            }
          }
        }
      }
    }
    if (data.dialog_data.media && data.dialog_data.media.video) {
      this.videoData = data.dialog_data.media.video[0];
    }
  }
  thumb = "";
  explorerPopupClose() {
    //Function to stop audio by clicking close button in explorer modal popup.
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
  }
  audioClick(text) {
    this.videostatus = false;
    //Function to play audio by clicking audiobutton in career Library.
    this.audioService.audioFunction(text, this.data.dialog_data);
  }
  videostop() {
    this.videostatus = false;
  }
  videoPlay() {
    this.stopAudio();
    this.videostatus = true;
  }
  stopAudio() {  //to stop current playing audio
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventDispatcher.dispatchAudioStop(evnt);
		  }
  ngOnDestroy() {
    this.compActive = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
  }
  //method that tracks the state of the thumbs and update accordingly;
  thumbUpAndDown(data) {
    var evnt = document.createEvent("CustomEvent");
    evnt.initCustomEvent("thumbEvnt", true, true, data);
    this.eventDispatcher.dispatchThumbUpEvent(evnt);
  }
}

/*** Explorer-Occupation Popup End  ***/

/***  Explorer Congratulations Popup Start***/

@Component({
  selector: "app-congratulations-explorer-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="explorer-challenge-popup">
        <div class="congratulations-popup">
          <div class="cong-popup-body">
            <div class="smiley" flex v-center h-center>
              <img
                src="./assets/images/smile-happy.png"
                alt="congratulations"
              />
            </div>
            <div class="popup-text" flex v-center h-center>
              <p class="text-center">
                {{ congratsText }}<br />{{ displayText }}
              </p>
            </div>
          </div>
          <div class="popup-footer">
            <p color="primary" class="text-center" font-bold>
              {{
                keywords.dictionary.scoreChallenge
                  | ScorePipe: data.dialog_data:54
              }}
            </p>
          </div>
        </div>
        <div class="popup-buttons" mat-dialog-actions flex v-center h-center>
          <button
            mat-raised-button
            color="primary"
            [mat-dialog-close]="'library'"
            class="common-buttons"
            flex
            v-center
          >
            <i class="material-icons">play_circle_outline</i
            >{{ keywords.dictionary.next }}
          </button>
          <button
            mat-raised-button
            [mat-dialog-close]="'closed'"
            class="common-buttons green"
            flex
            v-center
          >
            <i class="material-icons">sync</i>{{ playAgainButton }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CongratulationsExplorerPopUpComponent {
  keywords: any;
  playAgainButton: any;
  continueButton: any;
  pointsText: any;
  congratsText: any;
  displayText: any;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.congratsText = this.keywords.dictionary.congratulations;
    this.displayText = this.keywords.dictionary.completedChallenge;
    this.pointsText = this.keywords.dictionary.points;
    this.continueButton = this.keywords.dictionary.continue;
    this.playAgainButton = this.keywords.dictionary.playAgain;
  }
}
/***  Explorer Congratulations Popup  End***/

/*** Pathfinder Congratulations Popup  Start ***/
@Component({
  selector: "app-congratulations-common-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="pathfinder-congratulation-popup">
        <div class="congratulations-popup">
          <div class="cong-popup-body">
            <div class="smiley" flex v-center h-center>
              <img
                src="./assets/images/smile-happy.png"
                alt="congratulations"
              />
            </div>
            <div class="popup-smiley-text" flex v-center h-center>
              <p m-0 class="text-center">
                {{ congratsText }}<br />{{ displayText }}
              </p>
            </div>
            <div class="popup-footer">
              <p m-0 class="text-center">
                <span class="green-text">
                  {{
                    pathFinderText?.dictionary?.scoreChallenge
                      | challengePipe: data?.dialog_data:6
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="popup-buttons" mat-dialog-actions flex v-center h-center>
          <button
            mat-raised-button
            color="primary"
            [mat-dialog-close]="'library'"
            class="common-buttons path-blue"
            flex
            v-center
          >
            <i class="material-icons">play_circle_outline</i
            >{{ keywords.dictionary.next }}
          </button>
          <button
            mat-raised-button
            [mat-dialog-close]="'closed'"
            class="common-buttons green"
            flex
            v-center
          >
            <i class="material-icons">sync</i>{{ playAgainButton }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CongratulationsPathfinderPopUpComponent {
  keywords: any;
  displayText: any;
  pointsText: any;
  continueButton: any;
  playAgainButton: any;
  congratsText: any;
  pathFinderText: any;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.congratsText = this.keywords.dictionary.congratulations;
    this.displayText = this.keywords.dictionary.completedChallenge;
    this.pointsText = this.keywords.dictionary.points;
    this.continueButton = this.keywords.dictionary.continue;
    this.playAgainButton = this.keywords.dictionary.playAgain;
    this.pathFinderText = this.data.pathfinder_data;
  }
}

/*** Pathfinder Congratulations Popup  End***/

/*** CareerTrekker Congratulations Popup  Start ***/
@Component({
  selector: "app-congratulations-explorer-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="careertrekker-congratulations-popup">
        <div class="congratulations-popup">
          <div class="cong-popup-body">
            <div class="smiley" flex v-center h-center>
              <img
                src="./assets/images/smile-happy.png"
                alt="congratulations"
              />
            </div>
            <div class="popup-smiley-text" flex v-center h-center>
              <p m-0 class="text-center">
                {{ congratsText }}<br />{{ displayText }}
              </p>
            </div>
            <div class="popup-footer">
              <p m-0 class="text-center">
                <span class="green-text">
                  {{
                    keywords.dictionary.scoreChallenge
                      | ScorePipe: data.dialog_data:100
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="popup-buttons" mat-dialog-actions flex v-center h-center>
          <button
            mat-raised-button
            color="primary"
            [mat-dialog-close]="'library'"
            class="common-buttons"
            flex
            v-center
          >
            <i class="material-icons">play_circle_outline</i
            >{{ keywords.dictionary.next }}
          </button>
          <button
            mat-raised-button
            [mat-dialog-close]="'closed'"
            class="common-buttons green"
            flex
            v-center
          >
            <i class="material-icons">sync</i>{{ playAgainButton }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CongratulationsCareerTrekkerPopUpComponent {
  keywords: any;
  congratsText: any;
  displayText: any;
  pointsText: any;
  continueButton: any;
  playAgainButton: any;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.congratsText = this.keywords.dictionary.congratulations;
    this.displayText = this.keywords.dictionary.completedChallenge;
    this.pointsText = this.keywords.dictionary.points;
    this.continueButton = this.keywords.dictionary.continue;
    this.playAgainButton = this.keywords.dictionary.playAgain;
  }
}

/*** CareerTrekker Congratulations Popup  Start ***/

/**** Mobile Occupations filter popup */
@Component({
  selector: "app-library-trekker-filter-popup",
  template: ` <div class="{{ data.page_theme_color }}">
    <div class="occupation-explorer">
      <div class="custom-tabs">
        <div class="filter-head">
          <p class="text-center" m-0 text-color="primary">
            {{ keywords.dictionary.filterSkills }}
          </p>
        </div>
        <mat-tab-group
          class="{{ themeColor }}"
          [(selectedIndex)]="tabSelectedIndex"
        >
          <mat-tab *ngIf="module == 'Scout' || module == 'All'">
            <ng-template mat-tab-label>
              <div
                class="tab-button scout"
                #tooltip="matTooltip"
                flex
                v-center
                h-center
                (click)="
                  tabClick('group'); changeTheme('scout'); tooltip.show()
                "
                (blur)="tooltip.hide()"
                matTooltipClass="custom-tooltip"
                matTooltip="{{
                  commonLibraryHeading?.dictionary?.submodule?.scout?.tab
                    | libraryScoutPipe
                      : keywords?.dictionary?.filterBy
                      : keywords?.dictionary?.group
                }}"
              >
                <img
                  src="{{ assestsUrl }}Scout.svg"
                  alt="scout-image"
                  [ngClass]="tabSelectedIndex == 0 ? 'rotate-animation' : ''"
                />
                <span
                  class="tab-name"
                  [ngClass]="!(module == 'All') ? 'tab-pane-title' : ''"
                  >{{ tabnames[8] }}</span
                >
              </div>
            </ng-template>
            <div
              class="card circle-image-list-status custom-library-circle scout-body"
              flex
              v-center
              *ngFor="let a of scout; index as i"
            >
              <div
                class=" circle-img "
                flex
                v-center
                h-center
                *ngIf="scout != undefined"
              >
                <img
                  *ngIf="a.icon"
                  src="{{ assestsUrl }}{{ a.icon }}.svg"
                  alt="{{ a.name }}"
                />
              </div>
              <div
                (click)="
                  scoutClick(a.title, i);
                  modalarray[i] == ''
                    ? (modalarray[i] = 'All')
                    : (modalarray[i] = '')
                "
              >
                <mat-checkbox [checked]="true" *ngIf="modalarray[i] == 'All'">
                  <p d-none>text</p>
                </mat-checkbox>
                <mat-checkbox *ngIf="modalarray[i] == ''">
                  <p d-none>text</p>
                </mat-checkbox>
              </div>
              <div class="radio-options">
                <p m-0>{{ a?.title | titlecase }}</p>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="module === 'Navigator' || module == 'All'">
            <ng-template mat-tab-label>
              <div
                class="tab-button navigator"
                #tooltip="matTooltip"
                flex
                v-center
                h-center
                (click)="
                  tabClick('value'); changeTheme('navigator'); tooltip.show()
                "
                (blur)="tooltip.hide()"
                matTooltipClass="custom-tooltip"
                matTooltip="{{
                  commonLibraryHeading?.dictionary?.submodule?.navigator?.tab
                    | libraryNavigatorPipe
                      : keywords?.dictionary?.filterBy
                      : keywords?.dictionary?.workValue
                }}"
              >
                <img
                  src="{{ assestsUrl }}navigator.svg"
                  alt="navigator"
                  [ngClass]="tabSelectedIndex == 1 ? 'rotate-animation' : ''"
                />
                <span
                  class="tab-name"
                  [ngClass]="!(module == 'All') ? 'tab-pane-title' : ''"
                  >{{ tabnames[6] }}</span
                >
              </div>
            </ng-template>
            <div
              *ngFor="let nav of navigatorData; index as i"
              class="card circle-image-list-status custom-library-circle navigator-body"
              flex
              v-center
            >
              <div class="circle-img " flex v-center h-center>
                <img src="./assets/Svg/{{ nav.icon }}.svg" alt="navigator" />
              </div>
              <div
                (click)="
                  navigatorClick(nav.name, i);
                  modalarray[i] == ''
                    ? (modalarray[i] = 'All')
                    : (modalarray[i] = '')
                "
              >
                <mat-checkbox [checked]="true" *ngIf="modalarray[i] == 'All'">
                </mat-checkbox>
                <mat-checkbox *ngIf="modalarray[i] == ''"> </mat-checkbox>
              </div>
              <div class="radio-options">
                <p m-0>{{ nav.title }}</p>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="module == 'Explorer' || module == 'All'">
            <ng-template mat-tab-label>
              <div
                [ngClass]="{ 'library-single-tab': module == 'Explorer' }"
                class="tab-button explorer"
                flex
                v-center
                h-center
                (click)="
                  tabClick('characteristic');
                  changeTheme('explorer');
                  tooltip.show()
                "
                (blur)="tooltip.hide()"
                matTooltipClass="custom-tooltip"
                #tooltip="matTooltip"
                matTooltip="{{
                  commonLibraryHeading?.dictionary?.submodule?.explorer?.tab
                    | libraryCharacteristicPipe
                      : keywords?.dictionary?.filterBy
                      : keywords?.dictionary?.characteristic
                }}"
              >
                <img
                  src="assets/Svg/globe.svg"
                  alt="globe-image"
                  [ngClass]="tabSelectedIndex == 2 ? 'rotate-animation' : ''"
                />
                <span
                  class="tab-name"
                  [ngClass]="!(module == 'All') ? 'tab-pane-title' : ''"
                  >{{ tabnames[0] }}</span
                >
              </div>
            </ng-template>
            <div
              class="card circle-image-list-status custom-library-circle explorer-body"
              flex
              v-center
              *ngFor="let a of arr"
              (click)="changeTheme('explorer')"
            >
              <!-- <div class="circle-img " flex v-center h-center>
                <div class="icon-globe"></div>
              </div>-->
              <div class="circle-img explorer" flex v-center h-center>
                <img src="assets/Svg/gloab.svg" alt="explorer icon" />
              </div>
              <div class="radio-options">
                <p>{{ keywords.dictionary.occGroup }}</p>
                <ul class="list-style-none" flex v-center p-0 m-0>
                  <li *ngFor="let l of list1" class="radio-list">
                    <mat-radio-group [(ngModel)]="occgroup2">
                      <mat-radio-button
                        (click)="occGroupClick(l.cateroryId)"
                        [value]="l.categoryId"
                      ></mat-radio-button>
                      <p>{{ l.title | titlecase }}</p>
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="card circle-image-list-status custom-library-circle explorer-body"
              flex
              v-center
              *ngFor="let a of explorer; index as i"
            >
              <div class="circle-img " flex v-center h-center>
                <img class="w-100" src="assets/Svg/{{ a.icon }}.svg" />
              </div>
              <div class="radio-options">
                <p>{{ a.title | titlecase }}</p>
                <ul class="list-style-none" flex v-center p-0 m-0>
                  <li *ngFor="let l of list" class="radio-list">
                    <mat-radio-group [(ngModel)]="modalarray[i]">
                      <mat-radio-button
                        (click)="explorerClick(l, a.name, i)"
                        value="{{ l }}"
                      ></mat-radio-button>
                      <span ngStyle.xs="display:none">&nbsp;</span>
                      <p m-0>{{ l | buttonTextChange: keywords.dictionary }}</p>
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="module == 'Pathfinder' || module == 'All'">
            <ng-template mat-tab-label>
              <div
                [ngClass]="{ 'library-single-tab': module == 'Pathfinder' }"
                class="tab-button pathfinder"
                flex
                v-center
                h-center
                (click)="
                  tabClick('hollandcode');
                  changeTheme('pathfinder');
                  tooltip.show()
                "
                (blur)="tooltip.hide()"
                matTooltipClass="custom-tooltip"
                #tooltip="matTooltip"
                matTooltip="{{
                  commonLibraryHeading?.dictionary?.submodule?.pathfinder?.tab
                    | libraryPathFinderPipe
                      : keywords?.dictionary?.filterBy
                      : keywords?.dictionary?.personalityType
                }}"
              >
                <img
                  src="./assets/Svg/compass.svg"
                  alt="compass-image"
                  [ngClass]="tabSelectedIndex == 3 ? 'rotate-animation' : ''"
                />
                <span
                  class="tab-name"
                  [ngClass]="!(module == 'All') ? 'tab-pane-title' : ''"
                  >{{ tabnames[1] }}</span
                >
              </div>
            </ng-template>
            <div
              class="card circle-image-list-status custom-library-circle pathfinder-body"
              flex
              v-center
              *ngFor="let a of pathfinder; index as i"
              (click)="changeTheme('pathfinder')"
            >
              <div
                class=" circle-img "
                flex
                v-center
                h-center
                *ngIf="pathfinder != undefined"
              >
                <img
                  *ngIf="a.icon"
                  src="assets/pathfinder/holland/{{ a.icon }}.svg"
                  alt="a.name"
                />
              </div>
              <div
                (click)="pathFinder(a.hollandCode, i)"
                (keypress.space)="pathFinder(a.hollandCode, i)"
              >
                <mat-checkbox
                  [checked]="true"
                  *ngIf="pathfinderfilterarray.indexOf(a.hollandCode) < 0"
                ></mat-checkbox>
                <mat-checkbox
                  *ngIf="pathfinderfilterarray.indexOf(a.hollandCode) >= 0"
                ></mat-checkbox>
              </div>
              <div class="radio-options">
                <p m-0>{{ a.displayName | titlecase }}</p>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="module == 'Careertrekker' || module == 'All'">
            <ng-template mat-tab-label>
              <div
                [ngClass]="{ 'library-single-tab': module == 'Careertrekker' }"
                class="tab-button careertrekker"
                flex
                v-center
                h-center
                (click)="
                  tabClick('skill');
                  changeTheme('careertrekker');
                  tooltip.show()
                "
                (blur)="tooltip.hide()"
                matTooltipClass="custom-tooltip"
                #tooltip="matTooltip"
                matTooltip="{{
                  commonLibraryHeading?.dictionary?.submodule?.careertrekker
                    ?.tab
                    | librarySkillPipe
                      : keywords?.dictionary?.filterBy
                      : keywords?.dictionary?.skill
                }}"
              >
                <img
                  src="./assets/Svg/direction.svg"
                  alt="direction-image"
                  [ngClass]="tabSelectedIndex == 4 ? 'rotate-animation' : ''"
                />
                <span
                  class="tab-name"
                  [ngClass]="!(module == 'All') ? 'tab-pane-title' : ''"
                  >{{ tabnames[2] }}</span
                >
              </div>
            </ng-template>
            <div
              class="card circle-image-list-status custom-library-circle trekker-body"
              flex
              v-center
              *ngFor="let a of careertrekker; index as i"
              (click)="changeTheme('careertrekker')"
            >
              <div
                class="circle-img "
                flex
                v-center
                h-center
                *ngIf="careertrekker != undefined"
              >
                <img
                  class="w-100"
                  src="{{ assestsUrl }}{{ a.icon }}.svg"
                  alt="{{ a.title }}"
                />
              </div>
              <div class="radio-options ">
                <p>{{ a.title | titlecase }}</p>
                <ul class="list-style-none" flex v-center p-0 m-0>
                  <li *ngFor="let l of list" class="radio-list">
                    <mat-radio-group [(ngModel)]="modalarray2[i]">
                      <mat-radio-button
                        (click)="careerTrekker(l, a.name, i)"
                        value="{{ l }}"
                      >
                        <span d-none>&nbsp;</span>
                      </mat-radio-button>
                      <p>{{ l | buttonTextChange: keywords.dictionary }}</p>
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="{{ themeColor }} w-100" pt-12>
          <p mb-1>Thought Box</p>
          <form>
            <textarea class="thought-box-input w-100"></textarea>
          </form>
        </div>
      </div>
      <div flex v-center h-center float-right>
        <button color="accent" mat-fab mt-5 (click)="close()" class="start">
          <i class="material-icons" flex>arrow_forward</i>
        </button>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class OccupationsFilterPopUpComponent implements OnInit {
  tabnames: any = [];
  tabSelectedIndex: number;
  unmodifieddata = [];
  resp = [];
  respdata = [];
  occgroup: any;
  prevWeight: any;
  finalSortFilter = [];
  filterreturnData: {}[];
  attributesData = [];
  weightarray = [];
  weightarray2 = [];
  filterArray = [];
  arr = [];
  modalarray = [];
  modalarray2 = [];
  pathfinderfilterarray = [];
  canScroll: boolean;
  tabname: any;
  filterThumb: string;
  commonLibraryHeading: any;
  themeColor;
  module: any;
  occgroup2 = 0;
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  scout = [];
  explorer = [];
  pathfinder = [];
  careertrekker = [];
  list = ["All", "High", "Medium", "Low"];
  list1: any = [];
  assestsUrl: string;
  navigtorData = [];
  navigatorfilter = [];
  navigatorData: any;
  scoutfilterarray: any;
  constructor(
    public filterDialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string
  ) {
    this.assestsUrl = assetsMediaUrl;
    this.tabnames = JSON.parse(sessionStorage.getItem("librarytabnames"));
    this.module = this.data.filterdata[0].module;
    this.arr = this.data.filterdata[0].arr;
    this.modalarray = this.data.filterdata[0].modalarray;
    this.modalarray2 = this.data.filterdata[0].modalarray2;
    this.attributesData = this.data.filterdata[0].attributesData;
    this.pathfinderfilterarray = this.data.filterdata[0].pathfinderfilterarray;
    this.occgroup2 = this.data.filterdata[0].occgroup2;
    this.tabname = this.data.filterdata[0].tabname;
    this.weightarray = this.modalarray;
    this.weightarray2 = this.modalarray2;
    this.resp = this.data.filterdata[0].resp;
    this.respdata = this.data.filterdata[0].respdata;
    this.unmodifieddata = this.data.filterdata[0].unmodifieddata;
    this.explorer = this.data.filterdata[0].explorer;
    this.scout = this.data.filterdata[0].scout;
    this.list1 = this.data.filterdata[0].list1;
    this.pathfinder = this.data.filterdata[0].pathfinder;
    this.careertrekker = this.data.filterdata[0].careertrekker;
    this.filterArray = this.data.filterdata[0].filterArray;
    this.commonLibraryHeading = this.data.filterdata[0].commonLibraryHeading;
    this.navigatorData = this.data.filterdata[0].navigatorData;
    this.navigatorfilter = this.data.filterdata[0].navigatorFilterArray;
    if (this.tabname === "group") {
      this.tabSelectedIndex = 0;
      this.changeTheme("scout");
    } else if (this.tabname === "value") {
      this.tabSelectedIndex = 1;
      this.changeTheme("navigator");
    } else if (this.tabname === "characteristic") {
      this.tabSelectedIndex = 2;
      this.changeTheme("explorer");
    } else if (this.tabname === "hollandcode") {
      this.tabSelectedIndex = 3;
      this.changeTheme("pathfinder");
    } else {
      this.tabSelectedIndex = 4;
      this.changeTheme("trekker");
    }
  }
  ngOnInit() {}

  dataforParticular(sessionname) {
    const resp = [];
    for (let i = 0; i < this.attributesData.length; i++) {
      if (this.attributesData[i].type == sessionname) {
        resp.push(this.attributesData[i]);
      }
    }
    if (sessionname == "characteristic") {
      this.explorer = resp;
      this.modalarray.length = this.explorer.length;
      this.filterArray.length = this.explorer.length;

      for (let i = 0; i < this.modalarray.length; i++) {
        this.modalarray[i] = "All";
        this.filterArray[i] = "";
      }
      this.weightarray = this.modalarray;
      if (this.module == "Explorer") {
        this.changeTheme("explorer");
      }
    } else if (sessionname == "group") {
      this.changeTheme("scout");
      this.scout = resp;
      this.modalarray.length = this.scout.length;
      this.filterArray.length = this.scout.length;
      for (let i = 0; i < this.modalarray.length; i++) {
        this.modalarray[i] = "All";
        this.filterArray[i] = "";
      }
      for (let i = 0; i < this.modalarray.length; i++) {
        this.modalarray[i] = "All";
        this.filterArray[i] = "";
        this.scoutClick(this.scout[i].title, i);
      }
    } else if (sessionname == "hollandcode") {
      this.pathfinder = resp;
      this.modalarray.length = this.pathfinder.length;
      this.filterArray.length = this.pathfinder.length;
      if (
        sessionStorage.getItem("pathfinderlike") != undefined &&
        sessionStorage.getItem("pathfinderlike") != null &&
        sessionStorage.getItem("pathfinderlike") != "" &&
        this.module == "Pathfinder"
      ) {
        this.changeTheme("pathfinder");
        const like = JSON.parse(sessionStorage.getItem("pathfinderlike"));
        for (let i = 0; i < this.modalarray.length; i++) {
          this.pathfinderfilterarray.push(this.pathfinder[i].hollandCode);
          this.modalarray[i] = "";
          this.filterArray[i] = "";
        }
      } else {
        for (let i = 0; i < this.modalarray.length; i++) {
          this.modalarray[i] = "All";
          this.filterArray[i] = "";
        }
      }
      this.weightarray = this.modalarray;
    } else if (sessionname == "value") {
      this.changeTheme("navigator");
      this.navigatorData = resp;
      this.navigatorfilter = []; // for reassigning the data
      this.modalarray.length = this.navigatorData.length;
      for (let i = 0; i < this.modalarray.length; i++) {
        this.navigatorfilter.push(this.navigatorData[i].title);
        this.modalarray[i] = "";
        this.filterArray[i] = "";
      }
      for (let i = 0; i < this.modalarray.length; i++) {
        this.modalarray[i] = "All";
        this.filterArray[i] = "";
        this.navigatorClick(this.navigatorData[i].title, i);
      }
    } else if (sessionname == "skill") {
      this.careertrekker = resp;
      this.modalarray2.length = this.careertrekker.length;
      this.filterArray.length = this.careertrekker.length;
      if (
        sessionStorage.getItem("Careertrekkerlike") != undefined &&
        sessionStorage.getItem("Careertrekkerlike") != null &&
        sessionStorage.getItem("Careertrekkerlike") != "" &&
        this.module == "Careertrekker"
      ) {
        this.changeTheme("careertrekker");
        const likearray = JSON.parse(
          sessionStorage.getItem("Careertrekkerlike")
        );
        const like = [likearray[0].name, likearray[1].name];
        for (let i = 0; i < this.modalarray2.length; i++) {
          this.modalarray2[i] = "All";
          this.weightarray2[i] = "All";
          this.filterArray[i] = "";
        }
        for (let i = 0; i < this.careertrekker.length; i++) {
          if (like.indexOf(this.careertrekker[i].name) >= 0) {
            this.modalarray2[i] = "High";
            this.weightarray2[i] = "High";
          }
        }
      } else {
        for (let i = 0; i < this.modalarray2.length; i++) {
          this.modalarray2[i] = "All";
          this.filterArray[i] = "";
        }
      }
    }
  }
  changeTheme(themeColor) {
    this.themeColor = themeColor;
  }
  tabClick(url) {
    if (url == "group") {
      this.tabSelectedIndex = 0;
    } else if (url == "value") {
      this.tabSelectedIndex = 1;
    } else if (url == "characteristic") {
      this.tabSelectedIndex = 2;
    } else if (url == "hollandcode") {
      this.tabSelectedIndex = 3;
    } else if (url == "skill") {
      this.tabSelectedIndex = 4;
    }
    this.filterThumb = "";
    this.tabname = url;
    this.canScroll = true;
    this.modalarray = [];
    this.modalarray2 = [];
    this.occgroup = 0;
    this.occgroup2 = 0;
    this.respdata = this.resp;
    this.pathfinderfilterarray = [];
    this.dataforParticular(url);
  }
  close() {
    this.filterreturnData = [
      {
        weightarray: this.weightarray,
        filterArray: this.filterArray,
        modalarray2: this.modalarray2,
        weightarray2: this.weightarray2,
        modalarray: this.modalarray,
        themeColor: this.themeColor,
        tabname: this.tabname,
        occgroup2: this.occgroup2,
        scout: this.scout,
        navigatorData: this.navigatorData,
        pathfinderfilterarray: this.pathfinderfilterarray,
        scoutfilterarray: this.scoutfilterarray,
        navigatorfilter: this.navigatorfilter,
        module: this.module,
        respdata: this.respdata,
        explorer: this.explorer,
        pathfinder: this.pathfinder,
        careertrekker: this.careertrekker,
        tabSelectedIndex: this.tabSelectedIndex,
      },
    ];
    this.filterDialogRef.close(this.filterreturnData);
  }
  filter(type, name, weight, resp) {
    const respdata1 = [];
    this.canScroll = false;
    let data = [];
    if (resp != null && resp != "") {
      data = resp;
    }
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].attributes.length; j++) {
        if (data[i].attributes[j].type == type) {
          if (data[i].attributes[j].name == name) {
            if (
              data[i].attributes[j].weight == weight.toLowerCase() ||
              weight == "All"
            ) {
              respdata1.push(data[i]);
            }
          }
        }
      }
    }
    this.finalSortFilter = respdata1;
    this.prevWeight = weight;
    return this.finalSortFilter;
  }
  occGroupClick(category) {
    if (this.occgroup != category) {
      this.canScroll = true;
      this.respdata = [];
      for (let i = 0; i < this.resp.length; i++) {
        if (this.resp[i].category.categoryId == category || category == 0) {
          this.respdata.push(this.resp[i]);
        }
      }
      this.unmodifieddata = this.respdata;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          this.explorerClick(this.weightarray[i], this.filterArray[i], i);
        }
      }
      this.occgroup = category;
    }
  }
  explorerClick(weight, occgroup, inx) {
    this.filterThumb = "";
    this.weightarray[inx] = weight;
    this.canScroll = true;
    if (this.filterArray.indexOf(occgroup) < 0) {
      this.filterArray[inx] = occgroup;
      let data;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              this.unmodifieddata
            );
          } else {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    } else {
      let data;
      for (let i = 0; i < this.weightarray.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              this.unmodifieddata
            );
          } else {
            data = this.filter(
              "characteristic",
              this.filterArray[i],
              this.weightarray[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    }
  }
  pathFinder(holland, inx) {
    this.filterThumb = "";
    this.canScroll = true;
    const respdata1 = [];
    if (this.pathfinderfilterarray.indexOf(holland) < 0) {
      this.pathfinderfilterarray.push(holland);
    } else {
      const a = this.pathfinderfilterarray.indexOf(holland);
      this.pathfinderfilterarray.splice(a, 1);
    }
    for (let i = 0; i < this.resp.length; i++) {
      for (let j = 0; j < this.resp[i].attributes.length; j++) {
        if (this.resp[i].attributes[j].type == "hollandcode") {
          if (
            this.pathfinderfilterarray.indexOf(
              this.resp[i].attributes[j].name
            ) < 0
          ) {
            respdata1.push(this.resp[i]);
            break;
          }
        }
      }
    }
    this.respdata = respdata1;
  }
  careerTrekker(weight, skill, inx) {
    this.filterThumb = "";
    this.unmodifieddata = this.resp;
    this.canScroll = true;
    this.weightarray2[inx] = weight;
    if (this.filterArray.indexOf(skill) < 0) {
      this.filterArray[inx] = skill;
      let data;
      for (let i = 0; i < this.weightarray2.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              this.resp
            );
          } else {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    } else {
      let data;
      for (let i = 0; i < this.weightarray2.length; i++) {
        if (this.filterArray[i] != "" && this.filterArray[i] != undefined) {
          if (i == 0) {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              this.resp
            );
          } else {
            data = this.filter(
              "skill",
              this.filterArray[i],
              this.weightarray2[i],
              data
            );
          }
        }
      }
      this.respdata = data;
    }
  }
  scoutClick(holland, inx) {
    this.filterThumb = "all";
    this.canScroll = true;
    const respdata1 = [];
    if (this.scoutfilterarray.indexOf(holland) < 0) {
      this.scoutfilterarray.push(holland);
    } else {
      const a = this.scoutfilterarray.indexOf(holland);
      this.scoutfilterarray.splice(a, 1);
    }
    if (this.resp && this.resp.length >= 0) {
      for (let i = 0; i < this.resp.length; i++) {
        for (let j = 0; j < this.resp[i].attributes.length; j++) {
          if (this.scoutfilterarray.indexOf(this.resp[i].category.title) < 0) {
            respdata1.push(this.resp[i]);
            break;
          }
        }
      }
    }

    this.respdata = respdata1;
  }

  navigatorClick(title, inx) {
    this.filterThumb = "all";
    this.canScroll = true;
    const respdata1 = [];
    if (this.navigatorfilter.indexOf(title) < 0) {
      this.navigatorfilter.push(title);
    } else {
      const a = this.navigatorfilter.indexOf(title);
      this.navigatorfilter.splice(a, 1);
    }
    if (this.resp && this.resp.length > 0) {
      for (let i = 0; i < this.resp.length; i++) {
        for (let j = 0; j < this.resp[i].attributes.length; j++) {
          if (this.resp[i].attributes[j].type == "value") {
            if (
              this.navigatorfilter.indexOf(this.resp[i].attributes[j].name) < 0
            ) {
              respdata1.push(this.resp[i]);
              break;
            }
          }
        }
      }
    }
    this.respdata = respdata1;
  }
}
/**** end of Mobile Occupations filter popup */

/**** Mobile careertrekker filter popup ****/
@Component({
  selector: "app-career-trekker-filter-popup",
  template: `
    <div class="{{ data.page_theme_color }}">
      <div class="careertrekker-filter-popup mobile-design-show">
        <div class="filter-head">
          <p class="text-center" m-0 text-color="primary">
            {{ keywords.dictionary.filterSkills }}
          </p>
        </div>
        <div class="careerTrekker-filter">
          <mat-accordion>
            <mat-expansion-panel m-0 [expanded]="goodtoogle">
              <mat-expansion-panel-header
                (click)="goodtoogle = true; imptoogle = false"
              >
                <mat-panel-title>
                  "{{ keywords.dictionary.goodAt }}"
                  {{ keywords.dictionary.skills }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                class="card circle-image-list-status"
                flex
                v-center
                *ngFor="let a of data.filterdata[0].good; let i = index"
              >
                <div class="circle-img" flex v-center h-center>
                  <img class="w-100" src="assets/Svg/{{ a.icon }}.svg" />
                </div>
                <div class="radio-options">
                  <p>{{ a.title | titlecase }}</p>
                  <ul class="list-style-none" flex v-center p-0 m-0>
                    <li *ngFor="let l of list1" class="radio-list">
                      <mat-radio-group [(ngModel)]="goodSkill[i]">
                        <mat-radio-button value="{{ l }}"></mat-radio-button>
                        <p>{{ l | buttonTextChange: keywords.dictionary }}</p>
                      </mat-radio-group>
                    </li>
                  </ul>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel m-0 [expanded]="imptoogle">
              <mat-expansion-panel-header
                (click)="goodtoogle = false; imptoogle = true"
              >
                <mat-panel-title>
                  "{{ keywords.dictionary.needToImprove }}"
                  {{ keywords.dictionary.skills }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                class="card circle-image-list-status"
                flex
                v-center
                *ngFor="let a of data.filterdata[0].imp; let i = index"
              >
                <div class="circle-img" flex v-center h-center>
                  <img class="w-100" src="assets/Svg/{{ a.icon }}.svg" />
                </div>
                <div class="radio-options">
                  <p>{{ a.title | titlecase }}</p>
                  <ul class="list-style-none" flex v-center p-0 m-0>
                    <li *ngFor="let l of list1" class="radio-list">
                      <mat-radio-group [(ngModel)]="impSkill[i]">
                        <mat-radio-button value="{{ l }}"></mat-radio-button>
                        <p>{{ l | buttonTextChange: keywords.dictionary }}</p>
                      </mat-radio-group>
                    </li>
                  </ul>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div flex v-center h-center float-right>
            <button color="accent" mat-fab mt-5 (click)="close()" class="start">
              <i class="material-icons" flex>arrow_forward</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [],
})
export class CareerTrekkerFilterPopUpComponent implements OnInit {
  filterModalData: { goodSkills: string[]; impSkills: string[] }[];
  list1 = ["All", "High", "Medium", "Low"];
  selector = ".infinet-scroll-height";
  goodSkill = ["High", "High"];
  impSkill = ["All", "All"];
  goodtoogle = true;
  imptoogle = false;
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {
    this.goodSkill = this.data.filterdata[0].goodSkills;
    this.impSkill = this.data.filterdata[0].impSkills;
  }
  close() {
    this.filterModalData = [
      {
        goodSkills: this.goodSkill,
        impSkills: this.impSkill,
      },
    ];
    this.dialogRef.close(this.filterModalData);
  }
}

/**** Mobile careertrekker filter popup ****/
/** Session Expire  popup */

@Component({
  selector: "app-session-expire",
  template: `<div class="session-expired">
    <div class="session-header">
      <h2 m-0 class="w-100" flex v-center background-color="primary">
        <span class="w-100" flex h-center>{{
          data?.dialog_data?.dictionary?.sessionExpired
        }}</span>
      </h2>
    </div>
    <div class="session-body">
      <img
        flex
        h-center
        src="./assets/images/session-expire.png"
        alt="sessionexpire"
      />
      <p m-0 class="text-center" flex v-center h-center>
        {{ data?.dialog_data?.dictionary?.sessionWarning }}
      </p>
      <p
        *ngIf="!showZeroSeconds"
        m-0
        class="text-center"
        flex
        v-center
        h-center
      >
        0{{ minites }} : {{ ("0" + seconds).slice(-2) }}
      </p>
      <p *ngIf="showZeroSeconds" m-0 class="text-center" flex v-center h-center>
        0{{ minites }} : {{ ("0" + zeroSeconds).slice(-2) }}
      </p>
    </div>
    <div class="session-footer" flex h-center>
      <button mat-raised-button mat-dialog-close (click)="extendSession()">
        {{ data?.dialog_data?.dictionary?.yes }}
      </button>
      <button mat-raised-button mat-dialog-close (click)="setClearTime()">
        {{ data?.dialog_data?.dictionary?.no }}
      </button>
    </div>
  </div>`,
})
export class SessionExpireComponent implements OnDestroy {
  timeoutVariable;
  timer = 120;
  minites = 2;
  seconds = 60;
  showZeroSeconds = true; //variable used for first time when the dialog pops up.
  zeroSeconds = 0; //variable used for first time when the dialog pops up.
  observableCalling = true;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventdispatchService
  ) {
    interval(1000)
      .pipe(takeWhile(() => this.observableCalling))
      .subscribe(() => {
        this.showZeroSeconds = false;
        //the if condition so that the negative countdown avoided as sometimes it was happening
        if (this.timer > 0) {
          this.timer--;
        }
        if (this.timer == 0) {
          this.setClearTime();
        }
        if (this.timer > 60) {
          this.minites = 1;
          this.seconds--;
        } else if (this.timer == 60) {
          this.minites = 0;
          this.seconds = 60;
        } else if (this.timer < 60) {
          this.minites = 0;
          //the if condition so that the negative countdown avoided as sometimes it was happening.
          this.seconds > 0 ? this.seconds-- : (this.seconds = 0);
        }
      });
  }
  Reload() {
    window.location.reload();
  }
  /**setClearTime function for modal closing at the time of sessionExpiration  */
  setClearTime() {
    this.timeoutVariable = setTimeout(
      function () {
        sessionStorage.removeItem("session_token");
        window.location.href = "/logout";
        sessionStorage.setItem("theme_color", "");
        const event = document.createEvent("CustomEvent");
        event.initEvent("themeChanged", true, true);
        this.eventService.dispatch(event);
        clearTimeout(this.timeoutVariable);
        window.sessionStorage.clear();
      }.bind(this),
      0
    );
  }
  ngOnDestroy() {
    this.observableCalling = false;
  }
  extendSession() {
    const evnt = document.createEvent("CustomEvent");
    evnt.initEvent("sessionExtend", true, true);
    this.eventService.dispatch(evnt);
  }
}

//Below is for Errors:
@Component({
  selector: "app-error",
  template: `<div class="session-expired">
    <div class="session-header">
      <h2 m-0 class="w-100" flex v-center background-color="primary">
        <span class="w-100" flex h-center>Error</span>
      </h2>
    </div>
    <div class="session-body">
      <p m-0 class="text-center" flex v-center h-center>
        {{ data?.dialog_data?.dictionary?.sessionExpiredInstruction }}
      </p>
    </div>
    <div class="session-footer" flex h-center>
      <button mat-raised-button mat-dialog-close (click)="setClearTime()">
        {{ data?.dialog_data?.dictionary?.logout }}
      </button>
    </div>
  </div>`,
})
export class ErrorPopUpComponent {
  timeoutVariable;
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  /**setClearTime function for modal closing at the time of sessionExpiration  */
  setClearTime() {
    sessionStorage.removeItem("session_token");
    window.location.href = "/logout";
    sessionStorage.setItem("theme_color", "");
    sessionStorage.setItem("pageTitle", "");
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    window.sessionStorage.clear();
  }
}
@Component({
  selector: "app-quickpic-intro-popup",
  template: ` <div class="{{ data.page_theme_color }}">
    <div class="common-popup quickpic-info-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100" flex v-center h-center background-color="primary">
          <button
            mat-fab
            class="volume-up"
            flex
            v-center
            h-center
            mat-dialog-close
          >
            <i class="material-icons">clear</i>
          </button>
          <span class="header-text w-100 text-left" flex v-center p-5
            >&nbsp;Info</span
          >
        </h2>
      </div>
      <div class="common-popup-body">
        <p font-bold>Options :</p>
        <p m-0>The responsive choices are:</p>
        <ul p-0 class="list-style-none w-100 smile-icons" m-0>
          <li m-0 flex v-center>
            <i class="material-icons" text-color="primary"
              >sentiment_very_satisfied</i
            >
            <h4 class="text-center" m-0>Like very much</h4>
          </li>
          <li m-0 flex v-center>
            <i class="material-icons" text-color="primary"
              >sentiment_very_satisfied</i
            >
            <h4 class="text-center" m-0>Like</h4>
          </li>
          <li m-0 flex v-center>
            <i class="material-icons" text-color="primary"
              >sentiment_dissatisfied</i
            >
            <h4 class="text-center" m-0>Not Sure</h4>
          </li>
          <li m-0 flex v-center>
            <i class="material-icons" text-color="primary"
              >sentiment_very_dissatisfied</i
            >
            <h4 class="text-center" m-0>Dislike</h4>
          </li>
        </ul>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class QuickpicIntroPopUpComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}
@Component({
  selector: "app-quickpic-delete-popup",
  template: ` <div class="{{ data.page_theme_color }}">
    <div class="common-popup quickpic-delete-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100 powder-blue" flex v-center h-center>
          <span class="header-text w-100 text-center" flex v-center h-center
            >{{ keywords.dictionary.delete }}
            <span *ngIf="data.dialog_data.activeset != 'clearBoard'"
              >&nbsp;{{ data.dialog_data.activeset }}</span
            >
          </span>
        </h2>
      </div>
      <div class=" popup-body text-center">
        <p font-bold *ngIf="data.dialog_data.activeset != 'clearBoard'">
          {{ keywords.dictionary.deleteConfirm }}
        </p>
        <p font-bold *ngIf="data.dialog_data.activeset == 'clearBoard'">
          {{ keywords.dictionary.clearConfirm }}
        </p>
        <span>{{ keywords.dictionary.undoneUnavailible }}</span>
      </div>
      <div flex v-center h-center>
        <button
          mat-raised-button
          [mat-dialog-close]="true"
          class="delete-popup-buttons powder-blue"
        >
          {{ keywords.dictionary.yes }}
        </button>
        <button
          mat-raised-button
          [mat-dialog-close]="false"
          class="delete-popup-buttons powder-blue"
        >
          {{ keywords.dictionary.cancel }}
        </button>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class QuickpicDeletePopUpComponent {
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}

@Component({
  selector: "app-quickpic-result-mob-popup",
  template: ` <div class="{{ data.page_theme_color }}">
    <div class="common-popup quickpic-delete-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100" background-color="primary" flex v-center h-center>
          <button
            mat-fab
            background-color="warn"
            class="volume-up mat-close-button"
            flex
            v-center
            h-center
            [mat-dialog-close]="false"
          >
            <i class="material-icons">clear</i>
          </button>
          <span class="header-text w-100" flex v-center h-center font-bold>{{
            data.dialog_data.exploreHeader
          }}</span>
        </h2>
      </div>
      <div class="common-popup-body">
        <p class="quickpic-result-text">{{ data.dialog_data.exploreMore }}</p>
        <div class="delete-popup-buttons" flex v-center h-center>
          <button
            background-color="accent"
            mat-button
            [mat-dialog-close]="true"
            (click)="navigateToIntro()"
          >
            {{ keywords.dictionary.occupationLibrary }}
          </button>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class QuickPicMobilePopupComponent {
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  navigateToIntro() {
    this.router.navigate(["/app/library-occupations"], {
      relativeTo: this.activatedRoute,
      queryParams: {
        module: "All",
      },
    });
  }
}

@Component({
  selector: 'app-scout-yes-popup',
  template: `
  <div class="{{data.page_theme_color}}">
    <div class="scout-activity-popup yes-popup">
      <div class="common-popup-header">
      <button mat-raised-button color="primary" class="audio-box volume-up" [mat-dialog-close]="false">
        <i class="material-icons">clear</i>
      </button>
      </div>
      <div class="common-popup-body text-center">
        <span><img src="{{assetsURL}}star.svg" class="icon-star" alt="star-icon"></span>
        <h3 text-color="primary">{{data.dialog_data.job}}<br>{{data.dialog_data.text}}</h3>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class ScoutActivityYesModalComponent {
  assetsURL: string;
  constructor(public dialogRef: MatDialogRef<DialogopenService>,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private activatedRoute: ActivatedRoute) {
    this.assetsURL = assetsUrl;
  }
}

@Component({
  selector: 'app-navigator-success-popup',
  template: `
  <div class="{{data.page_theme_color}}">
    <div class="navigator-success-popup">
    <div class="congratulations-popup">
      <div class="common-popup-body text-center">
        <span><img src="./assets/images/smile-happy.png" alt="congratulations"></span>
        <h3 text-color="primary">{{data.dialog_data}}</h3>
      </div>
      <div class="popup-buttons" mat-dialog-actions flex v-center h-center>
        <button mat-raised-button [mat-dialog-close]="'nextRound'" class="common-buttons path-blue"
          flex v-center>
          <i class="material-icons">play_circle_outline</i>{{keywordsData.dictionary.next}}
        </button>
        <button mat-raised-button [mat-dialog-close]="'playAgainRound'" class="common-buttons green"
          flex v-center>
          <i class="material-icons">sync</i>{{keywordsData.dictionary.playAgain}}
        </button>
      </div>
    </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class NavigatorActivitySuccessModalCompnent {
  assetsURL: string;
  keywordsData = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  constructor(public dialogRef: MatDialogRef<DialogopenService>,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private activatedRoute: ActivatedRoute) {
    this.assetsURL = assetsUrl;

  }
}

@Component({
  selector: 'app-scout-No-popup',
  template: `
  <div class="{{data.page_theme_color}}">
    <div class="scout-activity-popup">
      <div class="common-popup-header">
      <button mat-raised-button color="primary" class="audio-box volume-up" [mat-dialog-close]="false">
        <i class="material-icons">clear</i>
      </button>
      </div>
      <div class="common-popup-body text-center">
        <span><img src="assets/Svg/try-again.svg" class="try-again-image" alt="Try again"></span>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class ScoutActivityNoModalComponent {
  assetsURL: string;
  constructor(public dialogRef: MatDialogRef<DialogopenService>,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private activatedRoute: ActivatedRoute) {
    this.assetsURL = assetsUrl;
  }
}

@Component({
  selector: 'app-quickpic-result-popup',
  template: `
  <div class="{{data.page_theme_color}}">
    <div class="common-popup quickpic-result-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100" flex v-center h-center background-color="primary">
          <button
            mat-fab
            flex
            v-center
            h-center
            mat-dialog-close
            class="close-icon-button volume-up"
            (click)="initpopupClose()"
          >
            <i flex v-center class="material-icons">close</i>
          </button>
          <span class="header-text w-100" flex v-center h-center font-bold>{{
            data.dialog_data.title
          }}</span>
          <button
            mat-fab
            class="quickpic-vol-btn"
            [disabled]="audionotfound == true && audioState == 'warning'"
            flex
            v-center
            h-center
            (click)="audioClick('inside')"
          >
            <i
              class="material-icons"
              *ngIf="audionotfound == false && audioState == 'stoped'"
              >volume_up</i
            >
            <i
              class="material-icons"
              *ngIf="audionotfound == false && audioState == 'playing'"
              >stop</i
            >
            <div
              class="audio-loading"
              *ngIf="audionotfound == false && audioState == 'loading'"
            ></div>
            <i
              class="material-icons"
              *ngIf="audionotfound == true && audioState == 'warning'"
              >warning</i
            >
          </button>
        </h2>
      </div>
      <div class="common-popup-body">
        <div class="common-popup-body-image text-center">
          <img
            src="{{ occLargeCardAPIUrl }}{{
              data.dialog_data.media?.images.card
            }}"
            alt="{{ data.dialog_data.title }}"
            class="w-100"
          />
        </div>
        <div class="">
          <p font-bold text-color="primary" m-0>
            {{ data.dialog_data.tasks.title }}
          </p>
          <ul class="list-style-none" p-0 m-0>
            <li flex *ngFor="let item of data.dialog_data.tasks.items">
              <i class="material-icons" text-color="primary" font-bold> done</i>
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class QuickpicResultPopUpComponent {
  audioSubscription: any;
  audionotfound = false;
  audioState = "stoped";
  occLargeCardAPIUrl: string;
  assetsUrls = JSON.parse(sessionStorage.getItem("assetsData"));
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private audiostopevent: EventdispatchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("OCC_LARGE_CARD_URL") private occCardUrl: string
  ) {
    this.occLargeCardAPIUrl = occCardUrl;
    this.audioSubscription = this.audioStore
      .select("audio")
      .subscribe((result) => {
        if (result) {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
  }
  initpopupClose() {
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
  }
  audioClick(text) {
    this.audioService.audioFunction(text, this.data.dialog_data);
  }
  ngOnDestroy() {
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });

    this.audioSubscription.unsubscribe();
  }
}

@Component({
  selector: "app-landing-create-popup",
  template: ` <div class="{{ colors[theme] }}">
    <div class="landing-create-occ-popup common-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100" background-color="primary" flex v-center h-center>
          <button
            mat-fab
            background-color="warn"
            class="volume-up"
            flex
            v-center
            h-center
            [mat-dialog-close]="true"
          >
            <i class="material-icons">clear</i>
          </button>
          <span class="header-text w-100" font-bold flex v-center h-center>{{
            keywords.dictionary.createOcc
          }}</span>
        </h2>
      </div>
      <div class="common-popup-body own-occupations">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxFlexFill
          fxLayoutGap="5px"
          fxLayoutAlign="center"
          fxLayoutWrap="wrap"
          class="save-occ-cards-row"
        >
          <div
            fxFlex="32%"
            fxFlex.xs="100%"
            class=""
            (click)="getOccCustomDetail('newOcc')"
          >
            <mat-card
              class="own-occ-cards add-more-card"
              pointer
              flex
              v-center
              h-center
            >
              <i class="material-icons">add</i>
            </mat-card>
          </div>
          <div
            fxFlex="32%"
            fxFlex.xs="100%"
            class=""
            *ngFor="let item of data.list"
          >
            <mat-card
              class="card own-occ-cards"
              pointer
              (click)="getOccCustomDetail(item.occId)"
            >
              <div
                class="card-header w-100"
                background-color="warn"
                flex
                v-center
              >
                <span class="w-100" font-bold>{{ item.title.substring(0,170) }}</span>
              </div>
              <div class="card-body" flex v-center h-center>
                <img
                  src="{{ item.media?.images.sprite }}"
                  alt="own-occupation-image"
                />
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class LandingCreatePopUpComponent {
  theme: number;
  colors = environment.THEME_COLORS;
  deleteactive: any;
  keywords: any = [];
  moduleName: string;
  stepperVal: string;
  dreamPage = "";
  constructor(
    public dialogRef: MatDialogRef<DialogopenService>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventService: EventdispatchService,
    private CareerCreatorStore: Store<CareerCreatorState>,
    private utils: Utilities,
  ) {
    this.CareerCreatorStore.dispatch({
      type: "CAREER_CREATOR_VAL",
      payload: {
        careerCreaterVals: {
          imgageSrc: "",
          name: "",
          description: "",
          id: "",
        },
      },
    });
    this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.moduleName = data.dialog_data;
    this.dreamPage = data.dream_career;

    if (data.dialog_data == "explorer") {
      this.theme = data.page_theme_color;
      this.stepperVal = "stepper2";
    }
    if (data.dialog_data == "pathfinder") {
      this.theme = data.page_theme_color;
      this.stepperVal = "stepper3";
    }
    if (data.dialog_data == "careertrekker") {
      this.theme = data.page_theme_color;
      this.stepperVal = "stepper2";
    } if (data.dialog_data == 'discoverer') {
      this.theme = data.page_theme_color;
    } if (data.dialog_data == 'scout') {
      this.theme = data.page_theme_color;
      this.stepperVal = "stepper4";
    } if (data.dialog_data == 'navigator') {
      this.theme = data.page_theme_color;
      this.stepperVal = "stepper5";
    }
  }

  getOccCustomDetail(occId) {
    let language = sessionStorage.getItem('Language');
    if (this.data.list.length >= 60 && occId === 'newOcc') {
      this.snackBar.open(language === 'en' ? 'Career Creations Limit Exceeded' : 'Límite de creaciones de carrera excedido', '', {
        duration: 2000,
        verticalPosition: 'top',
        panelClass: ['careerCreatorfailure-snackbar']
      });
    } else {
      try {
        this.dialogRef.close();
        sessionStorage.setItem('theme_color', this.colors[this.theme]);

        if (occId === 'newOcc') {
          this.router.navigate(['/app/create'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              newCareerAdding: occId,
              moduleName: this.moduleName,
              stepperVal: this.stepperVal
            }
          });
        }
        else {
          this.router.navigate(['/app/custom-occ-detail'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              itemId: occId,
              moduleName: this.moduleName,
              theme_color: this.colors[10]
            }
          });
        }
      } catch (e) {
        this.utils.handleError(`modalpopup.component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    }
  }
}
@Component({
  selector: "app-detail-occupation-popup",
  template: `
  <div class="{{data.page_theme_color}}">
  <div class="explorer-occ-popup profile-page-popup">
  <div class="common-occupation-popup" *ngIf="data.dialog_data!=undefined" p-0>
      <div class="common-popup-header" flex v-center h-center>
          <h2 m-0 class="w-100" flex v-center h-center>
              <button mat-fab class="volume-up" flex v-center h-center mat-dialog-close (click)="modalClose()"
                  [mat-dialog-close]="'careerCornerClose'">
                  <i class="material-icons">clear</i>
              </button>
              <span class="header-text w-100" flex v-center h-center>{{data.dialog_data.title.substring(0,20)}}</span>
              <button [mat-dialog-close]="'careerShare'" class="share-btn" *ngIf="(data.modal_text === 'custom')">
                  Share
                  <img src="assets/Svg/share.svg" alt="share-icon" />
              </button>
              <button
                  *ngIf="(data.modal_text === 'custom'  || data.modal_text === 'dreamOcc') && (data.modal_text !== 'CareerCorner')"
                  class="profilecarrer-delete" [mat-dialog-close]="'detailOccDelete'">
                  <img src="assets/Svg/delete.svg" alt="delete-image" />
              </button>
              <button
              *ngIf="(data.modal_text === 'custom'  || data.modal_text !== 'dreamOcc') && (data.modal_text !== 'CareerCorner')"
              class="profilecarrer-delete" [mat-dialog-close]="'detailOccDelete'">
              <img src="assets/Svg/delete.svg" alt="delete-image" />
          </button>
          </h2>
      </div>
      <div class="occ-content-block">
          <div class="common-occ-popup-image desktop-design-show"
              *ngIf="data.dialog_data.media?.images.card!=undefined">
              <img src="{{assetsLargeOccURL}}{{data.dialog_data.media?.images.card}}" alt="{{data.dialog_data.title}}"
                  class="w-100">
          </div>
          <div [ngClass]="(data.dialog_data.media?.images.card!=undefined) ? '' : ''">
          <div class="common-occ-popup-image"
              [ngClass]="(data.modal_text === 'custom' || data.modal_text === 'CareerCorner')? '': 'occ-content-image'"
              flex h-center p-5>
              <div class="spriteImage"
                  *ngIf="data.modal_text === 'custom' || data.modal_text === 'CareerCorner' || data.modal_text === 'dreamOcc'">
                  <img src="{{data.dialog_data.media?.images.sprite}}" alt="{{data.dialog_data.title}}" class="w-100">
                  <img *ngIf="setRibbon" class="sprite-badge" src="./assets/Svg/badge.svg" alt="badge icon" />
              </div>
              <div fxFlex="" class="common-occ-actor-text" *ngIf="data.dialog_data.media?.images.card!=undefined">
                  <p m-0 text-color="primary">
                      {{data.dialog_data.tasks.title}}
                      <button mat-raised-button class="audio-box volume-up" (click)="audioClick('inside')"
                        [disabled]="audionotfound == true && audioState == 'warning'"
                      >
                      <i
                        class="material-icons"
                        *ngIf="audionotfound == false && audioState == 'stoped'"
                        >volume_up</i
                      >
                      <i
                        class="material-icons"
                        *ngIf="audionotfound == false && audioState == 'playing'"
                        >stop</i
                      >
                      <div
                        class="audio-loading"
                        *ngIf="audionotfound == false && audioState == 'loading'"
                      ></div>
                      <i
                        class="material-icons"
                        *ngIf="audionotfound == true && audioState == 'warning'"
                        >warning</i
                      >
                      </button>
                  </p>
                  <ul class="list-style-none" p-0 m-0>
                      <li flex *ngFor="let item of data.dialog_data.tasks.items">
                          <i class="material-icons" text-color="primary"> done</i>
                          {{item}}
                      </li>
                  </ul>
              </div>
              <div fxFlex="" class="common-occ-actor-text" [ngClass] = "data.modal_text === 'dreamOcc' ? '' : 'custom-scroll'"  
                  *ngIf="data.modal_text === 'custom' || data.modal_text === 'CareerCorner' || data.modal_text === 'dreamOcc'">
                  <p m-0 text-color="primary">{{data.dialog_data.title}}</p>
                  <ul class="list-style-none" p-0 m-0 *ngIf="data.dialog_data.description">
                      <li flex>
                          <i class="material-icons" text-color="primary"> done</i>
                          {{data.dialog_data.description}}
                      </li>
                  </ul>
              </div>
          </div>
          <div *ngIf="(data.modal_text === 'custom')" fxLayout="row" fxLayout.xs="column" fxFlexFill
              fxLayoutWrap="wrap" fxLayoutGap="5px" fxLayoutAlign="center" class="common-occ-popup-card w-100"
              [ngClass]="(((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length==0))
          ||((attributesDatahigh.length!=0)&& (attributesDatamedium.length==0)&&(attributesDatalow.length!=0))
          ||((attributesDatahigh.length==0)&& (attributesDatamedium.length!=0)&&(attributesDatalow.length!=0)))? 'tabhHalfWidth':
          ((((attributesDatamedium.length!=0) && (attributesDatamedium.length==0) && (attributesDatalow.length==0))
          ||((attributesDatamedium.length==0) && (attributesDatamedium.length!=0) && (attributesDatalow.length==0))
          ||((attributesDatamedium.length==0) && (attributesDatamedium.length==0) && (attributesDatalow.length!=0)))? '':'tabFullwidth')">
          </div>
          <div p-5 d-block-mobile class="occ-popup-body custom-scroll profile-popup" flex v-center>
              <div class="w-100" fxLayoutWrap="wrap">
                  <div *ngIf="(data.modal_text !== 'dreamOcc') && categoryTitle" fxFlex="" mx-1 pt-12 class="career-group">
                      <p font-bold mb-1>{{keywords.dictionary.group}}</p>
                      <mat-card class="career-group-card box-shadow-none" mb-1 p-0>
                          <div class="card-body text-center h-100" flex v-center h-center>
                              <div class="career-icon">
                                  <img src="{{assetsImageUrl}}{{iconName}}.svg" alt="" />
                              </div>
                              <p m-0 class="career-icon_text">{{categoryTitle}}</p>
                          </div>
                      </mat-card>
                  </div>
                  <div *ngIf="(data.modal_text !== 'dreamOcc' && navigatorValues.length>0)" fxFlex="" mx-1 pt-12
                      class="navigator-value">
                      <p font-bold mb-1>{{keywords.dictionary.workValues}}</p>
                      <mat-card class="work-value-card box-shadow-none" mb-1 p-0>
                          <div class="card-body text-center" *ngFor="let item of navigatorValues">
                              <div class="career-icon">
                                  <img src="./assets/Svg/{{item.icon}}.svg" alt="" />
                              </div>
                              <p m-0 font-bold>{{item.displayName}}</p>
                          </div>
                      </mat-card>
                  </div>
                  <div fxFlex="" mx-1 pt-12 class="characteristics-block"
                      *ngIf="attributesDatahigh.length!=0 || attributesDatamedium.length!=0 || attributesDatalow.length!=0">
                      <p font-bold mb-1>{{keywords.dictionary.characteristics}}</p>
                      <mat-card class="tabs-card m-auto card-explorer" mb-1
                          [ngClass]="{'circle-icon-box' : attributesDatahigh.length > 6 || attributesDatamedium.length > 6 || attributesDatalow.length > 6}">
                          <mat-tab-group>
                              <mat-tab label="Tab 1" *ngIf="attributesDatahigh.length!=0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button green" flex v-center h-center>
                                          <span>{{keywords.dictionary.high}}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="" fxLayoutGap.xs="2px" [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                  ||((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0))
                  ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'hightab-class':''">
                                      <div fxFlex="" *ngFor="let item of attributesDatahigh"
                                          class="circle-img-iestyles" flex-grow-inherit flex h-center>
                                          <div class="circle-img green">
                                              <div class="icon-hovertitle" matTooltip="{{item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img class="w-100" src="assets/Svg/{{item.icon}}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 2" *ngIf="attributesDatamedium.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button red" flex v-center h-center>
                                          <span>{{ keywords.dictionary.medium }}</span>
                                      </div>
                                  </ng-template>

                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="" fxLayoutGap.xs="2px" [ngClass]="
                      attributesDatahigh.length != 0 &&
                      attributesDatamedium.length != 0 &&
                      attributesDatalow.length != 0
                        ? 'justify-center'
                        : attributesDatahigh.length != 0 &&
                          attributesDatamedium.length != 0
                        ? 'lowtab-class'
                        : 'hightab-class'
                    ">
                                      <div fxFlex="" *ngFor="let item of attributesDatamedium" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img orange">
                                              <div class="icon-hovertitle" matTooltip="{{ item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img class="w-100" src="assets/Svg/{{ item.icon }}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 3" *ngIf="attributesDatalow.length!=0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button purple" flex v-center h-center>
                                          <span>{{keywords.dictionary.low}}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="" fxLayoutGap.xs="5px" [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                  ||((attributesDatalow.length!=0)&&(attributesDatamedium.length!=0))
                  ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'lowtab-class':''" justify-end>
                                      <div fxFlex="" class="" flex-grow-inherit *ngFor="let item of attributesDatalow"
                                          flex h-center>
                                          <div class="circle-img purple">
                                              <div class="icon-hovertitle" matTooltip="{{item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img class="w-100" src="assets/Svg/{{item.icon}}.svg" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                          </mat-tab-group>
                      </mat-card>
                  </div>
                  <div fxFlex="" mx-1 class="personality-types" *ngIf="pathfinder.length!=0">
                      <p font-bold mb-1 pt-12>{{keywords.dictionary.personalityTypes}}</p>
                      <mat-card class="personality-card box-shadow-none" mb-1 p-0>
                          <div class="card-body text-center h-100 card-pathfinder" flex v-center h-center>
                              <div *ngFor="let item of pathfinder" class="legend-text">
                                  <img class="w-100" src="./assets/pathfinder/holland/{{item.icon}}.svg" p-5
                                      alt="creator" />
                                    <p m-0 font-bold class="w-100">{{item.displayName}}</p>
                              </div>
                          </div>
                      </mat-card>
                  </div>
                  <div fxFlex="" mx-1 class="skill-block"
                      *ngIf="CareertrekDatahigh.length!=0 || CareertrekDatamedium.length!=0 || CareertrekDatalow.length!=0">
                      <p font-bold mb-1 pt-12>{{keywords.dictionary.skills}}</p>
                      <mat-card class="tabs-card m-auto card-trekk" mb-1
                          [ngClass]="{'circle-icon-box' : CareertrekDatahigh.length > 6 || CareertrekDatamedium.length > 6 || CareertrekDatalow.length > 6}">
                          <mat-tab-group>
                              <mat-tab label="Tab 1" *ngIf="CareertrekDatahigh.length!=0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button green" flex v-center h-center>
                                          <span>{{keywords.dictionary.high}}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="2px" fxLayoutGap.xs="2px" [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))
                  ||((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0))
                  ||((CareertrekDatahigh.length!=0)&&(CareertrekDatalow.length!=0))? 'hightab-class':''">
                                      <div fxFlex="" *ngFor="let item of CareertrekDatahigh" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img green">
                                              <div class="icon-hovertitle" matTooltip="{{item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img src="{{assetsImageUrl}}{{item.icon}}.svg"
                                                      alt="{{item.icon}}" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 2" *ngIf="CareertrekDatamedium.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button red" flex v-center h-center>
                                          <span>{{ keywords.dictionary.medium }}</span>
                                      </div>
                                  </ng-template>
                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="2px" fxLayoutGap.xs="2px" [ngClass]="
                      CareertrekDatahigh.length != 0 &&
                      CareertrekDatamedium.length != 0 &&
                      CareertrekDatalow.length != 0
                        ? 'justify-center'
                        : CareertrekDatahigh.length != 0 &&
                          CareertrekDatamedium.length != 0
                        ? 'lowtab-class'
                        : 'hightab-class'
                    ">
                                      <div fxFlex="" *ngFor="let item of CareertrekDatamedium" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img orange">
                                              <div class="icon-hovertitle" matTooltip="{{ item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img src="{{ assetsImageUrl }}{{ item.icon }}.svg"
                                                      alt="{{ item.icon }}" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                              <mat-tab label="Tab 3" *ngIf="CareertrekDatalow.length != 0">
                                  <ng-template mat-tab-label h-center flex>
                                      <div class="tab-button purple" flex v-center h-center>
                                          <span>{{ keywords.dictionary.low }}</span>
                                      </div>
                                  </ng-template>

                                  <div fxLayout="row" fxFlexFill class="common-row-sub icon-hover-styles"
                                      fxLayoutGap="2px" fxLayoutGap.xs="5px" [ngClass]="
                      (CareertrekDatahigh.length != 0 &&
                        CareertrekDatamedium.length != 0 &&
                        CareertrekDatalow.length != 0) ||
                      (CareertrekDatalow.length != 0 &&
                        CareertrekDatamedium.length != 0) ||
                      (CareertrekDatahigh.length != 0 &&
                        CareertrekDatalow.length != 0)
                        ? 'lowtab-class'
                        : ''
                    " justify-end>
                                      <div fxFlex="" *ngFor="let item of CareertrekDatalow" flex h-center
                                          class="circle-img-iestyles" flex-grow-inherit>
                                          <div class="circle-img purple">
                                              <div class="icon-hovertitle" matTooltip="{{ item.name | titlecase}}"
                                                  matTooltipClass="custom-tooltip">
                                                  <img src="{{ assetsImageUrl }}{{ item.icon }}.svg"
                                                      alt="{{ item.icon }}" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </mat-tab>
                          </mat-tab-group>
                      </mat-card>
                  </div>
                  <div fxFlex="" *ngIf="data.modal_text!== 'dreamOcc'" class="thought-box w-100" mx-1>
                      <p font-bold mb-1 pt-12>{{keywords.dictionary.thoughtBox}}</p>
                      <form>
                          <textarea class="thought-box-card w-100" [ngModelOptions]="{standalone: true}"
                              name="thoughtBoxValue" [(ngModel)]="valueInTextArea"
                              (ngModelChange)="updateTextValue($event , data.modal_text)"></textarea>
                      </form>
                  </div>
              </div>
          </div>
          </div>
          <div class="popup-footer-height w-100"
              *ngIf="data.modal_text === 'custom'" p-5 flex v-center h-center
              pointer>
              <div flex (click)="customOccDetailPage(data.dialog_data.occId)">
                  <p m-0>{{ data.keyWords.dictionary.lanuchCustomOccDetails }}</p>
                  <i class="material-icons">launch</i>
              </div>
          </div>
          <div class="popup-footer-height w-100" *ngIf="data.modal_text === 'CareerCorner'" p-5 flex v-center h-center
              pointer>
          </div>
      </div>
  </div>
</div>
</div>
  `,
  styleUrls: [],
})
export class DetailOccupationsPopUpComponent {
  keywords: any = [];
  pathfinder: any = [];
  CareertrekDatalow: any = [];
  CareertrekDatamedium: any = [];
  CareertrekDatahigh: any = [];
  attributesDatalow: any = [];
  attributesDatamedium: any = [];
  attributesDatahigh: any = [];
  assetsUrls = JSON.parse(sessionStorage.getItem("assetsData"));
  launchURl = JSON.parse(sessionStorage.getItem("PROFILE"));
  imageurl = this.assetsUrls.images;
  assetsImageUrl: string;
  assetsLargeOccURL: string;
  compActive = true;
  addRibbonValue: string;
  deleteRibbonValue: string;
  navigatorValues: any = [];
  setRibbon = false;
  customInput: Subject<string> = new Subject();
  moduleType: string;
  careerOccId: string;
  valueInTextArea: string;
  careerCornerType: string;
  endUrlForThoughtBox: string;
  detailViewData: any;
  iconName: string;
  categoryTitle: string;
  images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
  categoriesArray: any = [];
  isThoughtUpdate = false;
  audioSubscription: any;
  audionotfound = false;
  audioState = "stoped";
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject('OCC_LARGE_CARD_URL') private assetsOccLargeUrl: string,
    private addRibbonStore: Store<AddRibbonToCareerState>, private snackBar: MatSnackBar,
    private deleteRibbonStore: Store<DeleteRibbonToCareerState>,
    private saveThoughtStore: Store<SaveThoughtBoxState>,
    private getThoughtStore: Store<GetThoughtBoxState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private audiostopevent: EventdispatchService,
    private utils: Utilities,
  ) {
    this.categoriesArray = data.dialog_data.categoriesArray;
    this.careerOccId = data.dialog_data.occId;
    this.detailViewData = data;
    if (data.dialog_data.reflection && data.modal_text === 'custom') {
      this.valueInTextArea = data.dialog_data.reflection;
    }
    this.assetsImageUrl = assetsUrl;
    this.assetsLargeOccURL = assetsOccLargeUrl;
    this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    this.customInput.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(value => {
      if (value && value.trim()) {
        this.saveThoughtBoxValue(value, this.moduleType);
      }
    });
    if (data.modal_text === 'CareerCorner') {
      this.endUrlForThoughtBox = 'user/CareerCornerOccs/Reflection/' + this.careerOccId;
      this.getDispatchForThoughtBox(this.endUrlForThoughtBox);
    }
    else if (data.modal_text === 'custom') {
      this.endUrlForThoughtBox = 'user/Custom-Occ/Reflection/' + this.careerOccId;
      this.getDispatchForThoughtBox(this.endUrlForThoughtBox);
    }
    else {
      if (data.modal_text === 'like') {
        this.endUrlForThoughtBox = 'user/occ/rate/reflection/' + this.careerOccId;
        this.getDispatchForThoughtBox(this.endUrlForThoughtBox);
      }
    }
    this.careerCornerType = data.modal_text;

    this.saveThoughtStore.select('saveThoughtValue').pipe(takeWhile(() => this.compActive))
      .subscribe((respData) => {
        try {
          if (respData && respData['saveThoughtValue'] && this.isThoughtUpdate) {
            this.snackBar.open(respData['saveThoughtValue'], '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }
        } catch (e) {
          this.utils.handleError(`modalpopup.component.ts constructor saveThoughtValue: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      });
    if (data.dialog_data.attributes != undefined && data.attributes != undefined) {
      for (let i = 0; i < data.dialog_data.attributes.length; i++) {
        for (let j = 0; j < data.attributes.length; j++) {
          if (data.dialog_data.attributes[i].type == "characteristic") {
            if (
              data.attributes[j].name == data.dialog_data.attributes[i].name &&
              data.attributes[j].type == "characteristic"
            ) {
              if (data.dialog_data.attributes[i].weight == "high") {
                this.attributesDatahigh.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "medium") {
                this.attributesDatamedium.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "low") {
                this.attributesDatalow.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              }
            }
          }
          if (data.dialog_data.attributes[i].type == "skill") {
            if (
              data.attributes[j].name == data.dialog_data.attributes[i].name &&
              data.attributes[j].type == "skill"
            ) {
              if (data.dialog_data.attributes[i].weight == "high") {
                this.CareertrekDatahigh.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "medium") {
                this.CareertrekDatamedium.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              } else if (data.dialog_data.attributes[i].weight == "low") {
                this.CareertrekDatalow.push({
                  icon: data.attributes[j].icon,
                  name: data.attributes[j].title,
                });
              }
            }
          }
          if (data.dialog_data.attributes[i].type == "hollandcode") {
            if (
              data.attributes[j].hollandCode ==
              data.dialog_data.attributes[i].name &&
              data.attributes[j].type == "hollandcode"
            ) {
              this.pathfinder.push(data.attributes[j]);
            }
          }
          if (data.dialog_data.attributes[i].type == "value") {
            if (
              data.attributes[j].name === data.dialog_data.attributes[i].name && data.attributes[j].type == "value"
            ) {
              this.navigatorValues.push(data.attributes[j]);
            }

          }
          if (data.dialog_data.attributes[i].type == "group") {
            if (
              data.attributes[j].name === data.dialog_data.attributes[i].name.toLowerCase() && data.attributes[j].type == "group"
            ) {
              this.categoryTitle = data.attributes[j].title;
              if (data.dialog_data.attributes[i].name === 'People') {
                this.iconName = "EXPLORER_PEOPLE"
              }
              else if (data.dialog_data.attributes[i].name === 'Ideas') {
                this.iconName = "EXPLORER_IDEAS"
              }
              else if (data.dialog_data.attributes[i].name === 'Things') {
                this.iconName = "EXPLORER_THINGS"
              }
              this.utils.handleError(`modalpopup.component.ts unfound group: categoryTitle ${this.categoryTitle} iconName ${this.iconName})}`);
            }
          }
          if (data.attributes[j].type == "group" && data.modal_text === 'like') {
            if (
              data.dialog_data.category.title
            ) {
              this.categoryTitle = data.dialog_data.category.title;
              if (parseInt(data.dialog_data.category.categoryId) === 1) {
                this.iconName = "EXPLORER_PEOPLE"
              }
              else if (parseInt(data.dialog_data.category.categoryId) === 2) {
                this.iconName = "EXPLORER_IDEAS"
              }
              else if (parseInt(data.dialog_data.category.categoryId) === 3) {
                this.iconName = "EXPLORER_THINGS"
              }
            }

          }
        }
      }
    }
    this.audioSubscription = this.audioStore.select('audio').subscribe((result) => {
      if (result) {
        this.audioState = result.audioState;
        this.audionotfound = result.audionotfound
      }
    })

  }
  getDispatchForThoughtBox(endUrl) {
    this.getThoughtStore.dispatch({
      type: 'GET_SAVE_THOUGHT_BOX', payload: {
        methodVal: 'GET', module_Name: 'careerCornerOccsGet',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: endUrl
      }
    });
  }
  ngOnInit() {
    this.getThoughtStore.select('getThoughtValue').pipe(takeWhile(() => this.compActive))
      .subscribe((respData) => {
        try {
          this.valueInTextArea = "";
          if (respData.reflection) {
            if (this.careerCornerType === 'CareerCorner') {
              this.valueInTextArea = respData.reflection;
            }
            else if (this.careerCornerType === 'custom') {
              this.valueInTextArea = respData.reflection;
            }
            else {
              if (!this.detailViewData.reflection && this.careerCornerType === 'like') {
                this.valueInTextArea = respData.reflection;
              }
            }
          }
        } catch (e) {
          this.utils.handleError(`modalpopup.component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      });
  }
  customOccDetailPage(item) {
    this.router.navigate(["/app/custom-occ-detail"], {
      relativeTo: this.activatedRoute,
      queryParams: {
        itemId: item,
      },
    });
    this.dialogRef.close();
  }

  addRibbon(occId) {
    this.addRibbonStore.dispatch({
      type: 'GET_RIBBON_VALUE', payload: {
        methodVal: 'POST', module_Name: 'careerCornerOccsPost',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/CareerCornerOccs/Ribbon/' + occId
      }
    });
    this.addRibbonStore.select('addRibbonValue').pipe(takeWhile(() => this.compActive))
      .subscribe((respData) => {
        try {
          if (respData && respData['addRibbonValue']) {
            this.setRibbon = true;
            this.snackBar.open(respData['addRibbonValue'], '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }
        } catch (e) {
          this.utils.handleError(`modalpopup.component.ts addRibbon: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      });
  }

  deleteRibbon(occId) {
    this.deleteRibbonStore.dispatch({
      type: 'GET_RIBBON_DELETE_VALUE', payload: {
        methodVal: 'DELETE', module_Name: 'careerCornerOccsDelete',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: 'user/CareerCornerOccs/Ribbon/' + occId
      }
    });
    this.deleteRibbonStore.select('deleteRibbonValue').pipe(takeWhile(() => this.compActive))
      .subscribe((respData) => {
        try {
          if (respData && respData['deleteRibbonValue']) {
            this.setRibbon = false;
            this.snackBar.open(respData['deleteRibbonValue'], '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }
        } catch (e) {
          this.utils.handleError(`modalpopup.component.ts deleteRibbon: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      });
  }

  updateTextValue($event: string, type) {
    this.isThoughtUpdate = true;
    this.moduleType = type;
    this.customInput.next($event);
  }

  saveThoughtBoxValue(value, type) {
    let bodyValue = [];
    bodyValue.push(value);
    if (type === 'CareerCorner') {
      this.endUrlForThoughtBox = 'user/CareerCornerOccs/Reflection/' + this.careerOccId;
      this.saveThoughtBoxDispatch(bodyValue);
    }
    else if (type === 'custom') {
      this.endUrlForThoughtBox = 'user/Custom-Occ/Reflection/' + this.careerOccId;
      this.saveThoughtBoxDispatch(bodyValue);
    }
    else {
      if (type === 'like') {
        this.endUrlForThoughtBox = 'user/occ/rate/reflection/' + this.careerOccId;
        this.saveThoughtBoxDispatch(bodyValue);
      }
    }

  }

  modalClose() {
    const evnt = document.createEvent("CustomEvent");
    this.audiostopevent.dispatchAudioStop(evnt);
  }

  audioClick(text) {
    this.audioService.audioFunction(text, this.data.dialog_data);
  }

  saveThoughtBoxDispatch(bodyVal) {
    this.saveThoughtStore.dispatch({
      type: 'SAVE_THOUGHT_BOX', payload: {
        methodVal: 'PUT', module_Name: 'careerCornerOccsPut',
        path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
        body_Params: {}, endUrlVal: this.endUrlForThoughtBox, body: bodyVal
      }
    });
  }
  ngOnDestroy() {
    this.compActive = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    this.audioSubscription.unsubscribe();
  }
}

@Component({
  selector: "app-navigate-challenge-popup",
  template: ` <div class="{{ data.dialog_data.page_theme_color }}">
    <div class="common-popup">
      <div class="common-popup-header" flex v-center h-center>
        <h2 m-0 class="w-100" flex v-center h-center background-color="primary">
          <button
            mat-fab
            flex
            v-center
            h-center
            mat-dialog-close
            class="close-icon-button volume-up"
          >
            <i flex v-center class="material-icons">close</i>
          </button>
          <span class="header-text w-100" flex v-center h-center>{{
            keywords.dictionary.challenge
          }}</span>
        </h2>
      </div>
      <div class="common-popup-body">
        <p font-bold>{{ keywords.dictionary.startChallenge }}</p>
      </div>
      <div class="navigation-challenge-footer" flex v-center h-center>
        <button
          background-color="primary"
          mat-raised-button
          [mat-dialog-close]="true"
          class="delete-popup-buttons green"
          (click)="challengeNavigation()"
        >
          {{ keywords.dictionary.challenge }}
        </button>
      </div>
    </div>
  </div>`,
  styleUrls: [],
})
export class navigateToChallengePopupPopUpComponent {
  keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogopenService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject("ASSETS_MEDIA_URL") private assetsUrl: string
  ) { }
  challengeNavigation() {
    this.dialogRef.close("Challenge");
  }
}
