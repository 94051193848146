import { Store } from '@ngrx/store';
import { Component, ViewChild, OnInit, AfterViewInit, ElementRef, HostListener, OnDestroy, Inject } from '@angular/core';
import { Observable,fromEvent, takeUntil, pairwise, switchMap } from 'rxjs';
import { OnLoadPopUpService } from '../../shared/common-services/onloadpopup.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import { ApiCallClass } from '../../shared/common-services/api-call-model';
import { ApiCallService } from '../../shared/common-services/api-call-service';
import { AudioState, CareerCreatorAttrState, CareerCreatorState, defaultAttributeDataState, DreamCareerListState, OwnOccListState, PageDataState, PathFinderAttrState } from '../../state-management/state/main-state';
import { RouteUrls } from '../../shared/common-constants/route.constants';
import { environment } from '../../../environments/environment';
import { Utilities } from '../../shared/common-services/utilities.service';
import { Next, Pointer, Pointer_mobile } from '../../shared/common-animations/CT_animations';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
import { CreateOwnOccupationContent } from './save-own-occ-models/createOwnModel';
@Component({
  selector: 'app-create-own-occ',
  templateUrl: './save-own-occ-layout.html',
  styles: [],
  animations: [Next, Pointer, Pointer_mobile],
})
export class CreateOwnOccComponent implements AfterViewInit, OnInit, OnDestroy {
  hideDeleteOption = true;
  assetsUrl: string;
  //The completed variable to set to true, to turn on the flip animation of the button
  completed: boolean;
  keyWords: any = [];
  keyWords1: any = [];
  newCareerAdding: string;
  moduleName: string;
  matStepperVal: string;
  attrData: any;
  id: any;
  dreamCareers = [];
  cardsSelected = [
    { name: '', icon: '', hollandCode: '', type: '', displayName: '' },
    { name: '', displayName: '', icon: '', hollandCode: '', type: '' },
  ];
  i: number = 0;
  theme_name: string;
  lang: string;
  savedEntireOcc = false;
  startInx = 0;
  optAniInx = 0;
  textAniInx = 0;
  medium = false;
  prevData = { title: '' };
  prevweight = '';
  high = false;
  low = false;
  resp1: any = [];
  highData: any = [];
  lowData: any = [];
  mediumData: any = [];
  savedDrawing = false;
  startSketch = false;
  startedDrawing = false;
  name = '';
  initialName = '';
  canvaswidth = 600;
  canvasheight = 300;
  describeThoughts = '';
  audioState = 'stoped';
  audioNotFound = false;
  colorsarray = [[], [], [], [], [], []];
  @ViewChild('canvas') public canvas: ElementRef;
  font = [1, 2, 3, 4, 5, 10, 15, 20];
  imgageSrc = '';
  colorCode = '#000';
  pencilFont;
  eraserFont;
  clickcolor;
  cPushArray = new Array();
  cStep = -1;
  selectedEraser = false;
  enableSave = false;
  color = [
    '#FFFFFF',
    '#C0C0C0',
    '#808080',
    '#000000',
    '#FF0000',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#008000',
    '#00FFFF',
    '#008080',
    '#0000FF',
    '#000080',
    '#FF00FF',
    '#800080',
    '#000000',
    '#000033',
    '#000066',
    '#000099',
    '#0000CC',
    '#0000FF',
    '#003300',
    '#003333',
    '#003366',
    '#003399',
    '#0033CC',
    '#0033FF',
    '#006600',
    '#006633',
    '#006666',
    '#006699',
    '#0066CC',
    '#0066FF',
    '#009900',
    '#009933',
    '#009966',
    '#009999',
    '#0099CC',
    '#0099FF',
    '#00CC00',
    '#00CC33',
    '#00CC66',
    '#00CC99',
    '#00CCCC',
    '#00CCFF',
    '#00FF00',
    '#00FF33',
    '#00FF66',
    '#00FF99',
    '#00FFCC',
    '#00FFFF',
    '#330000',
    '#330033',
    '#330066',
    '#330099',
    '#3300CC',
    '#3300FF',
    '#333300',
    '#333333',
    '#333366',
    '#333399',
    '#3333CC',
    '#3333FF',
    '#336600',
    '#336633',
    '#336666',
    '#336699',
    '#3366CC',
    '#3366FF',
    '#339900',
    '#339933',
    '#339966',
    '#339999',
    '#3399CC',
    '#3399FF',
    '#33CC00',
    '#33CC33',
    '#33CC66',
    '#33CC99',
    '#33CCCC',
    '#33CCFF',
    '#33FF00',
    '#33FF33',
    '#33FF66',
    '#33FF99',
    '#33FFCC',
    '#33FFFF',
    '#660000',
    '#660033',
    '#660066',
    '#660099',
    '#6600CC',
    '#6600FF',
    '#663300',
    '#663333',
    '#663366',
    '#663399',
    '#6633CC',
    '#6633FF',
    '#666600',
    '#666633',
    '#666666',
    '#666699',
    '#6666CC',
    '#6666FF',
    '#669900',
    '#669933',
    '#669966',
    '#669999',
    '#6699CC',
    '#6699FF',
    '#66CC00',
    '#66CC33',
    '#66CC66',
    '#66CC99',
    '#66CCCC',
    '#66CCFF',
    '#66FF00',
    '#66FF33',
    '#66FF66',
    '#66FF99',
    '#66FFCC',
    '#66FFFF',
    '#990000',
    '#990033',
    '#990066',
    '#990099',
    '#9900CC',
    '#9900FF',
    '#993300',
    '#993333',
    '#993366',
    '#993399',
    '#9933CC',
    '#9933FF',
    '#996600',
    '#996633',
    '#996666',
    '#996699',
    '#9966CC',
    '#9966FF',
    '#999900',
    '#999933',
    '#999966',
    '#999999',
    '#9999CC',
    '#9999FF',
    '#99CC00',
    '#99CC33',
    '#99CC66',
    '#99CC99',
    '#99CCCC',
    '#99CCFF',
    '#99FF00',
    '#99FF33',
    '#99FF66',
    '#99FF99',
    '#99FFCC',
    '#99FFFF',
    '#CC0000',
    '#CC0033',
    '#CC0066',
    '#CC0099',
    '#CC00CC',
    '#CC00FF',
    '#CC3300',
    '#CC3333',
    '#CC3366',
    '#CC3399',
    '#CC33CC',
    '#CC33FF',
    '#CC6600',
    '#CC6633',
    '#CC6666',
    '#CC6699',
    '#CC66CC',
    '#CC66FF',
    '#CC9900',
    '#CC9933',
    '#CC9966',
    '#CC9999',
    '#CC99CC',
    '#CC99FF',
    '#CCCC00',
    '#CCCC33',
    '#CCCC66',
    '#CCCC99',
    '#CCCCCC',
    '#CCCCFF',
    '#CCFF00',
    '#CCFF33',
    '#CCFF66',
    '#CCFF99',
    '#CCFFCC',
    '#CCFFFF',
    '#FF0000',
    '#FF0033',
    '#FF0066',
    '#FF0099',
    '#FF00CC',
    '#FF00FF',
    '#FF3300',
    '#FF3333',
    '#FF3366',
    '#FF3399',
    '#FF33CC',
    '#FF33FF',
    '#FF6600',
    '#FF6633',
    '#FF6666',
    '#FF6699',
    '#FF66CC',
    '#FF66FF',
    '#FF9900',
    '#FF9933',
    '#FF9966',
    '#FF9999',
    '#FF99CC',
    '#FF99FF',
    '#FFCC00',
    '#FFCC33',
    '#FFCC66',
    '#FFCC99',
    '#FFCCCC',
    '#FFCCFF',
    '#FFFF00',
    '#FFFF33',
    '#FFFF66',
    '#FFFF99',
    '#FFFFCC',
    '#FFFFFF',
  ];
  private cx: CanvasRenderingContext2D;
  buttonsText: any;
  compActive = true;
  editHeading: CreateOwnOccupationContent;
  shared: any;
  toggleEraser = false;
  prevColor: string;
  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = this.colorCode;
    this.captureEvents(canvasEl);
    const canvas = document.querySelector('canvas');
    this.captureMobileEvents(canvas);
  }
  captureMobileEvents(canvas) {
    if (this.cStep < 0) {
      this.cStep = 1;
    }
    canvas.addEventListener('touchstart', (e) => {
      this.cPushArray.length = this.cStep;
      this.cStep++;
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      this.cPushArray.push(canvasEl.toDataURL('image/svg+xml', 1.0));
    });
  }
  private captureEvents(canvasEl: HTMLCanvasElement) {
    fromEvent(canvasEl, 'mousedown').pipe(
      switchMap((e) => {
        return fromEvent(canvasEl, 'mousemove').pipe(
          takeUntil(fromEvent(canvasEl, 'mouseup')),
          takeUntil(fromEvent(canvasEl, 'mouseout')),
          pairwise()
        )}))
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };
        this.drawOnCanvas(prevPos, currentPos);
      });
    canvasEl.addEventListener(
      'touchstart',
      function (e) {
        const mousePos = getTouchPos(canvasEl, e);
        let touch: any;
        touch = e;
        touch = touch.touches[0];
        const mouseEvent = new MouseEvent('mousedown', {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvasEl.dispatchEvent(mouseEvent);
      },
      false
    );
    canvasEl.addEventListener(
      'touchend',
      function (e) {
        const mouseEvent = new MouseEvent('mouseup', {});
        canvasEl.dispatchEvent(mouseEvent);
      },
      false
    );
    canvasEl.addEventListener(
      'touchmove',
      function (e) {
        let touch: any;
        touch = e;
        touch = touch.touches[0];
        const mouseEvent = new MouseEvent('mousemove', {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvasEl.dispatchEvent(mouseEvent);
      },
      false
    );

    // Get the position of a touch relative to the canvas
    function getTouchPos(canvasDom, touchEvent) {
      const rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    }
    document.body.addEventListener(
      'touchstart',
      function (e) {
        if (e.target === canvasEl) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    document.body.addEventListener(
      'touchend',
      function (e) {
        if (e.target === canvasEl) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    document.body.addEventListener(
      'touchmove',
      function (e) {
        if (e.target === canvasEl) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
  }
  cPush() {
    if (this.cStep < this.cPushArray.length) {
      this.cStep++;
      this.cPushArray.length = this.cStep;
    }
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cPushArray.push(canvasEl.toDataURL('image/svg+xml', 1.0));
  }
  isCanvasBlank(canvas) {
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
    return !pixelBuffer.some((color) => color !== 0);
  }
  nameChanged() {
    if (this.name != this.initialName) {
      this.enableSave = true;
    } else {
      this.enableSave = false;
    }
  }
  isCanvasEmpty() {
    const canvass: HTMLCanvasElement = this.canvas.nativeElement;
    this.startSketch = true;
    setTimeout(() => {
      if (!this.isCanvasBlank(canvass)) {
        this.startedDrawing = true;
      }
    }, 100);
  }
  undo() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    if (this.cStep > 0) {
      this.cStep--;
      var canvasPic = new Image();
      canvasPic.src = this.cPushArray[this.cStep];
      const ctx = canvasEl.getContext('2d');
      canvasPic.onload = () => {
        ctx.drawImage(canvasPic, 0, 0);
      };
      setTimeout(() => {
        const canvass: HTMLCanvasElement = this.canvas.nativeElement;
        if (this.isCanvasBlank(canvass)) {
          this.startedDrawing = false;
        }
      }, 100);
    } else {
      this.startedDrawing = false;
      this.cStep = 0;
      this.cPushArray = [];
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    }
  }
  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.startedDrawing && this.selectedEraser == false) {
      this.startedDrawing = true;
    }
    if (!this.cx) {
      return;
    }
    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  onDrawImage(event) {
    this.savedDrawing = true;
    if (!this.startSketch) {
      if (this.cStep <= 0 || this.imgageSrc != '') {
        this.cStep = 1;
        this.cPushArray.push(this.imgageSrc);
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        const ctx = canvasEl.getContext('2d');
        const canvasPic = new Image();
        canvasPic.src = this.imgageSrc;
        canvasPic.onload = function () {
          ctx.drawImage(canvasPic, 0, 0, canvasEl.width, canvasEl.height);
        };
        this.imgageSrc = canvasPic.src;
      }
    } else {
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      this.imgageSrc = canvasEl.toDataURL('image/svg+xml', 1.0);
    }
  }
  ngOnInit() {
    this.optAniInx++;
    this.keyWords = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
    this.keyWords1 = this.oninitpopupservice.getKeyWordData('GLOBAL_SETTINGS');
    this.editHeading = this.oninitpopupservice.getKeyWordData(
      'CUSTOM_OCCUPATION_EDIT'
    );

    let actualWidth = window.innerWidth;
    const screenWidth = window.screen.width;
    if (screenWidth < actualWidth) {
      actualWidth = screenWidth;
    } else if (actualWidth > 1289) {
      this.canvaswidth = 600;
      this.canvasheight = 300;
    } else if (actualWidth >= 960 && actualWidth <= 1290) {
      this.canvaswidth = 510;
      this.canvasheight = 300;
    } else if (actualWidth > 450 && actualWidth < 960) {
      this.canvaswidth = 400;
      this.canvasheight = 300;
    } else if (actualWidth <= 450) {
      this.canvaswidth = 280;
      this.canvasheight = 200;
    }

    let a = 0;
    for (let i = 0; i < this.color.length; i++) {
      this.colorsarray[a].push(this.color[i]);
      if (i % 100 === 0) {
        a++;
      }
    }

    this.dreamCarrerList
      .select('dreamCareerList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((list) => {
        this.dreamCareers = list['dreamCareerList'];
      });

    this.CareerCreatorStore.select('careerCreaterVals')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((careerCreatorResp) => {
        if (careerCreatorResp && careerCreatorResp['careerCreaterVals'].occId) {
          this.name = careerCreatorResp['careerCreaterVals'].name;
          this.initialName = this.name;
          this.imgageSrc = careerCreatorResp['careerCreaterVals'].imgageSrc;
          this.describeThoughts =
            careerCreatorResp['careerCreaterVals'].description;
          this.id = careerCreatorResp['careerCreaterVals'].occId;
          if (this.imgageSrc) {
            this.savedDrawing = true;
          }
        }
      });
    this.store.dispatch({
      type: 'GET_OWN_OCC_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'explorer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: '/user/custom-occs/list',
      },
    });
    this.ownOccList();
    this.setCareerCreatorTitle(this.moduleName);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let actualWidth = window.innerWidth;
    const screenWidth = window.screen.width;
    if (screenWidth < actualWidth) {
      actualWidth = screenWidth;
    } else if (actualWidth > 1289) {
      this.canvaswidth = 600;
      this.canvasheight = 300;
    } else if (actualWidth >= 960 && actualWidth <= 1290) {
      this.canvaswidth = 510;
      this.canvasheight = 300;
    } else if (actualWidth > 450 && actualWidth < 960) {
      this.canvaswidth = 400;
      this.canvasheight = 300;
    } else if (actualWidth <= 450) {
      this.canvaswidth = 280;
      this.canvasheight = 200;
    }

    setTimeout(() => {
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      if (this.cStep > 0) {
        const ctx = canvasEl.getContext('2d');
        const canvasPic = new Image();
        canvasPic.src = this.cPushArray[this.cStep];
        canvasPic.onload = function () {
          ctx.drawImage(canvasPic, 0, 0, canvasEl.width, canvasEl.height);
        };
      } else {
        if (this.cStep < this.cPushArray.length) {
          this.cStep++;
          this.cPushArray.length = this.cStep;
        }
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cPushArray.push(canvasEl.toDataURL('image/svg+xml', 1.0));
        const ctx = canvasEl.getContext('2d');
        const canvasPic = new Image();
        canvasPic.src = canvasEl.toDataURL('image/svg+xml', 1.0);
        canvasPic.onload = function () {
          ctx.drawImage(canvasPic, 0, 0, canvasEl.width, canvasEl.height);
        };
      }
    }, 100);
  }
  onSelectFont(item) {
    if (this.selectedEraser) {
      this.eraserFont = item;
    } else {
      this.pencilFont = item;
    }
    this.cx.lineWidth = parseInt(item, 10);
  }
  onSelectColor(item) {
    this.colorCode = item;
    this.cx.strokeStyle = this.colorCode;
    if (this.pencilFont) {
      this.cx.lineWidth = parseInt(this.pencilFont, 10);
    }
  }
  onToggle() {
    this.toggleEraser = !this.toggleEraser;
    if (this.toggleEraser) {
      this.selectedEraser = true;
      this.prevColor = this.colorCode;
      this.eraseColor('#FFF');
    } else {
      this.selectedEraser = false;
      this.onSelectColor(this.prevColor);
    }
  }
  onClearDraw(event) {
    this.dialogService.quickpicDeletePopup({
      activeset: 'clearBoard',
      set: 'Clear Drawing Board',
    });
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result === true) {
          this.cStep = 0;
          this.cPushArray = [];
          const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
          this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
          this.startedDrawing = false;
          if (this.selectedEraser) {
            this.selectedEraser = false;
            this.toggleEraser = !this.toggleEraser;
            this.onSelectColor('#000');
          }
        }
      });
  }

  onPencilColor(event) {
    this.startedDrawing = false;
    if (this.savedDrawing == false || this.savedDrawing == true) {
      if (this.selectedEraser) {
        this.selectedEraser = false;
        this.toggleEraser = !this.toggleEraser;
        this.onSelectColor('#000');
      } else {
        this.onSelectColor(this.colorCode);
      }

      this.savedDrawing = false;
      if (this.cStep <= 0 || this.imgageSrc != '') {
        this.cStep = 1;
        this.cPushArray.length = this.cStep;
        this.cPushArray.push(this.imgageSrc);
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        const ctx = canvasEl.getContext('2d');
        const canvasPic = new Image();
        canvasPic.src = this.imgageSrc;
        canvasPic.onload = function () {
          ctx.drawImage(canvasPic, 0, 0, canvasEl.width, canvasEl.height);
        };
        this.imgageSrc = '';
      } else {
        this.imgageSrc = '';
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        const ctx = canvasEl.getContext('2d');
        const canvasPic = new Image();
        canvasPic.src = this.cPushArray[this.cPushArray.length - 1];
        canvasPic.onload = function () {
          ctx.drawImage(canvasPic, 0, 0, canvasEl.width, canvasEl.height);
        };
      }
    }
    this.cx.strokeStyle = this.colorCode;
  }
  eraseColor(item) {
    this.pencilFont = this.cx.lineWidth;
    this.selectedEraser = true;
    this.colorCode = item;
    this.cx.strokeStyle = this.colorCode;
    if (this.eraserFont) {
      this.cx.lineWidth = this.eraserFont;
    } else {
      this.cx.lineWidth = 10;
    }
  }
  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp(
          'CUSTOM_OCCUPATION',
          this.subModule
        );
      }.bind(this)
    );
  }
  stopAudio() {
    //to stop current playing audio
    this.audioState = 'stoped';
    this.audioNotFound = false;
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }
  createOwnOccs: any = [];
  showForwardArrow = false;
  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private apiJson: ApiCallService,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    public eventService: EventdispatchService,
    private CareerCreatorStore: Store<CareerCreatorState>,
    private CareerCreatorAttrStore: Store<CareerCreatorAttrState>,
    private pathFinderAttrStore: Store<PathFinderAttrState>,
    private router: Router,
    public snackBar: MatSnackBar,
    private store: Store<PageDataState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private ownOccstore: Store<OwnOccListState>,
    private dreamCarrerList: Store<DreamCareerListState>,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    private titleService: Title
  ) {
    this.assetsUrl = assetsMediaUrl;
    this.infopopup();
    let colors = environment.THEME_COLORS;
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: {
        module: 'CREATE_OWN_OCCUPATION',
        pageCode: 'CUSTOM_OCCUPATION',
      },
    });
    this.dreamCarrerList.dispatch({
      type: 'GET_DREAMCAREERS',
      payload: {
        methodVal: 'GET',
        module_Name: 'discoverer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/dream-occs/list',
      },
    });

    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));

    this.keyWords = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
    this.keyWords1 = this.oninitpopupservice.getKeyWordData('GLOBAL_SETTINGS');

    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') === '/app/create') {
          if (e.type === 'languageChanged') {
            this.cardsSelected = [
              {
                name: '',
                icon: '',
                hollandCode: '',
                type: '',
                displayName: '',
              },
              {
                name: '',
                displayName: '',
                icon: '',
                hollandCode: '',
                type: '',
              },
            ];
            this.attrData = [];
            /* */
            //After changing the language need to do empty following arrays to restart assessment
            this.highData = [];
            this.mediumData = [];
            this.lowData = [];
            /* */
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.store
              .select('pages')
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                this.buttonsText = JSON.parse(
                  sessionStorage.getItem('GLOBAL_SETTINGS')
                );

                this.keyWords =
                  this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
                this.keyWords1 =
                  this.oninitpopupservice.getKeyWordData('GLOBAL_SETTINGS');
              });
            this.startOwnOCC();
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              sessionStorage.setItem('infopopup', 'false');
              this.infopopup();
            }
          }
          if (e.type === 'backClicked') {
            this.unsavedChanges();
          }
        }
      });

    if (this.lang === '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          this.audioState = result.audioState;
          this.audioNotFound = result.audionotfound;
        }
      });
    this.startOwnOCC();
  }

  ownOccList() {
    this.ownOccstore
      .select('ownOccList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((ownOccListResp) => {
        if (ownOccListResp && ownOccListResp.ownOccList.length > 0) {
          try {
            this.createOwnOccs = ownOccListResp.ownOccList;
          } catch (e) {
            this.utils.handleError(`save-own-occ-component.ts ownOccList: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  startOwnOCC() {
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.newCareerAdding = params['newCareerAdding'];
        this.moduleName = params['moduleName'];
        this.matStepperVal = params['stepperVal'];

        // when your clicking back button from attributes page id is not getting(In this case commented this id) but if you are
        // clicking edit image getting id so, I have uncommented the id.
        this.id = params['id'];

        if (this.id) {
          this.dialogService.showLoading();
          this.savedDrawing = true;
          let endUrl = '';
          if (this.moduleName !== 'discoverer') {
            endUrl = 'custom-occ/';
          } else {
            endUrl = 'dream-occ/';
          }
          this.apiModel.moduleName = 'saveourownocc';
          this.apiModel.endUrl = '/user/' + endUrl + this.id;
          this.apiModel.sessionID = sessionStorage.getItem('session_token');
          this.apiModel.method = 'GET';

          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                this.keyWords =
                  this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
                const data = JSON.parse(resp);
                this.imgageSrc = data.media.images.sprite;
                this.showForwardArrow = true;
                this.name = data.title;
                this.initialName = this.name;
                this.describeThoughts = data.description;
                this.shared = data.shared;
                if (this.moduleName !== 'discoverer') {
                  this.attrData = data.attributes;
                }
                this.dialogService.hideLoading();
                this.keyWords =
                  this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
              });
          } catch (e) {
            this.utils.handleError(`save-own-occ-component.ts startOwnOcc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }
  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }
  discovererAudioClick(text, data) {
    this.audioService.audioFunction(text, data, true);
  }
  dataPost() {
    if (this.id == undefined || this.id == null) {
      this.apiModel.moduleName = 'custom-occ';
      this.apiModel.endUrl = '/user/custom-occ/create';
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'POST';
      this.apiModel.data = {
        title: this.name,
        description: this.describeThoughts,
        shared: false,
        media: {
          images: {
            title: 'string',
            sprite: this.imgageSrc,
          },
        },
        tasks: {
          title: 'string',
          items: ['string'],
        },
        attributes: [{}],
      };
    } else {
      this.apiModel.moduleName = 'custom-occ';
      this.apiModel.endUrl = '/user/custom-occ/update/' + this.id;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'PUT';
      this.apiModel.data = {
        title: this.name,
        description: this.describeThoughts,
        shared: this.shared,
        media: {
          images: {
            title: 'string',
            sprite: this.imgageSrc,
          },
        },
        tasks: {
          title: 'string',
          items: ['string'],
        },
        attributes: this.attrData,
      };
    }
  }
  setDreamCareer() {
    if (!this.id) {
      this.apiModel.moduleName = 'dream-custom-occ';
      this.apiModel.endUrl = '/user/dream-occ/create';
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'POST';
      this.apiModel.data = {
        title: this.name,
        media: {
          images: {
            sprite: this.imgageSrc,
          },
        },
      };
    } else {
      this.apiModel.moduleName = 'dream-custom-occ';
      this.apiModel.endUrl = '/user/dream-occ/update/' + this.id;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'PUT';
      this.apiModel.data = {
        title: this.name,
        media: {
          images: {
            sprite: this.imgageSrc,
          },
        },
      };
    }
  }

  saveDreamCareer() {
    this.setDreamCareer();
    try {
      this.apiJson
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            const data = JSON.parse(resp);
            if (!this.id) {
              this.dreamCareers = [data].concat(this.dreamCareers);
            } else {
              const occIndex = this.dreamCareers.findIndex(
                (x) => parseInt(x.occId) === parseInt(this.id)
              );
              this.dreamCareers[occIndex] = data;
            }
            this.dreamCarrerList.dispatch({
              type: 'GET_DREAMCAREERS',
              payload: {
                methodVal: 'GET',
                module_Name: 'discoverer',
                path_params: [],
                query_params: {},
                sessionID: sessionStorage.getItem('session_token'),
                body_Params: {},
                endUrlVal: 'user/dream-occs/list',
              },
            });
            this.router.navigateByUrl('/app/landing');
          }
        });
    } catch (e) {
      this.utils.handleError(`save-own-occ-component.ts saveDreamCareer: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  nextArrow() {
    if (this.name !== '' && this.describeThoughts !== '' && this.imgageSrc) {
      if (this.moduleName === 'discoverer') {
        this.saveDreamCareer();
      } else {
        this.showForwardArrow = true;
        this.saveDrawing();
      }
    }
  }
  saveDrawing() {
    this.dataPost();
    this.hideDeleteOption = false;
    try {
      this.apiJson
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp != undefined && resp != null) {
            const data = JSON.parse(resp);
            this.id = data.occId;
            this.attrData = data.attributes;
            this.navigateToCustomOcc(this.id);
            this.CareerCreatorStore.dispatch({
              type: 'CAREER_CREATOR_VAL',
              payload: {
                careerCreaterVals: {
                  imgageSrc: this.imgageSrc,
                  name: this.name,
                  description: this.describeThoughts,
                  occId: this.id,
                },
              },
            });
          }
        });
    } catch (e) {
      this.utils.handleError(`save-own-occ-component.ts saveDrawing: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  navigateToCustomOcc(occId) {
    if (this.newCareerAdding === 'newOcc') {
      if (this.moduleName == 'careertrekker' || this.moduleName == 'explorer') {
        this.CareerCreatorAttrStore.dispatch({
          type: 'CAREER_CREATOR_ATTR_VAL',
          payload: {
            careerCreaterAttrVals: {
              highData: undefined,
              mediumData: undefined,
              lowData: undefined,
              moduleName: '',
            },
          },
        });
      }
      if (this.moduleName == 'pathfinder') {
        this.pathFinderAttrStore.dispatch({
          type: 'PATHFINDER_ATTR_VAL',
          payload: {
            pathFinderAttrVals: { cardsSelected: undefined, moduleName: '' },
          },
        });
      }
      const evnt = document.createEvent('CustomEvent');
      this.eventService.dispatchAudioStop(evnt);
      this.router.navigate(['/app/createOccAttribute'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          module: this.moduleName,
          id: occId,
          stepper: this.matStepperVal,
        },
      });
    } else {
      this.router.navigate(['/app/custom-occ-detail'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          itemId: occId,
        },
      });
    }
  }

  deleteEntireOcc() {
    let endUrlType = '';
    if (this.moduleName !== 'discoverer') {
      endUrlType = 'custom-occ';
    } else {
      endUrlType = 'dream-occ';
    }
    this.dialogService.quickpicDeletePopup({
      activeset: '',
      set: this.keyWords.dictionary.delete + ' ' + this.name + ' ?',
    });
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result === true) {
          this.apiModel.moduleName = this.moduleName;
          this.apiModel.endUrl = `user/${endUrlType}/delete/ ${this.id}`;
          this.apiModel.sessionID = sessionStorage.getItem('session_token');
          this.apiModel.method = 'DELETE';
          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {});
            this.dreamCarrerList.dispatch({
              type: 'GET_DREAMCAREERS',
              payload: {
                methodVal: 'GET',
                module_Name: 'discoverer',
                path_params: [],
                query_params: {},
                sessionID: sessionStorage.getItem('session_token'),
                body_Params: {},
                endUrlVal: 'user/dream-occs/list',
              },
            });
            this.router.navigateByUrl('/app/landing');
          } catch (e) {
            this.utils.handleError(`save-own-occ-component.ts deleteEntireOcc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }

  showCustomOccsModal(module) {
    this.dialogService.landingCreatePopup(module, this.dreamCareers, 6);
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result === true) {
          this.router.navigateByUrl('/app/landing');
        }
      });
  }

  ngOnDestroy() {
    this.compActive = false;
  }

  private setCareerCreatorTitle(title: string) {
    //call capitalizeFirstLetter from utilities service
    this.titleService.setTitle(
      this.utils.capitalizeFirstLetter(title) + ' Career Creator - CareerTrek'
    );
  }
}
