import { Component, OnInit } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { EventdispatchService } from './shared/common-modal/eventdispach.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from "../environments/environment";
import { Utilities } from './shared/common-services/utilities.service';

//add google analytics 
declare const gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "app";
  // items: Array<any> = [];
  subscription = new Subscription();
  theme_name = "";
  constructor(
    private router: Router,
    private eventService: EventdispatchService,
    private utils: Utilities,
  ) {
    this.subscription = eventService.listen().subscribe((e) => {
      try {
        if (e.type == "themeChanged") {
          this.theme_name = sessionStorage.getItem("theme_color");
        }
      } catch (e) {
        this.utils.handleError(`app.component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    });
    if (
      sessionStorage.getItem("theme_color") != null &&
      sessionStorage.getItem("theme_color") != ""
    ) {
      this.theme_name = sessionStorage.getItem("theme_color");
    }
  }
  ngOnInit() {

    if (environment.enableGA4){
      this.setUpAnalytics();
    }
  }
  /**add google analytics 4   */
  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("config", "G-N5GX4EXRS3", {
          page_path: event.urlAfterRedirects,
        });
      });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
