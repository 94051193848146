<app-static-header></app-static-header>
<div class="Career-trek-content">
  <div class="container custom-occ-header profile-page-header text-right">
    <div flex-center justify-end>
      <!-- Save Button -->
      <button class="common-occ-header-button save-occ-header-btn" pointer
        [disabled]="(imgageSrc!='' && name!='' && enableSave)? null : true" onclick="this.disabled = true"
        [attr.disable]="(imgageSrc!='' && name!='' && enableSave)? null : true" flex-center
        *ngIf="(moduleName == 'discoverer')" (click)="saveDreamCareer()">
        <span class="desktop-design-show" font-bold>{{keyWords1.dictionary?.save}}</span>
        <i class="material-icons">save</i>
      </button>
      <!-- Delete Button -->
      <button class="common-occ-header-button delete-occ-header-btn" flex-center (click)="deleteEntireOcc()">
        <span class="desktop-design-show" font-bold>{{keyWords.dictionary.delete}}</span>
        <img src="assets/Svg/delete.svg" alt="delete-image" />
      </button>
    </div>

    <div class="landing-page-heading text-center" *ngIf="newCareerAdding && moduleName === 'discoverer'">
      <h4>{{keyWords?.dictionary?.headerDiscoverer}}


        <app-audio-button [audionotfound]="audioNotFound" [audioState]="audioState"
          (click)="discovererAudioClick('inside',keyWords)">
        </app-audio-button>

      </h4>
    </div>
    <div class="landing-page-heading text-center" *ngIf="newCareerAdding && moduleName !== 'discoverer'">
      <h4>{{keyWords?.header}}

        <app-audio-button [audionotfound]="audioNotFound" [audioState]="audioState"
          (click)="audioClick('inside',keyWords)">
        </app-audio-button>


      </h4>
    </div>
    <div class="landing-page-heading text-center" *ngIf="newCareerAdding!=='newOcc'">
      <h4>
        {{editHeading?.header}}
        <app-audio-button [audionotfound]="audioNotFound" [audioState]="audioState" [styleClass]="'custom-occ-audio'"
          (click)="audioClick('inside',editHeading)"></app-audio-button>

      </h4>
    </div>


    <!-- Delete Button -->

    <!-- <div class="landing-page-heading text-center" *ngIf="newCareerAdding">
      <h4>
        
        {{keyWords?.header}}
        <app-audio-button
          [audionotfound]="audionotfound"
          [audioState]="audioState"
          [styleClass]="'custom-occ-audio'"
          (click)="audioClick('inside',editHeading)"

        ></app-audio-button>
        
      </h4>
    </div>
     -->


  </div>

  <div class="container content-box" ngStyle.xs="padding: 0;">
    <div class="save-own-occ-parent save-stepper-image">
      <mat-horizontal-stepper>
        <mat-step>
          <div class="save-own-occ">
            <!-- <p class="w-100 mobile-design-show" p-5 ngStyle.xs="margin:5px;font-size:1rem;" font-bold>{{keyWords?.menuTitle}}</p> -->
            <mat-card class="card">
              <div class="drawing-block" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="5px">
                <div [ngClass]="savedDrawing == false ?'':'draw-edit' " class="m-auto">
                  <div class="board-tools">
                    <ul class="list-style-none" fxLayout="column" fxLayout.xs="row" p-0 m-0>
                      <li flex v-center *ngIf="startSketch == true && savedDrawing == false && startedDrawing == true">
                        <div class="desktop-design-show" ngStyle.sm="display:none;">
                          <div class="board-tool-tip">
                            {{keyWords?.dictionary.done}}
                          </div>
                        </div>
                        <div flex v-center>
                          <button mat-fab class="done-button" p-0
                            *ngIf="startSketch == true && savedDrawing == false && startedDrawing == true"
                            (click)="onDrawImage($event);savedDrawing = true; enableSave=true;">
                            <i class="material-icons"> done </i>
                          </button>
                        </div>
                      </li>
                      <li flex v-center *ngIf="savedDrawing == true ">
                        <button background-color="primary" mat-fab class="default-green-btn-bg" p-0
                          (click)="onPencilColor($event);startSketch=true">
                          <i class="material-icons">mode_edit</i>
                        </button>
                      </li>
                      <div class="menu-item_align" *ngIf="savedDrawing == false ">                        
                        <li *ngIf="savedDrawing == false|| imgageSrc==''" flex v-center >
                          <div class="desktop-design-show" ngStyle.sm="display:none;">
                            <div class="board-tool-tip white-space-nowrap">
                              {{keyWords?.dictionary.colorPalette}}
                            </div>
                          </div>
                          <!-- matStepperNext  -->
                          <div>
                            <button [disabled]="toggleEraser" mat-fab class="" p-0 [matMenuTriggerFor]="menu1">
                              <i class="material-icons">color_lens</i>
                            </button>
                            <mat-menu #menu1="matMenu">
                              <button mat-menu-item *ngFor="let item of color" [ngStyle]="{'background-color': item}"
                                 (click)="onSelectColor(item)">
                              </button>
                            </mat-menu>
                          </div>
                        </li>
                        <li *ngIf="savedDrawing == false || imgageSrc==''" flex v-center>
                          <div class="desktop-design-show" ngStyle.sm="display:none;">
                            <div class="board-tool-tip">
                              {{keyWords?.dictionary.thickness}}
                            </div>
                          </div>
                          <div>
                            <button mat-fab class="" p-0 [matMenuTriggerFor]="menu2">
                              <i class="material-icons">line_weight</i>
                            </button>
                            <mat-menu #menu2="matMenu">
                              <p mat-menu-item *ngFor="let item of font" (click)="onSelectFont(item)">
                                <!-- <mat-icon>dialpad</mat-icon> -->
                                <span><img src="./assets/images/{{item}}pxthickness.png" alt="Thickness" /></span>
                              </p>
                            </mat-menu>
                          </div>
                        </li>
                        <li *ngIf="savedDrawing == false || imgageSrc==''" flex v-center>
                          <div class="desktop-design-show" ngStyle.sm="display:none;">
                            <div class="board-tool-tip">
                              {{keyWords?.dictionary.undo}}
                            </div>
                          </div>
                          <div>
                            <button mat-fab class="" p-0 (click)="undo()">
                              <i class="material-icons">undo</i>
                            </button>
                          </div>
                        </li>
                        <li *ngIf="savedDrawing == false || imgageSrc==''" flex v-center>
                          <div class="desktop-design-show" ngStyle.sm="display:none;">
                            <div class="board-tool-tip" *ngIf="!toggleEraser" >
                              {{keyWords?.dictionary.eraser}}
                            </div>
                            <div class="board-tool-tip"*ngIf="toggleEraser" >
                              {{keyWords1?.dictionary.draw}}
                            </div>
                          </div>
                          <div>
                            <button mat-fab p-0 *ngIf="!toggleEraser" (click)="onToggle()">
                              <img src="{{assetsUrl}}/eraser-white.svg" alt="erase" />
                            </button>
                            <button background-color="primary" *ngIf="toggleEraser" mat-fab class="default-green-btn-bg" p-0
                            (click)="onToggle();startSketch=true">
                           <i class="material-icons">mode_edit</i>
                           </button>
                          </div>
                        </li>
                        <li *ngIf="savedDrawing == false || imgageSrc==''" flex v-center>
                          <div class="desktop-design-show" ngStyle.sm="display:none;">
                            <div class="board-tool-tip">
                              {{keyWords?.dictionary.delete}}
                            </div>
                          </div>
                          <button mat-fab class="" p-0 (click)="onClearDraw($event)">
                            <img src="assets/Svg/delete.svg" alt="delete-image" />
                          </button>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div align-self-center>
                  <div class="canves-block" flex h-center [hidden]="imgageSrc!=''" (click)="startSketch=true">
                    <div class="canvas-board">
                      <canvas [ngClass]="{'cursor-url':selectedEraser === true}" #canvas [width]="canvaswidth"
                        [height]="canvasheight" (click)="cPush()">
                      </canvas>
                    </div>
                    <!-- [width]="canvaswidth" [height]="canvasheight" ngStyle.lg="canvaswidth=100% ,canvasheight=300px"
                          ngStyle.xs="canvaswidth=100% ,canvasheight=165px" ngStyle.md="canvaswidth=100% ,canvasheight=300px"

                        {{canvaswidth}} {{canvasheight}} -->
                    <div class="canves-text" *ngIf="(!startSketch) && (savedDrawing == false)">
                      <div>
                        <!-- <button mat-raised-button class="upload-btn mobile-design-show" p-0 ngStyle.xs="position:absolute;top:0;right:0;">
                              <i class="material-icons">cloud_upload</i>&nbsp;Upload image
                            </button> -->
                        <!-- <i *ngIf="!startSketch" text-color="primary" class="material-icons text-center brush-icon">brush</i> -->
                        <img src="{{assetsUrl}}pencil-icon-Explorer.svg" *ngIf="moduleName==='explorer'"
                          alt="explorer-edit-image" />
                        <img src="{{assetsUrl}}pencil-icon-Career-trek.svg" *ngIf="moduleName==='careertrekker'"
                          alt="career-trek-edit-image" />
                        <img src="{{assetsUrl}}pencil-icon-Pathfinder.svg" *ngIf="moduleName==='pathfinder'"
                          alt="pathfinder-edit-image" />
                        <img src="{{assetsUrl}}pencil-icon-discoverer.svg" *ngIf="moduleName==='discoverer'"
                          alt="discoverer-edit-image" />
                        <img src="{{assetsUrl}}pencil-icon-scout.svg" *ngIf="moduleName==='scout'"
                          alt="scout-edit-image" />
                        <img src="./assets/Svg/pencil-icon-navigator.svg" *ngIf="moduleName==='navigator'"
                          alt="navigator-edit-image" />
                        <p *ngIf="!startSketch">{{keyWords?.dictionary.startSketch}}</p>
                      </div>
                    </div>
                  </div>
                  <!-- Here ngstyle added for canvas width and height dynamically -->
                  <div class="canves-block image-section" *ngIf="imgageSrc!=''"
                    [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex', 'margin':'auto'}">
                    <img class="canvas-board-img" src="{{imgageSrc}}" />
                  </div>
                </div>
              </div>
            </mat-card>
            <div p-5 ngStyle.xs="padding:0;">
              <h4 p-5 class="">
                {{keyWords?.dictionary.nameOcc}}
                <span class="mandatory-icon">*</span>
              </h4>
              <div>
                <!-- (ngModelChange)="onPencilColor($event);startSketch=true;savedDrawing=false;startedDrawing=true" -->
                <input id="email" type="text" class="text-feild" name="email" [(ngModel)]="name"
                  (ngModelChange)="isCanvasEmpty();nameChanged();" placeholder="{{keyWords?.dictionary.nameOcc}}"
                  ngStyle.xs="margin:5px 0;" />
              </div>



              <h4 *ngIf="moduleName!=='discoverer'" p-5 class=" ">{{keyWords?.dictionary.describeOcc}}
                <span class="mandatory-icon">*</span>
              </h4>
              <div *ngIf="moduleName!=='discoverer'">
                <textarea class="text-feild" rows="3" id="comment" placeholder="{{keyWords?.dictionary.describeOcc}}"
                  (ngModelChange)="isCanvasEmpty();" [(ngModel)]="describeThoughts"></textarea>

                <!-- <h4 p-5 class="">
                {{keyWords?.dictionary.describeOcc}}
                <span class="mandatory-icon">*</span>
              </h4>
              <div>
                <textarea
                  class="text-feild"
                  rows="3"
                  id="comment"
                  placeholder="{{keyWords?.dictionary.describeOcc}}"
                  [(ngModel)]="describeThoughts"
                ></textarea>

              </div> -->
              </div>

              <!-- <button mat-fab flex h-center class="save" *ngIf="name!='' && describeThoughts!='' && imgageSrc!=''" matStepperNext (click)="saveDrawing()">
                    <i class="material-icons">save</i>
                  </button> -->
              <div class="career-creator-nav">
                <!-- [hidden]="showForwardArrow" -->
                <button *ngIf="newCareerAdding!='newOcc' && name!='' && describeThoughts!='' && imgageSrc!=''" mat-fab
                  class="next-level custom-green-forward" [@flipAnimation]="completed" flex v-center h-center
                  (click)="nextArrow()">
                  <i class="material-icons">arrow_forward </i>
                </button>
                <button *ngIf="newCareerAdding =='newOcc' && name!='' && describeThoughts!='' && imgageSrc!=''" mat-fab
                  class="next-level" [@flipAnimation]="completed" flex v-center h-center (click)="nextArrow()">
                  <i class="material-icons">arrow_forward </i>
                </button>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
  <div class="mobile-design-show">
    <button pointer class="common-occ-header-button delete-occ-header-btn single-bottom-btn"
      (click)="deleteEntireOcc()">
      <span class="mat-button-wrapper">
        <img src="assets/Svg/delete.svg" alt="delete-image" />
      </span>
    </button>
  </div>
</div>
<app-static-footer></app-static-footer>