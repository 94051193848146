import { Component, Inject, OnInit } from '@angular/core';
import { Next } from '../../../shared/common-animations/CT_animations';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { Store, select } from '@ngrx/store';
import { AudioState, CommunityActivityDataState, PageDataState } from '../../../state-management/state/main-state';
import { take, takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { Title } from '@angular/platform-browser';
import {
  CommunityOccupations,
  CommunityList,
} from '../discoverer_models/communityOccupations';
@Component({
  selector: 'app-discoverer-activity',
  templateUrl: './discoverer-activity.component.html',
  animations: [Next],
})
export class DiscovererActivityComponent implements OnInit {
  occIds = [];
  assetsSVG_URL: any;
  moduleName: string;
  stepperVal: string;
  occCardAPIUrl: string;
  colors: any;
  arrowShow = false;
  checkedCareers:boolean[] = [false, false, false, false, false, false];
  discovererActivity: any;
  compActive:boolean = true;
  activateCommunityList: CommunityList;
  
  curAudio = '';
  headerAudioState = 'stoped';
  headerAudioNotFound:boolean = false;
  audioState = ['stoped', 'stoped', 'stoped', 'stoped', 'stoped', 'stoped'];
  audioNotFound:boolean[] = [false, false, false, false, false, false];
  audioButtonIndex = -1;
  lang: string;
  keywords: any;
  theme_name: string;
  completed: boolean = true;

  constructor(
    @Inject('ASSETS_MEDIA_URL') private assetsSvg_URL: string,
    @Inject('OCC_CARD_URL') private occCardUrl: string,
    private audioStore: Store<AudioState>,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private audioService: AudioService,
    private activityStore: Store<CommunityActivityDataState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private store: Store<PageDataState>,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private utils: Utilities,
    private titleService: Title
  ) {
    this.assetsSVG_URL = assetsSvg_URL;
    this.occCardAPIUrl = occCardUrl;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[6]);
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'DISCOVERER', pageCode: 'DISCOVERER_ACTIVITY' },
    });

    this.activityStore.dispatch({
      type: 'GET_COMMUNITYOCCS',
      payload: {
        methodVal: 'GET',
        module_Name: 'discoverer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/communityOccs?isForOverview=true',
      },
    });

    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') === '/app/discoverer/activity') {
          if (e.type === 'languageChanged') {
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.keywords = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            this.unsavedChanges();
          }
        }
      });

    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          if (this.curAudio === 'header') {
            this.headerAudioState = result.audioState;
            this.headerAudioNotFound = result.audionotfound;
            this.audioState = [
              'stoped',
              'stoped',
              'stoped',
              'stoped',
              'stoped',
              'stoped',
            ];
            this.audioNotFound = [false, false, false, false, false, false];
          } else if (this.curAudio === 'career') {
            this.audioState[this.audioButtonIndex] = result.audioState;
            this.audioNotFound[this.audioButtonIndex] = result.audionotfound;
            this.headerAudioState = 'stoped';
            this.headerAudioNotFound = false;
          }
        }
      });
  }

  ngOnInit() {
    this.discovererActivity = this.oninitpopupservice.getKeyWordData(
      'DISCOVERER_ACTIVITY'
    );
    this.activityStore
      .pipe(
        select('communityOccs'),
        takeWhile(() => this.compActive)
      )
      .subscribe((result) => {
        this.activateCommunityList =JSON.parse(JSON.stringify(result));
      });
    this.setDiscovererIntoTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  ImageClicked(inx, occId) {
    this.checkedCareers[inx] = !this.checkedCareers[inx];
    if (this.checkedCareers.indexOf(true) !== -1) {
      this.arrowShow = true;
      if (this.occIds.indexOf(occId) === -1) {
        this.occIds.push(occId);
      }
    } else {
      this.arrowShow = false;
      this.occIds.splice(inx);
    }
  }
  //Navigate to career creater drawing page
  getOccCustomDetail(occId) {
    try {
      this.saveChanges(occId);
    } catch (e) {
      this.utils.handleError(`discoverer-activity.component.ts getOccCustomDetail: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  saveChanges(occId) {
    this.apiModel.moduleName = 'userDiscoverer';
    this.apiModel.endUrl = 'user/SelectedCommunityOccs';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'POST';
    this.apiModel.data = {
      occIds: this.occIds,
    };
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            let colors = environment.THEME_COLORS;
            sessionStorage.setItem('theme_color', colors[6]);
            if (occId === 'newOcc') {
              this.router.navigate(['/app/create'], {
                relativeTo: this.activatedRoute,
                queryParams: {
                  newCareerAdding: occId,
                  moduleName: 'discoverer',
                },
              });
            }
          }
        });
    } catch (e) {
      this.utils.handleError(`discoverer-activity.component.ts saveChanges: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  discovererAudioClick(text, data) {
    this.curAudio = 'header';
    this.audioService.audioFunction(text, data);
  }

  audioClick(text, data, index) {
    this.curAudio = 'career';
    this.audioButtonIndex = index;
    //Reinitialized audioState and audioNotFound arrays to make the audio states to default.
    this.audioState = [
      'stoped',
      'stoped',
      'stoped',
      'stoped',
      'stoped',
      'stoped',
    ];
    this.audioNotFound = [false, false, false, false, false, false];
    this.audioService.audioFunction(text, data, true);
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('DISCOVERER_ACTIVITY');
      }.bind(this)
    );
  }

  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
    // this.router.navigateByUrl('/app/landing');
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = 'stoped';
    this.headerAudioNotFound = false;
    this.audioState = [
      'stoped',
      'stoped',
      'stoped',
      'stoped',
      'stoped',
      'stoped',
    ];
    this.audioNotFound = [false, false, false, false, false, false];
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setDiscovererIntoTitle() {
    this.titleService.setTitle('Discoverer Activity - CareerTrek');
  }
}
