// import { analyzeFileForInjectables } from "@angular/compiler";

export interface PageDataState {
  pages: any;
}
export const defaultPageDataState: PageDataState = {
  pages: {}
};

export interface DreamCareerListState {
  dreamCareerList:
  Array<{
    media: {
      images: {
        card: String,
        sprite: String
      }
    },
    source: String,
    title: String,
    occId: Number
  }>
}
export const defaultDreamCareerListState: DreamCareerListState = {
  dreamCareerList:
    [{
      media: {
        images: {
          card: "",
          sprite: ""
        }
      },
      source: "",
      title: "",
      occId: null
    }]
}

export interface ModulesDataState {
  modules: Array<
    {
      schoolNumber: String,
      ssoType: String,
      siteId: Number,
      componentId: Number,
      moduleName: String,
      showThoughtBox: Boolean
    }>
}
export const defaultModulesDataState: ModulesDataState = {
  modules: [
    {
      schoolNumber: "",
      ssoType: "",
      siteId: null,
      componentId: null,
      moduleName: "",
      showThoughtBox: false
    }
  ]
};
export interface CommunityActivityDataState {
  communityOccs: Array<{
    media: {
      images: {
        card: String,
        sprite: String
      },
      audio: [
        {
          type: String,
          src: String
        },
        {
          type: String,
          src: String
        }
      ],
      video: [
        {
          type: String,
          videoSrc: String,
          textSrc: String
        }
      ],
      descAudio: [
        {
          type: String,
          src: String
        },
        {
          type: String,
          src: String
        }
      ]
    },
    category: {
      categoryId: Number,
      title: String
    },
    source: String,
    occId: Number,
    title: String,
    description: String
  }>
}
export const defaultCommunityActivityDataState: CommunityActivityDataState = {
  communityOccs: [{
    media: {
      images: {
        card: "",
        sprite: ""
      },
      audio: [
        {
          type: "",
          src: ""
        },
        {
          type: "",
          src: ""
        }
      ],
      video: [
        {
          type: "",
          videoSrc: "",
          textSrc: ""
        }
      ],
      descAudio: [
        {
          type: "",
          src: ""
        },
        {
          type: "",
          src: ""
        }
      ]
    },
    category: {
      categoryId: null,
      title: ""
    },
    source: "",
    occId: null,
    title: "",
    description: ""
  }]
};

export interface SiteDataState {
  sites: any;
}
export const defaultSiteDataState: SiteDataState = {
  sites: {}
};

export interface ProfileDataState {
  profileImage: any;
};

export const defaultProfileDataState: ProfileDataState = {
  profileImage: ""
};

export interface OccsDataState {
  occs: any;
}
export const defaultOccsDataState: OccsDataState = {
  occs: {}
};

export interface OccIndexDataState {
  occIndex: any;
}
export const defaultOccIndexDataState: OccIndexDataState = {
  occIndex: {}
};
export interface AttributeDataState {
  attribute: any;
}
export const defaultAttributeDataState: AttributeDataState = {
  attribute: {}
};
export interface FactoidDataState {
  factoid: any;
}
export const defaultFactoidDataState: FactoidDataState = {
  factoid: {}
};

export interface OwnOccListState {
  ownOccList: any;
}
export const defaultOwnOccListState: OwnOccListState = {
  ownOccList: {}
};
export interface PathFinderTopSkillsState {
  cardsSelected: Array<{
    attributeId: number,
    description: String,
    displayName: String,
    hollandCode: String,
    icon: String,
    language: String,
    media: {
      audio: Array<{ src: String, type: String }>
    }
    mediaFile: String,
    name: String,
    standardHollandCodeType: String,
    title: String,
    type: String
  }>
}
export const defaultPathFinderTopSkillsState: PathFinderTopSkillsState = {
  cardsSelected: undefined
}


export interface OccThumbState {
  OccThumbList: any;
}
export const defaultOccThumbState: OccThumbState = {
  OccThumbList: {
    occs: {
      evaluated: []
    }
  }
};

export interface PathwayOccViewedState {
  pathwayOccViewedList: any;
}
export const defaultPathwayOccViewedState: PathwayOccViewedState = {
  pathwayOccViewedList: {
    modules: {
      PATHFINDER: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};

export interface PathwayOccViewedCareerState {
  pathwayOccViewedCareers: any;
  // pathwayOccViewedCareers: {
  //   modules: {
  //     PATHFINDER: {
  //       occs: {
  //         reviewed: []
  //       }
  //     }
  //   }
  // }
}
export const defaultPathwayOccViewedCareerState: PathwayOccViewedCareerState = {
  pathwayOccViewedCareers: {
    modules: {
      PATHFINDER: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};

export interface TrekkerOccViewedState {
  trekkerOccViewedList: any;
}
export const defaultTrekkerOccViewedState: TrekkerOccViewedState = {
  trekkerOccViewedList: {
    modules: {
      TREKKER: {
        occs: {
          reviewed: []
        }
      }
    }
  }

};

export interface ScoutOccViewedState {
  scoutOccViewedList: any;
  // scoutOccViewedList: {
  //   modules: {
  //     TREKKER: {
  //       occs: {
  //         reviewed: Array<String>
  //       }
  //     }
  //   }
  // }
}
export const defaultScoutOccViewedState: ScoutOccViewedState = {
  scoutOccViewedList: {
    modules: {
      SCOUT: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};


export interface TrekkerOccViewedCareerState {
  trekkerOccViewedCareers: any;
}
export const defaultTrekkerOccViewedCareerState: TrekkerOccViewedCareerState = {
  trekkerOccViewedCareers: {
    modules: {
      TREKKER: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};
export interface ScoutOccViewedCareerState {
  scoutOccViewedCareers: any;
  // scoutOccViewedCareers: {
  //   modules: {
  //     TREKKER: {
  //       occs: {
  //         reviewed: Array<String>
  //       }
  //     }
  //   }
  // }
}
export const defaultScoutOccViewedCareerState: ScoutOccViewedCareerState = {
  scoutOccViewedCareers: {
    modules: {
      SCOUT: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};

/* Explorer viewed Occs list and career stores*/
//List store
export interface ExplorerOccsViewedListStore {
  explorerOccsViewedList: any;
  // explorerOccsViewedList: {
  //   modules: {
  //     EXPLORER: {
  //       occs: {
  //         reviewed: Array<String>
  //       }
  //     }
  //   }
  // }
}

export const defaultExplorerOccsViewedListStore: ExplorerOccsViewedListStore = {
  explorerOccsViewedList: {
    modules: {
      EXPLORER: {
        occs: {
          reviewed: []
        }
      }
    }
  }
}

//view Career store
export interface ExplorerOccsViewedCareerStore {
  explorerOccsViewedCareer: {
    modules: {
      EXPLORER: {
        occs: {
          reviewed: Array<String>
        }
      }
    }
  }
}

export const defaultExplorerOccsViewedCareerStore: ExplorerOccsViewedCareerStore = {
  explorerOccsViewedCareer: {
    modules: {
      EXPLORER: {
        occs: {
          reviewed: []
        }
      }
    }
  }
}

/* Navigator Occ Viewed stores started here */
export interface NavigatorOccViewedState {
  navigatorOccViewedList: any;
  // navigatorOccViewedList: {
  //   modules: {
  //     TREKKER: {
  //       occs: {
  //         reviewed: Array<String>
  //       }
  //     }
  //   }
  // }
}
export const defaultNavigatorOccViewedState: NavigatorOccViewedState = {
  navigatorOccViewedList: {
    modules: {
      NAVIGATOR: {
        occs: {
          reviewed: []
        }
      }
    }
  }
};
export interface NavigatorOccViewedCareerState {
  navigatorOccViewedCareers: any
  // navigatorOccViewedCareers: {
  //   modules: {
  //     TREKKER: {
  //       occs: {
  //         reviewed: Array<String>
  //       }
  //     }
  //   }
  // }
}
export const defaultNavigatorOccViewedCareerState: NavigatorOccViewedCareerState = {
  navigatorOccViewedCareers: {
    modules: {
      NAVIGATOR: {
        occs: {
          reviewed: []
        }
      }
    }
  }
}

/* State for NavigatorResult Store */
export interface NavigatorResultStoreState {
  navigatorResult: {
    value1List: Array<object>,
    value2List: Array<object>,
  };
}
export const defaultNavigatorResultStoreState: NavigatorResultStoreState = {
  navigatorResult: {
    value1List: [],
    value2List: []
  }
};


export interface UserProfileState {
  userProfileData: any;
}
export const defaultUserProfileState: UserProfileState = {
  userProfileData: {}
};

export interface TrekkerAssessmentState {
  trekkerAssessmentques: {
    topSkill: Array<any>,
    impSkill: Array<any>,
    name: String,
    quesSkill: Array<any>
  }
}
export const defaultTrekkerAssessmentState: TrekkerAssessmentState = {
  trekkerAssessmentques: {
    topSkill: [],
    impSkill: [],
    name: '',
    quesSkill: []
  }
};

export interface QuickpicQuesIdState {
  quickpicAssessment: {
    questionsId: number;
    answers: Array<{ questionId: number, value: number }>,
    totalqueslength: number
  }
}

export const defaultQuickpicQuesIdVal: QuickpicQuesIdState = {
  quickpicAssessment: {
    questionsId: 0,
    answers: undefined,
    totalqueslength: 0
  }
}
export interface PathFinderquesState {
  pathFinderques: {
    name: String,
    like: Array<any>,
    unlike: Array<any>,
    saveArray: Array<any>,
    nameArray: Array<any>,
    likeAttributeId: Array<any>,
    idArray: Array<any>,
    toStoreLikedDataInArray: Array<any>
  };
}
export const defaultPathFinderquesState: PathFinderquesState = {
  pathFinderques: {
    name: '',
    like: [],
    unlike: [],
    saveArray: [],
    nameArray: [],
    likeAttributeId: [],
    idArray: [],
    toStoreLikedDataInArray: []
  }
};
export interface completionListState {
  completionList: any;
}
export interface CareerCreatorState {
  careerCreaterVals: {
    imgageSrc: String,
    name: String,
    description: String,
    occId: number
  };
}
export const defaultCareerCreatorState: CareerCreatorState = {
  careerCreaterVals: {
    imgageSrc: '',
    name: '',
    description: '',
    occId: null
  }
};
export interface CareerCreatorAttrState {
  careerCreaterAttrVals: {
    highData: {
      attributeId: number,
      description: String,
      icon: String,
      language: String,
      name: String,
      title: String,
      type: String
    },
    mediumData: {
      attributeId: number,
      description: String,
      icon: String,
      language: String,
      name: String,
      title: String,
      type: String
    },
    lowData: {
      attributeId: number,
      description: String,
      icon: String,
      language: String,
      name: String,
      title: String,
      type: String
    },
    moduleName: String
  };
}
export const defaultCareerCreatorAttrState: CareerCreatorAttrState = {
  careerCreaterAttrVals: {
    highData: undefined,
    mediumData: undefined,
    lowData: undefined,
    moduleName: ''
  }
};
export interface PathFinderAttrState {
  pathFinderAttrVals: {
    cardsSelected: Array<{
      attributeId: number,
      description: String,
      displayName: String,
      hollandCode: String,
      icon: String,
      language: String,
      media: {
        audio: Array<{ src: String, type: String }>
      }
      mediaFile: String,
      name: String,
      standardHollandCodeType: String,
      title: String,
      type: String
    }>,
    moduleName: String;
  };
}
export const defaultPathFinderAttrState: PathFinderAttrState = {
  pathFinderAttrVals: {
    cardsSelected: undefined,
    moduleName: ''
  }
};
export const defaultCompletionListState: completionListState = {
  //the schema is very long so outer structure is defined here.
  completionList: {
    "user": {},
    "modules": {
      "assessments": {
        "EXPLORER": {
          "secondary": {
            "data": []
          },
          "completions": []
        },
        "PATHFINDER": {
          "secondary": {
            "data": []
          },
          "completions": []
        },
        "TREKKER": {
          "secondary": {
            "data": []
          },
          "completions": []
        },
        "QUICKPIC": {
          "secondary": {
            "data": []
          },
          "completions": []
        }
      }
    },
    "occupations": {
    }
  }
};


export interface AudioState {
  audio: any;
}
export const defaultAudioState: AudioState = {
  audio: {
    audioState: 'stoped',
    audionotfound: false
  }
};
export interface AttributesState {
  attributesData: any;
}
export const defaultAttributesState: AttributesState = {
  attributesData: {}
};
export interface CategoriesState {
  categoriesData: any;
}
export const defaultCategoriesState: CategoriesState = {
  categoriesData: {}
};
export interface PathFinderChallengeState {
  pathFinderChallenge: any;
}
export const defaultPathFinderChallengeState: PathFinderChallengeState = {
  pathFinderChallenge: {
    attempts: 0,
    progress: 0,
    matchedEle: [],
    matchedEle2: [],
    cards: [],
    cards2: [],
  }

};

export interface ExplorerChallengeState {
  explorerChallenge: {
    savedIds: Array<any>,
    totalLength: number,
    score: number,
    activeIds: Array<any>
  }
}

export const defaultExplorerChallengeState: ExplorerChallengeState = {
  explorerChallenge: {
    savedIds: [],
    totalLength: 0,
    score: 0,
    activeIds: []
  }
}

export interface NavigatorWorkValuesState {
  navigatorWorkValuesData: {
    page1List: Array<any>,
    page2List: Array<any>
  }
}

export const defaultNavigatorWorkValuesState: NavigatorWorkValuesState = {
  navigatorWorkValuesData: {
    page1List: [],
    page2List: []
  }
}

//Navigator Tiebreaker Store
export interface NavigatorTieBreakerState {
  navigatorTieData: {
    list: Array<any>,
    cardsSelected: Array<any>
  }
}

export const defaultNavigatorTieBreakerState: NavigatorTieBreakerState = {
  navigatorTieData: {
    list: [],
    cardsSelected: []
  }
}

export interface NavigatorChallengeState {
  navigatorChallengeData: {
    storedData: Array<any>,
    challengeQuestions: Array<any>
  }
}

export const defaultNavigatorChallengeState: NavigatorChallengeState = {
  navigatorChallengeData: {
    storedData: [],
    challengeQuestions: []
  }
}

export interface ScoutActivityOneState {
  scoutActivityOneData: {
    storedData: Array<any>
  }
}

export const defaultScoutActivityOneState: ScoutActivityOneState = {
  scoutActivityOneData: {
    storedData: []
  }
}

export interface ScoutActivityTwoState {
  scoutActivityTwoData: {
    storedData: Array<any>
  }
}

export const defaultScoutActivityTwoState: ScoutActivityTwoState = {
  scoutActivityTwoData: {
    storedData: []
  }
}

// Avatar States 

export interface AvatarListState {
  emojiFaceData: {
    eyes: Array<string>,
    nose: Array<string>,
    mouth: Array<string>
  }
}

export const defaultAvatarListState: AvatarListState = {
  emojiFaceData: {
    eyes: [],
    nose: [],
    mouth: []
  }
};

export interface UserAvatarState {
  userEmojiData: {
    eyes: string,
    nose: string,
    mouth: string,
    color: string,
    source: string
  }
}

export const defaultUserAvatarState: UserAvatarState = {
  userEmojiData: {
    eyes: '',
    nose: '',
    mouth: '',
    color: '',
    source: ''
  }
};
export interface AvatarProfileDataState {
  avatarProfileImage: any;
};

export const defaultAvatarProfileDataState: AvatarProfileDataState = {
  avatarProfileImage: Blob
};
export interface TrekkerTopSkillsState {
  trekkerCardsSelected: {
    selectedLike: Array<{
      attributeId: number,
      description: String,
      displayName: String,
      hollandCode: String,
      icon: String,
      language: String,
      media: {
        audio: Array<{ src: String, type: String }>
      }
      mediaFile: String,
      name: String,
      standardHollandCodeType: String,
      title: String,
      type: String
    }>,
    selectedUnlike: Array<{
      attributeId: number,
      description: String,
      displayName: String,
      hollandCode: String,
      icon: String,
      language: String,
      media: {
        audio: Array<{ src: String, type: String }>
      }
      mediaFile: String,
      name: String,
      standardHollandCodeType: String,
      title: String,
      type: String
    }>,
    Cards: Array<{
      attributeId: number,
      description: String,
      displayName: String,
      hollandCode: String,
      icon: String,
      language: String,
      media: {
        audio: Array<{ src: String, type: String }>
      }
      mediaFile: String,
      name: String,
      standardHollandCodeType: String,
      title: String,
      type: String
    }>,

  }
}
export const defaultTrekkerTopSkillsState: TrekkerTopSkillsState = {
  trekkerCardsSelected: {
    selectedLike: undefined,
    selectedUnlike: undefined,
    Cards: undefined
  }
}
export interface TrekkerChallengeState {
  trekkerChallengeQues: {
    attemptedquestions: Array<any>,
    score: number,
    questions: number
  }
}
export const defaultTrekkerChallengeState: TrekkerChallengeState = {
  trekkerChallengeQues: {
    attemptedquestions: [],
    score: 0,
    questions: 0
  }
};
/* State for Explorer Task Store */
export interface ExplorerTaskStoreState {
  explorerTask: {
    savedData: Array<object>,
    savedDataIds: Array<number>
  };
}
export const defaultExpTaskStoreState: ExplorerTaskStoreState = {
  explorerTask: {
    savedData: undefined,
    savedDataIds: undefined
  }
};

export interface AddRibbonToCareerState {
  addRibbonValue: String
};

export const defaultAddRibbonToCareerState: AddRibbonToCareerState = {
  addRibbonValue: ""
};

export interface DeleteRibbonToCareerState {
  deleteRibbonValue: String
};

export const defaultDeleteRibbonToCareerState: DeleteRibbonToCareerState = {
  deleteRibbonValue: ""
};

export interface SaveThoughtBoxState {
  saveThoughtValue: String
};

export const defaultSaveThoughtBoxState: SaveThoughtBoxState = {
  saveThoughtValue: ""
};


export interface GetThoughtBoxState {
  getThoughtValue: any
};

export const defaultGetThoughtBoxState: GetThoughtBoxState = {
  getThoughtValue: {}
};