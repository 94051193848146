import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ExploreOccupationsComponent } from './explore-occs-component';
import { StaticHeaderComponent } from '../../shared/common-header-footer/header.component';
import { StaticFooterComponent } from '../../shared/common-header-footer/footer.component';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
const routes: Routes = [{
  path: 'app/library-occupations', component: ExploreOccupationsComponent,
  children: [

  ]
}];
@NgModule({
  imports: [
    CommonModule, [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], SharedModule.forRoot(),
    InfiniteScrollModule
  ],

  declarations: [ExploreOccupationsComponent],
  providers: [],
  exports: [RouterModule]

})



export class ExplorerOccupationsModule { }
