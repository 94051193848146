import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nextOccPipe",
})
export class OccPipe implements PipeTransform {
  transform(val: any, occ: string): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<OCC>>/g, occ);
  }
}

@Pipe({
  name: "sharedOccPipe",
})
export class SharedOccupationsPipe implements PipeTransform {
  transform(val: any, first: string, second: string): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<ATTRIBUTE1>>/g, first);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<ATTRIBUTE2>>/g, second);
  }
}

@Pipe({
  name: "quesList",
})
export class QuestionsListPipe implements PipeTransform {
  transform(val: any, first: number, second: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<completedQuestions>>/g, first);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<totalQuestions>>/g, second);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<totalQuestions>>/g, second);
  }
}

@Pipe({
  name: "ScorePipe",
})
export class ScoreSentencePipe implements PipeTransform {
  transform(val: any, result: number, actual: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<POINTS_EARNED>>/g, result);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<POSSIBLE_POSSIBLE>>/g, actual);
  }
}

@Pipe({
  name: "FooterPipe",
})
export class FooterYearPipe implements PipeTransform {
  transform(val: any, result: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<CURRENT_YEAR>>/g, result);
  }
}

@Pipe({
  name: "customDate",
})
export class CustomDate implements PipeTransform {
  transform(value: string): String {
    if (value != null && value !== "") {
      value = value.split("T")[0];
      let date: Date;
      date = new Date(value);
      if (date != null) {
        const month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        // to ensure YYYY-MM-DD format
        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const cmpltDate = month + "/" + day + "/" + date.getFullYear();

        return cmpltDate;
      }
    }
    return "";
  }
}
enum monthsSpanish {
  Enero,
  Febrero,
  Marzo,
  Abril,
  Mayo,
  Junio,
  Julio,
  Agosto,
  Septiembre,
  Octubre,
  Noviembre,
  Diciembre,
}
enum monthsEng {
  January,
  Febraury,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
@Pipe({ name: "datePipeOnLangChange" })
export class SpanishAndEnglishDatePipe implements PipeTransform {
  transform(date: any, value: any): any {
    let Language = sessionStorage.getItem("Language");
    if (Language === "en") {
      const data = new Date(date);
      const dayOfMonth = data.getDate();
      const nameOfMonth = monthsEng[data.getMonth()];
      const year = data.getFullYear();
      const result = nameOfMonth + " " + dayOfMonth + "," + " " + year;
      return result;
    } else {
      const data = new Date(date);
      const dayOfMonth = data.getDate();
      const nameOfMonth = monthsSpanish[data.getMonth()];
      const year = data.getFullYear();
      const result = nameOfMonth + " " + dayOfMonth + "," + " " + year;
      return result;
    }
  }
}
@Pipe({
  name: "taskSentence",
})
export class TaskSentence implements PipeTransform {
  transform(
    val: string,
    occ: string,
    weight: string,
    attribute: string,
    val2: any
  ): String {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<occ>>/g, occ);
    if (weight.toLowerCase() == "high") {
      weight = val2.high;
    } else if (weight.toLowerCase() == "medium") {
      weight = val2.medium;
    } else if (weight.toLowerCase() == "low") {
      weight = val2.low;
    } else {
      weight = weight;
    }
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<weight>>/g, weight);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<attribute>>/g, attribute);
    return val;
  }
}
@Pipe({
  name: "buttonTextChange",
})
export class ButtonTextChange implements PipeTransform {
  transform(val: string, val2: any): String {
    if (val.toLowerCase() === "high") {
      return val2.high;
    } else if (val.toLowerCase() === "medium") {
      return val2.medium;
    } else if (val.toLowerCase() === "low") {
      return val2.low;
    } else if (val.toLowerCase() === "all") {
      return val2.selectAll;
    } else {
      return val;
    }
  }
}

@Pipe({
  name: "ModuleNamePipe",
})
export class ModuleNamePipe implements PipeTransform {
  transform(val: any, result: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<MODULE_NAME>>/g, result);
  }
}

@Pipe({
  name: "challengePipe",
})
export class challengePathfinderPipe implements PipeTransform {
  transform(val: any, result: number, actual: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<SCORE_TRIES>>/g, result);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<SCORE_MATCHES>>/g, actual);
  }
}

@Pipe({
  name: "libraryCharacteristicPipe",
})
export class libraryCharacteristicPipe implements PipeTransform {
  transform(val: any, filterBy: any, characteristic: any): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<filterBy>>/g, filterBy);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<characteristic>>/g, characteristic);
  }
}

@Pipe({
  name: "libraryPathFinderPipe",
})
export class libraryPathFinderPipe implements PipeTransform {
  transform(val: any, filterBy: any, personalityType: any): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<filterBy>>/g, filterBy);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<personalityType>>/g, personalityType);
    val = val.replace(/<<personalityTipos>>/g, personalityType);
    return val;
  }
}
 
@Pipe({
  name: 'libraryScoutPipe'
})

export class libraryScoutPipe implements PipeTransform {

  transform(val: any, filterBy: any, group: any): any {
    val = val.replace('< <', '<<');
    val = val.replace('> >', '>>');
    val = val.replace(/<<filterBy>>/g, filterBy);
    val = val.replace('< <', '<<');
    val = val.replace('> >', '>>');
    return val.replace(/<<group>>/g, group);
  
  }
}

@Pipe({
  name: 'libraryNavigatorPipe'
})

export class libraryNavigatorPipe implements PipeTransform {

  transform(val: any, filterBy: any, value: any): any {
    val = val.replace('< <', '<<');
    val = val.replace('> >', '>>');
    val = val.replace(/<<filterBy>>/g, filterBy);
    val = val.replace('< <', '<<');
    val = val.replace('> >', '>>');
    return val.replace(/<<value>>/g, value);
  
  }
}

@Pipe({
  name: "librarySkillPipe",
})
export class librarySkillPipe implements PipeTransform {
  transform(val: any, filterBy: any, skill: any): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    val = val.replace(/<<filterBy>>/g, filterBy);
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<skill>>/g, skill);
  }
}

@Pipe({
  name: "attributeNamePipe",
})
export class AttributeNamePipe implements PipeTransform {
  transform(val: any, result: number): any {
    val = val.replace("< <", "<<");
    val = val.replace("> >", ">>");
    return val.replace(/<<attribute>>/g, result);
  }
}