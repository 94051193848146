import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import {
  AudioState,
  PageDataState,
  QuickpicQuesIdState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { RouteUrls } from "../../../shared/common-constants/route.constants";
import { environment } from "../../../../environments/environment";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { Next } from "../../../shared/common-animations/CT_animations";
import { takeWhile } from "rxjs/operators";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
import { KeyWords } from '../../../shared/models/CT-distionary';
@Component({
  selector: 'app-quickpic-question',
  templateUrl: './quickpic-question-layout.html',
  styles: [],
  animations: [Next],
})
export class QuickpicQuestionComponent implements OnInit, OnDestroy {
  theme_name: string;
  lang: string;
  index: number;
  id: any;
  answers: any = [];
  startIndex = 0;
  questions: any = [];
  optionId: any = 10;
  progress: number;
  keywords: KeyWords;
  //The completed variable to set to true, to turn on the flip animation of the button
  completed: boolean;
  nextIcon: string;
  pagesResp: any = [];
  asessmentTitle: any;
  compActive = true;
  audioState = 'stoped';
  audionotfound = false;
  headerAudioState = 'stoped';
  quesAudioState = 'stoped';
  audioType: string;

  /**this is view text from api */
  viewText:Map<string,string>;

  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    public eventService: EventdispatchService,
    public quesIdStore: Store<QuickpicQuesIdState>,
    private audioService: AudioService,
    private audioStore: Store<AudioState>,
    private router: Router,
    private utils: Utilities,
    public store: Store<PageDataState>,
    private oninitpopupservice: OnLoadPopUpService,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[4]);
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'QUICKPIC', pageCode: 'QUICKPIC_ASSESSMENT' },
    });
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') == '/app/quickpic/assessment') {
          if (e.type === 'languageChanged') {
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.ngOnInit(); //Since the pages call is already subscribed through ngOnInit() I have added this.ngONInit() and commented the bellow pages call.
            this.activatedRoute.queryParams
              .pipe(takeWhile(() => this.compActive))
              .subscribe((params) => {
                this.id = params['id'];
                if (this.id) {
                  this.apiModel.moduleName = 'quick-pic';
                  this.apiModel.endUrl = '/quick-pic/restore/' + this.id;
                  this.apiModel.sessionID =
                    sessionStorage.getItem('session_token');
                  this.apiModel.method = 'GET';
                  try {
                    this.apiCall
                      .getData([this.apiModel])
                      .pipe(takeWhile(() => this.compActive))
                      .subscribe((resp) => {
                        this.answers = JSON.parse(resp);
                        if (this.answers.length > 48) {
                          this.answers = [];
                        }
                        this.index = this.answers.length;
                        this.startIndex = this.answers.length;
                        this.progress = 2.0833 * this.startIndex;
                        this.dialogService.hideLoading();
                      });
                  } catch (e) {
                    this.utils.handleError(`quickpic-question-component.ts constructor: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                  }
                } else {
                  this.index = 0;
                }
              });
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              sessionStorage.setItem('infopopup', 'false');
              this.infopopup(); // To display info popup model text when we click on the help icon.
            }
          }
          if (e.type === 'backClicked') {
            this.stopAudio();
            if (this.startIndex === 0) {
              this.unsavedChanges();
            } else {
              this.questionback();
            }
          }
        }
      });

    if (this.lang === '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          this.headerAudioState = result.audioState;
          this.audionotfound = result.audionotfound;
          this.quesAudioState = result.audioState;
          /*If two audio icons are in same page when clicking one audio icon another audio icon must be in rest position(i.e. showing the 'volume_up icon')
                below code is for differentiating audio icons in both the conditions(i.e. playing instructional text audio and questions audio)*/
          if (this.audioType == 'headerAudio') {
            this.quesAudioState = 'stoped';
          } else if (this.audioType == 'quesAudio') {
            this.headerAudioState = 'stoped';
          }
        }
      });
    this.dialogService.showLoading();
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.id = params['id'];
        if (this.id != undefined && this.id != null) {
          this.apiModel.moduleName = 'quick-pic';
          this.apiModel.endUrl = '/quick-pic/restore/' + this.id;
          this.apiModel.sessionID = sessionStorage.getItem('session_token');
          this.apiModel.method = 'GET';
          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                this.answers = JSON.parse(resp);
                if (this.answers.length > 48) {
                  this.answers = [];
                }
                this.startIndex = this.answers.length;
                this.progress = 2.0833 * this.startIndex;
                this.index = this.answers.length;
              });
          } catch (e) {
            this.utils.handleError(`quickpic-question-component.ts quick-pic: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        } else {
          this.index = 0;
        }
      });
  }
  unsavedChanges() {
    this.utils.backRoute();
  }
  infopopup() {
    this.stopAudio();
    // To Display the text that is displayed for the Quick Quick Landing page help icon.
    this.oninitpopupservice.getOnLoadPopUp('QUICKPIC_ASSESSMENT');
  }

  ngAfterViewInit() {
    this.infopopup();
  }
  stopAudio() {
    //to stop current playing audio
    this.audioType = 'stoped';
    this.audionotfound = false;
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }
  ngOnInit() {
    this.dialogService.showLoading();
    this.getData(); //without, this.getData() while changing the launguage in quick pic assessments the questions are not changing to other launguage.
    let answersIds;
    let intialVal = true;
    this.quesIdStore
      .select('quickpicAssessment')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((quesID) => {
        if (quesID && quesID['quickpicAssessment'].answers) {
          this.startIndex = quesID['quickpicAssessment'].questionsId;
          this.progress = 2.0833 * this.startIndex;
          this.answers = quesID['quickpicAssessment'].answers;
          if (
            this.answers &&
            this.answers.length != 0 &&
            this.answers.length ===
              quesID['quickpicAssessment'].totalqueslength &&
            intialVal == true
          ) {
            answersIds = this.answers.pop();
            this.optionId = answersIds.value;
            this.optionSelection();
          }
        }
        intialVal = false;
      });
    this.getQuickPicAssesmentText();
    this.setQuickPicAssessmentTitle();
  }
  getQuickPicAssesmentText() {
    this.store
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          this.pagesResp = resp.pages;
          this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS')); //had to set the keywords here as the pages take more time to respond than any other call and only after getting pages response it is possible to get the appropriate Global_Settings on lang change.
      
          /**convert to Map object */
          this.viewText = new Map(Object.entries(this.keywords.dictionary));
          if (this.pagesResp) {
            this.asessmentTitle = this.pagesResp.find(
              (obj) => obj.pageCode === 'QUICKPIC_ASSESSMENT'
            );
          }
        }
      });
    this.dialogService.hideLoading(); //As 'pages' takes more time to respond only after that hiding the spinner.
  }
  getData() {
    //When we change the language in QuicPic-Assessment the questions should also be changed in that launguge.
    this.apiModel.moduleName = 'quick-pic';
    this.apiModel.endUrl = '/quick-pic/questions';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            this.questions = JSON.parse(resp);
          }
        });
    } catch (e) {
      this.utils.handleError(`quickpic-question-component.ts getData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
    this.startIndex = 0;
    this.progress = 2.0833 * this.startIndex;
  }
  nextQuestion(questionKey, optionKey) {
    this.nextIcon = '';
    this.audionotfound = false;
    this.audioState = 'stoped';
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    if (this.startIndex < this.questions.questions.length - 1) {
      this.startIndex++;
      this.progress = 2.0833 * this.startIndex;
      this.answers.push({
        questionId: questionKey,
        value: optionKey,
      });
      this.optionId = 10;
    } else {
      this.progress = 100;
      this.answers.push({
        questionId: questionKey,
        value: optionKey,
      });
      if (this.id != null && this.id != undefined) {
        this.apiModel.moduleName = 'quick-pic';
        this.apiModel.endUrl = '/quick-pic/update/' + this.id;
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'PUT';
        this.apiModel.data = {
          answers: this.answers,
        };
        try {
          this.apiCall
            .getData([this.apiModel])
            .pipe(takeWhile(() => this.compActive))
            .subscribe((resp) => {
              if (resp !== undefined && resp != null) {
                resp = JSON.parse(resp);
                this.apiModel.moduleName = 'quick-pic';
                this.apiModel.endUrl = '/quick-pic/reflection/' + resp._id;
                this.apiModel.sessionID =
                  sessionStorage.getItem('session_token');
                this.apiModel.method = 'PUT';
                this.apiModel.data = {
                  reflection: ' ', // When 'reflection': '-', The newly completed Answer set having '-' initially in the notes field.
                };
                try {
                  this.apiCall
                    .getData([this.apiModel])
                    .pipe(takeWhile(() => this.compActive))
                    .subscribe((resp1) => {
                      if (resp !== undefined && resp != null) {
                        resp = resp;
                        try {
                          this.router.navigate(['/app/quickpic/result'], {
                            relativeTo: this.activatedRoute,
                            queryParams: {
                              id: resp._id,
                            },
                          });
                        } catch (e) {
                          this.utils.handleError(`quickpic-question-component.ts reflection: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                        }
                      }
                    });
                } catch (e) {
                  this.utils.handleError(`quickpic-question-component.ts reflection exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                }
              }
            });
        } catch (e) {
          this.utils.handleError(`quickpic-question-component.ts reflection exception 2: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      } else {
        this.apiModel.moduleName = 'quick-pic';
        this.apiModel.endUrl = '/quick-pic/save';
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'POST';
        this.apiModel.data = {
          name: 'completed',
          answers: this.answers,
        };
        try {
          this.apiCall
            .getData([this.apiModel])
            .pipe(takeWhile(() => this.compActive))
            .subscribe((resp) => {
              if (resp !== undefined && resp != null) {
                resp = JSON.parse(resp);
                this.apiModel.moduleName = 'quick-pic';
                this.apiModel.endUrl = 'quick-pic/reflection/' + resp._id;
                this.apiModel.sessionID =
                  sessionStorage.getItem('session_token');
                this.apiModel.method = 'PUT';
                this.apiModel.data = {
                  reflection: ' ', // When 'reflection': '-', The newly completed Answer set having '-' initially in the notes field.
                };
                try {
                  this.apiCall
                    .getData([this.apiModel])
                    .pipe(takeWhile(() => this.compActive))
                    .subscribe((resp1) => {
                      if (resp !== undefined && resp != null) {
                        resp = resp;
                        try {
                          this.router.navigate(['/app/quickpic/result'], {
                            relativeTo: this.activatedRoute,
                            queryParams: {
                              id: resp._id,
                            },
                          });
                        } catch (e) {
                          this.utils.handleError(`quickpic-question-component.ts put: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                        }
                      }
                    });
                } catch (e) {
                  this.utils.handleError(`quickpic-question-component.ts put exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                }
              }
            });
        } catch (e) {
          this.utils.handleError(`quickpic-question-component.ts put exception 2: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      }
    }
    this.quesIdStore.dispatch({
      type: 'QuickPIC_QuestionsID',
      payload: {
        quickpicAssessment: {
          questionsId: this.startIndex,
          answers: this.answers,
          totalqueslength: this.questions.questions.length,
        },
      },
    });
  }
  routeNext(completed) {
    try {
      this.router.navigate(['/app/quickpic/restore'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          completed: completed,
        },
      });
    } catch (e) {
      this.utils.handleError(`quickpic-question-component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  audioClick(text, data, audioType) {
    this.audioType = audioType;
    this.audioService.audioFunction(text, data);
  }
  partialSave() {
    if (this.id != null && this.id != undefined) {
      this.apiModel.moduleName = 'quick-pic';
      this.apiModel.endUrl = '/quick-pic/update/' + this.id;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'PUT';
      this.apiModel.data = {
        answers: this.answers,
      };
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            if (resp !== undefined && resp != null) {
              this.routeNext('partial');
            }
          });
      } catch (e) {
        this.utils.handleError(`quickpic-question-component.ts quick-pic put: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    } else {
      this.apiModel.moduleName = 'quick-pic';
      this.apiModel.endUrl = '/quick-pic/save';
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'POST';
      this.apiModel.data = {
        name: 'NA',
        answers: this.answers,
      };
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            if (resp !== undefined && resp != null) {
              this.routeNext('partial');
            }
          });
      } catch (e) {
        this.utils.handleError(`quickpic-question-component.ts quick-pic post: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    }
  }

  optionSelection() {
    this.completed = !this.completed;
    this.nextIcon = 'yes';
  }
  questionback() {
    if (this.startIndex > 0) {
      this.startIndex--;
      this.optionId =
        this.answers[this.answers.length - 1] &&
        this.answers[this.answers.length - 1].value;
      //This will change the porgress bar code on clicking the quick pic assessment, back button.
      this.progress = 2.0833 * this.startIndex;
      this.answers.pop();
      this.optionSelection();
    }
  }
  ngOnDestroy() {
    this.compActive = false;
  }

  private setQuickPicAssessmentTitle() {
    this.titleService.setTitle('Quick Pic Assessment - CareerTrek');
  }
}
