import { DialogopenService } from './../../../shared/common-modal/modalpopup.service';
import { ANIMATION_MOVE_Y } from './../../../shared/common-animations/CT_animations';
import { Location } from '@angular/common';
/**Import angular core packages */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { AudioState, ExplorerTaskStoreState, PageDataState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
// import { throwMatDuplicatedDrawerError } from '@angular/material';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-explorer-intro',
	templateUrl: './explorer-intro-layout.html',
	animations: [ANIMATION_MOVE_Y]
})

export class ExplorerIntroComponent implements OnInit, OnDestroy {
	languagekey = 'en';
	multipleScores = [{ 'score': [0, 0, 0, 0, 0] }, { 'score': [0, 0, 0, 0, 0] }, { 'score': [0, 0, 0, 0, 0] }];
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	// Intialized with emptuy array to display directly play again button, instead of showing Go and then play again
	multipleScoresActive = [];
	data: any;
	score: Array<any> = [];
	toogleState = true;
	modeldata: any;
	id: void;
	url: any;
	lang = '';
	cards: any;
	dialog: any;
	theme_name;
	respdata: any;
	compActive = true;
	audioState = 'stoped';
	audionotfound = false;

	constructor(private _location: Location, public dialogService: DialogopenService,
		public eventService: EventdispatchService,
		private apiCall: ApiCallService,
		private audioStore: Store<AudioState>,
		private explorerTaskStore: Store<ExplorerTaskStoreState>,
		private audioService: AudioService,
		private apiModel: ApiCallClass, private activatedRoute: ActivatedRoute,
		private store: Store<PageDataState>, private router: Router,
		private oninitpopupservice: OnLoadPopUpService, private utils: Utilities,
		private titleService: Title
	) {
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[0]);
		this.store.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'EXPLORER',pageCode:'EXPLORER_INTRO'}});	
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		this.lang = sessionStorage.getItem('session_token');
		 eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') === ('/app/explorer/intro')) {
				if (e.type === 'languageChanged') {
					this.languagekey = this.modeldata.language;
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
					this.getapidata('occs/categories', 'Categories', this.lang);
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					this.unsavedChanges();
				}
			}
		});

		if (this.lang === '' || this.lang === null) {
			this.lang = sessionStorage.getItem('session_token');
		}
		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
	}
	getScores(endurl, inx) {
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = 'user/characteristics/challenge/results/' + endurl;
		this.apiModel.sessionID = this.lang;
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (JSON.parse(resp) != undefined && JSON.parse(resp) != null) {
					const data = JSON.parse(resp);
					if (data.length >= 1 && data[0] != undefined) {
						this.multipleScoresActive[inx] = true;
						for (let i = 0; i < data.length; i++) {
							for (let j = i + 1; j < data.length; ++j) {
								if (data[i].score < data[j].score) {
									const a = data[i];
									data[i] = data[j];
									data[j] = a;
								}
							}
						}
						for (let i = 0; i < 5 && i < data.length; i++) {
							this.multipleScores[inx].score[i] = data[i];
						}
					}
					else{
						this.multipleScoresActive[inx] = false;
					}
					if(this.cards && (endurl == 'EXPLORER_PEOPLE' ||  
						endurl =='EXPLORER_IDEAS' || endurl== 'EXPLORER_THINGS')){
						this.dialogService.hideLoading();
					   }
				}
			});
		} catch (e) {
			this.utils.handleError(`explorer-intro-component.ts getScores: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp('EXPLORER_INTRO');
		}.bind(this));
	}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	getapidata(endurl, sessionname, lang) {
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = endurl;
		this.apiModel.sessionID = lang;
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				this.cards = [];
				const pageCodes=['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS']; //File names
				this.cards = JSON.parse(resp);
				this.cards.map((val,inx)=> {    //To get a cleaner file names we mapped the pagecodes for this.card
					val.pageCode=pageCodes[inx]
				});
				this.multipleScores = [{ 'score': [0, 0, 0, 0, 0] }, { 'score': [0, 0, 0, 0, 0] }, { 'score': [0, 0, 0, 0, 0] }];
				// this.multipleScores.length = this.cards.length;
				for (let i = 0; i < this.cards.length; i++) {
					if (this.cards[i].categoryId === 1) {
						this.getScores('EXPLORER_PEOPLE', i);
					} else if (this.cards[i].categoryId === 2) {
						this.getScores('EXPLORER_IDEAS', i);
					} else if (this.cards[i].categoryId === 3) {
						this.getScores('EXPLORER_THINGS', i);
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`explorer-intro.component.ts getapidata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	ngOnInit() {
		this.infopopup();
		let data;
		setTimeout(() => {
			this.getapidata('occs/categories', 'Categories', this.lang);
			this.dialogService.showLoading();
			 this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp.pages != undefined && resp.pages[0] != null) {
					this.modeldata = [];
					data = (resp.pages);
					for (let i = 0; i < data.length; i++) {
						if (data[i].pageCode === 'EXPLORER_INTRO') {
							this.modeldata = data[i];
							this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
						}
					}
				}
				if (this.cards != undefined && this.modeldata.language != this.languagekey) {
					this.languagekey = this.modeldata.language;
				}
			});
		}, 0);

		this.setExplorerIntroTitle();
	}
	modalOpen(category, id, completed) {
		this.explorerTaskStore.dispatch({type: 'EXPLORER_TASK_STORE_VAL', payload: {}});
		let name = '';
		if (parseInt(id, 10) === 2) {
			name = 'EXPLORER_IDEAS';
		} else if (parseInt(id, 10) === 1) {
			name = 'EXPLORER_PEOPLE';
		} else if (parseInt(id, 10) === 3) {
			name = 'EXPLORER_THINGS';
		}
		this.oninitpopupservice.getOnLoadPopUp(name);
		sessionStorage.setItem('popupType', 'false')
		this.apiModel.moduleName = 'explorer';
		if (completed) {
			this.apiModel.endUrl = 'occs/category/random/' + id + '/3';
		} else {
			this.apiModel.endUrl = 'occs/category/default/' + id;
		}
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp) {
					this.respdata = JSON.parse(resp);
					if (this.respdata[0]) {
						try {
							
							this.audionotfound = false;
							this.audioState = 'stoped';
							this.audioStore.dispatch({
								type: 'SET_AUDIO_STOPED'
								});
							const evnt = document.createEvent('CustomEvent');
							this.eventService.dispatchAudioStop(evnt);
							this.router.navigate(['/app/explorer/task'], {
								relativeTo: this.activatedRoute,
								queryParams: {
									category: id,
									pageCode: name,
									occId: this.respdata[0].occId,
									data: this.respdata[0].occId + '&' + this.respdata[1].occId + '&' + this.respdata[2].occId
								}
							});
						} catch (e) {
							this.utils.handleError(`explorer-intro-component.ts getOnLoadPopUp: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
						}
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`explorer-intro-component.ts modalOpen: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	unsavedChanges() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.router.navigateByUrl('/app/landing');
	}
	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	ngOnDestroy() {
		this.compActive = false;
	}

	private setExplorerIntroTitle(){
		this.titleService.setTitle("Explorer Intro - CareerTrek");
	}
}
