
/**Import angular core packages */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeDataState, AudioState, OccsDataState, OccThumbState, PageDataState, PathFinderquesState, PathFinderTopSkillsState, PathwayOccViewedCareerState, PathwayOccViewedState, SiteDataState, UserProfileState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { take, takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Next } from '../../../shared/common-animations/CT_animations';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-pathfinder-assessment',
	templateUrl: './pathfinder-layout.html',
	animations: [Next]
})

export class PathFinderAssessmentComponent implements OnInit, OnDestroy {
	languageKey: any;
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	token: string;
	theme_name: string;
	displayName = '';
	saveArray = [];
	occStoreData: any = [];
	sitedata: any;
	length = 1;
	defaultdata: any;
	attributes: any;
	name: any;
	occArray = [];
	defaultoccsdata = [];
	like = [];
	unlike = [];
	occsdata = [];
	nameArray = [];
	lengtharray = 0;
	playPromise: any;
	audio: any;
	audioSubscription: any;
	audionotfound = false;
	occCardAPIUrl: string;
	assetsURL: string;
	iconsList: any;
	compActive = true;
	pathFinderAssess: any;
	hollandCode;//variable for storing the hollandCode key value.
	attributeId;//variable for storing the attributeId.
	likeAttributeId;//variable for storing the liked attributeId.
	unlikeAttributeId;//variable for storing the unliked attributeId.
	idArray: any = [];
	attribute_id: any = [];
	toStoreLikedDataInArray = [];//Variable used to store the Defaultdata and to assign hollandCode to redirect to result page when only two types are liked.
	subscription: Subscription;
	questionsAudioState = 'stoped';
	headerAudioState = 'stoped';
	audioType: string;
	nextIcon = '';
	selectedOption = '';
	OpenedOccList = [];
	varForOccIds: any = [];
	viewedOccs: any;
	profileData: any;
	isModalActive: boolean;
	careerItem: any;
	popupSelectDataIndex: any;
	thumbFalse = [];
	thumbTrue = [];
	favourate: any;
	completed: boolean = true;
	// varForOccIds: any = [];
	constructor(private _location: Location, public eventService: EventdispatchService, private oninitpopupservice: OnLoadPopUpService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store1: Store<AttributeDataState>,
		private storeOccs: Store<OccsDataState>,
		private storeSites: Store<SiteDataState>,
		private userProfileStore: Store<UserProfileState>,
		private pathFinderAssessmentStore: Store<PathFinderquesState>,
		private store: Store<PageDataState>,
		private pathFinderTopSkillsStore: Store<PathFinderTopSkillsState>,
		private pathOccViewedListStore: Store<PathwayOccViewedState>,
		private pathOccViewedCareerStore: Store<PathwayOccViewedCareerState>,
		private occThumbStateStore: Store<OccThumbState>,
		private apiModel: ApiCallClass,
		private apiCall: ApiCallService, private utils: Utilities,
		private audioStore: Store<AudioState>, private audioService: AudioService,
		public dialogService: DialogopenService,
		@Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
		@Inject('OCC_CARD_URL') private occCardUrl: string,
		private titleService: Title) {
		this.occCardAPIUrl = occCardUrl;
		this.assetsURL = assetsUrl;
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[1]);
		// sessionStorage.setItem('pageTitle', '2');
		//    sessionStorage.setItem('moduleKey', 'PATHFINDER##PATHFINDER_ASSESS'); // Since using numbers is not a good way we have used pagecode and module name for page heading.
		this.store.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'PATHFINDER', pageCode: 'PATHFINDER_ASSESS' } });

		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		// this.infopopup();
		sessionStorage.setItem('isModalActive', 'false');
		this.userProfileStore.dispatch({
			type: 'GET_USER_PROFILE_DATA', payload: {
				methodVal: 'GET', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: '/user/profile'
			}
		});
		this.pathOccViewedListStore.dispatch({
			type: 'GET_PATHWAY_OCC_VIEWED_LIST', payload: {
				methodVal: 'GET', module_Name: 'pathfinder ',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: 'user/occ/viewed/list/pathfinder '
			}
		});
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/pathfinder/assessment')) {
				if (e.type === 'languageChanged') {
					this.nameArray.length = 0;//making sure that the array is empty on lang change.
					this.token = sessionStorage.getItem('session_token');
					// this.oninitpopupservice.pageCallText(this.lang, 'pages');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.oninitpopupservice.pageCallText(this.token, 'attributes/hollandcode');
					if (this.attribute_id.length) {
						//On lang change the data(the occs order also) at the app level changes. After like or unlike few occs then on changing the lang making sure that the the data from pevious selection is empty. If not done then some occs might miss to be viewed for selecting the like or unlike.
						this.attribute_id = [];
						this.toStoreLikedDataInArray = [];
						this.idArray = [];
						this.name = "";
					} else {
						this.attributeId = undefined;//On navigating from intro page to assessments page, then changing the language and clicking on like or unlike resulted in navigation to topskills to avoid that to happen assigning undefined.
					}
					this.emptyValues();
					//Dispatching the empty values to start again as on language change the occs order might change.
					this.pathFinderAssessmentStore.dispatch({
						type: 'PATHFINDER_ASSESSMENT_VAL', payload: {
							pathFinderques: {
								name: this.name, like: this.like, idArray: this.idArray,
								unlike: this.unlike, saveArray: this.saveArray, nameArray: this.nameArray, likeAttributeId: this.likeAttributeId, toStoreLikedDataInArray: this.toStoreLikedDataInArray
							}
						}
					});

					this.ngOnInit();
				}
				if (e.type == 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type == 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type == 'backClicked') {
					const evnt = document.createEvent('CustomEvent');
					this.eventService.dispatchAudioStop(evnt);
					this.unsavedChanges();
				}
			}

		});
		this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem("isModalActive") === "false") {
          if (!this.isModalActive) {
            this.headerAudioState = result.audioState;
            this.questionsAudioState = result.audioState;
            this.audionotfound = result.audionotfound;
            /*If two audio icons are in same page when clicking one audio icon another audio icon 
					must be in rest position(i.e. showing the 'volume_up icon'). Below code is for differentiating 
					audio icons in both the conditions(i.e. playing instructional text audio and questions audio)*/
            if (this.audioType == "headerAudio") {
              this.questionsAudioState = "stoped";
            } else if (this.audioType == "questionsAudio") {
              this.headerAudioState = "stoped";
            }
          }
        }
      });
		this.pathOccViewedCareerStore.select('pathwayOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata["pathwayOccViewedCareers"]) {
				this.dialogService.hideLoading();
			}
		});

		this.pathOccViewedListStore.select('pathwayOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata["pathwayOccViewedList"]["modules"]) {
				this.viewedOccs = respdata["pathwayOccViewedList"];
			}
		});
	}
	audioClick(text, data, audioType) {
		this.audioType = audioType;
		this.audioService.audioFunction(text, data);
	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_ASSESS');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioType = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	getApiData() {
		this.defaultoccsdata = [];
		if (this.occStoreData.length > 0 && this.varForOccIds.length > 0) {
			for (let i = 0; i < this.occStoreData.length; i++) {
				if (this.varForOccIds.indexOf(this.occStoreData[i].occId) >= 0) {
					if (this.viewedOccs) {
						const occIndex = this.viewedOccs.modules.PATHFINDER.occs.reviewed.findIndex(x => x.occId === this.occStoreData[i].occId);
						if (occIndex >= 0) {
							this.occStoreData[i]['opened'] = true;
						} else {
							this.occStoreData[i]['opened'] = false;
						}
					}
					this.defaultoccsdata.push(this.occStoreData[i]);
				}
			}
			if (this.defaultoccsdata[0] != undefined && this.languageKey != this.defaultoccsdata[0].language
				&& this.defaultdata.language != this.languageKey) {
				this.languageKey = this.defaultoccsdata[0].language;
				this.dialogService.hideLoading();
			}
		}
	}

	getdata() {
		let varToStoreAttributeObj;
		let attrHollandCode = [];
		try {
			this.store1.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				this.attributes = (resp.attribute);
				for (let i = 0; i < this.attributes.length; i++) {
					if (this.attributes[i].hollandCode) {
						if (attrHollandCode.indexOf(this.attributes[i].hollandCode) < 0) {
							attrHollandCode.push(this.attributes[i].hollandCode);
						}
					}
					if (this.attributeId == this.attributes[i].attributeId) {
						this.defaultdata = this.attributes[i];
						if (this.attribute_id.indexOf(this.attributes[i].attributeId) < 0) {
							this.attribute_id.push(this.attributes[i].attributeId);
						}
					}
				}

			});
			varToStoreAttributeObj = this.attributes.find(val => val.attributeId === parseInt(this.attributeId));
			this.storeOccs.select('occs').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp && resp.occs) {
					this.occStoreData = JSON.parse(resp.occs);
					this.getApiData();
				}
			})
			this.storeSites.select('sites').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
				if (respdata && respdata.moduleDefaults && respdata.moduleDefaults.length > 0) {
					this.sitedata = respdata;
					const resp = this.sitedata;
					this.occArray = [];
					if (resp.moduleDefaults && varToStoreAttributeObj) {
						for (let i = 0; i < resp.moduleDefaults.length; i++) {
							if (resp.moduleDefaults[i].module == 'PATHFINDER') {
								//Making sure that the data(occs) is pushed into occArray only if it comes in the response of the attributes api so that the occ which doesnot comes in the attribute response is not pushed.Hence there wont be any mismatch of names and images in personality type cards.
								if (attrHollandCode.indexOf(resp.moduleDefaults[i].hollandCode) >= 0) {
									this.occArray.push(resp.moduleDefaults[i]);
									let toStoreRespHollandCode = resp.moduleDefaults[i].hollandCode;
									let toStoreVarHollandCode = varToStoreAttributeObj.hollandCode
									if (toStoreRespHollandCode === toStoreVarHollandCode) {
										this.lengtharray = resp.moduleDefaults[i].occIds.length;
										this.varForOccIds = resp.moduleDefaults[i].occIds;
										this.getApiData();
									}
								}
							}
						}
					}
				}
			})
		} catch (e) {
			this.utils.handleError(`pathfinder-component.ts getdata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	modalOpen(item, i) {
		this.stopAudio();
		sessionStorage.setItem('isModalActive', 'true');
		this.iconsList = [];
		this.careerItem = item;
		this.popupSelectDataIndex = i;
		this.isModalActive = true;
		for (let i = 0; i < item.attributes.length; i++) {
			for (let j = 0; j < this.attributes.length; j++) {
				if (item.attributes[i].name === this.attributes[j].hollandCode) {
					this.iconsList.push({ 'hollandCode': item.attributes[i].name, 'title': this.attributes[j].displayName });
				}
			}
		}
		//Assigning icons to the pass in parameter of item from html
		item['icons'] = this.iconsList;
		this.dialogService.pathfinderOccsPopUp(item, '');
		this.dialogService.dialogRef
			.afterClosed()
			.pipe(takeWhile(() => this.compActive))
			.subscribe((result) => {
				sessionStorage.setItem('isModalActive', 'false');
				this.isModalActive = false;
				if (result === '') {
					this.defaultoccsdata[i]['viewed'] = true;
					this.pathOccViewedCareerStore.dispatch({
						type: 'GET_PATHWAY_OCC_VIEWED_LIST', payload: {
							methodVal: 'PUT', module_Name: 'Pathfinder',
							path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
							body_Params: {}, endUrlVal: 'user/occ/viewed/' + item.occId + '/pathfinder'
						}
					});
				}
			});
	}
	emptyValues() {
		this.occArray = [];
		this.defaultoccsdata = [];
		this.like = [];
		this.unlike = [];
		this.likeAttributeId = [];
		this.unlikeAttributeId = [];
		this.occsdata = [];
		this.nameArray = [];
		this.lengtharray = 0;
		this.saveArray = [];
		this.occStoreData = [];
		this.sitedata = [];
		this.length = 0;
		this.defaultdata = [];
		this.attributes = [];

	}
	ngOnInit() {
		this.compActive = true;
		this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.pathFinderAssess = data.find((obj => obj.pageCode === 'PATHFINDER_ASSESS'));
			}
		}
		);
		setTimeout(() => {
			this.emptyValues();
			let intialVal = true; // based on this intialVal removing the elements from saveArray,like and unlike arrays.
			this.pathFinderAssessmentStore.select('pathFinderques').pipe(takeWhile(() => this.compActive)).subscribe(quesresp => {
				this.idArray = quesresp['pathFinderques'].idArray;
				this.name = quesresp['pathFinderques'].name;
				this.likeAttributeId = quesresp['pathFinderques'].likeAttributeId;
				this.toStoreLikedDataInArray = quesresp['pathFinderques'].toStoreLikedDataInArray;
				this.like = quesresp['pathFinderques'].like;
				this.unlike = quesresp['pathFinderques'].unlike;
				this.nameArray = quesresp['pathFinderques'].nameArray;
				this.saveArray = quesresp['pathFinderques'].saveArray;
				this.length = this.idArray.length;
				if (this.idArray.length === 6 && intialVal == true) {
					this.attributeId = this.idArray[this.idArray.length - 1];
					this.unsavedChanges();
				}
				intialVal = false;
			});
			this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
				if (!this.name && !this.attributeId) {
					this.name = params['attrName'];
					this.hollandCode = params['hollandcode'];
					this.attributeId = params['attributeId'];
				}
			});
			this.getdata();

		});

		this.setPathfinderAssessmentTitle();
	}
	toogleData() {
		for (let i = 0; i < this.occArray.length; i++) {
			if (this.nameArray.indexOf(this.occArray[i].name) < 0) {
				this.name = this.occArray[i].name;
				this.lengtharray = this.occArray[i].occIds.length;
				this.varForOccIds = this.occArray[i].occIds;
				this.getApiData();
				this.esLangMethod();
				break;
			}
		}
		for (let i = 0; i < this.attributes.length; i++) {
			if (this.name == this.attributes[i].name) {
				this.defaultdata = this.attributes[i];
				this.attributeId = this.attributes[i].attributeId;
			}
		}
	}
	//This method is called when on spanish language for defaultdata
	esLangMethod() {

		let someValue = this.occArray.find(val => val.name === this.name);
		try {
			for (let i = 0; i < this.attributes.length; i++) {
				if (someValue.hollandCode == this.attributes[i].hollandCode) {
					this.defaultdata = this.attributes[i];
				}
			}
		} catch (e) {
			this.utils.handleError(`pathfinder-component.ts esLangMethod: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	// Here checking nameArray(question name) with occArray(question name) then assigning the elements
	backtoogleData() {
		if (this.attributes.length) {
			let toStoreAttribute;
			for (let i = 0; i < this.occArray.length; i++) {
				if (this.attribute_id.indexOf(this.idArray[this.idArray.length - 1])) {
					this.attributeId = this.idArray[this.idArray.length - 1];
					this.lengtharray = this.occArray[i].occIds.length;
					toStoreAttribute = this.attributes.find(obj => obj.attributeId === this.attributeId);
					if (toStoreAttribute) {
						let getoccIds = this.occArray.find(obj => obj.hollandCode === toStoreAttribute.hollandCode);
						this.varForOccIds = getoccIds.occIds;
						this.getApiData();
					}
					break;
				}
				else {
					this.compActive = false;//To avoid the multiple subscriptions of the store.
					this.ngOnInit();
					toStoreAttribute = this.attributes.find(obj => obj.attributeId === this.attributeId)
					if (toStoreAttribute) {
						let getoccIds = this.occArray.find(obj => obj.name === toStoreAttribute.name);
						this.lengtharray = this.occArray[i].occIds.length;
						this.varForOccIds = getoccIds.occIds;
						this.getApiData();
					}
				}

			}
			for (let i = 0; i < this.attributes.length; i++) {
				if (this.attributeId == this.attributes[i].attributeId) {
					this.defaultdata = this.attributes[i];
				}
			}
		}
	}

	nextQuestion(option) {
		this.nextIcon = 'yes';
		this.selectedOption = option;
	}
	save(data) {
		this.nextIcon = '';
		//the below variable used to push the names into the like Array if not used, then only name pushed.
		let toStoreDefaultData = this.defaultdata;
		this.questionsAudioState = 'stoped';
		this.headerAudioState = 'stoped';
		this.audioStore.dispatch({
			type: 'SET_AUDIO_STOPED'
		});
		this.audionotfound = false;
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		if (this.length < this.occArray.length - 1) {
			this.length++;
			//Below if condition will make sure that only english names are pushed to nameArray irrespective of lang
			let pushVal = this.occArray.find(val => val.hollandCode === this.defaultdata.hollandCode);
			if (!this.nameArray.length) {
				this.nameArray.push(pushVal.name);
			}
			else {
				this.nameArray.push(this.name);
			}
			this.saveArray.push(data);
			if (data === 'like') {
				this.like.push(toStoreDefaultData.displayName);
				this.toStoreLikedDataInArray.push(toStoreDefaultData);
				this.likeAttributeId.push(toStoreDefaultData.attributeId);
			} else {
				this.unlike.push(toStoreDefaultData.displayName);
				this.unlikeAttributeId.push(toStoreDefaultData.attributeId);

			}
			this.idArray.push(toStoreDefaultData.attributeId);
			this.toogleData();
		} else {
			this.saveArray.push(data);
			this.nameArray.push(this.name);
			this.idArray.push(toStoreDefaultData.attributeId);
			if (data == 'like') {
				this.like.push(toStoreDefaultData.displayName);
				this.toStoreLikedDataInArray.push(toStoreDefaultData);
				this.likeAttributeId.push(toStoreDefaultData.attributeId);
			} else {
				this.unlike.push(toStoreDefaultData.displayName);
				this.unlikeAttributeId.push(toStoreDefaultData.attributeId);
			}
			if (this.like.length != 2) {
				this.pathFinderTopSkillsStore.dispatch({ type: 'PATHFINDER_TOPSKILLS_VAL', payload: { cardsSelected: undefined } });
				this.router.navigateByUrl('/app/pathfinder/topskills');
				try {
					this.router.navigate(['/app/pathfinder/topskills'], {
						relativeTo: this.activatedRoute,
						queryParams: {
							like: this.like,
							likeAttributeId: this.likeAttributeId
						}
					});
				} catch (e) {
					this.utils.handleError(`pathfinder-component.ts save topskills: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}
			} else {
				sessionStorage.setItem('pathfinderlike', JSON.stringify(this.like));
				this.router.navigateByUrl('/app/pathfinder/result');
				try {
					this.router.navigate(['/app/pathfinder/result'], {
						relativeTo: this.activatedRoute,
						queryParams: {
							like: this.like[0] + '&' + this.like[1],
							hollandCode: this.toStoreLikedDataInArray[0].hollandCode + '&' + this.toStoreLikedDataInArray[1].hollandCode
						}
					});
				} catch (e) {
					this.utils.handleError(`pathfinder-component.ts save pathfinderlike: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}
			}
		}
		this.pathFinderAssessmentStore.dispatch({
			type: 'PATHFINDER_ASSESSMENT_VAL', payload: {
				pathFinderques: {
					name: this.name, like: this.like, idArray: this.idArray,
					unlike: this.unlike, saveArray: this.saveArray, nameArray: this.nameArray, likeAttributeId: this.likeAttributeId, toStoreLikedDataInArray: this.toStoreLikedDataInArray
				}
			}
		});
	}
	unsavedChanges() {
		this.questionsAudioState = 'stoped';
		this.headerAudioState = 'stoped';
		if ((this.like.length == 0 && this.unlike.length == 0)) {
			this.utils.backRoute();
		} else if ((this.like.length > 0 || this.unlike.length > 0)) {
			this.length--;
			if (this.idArray.length === 1) {
				this.attributeId = this.idArray[0];
			}
			if (this.saveArray[this.saveArray.length - 1] == 'like') {
				this.name = this.like.pop();
				this.likeAttributeId.pop();
				this.toStoreLikedDataInArray.pop();
			} else {
				this.name = this.unlike.pop();
			}
			this.nameArray.pop();
			this.saveArray.pop();
			this.backtoogleData();
			this.idArray.pop();
			this.nextIcon = 'yes';
		}
	}
	ngOnDestroy() {
		this.audioStore.dispatch({
			type: 'SET_AUDIO_STOPED'
		});
		this.compActive = false;
	}

	private setPathfinderAssessmentTitle(){
		this.titleService.setTitle("Pathfinder Assessment - CareerTrek");
	}
}
