<div class="Career-trek-content">
  <div class="container">
    <div class="content-box" *ngIf="defaultdata!=undefined">
      <div class="quickpic-restore">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutGap.xs="5px" class="desktop-design-show">
          <div fxFlex="32%" fxFlex.sm="35%" class="">
            <mat-card class="card h-100 answer-set-card">
              <ul class="side-task-list list-style-none" m-0 p-0>
                <li class="answer-set-list" *ngFor="let item of list;index as i" pointer>
                  <mat-card class="card" flex v-center
                    [ngClass]="[(defaultdata._id==item._id) ? 'active':' ', (item.reflection!=undefined)? 'green':'brown']"
                    (click)="defaultdata=item;activeset=(item.answerSet);completed='list';partialData()" v-center h-center>
                    <div class="left-border" flex>
                      <p text-color="primary" class="w-100">{{keyWords.dictionary.answerSet}} {{item.answerSet}}</p>
                      <!--<i class="material-icons" (click)="modalOpen(defaultdata,activeset)" pointer>delete</i>-->
                      <p m-0 p-0>{{list.dateSaved | date:'MMMM d, y'}}&nbsp;&nbsp;</p>
                    </div>
                    <!-- delete(item._id) -->
                  </mat-card>
                </li>
              </ul>
            </mat-card>
          </div>
          <div fxFlex="68%" fxFlex.sm="65%" class="answer-save-section" flex flex-column justify-between>
            <mat-card class="card  answer-card" *ngIf="defaultdata.reflection==undefined">
              <div p-5 class="w-100">
                <h4 class="text-center">{{keyWords.dictionary.incompleteStatus}}</h4>
                <h2 class="answer-text text-center" p-5 *ngIf="defaultdata.reflection==undefined">
                  {{keyWords.dictionary.questionStatus | quesList : QuestionsAtt : 48 }}</h2>
                <div flex v-center h-center>
                  <img src="{{keyWords?.media?.images?.incomplete[0]?.src}}" class="image-of-questionstatus">
                  <span class="text-nav-link" font-bold (click)="routeUrl('assessment')"
                    *ngIf="defaultdata.reflection==undefined">{{keyWords.dictionary.finishUp}}</span>
                </div>
              </div>
            </mat-card>
            <mat-card class="card  answer-card" *ngIf="defaultdata.reflection!=undefined">
              <div p-5 class="w-100">
                <h2 class="answer-text text-center" p-5 *ngIf="defaultdata.reflection!=undefined"><i
                    class="material-icons">done</i>{{keyWords.dictionary.allCompleted}}</h2>
                <div flex v-center h-center>
                  <img src="{{keyWords?.media?.images?.complete[0]?.src}}" class="image-of-allcompleted">
                  <span class="text-nav-link" font-bold (click)="routeUrl('result')"
                    *ngIf="defaultdata.reflection!=undefined">{{keyWords.dictionary.viewResults}}</span>
                </div>
                <h2 class="light-gray w-100" p-5 mb-10>{{keyWords.dictionary.reflection}}</h2>
                <textarea rows="4" class="w-100" [(ngModel)]="name">{{name}}</textarea>
                <div my-1>
                  <button mat-raised-button color="primary"
                    (click)="completedPost()">{{buttonsText.dictionary.save}}</button>&nbsp;
                  <button mat-raised-button color="primary"
                    (click)="name=defaultdata.reflection">{{buttonsText.dictionary.cancel}}</button>
                </div>
              </div>
            </mat-card>
            <div class="answer-card create-section-block" mb-0 flex v-end h-center>
              <p m-0 class="w-100 create-section-content">
                <span class="light-gray">{{keyWords.dictionary.lastSaved}}</span>
                <span flex v-center><i class="material-icons">perm_contact_calendar</i>{{defaultdata.dateSaved |
                  datePipeOnLangChange:language}}</span>
              </p>
              <button mat-raised-button color="primary"
                (click)="modalOpen(defaultdata,defaultdata.answerSet)">{{buttonsText.dictionary.delete}}</button>
            </div>
          </div>
        </div>
        
        <div class="mobile-design-show">
          <mat-card class="card" p-5>
            <div class="h-100 answer-save-section">
              <div flex v-center *ngIf="!listShow">
                <div class="answer-card w-100" flex v-center>
                  <div flex class="w-100" justify-end>
                    <button mat-raised-button class="go-to" (click)="openResultBottomSheet()">List</button>
                  </div>
                </div>
              </div>
        
              <mat-card class="card h-100 answer-set-card" *ngIf="listShow">
                <ul class="side-task-list list-style-none" m-0 p-5>
                  <li class="answer-set-list" *ngFor="let item of list;index as i" pointer>
                    <mat-card class="card" flex v-center
                      [ngClass]="[(defaultdata._id==item._id) ? 'active':'', (item.reflection!=undefined)? 'green':'brown']">
                      <div class="left-border w-100" flex>
                        <p text-color="primary" class="w-100">{{keyWords.dictionary.answerSet}} {{item.answerSet}}</p>
                        <button mat-raised-button text-color="primary" class="readmore-mobile" flex v-center h-center
                          ngStyle.xs="background-color: #dedede;margin:0px 5px;"
                          (click)="defaultdata=item;activeset=(item.answerSet);completed='list'; listShow=false">
                          <div flex v-center>
                            <div class="circle-eye" ngStyle.xs="left: -10px;background-color: #ccc;" flex v-center h-center>
                              <i class="material-icons" text-color="primary"
                                ngStyle.xs="font-size: 12px;margin:0px;">remove_red_eye</i>
                            </div>
                            <span class="w-100" text-color="primary">view</span>
                          </div>
                        </button>
                      </div>
                    </mat-card>
                  </li>
                </ul>
              </mat-card>
        
              <div *ngIf="!listShow">
                <mat-card class="card " *ngIf="defaultdata.reflection==undefined">
                  <h4 class="text-center">{{keyWords.dictionary.incompleteStatus}}</h4>
                  <h2 class="text-center" p-5 font-bold m-0 *ngIf="defaultdata.reflection==undefined">
                    {{keyWords.dictionary.questionStatus | quesList : QuestionsAtt : 48 }}
                  </h2>
                  <div flex v-center h-center>
        
                    <img src="{{keyWords?.media?.images?.incomplete[0]?.src}}" class="w-50">
        
                    <span class="text-nav-link" font-bold (click)="routeUrl('assessment')"
                      *ngIf="defaultdata.reflection==undefined">{{keyWords.dictionary.finishUp}}</span>
                  </div>
                </mat-card>
                <mat-card class="card " *ngIf="defaultdata.reflection!=undefined">
                  <h2 class="text-center" p-5 font-bold m-0 *ngIf="defaultdata.reflection!=undefined">
                    {{keyWords.dictionary.allCompleted}}
                  </h2>
                  <div p-5 class="w-100">
                    <div flex v-center h-center>
                      <img src="{{keyWords?.media?.images?.complete[0]?.src}}" class="w-50">
                      <span class="text-nav-link" font-bold (click)="routeUrl('result')"
                        *ngIf="defaultdata.reflection!=undefined">{{keyWords.dictionary.viewResults}}</span>
                    </div>
                    <div>
                      <p class="light-gray w-100" p-5 m-0>{{keyWords.dictionary.reflection}}</p>
                      <textarea rows="4" class="w-100" [(ngModel)]="name">{{defaultdata.reflection}}</textarea>
                      <div class="answer-buttons w-100">
                        <button mat-raised-button color="primary"
                          (click)="completedPost()">{{buttonsText.dictionary.save}}</button>
                        <button mat-raised-button color="primary" (click)="name=''">{{buttonsText.dictionary.cancel}}</button>
                      </div>
                    </div>
                  </div>
                </mat-card>
                <div flex h-center v-center>
                  <div class="w-100">
                    <p text-color="primary" class="answer-card-info" m-0>{{keyWords.dictionary.answerSet}}
                      {{defaultdata.answerSet}}</p>
                    <p m-0 flex v-center>
                      <span>{{keyWords.dictionary.createdOn}}</span>
                      <i class="material-icons">perm_contact_calendar</i>
                      <span>{{defaultdata.dateSaved | datePipeOnLangChange:language }}</span>
                    </p>
                  </div>
                  <button flex h-center v-center class="delete-button" (click)="modalOpen(defaultdata,defaultdata.answerSet)">
                    <img src="assets/Svg/delete.svg" alt="delete-image" />
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <!-- <div class="quickpic-restore" ngStyle.xs="padding:0 5px;"  *ngIf="hideMagnify">
      <div class="no-data-div text-center">
        <p font-bold class="w-100" >{{keyWords.dictionary.noAssessmentTaken}}</p>
        <img class="no-data-image"  flex v-center h-center [src]="buttonsText.media.images.noResults[0].src" [alt]="buttonsText.dictionary.noResults">
      </div>
    </div> -->
    
    
  </div>
</div>
<div class="quickpic-restore" ngStyle.xs="padding:0 5px;"  *ngIf="defaultdata==undefined">
  <div class="qp-noresults">
    <div class="d-table w-100 h-100">
      <div class="d-table-cell">
        <div class="container content-box">
          <div class="no-data-div">
            <p font-bold class="w-100 text-center restore-no-assesment" >{{keyWords.dictionary.noAssessmentTaken}}</p>
            <!-- <img class="no-data-image m-auto"  flex v-center h-center [src]="buttonsText.media.images.noResults[0].src" [alt]="buttonsText.dictionary.noResults"> -->
            <i class="material-icons ct-smiley text-center" flex v-center h-center text-color="primary">
                      mood
                    </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>