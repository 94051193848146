
/**Import angular core packages */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { OccsDataState, AttributeDataState, PageDataState, OccThumbState, UserProfileState, TrekkerOccViewedState, TrekkerOccViewedCareerState, TrekkerChallengeState, AudioState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { Observable } from 'rxjs';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { Location } from '@angular/common';
// import { setTimeout } from 'timers';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Next } from '../../../shared/common-animations/CT_animations';
import { take, takeWhile, skip } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-careertrekker-result',
	templateUrl: './careertrekker-result-layout.html',
	animations: [Next]
})

export class CareerTrekkerResultComponent implements OnInit, OnDestroy {

	attributesData(arg0: any, arg1: any, arg2: any): any {
		throw new Error("Method not implemented.");
	}
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	theme_name: string;
	lang: any;
	languageKey: String;
	filterModalData: {}[];
	/********* for html ***********/
	imptoogle = false;
	goodtoogle = true;
	/********* for html ******/
	finalSortFilter: any[];
	prevWeight = '';
	j: any;
	unmodifieddata = [];
	resp: any;
	thumb = [];
	weightarray2 = ['High', 'High', 'All', 'All'];
	filterArray = [];
	careertrekker: any;
	respdata: any;
	canScroll: boolean;
	good = [];
	imp = [];
	list1 = ['All', 'High', 'Medium', 'Low'];
	selector = '.infinet-scroll-height';
	goodSkill = [];
	impSkill = [];
	cards = [];
	weights = [];
	selector2 = '.infinte-scroll-height-mobile';
	//The completed variable to set to true, to turn on the flip animation of the button
	completed: boolean;
	occCardAPIUrl: string;
	assetsUrl: string;
	trekkerResultHeading: any;
	compActive = true;
	favourate = { 'occs': { 'evaluated': [] } };
	thumbFalse = [];
	thumbTrue = [];
	profileData: any;
	viewedOccs: any;
	TrekkerModalpopupPayload: string;
	popupSelectDataIndex: number;
	careerItem: any;
	audioState = 'stoped';
	audionotfound = false;
	constructor(private apiCall: ApiCallService,
		private apiModel: ApiCallClass,
		public dialogService: DialogopenService, private router: Router,
		private store: Store<AttributeDataState>, private store1: Store<OccsDataState>, private _location: Location,
		private store2: Store<PageDataState>,
		private userProfileStore: Store<UserProfileState>,
		private occThumbStateStore: Store<OccThumbState>,
		private utils: Utilities,
		private trekkerOccViewedStore: Store<TrekkerOccViewedCareerState>,
		private trekkerChallengeStore: Store<TrekkerChallengeState>,
		private trekkerOccViewedListStore: Store<TrekkerOccViewedState>,
		private audioStore: Store<AudioState>,
		private audioService: AudioService,
		private oninitpopupservice: OnLoadPopUpService, private eventService: EventdispatchService,
		@Inject('OCC_CARD_URL') private occCardUrl: string, @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		private activatedRoute: ActivatedRoute,
		private titleService: Title) {
		this.occCardAPIUrl = occCardUrl;
		this.assetsUrl = assetsMediaUrl;
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[2]);
		this.store2.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'CAREER_TREKKER', pageCode: 'LIBRARY_TREKKER' } });
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		this.good = JSON.parse(sessionStorage.getItem('twoGood'));
		this.imp = JSON.parse(sessionStorage.getItem('twoImprove'));
		this.weights = JSON.parse(sessionStorage.getItem('weights'));
		// Dynamically we are assigning the values in this.goodSkill and this.imSkill because,
		//  whenever we are changing the radio button the weights are changed and we will get the result according to the changed weights.
		this.goodSkill = [this.weights[0], this.weights[1]];
		this.impSkill = [this.weights[2], this.weights[3]];
		//listening to the customeEvent from explorer-occupation-popup thumbs clicks
		this.eventService.listenThumbUpEvent().pipe(debounceTime(1000),
			takeWhile(() => this.compActive)).subscribe((e) => {
				this.TrekkerModalpopupPayload = e.detail;
				if (this.TrekkerModalpopupPayload) {
					this.trekkerThumbEvntMethod(this.TrekkerModalpopupPayload);
				}
			})
		this.trekkerOccViewedListStore.select('trekkerOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata.trekkerOccViewedList.modules) {
				this.dialogService.showLoading();
				this.viewedOccs = respdata.trekkerOccViewedList;
			}
		});

		this.trekkerOccViewedListStore.dispatch({
			type: 'GET_TREKER_OCC_VIEWED_LIST', payload: {
				methodVal: 'GET', module_Name: 'trekker ',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: 'user/occ/viewed/list/trekker '
			}
		});
		this.userProfileStore.dispatch({
			type: 'GET_USER_PROFILE_DATA', payload: {
				methodVal: 'GET', module_Name: 'trekker ',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: '/user/profile'
			}
		});
		//In the below store subscription using skip operator because as per my observation the store gets subscribed twice everytime it is triggered. 
		//While navigating to previous page and going forward(as mentioned the store 'userProfileData' gets subscribed twice evrytime it is triggered) thus making the infinitescroll to not function properly
		//The skip(1) operator will not accept the first subscription and will make sure that the second subscription only is considered.
		this.userProfileStore.select('userProfileData').pipe(skip(1)).subscribe((respdata) => {
			this.profileData = respdata.userProfileData.occs;
			if (this.profileData) {
				this.favourate.occs.evaluated = this.profileData.evaluated;
				this.thumbFalse = [];
				this.thumbTrue = [];
				for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
					if (this.favourate.occs.evaluated[j].like == true) {
						this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
					} else if (this.favourate.occs.evaluated[j].like == false) {
						this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
					}
				}
				this.showData();

			}
		});
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/careertrekker/result')) {
				if (e.type === 'languageChanged') {
					if (this.resp[0] != undefined) {
						this.languageKey = this.resp[0].language;
					}
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					const evnt = document.createEvent('CustomEvent');
					this.eventService.dispatchAudioStop(evnt);
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}
		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
		this.occThumbStateStore.select('OccThumbList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata.OccThumbList.occs) {
				this.favourate.occs.evaluated = respdata.OccThumbList.occs.evaluated;
				this.thumbFalse = [];
				this.thumbTrue = [];
				if (this.favourate) {
					for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
						if (this.favourate.occs.evaluated[j].like == true) {
							this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
						} else if (this.favourate.occs.evaluated[j].like == false) {
							this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
						}
					}
					this.dialogService.hideLoading();
				}
			}

		});

		this.trekkerOccViewedStore.select('trekkerOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata.trekkerOccViewedCareers) {
				this.dialogService.hideLoading();
			}
		});
	}
	trekkerThumbEvntMethod(result) {
		let endurl = '';
		this.dialogService.showLoading();
		if (result) {
			if (result === 'thumb_up' || result === 'thumb_down') {
				if ((this.thumbTrue.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_up')) {
					endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
					this.thumbTrue.push(this.careerItem.occId);
				}
				if ((this.thumbFalse.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_down')) {
					endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
					this.thumbFalse.push(this.careerItem.occId);
				}
			}
			if (result === 'thumb_up_deselect' || result === 'thumb_down_deselect') {
				if ((this.thumbTrue.indexOf(this.careerItem.occId) >= 0) || (this.thumbFalse.indexOf(this.careerItem.occId) >= 0)) {
					if (result === 'thumb_up_deselect') {
						endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
					} else {
						endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
					}
				} else {
					this.setCheckboxCareer(this.careerItem.occId, this.popupSelectDataIndex);
				}
			}
			if (endurl != '') {
				this.saveThumbStatus(endurl, result, this.careerItem, this.popupSelectDataIndex);
			} else {
				this.dialogService.hideLoading();
			}
		}
	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp('LIBRARY_TREKKER');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
		
	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	filterModal() {
		this.filterModalData = [{
			'good': this.good,
			'imp': this.imp,
			'goodSkills': this.goodSkill,
			'impSkills': this.impSkill
		}];
		this.dialogService.careerTrekkerFilter(this.filterModalData);
		this.dialogService.filterDialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			this.goodSkill = result[0].goodSkills;
			this.impSkill = result[0].impSkills;
			for (let i = 0; i < this.goodSkill.length; i++) {
				this.careerTrekker(this.goodSkill[i], this.good[i].name, i);
			}
			for (let i = 0; i < this.impSkill.length; i++) {
				this.careerTrekker(this.impSkill[i], this.imp[i].name, i + 2);
			}
		});
	}

	getapidata(endurl, sessionname) {
		try {
			this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
				if (sessionname === 'skill') {
					this.careertrekker = (res.attribute);
					this.thumb.length = this.careertrekker.length;
					this.filterArray.length = this.careertrekker.length;
					for (let i = 0; i < this.thumb.length; i++) {
						this.thumb[i] = '';
						this.filterArray[i] = '';
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`careertrekker-result.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	ngOnInit() {
		this.store2.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.trekkerResultHeading = data.find((obj => obj.pageCode === 'LIBRARY_TREKKER'));
			}
		});
		this.completed = !this.completed;
		this.getapidata('attributes/skill', 'skill');
		this.weightarray2.length = this.goodSkill.length + this.impSkill.length;
		this.weights = JSON.parse(sessionStorage.getItem('weights'));
		/* start for getting the occs data */
		setTimeout(function () {
			try {
				this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
					const data = (res.attribute);
					this.attributesData = data;
					if (this.good.length > 2) {
						// filtering the Good at skills list which are already in need to improve skills list.
						this.good = this.good.filter(goodValue => !this.imp.some(improveValue => goodValue.name === improveValue.name))
						for (let counter = 0; counter < this.good.length; counter++) {
							this.goodSkill[counter] = "All"; // The good at skills list to be selected as All.
						}
					}
					if (this.imp.length > 2) {
						// filtering the Need to Improve skills list which are already in Good at skills list.
						this.imp = this.imp.filter(improveValue => !this.good.some(goodValue => improveValue.name === goodValue.name));
						for (let counter = 0; counter < this.imp.length; counter++) {
							this.impSkill[counter] = "All";
							// The need to improve skills list to be selected as All.
						}
					}
					if (data != undefined) {
						for (let i = 0; i < this.good.length; i++) {
							for (let j = 0; j < data.length; j++) {
								if (this.good[i] && this.good[i].name == data[j].name) {
									this.good[i] = data[j];
								}
								if (this.imp[i] && this.imp[i].name == data[j].name) {
									this.imp[i] = data[j];
								}
							}
						}
					}


				});
				//try to close the model below subscription will be calling multiple times. So, here using take(1)
				this.store1.select('occs').pipe(take(1)).subscribe((resp) => {
					if (resp && resp.occs) {
						this.resp = JSON.parse(resp.occs);
						this.respdata = JSON.parse(resp.occs);
						this.unmodifieddata = this.respdata;
						for (let i = 0; i < this.good.length; i++) {
							this.careerTrekker(this.weights[i], this.good[i].name, i);
						}
						for (let i = 0; i < this.imp.length; i++) {
							this.careerTrekker(this.weights[i + 2], this.imp[i].name, i + 2);
						}
						this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
						this.dialogService.hideLoading();
					}
				});

			} catch (e) {
				this.utils.handleError(`careertrekker-result.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
			}
		}.bind(this), 0);
		/*end for getting the occs data */
		this.setTrekkerResultTitle();
	}
	careerTrekker(weight, skill, inx) {
		this.weights[inx] = weight;
		sessionStorage.setItem('weights', JSON.stringify(this.weights));
		this.canScroll = true;
		this.respdata = this.filter('skill', skill, weight, this.resp);
		this.weightarray2[inx] = weight;
		if (this.filterArray.indexOf(skill) < 0) {
			this.filterArray[inx] = skill;
			let data;
			for (let i = 0; i < this.weightarray2.length; i++) {
				if (this.filterArray[i] != '' && this.filterArray[i] != undefined) {
					if (i == 0) {
						data = this.filter('skill', this.filterArray[i], this.weightarray2[i], this.resp);
					} else {
						data = this.filter('skill', this.filterArray[i], this.weightarray2[i], data);
					}
				}
			}
			this.respdata = data;
		} else {
			let data;
			for (let i = 0; i < this.weightarray2.length; i++) {
				if (this.filterArray[i] != '' && this.filterArray[i] != undefined) {
					if (i == 0) {
						data = this.filter('skill', this.filterArray[i], this.weightarray2[i], this.unmodifieddata);
					} else {
						data = this.filter('skill', this.filterArray[i], this.weightarray2[i], data);
					}
				}

			}
			this.respdata = data;
		}
		this.sortOccDataAlphabetically();
		this.showData();
	}
	sortOccDataAlphabetically(){
		this.respdata.sort(function(a, b){
		if(a.title < b.title) { return -1; };
		if(a.title > b.title) { return 1; };
		return 0;
	})
	}
	showData() {
		// For adjusting the scroll to initial state when we change the data in view mode
		window.scroll(0, 0);
		this.cards = [];
		this.j = 0;
		if (this.profileData && this.viewedOccs) {
			if (this.respdata && this.respdata[this.j] != null) {
				for (let i = 0; ( i < this.respdata.length); i++) {
					const occIndex = this.viewedOccs.modules.TREKKER.occs.reviewed.
						findIndex(x => x.occId === this.respdata[this.j].occId);
					if (occIndex >= 0 && (this.thumbTrue.indexOf(this.respdata[this.j].occId) < 0) && (this.thumbFalse.indexOf(this.respdata[this.j].occId) < 0)) {
						this.respdata[this.j]['viewed'] = true;
					} else {
						this.respdata[this.j]['viewed'] = false;
					}
					this.cards.push(this.respdata[this.j]);
					this.j++;
					/* To stop snipper immediately after getting cards data */
					this.dialogService.hideLoading();
				}
			}
		}
	}
	filter(type, name, weight, resp) {
		const respdata1 = [];
		this.canScroll = false;
		let data = [];
		if (resp != null && resp != '' && resp != undefined) {
			data = resp;
		}
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < data[i].attributes.length; j++) {
				if (data[i].attributes[j].type == type) {
					if (data[i].attributes[j].name == name) {
						if (data[i].attributes[j].weight == weight.toLowerCase() || weight == 'All') {
							respdata1.push(data[i]);
						}
					}
				}
			}
			this.finalSortFilter = respdata1;
		}
		return this.finalSortFilter;
	}
	modalOpen(item, index) {
		this.stopAudio();
		sessionStorage.setItem('isModalActive', 'true');
		this.careerItem = item;
		this.popupSelectDataIndex = index;

		let like = '';
		if (this.thumbTrue.indexOf(item.occId) >= 0) {
			like = 'like';
		}
		else if (this.thumbFalse.indexOf(item.occId) >= 0) {
			like = 'unlike';
		}
		else {
			like = 'undecided';
		}
		this.dialogService.explorerOccsPopup(item, 'skill', this.attributesData, like, sessionStorage.getItem('theme_color'));


		//Here I did a API call to set the user has viewed the career. 
		this.dialogService.dialogRef.afterClosed().pipe(
			takeWhile(() => this.compActive)).subscribe(result => {
				sessionStorage.setItem('isModalActive', 'false');
				if (result === '') {
					this.setCheckboxCareer(this.careerItem, this.popupSelectDataIndex);
				}
			});
	}
	onScroll() {
		if (this.respdata.length>0) {
			if (this.j < this.respdata.length) {
				for (let k = 0; ((k < 12) && (this.j < this.respdata.length)); k++) {
					const occIndex = this.viewedOccs.modules.TREKKER.occs.reviewed.
						findIndex(x => x.occId === this.respdata[this.j].occId);
					if (occIndex >= 0 && (this.thumbTrue.indexOf(this.respdata[this.j].occId) < 0) && (this.thumbFalse.indexOf(this.respdata[this.j].occId) < 0)) {
						this.respdata[this.j]['viewed'] = true;
					} else {
						this.respdata[this.j]['viewed'] = false;
					}
					this.cards.push(this.respdata[this.j]);
					this.j++;
				}
			} else {
				this.canScroll = true;
			}
		}
	}

	goToChallenge() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.trekkerChallengeStore.dispatch({ type: 'TREKKER_CHALLENGE_VAL', payload: { trekkerChallengeQues: { attemptedquestions: [], score: 0, questions: 0 } } });
		this.router.navigate(['../challenge'], {
			relativeTo: this.activatedRoute,
		});
	}
	unsavedChanges() {
		this.utils.backRoute();
	}
	ngOnDestroy() {
		this.compActive = false;
	}


	// This method executes to set the thumbs up or down. 
	saveThumbStatus(endurl, status, item, index) {
		this.cards[index]['viewed'] = false;
		this.occThumbStateStore.dispatch({
			type: 'GET_OCC_THUMB', payload: {
				methodVal: 'GET', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: endurl
			}
		});
		if (status === 'thumb_down_deselect' || status === 'thumb_up_deselect') {
			this.setCheckboxCareer(item, index);
		}
	}
	setCheckboxCareer(item, index) {
		this.cards[index]['viewed'] = true;
		this.trekkerOccViewedStore.dispatch({
			type: 'GET_TREKKER_OCC_VIEWED_CAREER', payload: {
				methodVal: 'PUT', module_Name: 'Trekker',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: 'user/occ/viewed/' + item.occId + '/trekker'
			}
		});

	}

	private setTrekkerResultTitle() {
		this.titleService.setTitle("Trekker Result - CareerTrek");
	}

}
