<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{ pageData.header }}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
      (click)="audioClick('inside',pageData)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="navigator-intro content-box">
      <div class="mobile-heading mobile-design-show" flex v-center justify-end>
        <button mat-fab color="primary" (click)="clickRoute()" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="center" fxLayoutGap.xs="2px" fxLayoutGap="10px">
        <div fxFlex.gt-sm="30%" fxFlex="47%" class="navigator-cards" *ngFor="let card of cardsData"
        [ngClass]="((card.icon).name=='support')?'support-card':''">
          <mat-card class="card">
            <mat-card-title text-color="primary" class="card-header">
              {{ card.title }}
            </mat-card-title>
            <mat-card-content class="card-body text-center" flex v-center h-center>
              <div>
                <img src="./assets/Svg/{{ card.icon }}.svg" alt="navigator-card-icon" />
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="desktop-design-show">
        <button mat-fab color="primary" class="next-level" (click)="clickRoute()" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>