import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Inject,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { EventdispatchService } from "../../shared/common-modal/eventdispach.service";
import { OnLoadPopUpService } from "../../shared/common-services/onloadpopup.service";
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import {
  AudioState,
  completionListState,
  DreamCareerListState,
  ModulesDataState,
  OwnOccListState,
  PageDataState,
} from "../../state-management/state/main-state";
import { StoreService } from "../../state-management/services/store-service";
import { Jump } from "../../shared/common-animations/CT_animations";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogopenService } from "../../shared/common-modal/modalpopup.service";
import { ApiCallService } from "../../shared/common-services/api-call-service";
import { Utilities } from "../../shared/common-services/utilities.service";
import { ApiCallClass } from "../../shared/common-services/api-call-model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { skip, takeWhile } from "rxjs/operators";
import { AudioService } from "../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-layout.html',
  animations: [Jump],
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit {
  CreateOwnOccs: any = [];
  careertrekker: any = [];
  discoverer: any = [];
  careercorner: any = [];
  scout: any = [];
  navigatorCompletionSet: any = [];
  quickPicCompletionSet: any = [];
  pathfinder: any = [];
  ideaScores = false;
  peopleScores = false;
  thingsScores = false;
  UserDetails: any = [];
  pagesresp: any = [];
  langkey = 'en';
  subtitles: Array<string>;
  dreamCareers = [];
  modeldata: any;
  data: any;
  title = 'hello';
  pagesText: any;
  sitesText: any;
  languageNewToken: string;
  keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
  pageTitle: any = [];
  theme_name = '';
  toogleState = true;
  tokenInfo;
  colors = environment.THEME_COLORS;
  launchHeader: String;
  compActive = true;
  setValue = false;
  launchDictionary: any;
  audioState = 'stoped';
  audionotfound = false;
  pageResData;
  assetsSVG_URL: string;
  enabledModulesList: any;
  activateModuleList = [];
  previousResolution = 0;
  // arrayForCarousel=[1];
  right = 0;
  left = 0;
  indexForCarousel = 0;
  modulesData = [];
  windowSizeValue: any;
  activateChildModuleList: any = [];
  actualRightCount: number = 0;
  rightCount: number = 0;
  leftCount: number = 0;
  navDirectionVariable;
  variableToRestrictUnnecessaryCardCreation = 0;
  cardDimensions: number = 0;
  @ViewChild('landingCarouselId')
  landingCarouselId: ElementRef;
  constructor(
    private apiCall: ApiCallService,
    private dreamCarrerList: Store<DreamCareerListState>,
    private apiModel: ApiCallClass,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public eventService: EventdispatchService,
    private utils: Utilities,
    private oninitpopupservice: OnLoadPopUpService,
    private store: Store<PageDataState>,
    private moduleStore: Store<ModulesDataState>,
    public dialogService: DialogopenService,
    private storeService: StoreService,
    public snackBar: MatSnackBar,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private commonCall: ApiCallService,
    private ownOccstore: Store<OwnOccListState>,
    private moduleCompletionstore: Store<completionListState>,
    private route: ActivatedRoute,
    @Inject('ASSETS_MEDIA_URL') private assetsSvg_URL: string,
    private titleService: Title
  ) {
    this.assetsSVG_URL = assetsSvg_URL;
    this.modulesData = this.commonCall.cardsArray;
    // Created store for getting the own occ list
    this.store.dispatch({
      type: 'GET_OWN_OCC_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'explorer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: '/user/custom-occs/list',
      },
    });

    this.moduleStore.dispatch({
      type: 'GET_MODULES',
      payload: {
        methodVal: 'GET',
        module_Name: '',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/components',
      },
    });

    this.dreamCarrerList.dispatch({
      type: 'GET_DREAMCAREERS',
      payload: {
        methodVal: 'GET',
        module_Name: 'discoverer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/dream-occs/list',
      },
    });

    this.theme_name = '';
    sessionStorage.removeItem('theme_color');
    sessionStorage.setItem('theme_color', '');
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    this.tokenInfo = this.commonCall.getDecodedAccessToken(
      sessionStorage.getItem('session_token')
    );
    const payloajson = {
      type: 'OCC_LIST_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'occs',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'occs',
      },
    };
    // this.oninitpopupservice.pageCallText(sessionStorage.getItem('session_token'), 'pages'); // Commented this line because the pages call should be called only once. If not the previous header will be displayed on the landing page when we click on the back button.
    const languageKey = sessionStorage.getItem('language');
    this.storeService.commonLanguageChange(
      languageKey,
      'OCC_index_list',
      payloajson
    );
    this.languageNewToken = sessionStorage.getItem('session_token');
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') == '/app/landing') {
          if (e.type === 'languageChanged') {
            this.langkey = this.pagesresp[0].language;
            this.languageNewToken = sessionStorage.getItem('session_token');
            this.actualRightCount = this.rightCount;
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.keywords = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            this.moduleStore.dispatch({
              type: 'GET_MODULES',
              payload: {
                methodVal: 'GET',
                module_Name: '',
                path_params: [],
                query_params: {},
                sessionID: this.languageNewToken,
                body_Params: {},
                endUrlVal: 'user/components',
              },
            });
            this.setValue = false;
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = '';
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
        }
      });
    if (!this.languageNewToken) {
      this.languageNewToken = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result) {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    this.changeTheme(this.colors[3], '');

    //Created store for module completion data
    this.moduleCompletionstore.dispatch({
      type: 'GET_MODULE_COMPLETION_DATA',
      payload: {
        methodVal: 'GET',
        module_Name: 'explorer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: '/user/profile/provide/portfolio',
      },
    });
  }

  ngAfterViewInit() {
    this.infopopup();
  }
  infopopup() {
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('LAUNCH');
      }.bind(this)
    );
  }
  routequicpic() {
    this.dialogService.showLoading(); //Added  this.dialogService.showLoading(), To get loading spinner until the restore button loads
    this.router.navigateByUrl('/app/quickpic/intro');
  }
  ngOnInit() {
    this.dreamCarrerList
      .select('dreamCareerList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((list) => {
        this.dreamCareers = list['dreamCareerList'];
      });

    this.moduleStore
      .select('modules')
      .pipe(takeWhile(() => this.compActive))
      .pipe(skip(1))
      .subscribe((result) => {
        if (result) {
          this.activateModuleList = [];
          this.activateChildModuleList = [];
          this.enabledModulesList = Object.assign({}, result);
          if (this.enabledModulesList.modules.length > 0) {
            this.enabledModulesList.modules.forEach((element) => {
              const index = this.modulesData.findIndex(
                (x) => x.name === element.moduleName
              );
              if (index >= 0) {
                element = { ...element, ...this.modulesData[index] };
              }
              this.activateModuleList.push(element);
              // }
            });
            if (this.activateModuleList.length > 0) {
              if (this.actualRightCount > 0) {
                this.rightCount = this.actualRightCount;
              } else {
                this.rightCount = this.activateModuleList.length > 3 ? 3 : 0;
              }
              this.leftCount =
                this.activateModuleList.length > 3
                  ? this.activateModuleList.length
                  : 0;
              for (let i = 0; i < this.activateModuleList.length; i++) {
                this.activateModuleList[i].indexValue = i;
                if (this.actualRightCount != 0 && i <= this.actualRightCount) {
                  this.activateChildModuleList.push(this.activateModuleList[i]);
                } else if (i < 4) {
                  this.activateChildModuleList.push(this.activateModuleList[i]);
                }
              }
            }

            if (window.innerWidth >= 960 && window.innerWidth <= 1279) {
              this.cardDimensions = 227;
            } else if (window.innerWidth >= 1280) {
              this.cardDimensions = 267;
            } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
              this.cardDimensions = 267;
            } else if (window.innerWidth >= 480 && window.innerWidth <= 599) {
              this.cardDimensions = 218;
            } else if (window.innerWidth <= 479) {
              this.cardDimensions = 276;
            }
          }
        }
      });

    //On Language change the loading spinner hides only when there is response from custom-occs/list so dispatching. Previously as this response returned null the loading spinner was not hiding.
    this.store.dispatch({
      type: 'GET_OWN_OCC_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'explorer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: '/user/custom-occs/list',
      },
    });
    this.launchDictionary = this.oninitpopupservice.getKeyWordData('LAUNCH');
    this.userDetails();
    const ref = this;
    ref.store
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages !== undefined && resp.pages[0] != null) {
          if (this.setValue === false) {
            this.store.dispatch({
              type: 'TRIGGER_TEXT_CHANGE_EVENT',
              payload: { module: 'LAUNCH', pageCode: 'LAUNCH' },
            });
            this.setValue = true;
          }
          this.pagesresp = resp.pages;
          if (this.pagesresp) {
            for (let i = 0; i < this.pagesresp.length; i++) {
              if (this.pagesresp[i].pageCode === 'LAUNCH') {
                this.launchHeader = this.pagesresp[i].header;
                this.pageResData = this.pagesresp[i];
              }
            }
          }
          this.pageTitle = this.utils.pageTitleFunc(resp.pages);
          this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
          if (
            this.pagesresp[0] &&
            this.pagesresp[0].language != this.langkey &&
            this.keywords.language != this.langkey
          ) {
            this.langkey = this.pagesresp[0].language;
          }
        }
      });
    this.ownOccList();
    this.setCareerTrekHomeTitle();
  }
  routeChange(route) {
    this.audionotfound = false;
    this.audioState = 'stoped';
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.router.navigateByUrl(route);
  }

  rightNavigation() {
    if (this.navDirectionVariable === undefined) {
      this.navDirectionVariable = 'right';
    }
    if (
      this.variableToRestrictUnnecessaryCardCreation >= 0 &&
      this.navDirectionVariable === 'right'
    ) {
      this.variableToRestrictUnnecessaryCardCreation++;
      this.rightCount++;
      if (this.activateModuleList.length > 0) {
        if (this.rightCount >= this.activateModuleList.length) {
          this.rightCount = 0;
        }
        for (let i = 0; i < this.activateModuleList.length; i++) {
          if (this.rightCount <= this.activateModuleList[i].indexValue) {
            // this.activateChildModuleList.shift();
            this.activateChildModuleList.push(
              this.activateModuleList[this.rightCount]
            );
            break;
          }
        }
      }
    } else {
      this.variableToRestrictUnnecessaryCardCreation--;
      if (this.variableToRestrictUnnecessaryCardCreation <= 0) {
        this.variableToRestrictUnnecessaryCardCreation = 0;
        this.navDirectionVariable = 'right';
      }
    }
    this.left = this.left - this.cardDimensions;
    this.right = this.right - this.cardDimensions;
  }

  leftNavigation() {
    if (this.navDirectionVariable === undefined) {
      this.navDirectionVariable = 'left';
    }
    if (
      this.variableToRestrictUnnecessaryCardCreation >= 0 &&
      this.navDirectionVariable === 'left'
    ) {
      this.variableToRestrictUnnecessaryCardCreation++;
      this.leftCount--;
      if (this.activateModuleList.length > 0) {
        if (this.leftCount < 0) {
          this.leftCount = 8;
        }
        for (let i = this.activateModuleList.length - 1; i >= 0; i--) {
          if (this.leftCount === this.activateModuleList[i].indexValue) {
            this.activateChildModuleList.unshift(
              this.activateModuleList[this.leftCount]
            );
            this.landingCarouselId.nativeElement.style.transition = 'none';
            this.left = this.left - this.cardDimensions;
            break;
          }
        }
      }
      setTimeout(() => {
        this.landingCarouselId.nativeElement.style.transition =
          '500ms ease-in-out left';
        this.left = this.left + this.cardDimensions;
      }, 100);
    } else {
      this.variableToRestrictUnnecessaryCardCreation--;
      this.landingCarouselId.nativeElement.style.transition = 'none';
      setTimeout(() => {
        this.landingCarouselId.nativeElement.style.transition =
          '500ms ease-in-out left';
        this.left = this.left + this.cardDimensions;
      }, 100);
      if (this.variableToRestrictUnnecessaryCardCreation <= 0) {
        this.navDirectionVariable = 'left';
      }
    }
  }
  ownOccList() {
    this.dialogService.showLoading();
    this.ownOccstore
      .select('ownOccList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((ownOccListResp) => {
        if (ownOccListResp && ownOccListResp.ownOccList.length >= 0) {
          try {
            this.CreateOwnOccs = ownOccListResp.ownOccList;
            this.dialogService.hideLoading();
          } catch (e) {
            this.utils.handleError(`landing-component.ts ownOccList: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSizeValue = event.target.innerWidth;
    if (this.windowSizeValue > 959 && this.windowSizeValue < 1280) {
      this.cardDimensions = 227;
      this.left = -227 * this.variableToRestrictUnnecessaryCardCreation;
    } else if (this.windowSizeValue >= 1280) {
      this.cardDimensions = 267;
      this.left = -267 * this.variableToRestrictUnnecessaryCardCreation;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
      this.cardDimensions = 267;
      this.left = -267 * this.variableToRestrictUnnecessaryCardCreation;
    } else if (window.innerWidth >= 480 && window.innerWidth <= 599) {
      this.cardDimensions = 218;
      this.left = -218 * this.variableToRestrictUnnecessaryCardCreation;
    } else if (window.innerWidth <= 479) {
      this.cardDimensions = 276;
      this.left = -276 * this.variableToRestrictUnnecessaryCardCreation;
    }
  }

  userDetails() {
    this.moduleCompletionstore
      .select('completionList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((moduleCompListResp) => {

        if (moduleCompListResp && moduleCompListResp.completionList) {
         
          try {
            this.UserDetails = moduleCompListResp.completionList;
            if (this.UserDetails) {
              if (this.UserDetails.modules.assessments) {
                if(this.UserDetails.modules.assessments.EXPLORER){
                const data =
                  this.UserDetails.modules.assessments.EXPLORER.secondary.data;
                if (data.length >= 1 && data[0] !== undefined) {
                  for (let i = 0; i < data.length; i++) {
                    if (
                      data[i].name == 'EXPLORER_IDEAS' &&
                      this.ideaScores == false &&
                      data[i].completions.length > 0
                    ) {
                      this.ideaScores = true;
                    }
                    if (
                      data[i].name == 'EXPLORER_PEOPLE' &&
                      this.peopleScores == false &&
                      data[i].completions.length > 0
                    ) {
                      this.peopleScores = true;
                    }
                    if (
                      data[i].name == 'EXPLORER_THINGS' &&
                      this.thingsScores == false &&
                      data[i].completions.length > 0
                    ) {
                      this.thingsScores = true;
                    }
                    if (
                      this.ideaScores == false &&
                      this.peopleScores == false &&
                      this.thingsScores == false
                    ) {
                      break;
                    }
                  }
                }
              }
              }
              if (
                this.UserDetails.modules.assessments.QUICKPIC &&
                this.UserDetails.modules.assessments.QUICKPIC.completions
                  .length > 0
              ) {
                this.quickPicCompletionSet =
                  this.UserDetails.modules.assessments.QUICKPIC.completions;
              }
              if (
                this.UserDetails.modules.assessments.PATHFINDER &&
                this.UserDetails.modules.assessments.PATHFINDER.completions
                  .length > 0
              ) {
                this.pathfinder =
                  this.UserDetails.modules.assessments.PATHFINDER.completions;
              }
              if (
                this.UserDetails.modules.assessments.TREKKER &&
                this.UserDetails.modules.assessments.TREKKER.completions
                  .length > 0
              ) {
                this.careertrekker =
                  this.UserDetails.modules.assessments.TREKKER.completions;
              }

              if (
                this.UserDetails.modules.assessments.DISCOVERER &&
                this.UserDetails.modules.assessments.DISCOVERER.completions
                  .length > 0
              ) {
                this.discoverer =
                  this.UserDetails.modules.assessments.DISCOVERER.completions;
              }

              if (
                this.UserDetails.modules.assessments.SCOUT &&
                this.UserDetails.modules.assessments.SCOUT.completion != ''
              ) {
                this.scout = [];
                this.scout.push(
                  this.UserDetails.modules.assessments.SCOUT.completion
                );
              }
              if (
                this.UserDetails.modules.assessments.NAVIGATOR &&
                this.UserDetails.modules.assessments.NAVIGATOR.completion != ''
              ) {
                this.navigatorCompletionSet = [];
                this.navigatorCompletionSet.push(
                  this.UserDetails.modules.assessments.NAVIGATOR.completion
                );
              }
              if (
                this.UserDetails &&
                this.UserDetails.user &&
                this.UserDetails.user.accessibility
              ) {
                sessionStorage.setItem(
                  'infoPopupState',
                  JSON.stringify(this.UserDetails.user.accessibility)
                );
              }
            }
            if (this.ideaScores == true) {
              sessionStorage.setItem('EXPLORER_IDEAS', 'completed');
            }
            if (this.peopleScores == true) {
              sessionStorage.setItem('EXPLORER_PEOPLE', 'completed');
            }
            if (this.thingsScores == true) {
              sessionStorage.setItem('EXPLORER_THINGS', 'completed');
            }
          } catch (e) {
            this.utils.handleError(`landing-component.ts userDetails: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }

  changeTheme(theme_color, title) {
    sessionStorage.setItem('theme_color', this.colors[theme_color]);
    sessionStorage.setItem('pageTitle', title);
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
  }
  explorerOccs() {
    this.router.navigateByUrl('/app/library-occupations');
    this.router.navigate(['/app/library-occupations'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        module: 'All',
      },
    });
  }

  modelOpen(module, themeColourNumber) {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.dialogService.showLoading();
    if (
      (module == 'explorer' &&
        this.ideaScores == true &&
        this.thingsScores == true &&
        this.peopleScores == true) ||
      (module == 'pathfinder' && this.pathfinder.length > 0) ||
      (module == 'careertrekker' && this.careertrekker.length > 0) ||
      (module == 'discoverer' && this.discoverer.length > 0) ||
      (module == 'scout' && this.scout.length > 0) ||
      (module == 'navigator' && this.navigatorCompletionSet.length > 0)
    ) {

      this.dialogService.hideLoading();
      if (module === 'discoverer') {
        this.dialogService.landingCreatePopup(
          module,
          this.dreamCareers,
          themeColourNumber,
          'landing'
        );
      } else {
        this.dialogService.landingCreatePopup(
          module,
          this.CreateOwnOccs,
          themeColourNumber
        );
      }
      //this.popupClose(module, themeColourNumber);
    } else {
      this.dialogService.hideLoading();
      this.snackBar.open(this.launchDictionary.dictionary.completeFirst, '', {
        duration: 1000,
        panelClass: ['failure-snackbar'],
      });
    }
  }
  popupClose(module, themeColourNumber) {
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result.option == 'delete') {
          this.apiModel.moduleName = 'OwnOcc';
          this.apiModel.endUrl = 'user/custom-occ/delete/' + result.item.occId;
          this.apiModel.sessionID = sessionStorage.getItem('session_token');
          this.apiModel.method = 'DELETE';
          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                this.userDetails();
              });
          } catch (e) {
            this.utils.handleError(`landing-component.ts popupClose delete: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
        if (result.option == 'edit') {
          try {
            this.changeTheme(themeColourNumber, 6);
            this.router.navigate(['/app/create'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                module: module,
                id: result.item.occId,
              },
            });
          } catch (e) {
            this.utils.handleError(`landing-component.ts popupClose edit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
        if (result == 'new') {
          try {
            this.changeTheme(themeColourNumber, 6);
            this.router.navigate(['/app/create'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                module: module,
              },
            });
          } catch (e) {
            this.utils.handleError(`landing-component.ts popupClose new: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }
  ngOnDestroy() {
    this.compActive = false;
  }

  private setCareerTrekHomeTitle() {
    this.titleService.setTitle('Home - CareerTrek');
  }
}

