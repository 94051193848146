<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{discovererIntro?.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="audioClick('inside',discovererIntro)"></app-audio-button>
    </h4>
  </div>

  <div class="container">
    <div class="discoverer-intro content-box">
      <div class="mobile-heading mobile-design-show forward-arrow " *ngIf="durationCompleted">
        <div flex v-center justify-end>
          <button mat-fab color="primary" (click)="activityRoute()" [@flipAnimation]="completed" class="next-level">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
      <div class="video-block">
        <!-- <video *ngIf="videoData.textSrc; else notrack" (ended)="vidEnded()" crossorigin="anonymous" disablePictureInPicture class="videobox" controls controlsList="nodownload">
          <source src="{{discovererIntro?.media?.video[0]?.src}}" type="video/mp4" />
          <track src="{{videoData?.textSrc}}" type="text/vtt" kind="subtitles" srclang="en" label="English" default/>
        </video> -->
        <!-- <ng-template #notrack> -->
        <video #videoDiscovererId (pause)="videoPaused()" (play)="videoPlayed($event)" (ended)="vidEnded()"
          disablePictureInPicture controls controlsList="nodownload" class="videobox">
          <source #videoSourceDiscoverer src="{{discovererIntroVideo}}" type="video/mp4" />
        </video>
        <div id="video-controls">
          <i *ngIf="videoStatus=='play'" id="mat-icon" class="material-icons ng-star-inserted"
            (click)="playVideo()">play_arrow</i>
          <i *ngIf="videoStatus=='pause'" id="mat-icon" class="material-icons ng-star-inserted"
            (click)="playVideo()">pause</i>
        </div>
        <!-- </ng-template> -->

      </div>
      <div class="desktop-design-show" *ngIf="durationCompleted">
        <button mat-fab color="primary" (click)="activityRoute()" class="next-level" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>

    </div>
  </div>
</div>