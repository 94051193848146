<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{pathFinderResult?.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="audioClick('inside',pathFinderResult)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        (click)="audioClick('inside',pathFinderResult)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i> 
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="pathfinder-result content-box" *ngIf="occdata!=null&&occdata!=[]&&occdata!=undefined">
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center" fxLayoutGap="5px"
        d-block-mobile ngStyle.sm="display:block;" [ngStyle]="{'display': 'flex'}">
        <div fxFlex="70%">
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
            <div fxFlex="" class="pathfinder-cards">
              <img *ngIf="first_icon" alt="{{first}}" src="./assets/pathfinder/holland/hc_{{first_icon}}.svg"
                class="m-auto mobile-design-show d-flex" ngStyle.xs="width:40px;" />

              <mat-card class="card text-center" ngStyle.xs="color: white">
                <div class="desktop-design-show">
                  <mat-card-title class="card-header-icon" flex v-center>
                    <h3 class="w-100" text-color="primary" m-0>{{first}}</h3>
                    <img *ngIf="first_icon" alt="{{first}}" src="./assets/pathfinder/holland/hc_{{first_icon}}.svg" />
                  </mat-card-title>
                </div>
                <h3 class="w-100 mobile-design-show" m-0>{{first}}</h3>
                <mat-card-content class="card-body text-center">
                  <div class="card image-card" pointer *ngFor="let a of firstholland;let i = index" tabindex="0"
                    (click)="modalOpen(a,'firstholland',i)" (keyup.enter)="modalOpen(a,'firstholland',i)" flex v-center
                    h-center>
                    <!--(load) ternary condition below is to make sure that the spinner is shown till the last image is viewed-->
                    <div class="desktop-design-show">
                      <img src="{{occCardAPIUrl}}{{a?.media?.images?.card}}"
                        (load)="i==firstholland.length-1?hideLoading():''" />
                    </div>
                    <div
                      [ngClass]="{'image-card-inactive' : (((thumbTrue.indexOf(a?.occId)===-1) && (thumbFalse.indexOf(a.occId)===-1)) && !a.viewed)}"
                      class="opacity-text desktop-design-show">
                      <p class="w-100" m-0>
                        <span class="w-100">{{a.title}}</span>
                        <!-- <i class="material-icons success-tick" *ngIf="a?.viewed" >done</i> -->
                        <!-- *ngIf="thumbTrue.indexOf(a.occId)>=0" -->
                        <!-- *ngIf="thumbFalse.indexOf(a.occId)>=0" -->
                        <img alt="thumb-up-filled icon" *ngIf="thumbTrue.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-up-outline-white.svg" />
                        <!-- <img alt="thumb-down-filled icon" *ngIf="thumbFalse.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-down-outline-white.svg" /> -->
                      </p>
                    </div>
                    <div class="mobile-design-show">{{a?.title}}</div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxFlex="" class="pathfinder-cards">
              <img *ngIf="second_icon" src="./assets/pathfinder/holland/hc_{{second_icon}}.svg" alt="{{second}}"
                class="m-auto mobile-design-show d-flex" ngStyle.xs="width:40px;" />
              <mat-card class="card text-center" ngStyle.xs="color: white;">
                <div class="desktop-design-show">
                  <mat-card-title class="card-header-icon" flex v-center m-0>
                    <h3 class="w-100" text-color="primary" m-0>{{second}}</h3>
                    <img *ngIf="second_icon" src="./assets/pathfinder/holland/hc_{{second_icon}}.svg"
                      alt="{{second}}" />
                  </mat-card-title>
                </div>
                <h3 class="w-100 mobile-design-show" m-0>{{second}}</h3>
                <mat-card-content class="card-body text-center">
                  <div class="card image-card" pointer *ngFor="let a of secondholland;let i=index" tabindex="0"
                    (click)="modalOpen(a,'secondholland',i)" (keyup.enter)="modalOpen(a,'secondholland',i)" flex
                    v-center h-center>
                    <!--(load) ternary condition below is to make sure that the spinner is shown till the last image is viewed-->
                    <div class="desktop-design-show">
                      <img src="{{occCardAPIUrl}}{{a.media?.images.card}}"
                        (load)="i==secondholland.length-1?hideLoading():''" />
                    </div>
                    <div
                      [ngClass]="{'image-card-inactive' : (((thumbTrue.indexOf(a.occId)===-1) && (thumbFalse.indexOf(a.occId)===-1)) && !a.viewed)}"
                      class="opacity-text desktop-design-show">
                      <p class="w-100" m-0>
                        <span class="w-100">{{a?.title}}</span>
                        <!-- <i class="material-icons success-tick" *ngIf="a?.viewed">done</i> -->
                        <img alt="thumb-up-filled icon" *ngIf="thumbTrue.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-up-outline-white.svg" />
                        <!-- <img alt="thumb-down-filled icon" *ngIf="thumbFalse.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-down-outline-white.svg" /> -->
                      </p>
                    </div>
                    <div class="mobile-design-show">{{a?.title}}</div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>

        <div fxFlex="35%" fxFlex.md="40%" class="shared-occ">
          <div class="desktop-design-show h-100">
            <mat-card class="card text-center h-100">
              <mat-card-title class="card-header-icon sky-blue" flex v-center>
                <h3 class="w-100" text-color="primary" m-0>
                  {{keywords?.dictionary?.sharedOccs}}
                </h3>
              </mat-card-title>
              <mat-card-content class="card-body text-center">
                <div class="selected-icons" flex v-center h-center>
                  <div *ngIf="first_icon != undefined && first_icon != null && first_icon !=''">
                    <img *ngIf="first_icon" src="./assets/pathfinder/holland/hc_{{first_icon}}.svg" alt="{{first}}" />
                  </div>
                  <div *ngIf="second_icon != undefined">
                    <img *ngIf="second_icon" src="./assets/pathfinder/holland/hc_{{second_icon}}.svg"
                      alt="{{second}}" />
                  </div>
                </div>

                <!-- Carousel Start -->
                <div class="shared-card-carousel" flex>
                  <div class="carousel"
                    [ngStyle]="{'left': left + 'px','right': right + 'px','transition':'500ms ease-in-out left'}"
                    pointer (click)="modalOpen(item,'sharedoccs',i)" *ngFor="let item of sharedoccs;let i=index"
                    (keyup.enter)="modalOpen(item,'sharedoccs',i)">
                    <div class="carousel-card">
                      <div class="carousel-width m-auto">
                        <div class="card image-card" tabindex="0">
                          <div>
                            <img src="{{occCardAPIUrl}}{{item?.media?.images.card}}" />
                          </div>

                          <div
                            [ngClass]="{'image-card-inactive' : (((thumbTrue.indexOf(item.occId)===-1) && (thumbFalse.indexOf(item.occId)===-1)) && !item.viewed)}"
                            class="opacity-text">
                            <p class="w-100" m-0>
                              <span class="w-100">{{item.title}}</span>
                              <!-- <i class="material-icons success-tick" *ngIf="item.viewed">done</i> -->
                              <img alt="thumb-up-filled icon" *ngIf="thumbTrue.indexOf(item.occId)>=0"
                                src="{{assetsURL}}thumbs-up-outline-white.svg" />
                              <!-- <img alt="thumb-down-filled icon" *ngIf="thumbFalse.indexOf(item.occId)>=0"
                                src="{{assetsURL}}thumbs-down-outline-white.svg" /> -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="left"
                  [ngStyle]="indexOfshared<=0?{'opacity':'0.5','pointer-events':'none','cursor':'default'}:{}"
                  (click)="indexOfshared > 0? indexOfshared=indexOfshared-1:'';indexOfshared >= 0? left = left +  280:'';indexOfshared >= 0? right = right +  280:'' "
                  (keyup.enter)="indexOfshared > 0? indexOfshared=indexOfshared-1:'';indexOfshared >= 0? left = left +  280:'';indexOfshared >= 0? right = right +  280:'' ">
                  <i class="material-icons" pointer text-color="primary">keyboard_arrow_left</i>
                </div>
                <div class="right"
                  [ngStyle]="indexOfshared >= sharedoccs.length-1?{'opacity':'0.5','pointer-events':'none','cursor':'default'}:{}"
                  (click)="indexOfshared <= sharedoccs.length-1? indexOfshared=indexOfshared+1:''; indexOfshared <= sharedoccs.length-1? left = left -  280:'';indexOfshared <= sharedoccs.length-1? right = right -  280:'' "
                  (keyup.enter)="indexOfshared <= sharedoccs.length-1? indexOfshared=indexOfshared+1:'';indexOfshared <=  sharedoccs.length-1? left = left -  280:'';indexOfshared <=  sharedoccs.length-1? right = right -  280:'' ">
                  <i class="material-icons" pointer text-color="primary">keyboard_arrow_right</i>
                </div>
                <!-- Carousel End -->
                <p m-0 p-5>
                  {{keywords?.dictionary?.sharedOccAttributes | sharedOccPipe :
                  first : second }}
                </p>
                <div class="you-are-text" text-color="primary">
                  {{keywords.dictionary.startChallenge}}
                </div>
                <div class="challenge-block" flex v-center h-center>
                  <img src="{{assetsURL}}star.svg" class="rotate-animation" alt="star-icon" />
                  <button mat-raised-button background-color="primary" class="challenge-button"
                    (click)="navigateChallenge()">
                    {{keywords?.dictionary?.challenge}}
                  </button>
                </div>

                <br />
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="mobile-design-show mobile-selected-cards">
          <mat-card class="card w-100">
            <mat-card-title class="card-header sky-blue" background-color="primary">
              {{keywords?.dictionary?.startChallenge}}</mat-card-title>
            <mat-card-content class="challenge-block card-body text-center" flex v-center h-center>
              <img src="{{assetsURL}}star.svg" class="rotate-animation" alt="star-icon" />
              <button mat-raised-button background-color="warn" class="challenge-button" (click)="navigateChallenge()">
                {{keywords?.dictionary?.challenge}}
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>