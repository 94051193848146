<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading" *ngIf="cardsSelected[1].icon==''">
      {{pathFinderTopSkills?.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
        (click)="audioClick('inside',pathFinderTopSkills)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',pathFinderTopSkills)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i> 
      </button> -->
    </h4>
    <h4 class="landing-page-heading" *ngIf="cardsSelected[1].icon!=''">
      {{pathFinderTopSkills?.dictionary?.selectedTopTypes}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
        (click)="audioClick('inside',pathFinderTopSkills)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="top-skills-layout content-box">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" d-block-mobile>
        <div fxFlex="30%" fxFlex.sm="40%" fxFlex.xs="100%" flex-basis class="pathfinder-cards">
          <div class="select-cards">
            <h4 class="mobile-design-show text-center">
              <div *ngIf="cardsSelected[1].icon!=''">
                <button mat-fab color="accent" class="next-level light-red" (click)="clickNext()"
                  [@flipAnimation]="completed">
                  <i class="material-icons">arrow_forward</i>
                </button>
              </div>
            </h4>

            <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap="wrap" d-block ngStyle.sm="display:block;"
              d-flex-mobile>
              <div fxFlex="49%" class="pathfinder-cards" *ngFor="let a of cards;index as i">
                <mat-card class="card card-border" pointer tabindex="0" (click)="cardSelected(a,i)"
                  (keyup.enter)="cardSelected(a,i)" flex v-center h-center>
                  <div>
                    <div flex v-center h-center>
                      <div class="desktop-design-show">
                        <img *ngIf="a.icon" src="./assets/pathfinder/holland/{{a?.icon}}.svg" alt="{{a?.displayName}}"
                          class="w-50" />
                        <img *ngIf="a.icon==cardsSelected[0].icon || a.icon==cardsSelected[1].icon"
                          src="{{assetsURL}}thumbs-up-outline-grey.svg" class="success-tick no-background"
                          alt="thumb-up icon" />
                      </div>
                      <div class="mobile-design-show">
                        <img *ngIf="a.icon" src="./assets/pathfinder/holland/{{a?.icon}}.svg" class="selectable-icon"
                          alt="{{a?.displayName}}" />
                        <img *ngIf="a.icon==cardsSelected[0].icon || a.icon==cardsSelected[1].icon"
                          src="{{assetsURL}}thumbs-up-outline-grey.svg" class="success-tick no-background"
                          alt="thumb-up icon" />
                      </div>
                    </div>
                    <!-- </button> -->
                    <p m-0 class="text-center" text-color="primary">
                      {{a?.displayName}}
                    </p>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="70%" fxFlex.xs="100%" class="pathfinder-cards" flex h-center>
          <div class="two-cards w-100" flex v-center>
            <div class="desktop-design-show w-100">
              <p class="top-two-title" flex v-center h-center text-color="primary" *ngIf="cardsSelected[1].icon!=''">
                <img src="{{assetsURL}}thumbs-up-outline-grey.svg" mr-4 alt="thumb-up icon" />
                {{pathFinderTopSkills?.dictionary?.yourTopTypes}}
              </p>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <div fxFlex="45%" flex-basis *ngFor="let data of cardsSelected;index as i">
                  <mat-card class="card top-two-cards">
                    <mat-card-title class="card-header" background-color="primary" *ngIf="data.icon!=''">
                      {{data?.displayName}}</mat-card-title>
                    <mat-card-title class="card-header" background-color="primary" *ngIf="data.name==''">
                      {{keywords?.dictionary?.typeText}}
                      {{i+1}}</mat-card-title>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <img *ngIf="data.icon" src="./assets/pathfinder/holland/{{data?.icon}}.svg"
                        alt="{{data?.name}}" />
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="cardsSelected[1].icon!=''" class="desktop-design-show">
        <button mat-fab color="accent" class="next-level light-red" (click)="clickNext()" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>