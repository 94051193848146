
/**Import angular core packages */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { select, Store } from '@ngrx/store';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { Next } from '../../../shared/common-animations/CT_animations';
import { AttributeDataState, AudioState, PageDataState, TrekkerAssessmentState, TrekkerTopSkillsState } from '../../../state-management/state/main-state';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-careertrekker-assessment',
  templateUrl: './careertrekker-layout.html',
  animations: [Next],
})
export class CareerTrekkerAssessmentComponent implements OnInit, OnDestroy {
  languageKey = 'en';
  /***** Start of Varibles Decleration *****/
  //The completed variable to set to true, to turn on the flip animation of the button
  completed: boolean;
  name: any;
  cardsData = [];
  topSkill = [];
  ImpSkill = [];
  quesSkill = [];
  startInx = 0;
  lang;
  inxlength = 0;
  nextIcon = '';
  theme_name;
  goodChoice = false;
  improveChoice = false;
  audio: any;
  playPromise: any;
  headerAudioState = 'stoped';
  audionotfound = false;
  assetsUrl: string;
  trekkerHeading: any;
  compActive = true;
  audioType: string;
  quesAudioState = 'stoped';

  trekker_volume_btn: string = 'trekker-volume-btn';

  /***** End of Varibles Decleration *****/

  /***** Start of Constructor *****/
  constructor(
    private _location: Location,
    private store: Store<AttributeDataState>,
    private store1: Store<PageDataState>,
    private trekkerAssessmentStore: Store<TrekkerAssessmentState>,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    private trekkerTopSkillsStore: Store<TrekkerTopSkillsState>,
    private router: Router,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private oninitpopupservice: OnLoadPopUpService,
    private eventService: EventdispatchService,
    private utils: Utilities,
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    private titleService: Title
  ) {
    this.assetsUrl = assetsMediaUrl;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[2]);
    this.store1.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'CAREER_TREKKER', pageCode: 'TREKKER_ASSESS' },
    });
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    sessionStorage.setItem('isModalActive', 'false'); //initialzing modalactive as false by default
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (
          sessionStorage.getItem('pathurl') == '/app/careertrekker/assessment'
        ) {
          if (e.type === 'languageChanged') {
            this.languageKey = this.cardsData[0].language;
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            const evnt = document.createEvent('CustomEvent');
            this.eventService.dispatchAudioStop(evnt);
            this.unsavedChanges();
          }
        }
      });
    if (this.lang === '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          // to check whether the modal is active or not
          this.headerAudioState = result.audioState;
          this.quesAudioState = result.audioState;
          this.audionotfound = result.audionotfound;
          /*If two audio icons are in same page when clicking one audio icon another audio icon must be in rest position(i.e. showing the 'volume_up icon')
				below code is for differentiating audio icons in both the conditions(i.e. playing instructional text audio and questions audio)*/
          if (this.audioType == 'headerAudio') {
            this.quesAudioState = 'stoped';
          } else if (this.audioType == 'quesAudio') {
            this.headerAudioState = 'stoped';
          }
        }
      });
  }

  audioClick(text, data, audioType) {
    this.audioType = audioType;
    this.audioService.audioFunction(text, data);
  }
  /***** End of Constructor *****/
  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('TREKKER_ASSESS');
      }.bind(this)
    );
  }
  ngAfterViewInit() {
    this.infopopup();
  }
  stopAudio() {
    //to stop current playing audio
    this.audioType = 'stoped';
    this.audionotfound = false;
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }
  /***** Start of ngOnInit *****/
  ngOnInit() {
    // Calling pageText after reloading the page
    this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
    this.cardsData = [];
    this.topSkill = [];
    this.ImpSkill = [];
    this.quesSkill = [];
    let intialVal = true; // Based on this variable val poping the elements from quesSkill,topSkill,ImpSkill arrays.
    this.store1
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.trekkerHeading = data.find(
            (obj) => obj.pageCode === 'TREKKER_ASSESS'
          );
        }
      });

    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        if (!this.name) {
          this.name = params['skillname'];
        }
        this.store
          .select('attribute')
          .pipe(takeWhile(() => this.compActive))
          .subscribe((res) => {
            if (res) {
              this.cardsData = res.attribute;
              if (this.languageKey != this.cardsData[0].language) {
                this.languageKey = this.cardsData[0].language;
                this.dialogService.hideLoading();
              }
            }
          });
      });
    this.trekkerAssessmentStore
      .select('trekkerAssessmentques')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((quesresp) => {
        if (quesresp) {
          this.topSkill = quesresp['trekkerAssessmentques'].topSkill;
          this.ImpSkill = quesresp['trekkerAssessmentques'].ImpSkill;
          this.quesSkill = quesresp['trekkerAssessmentques'].quesSkill;
          this.name = quesresp['trekkerAssessmentques'].name;
          if (
            intialVal == true &&
            this.quesSkill.length === this.cardsData.length
          ) {
            if (
              this.topSkill[this.topSkill.length - 1] ==
              this.quesSkill[this.quesSkill.length - 1]
            ) {
              this.topSkill.pop();
              this.improveChoice = false;
              this.goodChoice = true;
            } else if (
              this.ImpSkill[this.ImpSkill.length - 1] ==
              this.quesSkill[this.quesSkill.length - 1]
            ) {
              this.ImpSkill.pop();
              this.improveChoice = true;
              this.goodChoice = false;
            }
            this.nextIcon = 'yes';
            this.inxlength = this.quesSkill.length - 1;
            this.startInx = this.quesSkill.length - 1;
            this.quesSkill.pop();
          }
          intialVal = false;
        }
      });
    this.setTrekkerAssessmentTitle();
  }
  /***** End of ngOnInit *****/

  /***** Start of NextQuestion method *****/
  nextQuestion(skill, inx) {
    // debugger
    this.name = skill;
    this.nextIcon = '';
    this.startInx++;
    this.audionotfound = false;
    this.quesAudioState = 'stoped';
    this.headerAudioState = 'stoped';
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    if (
      this.topSkill.indexOf(this.name) < 0 &&
      this.topSkill.indexOf(this.name) < 0 &&
      this.quesSkill.indexOf(this.name) < 0
    ) {
      if (this.goodChoice == true) {
        this.improveChoice = false;
        this.topSkill.push(skill);
      } else if (this.improveChoice == true) {
        this.goodChoice = false;
        this.ImpSkill.push(skill);
      }
      this.quesSkill.push(skill); // Here storing all question names(skill names)
    }
    this.trekkerAssessmentStore.dispatch({
      type: 'TREKKER_ASSESSMENT_VAL',
      payload: {
        trekkerAssessmentques: {
          topSkill: this.topSkill,
          ImpSkill: this.ImpSkill,
          quesSkill: this.quesSkill,
        },
      },
    });
    this.goodChoice = false;
    this.improveChoice = false;
    if (this.inxlength == this.cardsData.length - 1) {
      sessionStorage.removeItem('goodSkill');
      sessionStorage.removeItem('ImpSkill');
      sessionStorage.setItem('goodSkill', JSON.stringify(this.topSkill));
      sessionStorage.setItem('ImpSkill', JSON.stringify(this.ImpSkill));
      this.trekkerTopSkillsStore.dispatch({
        type: 'TREKKER_TOPSKILLS_VAL',
        payload: {
          trekkerCardsSelected: {
            selectedLike: undefined,
            selectedUnlike: undefined,
            cards: undefined,
          },
        },
      });
      this.router.navigateByUrl('/app/careertrekker/topskills');
    } else {
      this.inxlength++;
    }
  }
  /***** Start of NextQuestion method *****/

  /***** End of addskills method *****/
  addSkills(evnt) {
    this.completed = !this.completed;
    this.nextIcon = 'yes';
  }
  /***** Start of addskills method *****/

  /***** End of unsavedChanges method *****/
  unsavedChanges() {
    this.quesAudioState = 'stoped';
    this.headerAudioState = 'stoped';
    let lastQues; //this variable is for storing pevious question name(skill name)
    if (this.startInx == 0) {
      this.utils.backRoute();
    } else if (
      (this.startInx != 0 && this.topSkill.length > 0) ||
      this.ImpSkill.length > 0
    ) {
      lastQues = this.quesSkill.pop();
      if (this.topSkill[this.topSkill.length - 1] == lastQues) {
        this.topSkill.pop();
        this.improveChoice = false;
        this.goodChoice = true;
      } else {
        this.ImpSkill.pop();
        this.improveChoice = true;
        this.goodChoice = false;
      }
      this.completed = !this.completed;
      this.nextIcon = 'yes';
      this.inxlength--;
      this.startInx--;
    }
  }
  /***** End of unsavedChanges method *****/

  /***** Start of ngOnDestroy method *****/
  ngOnDestroy() {
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    this.compActive = false;
  }
  /***** Start of ngOnDestroy method *****/

  private setTrekkerAssessmentTitle() {
    this.titleService.setTitle('Trekker Assessment - CareerTrek');
  }
}
