<div class="{{theme_name}}">
    <!-- <ng4-loading-spinner [timeout]="120000"> </ng4-loading-spinner> -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.5)" size = "medium" color = "#fff" type = "ball-scale-multiple" ></ngx-spinner>
    <router-outlet></router-outlet>
</div>
<!-- <div id="container">
    <div ng2-carouselamos class="slides-wrapper" [items]="items" [width]="110" [$prev]="prev" [$next]="next" [$item]="item">
    </div>


    <ng-template #prev>
        <div id="left"> left</div>
    </ng-template>
    <ng-template #next>
        <div id="right"> right</div>
    </ng-template>
    <ng-template #item let-item let-i="index">

        <div class="items">
            <img src="{{ item.name }}">
        </div>

    </ng-template>

</div> -->
