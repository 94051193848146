<app-static-header></app-static-header>
<div class="Career-trek-content">
  <div class="container custom-occ-header profile-page-header text-right">
    <!-- Below HTML code commented for Career Creator assignment pages, should not have a delete button

    <button class="common-occ-header-button delete-occ-header-btn mat-button" (click)="deleteEntireOcc()">
      <span class="mat-button-wrapper">
        <span font-bold=""> {{keyWords.dictionary.delete}}</span>
        <img src="assets/Svg/delete.svg" alt="delete-image" />
      </span>
    </button> -->
    <div class="landing-page-heading text-center" *ngIf="(module=='explorer') || (module=='navigator')
      || (module == 'careertrekker')">
      <h4 *ngIf="(!savedEntireOcc); else headingElseSelected">
        {{keyWords?.subModule[module].header}}
        <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
          (click)="audioClick('inside',keyWords?.subModule[module])"></app-audio-button>
        <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
         (click)="audioClick('inside',keyWords?.subModule[module])">
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
         <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
         <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
   </button> -->
      </h4>
    </div>

    <ng-template #headingElseSelected>
      <h4>
        {{instructionText?.subModule[module]?.header}}
        <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
          (click)="audioClick('inside',instructionText?.subModule[module])"></app-audio-button>
        <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
          [disabled]="audionotfound==true && audioState=='warning'" 
           (click)="audioClick('inside',instructionText.subModule[module])">
           <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
           <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
           <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
           <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
     </button> -->
      </h4>
    </ng-template>

    <div class="landing-page-heading text-center" *ngIf="(module=='pathfinder')">
      <h4 *ngIf="(cardsSelected[0].name!='' && cardsSelected[1].name!=''); else noOrOneSelected">
        {{buttonsText?.dictionary.top2TypesSelected}}
        <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
          (click)="audioClick('inside',instructionText.subModule.pathfinder)"></app-audio-button>
        <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
         (click)="audioClick('inside',instructionText.subModule.pathfinder)">
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
         <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
         <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
   </button> -->
      </h4>
      <ng-template #noOrOneSelected>
        <h4>
          {{keyWords?.subModule[module].header}}
          <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
            (click)="audioClick('inside',keyWords?.subModule[module])"></app-audio-button>
          <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
         (click)="audioClick('inside',keyWords?.subModule[module])">
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
         <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
         <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
         <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
   </button> -->
        </h4>
      </ng-template>
    </div>

    <div class="landing-page-heading text-center" *ngIf="module=='scout'">

      <h4 *ngIf="(cardsSelected[0].name!='' && cardsSelected[1].name!=''); else noOrOneSelected">
        {{buttonsText?.dictionary.top2TypesSelected}}
        <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
          (click)="audioClick('inside',instructionText.subModule.scout)"></app-audio-button>
      </h4>

      <ng-template #noOrOneSelected>
        <h4>
          {{keyWords?.subModule[module].header}}
          <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
            (click)="audioClick('inside',keyWords?.subModule[module])"></app-audio-button>
        </h4>
      </ng-template>


      <!-- <h4>Select a career group for this career.</h4> -->
    </div>
  </div>
  <div class="container">
    <div class="save-own-occ-parent content-box">
      <mat-horizontal-stepper>
        <mat-step *ngIf="resp1 && (module=='explorer' || module == 'careertrekker') && showMatStepper === 'stepper2'">
          <div class="save-occ-explorer" [ngClass]="module === 'explorer'? 'explorer-occ' : 'trekker-occ'">
            <div class="explorer-occuptation explorer-task">
              <div flex v-center>
                <div class="w-100 text-center occupation-heading">
                  <h1 m-0>{{name}}</h1>
                  <p m-0 class="text-center">{{describeThoughts}}</p>
                </div>
                <!-- <button mat-fab class="orange-arrow" matStepperPrevious>
                      <i class="material-icons">mode_edit</i>
                    </button> -->
              </div>
              <mat-card class="card questions-section">
                <div class="image-content" flex v-center h-center>
                  <!-- Here ngstyle added for canvas width and height dynamically -->
                  <div class="canves-block" *ngIf="imgageSrc!=''"
                    [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex'}">
                    <img class="saved-canvas-img" src="{{imgageSrc}}" />
                  </div>
                </div>

                <div class="task-section text-center">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
                    ngStyle.xs="padding: 0 15px;align-items: normal;" *ngIf="savedEntireOcc==false">
                    <div fxFlex="55%" flex-basis flex v-center>
                      <div class="icon-sec question-block w-100" flex v-center justify-between
                        *ngIf="resp1[startInx]!=undefined">
                        <div class="w-100" flex v-center>
                          <div class="circle-icon" flex v-center h-center>
                            <img *ngIf="module == 'careertrekker'" class="w-100"
                              src="{{assetsUrl}}{{resp1[startInx].icon}}.svg" />
                            <img *ngIf="module == 'explorer'" class="w-100"
                              src="assets/Svg/{{resp1[startInx].icon}}.svg" />
                          </div>
                          <div class="
                              w-100
                              question-block-text
                              desktop-design-show
                            " *ngIf="resp1[startInx]!=undefined">
                            {{resp1[startInx].title | titlecase}}
                          </div>
                          <div class="mobile-design-show question-block-text" *ngIf="resp1[startInx]!=undefined">
                            {{resp1[startInx].title | titlecase}}
                          </div>
                        </div>
                        <div [@pointerstate]="textAniInx">
                          <div fxFlex="49px" flex v-center ngStyle.xs="display:none;"
                            class="question-block-circle-arrow" *ngIf="high==true||medium==true||low==true"
                            (click)="nextAttribute()">
                            <button mat-fab class="" [@flipAnimation]="completed">
                              <i class="material-icons">arrow_back</i>
                            </button>
                            <img class="pointer-image animate" src="./assets/Svg/hand-pointer-5-1.svg" />
                          </div>
                          <div class="
                              mobile-design-show
                              question-block-circle-arrow
                            " *ngIf="high==true||medium==true||low==true" (click)="nextAttribute()">
                            <button [@flipAnimation]="completed" mat-fab color="primary" class=""
                              [ngStyle]="{'position':'relative','left':'0'}">
                              <i class="material-icons">arrow_back</i>
                            </button>
                            <img class="pointer-image animate" src="./assets/Svg/hand-pointer-5-1.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="levels-section" *ngIf="resp1[startInx]!=undefined" flex fxFlex="40%" flex-basis v-center>
                      <div class="icon-sec status-block">
                        <div [@pointerstate]="optAniInx" class="desktop-design-show">
                          <img *ngIf="!high && !medium && !low" class="pointer-image animate"
                            src="./assets/Svg/hand-pointer-5-1.svg" />
                        </div>
                        <div [@pointerstate]="optAniInx" class="mobile-design-show">
                          <img *ngIf="!high && !medium && !low" class="pointer-image animate"
                            src="./assets/Svg/hand-pointer-5-1.svg" />
                        </div>
                        <ul class="list-style-none status-block" flex v-center h-center m-0 p-0>
                          <li>
                            <button mat-fab class="green" flex v-center h-center
                              (click)="weightSelection('high',resp1[startInx]);high=true;low=false;medium=false">
                              {{buttonsText?.dictionary.high}}
                              <i class="material-icons pointer-icon done" *ngIf="high==true">done</i>
                              <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                            </button>
                          </li>
                          <li>
                            <button mat-fab style="cursor: default" class="orange"
                              (click)="weightSelection('medium',resp1[startInx]);high=false;low=false;medium=true">
                              {{buttonsText?.dictionary.medium}}
                              <i class="material-icons pointer-icon done" *ngIf="medium==true">done</i>
                              <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                            </button>
                          </li>
                          <li>
                            <button mat-fab class="purple"
                              (click)="weightSelection('low',resp1[startInx]);high=false;low=true;medium=false">
                              {{buttonsText?.dictionary.low}}
                              <i class="material-icons pointer-icon done" *ngIf="low==true">done</i>
                              <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>

                  <div fxLayout="row" fxFlexFill class="common-row-sub rating-cards" fxLayoutGap="10px"
                    fxLayoutGap.sm="5px" fxLayoutGap.xs="5px" ngStyle.xs="padding: 0 5px;">
                    <div fxFlex="" fxFlex.xs="32%"
                      *ngIf="(savedEntireOcc==true && highData.length!=0 ) || savedEntireOcc==false"
                      [ngClass]="savedEntireOcc==true ?'desktop-design-show':'' ">
                      <mat-card class="card h-100 card-high-data desktop-design-show">
                        <mat-card-title class="card-header" font-bold flex v-center h-center>
                          {{buttonsText?.dictionary.high}}
                        </mat-card-title>
                        <ul class="list-style-none icon-sec" ngStyle.xs="min-height:30px; height:auto" flex v-center
                          h-center p-0 fxLayoutWrap="wrap">
                          <li class="status-circle" *ngFor="let high of highData" flex h-center v-center>
                            <div class="
                                desktop-design-show
                                custom-circle-img-width
                              ">
                              <img *ngIf="module == 'careertrekker'" class="trekker-image w-100"
                                src="{{assetsUrl}}{{high.icon}}.svg" matTooltipClass="custom-tooltip"
                                matTooltip="{{high.title | titlecase}}" />
                              <img *ngIf="module == 'explorer'" class="w-100" src="{{assetsUrl}}{{high.icon}}.svg"
                                matTooltipClass="custom-tooltip" matTooltip="{{high.title | titlecase}}" />
                            </div>
                            <div class="mobile-design-show">
                              <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                              </mat-checkbox>
                              <!-- <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox> -->
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>
                    <div fxFlex="" fxFlex.xs="32%"
                      *ngIf="(savedEntireOcc==true && mediumData.length!=0 ) || savedEntireOcc==false"
                      [ngClass]="savedEntireOcc==true ?'desktop-design-show':'' ">
                      <mat-card class="card h-100 card-medium-data desktop-design-show">
                        <mat-card-title class="card-header" font-bold flex v-center h-center>
                          {{buttonsText?.dictionary.medium}}</mat-card-title>
                        <ul class="list-style-none icon-sec" ngStyle.xs="min-height:30px; height:auto" flex v-center
                          h-center p-0 fxLayoutWrap="wrap">
                          <li class="status-circle" *ngFor="let medium of mediumData" flex h-center v-center>
                            <div class="
                                desktop-design-show
                                custom-circle-img-width
                              ">
                              <img *ngIf="module == 'careertrekker'" class="trekker-image w-100"
                                src="{{assetsUrl}}{{medium.icon}}.svg" matTooltipClass="custom-tooltip"
                                matTooltip="{{medium.title | titlecase}}" />
                              <img *ngIf="module == 'explorer'" class="w-100" src="{{assetsUrl}}{{medium.icon}}.svg"
                                matTooltipClass="custom-tooltip" matTooltip="{{medium.title | titlecase}}" />
                            </div>
                            <div class="mobile-design-show">
                              <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                              </mat-checkbox>
                              <!-- <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox> -->
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>
                    <div fxFlex="" fxFlex.xs="32%"
                      *ngIf="(savedEntireOcc==true && lowData.length!=0 ) || savedEntireOcc==false"
                      [ngClass]="savedEntireOcc==true ?'desktop-design-show':'' ">
                      <mat-card class="card h-100 card-low-data desktop-design-show">
                        <mat-card-title class="card-header" font-bold flex v-center h-center>
                          {{buttonsText?.dictionary.low}}
                        </mat-card-title>
                        <ul class="list-style-none icon-sec" ngStyle.xs="min-height:30px; height:auto" flex v-center
                          h-center p-0 fxLayoutWrap="wrap">
                          <li class="status-circle" *ngFor="let low of lowData" flex h-center v-center>
                            <div class="
                                desktop-design-show
                                custom-circle-img-width
                              ">
                              <img *ngIf="module == 'careertrekker'" class="trekker-image w-100"
                                src="{{assetsUrl}}{{low.icon}}.svg" matTooltipClass="custom-tooltip"
                                matTooltip="{{low.title | titlecase}}" />
                              <img *ngIf="module == 'explorer'" class="w-100" src="{{assetsUrl}}{{low.icon}}.svg"
                                matTooltipClass="custom-tooltip" matTooltip="{{low.title | titlecase}}" />
                            </div>
                            <div class="mobile-design-show">
                              <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                              </mat-checkbox>
                              <!-- <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox> -->
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="mobile-design-show" *ngIf="savedEntireOcc==true">
                  <mat-card class="card tabs-card w-100">
                    <mat-card-content class="card-body text-center">
                      <div class="w-100">
                        <mat-tab-group>
                          <mat-tab label="Tab 1"
                            *ngIf="(savedEntireOcc && highData.length!=0 ) || !savedEntireOcc">
                            <ng-template mat-tab-label h-center flex>
                              <div class="tab-button green" flex v-center h-center>
                                <span>{{buttonsText?.dictionary.high}}</span>
                              </div>
                            </ng-template>

                            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                              fxLayoutGap.xs="5px" [ngClass]="((highData.length!=0)&&(mediumData.length!=0)&&(lowData.length!=0))
                                        ||((highData.length!=0)&&(mediumData.length!=0))
                                        ||((highData.length!=0)&&(lowData.length!=0))? 'hightab-class':''">
                              <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                <div class="circle-img green trekker-icons" *ngFor="let high of highData">
                                  <img src="{{assetsUrl}}{{high.icon}}.svg" matTooltipClass="custom-tooltip"
                                    matTooltip="{{high.title | titlecase}}" />
                                </div>
                              </div>
                            </div>
                          </mat-tab>
                          <mat-tab label="Tab 2"
                            *ngIf="(savedEntireOcc && mediumData.length!=0 ) || !savedEntireOcc">
                            <ng-template mat-tab-label h-center flex>
                              <div class="tab-button red" flex v-center h-center>
                                <span>{{buttonsText?.dictionary.medium}}</span>
                              </div>
                            </ng-template>

                            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                              fxLayoutGap.xs="5px" [ngClass]="((highData.length!=0)&&(mediumData.length!=0)&&(lowData.length!=0))? 'justify-center':
                                ((highData.length!=0)&&(mediumData.length!=0))? 'lowtab-class':'hightab-class'">
                              <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                <div class="circle-img orange trekker-icons" *ngFor="let medium of mediumData">
                                  <img src="{{assetsUrl}}{{medium.icon}}.svg" matTooltipClass="custom-tooltip"
                                    matTooltip="{{medium.title | titlecase}}" />
                                </div>
                              </div>
                            </div>
                          </mat-tab>
                          <mat-tab label="Tab 3"
                            *ngIf="(savedEntireOcc && lowData.length!=0 ) || !savedEntireOcc">
                            <ng-template mat-tab-label h-center flex>
                              <div class="tab-button purple" flex v-center h-center>
                                <span>{{buttonsText?.dictionary.low}}</span>
                              </div>
                            </ng-template>

                            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                              fxLayoutGap.xs="5px" [ngClass]="((highData.length!=0)&&(mediumData.length!=0)&&(lowData.length!=0))
                           ||((lowData.length!=0)&&(mediumData.length!=0))
                           ||((highData.length!=0)&&(lowData.length!=0))? 'lowtab-class':''" justify-end>
                              <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                <div class="circle-img purple trekker-icons" *ngFor="let low of lowData">
                                  <img src="{{assetsUrl}}{{low.icon}}.svg" matTooltipClass="custom-tooltip"
                                    matTooltip="{{low.title | titlecase}}" />
                                </div>
                              </div>
                            </div>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </mat-card>
            </div>

            <div class="" *ngIf="savedEntireOcc!=false" (click)="dataUpload()">
              <button class="next-level mat-fab mat-accent" [@flipAnimation]="completed" color="primary" mat-fab=""
                ng-reflect-color="accent">
                <span class="mat-button-wrapper"><i class="material-icons">arrow_forward</i></span>
                <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                <div class="mat-button-focus-overlay"></div>
              </button>
            </div>
          </div>
        </mat-step>

        <!-- <button mat-raised-button matStepperPrevious>Back</button> -->
        <!-- <button mat-button matStepperNext>Next</button> -->
        <mat-step *ngIf="module=='pathfinder' && showMatStepper === 'stepper3'">
          <div class="save-occ-pathfinder">
            <div flex v-center>
              <div class="w-100 text-center occupation-heading">
                <h1 m-0>{{name}}</h1>
                <p m-0 class="text-center">{{describeThoughts}}</p>
              </div>
              <!-- <button mat-fab class="orange-arrow" matStepperPrevious>
                              <i class="material-icons">mode_edit</i>
                            </button> -->
            </div>
            <div class="explorer-occuptation explorer-task">
              <mat-card class="card questions-section">
                <div class="image-content" flex v-center h-center>
                  <!-- Here ngstyle added for canvas width and height dynamically -->
                  <div class="canves-block" *ngIf="imgageSrc!=''"
                    [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex'}">
                    <img class="saved-canvas-img" src="{{imgageSrc}}" alt="createownocc" />
                  </div>
                </div>
                <div class="task-section text-center">
                  <div class="top-skills-layout">
                    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
                      <div fxFlex="" fxFlex.sm="40%" flex-basis fxFlex.xs="100%" flex v-center h-center
                        class="pathfinder-cards" *ngIf="!(cardsSelected[0].name!='' && cardsSelected[1].name!='')">
                        <div class="select-cards w-100" p-0>
                          <div class="mobile-design-show">
                            <!-- <h1 class="text-center" text-color="primary" ngStyle.xs="font-size: 1.2rem;">{{name}}</h1> -->
                          </div>
                          <!-- <h4 class="text-center" ngStyle.xs="color:#545454;">
                                        {{buttonsText?.dictionary.selectTop2Types}}</h4> -->
                          <div fxLayout="row" fxLayoutWrap="wrap">
                            <!-- [ngClass]="(cardsSelected[0].name!=''&& cardsSelected[1].name!='')?'desktop-design-show':''" -->
                            <div fxFlex="33%" fxFlex.xs="49%" class="pathfinder-cards" *ngFor="let item of resp1">
                              <mat-card class="card card-border" matTooltip="{{item.title}}"
                                matTooltipClass="custom-tooltip" tabindex="0" flex v-center h-center pf-theme-border-xs
                                (click)="pathfinderCardSelect(item)">
                                <div>
                                  <div flex v-center h-center>
                                    <div>
                                      <img src="./assets/pathfinder/holland/{{item.icon}}.svg" class="w-50" />
                                      <i class="material-icons success-tick"
                                        *ngIf="cardsSelected[0].icon==item.icon||cardsSelected[1].icon==item.icon">done</i>
                                    </div>
                                  </div>
                                  <p m-0 class="text-center" text-color="primary">
                                    {{item.displayName}}
                                  </p>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div fxFlex="" flex-basis fxFlex.xs="100%" class="pathfinder-cards"
                        *ngIf="cardsSelected[0].name!='' && cardsSelected[1].name!=''">
                        <div class="two-cards w-100">
                          <div class="desktop-design-show">
                            <!-- <p text-color="primary">top 2 types</p> -->
                            <!-- <h4 *ngIf="(cardsSelected[0].name=='' || cardsSelected[1].name=='')">{{buttonsText?.dictionary.top2Types}}</h4>	 -->
                            <!-- <h4 *ngIf="(cardsSelected[0].name!='' && cardsSelected[1].name!='')">{{buttonsText?.dictionary.top2TypesSelected}}</h4>	 -->

                            <div fxLayout.sm="column" fxLayout.sm="row" fxLayoutAlign="center">
                              <div fxFlex="50%" *ngFor="let card of cardsSelected; index as i">
                                <mat-card class="card top-two-cards">
                                  <mat-card-title class="card-header" *ngIf="card.name!=''" background-color="primary">
                                    {{card.displayName}}</mat-card-title>
                                  <mat-card-title class="card-header" *ngIf="card.name==''" background-color="primary">
                                    {{buttonsText?.dictionary.skill}}
                                    {{i+1}}</mat-card-title>
                                  <mat-card-content class="card-body text-center" flex v-center h-center>
                                    <img *ngIf="card.icon!=''" src="./assets/pathfinder/holland/{{card.icon}}.svg" />
                                  </mat-card-content>
                                </mat-card>
                              </div>
                            </div>
                          </div>

                          <div class="mobile-design-show mobile-selected-cards"
                            *ngIf="!(cardsSelected[0].name!=''&& cardsSelected[1].name!='')">
                            <mat-card class="card w-100">
                              <mat-card-title class="card-header">{{buttonsText?.dictionary.top2}}</mat-card-title>
                              <mat-card-content class="card-body text-center" flex v-center>
                                <div fxLayout="row" fxLayoutAlign="center" class="w-100">
                                  <div fxFlex="48%" *ngFor="let card of cardsSelected">
                                    <mat-card class="card selected-card" v-center h-center>
                                      <p m-0 text-color="primary" *ngIf="card.name!=''">
                                        {{card.displayName}}
                                      </p>
                                    </mat-card>
                                  </div>
                                </div>
                              </mat-card-content>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-design-show" *ngIf="cardsSelected[0].name!='' && cardsSelected[1].name!=''">
                    <!-- <div class="image-content"> -->
                    <!-- <img *ngIf="imgageSrc" src="{{imgageSrc}}" alt="createownOcc" class="w-100"> -->
                    <!-- <img src="assets/images/nodata-found.png" alt="save-own-new-occ" class="w-100">-->
                    <!-- </div> -->
                    <div class="text-center">
                      <!-- <div class="mobile-bg">
                                    <div class="save-occ-btn">
                                      <button mat-fab class="orange-arrow" (click)="dataUpload()">
                                        <i class="material-icons">save</i>
                                      </button>
                                    </div>
                                    <h2 m-0 ngStyle.xs="color: #f9a825">{{name}}</h2>
                                    <p m-0 class="text-center">{{describeThoughts}}</p>
                                  </div> -->
                      <div fxLayout.sm="column" fxLayout.sm="row" fxLayoutAlign="center" fxLayoutGap="10px"
                        ngStyle.xs="padding:10px;">
                        <div fxFlex="50%" *ngFor="let card of cardsSelected">
                          <mat-card class="card top-two-cards">
                            <mat-card-title class="card-header" background-color="primary">{{card.displayName}}
                            </mat-card-title>
                            <mat-card-content class="card-body text-center" flex v-center h-center>
                              <img src="./assets/pathfinder/holland/{{card.icon}}.svg" class="w-50" />
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
              <div (click)="dataUpload()" *ngIf="cardsSelected[0].name!='' && cardsSelected[1].name!=''">
                <!-- <button mat-raised-button float-right color="primary">{{buttonsText?.dictionary.save}}</button> -->
                <button class="next-level mat-fab mat-accent" color="primary" mat-fab="" ng-reflect-color="primary">
                  <span class="mat-button-wrapper"><i class="material-icons">arrow_forward</i></span>
                  <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
              </div>
              <div (click)="dataUpload()" *ngIf="(cardsSelected[0].name!='' && cardsSelected[1].name!='')">
                <!-- <button mat-raised-button float-right color="primary">{{buttonsText?.dictionary.save}}</button> -->
                <button class="next-level mat-fab mat-accent" color="primary" mat-fab="" ng-reflect-color="primary">
                  <span class="mat-button-wrapper"><i class="material-icons">arrow_forward</i></span>
                  <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
              </div>
            </div>
          </div>
          <!-- <button mat-button matStepperPrevious>Back</button> -->
          <!-- <button mat-button matStepperNext>Next</button> -->
        </mat-step>
        <!-- Scout module career creator assignment page -->
        <mat-step *ngIf="module=='scout' && showMatStepper === 'stepper4'">
          <div class="save-occ-scout">
            <div class="explorer-occuptation explorer-task">
              <div flex v-center>
                <div class="w-100 text-center occupation-heading">
                  <h1 m-0>{{name}}</h1>
                  <p m-0 class="text-center">
                    {{describeThoughts}}
                  </p>
                </div>
              </div>
              <mat-card class="card questions-section">
                <div class="image-content" flex v-center h-center>
                  <!-- Here ngstyle added for canvas width and height dynamically -->
                  <div class="canves-block" *ngIf="imgageSrc!=''"
                    [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex'}">
                    <img class="saved-canvas-img" src="{{imgageSrc}}" />
                  </div>
                </div>
              </mat-card>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap="wrap" fxLayoutAlign="center"
                class="task-section text-center">

                <div fxFlex="30%" pointer *ngFor="let category of categories">
                  <mat-card class="card career-cards" (click)="setScoutCareer(category)">
                    <h4>{{category.title}}</h4>
                    <img src="{{assetsUrl}}{{category.icon}}.svg" alt="">
                    <div class="save-career" *ngIf="categoryCard[0].icon==category.icon">
                      <img src="/assets/images/cardclick.png" alt="">
                    </div>
                  </mat-card>
                </div>


                <!-- <div fxFlex="30%" pointer>
                  <mat-card class="card career-cards" (click)="setScoutCareer('People')">
                    <h4>People</h4>
                    <img src="{{assetsUrl}}EXPLORER_PEOPLE.svg" alt="">
                    <div class="save-career" *ngIf="peopleTick">
                      <img src="/assets/images/cardclick.png" alt="">
                    </div>
                  </mat-card>
                </div> -->
                <!-- <div fxFlex="30%" pointer>
                  <mat-card class="card career-cards" (click)="setScoutCareer('Ideas')">
                    <h4>Ideas</h4>
                    <img class="ideas-img" src="{{assetsUrl}}EXPLORER_IDEAS.svg" alt="">
                    <div class="save-career" *ngIf="ideasTick">
                      <img src="/assets/images/cardclick.png" alt="">
                    </div>
                  </mat-card>
                </div> -->
                <!-- <div fxFlex="30%" pointer>
                  <mat-card class="card career-cards" (click)="setScoutCareer('Things')">
                    <h4>Things</h4>
                    <img src="{{assetsUrl}}EXPLORER_THINGS.svg" alt="">
                    <div class="save-career" *ngIf="thingsTick">
                      <img src="/assets/images/cardclick.png" alt="">
                    </div>
                  </mat-card>
                </div> -->
              </div>
            </div>
            <div class="" *ngIf="thingsTick || ideasTick || peopleTick" (click)="dataUpload()">
              <button class="next-level mat-fab mat-accent" [@flipAnimation]="completed" color="primary" mat-fab=""
                ng-reflect-color="accent">
                <span class="mat-button-wrapper"><i class="material-icons">arrow_forward</i></span>
                <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                <div class="mat-button-focus-overlay"></div>
              </button>
            </div>
          </div>
        </mat-step>
        <!-- Navigator module career creator assignment page -->
        <mat-step *ngIf="module=='navigator' && showMatStepper === 'stepper5'">
          <div class="save-occ-navigator">
            <div class="explorer-occuptation explorer-task">
              <div flex v-center>
                <div class="w-100 text-center occupation-heading">
                  <h1 m-0>{{name}}</h1>
                  <p m-0 class="text-center">{{describeThoughts}}</p>
                </div>
              </div>
              <mat-card class="card questions-section">
                <div class="image-content" flex v-center h-center>
                  <!-- Here ngstyle added for canvas width and height dynamically -->
                  <div class="canves-block" *ngIf="imgageSrc!=''"
                    [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex'}">
                    <img class="saved-canvas-img" src="{{imgageSrc}}">
                  </div>
                </div>
              </mat-card>
              <div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="center" class="task-section text-center">
                <div fxFlex.gt-sm="30%" fxFlex="49%" pointer *ngFor="let item of resp1">
                  <mat-card class="card career-cards" (click)="navigatorCardselection(item)">
                    <img src="./assets/Svg/{{item.icon}}.svg" alt="">
                    <h4>{{item.title}}</h4>
                    <div class="save-career" *ngIf="cardsSelected[0].icon==item.icon||cardsSelected[1].icon==item.icon">
                      <img src="/assets/images/cardclick.png" alt="">
                    </div>
                  </mat-card>
                </div>
                <!-- <div fxFlex.gt-sm="30%" fxFlex="49%" pointer>
                  <mat-card class="card career-cards">
                    <img src="./assets/Svg/achievement.svg" alt="">
                    <h4>Achievement</h4>                                    
                  </mat-card>
                </div>
                <div fxFlex.gt-sm="30%" fxFlex="49%" pointer>
                  <mat-card class="card career-cards">
                    <img src="./assets/Svg/independence.svg" alt="">
                    <h4>Independence</h4>                                    
                  </mat-card>
                </div>
                <div fxFlex.gt-sm="30%" fxFlex="49%" pointer>
                  <mat-card class="card career-cards">
                    <img src="./assets/Svg/working_conditions.svg" alt="">
                    <h4>working_conditions</h4>                                    
                  </mat-card>
                </div>
                <div fxFlex.gt-sm="30%" fxFlex="49%" pointer>
                  <mat-card class="card career-cards">
                    <img src="./assets/Svg/support.svg" alt="">
                    <h4>Support</h4>                                    
                  </mat-card>
                </div>
                <div fxFlex.gt-sm="30%" fxFlex="49%" pointer>
                  <mat-card class="card career-cards">
                    <img src="./assets/Svg/recognition.svg" alt="">
                    <h4>Recognition</h4>                                    
                  </mat-card>
                </div> -->
              </div>
            </div>
            <div (click)="dataUpload()" *ngIf="cardsSelected[0].name!='' && cardsSelected[1].name!=''">
              <!-- <button mat-raised-button float-right color="primary">{{buttonsText?.dictionary.save}}</button> -->
              <button class="next-level mat-fab mat-accent" color="primary" mat-fab="" ng-reflect-color="primary"
                [@flipAnimation]="completed">
                <span class="mat-button-wrapper"><i class="material-icons">arrow_forward</i></span>
                <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                <div class="mat-button-focus-overlay"></div>
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="(module=='explorer' || module == 'careertrekker') && showMatStepper === 'stepper2'">
          <div class="save-occ-careertrekking">
            <div class="save-occ-explorer" *ngIf="resp1!=undefined">
              <div class="explorer-occuptation explorer-task">
                <mat-card class="card questions-section">
                  <div class="image-content">
                    <!-- Here ngstyle added for canvas width and height dynamically -->
                    <div class="canves-block" *ngIf="imgageSrc!=''"
                      [ngStyle]="{'width': canvaswidth+'px', 'height': canvasheight+'px', 'display':'flex'}">
                      <img class="saved-canvas-img" src="{{imgageSrc}}" alt="save-own-occ" />
                    </div>
                  </div>
                  <div class="task-section text-center">
                    <!-- <div class="mobile-bg">
                      <div class="mobile-design-show" ngStyle.xs="position:absolute;right:25px;">
                        <button mat-fab class="orange-arrow" (click)="dataUpload()">
                          <i class="material-icons">save</i>
                        </button>
                      </div>
                      <h2 m-0 ngStyle.xs="color: #f9a825">{{name}}</h2>
                      <p m-0 class="text-center">{{describeThoughts}}</p>
                    </div> -->
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" ngStyle.xs="padding: 0 15px;">
                      <div fxFlex="55%">
                        <div class="icon-sec question-block dark-grey" flex v-center>
                          <div class="circle-icon" flex v-center h-center>
                            <div class="icon-char_math"></div>
                          </div>
                          <div class="w-100">present data</div>
                          <div class="animate mobile-design-show">
                            <button mat-fab class="orange-arrow" [ngStyle]="{'position':'relative','left':'16px'}">
                              <i class="material-icons">arrow_forward </i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="m-auto">
                        <div class="icon-sec status-block">
                          <ul class="list-style-none status-block" flex v-center h-center m-0 p-0>
                            <li>
                              <button mat-fab class="green" flex v-center h-center>
                                {{buttonsText?.dictionary.high}}
                                <i class="material-icons pointer-icon done">done</i>
                                <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                              </button>
                            </li>
                            <li>
                              <button mat-fab style="cursor: default" class="orange">
                                {{buttonsText?.dictionary.medium}}
                                <i class="material-icons pointer-icon done">done</i>
                                <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                              </button>
                            </li>
                            <li>
                              <button mat-fab class="purple">
                                {{buttonsText?.dictionary.low}}
                                <i class="material-icons pointer-icon done">done</i>
                                <!-- <img class="pointer-image animate" src="./assets/images/pointer.png" /> -->
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div fxFlex="49px" flex v-center ngStyle.xs="display:none;">
                        <button mat-fab class="orange-arrow">
                          <i class="material-icons">arrow_forward </i>
                        </button>
                      </div>
                      <!-- </div> -->
                    </div>

                    <div fxLayout="row" fxFlexFill class="common-row-sub rating-cards" fxLayoutGap="10px"
                      fxLayoutGap.sm="5px" ngStyle.xs="padding: 0 15px;">
                      <div fxFlex="" *ngIf="(savedEntireOcc==true && highData.length!=0 ) || savedEntireOcc==false">
                        <mat-card class="card"
                          [ngClass]="module === 'explorer'? 'explorer-theme-bg' : 'career-trekker-bg'">
                          <mat-card-title class="card-header" flex v-center h-center
                            ngStyle.xs="background-color:#00000030;">{{buttonsText?.dictionary.high}}
                          </mat-card-title>
                          <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                            <li class="status-circle green" *ngFor="let high of highData">
                              <div class="desktop-design-show">
                                <div class="icon-char_math"></div>
                              </div>
                              <div class="mobile-design-show">
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                                </mat-checkbox>
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>
                      <div fxFlex="" *ngIf="(savedEntireOcc==true && mediumData.length!=0 ) || savedEntireOcc==false">
                        <mat-card class="card"
                          [ngClass]="module === 'explorer'? 'explorer-theme-bg' : 'career-trekker-bg'">
                          <mat-card-title class="card-header" flex v-center h-center
                            ngStyle.xs="background-color:#00000030;">{{buttonsText?.dictionary.medium}}
                          </mat-card-title>
                          <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                            <li class="status-circle orange">
                              <div class="desktop-design-show">
                                <div class="icon-char_math"></div>
                              </div>
                              <div class="mobile-design-show">
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                                </mat-checkbox>
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>
                      <div fxFlex="" *ngIf="(savedEntireOcc==true && lowData.length!=0 ) || savedEntireOcc==false">
                        <mat-card class="card"
                          [ngClass]="module === 'explorer'? 'explorer-theme-bg' : 'career-trekker-bg'">
                          <mat-card-title class="card-header" flex v-center h-center
                            ngStyle.xs="background-color:#00000030;">{{buttonsText?.dictionary.low}}
                          </mat-card-title>
                          <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                            <li class="status-circle purple">
                              <div class="desktop-design-show">
                                <div class="icon-char_math"></div>
                              </div>
                              <div class="mobile-design-show">
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true">
                                </mat-checkbox>
                                <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"></mat-checkbox>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>
                    </div>

                    <div class="mobile-design-show">
                      <mat-card class="card tabs-card w-100">
                        <mat-card-content class="card-body text-center">
                          <div class="w-100">
                            <mat-tab-group>
                              <mat-tab label="Tab 1">
                                <ng-template mat-tab-label h-center flex>
                                  <div class="tab-button green" flex v-center h-center>
                                    <span>{{buttonsText?.dictionary.high}}</span>
                                  </div>
                                </ng-template>

                                <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                  fxLayoutGap.xs="5px">
                                  <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                    <div class="circle-img green">
                                      <img src="./assets/images/bulb.png" />
                                    </div>
                                    <div class="circle-img green">
                                      <img src="./assets/images/bulb.png" />
                                    </div>
                                    <div class="circle-img green">
                                      <img src="./assets/images/bulb.png" />
                                    </div>
                                  </div>
                                </div>
                              </mat-tab>
                              <mat-tab label="Tab 2">
                                <ng-template mat-tab-label h-center flex>
                                  <div class="tab-button red" flex v-center h-center>
                                    <span>{{buttonsText?.dictionary.medium}}</span>
                                  </div>
                                </ng-template>

                                <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                  fxLayoutGap.xs="5px">
                                  <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                    <div class="circle-img orange">
                                      <img src="./assets/images/bulb.png" />
                                    </div>
                                  </div>
                                </div>
                              </mat-tab>
                              <mat-tab label="Tab 3">
                                <ng-template mat-tab-label h-center flex>
                                  <div class="tab-button purple" flex v-center h-center>
                                    <span>{{buttonsText?.dictionary.low}}</span>
                                  </div>
                                </ng-template>

                                <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px"
                                  fxLayoutGap.xs="5px">
                                  <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                                    <div class="circle-img purple">
                                      <img src="./assets/images/bulb.png" />
                                    </div>
                                  </div>
                                </div>
                              </mat-tab>
                            </mat-tab-group>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </mat-card>
                <div class="desktop-design-show">
                  <button mat-raised-button float-right color="primary" (click)="dataUpload()">
                    {{buttonsText?.dictionary.save}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <button mat-button matStepperPrevious>Back</button> -->
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <!-- Below HTML code commented for Career Creator assignment pages, should not have a delete button
      <div class="mobile-design-show">
      <button pointer class="common-occ-header-button delete-occ-header-btn single-bottom-btn"
        (click)="deleteEntireOcc()">
        <span class="mat-button-wrapper">
          <img src="assets/Svg/delete.svg" alt="delete-image" />
        </span>
      </button>
    </div> -->
  </div>
</div>

<app-static-footer></app-static-footer>