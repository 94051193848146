import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { EventdispatchService } from "../../../../app/shared/common-modal/eventdispach.service";
import { DialogopenService } from "../../../../app/shared/common-modal/modalpopup.service";
import { Next } from '../../../shared/common-animations/CT_animations';
@Component({
  selector: 'quickpic-body',
  templateUrl: 'quickpic-result-body.layout.html',
  styles: [],
  animations: [Next],
})
export class QuickpicResultBodyComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input('charmediumattr') charmediumattr;
  @Input('charlowattr') charlowattr;
  @Input('charhighattr') charhighattr;
  @Input('skilllowattr') skilllowattr;
  @Input('skillmediumattr') skillmediumattr;
  @Input('skillhighattr') skillhighattr;
  @Input('pathfinderattributes') pathfinderattributes;
  @Input('defaultdata') defaultdata;
  @Input('buttonsText') buttonsText;
  @Input('navigatorAttributes') navigatorAttributes;
  @Input('scoutIconText') scoutIconText;
  @Input('scoutIconName') scoutIconName;
  @Input('occsData') occs;
  @Output() heightChangeEmit = new EventEmitter();
  @Output() quicpicOccChangeEmit = new EventEmitter();
  assetsUrl: string;
  statusHeight = 0;
  blockTotalHeight = 0;
  compActive = true;
  contentunsubscribe = new Subscription();
  initLoading = false;
  @ViewChild('statusCardHeight')
  statusCardHeight: ElementRef;
  @ViewChild('charTab') charTab: MatTabGroup;
  @ViewChild('skillTab') skillTab: MatTabGroup;
  @ViewChild('charTabmobile') charTabmobile: MatTabGroup;
  @ViewChild('skillTabmobile') skillTabmobile: MatTabGroup;

  constructor(
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    public dialogService: DialogopenService,
    private quickPiccontent: EventdispatchService
  ) {
    this.assetsUrl = assetsMediaUrl;
  }

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.blockHeight();
  }
  // Here this function is used to set the same height for right side occs list and left side result blocks
  blockHeight() {
    if (this.statusCardHeight && this.statusCardHeight.nativeElement) {
      this.statusHeight = this.statusCardHeight.nativeElement.offsetHeight;
      this.blockTotalHeight = this.statusHeight;
    }
    this.heightChangeEmit.emit(this.blockTotalHeight);
  }
  modalOpen() {
    this.dialogService.quickpicResultPopup(this.defaultdata);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initLoading = true;
  }
  goToNextTabIndex() {
    if (
      this.skillTab &&
      this.charTab &&
      this.charTabmobile &&
      this.skillTabmobile
    ) {
      this.skillTab.selectedIndex = 0;
      this.charTab.selectedIndex = 0;
      this.charTabmobile.selectedIndex = 0;
      this.skillTabmobile.selectedIndex = 0;
    }
    this.heightChangeEmit.emit(this.blockTotalHeight);
  }
  onTabClick() {
    if (this.initLoading) {
      this.goToNextTabIndex();
      this.initLoading = false;
    }
  }
  quickpicMobileList(index) {
    this.quicpicOccChangeEmit.emit(index);
  }
  ngOnDestroy() {
    this.contentunsubscribe.unsubscribe();
    this.compActive = false;
  }
  /**bug fix purpose */
  attributesCall(){};
}