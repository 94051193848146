import {
  Component,
  ElementRef,
  ViewChild,
  Inject,
  HostListener,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Store } from "@ngrx/store";
import {
  AudioState,
  PageDataState,
} from "../../../state-management/state/main-state";
import { environment } from "../../../../environments/environment";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { delay, takeWhile } from "rxjs/operators";
import { Next } from "../../../shared/common-animations/CT_animations";
import { MatTabGroup } from "@angular/material/tabs";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-quickpic-result',
  templateUrl: './quickpic-result-layout.html',
  styles: [],
  animations: [Next],
})
export class QuickpicResultComponent implements OnInit, OnDestroy {
  keyWords: any = [];
  buttonsText: any = [];
  nextocc:any;
  len;number = 1;
  lang: string;
  theme_name: string;
  charmediumattr: any = [];
  charlowattr: any = [];
  charhighattr: any = [];
  skilllowattr: any = [];
  skillmediumattr: any = [];
  skillhighattr: any = [];
  pathfinderattributes: any = [];
  navigatorWorkValuesSet: any = [];
  defaultdata: any;
  occs: any;
  occs2: any;
  id: any;
  occCardAPIUrl: string;
  compActive:boolean = true;
  arrowDisplay:boolean = true;
  assetsUrl: string;
  navigateIntro: boolean;
  completed: boolean;
  blockTotalHeight:number = 0;
  statusHeight:number = 0;
  attributeResponse:any;
  audioState:string = 'stoped';
  audionotfound:boolean = false;
  scoutIconName: string;
  scoutIconText: string;
  @ViewChild('statusCardHeight')
  statusCardHeight: ElementRef;
  @ViewChild('charTab') charTab: MatTabGroup;
  @ViewChild('skillTab') skillTab: MatTabGroup;
  @ViewChild('charTabmobile') charTabmobile: MatTabGroup;
  @ViewChild('skillTabmobile') skillTabmobile: MatTabGroup;
  occList: any;

  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    public eventService: EventdispatchService,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private router: Router,
    private store: Store<PageDataState>,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    @Inject('OCC_CARD_URL') private occCardUrl: string,
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    private titleService: Title
  ) {
    this.assetsUrl = assetsMediaUrl;
    this.occCardAPIUrl = occCardUrl;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[4]);
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'QUICKPIC', pageCode: 'QUICKPIC_RESULTS' },
    });
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    this.keyWords = this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') == '/app/quickpic/result') {
          if (e.type === 'languageChanged') {
            
            this.lang = sessionStorage.getItem('session_token');
            // if (this.occs.length > 0) {
            this.occs2 = this.occs;
            this.getOccs();
          }
          if (e.type == 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type == 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              sessionStorage.setItem('infopopup', 'false');
              this.infopopup();
            }
          }
          if (e.type == 'backClicked') {
            const evnt = document.createEvent('CustomEvent');
            this.eventService.dispatchAudioStop(evnt);
            this.unsavedChanges();
          }
        }
      });

    if (this.lang == '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    
  }
  unsavedChanges() {
    this.utils.backRoute();

  }
  getOccs() {
    this.apiModel.method = 'GET';
    this.apiModel.moduleName = 'explorer';
    this.apiModel.endUrl = 'occs';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiCall
      .getData([this.apiModel])
      .pipe(takeWhile(() => this.compActive))
      .subscribe((data) => {
        this.occList = JSON.parse(data[0]);
        this.apiData();
      });
    
  }
  apiData() {
    this.apiModel.moduleName = 'quick-pic';
    this.apiModel.endUrl = '/quick-pic/results/' + this.id;
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp != undefined && resp != null) {
            if (this.occs2 && this.occs2.length > 0) {
              var filteredArray = this.occList.filter((f) =>
                this.occs2.some((item) => item.occId === f.occId)
              );
              this.occs = filteredArray;
              this.occs.sort((a, b) => a.occId - b.occId);
              this.defaultdata = this.occs[0];
            } else {
              this.occs = JSON.parse(resp);
              this.occs.sort((a, b) => a.occId - b.occId);
              this.defaultdata = this.occs[0];
            }
            this.dialogService.hideLoading();
            this.attributesCall(0);
          }
        });
    } catch (e) {
      this.utils.handleError(`quickpic-result-component.ts apiData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  ngOnInit() {
    this.store
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages != undefined && resp.pages[0] != null) {
          const data = resp.pages;
          for (let i = 0; i < data.length; i++) {
            if (data[i].pageCode == 'GLOBAL_SETTINGS') {
              sessionStorage.removeItem('GLOBAL_SETTINGS');
              sessionStorage.setItem(
                'GLOBAL_SETTINGS',
                JSON.stringify(data[i])
              );
            
            }
          }
        }
        this.buttonsText = JSON.parse(
          sessionStorage.getItem('GLOBAL_SETTINGS')
        );
        this.keyWords =
          this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
      });
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.id = params['id'];
        this.apiData();
      });
    this.setQuickPicResultTitle();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('QUICKPIC_RESULTS');
      }.bind(this)
    );
  }

  ngAfterViewInit() {
    this.infopopup();
  }
  stopAudio() {
    //to stop current playing audio
    this.audioState = 'stoped';
    this.audionotfound = false;
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }

  // For desktop view - When user clicks on the last occ then fab click navigates to intro througth popup.
  Navigateintro() {
    this.dialogService.quickPicResultModel(this.keyWords.dictionary);
  }
  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }
  attributesCall(index?: number) {
    // Displays fab icon at last occ click
    if (index === this.occs.length - 1) {
      this.navigateIntro = true;
      this.completed = true;
    } else {
      this.navigateIntro = false;
      this.completed = false;
    }

    this.apiModel.moduleName = 'attributes';
    this.apiModel.endUrl = 'attributes';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            resp = JSON.parse(resp);
            this.charmediumattr = [];
            this.charlowattr = [];
            this.charhighattr = [];
            this.skilllowattr = [];
            this.skillmediumattr = [];
            this.skillhighattr = [];
            this.pathfinderattributes = [];
            this.navigatorWorkValuesSet = [];
            for (let j = 0; j < this.defaultdata.attributes.length; j++) {
              for (let i = 0; i < resp.length; i++) {
                if (this.defaultdata.attributes[j].type == 'value') {
                  if (
                    resp[i].type == 'value' &&
                    this.defaultdata.attributes[j].name === resp[i].name
                  ) {
                    this.navigatorWorkValuesSet.push(resp[i]);
                    break;
                  }
                }

                if (this.defaultdata.attributes[j].type == 'hollandcode') {
                  if (
                    resp[i].type == 'hollandcode' &&
                    this.defaultdata.attributes[j].name == resp[i].hollandCode
                  ) {
                    this.pathfinderattributes.push(resp[i]);
                    break;
                  }
                }

                if (resp[i].type == 'group') {
                  if (this.defaultdata.category.title) {
                    this.scoutIconText = this.defaultdata.category.title;
                    if (parseInt(this.defaultdata.category.categoryId) === 1) {
                      this.scoutIconName = 'EXPLORER_PEOPLE';
                    } else if (
                      parseInt(this.defaultdata.category.categoryId) === 2
                    ) {
                      this.scoutIconName = 'EXPLORER_IDEAS';
                    } else if (
                      parseInt(this.defaultdata.category.categoryId) === 3
                    ) {
                      this.scoutIconName = 'EXPLORER_THINGS';
                    }
                  }
                }
                if (this.defaultdata.attributes[j].type == 'skill') {
                  if (
                    resp[i].type == 'skill' &&
                    this.defaultdata.attributes[j].name == resp[i].name
                  ) {
                    if (this.defaultdata.attributes[j].weight == 'high') {
                      this.skillhighattr.push(resp[i]);
                      break;
                    } else if (
                      this.defaultdata.attributes[j].weight == 'medium'
                    ) {
                      this.skillmediumattr.push(resp[i]);
                      break;
                    } else {
                      this.skilllowattr.push(resp[i]);
                      break;
                    }
                  }
                }
                if (this.defaultdata.attributes[j].type == 'characteristic') {
                  if (
                    resp[i].type == 'characteristic' &&
                    this.defaultdata.attributes[j].name == resp[i].name
                  ) {
                    if (this.defaultdata.attributes[j].weight == 'high') {
                      this.charhighattr.push(resp[i]);
                      break;
                    } else if (
                      this.defaultdata.attributes[j].weight == 'medium'
                    ) {
                      this.charmediumattr.push(resp[i]);
                      break;
                    } else {
                      this.charlowattr.push(resp[i]);
                      break;
                    }
                  }
                }
              }
            }
          }
        });
    } catch (e) {
      this.utils.handleError(`quickpic-result-component.ts attributesCall: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  heightCalculation(count) {
    this.blockTotalHeight = count;
  }
  nextData(navData) {
    if (navData.action === 'nextNav') {
      this.nextocc = this.nextocc + 1;
    } else {
      this.nextocc = navData.index;
    }
    this.attributesCall(this.nextocc);

    if (this.nextocc < this.occs.length) {
      this.defaultdata = this.occs[this.nextocc];
    } else {
      this.dialogService.quickPicResultModel(this.keyWords.dictionary);
    }
  }
  modalOpen() {
    this.dialogService.quickpicResultPopup(this.defaultdata);
  }
  ngOnDestroy() {
    this.compActive = false;
  }

  private setQuickPicResultTitle() {
    this.titleService.setTitle('Quick Pic Result - CareerTrek');
  }
}
