import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './modules/landing-module/landing-component';
// import { WelcomeComponent } from './modules/landing-module/welcome-component';
import { ProfileComponent } from './modules/landing-module/profile-component';
import { CreateOwnOccComponent } from './modules/save-own-occ-module/save-own-occ-component';
import { CustomOccDetailComponent } from './modules/custom-occ-detail/custom-occ-component';
import { CreateOwnOccAttributeComponent } from './modules/save-own-occ-module/save-own-attribute-component';

const routes: Routes = [
  // {
  //   path: '', component: WelcomeComponent, pathMatch: 'full'
  // },
  {
    path: 'app/landing', component: LandingComponent, pathMatch: 'full'
  },
  {
    path: 'app/profile', component: ProfileComponent, pathMatch: 'full'
  },
  {
    path: 'app/create', component: CreateOwnOccComponent
  },
  {
  path : 'app/createOccAttribute', component :CreateOwnOccAttributeComponent
  },
  {path: 'app/custom-occ-detail', component: CustomOccDetailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


