
/**Import angular core packages */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { Store } from '@ngrx/store';
import { AttributeDataState, AudioState, PageDataState, PathFinderquesState } from '../../../state-management/state/main-state';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { Next } from '../../../shared/common-animations/CT_animations';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-pathfinder-intro',
	templateUrl: './pathfinder-intro-layout.html',
	animations: [Next]
})

export class PathFinderIntroComponent implements OnInit,OnDestroy {
	title: string;
	languageKey: any;
	cards: any = [];
	lang;
	theme_name;

	//The completed variable to set to true, to turn on the flip animation of the button
	completed: boolean;
	compActive = true;
	pathFinderIntroText : any;
	audioState = 'stoped';
	audionotfound = false;

	constructor(private _location: Location,
		private activatedRoute: ActivatedRoute,
		public eventService: EventdispatchService,
		private router: Router,
		private store: Store<AttributeDataState>,
		private store1: Store<PageDataState>,
		private audioStore: Store<AudioState>,
        private audioService: AudioService,
        private pathFinderAssessmentStore:Store<PathFinderquesState>,
		public dialogService: DialogopenService,
		private oninitpopupservice: OnLoadPopUpService,
		private apiModel: ApiCallClass,
		private apiCall: ApiCallService, private utils: Utilities,
		private titleService: Title
	) {
		let colors = environment.THEME_COLORS;
        sessionStorage.setItem('theme_color', colors[1]);
		const event = document.createEvent('CustomEvent');
        event.initEvent('themeChanged', true, true);
        this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		this.oninitpopupservice.pageCallText(this.lang, 'attributes/hollandcode');
		 eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/pathfinder/intro')) {
				if (e.type === 'languageChanged') {
					this.languageKey = this.cards[0] && this.cards[0].language;
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.pageCallText(this.lang, 'attributes/hollandcode');
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}

		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})

	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function() {
			this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_INTRO');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
		
	getAttributes() {
		try {
			this.dialogService.showLoading();
			this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				const data = (resp.attribute);
				this.cards = [];
				if (data[0] != undefined) {
					if (data[0].type == 'hollandcode') {
						for (let i = 0; i < data.length; i++) {
							this.cards.push(data[i]);
						}
					}
					this.title = sessionStorage.getItem('formobileheading');
				}
				if (this.cards[0] != undefined && this.languageKey != this.cards[0].language) {
					this.languageKey = this.cards[0].language;
				}
				if(this.cards && this.cards.length>0){
					this.dialogService.hideLoading();
				}
			});
		} catch (e) {
			this.utils.handleError(`pathfinder-intro-component.ts getAttributes: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	ngOnInit() {
		// debugger;
		this.store1.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'PATHFINDER',pageCode:'PATHFINDER_INTRO'}});
		this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.pathFinderIntroText = data.find((obj => obj.pageCode === 'PATHFINDER_INTRO'));
			}}
		);
		this.completed = !this.completed;
		setTimeout(() => {
			this.getAttributes();
		});
		this.setPathfinderIntroTitle();
	}
	clickRoute(name,hollandcode,attributeId) {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.pathFinderAssessmentStore.dispatch({ type: 'PATHFINDER_ASSESSMENT_VAL', payload: { pathFinderques: { name: '', like: [], idArray : [],
			unlike: [], saveArray: [], nameArray: [], likeAttributeId: [],toStoreLikedDataInArray:[] } } });	
		this.router.navigateByUrl('/app/pathfinder/assessment');
		try {
			this.router.navigate(['/app/pathfinder/assessment'], {
				relativeTo: this.activatedRoute,
				queryParams: {
					attrName: name,
					hollandcode:hollandcode,
					attributeId:attributeId
				}
			});
		} catch (e) {
			this.utils.handleError(`pathfinder-intro.component.ts clickRoute: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	unsavedChanges() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.utils.backRoute();
	}

	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}

	ngOnDestroy(){
		this.compActive = false;
	}

	private setPathfinderIntroTitle(){
		this.titleService.setTitle("Pathfinder Intro - CareerTrek");
	}
}
