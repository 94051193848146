import { Action } from '../actions/main-action-creator';
import { ActionReducer } from '@ngrx/store';
import {
  PageDataState,
  defaultPageDataState,
  SiteDataState,
  defaultSiteDataState,
  defaultProfileDataState,
  OccIndexDataState,
  defaultOccIndexDataState,
  OccsDataState,
  defaultOccsDataState,
  defaultAttributeDataState,
  AttributeDataState,
  FactoidDataState,
  defaultFactoidDataState,
  defaultAudioState,
  defaultOwnOccListState,
  defaultCompletionListState,
  defaultQuickpicQuesIdVal,
  defaultPathFinderTopSkillsState,
  defaultPathwayOccViewedState,
  defaultUserProfileState,
  defaultPathwayOccViewedCareerState,
  defaultOccThumbState,
  defaultTrekkerOccViewedState,
  defaultTrekkerOccViewedCareerState,
  defaultCategoriesState,
  defaultAttributesState,
  defaultPathFinderChallengeState,
  defaultTrekkerAssessmentState,
  defaultCareerCreatorState,
  defaultPathFinderquesState,
  defaultAvatarListState,
  defaultUserAvatarState,
  defaultAvatarProfileDataState,
  defaultCareerCreatorAttrState,
  defaultPathFinderAttrState,
  defaultScoutActivityOneState,
  defaultScoutActivityTwoState,
  defaultExplorerChallengeState,
  defaultTrekkerTopSkillsState,
  defaultTrekkerChallengeState,
  defaultModulesDataState,
  defaultExpTaskStoreState,
  defaultCommunityActivityDataState,
  defaultDreamCareerListState,
  defaultScoutOccViewedState,
  defaultScoutOccViewedCareerState,
  defaultNavigatorOccViewedState,
  defaultNavigatorOccViewedCareerState,
  defaultNavigatorResultStoreState,
  defaultAddRibbonToCareerState,
  defaultDeleteRibbonToCareerState,
  defaultGetThoughtBoxState,
  defaultSaveThoughtBoxState,
  defaultNavigatorWorkValuesState,
  defaultNavigatorTieBreakerState,
  defaultNavigatorChallengeState,
  defaultExplorerOccsViewedListStore,
  defaultExplorerOccsViewedCareerStore
} from '../state/main-state';

export function getAudioState(state = defaultAudioState, action: Action) {
  switch (action.type) {
    case 'SET_AUDIO_PLAYING': {
      return {
        audioState: 'playing',
        audionotfound: false
      }
    } case 'SET_AUDIO_WARNING': {
      return {
        audioState: 'warning',
        audionotfound: true
      }
    }
    case 'SET_AUDIO_STOPED': {
      return {
        audioState: 'stoped',
        audionotfound: false
      }
    }
    case 'SET_AUDIO_LOADING': {
      return {
        audioState: 'loading',
        audionotfound: false
      }
    }
    default: {
      return state.audio;
    }

  }
}

/// Helper function to create new state object
const newState = (state, newData) => {
  return Object.assign({}, state, newData);
};
/// Reducer function

export function getpagesReducer(state = defaultPageDataState, action: Action) {
  try {
    const checknull = window.sessionStorage.getItem('pagesData');
    if (checknull != null && checknull !== undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }

  switch (action.type) {
    case 'SET_PAGE_TEXT': {
      state = { pages: action.payload };
      window.sessionStorage.setItem('pagesData', JSON.stringify(state));
      window.sessionStorage.setItem('GLOBAL_SETTINGS', JSON.stringify(state.pages.find((object) => object.pageCode === "GLOBAL_SETTINGS")));
      return (state);
    }
    case 'TRIGGER_TEXT_CHANGE_EVENT': {
      let stpages = (state.pages && state.pages.length > 0) ? state.pages.map(val => {
        if (val.module === action.payload.module && val.pageCode === action.payload.pageCode) {
          val.selected = true;
        } else {
          val.selected = false;
        }
        return val;
      }) : state.pages;
      state = { pages: stpages };
      window.sessionStorage.setItem('pagesData', JSON.stringify(state));

      return state;
    }
    default: {
      return state;
    }
  }
}
export function getsiteReducer(state = defaultSiteDataState, action: Action) {
  switch (action.type) {
    case 'SET_SITE_TEXT': {
      return JSON.parse(action.payload);
    }
    default: {
      return state;
    }
  }
}

// For user profile Data.
export function getProfileDataReducer(profile = defaultProfileDataState, action: Action) {

  switch (action.type) {
    case 'SET_PROFILE_IMAGE': {
      return { ...profile, profileImage: action.payload };
    }
    default: {
      return profile;
    }
  }
}

export function getoccsReducer(state = defaultOccsDataState, action: Action) {
  try {
    const checknull = window.sessionStorage.getItem('occupationList');
    if (checknull != null && checknull !== undefined) {

      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }
  switch (action.type) {

    case 'SET_OCC_LIST_TEXT': {

      state = { occs: action.payload };
      window.sessionStorage.setItem('occupationList', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}

export function getoccsIndexReducer(state = defaultOccIndexDataState, action: Action) {
  switch (action.type) {
    case 'SET_OCC_TOTAL_LIST_TEXT': {
      return JSON.parse(action.payload);
    }
    default: {
      return state;
    }
  }
}
export function getattributesReducer(state = defaultAttributeDataState, action: Action) {
  try {
    const checknull = window.sessionStorage.getItem('attributesData');
    if (checknull != null && checknull !== undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }
  switch (action.type) {
    case 'SET_ATTRIBUTES_TEXT': {
      state = { attribute: JSON.parse(action.payload) };
      window.sessionStorage.setItem('attributesData', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}

export function getfactoidsReducer(state = defaultFactoidDataState, action: Action) {
  switch (action.type) {
    case 'SET_FACTOIDS_TEXT': {
      return JSON.parse(action.payload);
    }
    default: {
      return state;
    }
  }
}
export function getOwnOccListReducer(createOwnOccList = defaultOwnOccListState, action: Action) {
  switch (action.type) {
    case 'SET_OWN_OCC_TEXT': {
      return { ...createOwnOccList, ownOccList: JSON.parse(action.payload) };
    }
    default: {
      return defaultOwnOccListState;// The Occ list should be updated everytime.So sending empty result to the default.  
    }
  }
}

export function getmodulesReducer(moduleList = defaultModulesDataState, action: Action) {

  switch (action.type) {
    case 'SET_MODULES': {
      return { ...moduleList, modules: JSON.parse(action.payload) };
    }
    default: {
      return moduleList;
    }
  }
}

export function getcommunityOccsReducer(communityOccsList = defaultCommunityActivityDataState, action: Action) {

  switch (action.type) {
    case 'SET_COMMUNITYOCCS': {
      return { ...communityOccsList, communityOccs: JSON.parse(action.payload) };
    }
    default: {
      return communityOccsList;
    }
  }
}

export function getPathwayOccViewedReducer(pathwayViewedOccList = defaultPathwayOccViewedState,
  action: Action) {
  switch (action.type) {
    case 'SET_PATHWAY_OCC_VIEWED_LIST': {
      return { ...pathwayViewedOccList, pathwayOccViewedList: JSON.parse(action.payload) };
    }
    default: {
      return pathwayViewedOccList;
    }
  }
}

export function getTrekkerOccViewedReducer(trekkerViewedOccList = defaultTrekkerOccViewedState,
  action: Action) {
  switch (action.type) {
    case 'SET_TREKKER_OCC_VIEWED_LIST': {
      return { ...trekkerViewedOccList, trekkerOccViewedList: JSON.parse(action.payload) };
    }
    default: {
      return trekkerViewedOccList;
    }
  }
}

export function getScoutOccViewedReducer(scoutOccViewedList = defaultScoutOccViewedState,
  action: Action) {
  switch (action.type) {
    case 'SET_SCOUT_OCC_VIEWED_LIST': {
      return { ...scoutOccViewedList, scoutOccViewedList: JSON.parse(action.payload) };
    }
    default: {
      return scoutOccViewedList;
    }
  }
}

export function getPathwayOccViewedCareerReducer(pathwayViewedOccCareers = defaultPathwayOccViewedCareerState,
  action: Action) {
  switch (action.type) {
    case 'SET_PATHFINDER_OCC_VIEWED_CAREER': {
      return { ...pathwayViewedOccCareers, pathwayOccViewedCareers: JSON.parse(action.payload) };
    }
    default: {
      return pathwayViewedOccCareers;
    }
  }
}
export function getTrekkerOccViewedCareerReducer(trekkerViewedOccCareers = defaultTrekkerOccViewedCareerState,
  action: Action) {
  switch (action.type) {
    case 'SET_TREKKER_OCC_VIEWED_CAREER': {
      return { ...trekkerViewedOccCareers, trekkerOccViewedCareers: JSON.parse(action.payload) };
    }
    default: {
      return trekkerViewedOccCareers;
    }
  }
}

export function getScoutOccViewedCareerReducer(scoutViewedOccCareers = defaultScoutOccViewedCareerState,
  action: Action) {
  switch (action.type) {
    case 'SET_SCOUT_OCC_VIEWED_CAREER': {
      return { ...scoutViewedOccCareers, scoutOccViewedCareers: JSON.parse(action.payload) };
    }
    default: {
      return scoutViewedOccCareers;
    }
  }
}

/*Explorer occs viewed list and career reducers*/
//List
export function getExplorerOccViewedListReducer(explorerOccsViewedList = defaultExplorerOccsViewedListStore,
  action: Action) {
  switch (action.type) {
    case 'SET_EXPLORER_OCC_VIEWED_LIST': {
      return { ...explorerOccsViewedList, explorerOccsViewedList: JSON.parse(action.payload) };
    }
    default: {
      return explorerOccsViewedList;
    }
  }
}

//Viewed Career
export function getExplorerOccViewedCareerReducer(explorerOccsViewedCareer = defaultExplorerOccsViewedCareerStore,
  action: Action) {
  switch (action.type) {
    case 'SET_EXPLORER_OCC_VIEWED_CAREER': {
      return { ...explorerOccsViewedCareer, explorerOccsViewedCareer: JSON.parse(action.payload) };
    }
    default: {
      return explorerOccsViewedCareer;
    }
  }
}

//Naigator occ viewed list reducer
export function getNavigatorOccViewedReducer(navigatorOccViewedList = defaultNavigatorOccViewedState,
  action: Action) {
  switch (action.type) {
    case 'SET_NAVIGATOR_OCC_VIEWED_LIST': {
      return { ...navigatorOccViewedList, navigatorOccViewedList: JSON.parse(action.payload) };
    }
    default: {
      return navigatorOccViewedList;
    }
  }
}

//Naigator occ viewed career reducer
export function getNavigatorOccViewedCareerReducer(navigatorViewedOccCareers = defaultNavigatorOccViewedCareerState,
  action: Action) {
  switch (action.type) {
    case 'SET_NAVIGATOR_OCC_VIEWED_CAREER': {
      return { ...navigatorViewedOccCareers, navigatorOccViewedCareers: JSON.parse(action.payload) };
    }
    default: {
      return navigatorViewedOccCareers;
    }
  }
}


export function getOccThumbReducer(OccThumbData = defaultOccThumbState,
  action: Action) {
  switch (action.type) {
    case 'SET_OCC_THUMB': {
      return { ...OccThumbData, OccThumbList: JSON.parse(action.payload) };
    }
    default: {
      return OccThumbData;
    }
  }
}



export function getUserProfileDataReducer(userProfile = defaultUserProfileState,
  action: Action) {
  switch (action.type) {
    case 'SET_USER_PROFILE_DATA': {
      return { ...userProfile, userProfileData: JSON.parse(action.payload) };
    }
    default: {
      return userProfile;
    }
  }
}


export function getModuleCompletionDataReducer(moduleCompletionList = defaultCompletionListState, action: Action) {
  switch (action.type) {
    case 'SET_MODULE_COMPLETION_DATA': {
      return { ...moduleCompletionList, completionList: JSON.parse(action.payload) };
    }
    default: {
      return moduleCompletionList;
    }
  }
}
export function getdefaultPathFinderTopSkillsReducer(state = defaultPathFinderTopSkillsState, action: Action) {
  switch (action.type) {
    case 'PATHFINDER_TOPSKILLS_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
export function getTrekkerAssessmentReducer(state = defaultTrekkerAssessmentState, action: Action) {
  switch (action.type) {
    case 'TREKKER_ASSESSMENT_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
export function getCareerCreatorAnswersReducer(state = defaultCareerCreatorState, action: Action) {
  switch (action.type) {
    case 'CAREER_CREATOR_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getDreamCareerListReducer(careetState = defaultDreamCareerListState, action: Action) {
  switch (action.type) {
    case 'SET_DREAMCAREERS': {
      return { ...careetState, dreamCareerList: JSON.parse(action.payload) };
    }
    default: {
      return careetState;
    }
  }
}

export function getQuickpicQuesIdReducer(state = defaultQuickpicQuesIdVal, action: Action) {
  switch (action.type) {
    case 'QuickPIC_QuestionsID': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
export function getAttributesReducer(Attributescall = defaultAttributesState, action: Action) {
  switch (action.type) {
    case 'SET_ATTRIBUTES_DATA': {
      return { ...Attributescall, attributesList: JSON.parse(action.payload) };
    }
    default: {
      return Attributescall;
    }
  }
}
export function getCategoriesReducer(Catagoriescall = defaultCategoriesState, action: Action) {
  switch (action.type) {
    case 'SET_CATEGORIES_DATA': {
      return { ...Catagoriescall, CatagoriesList: JSON.parse(action.payload) };
    }
    default: {
      return Catagoriescall;
    }
  }
}
export function getPathFinderquesReducer(state = defaultPathFinderquesState, action: Action) {
  switch (action.type) {
    case 'PATHFINDER_ASSESSMENT_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getCareerCreatorAttrReducer(state = defaultCareerCreatorAttrState, action: Action) {
  switch (action.type) {
    case 'CAREER_CREATOR_ATTR_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getPathFinderAttrReducer(state = defaultPathFinderAttrState, action: Action) {
  switch (action.type) {
    case 'PATHFINDER_ATTR_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }

}
export function getPathFinderChallengeReducer(state = defaultPathFinderChallengeState, action: Action) {
  switch (action.type) {
    case 'PATHFINDER_CHALLENGE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

//Avatar Reducer implementation started Here
export function getavatarListReducer(emojiFaceData = defaultAvatarListState, action: Action) {
  try {
    switch (action.type) {
      case 'SET_AVATAR_TOTAL_LIST': {
        return { ...emojiFaceData, emojiFaceData: JSON.parse(action.payload) };
      }
      default: {
        return emojiFaceData;
      }
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }
}

export function getExplorerChallengeReducer(state = defaultExplorerChallengeState, action: Action) {
  switch (action.type) {
    case 'EXPLORER_CHALLENGE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getNavigatorWorkValuesReducer(state = defaultNavigatorWorkValuesState, action: Action) {
  switch (action.type) {
    case 'NAVIGATOR_WORK_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

//Navigator TieBreaker Reduce
export function getNavigatorTieBreakerReducer(state = defaultNavigatorTieBreakerState, action: Action) {
  switch (action.type) {
    case 'NAVIGATOR_TIE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getNavigatorChallengeReducer(state = defaultNavigatorChallengeState, action: Action) {
  switch (action.type) {
    case 'NAVIGATOR_CHALLENGE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getScoutActivityOneReducer(state = defaultScoutActivityOneState, action: Action) {
  try {
    switch (action.type) {
      case 'SCOUT_ACTIVITY_ONE_VAL': {
        return { ...state, ...action.payload };
      }
      default: {
        return state;
      }
    }
  } catch (e) {
    console.log('getScoutActivityOneReducer error', e);
  }
}

export function getScoutActivityTwoReducer(state = defaultScoutActivityTwoState, action: Action) {
  try {
    switch (action.type) {
      case 'SCOUT_ACTIVITY_TWO_VAL': {
        return { ...state, ...action.payload };
      }
      default: {
        return state;
      }
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }
}

export function getdefaultTrekkerTopSkillsReducer(state = defaultTrekkerTopSkillsState, action: Action) {
  try {

    switch (action.type) {
      case 'TREKKER_TOPSKILLS_VAL': {
        return { ...state, ...action.payload };
      }
      default: {
        return state;
      }
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }
}
export function getUserAvatarReducer(userEmojiData = defaultUserAvatarState, action: Action) {
  try {
    switch (action.type) {
      case 'SET_USER_AVATAR_DATA': {
        return { ...userEmojiData, userEmojiData: JSON.parse(action.payload) };
      }
      default: {
        return userEmojiData;
      }
    }
  } catch (e) {
    console.log('error in reducer--------->' + e.message);
  }

}
export function getAvatarProfileDataReducer(profile = defaultAvatarProfileDataState, action: Action) {
  switch (action.type) {
    case 'SET_AVATAR_PROFILE_IMAGE': {
      return { ...profile, avatarProfileImage: action.payload };
    }
    default: {
      return profile;
    }
  }
}
export function getdefaultTrekkerChallengeReducer(state = defaultTrekkerChallengeState, action: Action) {
  switch (action.type) {
    case 'TREKKER_CHALLENGE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
/* Reducer for Navigator Result Store */
export function getdefaultNavigatorResultStoreState(state = defaultNavigatorResultStoreState, action: Action) {
  switch (action.type) {
    case 'NAVIGATOR_RESULT_STORE_VAL': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

/* Reducer for Explorer Task Store */
export function getdefaultExpTaskStoreState(state = defaultExpTaskStoreState, action: Action) {
  switch (action.type) {
    case 'EXPLORER_TASK_STORE_VAL': {
      return { ...state, explorerTask: action.payload };
    }
    default: {
      return state;
    }
  }
}

export function getAddRibbonToCareer(addRibbon = defaultAddRibbonToCareerState, action: Action) {
  switch (action.type) {
    case 'SET_RIBBON_VALUE': {
      return { ...addRibbon, addRibbonValue: action.payload };
    }
    default: {
      return addRibbon;
    }
  }
}

export function getDeleteRibbonToCareer(deleteRibbon = defaultDeleteRibbonToCareerState, action: Action) {
  switch (action.type) {
    case 'DELETE_RIBBON_VALUE': {
      return { ...deleteRibbon, deleteRibbonValue: action.payload };
    }
    default: {
      return deleteRibbon;
    }
  }
}

export function getThoughtBoxForCareerCorner(getThought = defaultGetThoughtBoxState, action: Action) {
  switch (action.type) {
    case 'SET_THOUGHT_BOX_VALUE': {
      return JSON.parse(action.payload);
    }
    default: {
      return getThought;
    }
  }
}

export function saveThoughtBoxForCareerCorner(saveThought = defaultSaveThoughtBoxState, action: Action) {
  switch (action.type) {
    case 'SET_THOUGHT_BOX': {
      return { ...saveThought, saveThoughtValue: action.payload };
    }
    default: {
      return saveThought;
    }
  }
}


