<div *ngIf="defaultdata!=undefined" class="desktop-design-show">
  <mat-card class="card">
    <div class="second-block" #statusCardHeight>
      <div flex v-center>
        <h2 text-color="primary" mb-0 mr-10>{{defaultdata.title}}</h2>
        <button mat-button color="primary" (click)="modalOpen()" class="readmore-button" flex v-center h-center>
          <i class="material-icons">remove_red_eye</i>
          <span font-bold>{{buttonsText.dictionary.readMore}}</span>
        </button>
      </div>
      <p class="w-100" m-0>{{defaultdata.description}}</p>
      <div ngStyle.sm="display:none;">
        <h4 m-0> {{buttonsText.dictionary.group}}</h4>
        <div class="career-group">
          <mat-card class="card" m-0>
            <div flex v-center h-center class="career-card">
              <img src="{{assetsUrl}}{{scoutIconName}}.svg" alt="">
              <p m-0 font-bold>{{scoutIconText}}</p>
            </div>
          </mat-card>
        </div>
        <h4 m-0>{{buttonsText.dictionary.workValues}}</h4>
        <div fxLayout="row" fxFlexFill fxLayoutGap="5px" class="navigator-group">
          <div fxFlex="" *ngFor="let item of navigatorAttributes">
            <mat-card class="card" m-0>
              <div flex v-center h-center>
                <div class="group-work-card">
                  <img src="./assets/Svg/{{item?.icon}}.svg" alt="">
                  <p m-0 font-bold>{{item?.displayName}}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <h4 m-0>{{buttonsText.dictionary.characteristics}}</h4>
        <div fxLayout="row" fxFlexFill class="common-row-sub status-card" fxLayoutGap="5px" fxLayoutWrap="wrap">
          <div fxFlex="" *ngIf="charhighattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header green">{{buttonsText.dictionary.high}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of charhighattr">
                  <div class="status-circle green" flex v-center h-center>
                    <img src="assets/Svg/{{attr.icon}}.svg">
                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
          <div fxFlex="" *ngIf="charmediumattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header red"> {{buttonsText.dictionary.medium}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of charmediumattr">
                  <div class="status-circle orange" flex v-center h-center>
                    <img src="assets/Svg/{{attr.icon}}.svg">

                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
          <div fxFlex="" *ngIf="charlowattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header purple"> {{buttonsText.dictionary.low}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of charlowattr">
                  <div class="status-circle purple" flex v-center h-center>
                    <img src="assets/Svg/{{attr.icon}}.svg">
                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
        </div>
        <h4 m-0>{{buttonsText.dictionary.personalityTypes}}</h4>
        <div fxLayout="row" fxFlexFill class="common-row-sub personality-block" fxLayoutGap="5px">
          <div fxFlex="" *ngFor="let attr of pathfinderattributes; let inx=index">
            <mat-card class="card" m-0>
              <div flex v-center h-center p-5>
                <img class="image-size" src="assets/pathfinder/holland/{{attr.icon}}.svg" alt="img-quick" loaded
                  (loaded)="(inx==pathfinderattributes.length-1)?blockHeight():''">
                <p m-0 font-bold>{{attr.displayName}}</p>
              </div>
            </mat-card>
          </div>
        </div>
        <h4 m-0>{{buttonsText.dictionary.skills}}</h4>
        <div fxLayout="row" fxFlexFill class="common-row-sub skill-block" fxLayoutGap="5px" fxLayoutGap.sm="0px"
          fxLayoutWrap="wrap">
          <div fxFlex="" fxFlex.sm="100%" *ngIf="skillhighattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header green"> {{buttonsText.dictionary.high}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of skillhighattr; let inx=index">
                  <div class="status-circle green" flex v-center h-center>
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}" loaded
                      (loaded)="(inx==skillhighattr.length-1)? blockHeight():''">
                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
          <div fxFlex="" fxFlex.sm="100%" *ngIf="skillmediumattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header red"> {{buttonsText.dictionary.medium}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of skillmediumattr; let inx=index">
                  <div class="status-circle orange" flex v-center h-center>
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}" loaded
                      (loaded)="(inx==skillmediumattr.length-1)? blockHeight():''">
                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
          <div fxFlex="" fxFlex.sm="100%" *ngIf="skilllowattr.length!=0" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <mat-card-title class="card-header purple"> {{buttonsText.dictionary.low}}</mat-card-title>
              <ul class=" list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex v-center h-center p-5>
                <li *ngFor="let attr of skilllowattr; let inx=index">
                  <div class="status-circle purple" flex v-center h-center>
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}" loaded
                      (loaded)="(inx==skilllowattr.length-1)? blockHeight():''">
                  </div>
                  <div class="img-title m-auto text-center">
                    <p>{{attr.title | titlecase}}</p>
                  </div>
                </li>
              </ul>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="tabs-status-tabs" ngStyle.md="display:none;" ngStyle.lg="display:none;" ngStyle.xl="display:none;">
        <h4 class="qp-career-tab-heading"> {{buttonsText.dictionary.group}}</h4>
        <div fxLayout="row" fxFlexFill class="common-row-sub career-group" fxLayoutGap="10px" fxLayoutWrap="wrap">
          <div fxFlex="" ngStyle.sm="margin-top:5px;">
            <mat-card class="card h-100">
              <div flex v-center h-center class="career-card">
                <img mr-10 src="{{assetsUrl}}{{scoutIconName}}.svg" alt="">
                <p m-0>{{scoutIconText}}</p>
              </div>
            </mat-card>
          </div>
        </div>
        <h4 class="qp-career-tab-heading">{{buttonsText.dictionary.workValues}}</h4>
        <div fxLayout="row" fxFlexFill fxLayoutGap="5px" class="navigator-group">
          <div fxFlex="" *ngFor="let item of navigatorAttributes">
            <mat-card class="card" m-0>
              <div flex flex-column v-center h-center>
                <div class="group-work-card">
                  <img src="./assets/Svg/{{item?.icon}}.svg" alt="">
                  <p m-0>{{item?.displayName}}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.characteristics}}</h4>
        <div class="tabs-card w-100">
          <mat-tab-group class="card qp-career-tab-body outline-yellow" #charTab>
            <!-- {{goToNextTabIndex()}} -->
            <mat-tab label="Tab 1" *ngIf="charhighattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button green" flex v-center h-center>
                  <span>{{buttonsText.dictionary.high}}</span>
                </div>
              </ng-template>

              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))
                           ||((charhighattr.length!=0)&&(charmediumattr.length!=0))
                           ||((charhighattr.length!=0)&&(charlowattr.length!=0))? 'hightab-class':''">
                <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                  <div class="circle-img green" *ngFor="let attr of charhighattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="assets/Svg/{{attr.icon}}.svg">
                  </div>
                  <!-- <div class="circle-img green">
                          <img src="./assets/images/bulb.png">
                        </div>
                        <div class="circle-img green">
                          <img src="./assets/images/bulb.png">
                        </div> -->
                </div>
              </div>

            </mat-tab>
            <mat-tab label="Tab 2" *ngIf="charmediumattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button red" flex v-center h-center>
                  <span>{{buttonsText.dictionary.medium}}</span>
                </div>
              </ng-template>

              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))? '': ((charhighattr.length!=0)&&(charmediumattr.length!=0))? 'lowtab-class':'hightab-class'">
                <div fxFlex="" flex h-center fxLayoutWrap="wrap">
                  <div class="circle-img orange" *ngFor="let attr of charmediumattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="assets/Svg/{{attr.icon}}.svg">
                  </div>
                </div>
              </div>

            </mat-tab>
            <mat-tab label="Tab 3" *ngIf="charlowattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button purple" flex v-center h-center>
                  <span>{{buttonsText.dictionary.low}}</span>
                </div>
              </ng-template>

              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))
                           ||((charlowattr.length!=0)&&(charmediumattr.length!=0))
                           ||((charhighattr.length!=0)&&(charlowattr.length!=0))? 'lowtab-class':''">
                <div fxFlex="" flex h-center fxLayoutWrap="wrap">
                  <div class="circle-img purple" *ngFor="let attr of charlowattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="assets/Svg/{{attr.icon}}.svg">
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>



        </div>

        <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.personalityTypes}}</h4>
        <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="10px">
          <div fxFlex="" *ngFor="let attr of pathfinderattributes">
            <mat-card class="card h-100 card qp-career-tab-body outline-sky-blue">
              <div flex v-center h-center p-5>
                <img class="image-size" src="assets/pathfinder/holland/{{attr.icon}}.svg" alt="img-quick">
                <p m-0>{{attr.displayName}}</p>
              </div>
            </mat-card>
          </div>
        </div>

        <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.skills}}</h4>
        <div class="tabs-card w-100">
          <mat-tab-group class="card qp-career-tab-body outline-pink" #skillTab (cdkObserveContent)="onTabClick()">
            <mat-tab label="Tab 1" *ngIf="skillhighattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button green" flex v-center h-center>
                  <span>{{buttonsText.dictionary.high}}</span>
                </div>
              </ng-template>

              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                ||((skillhighattr.length!=0)&&(skillmediumattr.length!=0))
                ||((skillhighattr.length!=0)&&(skilllowattr.length!=0))? 'hightab-class':''">
                <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                  <div class="circle-img green" *ngFor="let attr of skillhighattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Tab 2" *ngIf="skillmediumattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button red" flex v-center h-center>
                  <span>{{buttonsText.dictionary.medium}}</span>
                </div>
              </ng-template>
              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))? '': ((skillhighattr.length!=0)&&(skillmediumattr.length!=0))? 'lowtab-class':'hightab-class'">
                <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                  <div class="circle-img orange" *ngFor="let attr of skillmediumattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Tab 3" *ngIf="skilllowattr.length!=0">
              <ng-template mat-tab-label h-center flex>
                <div class="tab-button purple" flex v-center h-center>
                  <span>{{buttonsText.dictionary.low}}</span>
                </div>
              </ng-template>
              <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                ||((skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                ||((skillhighattr.length!=0)&&(skilllowattr.length!=0))? 'lowtab-class':''">
                <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                  <div class="circle-img purple" *ngFor="let attr of skilllowattr"
                    matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                    <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>


        </div>


      </div>


    </div>
  </mat-card>
</div>
<div class="mobile-design-show">
  <mat-horizontal-stepper>
    <mat-step>
      <!-- <h2 text-color="primary" font-normal ngStyle.xs="text-align:center;padding: 0 15px">{{keyWords.subHeader}}</h2> -->
      <mat-card class="card">
        <mat-card-title class="card-header" background-color="primary" ngStyle.xs="font-weight:bold;">
          {{buttonsText.dictionary.occupations}}</mat-card-title>
        <mat-card-content class="card-body text-center"
          ngStyle.xs="height: calc(60vh - 30px);overflow-y: auto;overflow-x: hidden;;padding: 0 10px;">
          <mat-list role="list">
            <mat-list-item *ngFor="let occ of occs; let i = index;" role="listitem"
              ngStyle.xs="height:50px;border-bottom:1px solid #ccc;" text-color="primary">
              <div class="text-center w-100" font-bold (click)="defaultdata=undefined;defaultdata=occ;attributesCall()">
                <button mat-button matStepperNext>{{occ.title}}</button>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </mat-step>
    <mat-step>
      <div flex v-center p-5 *ngIf="defaultdata!=undefined">
        <div class="second-block" #statusCardHeight>
          <div flex v-center>
            <h2 text-color="primary" mb-0 mr-10>{{defaultdata.title}}</h2>
            <button mat-button color="primary" (click)="modalOpen()" class="readmore-button" flex v-center h-center>
              <i class="material-icons">remove_red_eye</i>
              <span font-bold>{{buttonsText.dictionary.readMore}}</span>
            </button>
          </div>
          <p class="w-100" m-0>{{defaultdata.description}}</p>
          <div class="tabs-status-tabs" ngStyle.md="display:none;" ngStyle.lg="display:none;" ngStyle.xl="display:none;">
            <h4 class="qp-career-tab-heading"> {{buttonsText.dictionary.group}}</h4>
            <div fxLayout="row" fxFlexFill class="common-row-sub career-group" fxLayoutGap="10px" fxLayoutWrap="wrap">
              <div fxFlex="" ngStyle.sm="margin-top:5px;">
                <mat-card class="card h-100">
                  <div flex v-center h-center class="career-card">
                    <img *ngIf="scoutIconName" mr-10 src="{{assetsUrl}}{{scoutIconName }}.svg" alt="">
                    <p m-0>{{scoutIconText}}</p>
                  </div>
                </mat-card>
              </div>
            </div>
            <h4 class="qp-career-tab-heading">{{buttonsText.dictionary.workValues}}</h4>
            <div fxLayout="row" fxFlexFill fxLayoutGap="5px" class="navigator-group">
              <div fxFlex="" *ngFor="let item of navigatorAttributes">
                <mat-card class="card" m-0>
                  <div flex flex-column v-center h-center>
                    <div class="group-work-card">
                      <img src="./assets/Svg/{{item?.icon}}.svg" alt="">
                      <p m-0>{{item?.displayName}}</p>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.characteristics}}</h4>
            <div class="tabs-card w-100">
              <mat-tab-group class="card qp-career-tab-body outline-yellow" #charTab>
                <!-- {{goToNextTabIndex()}} -->
                <mat-tab label="Tab 1" *ngIf="charhighattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button green" flex v-center h-center>
                      <span>{{buttonsText.dictionary.high}}</span>
                    </div>
                  </ng-template>
    
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))
                               ||((charhighattr.length!=0)&&(charmediumattr.length!=0))
                               ||((charhighattr.length!=0)&&(charlowattr.length!=0))? 'hightab-class':''">
                    <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                      <div class="circle-img green" *ngFor="let attr of charhighattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="assets/Svg/{{attr.icon}}.svg">
                      </div>
                      <!-- <div class="circle-img green">
                              <img src="./assets/images/bulb.png">
                            </div>
                            <div class="circle-img green">
                              <img src="./assets/images/bulb.png">
                            </div> -->
                    </div>
                  </div>
    
                </mat-tab>
                <mat-tab label="Tab 2" *ngIf="charmediumattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button red" flex v-center h-center>
                      <span>{{buttonsText.dictionary.medium}}</span>
                    </div>
                  </ng-template>
    
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                    [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))? '': ((charhighattr.length!=0)&&(charmediumattr.length!=0))? 'lowtab-class':'hightab-class'">
                    <div fxFlex="" flex h-center fxLayoutWrap="wrap">
                      <div class="circle-img orange" *ngFor="let attr of charmediumattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="assets/Svg/{{attr.icon}}.svg">
                      </div>
                    </div>
                  </div>
    
                </mat-tab>
                <mat-tab label="Tab 3" *ngIf="charlowattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button purple" flex v-center h-center>
                      <span>{{buttonsText.dictionary.low}}</span>
                    </div>
                  </ng-template>
    
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((charhighattr.length!=0)&&(charmediumattr.length!=0)&&(charlowattr.length!=0))
                               ||((charlowattr.length!=0)&&(charmediumattr.length!=0))
                               ||((charhighattr.length!=0)&&(charlowattr.length!=0))? 'lowtab-class':''">
                    <div fxFlex="" flex h-center fxLayoutWrap="wrap">
                      <div class="circle-img purple" *ngFor="let attr of charlowattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="assets/Svg/{{attr.icon}}.svg">
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.personalityTypes}}</h4>
            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="10px">
              <div fxFlex="" *ngFor="let attr of pathfinderattributes">
                <mat-card class="card h-100 card qp-career-tab-body outline-sky-blue">
                  <div flex v-center h-center p-5>
                    <img class="image-size" src="assets/pathfinder/holland/{{attr.icon}}.svg" alt="img-quick">
                    <p m-0>{{attr.displayName}}</p>
                  </div>
                </mat-card>
              </div>
            </div>
            <h4 class="qp-career-tab-heading" m-0>{{buttonsText.dictionary.skills}}</h4>
            <div class="tabs-card w-100">
              <mat-tab-group class="card qp-career-tab-body outline-pink" #skillTab (cdkObserveContent)="onTabClick()">
                <mat-tab label="Tab 1" *ngIf="skillhighattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button green" flex v-center h-center>
                      <span>{{buttonsText.dictionary.high}}</span>
                    </div>
                  </ng-template>
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                    ||((skillhighattr.length!=0)&&(skillmediumattr.length!=0))
                    ||((skillhighattr.length!=0)&&(skilllowattr.length!=0))? 'hightab-class':''">
                    <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                      <div class="circle-img green" *ngFor="let attr of skillhighattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Tab 2" *ngIf="skillmediumattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button red" flex v-center h-center>
                      <span>{{buttonsText.dictionary.medium}}</span>
                    </div>
                  </ng-template>
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                    [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))? '': ((skillhighattr.length!=0)&&(skillmediumattr.length!=0))? 'lowtab-class':'hightab-class'">
                    <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                      <div class="circle-img orange" *ngFor="let attr of skillmediumattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Tab 3" *ngIf="skilllowattr.length!=0">
                  <ng-template mat-tab-label h-center flex>
                    <div class="tab-button purple" flex v-center h-center>
                      <span>{{buttonsText.dictionary.low}}</span>
                    </div>
                  </ng-template>
                  <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px" [ngClass]="((skillhighattr.length!=0)&&(skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                    ||((skillmediumattr.length!=0)&&(skilllowattr.length!=0))
                    ||((skillhighattr.length!=0)&&(skilllowattr.length!=0))? 'lowtab-class':''">
                    <div fxFlex="" flex h-center v-center fxLayoutWrap="wrap">
                      <div class="circle-img purple" *ngFor="let attr of skilllowattr"
                        matTooltip="{{attr.title | titlecase}}" matTooltipClass="custom-tooltip">
                        <img src="{{assetsUrl}}{{attr.icon}}.svg" alt=" {{attr.title}}">
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-design-show" float-right>
        <button mat-fab color="primary" (click)="quickpicMobileList({action:'nextNav',index:0})"
          ngStyle.xs="position:absolute;right:2px;bottom:-2px;z-index:9;">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <!-- <button mat-button matStepperPrevious>Back</button> -->
    </mat-step>
  </mat-horizontal-stepper>

</div>