import { Component, OnInit } from "@angular/core";
import { Next } from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";

import {
  AttributeDataState,
  PageDataState,
  AudioState,
  NavigatorWorkValuesState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-navigator-intro",
  templateUrl: "./navigator-intro.component.html",
  animations: [Next],
})
export class NavigatorIntroComponent implements OnInit {
  completed = true;
  compActive = true;
  pageData: any;
  attributeData: any;
  pageCode: string;
  keywords: any;
  theme_name: string;
  cardsData: any;
  lang = "";
  headerAudioState = "stoped";
  headerAudionotfound = false;
  curAudio: string;

  constructor(
    public router: Router,
    private store: Store<PageDataState>,
    private navigatorWorkValuesState: Store<NavigatorWorkValuesState>,
    private attrStore: Store<AttributeDataState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_INTRO" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/intro") {
          if (e.type === "languageChanged") {
            // this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
            this.lang = sessionStorage.getItem("session_token");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        			if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });
  }

  ngOnInit() {
    this.dialogService.showLoading();
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.pageData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_INTRO"
          );
        }
      });
      this.attributeDataCall();
      this.setNavigatorIntroTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  clickRoute() {
    this.navigatorWorkValuesState.dispatch({
      type: 'NAVIGATOR_WORK_VAL'
      , payload: {
        navigatorWorkValuesData: {
          page1List: [],
          page2List: []
        }
      }
    });
    this.router.navigate(["../activity"], { relativeTo: this.activatedRoute });
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
    // this.router.navigateByUrl('/app/landing');
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_INTRO");
      }.bind(this)
    );
  }

  attributeDataCall() {
    this.oninitpopupservice.pageCallText(this.lang, "attributes/value");
    this.attrStore
      .select("attribute")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((res) => {
        const data = res.attribute;
        if (data[0]) {
          this.cardsData = [];
          data.forEach((element) => {
            if (element.type == "value") {
              this.cardsData.push(element);
            }
          });
        }
        if (this.cardsData && this.cardsData.length>0){
          this.dialogService.hideLoading();
       }
      });
  }
  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  /*end of logic  for buttons two show and to add those to array*/

  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setNavigatorIntroTitle(){
    this.titleService.setTitle("Navigator Intro - CareerTrek");
  }
}
