<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading" *ngIf="l>0">
      {{trekkerTopSkills?.selectTop2ImproveSkills}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" (click)="audioClick('inside',pagerespImpAt)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',pagerespImpAt)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
      </button> -->
    </h4>
    <h4 class="landing-page-heading" *ngIf="l==0">
      {{trekkerTopSkills?.selectTop2Skills}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" (click)="audioClick('inside',pagerespGoodAt)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',pagerespGoodAt)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="content-box career-trekker-top-skills">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px">
        <div fxFlex.md="35%" fxFlex.lg="35%" fxFlex.xl="35%" fxFlex.sm="58%" class="selecting-cards" flex flex-column>
          <h3 class="career-treker-heading" *ngIf="l>0" background-color="primary">"{{keywords?.dictionary?.needToImprove}}" {{keywords?.dictionary?.skills}}</h3>
          <h3 class="career-treker-heading" *ngIf="l==0" background-color="primary">"{{keywords?.dictionary?.goodAt}}" {{keywords?.dictionary?.skills}}</h3>
          <div fxLayout="row" class="common-row-sub" fxLayoutWrap="wrap" fxLayoutAlign="left">
            <div fxFlex="49%" *ngFor="let a of cards;let inx = index">
              <mat-card class="card text-center" pointer tabindex="0" (click)="cardSelected(a, inx)" (keyup.enter)="cardSelected(a, inx)"
                flex v-center h-center>
                <div class="w-100">
                  <div flex v-center h-center>
                    <img src="{{assetsUrl}}{{a?.icon}}.svg" alt="{{a?.title}}">
                    <i *ngIf="a?.icon==cardsSelected[0]?.icon || a?.icon==cardsSelected[1]?.icon" class="material-icons success-tick">done</i>
                  </div>
                  <p m-0 text-color="primary" font-bold class="text-center w-100 ">{{a?.title}}</p>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <div fxFlex.md="35%" fxFlex.lg="35%" fxFlex.xl="35%" fxFlex.sm="40%" class="top-two-cards">
          <div class="desktop-design-show h-100">
            <mat-card class="selected-box h-100">
              <div class="selected-box-header">
                <h3 class="career-treker-heading" text-color="primary" *ngIf="l==0 && cardsSelected[1]?.icon!=''">{{trekkerTopSkills?.top2SkillsSelected}}</h3>
                <h3 class="career-treker-heading" text-color="primary" *ngIf="l>0 && cardsSelected[1]?.icon!=''">{{trekkerTopSkills?.top2ImproveSkills}}</h3>
              </div>
              <div class="selected-cards" *ngFor="let selected of cardsSelected;let i= index">
                <mat-card class="card" flex h-center flex-column>
                  <div class="" flex v-center h-center *ngIf="selected.icon!=''">
                    <img src="{{assetsUrl}}{{selected?.icon}}.svg" alt="{{selected?.title}}">
                  </div>
                  <p m-0 class="text-center" font-bold text-color="primary" *ngIf="selected?.icon!=''">{{selected?.title}}</p>
                  <p m-0 class="text-center h-100" font-bold flex v-center h-center text-color="primary" *ngIf="selected.icon==''">{{keywords?.dictionary?.skill}} {{i+1}}</p>
                </mat-card>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div *ngIf="cardsSelected[1]?.title!=''" class="desktop-design-show">
        <button class="next-level dark-pink" mat-fab color="primary" [@flipAnimation]="completed" (click)="clickNext('desktopRes')">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <div *ngIf="cardsSelected[1]?.title!=''" class="mobile-design-show">
        <button class="next-level dark-pink" mat-fab color="primary" [@flipAnimation]="completed" (click)="clickNext('mobileRes')">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>