import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { PageDataState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-explorer-information',
    templateUrl: './explorer-information.component.html',
    styleUrls: []
})
export class ExplorerInformationComponent implements OnInit, OnDestroy {
    categoryId: any;
    name: any;
    languageKey: String;
    modeldata: any;
    title: 'ExplorerInformationComponent';
    data = [];
    lang = '';
    theme_name = '';
    compActive = true;
    constructor(private _location: Location, public dialogService: DialogopenService,
        public eventService: EventdispatchService,
        private apiCall: ApiCallService,
        private apiModel: ApiCallClass, private activatedRoute: ActivatedRoute,
        private store: Store<PageDataState>, private router: Router,
        private oninitpopupservice: OnLoadPopUpService, private utils: Utilities) {
        this.lang = sessionStorage.getItem('session_token');
        this.infopopup();
        eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
            if (sessionStorage.getItem('pathurl') == ('/app/explorer/information')) {
                if (e.type === 'languageChanged') {
                    this.languageKey = this.modeldata.language;
                    this.lang = sessionStorage.getItem('session_token');
                    this.ngOnInit();
                }
                if (e.type === 'themeChanged') {
                    this.theme_name = sessionStorage.getItem('theme_color');
                }
                if (e.type === 'infoModal') {
                    if (sessionStorage.getItem('infopopup') == 'true') {
                        this.infopopup();
                        sessionStorage.setItem('infopopup', 'false');
                    }
                }
                if (e.type === 'backClicked') {
                    this.unsavedChanges();
                }
            }
        });

        if (this.lang === '' || this.lang == null) {
            this.lang = sessionStorage.getItem('session_token');
        }
    }
    infopopup() {
        Promise.resolve().then(function() {
			this.oninitpopupservice.getOnLoadPopUp('EXPLORER_' + this.name.toUpperCase());
		}.bind(this));
    }
    getapidata() {
        this.apiModel.moduleName = 'explorer';
        if (sessionStorage.getItem('explorer_' + this.categoryId) == 'completed') {
            this.apiModel.endUrl = 'occs/category/random/' + this.categoryId + '/3';
        } else {
            this.apiModel.endUrl = 'occs/category/default/' + this.categoryId;
        }
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'GET';
        try {
            this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                if (resp != undefined && resp != null) {
                    this.data = JSON.parse(resp);
                }
            });
        } catch (e) {
            this.utils.handleError(`explorer-information.component.ts getapidata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }
    ngOnInit() {
        this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
            this.name = params['introname'];
            this.categoryId = params['id'];
            if (this.categoryId == '2') {
                this.name = 'ideas';
            } else if (this.categoryId == '1') {
                this.name = 'people';
            } else {
                this.name = 'things';
            }
            if (params['completed'] == 'true') {
                sessionStorage.setItem('explorer_' + params['id'], 'completed');

            }
            this.getapidata();
             this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                if (resp.pages != undefined && resp.pages[0] != null) {
                    const data = (resp.pages);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].pageCode === 'EXPLORER_' + this.name.toUpperCase()) {
                            this.modeldata = data[i];
                        }
                    }
                }
                if (this.data[0] != undefined) {
                    if (this.data[0].language != this.languageKey && this.modeldata.language != this.languageKey) {
                        this.languageKey = this.modeldata.language;
                        this.dialogService.hideLoading();
                    }
                }
            });
        });
    }
    taskroute() {
        this.router.navigateByUrl('/app/explorer/task');
        try {
            this.router.navigate(['/app/explorer/task'], {
                relativeTo: this.activatedRoute,
                queryParams: {
                    category: this.categoryId,
                    occId: this.data[0].occId,
                    data: this.data[0].occId + '&' + this.data[1].occId + '&' + this.data[2].occId
                }
            });
        } catch (e) {
            this.utils.handleError(`explorer-information.component.ts taskroute: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    unsavedChanges() {
        this.utils.backRoute();
    }
    ngOnDestroy() {
        this.compActive = false;
    }
}
