<div class="Career-trek-content">
  <div>
    <h4 class="landing-page-heading">
      {{keyWords.subHeader}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
        (click)="audioClick('inside',keyWords)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="quickpic-result content-box">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutGap.xs="5px">
        <div fxFlex="25%" fxFlex.md="24%" fxFlex.sm="40%" class="desktop-design-show">
          <mat-card class="card h-100">
            <ul class="side-task-list list-style-none list-scroll-card" m-0 p-0
              [ngStyle.lg]="{'height': blockTotalHeight+'px'}">
              <li *ngFor="let occ of occs;let inx=index">
                <div class="image-card" pointer [ngClass]="occ.occId==defaultdata.occId ? 'active':''"
                  (click)="defaultdata=undefined;defaultdata=occ;attributesCall()">
                  <!-- <img src="./assets/images/man.jpg">-->
                  <div class="image-border">
                    <img src="{{occCardAPIUrl}}{{occ.media?.images.card}}" loaded />
                  </div>
                  <div class="opacity-text quickpic-result-text">
                    <p m-0 flex v-center h-center>
                      <span>{{occ.title}}</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </mat-card>
        </div>

        <div *ngIf="defaultdata">
          <quickpic-body [charmediumattr]="charmediumattr" [charlowattr]="charlowattr" [charhighattr]="charhighattr"
            [skilllowattr]="skilllowattr" [skillmediumattr]="skillmediumattr" [skillhighattr]="skillhighattr"
            [defaultdata]="defaultdata" [buttonsText]="buttonsText" 
            [pathfinderattributes]="pathfinderattributes" [navigatorAttributes] = "navigatorWorkValuesSet" 
            [scoutIconText] = "scoutIconText"
            [scoutIconName] = "scoutIconName"
            [occsData]="occs" (heightChangeEmit)="heightCalculation($event)" (quicpicOccChangeEmit)="nextData($event)">
          </quickpic-body>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <button mat-button routerLink="/quickpic/result">route to result</button> -->