<div class="Career-trek-content">
    <div class="container">
        <h4 class="landing-page-heading" *ngIf="cardsSelected[1].src==''">
            {{pageData.header}}
            <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
                (click)="audioClick('inside', pageData)">
            </app-audio-button>
        </h4>
        <h4 class="landing-page-heading" *ngIf="cardsSelected[1].src!=''">
            {{tieselectedPagedata.header}}
            <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
                (click)="audioClick('inside', tieselectedPagedata)">
            </app-audio-button>
        </h4>

    </div>
    <div class="container">
        <div class="top-skills-layout navigator-tie content-box">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" d-block-mobile>
                <div fxFlex="30%" fxFlex.sm="40%" fxFlex.xs="100%" flex-basis>
                    <div class="select-cards">
                        <h4 class="mobile-design-show text-center">
                            <div *ngIf="this.secdItem>1">
                                <button mat-fab color="accent" class="next-level light-red" (click)="clickNext()"
                                    [@flipAnimation]="completed">
                                    <i class="material-icons">arrow_forward</i>
                                </button>
                            </div>
                        </h4>
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap="wrap" d-block ngStyle.sm="display:block;"
                            d-flex-mobile>
                            <div fxFlex="49%" *ngFor="let card of page1List; let i = index">
                                <mat-card (click)="cardSelected(card,i)" (keyup.enter)="cardSelected(card,i)"
                                    class="card card-border" pointer tabindex="0" flex v-center h-center>
                                    <div class="nav-tie-list">
                                        <div flex v-center h-center>
                                            <div class="desktop-design-show">
                                                <img src="{{ card.src }}" class="w-50" />
                                                <img *ngIf="card.src==cardsSelected[0].src || card.src==cardsSelected[1].src"
                                                    src="{{assetsURL}}thumbs-up-outline-grey.svg"
                                                    class="success-tick no-background" alt="thumb-up icon" />
                                            </div>
                                            <div class="mobile-design-show">
                                                <img src="{{ card.src }}" class="selectable-icon" />
                                                <img *ngIf="card.src==cardsSelected[0].src || card.src==cardsSelected[1].src"
                                                    src="{{assetsURL}}thumbs-up-outline-grey.svg"
                                                    class="success-tick no-background" alt="thumb-up icon" />
                                            </div>
                                        </div>
                                        <p m-0 class="text-center" text-color="primary">
                                            {{ card.title }}
                                        </p>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="70%" fxFlex.xs="100%" flex h-center>
                    <div class="two-cards w-100" flex v-center>
                        <div class="desktop-design-show w-100">
                            <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
                                <div fxFlex="45%" flex-basis *ngFor="let data of cardsSelected;let i = index">
                                    <mat-card class="card navigator-two-tiecards">
                                        <mat-card-title *ngIf="data.src!=''" class="card-header"
                                            background-color="primary">{{data.title}}
                                        </mat-card-title>
                                        <mat-card-title class="card-header" background-color="primary"
                                            *ngIf="data.title==''">{{i+1}}
                                        </mat-card-title>
                                        <mat-card-content class="card-body text-center" flex v-center h-center>
                                            <img src="{{data.src}}" />
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="desktop-design-show" *ngIf="cardsSelected[1].src!=''">
                <button mat-fab color="accent" class="next-level light-red" (click)="clickNext()"
                    [@flipAnimation]="completed">
                    <i class="material-icons">arrow_forward</i>
                </button>
            </div>
        </div>
    </div>
</div>