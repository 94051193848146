export interface LogOutPostData {
  ssoUserId: string;
  authType: string;
  ssoDistrictId: string;
  logOutId: number;
  stateAbbr: string;
  uNumber: number;
}

export interface LogoutResponse {
  statusCode: number;
  message: string;
  errorMsgTrace: ErrMessages;
  isSuccessful: boolean;
}

export interface ErrMessages {
  errorMessage: string;
  errorStackTrace: string;
}
