<div class="Career-trek-content">
  <div class="container content-inner-header">
    <div class="text-right qp-save-btn">
      <div class="save-btn desktop-design-show" *ngIf="startIndex!=0">
        <button
          mat-raised-button
          color="primary"
          tabindex="0"
          (click)="partialSave()"
          class=""
        >
          {{viewText.get('save')}}
        </button>
      </div>
      <div
        class="w-100 mobile-design-show save-btn qp-save-btn"
        tabindex="7"
        *ngIf="startIndex!=0"
      >
        <button
          mat-raised-button
          color="primary"
          float-right
          (click)="partialSave()"
        >
          {{viewText.get('save')}}
        </button>
      </div>
    </div>
    <div>
      <h4 class="landing-page-heading">
        {{asessmentTitle?.pageText?.sections[0]?.title}}
        <app-audio-button
          [audionotfound]="audionotfound"
          [audioState]="headerAudioState"
          (click)="audioClick('inside',asessmentTitle,'headerAudio')"
        ></app-audio-button>
        <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
          [disabled]="audionotfound==true && headerAudioState=='warning'" 
          (click)="audioClick('inside',asessmentTitle,'headerAudio')">
          <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='stoped'">volume_up</i>
          <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='playing'">stop</i>
          <div class="audio-loading" *ngIf="audionotfound==false && headerAudioState=='loading'"></div>
          <i class="material-icons" *ngIf="audionotfound==true && headerAudioState=='warning'">warning</i>
        </button> -->
      </h4>
    </div>
  </div>
  <div class="container">
    <div class="quickpic-questions content-box">
      <div class="assessment-content-card" *ngIf="questions!=undefined">
        <div>
          <div
            class="question-card"
            background-color="primary"
            flex
            v-center
            ngStyle.lg="display:none"
            ngStyle.xl="display:none"
          >
            <div class="circle-icon" flex v-center background-color="accent">
              <img
                src="./assets/images/baby_CCI.png"
                alt="quickpic_logo"
                class="m-auto"
              />
            </div>
            <p
              m-0
              *ngIf="questions.questions!=undefined"
              class="text-center w-100"
              font-bold
            >
              {{questions.questions[startIndex].text}}
              <app-audio-button
                [audionotfound]="audionotfound"
                [audioState]="quesAudioState"
                (click)="audioClick('inside',questions.questions[startIndex],'quesAudio')"
              ></app-audio-button>
              <!-- <button mat-raised-button class="audio-box volume-up"
                [disabled]="audionotfound==true && quesAudioState=='warning'" 
        (click)="audioClick('inside',questions.questions[startIndex],'quesAudio')">
        <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && quesAudioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && quesAudioState=='warning'">warning</i>
              </button> -->
            </p>
            <div
              *ngIf="nextIcon == 'yes'"
              class="mobile-design-show"
              style="position: absolute; right: 0px"
            >
              <button
                mat-fab
                class="next-level"
                color="primary"
                [@flipAnimation]="completed"
                *ngIf="optionId!=10"
                (click)="nextQuestion(questions.questions[startIndex].questionId,optionId)"
                ngStyle.xs="right:0;margin: -16px 0;"
              >
                <i class="material-icons">arrow_forward</i>
              </button>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="5px">
          <div fxFlex="" fxFlex.sm="49%" class="">
            <div class="question-section">
              <div
                class="question-card desktop-design-show"
                background-color="primary"
                flex
                v-center
                ngStyle.md="display:none;"
                ngStyle.sm="display:none;"
              >
                <div
                  class="circle-icon"
                  flex
                  v-center
                  background-color="accent"
                >
                  <img
                    src="./assets/images/baby_CCI.png"
                    alt="quickpic_logo"
                    class="m-auto"
                  />
                </div>
                <p
                  m-0
                  *ngIf="questions.questions!=undefined"
                  class="text-center w-100"
                  font-bold
                  ngStyle.xs="width:70%;"
                >
                  {{questions.questions[startIndex].text}}
                  <app-audio-button
                    [audionotfound]="audionotfound"
                    [audioState]="quesAudioState"
                    (click)="audioClick('inside',questions.questions[startIndex],'quesAudio')"
                  ></app-audio-button>
                  <!-- <button mat-raised-button class="audio-box volume-up"
                    [disabled]="audionotfound==true && quesAudioState=='warning'" 
                    (click)="audioClick('inside',questions.questions[startIndex],'quesAudio')">
                    <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='stoped'">volume_up</i>
                    <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='playing'">stop</i>
                    <div class="audio-loading" *ngIf="audionotfound==false && quesAudioState=='loading'"></div>
                    <i class="material-icons" *ngIf="audionotfound==true && quesAudioState=='warning'">warning</i>
                  </button> -->
                </p>
                <div
                  *ngIf="nextIcon == 'yes'"
                  class="mobile-design-show"
                  style="position: absolute; right: 0px"
                >
                  <button
                    mat-fab
                    class="next-level"
                    color="primary"
                    [@flipAnimation]="completed"
                    *ngIf="optionId!=10"
                    (click)="nextQuestion(questions.questions[startIndex].questionId,optionId)"
                    ngStyle.xs="right:0;margin: -16px 0;"
                  >
                    <i class="material-icons">arrow_forward</i>
                  </button>
                </div>
              </div>

              <ul
                p-0
                class="list-style-none w-100 smile-icons"
                m-0
                fxLayout="row"
                fxLayoutWrap="wrap"
              >
                <li m-0 *ngFor="let options of questions.answers" fxFlex="48%">
                  <i
                    class="material-icons w-100 text-center check-tick"
                    font-bold
                    flex
                    v-center
                    h-center
                    *ngIf="optionId==options.value"
                    >check</i
                  >
                  <button
                    class="circle-icon extra-css"
                    d-block-mobile
                    mat-icon-button
                    pointer
                    [class.active]="optionId==options.value"
                    flex
                    h-center
                    v-center
                    tabindex="options.value+1"
                    (click)="optionId=options.value;optionSelection()"
                  >
                    <i
                      class="material-icons smileys"
                      text-color="primary"
                      *ngIf="options.value==3"
                    >
                      mood
                    </i>
                    <i
                      class="material-icons smileys"
                      text-color="primary"
                      *ngIf="options.value==2"
                    >
                      sentiment_satisfied
                    </i>
                    <i
                      class="material-icons smileys"
                      text-color="primary"
                      *ngIf="options.value==1"
                    >
                      sentiment_very_dissatisfied
                    </i>
                    <i
                      class="material-icons smileys"
                      text-color="primary"
                      *ngIf="options.value==0"
                    >
                      mood_bad
                    </i>
                  </button>
                  <p class="text-center" m-0 font-bold>{{options.text}}</p>
                </li>
                <div class="desktop-design-show" *ngIf="nextIcon == 'yes'">
                  <button
                    mat-fab
                    class="next-level"
                    [@flipAnimation]="completed"
                    color="primary"
                    *ngIf="optionId!=10"
                    (click)="nextQuestion(questions.questions[startIndex].questionId,optionId)"
                  >
                    <i class="material-icons">arrow_forward</i>
                  </button>
                </div>
              </ul>

              <!-- <div class="mobile-design-show" flex v-center>
                <p flex v-center class="w-100 qp-purple-clr" m-0>
                  <i class="material-icons">chevron_left</i> &nbsp;Q.{{startIndex+1}}</p>
                <p class="qp-purple-clr" m-0 *ngIf="questions.questions!=undefined">{{this.questions.questions.length - (startIndex+1)}}&nbsp;{{keywords?.dictionary?.remaining}}</p>
              </div> -->
            </div>
          </div>
          <div fxFlex="48%" flex v-center>
            <div class="image-frame">
              <img
                *ngIf="questions.questions!=undefined"
                src="{{questions.questions[startIndex].image}}"
                alt="bg-img-quickpick"
                class="w-100 bg-img-quickpick"
              />
            </div>
          </div>
        </div>
        <div>
          <ul class="score-data list-style-none" p-0 flex v-center>
            <li
              class="desktop-design-show"
              pointer
              tabindex="8"
              (click)="questionback()"
            >
              <p flex v-center>
                <i class="material-icons">chevron_left</i>
                &nbsp;Q.{{startIndex+1}}
              </p>
            </li>
            &nbsp;
            <li class="progress-bar">
              <mat-progress-bar
                mode="determinate"
                value="{{progress}}"
              ></mat-progress-bar>
            </li>
            &nbsp;
            <li
              class="desktop-design-show"
              *ngIf="questions.questions!=undefined"
            >
              <p>
                {{this.questions.questions.length -
                (startIndex+1)}}&nbsp;{{viewText.get('remaining')}}
              </p>
            </li>
          </ul>
          <div class="mobile-design-show" flex v-center>
            <p flex v-center class="w-100 qp-purple-clr" m-0>
              <i class="material-icons" (click)="questionback()"
                >chevron_left</i
              >
              &nbsp;Q.{{startIndex+1}}
            </p>
            <p class="qp-purple-clr" m-0 *ngIf="questions.questions!=undefined">
              {{this.questions.questions.length -
              (startIndex+1)}}&nbsp;{{viewText.get('remaining')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
