import { Component, Inject, OnInit } from '@angular/core';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { environment } from '../../../../environments/environment';
import { Next } from '../../../shared/common-animations/CT_animations';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { take, takeWhile } from 'rxjs/operators';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { Utilities } from "../../../shared/common-services/utilities.service";
import { Store } from '@ngrx/store';
import { AudioState, PageDataState, ScoutActivityTwoState } from '../../../state-management/state/main-state';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
import { ScoutContent } from '../scout-models/scout_model';

@Component({
  selector: 'app-scout-activity_two',
  templateUrl: './scout-activity_two.component.html',
  animations: [Next],
})
export class ScoutActivityTwoComponent implements OnInit {
  headerAudioState = 'stoped';
  headerAudionotfound = false;
  quesAudioState = 'stoped';
  quesAudionotfound = false;
  ansAudioState = ['stoped', 'stoped'];
  ansAudionotfound = [false, false];
  completed: boolean;
  nextIcon = '';
  assetsUrl: string;
  compActive = true;
  categories = [];
  selectedAnswers = [];
  categoryOption: ScoutContent;
  categoryTypes = ['People', 'Ideas', 'Things'];
  categoryLength = [];
  occUrl: string;
  indexValue = 0;
  scoutAssessText: any = {};
  resultData = [];
  keywords: any;
  theme_name: string;
  selectedRadio = '';
  curAudio: string;
  ansInx: any;
  languageChanged = '';
  completedQues = 0;
  constructor(
    public eventService: EventdispatchService,
    private apiModel: ApiCallClass,
    private oninitpopupservice: OnLoadPopUpService,
    private apiCall: ApiCallService,
    private utils: Utilities,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private scoutActivityTwoState: Store<ScoutActivityTwoState>,
    @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
    @Inject('OCC_CARD_URL') private occCardUrl: string,
    public dialogService: DialogopenService,
    private pagesData: Store<PageDataState>,
    private titleService: Title
  ) {
    this.languageChanged = sessionStorage.getItem('languageSet');
    if (!this.languageChanged) {
      this.languageChanged = 'en';
    }
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[7]);
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    sessionStorage.setItem('isModalActive', 'false');
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') === '/app/scout/activity_two') {
          if (e.type === 'languageChanged') {
            this.languageChanged = sessionStorage.getItem('languageSet');
            this.keywords = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            this.stopAudio();
            if (this.indexValue <= 0) {
              this.unsavedChanges();
            } else {
              this.indexValue--;
              this.completedQues--;
              this.selectedRadio = this.selectedAnswers[this.indexValue];
              this.categoryOption = this.categories[this.indexValue];
              this.nextIcon = 'yes';
            }
          }
        }
      });
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          if (this.curAudio === 'header') {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
            this.quesAudioState = 'stoped';
            this.quesAudionotfound = false;
            this.ansAudioState = ['stoped', 'stoped'];
            this.ansAudionotfound = [false, false];
          } else if (this.curAudio === 'question') {
            this.quesAudioState = result.audioState;
            this.quesAudionotfound = result.audionotfound;
            this.headerAudioState = 'stoped';
            this.headerAudionotfound = false;
            this.ansAudioState = ['stoped', 'stoped'];
            this.ansAudionotfound = [false, false];
          } else if (this.curAudio === 'answer') {
            this.ansAudioState[this.ansInx] = result.audioState;
            this.ansAudionotfound[this.ansInx] = result.audionotfound;
            this.headerAudioState = 'stoped';
            this.headerAudionotfound = false;
            this.quesAudioState = 'stoped';
            this.quesAudionotfound = false;
          }
        }
      });
    this.assetsUrl = assetsMediaUrl;
    this.occUrl = occCardUrl;
  }

  ngOnInit() {
    this.pagesData
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((response) => {
        if (response.pages) {
          const data = response.pages;
          this.scoutAssessText = data.find(
            (obj) => obj.pageCode === 'SCOUT_ASSESS'
          );
          this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
        }
      });

    this.getActivityThisOrThatDetails();

    this.setScoutActivityTwoTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  getActivityThisOrThatDetails() {
    this.apiModel.moduleName = 'scout';
    this.apiModel.endUrl = `ThisOrThat`;
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            this.categories = JSON.parse(resp);
            //here we are sorting the actual categories array based on thisOrThatId
            //for getting same order of data while doing language change
            this.categories.sort((a, b) =>
              a.thisOrThatId < b.thisOrThatId ? 1 : -1
            );
            this.categoryOption = this.categories[0];
            this.scoutActivityTwoState
              .select('scoutActivityTwoData')
              .pipe(take(1))
              .subscribe((storeresp) => {
                this.selectedAnswers =
                  storeresp['scoutActivityTwoData'].storedData;
                //coming back after completing activity two partially
                if (
                  this.selectedAnswers.length !== 0 &&
                  this.categories.length > this.selectedAnswers.length
                ) {
                  this.categoryOption = this.categories[this.indexValue];
                  this.selectedRadio = this.selectedAnswers[this.indexValue];
                  this.completedQues = this.selectedAnswers.length;
                } else if (
                  this.selectedAnswers.length == this.categories.length
                ) {
                  //coming back after completing activity two completely
                  this.indexValue = this.selectedAnswers.length - 1;
                  this.categoryOption = this.categories[this.indexValue];
                  this.selectedRadio = this.selectedAnswers[this.indexValue];
                  this.completedQues = this.selectedAnswers.length;
                  this.nextIcon = 'yes';
                }
              });
          }
        });
    } catch (e) {
      this.utils.handleError(`scout-activity_two.component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  addActivity(inxVal, optionKey) {
    if (this.selectedAnswers[inxVal] === optionKey) {
      //Here setTimeout is required without this empty value is not getting assigned
      setTimeout(() => (this.selectedRadio = ''), 100);
      this.selectedAnswers.splice(inxVal, 1);
      this.completedQues--;
      this.nextIcon = 'no';
    } else {
      this.nextIcon = 'yes';
      if (this.selectedAnswers[inxVal]) {
        this.selectedAnswers[inxVal] = optionKey;
      } else {
        this.selectedAnswers.push(optionKey);
      }
    }
    this.scoutActivityTwoState.dispatch({
      type: 'SCOUT_ACTIVITY_TWO_VAL',
      payload: {
        scoutActivityOneData: {
          storedData: this.selectedAnswers,
        },
      },
    });
  }
  clickRoute() {
    this.dialogService.showLoading();
    this.stopAudio();
    this.indexValue++;
    this.nextIcon = 'no';
    this.selectedRadio = '';
    // after checked and visited sum of careers and coming back to the current item to be checked
    this.completedQues++;
    this.categoryOption = this.categories[this.indexValue];
    if (this.selectedAnswers[this.indexValue]) {
      this.selectedRadio = this.selectedAnswers[this.indexValue];
      this.nextIcon = 'yes';
    }

    if (this.indexValue === this.categories.length) {
      this.categoryTypes.forEach((element) => {
        this.categoryLength.push(this.getCountOfOccurances(element));
      });

      let indexData: any = this.getMaxNumberIndex(this.categoryLength);
      this.resultData.push(this.categoryTypes[indexData.index]);
      this.categoryLength[indexData.index] = -1;

      if (this.categoryLength.indexOf(indexData.value) === -1) {
        this.apiModel.moduleName = 'scout';
        this.apiModel.endUrl = 'user/thisOrThat/result';
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'POST';
        this.apiModel.data = this.resultData;

        try {
          this.apiCall
            .getData([this.apiModel])
            .pipe(takeWhile(() => this.compActive))
            .subscribe((resp) => {
              if (resp) {
                this.dialogService.hideLoading();
                this.router.navigate(['../results'], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    category: indexData.index,
                  },
                });
              }
            });
        } catch (e) {
          this.utils.handleError(`scout-activity_two.component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      } else {
        this.dialogService.hideLoading();
        this.categoryLength[indexData.index] = indexData.value;

        const data = {
          elements: this.categoryLength,
          topRated: indexData.value,
        };

        this.router.navigate(['../tieBreaker'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            categoryData: JSON.stringify(data),
          },
        });
      }
    } else {
      this.dialogService.hideLoading();
    }
  }
  getOccurrence(array, value) {
    return array.filter((v) => v === value).length;
  }

  getCountOfOccurances(element) {
    let count = 0;
    count = this.selectedAnswers.filter((el) => {
      return el.indexOf(element) != -1;
    }).length;
    return count;
  }

  getMaxNumberIndex(categoryLen) {
    if (categoryLen.length === 0) {
      return -1;
    }

    var max = categoryLen[0];
    var maxIndex = 0;

    for (var i = 1; i < categoryLen.length; i++) {
      if (categoryLen[i] > max) {
        maxIndex = i;
        max = categoryLen[i];
      }
    }

    return { index: maxIndex, value: max };
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('SCOUT_ASSESS');
      }.bind(this)
    );
  }

  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  headerAudioClick(text, data) {
    this.curAudio = 'header';
    this.audioService.audioFunction(text, data);
  }
  quesAudioClick(text, data) {
    this.curAudio = 'question';
    this.audioService.audioFunction(text, data);
  }
  ansAudioClick(text, data, ansInx) {
    this.curAudio = 'answer';
    this.ansInx = ansInx;
    var data1 = [];
    data1[0] = data[0].src;
    data1[1] = data[1].src;
    this.ansAudioState = ['stoped', 'stoped'];
    this.ansAudionotfound = [false, false];
    this.audioService.audioFunction(text, data1, false, 'pipe');
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = 'stoped';
    this.headerAudionotfound = false;
    this.quesAudioState = 'stoped';
    this.quesAudionotfound = false;
    this.ansAudioState = ['stoped', 'stoped'];
    this.ansAudionotfound = [false, false];
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }
  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setScoutActivityTwoTitle() {
    this.titleService.setTitle('Scout Activity Two - CareerTrek');
  }
}
