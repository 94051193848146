import { Component, Inject, OnInit } from "@angular/core";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { Next } from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { takeWhile } from "rxjs/operators";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { AudioState, PageDataState } from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
import { ScoutTie } from '../scout-models/scout_model';
@Component({
  selector: "app-scout-tie-breaker",
  templateUrl: "./scout-tie.component.html",
  animations: [Next],
})

export class ScoutTieBreakerComponent implements OnInit {
  completed = true;
  assetsUrl: string;
  pages: any;
  compActive = true;
  categories = [];
  scoutHeader = [];
  theme_name: string;
  keywords: any;
  categoryTypes = ['People', 'Ideas', 'Things'];
  images:Array<string> = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
  scoutSharedTie: any;
  curAudio: string;
  headerAudioState = 'stoped';
  headerAudionotfound = false;
  subHeaderAudioState = 'stoped';
  subHeaderAudionotfound = false;
  langToken: string;
  scoutTie: ScoutTie;
  constructor(
    public eventService: EventdispatchService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private pagesData: Store<PageDataState>,
    private oninitpopupservice: OnLoadPopUpService,
    public store: Store<PageDataState>,
    private utils: Utilities,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string,
    private titleService: Title
  ) {
    this.assetsUrl = assetsMediaUrl;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[7]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/scout/tieBreaker") {
          if (e.type === "languageChanged") {
            this.langToken = sessionStorage.getItem('session_token');
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            this.unsavedChanges();
          }
        }
      });

    if (!this.langToken) {
      this.langToken = sessionStorage.getItem('session_token');
    }
    this.assetsUrl = assetsMediaUrl;
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
            this.subHeaderAudioState = "stoped";
            this.subHeaderAudionotfound = false;
          } else if (this.curAudio === "subheader") {
            this.subHeaderAudioState = result.audioState;
            this.subHeaderAudionotfound = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
          }
        }
      });
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.scoutTie = JSON.parse(params["categoryData"]);
      });
  }
  routeClick(category, inx) {
    let categoryTitle = "";
    this.categories.forEach((element, inx) => {
      if (element.title === category) {
        categoryTitle = this.categoryTypes[inx];
      }
    });
    this.apiModel.moduleName = 'scout';
    this.apiModel.endUrl = 'user/thisOrThat/result';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'POST';
    this.apiModel.data = [categoryTitle];
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
        if (resp) {
          this.router.navigate(['../results'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              'category': inx
            }
          });

        }

      });

    } catch (e) {
      this.utils.handleError(`scout-tie.component.ts routeClick: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  ngOnInit() {
    this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
      if (resp.pages) {
        const data = (resp.pages);
        this.pagesData = data;
        this.scoutSharedTie = data.find((obj => obj.pageCode === 'SCOUT_SHARED_TIE'));
      }
    });
    this.getCategoryList();

    this.setScoutTieTitle();
  }
  ngAfterViewInit() {
    this.infopopup()
  }

  getCategoryList() {
    this.apiModel.moduleName = "Favoccs";
    this.apiModel.endUrl = "/occs/categories";
    this.apiModel.method = "GET";
    this.apiModel.sessionID = this.langToken;
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.categories = JSON.parse(resp);
          if (this.categories) {
            this.categories.forEach((element, inx) => {
              element["icon"] = this.images[inx];
            });
          
            //leave top rated category items
            for (let i = 0; i < this.scoutTie.elements.length; i++) {
              if (this.scoutTie.elements[i] !== this.scoutTie.topRated) {
                this.categories.splice(i, 1);
                this.images.splice(i, 1);
              }
              
            }
        
          }
        });
    } catch (e) {
      this.utils.handleError(`scout-tie.component.ts getCategoryList: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  infopopup() {
    this.stopAudio();
    Promise.resolve().then(function () {
      this.oninitpopupservice.getOnLoadPopUp('SCOUT_SHARED_TIE');
    }.bind(this));
  }

  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  headeraudioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  subheaderAudioClick(text, data) {
    this.curAudio = "subheader";
    this.audioService.audioFunction(text, data, true);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.subHeaderAudioState = "stoped";
    this.subHeaderAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setScoutTieTitle(){
    this.titleService.setTitle("Scout Tie - CareerTrek");
  }
}
