
/**Import angular core packages */
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OccsDataState, AttributeDataState, PageDataState, PathwayOccViewedState, UserProfileState, PathwayOccViewedCareerState, OccThumbState, PathFinderChallengeState, SiteDataState, AudioState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { take, takeWhile } from 'rxjs/operators';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { debounceTime } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-pathfinder-result',
	templateUrl: './pathfinder-result-layout.html'
})

export class PathFinderResultComponent implements OnInit, OnDestroy {
	left: number = 0;
	right: number = 0;
	languageKey: any;
	keywords: any;
	theme_name: string;
	lang: string;
	second_icon: any;
	first_icon: any;
	second: any; // for html purpose hollandcode name
	first: any; // for html purpose hollandcode name
	sitedata: any;
	occdata = [];
	secondholland = [];
	indexOfshared = 0;
	firstholland = [];
	sharedoccs = [];
	resp: any;
	selectedIndex;
	attrdata = [];
	liked = [];
	a = 0; b = 0; c = 0;
	icons = [];
	occCardAPIUrl: string;
	pathFinderResult: any;
	compActive = true;
	assetsURL: string;
	viewedOccs: any;
	favourate = { 'occs': { 'evaluated': [] } };
	thumbFalse = [];
	thumbTrue = [];
	profileData: any;
	pathFinderModalpopupPayload: string;
	popupSelectData = {
		type: 'none',
		index: -1
	};
	careerItem: any;
	headerAudioState:string = 'stoped';
	headerAudionotfound:boolean = false;
	isModalActive: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		public eventService: EventdispatchService,
		private store: Store<AttributeDataState>,
		private pathwayOccViewedStore: Store<PathwayOccViewedState>,
		private userProfileStore: Store<UserProfileState>,
		private audioStore: Store<AudioState>, private audioService: AudioService,
		private pathwayOccViewedCareerStore: Store<PathwayOccViewedCareerState>,
		private occThumbStateStore: Store<OccThumbState>,
		private store1: Store<PageDataState>,
		private apiModel: ApiCallClass,
		private storeOccs: Store<OccsDataState>,
		private storeSites: Store<SiteDataState>,
		private pathFinderChallengeStore: Store<PathFinderChallengeState>,
		private apicallservice: ApiCallService,
		private oninitpopupservice: OnLoadPopUpService,
		private cdref: ChangeDetectorRef ,
		public dialogService: DialogopenService, private utils: Utilities,
		@Inject('OCC_CARD_URL') private occCardUrl: string,
		@Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
		private titleService: Title
	) {
		this.assetsURL = assetsUrl;
		this.occCardAPIUrl = occCardUrl;
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[1]);
		this.store1.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'PATHFINDER', pageCode: 'PATHFINDER_SHARED' } });
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		//listening to the customeEvent from Pathfinder-popup thumbs clicks
		this.eventService.listenPathFinderThumbUpDownEvent().pipe(
			debounceTime(1000),
			takeWhile(() => this.compActive)).subscribe((e) => {
				this.pathFinderModalpopupPayload = e.detail;
				if (this.pathFinderModalpopupPayload) {
					this.setmodalpopupData(this.pathFinderModalpopupPayload);
				}
			})


		// Created store for getting the viewed occ list 
		this.pathwayOccViewedStore.dispatch({
			type: 'GET_PATHWAY_OCC_VIEWED_LIST', payload: {
				methodVal: 'GET', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: '/user/occ/viewed/list/pathfinder'
			}
		});
		this.userProfileStore.dispatch({
			type: 'GET_USER_PROFILE_DATA', payload: {
				methodVal: 'GET', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: '/user/profile'
			}
		});


		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/pathfinder/result')) {
				if (e.type === 'languageChanged') {
					this.languageKey = this.secondholland[0].language;
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.oninitpopupservice.pageCallText(this.lang, 'attributes/hollandcode');
					this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
						this.attrdata = (resp.attribute);
					});
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}

		this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			this.attrdata = (resp.attribute);
		});

		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe(result => {
			if (result && sessionStorage.getItem('isModalActive') === 'false') {
				if (!this.isModalActive) {
					this.headerAudioState = result.audioState;
					this.headerAudionotfound = result.audionotfound;
				}
			}
		})
	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_SHARED');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		this.cdref.detectChanges();
	}
	// click on career, Modal 
	modalOpen(item, type, index) {
		this.stopAudio();
		this.isModalActive = true;
		this.popupSelectData.type = type;
		this.popupSelectData.index = index;
		sessionStorage.setItem('isModalActive', 'true');
		let like = '';
		if (this.thumbTrue.indexOf(item.occId) >= 0) {
			like = 'like';
		}
		else if (this.thumbFalse.indexOf(item.occId) >= 0) {
			like = 'unlike';
		}
		else {
			like = 'undecided';
		}
		this.icons = [];
		for (let i = 0; i < item.attributes.length; i++) {
			for (let j = 0; j < this.attrdata.length; j++) {
				if (item.attributes[i].name === this.attrdata[j].hollandCode) {
					this.icons.push({ 'hollandCode': item.attributes[i].name, 'title': this.attrdata[j].displayName });
				}
			}
		}
		//Assigning icons to the pass in parameter of item from html
		item['icons'] = this.icons;
		this.careerItem = item;
		this.dialogService.pathfinderOccsPopUp(item, like);
		this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			sessionStorage.setItem('isModalActive', 'false');
			this.isModalActive = false;
			if (result === '') {
				this.setmodalpopupData('');
			}
		});
	}

	setmodalpopupData(result) {
		let endurl = '';
		if (result) {
			if (this.popupSelectData.type === 'firstholland') {
				this.firstholland[this.popupSelectData.index]['viewed'] = false;
			}
			if (this.popupSelectData.type === 'secondholland') {
				this.secondholland[this.popupSelectData.index]['viewed'] = false;
			}
			if (this.popupSelectData.type === 'sharedoccs') {
				this.sharedoccs[this.popupSelectData.index]['viewed'] = false;
			}
			if (result === 'thumb_up' || result === 'thumb_down') {
				if ((this.thumbTrue.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_up')) {
					endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
					this.thumbTrue.push(this.careerItem.occId);
				}

				if ((this.thumbFalse.indexOf(this.careerItem.occId) === -1) && (result === 'thumb_down')) {
					endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
					this.thumbFalse.push(this.careerItem.occId);
				}

			}

			if (result === 'thumb_up_dselect' || result === 'thumb_down_dselect') {
				if ((this.thumbTrue.indexOf(this.careerItem.occId) >= 0) || (this.thumbFalse.indexOf(this.careerItem.occId) >= 0)) {

					if (result === 'thumb_up_dselect') {
						endurl = 'user/occ/rate/' + this.careerItem.occId + '/true';
					} else {
						endurl = 'user/occ/rate/' + this.careerItem.occId + '/false';
					}
				} else {
					this.setCheckboxCareer(this.careerItem, this.popupSelectData.type, this.popupSelectData.index);
				}
			}
			if (endurl != '') {
				this.saveThumbStatus(endurl, result, this.careerItem, this.popupSelectData.type, this.popupSelectData.index);
			} else {
				this.dialogService.hideLoading();
			}
		} else {
			this.setCheckboxCareer(this.careerItem, this.popupSelectData.type, this.popupSelectData.index);
		}
	}
	hideLoading() {
		this.dialogService.hideLoading();
	}

	//  Saving the viewed career 
	setCheckboxCareer(item, occType, index) {
		if (occType === 'firstholland') {
			this.firstholland[index]['viewed'] = true;
		}
		if (occType === 'secondholland') {
			this.secondholland[index]['viewed'] = true;
		}
		if (occType === 'sharedoccs') {
			this.sharedoccs[index]['viewed'] = true;
		}

		this.pathwayOccViewedCareerStore.dispatch({
			type: 'GET_PATHFINDER_OCC_VIEWED_CAREER', payload: {
				methodVal: 'PUT', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: '/user/occ/viewed/' + item.occId + '/pathfinder'
			}
		});


		this.pathwayOccViewedCareerStore.select('pathwayOccViewedCareers').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata.pathwayOccViewedCareers) {
				this.viewedOccs = respdata.pathwayOccViewedCareers;
				this.getoccsdata();
				this.dialogService.hideLoading();
			}
		});



	}
	getsitedata() {
		if (this.sitedata) {
			const resp = this.sitedata;
			this.c = 0; this.b = 0;
			if (resp.moduleDefaults != '' && resp.moduleDefaults != null && resp.moduleDefaults != undefined) {
				for (let i = 0; i < resp.moduleDefaults.length; i++) {
					if (resp.moduleDefaults[i].module == 'PATHFINDER') {
						if (this.liked[0] == resp.moduleDefaults[i].hollandCode) {
							this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
							for (let j = 0; j < this.attrdata.length; j++) {
								if (resp.moduleDefaults[i].hollandCode == this.attrdata[j].hollandCode) {
									this.first = this.attrdata[j].displayName;
								}
							}
							this.first_icon = resp.moduleDefaults[i].hollandCode;
							for (let k = 0; k < this.occdata.length; k++) {
								if (resp.moduleDefaults[i].occIds.indexOf(this.occdata[k].occId) >= 0) {
									//To Check the current career is in the viewed occ to add color bar to the career card
									if (this.viewedOccs) {
										let occIndex
										if (this.viewedOccs.modules) {
											occIndex = this.viewedOccs.modules.PATHFINDER.occs.reviewed.
												findIndex(x => x.occId === this.occdata[k].occId);
										} else if (this.viewedOccs[0] && this.viewedOccs[0].modules) {
											occIndex = this.viewedOccs[0].modules.PATHFINDER.occs.reviewed.
												findIndex(x => x.occId === this.occdata[k].occId);
										}
										if (occIndex >= 0 && (this.thumbTrue.indexOf(this.occdata[k].occId) < 0) && (this.thumbFalse.indexOf(this.occdata[k].occId) < 0)) {
											this.occdata[k]['viewed'] = true;
										}
									}
									this.firstholland.push(this.occdata[k]);
									this.b++;
								}
							}
						} else if (this.liked[1] == resp.moduleDefaults[i].hollandCode) {
							for (let j = 0; j < this.attrdata.length; j++) {
								if (resp.moduleDefaults[i].hollandCode == this.attrdata[j].hollandCode) {
									this.second = this.attrdata[j].displayName;
								}
							}
							this.second_icon = resp.moduleDefaults[i].hollandCode;
							for (let k = 0; k < this.occdata.length; k++) {
								if (resp.moduleDefaults[i].occIds.indexOf(this.occdata[k].occId) >= 0) {
									if (this.viewedOccs) {
										//To Check the current career is in the viewed occ to add color bar to the career card
										let occIndex
										if (this.viewedOccs.modules) {
											occIndex = this.viewedOccs.modules.PATHFINDER.occs.reviewed.
												findIndex(x => x.occId === this.occdata[k].occId);
										} else if (this.viewedOccs[0] && this.viewedOccs[0].modules) {
											occIndex = this.viewedOccs[0].modules.PATHFINDER.occs.reviewed.
												findIndex(x => x.occId === this.occdata[k].occId);
										}
										if (occIndex >= 0 && (this.thumbTrue.indexOf(this.occdata[k].occId) < 0) && (this.thumbFalse.indexOf(this.occdata[k].occId) < 0)) {
											this.occdata[k]['viewed'] = true;
										}
									}

									this.secondholland.push(this.occdata[k]);
									this.c++;
								}
							}
						}
					}
				}
			}
		}
		if (this.secondholland[0] != undefined && this.languageKey != this.secondholland[0].language
			&& this.keywords.language != this.languageKey) {
			this.languageKey = this.secondholland[0].language;

		}
	}

	getoccsdata() {
		this.secondholland = [];
		this.firstholland = [];
		this.sharedoccs = [];
		const resp = this.occdata;
		if (resp != null && resp != undefined && Array.isArray(resp) && resp.length>0) {
			for (let i = 0; i < resp.length; i++) {
				for (let j = 0; j < resp[i].attributes.length - 1; j++) {
					if ((this.liked[0] == resp[i].attributes[j].name &&
						this.liked[1] == resp[i].attributes[j + 1].name)
						|| (this.liked[1] == resp[i].attributes[j].name &&
							this.liked[0] == resp[i].attributes[j + 1].name)) {
						if (this.viewedOccs) {
							//To Check the current career is in the viewed occ to add color bar to the career card
							let occIndex
							if (this.viewedOccs.modules) {
								occIndex = this.viewedOccs.modules.PATHFINDER.occs.reviewed.
									findIndex(x => x.occId === resp[i].occId);
							} else if (this.viewedOccs[0] && this.viewedOccs[0].modules) {
								occIndex = this.viewedOccs[0].modules.PATHFINDER.occs.reviewed.
									findIndex(x => x.occId === resp[i].occId);
							}

							if (occIndex >= 0 && (this.thumbTrue.indexOf(resp[i].occId) < 0) && (this.thumbFalse.indexOf(resp[i].occId) < 0)) {
								resp[i]['viewed'] = true;
							}
						}

						this.sharedoccs.push(resp[i]);
						this.a++;
						break;
					}
				}
			}
			this.getsitedata();
			
		}
	}
	ngOnInit() {
		this.dialogService.showLoading();
		this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.pathFinderResult = data.find((obj => obj.pageCode === 'PATHFINDER_SHARED'));
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
			}
		}
		);
		this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
			const data = params['like'];
			const holandcode = params['hollandCode'];
			const holandCode = holandcode.split('&');
			this.liked = data.split('&');
			sessionStorage.setItem('pathfinderlike', JSON.stringify(this.liked));
			try {
				this.storeOccs.select('occs').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
					// debugger;
					if (resp && resp.occs) {
						this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
						this.occdata = JSON.parse(resp.occs);
						this.getCareerViewedList();
					}
				});
				this.storeSites.select('sites').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
					if (respdata) {
						this.sitedata = respdata;
						const resp = this.sitedata;
						if (resp.moduleDefaults) {
							for (let i = 0; i < resp.moduleDefaults.length; i++) {
								if (resp.moduleDefaults[i].module == 'PATHFINDER') {
									if (holandCode[0] == resp.moduleDefaults[i].hollandCode) {
										this.liked[0] = resp.moduleDefaults[i].hollandCode;
									}
									if (holandCode[1] == resp.moduleDefaults[i].hollandCode) {
										this.liked[1] = resp.moduleDefaults[i].hollandCode;
									}
								}
							}
						}
					}
				});
			} catch (e) {
				this.utils.handleError(`pathfinder-result.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
			}
		});

		this.occThumbStateStore.select('OccThumbList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {
			if (respdata.OccThumbList.occs) {
				this.favourate.occs.evaluated = respdata.OccThumbList.occs.evaluated;
				this.thumbFalse = [];
				this.thumbTrue = [];
				if (this.favourate) {
					for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
						if (this.favourate.occs.evaluated[j].like == true) {
							this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
						} else if (this.favourate.occs.evaluated[j].like == false) {
							this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
						}
					}

				}
			}

		});

		this.setPathfinderResultsTitle();
	}
	// To get the list of viewed careers by user.
	getCareerViewedList() {
		this.pathwayOccViewedStore.select('pathwayOccViewedList').pipe(takeWhile(() => this.compActive)).subscribe((respdata) => {

			if (respdata.pathwayOccViewedList.modules) {
				this.viewedOccs = respdata.pathwayOccViewedList;
				this.getUserProfileData();
			}
		});
	}

	//To get the data of user fav occs
	getUserProfileData() {
		try {

			this.userProfileStore.select('userProfileData').pipe(take(1)).subscribe((respdata) => {
					this.profileData = respdata.userProfileData.occs;

					if (this.profileData) {
						this.favourate.occs.evaluated = this.profileData.evaluated;
						this.thumbFalse = [];
						this.thumbTrue = [];
						for (let j = 0; j < this.favourate.occs.evaluated.length; j++) {
							if (this.favourate.occs.evaluated[j].like == true) {
								this.thumbTrue.push(this.favourate.occs.evaluated[j].occId);
							} else if (this.favourate.occs.evaluated[j].like == false) {
								this.thumbFalse.push(this.favourate.occs.evaluated[j].occId);
							}
						}
						this.getoccsdata();
					}
				});
		} catch (e) {
			this.utils.handleError(`pathfinder-result.ts getUserProfileData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	// To save thumbs status in popup
	saveThumbStatus(endurl, status, item, type, index) {
		this.occThumbStateStore.dispatch({
			type: 'GET_OCC_THUMB', payload: {
				methodVal: 'GET', module_Name: 'pathfinder',
				path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
				body_Params: {}, endUrlVal: endurl
			}
		});
		if (status === 'thumb_down_dselect' || status === 'thumb_up_dselect') {
			this.setCheckboxCareer(item, type, index);
		}
	}
	unsavedChanges() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.utils.backRoute();
	}
	navigateChallenge() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);

		/*After completion of challenge, Navigating through careerLibrary to Landing page while clicking 
		 on Icon (i.e. navigating to landing page) previous challenge value storing. So here making empty*/

		this.pathFinderChallengeStore.dispatch({
			type: 'PATHFINDER_CHALLENGE_VAL'
			, payload: {
				pathFinderChallenge: {
					attempts: 0,
					progress: 0,
					matchedEle: [],
					matchedEle2: []
				}
			}
		});
		this.router.navigateByUrl('/app/pathfinder/challenge');
	}

	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}

	// Stop audio event method
	stopAudio() {
		this.headerAudioState = "stoped";
		this.headerAudionotfound = false;
		this.audioStore.dispatch({
			type: "SET_AUDIO_STOPED",
		});
		const evnt = document.createEvent("CustomEvent");
		this.eventService.dispatchAudioStop(evnt);
	}

	ngOnDestroy() {
		this.compActive = false;
	}

	private setPathfinderResultsTitle() {
		this.titleService.setTitle("Pathfinder Results - CareerTrek");
	}
}
