import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickpicComponent } from './quickpic-component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuickpicQuestionComponent } from './quickpic-questions/quickpic-question-component';
import { QuickpicIntroComponent } from './quickpic-intro/quickpic-intro-component';
import { QuickpicRestoreComponent, ResultBottomSheet } from './quickpic-restore/quickpic-restore-component';
import { QuickpicResultComponent } from './quickpic-result/quickpic-result-component';
import { LoadedDirective } from './quickpic-result/loaded.directive';
import { QuickpicResultBodyComponent } from './quickpic-result/quickpic-result-body.component';
import { ObserversModule } from '@angular/cdk/observers';

const routes: Routes = [{
    path: 'app/quickpic', component: QuickpicComponent,
    children: [
        { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
        { path: 'intro', component: QuickpicIntroComponent },
        { path: 'assessment', component: QuickpicQuestionComponent },
        { path: 'restore', component: QuickpicRestoreComponent },
        { path: 'result', component: QuickpicResultComponent },

    ]
}];
@NgModule({
    imports: [
        CommonModule, [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], SharedModule.forRoot(), FlexLayoutModule, ObserversModule
    ],
    declarations: [
        ResultBottomSheet,
        QuickpicComponent,
        QuickpicIntroComponent,
        QuickpicQuestionComponent,
        QuickpicRestoreComponent,
        QuickpicResultComponent,
        LoadedDirective, QuickpicResultBodyComponent
    ],
    providers: [],
    exports: [RouterModule]
})
export class QuickpicModule { }
