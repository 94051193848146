<div class="Career-trek-content" *ngIf="listOfOccs">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{scoutPageData.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="headeraudioClick('inside',scoutPageData)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="scout-results content-box">
      <div class="result-image">
        <img src="{{assetsUrl}}{{images[category-1]}}.svg" alt="explore-icon">
      </div>
      <h3 text-color="primary" class="scout-result-header">
        {{scoutPageData.subHeader}}
        <app-audio-button [audionotfound]="subHeaderAudionotfound" [audioState]="subHeaderAudioState"
          (click)="subheaderAudioClick('inside',scoutPageData)">
        </app-audio-button>
      </h3>
      <div class="scout-result-block">
        <div class="result-cards">
          <div pointer class="scout-result-cards" *ngFor="let occ of listOfOccs;let inx = index;">
            <mat-card class="card">
              <mat-card-content class="scout-result-body text-center" (click)="modalOpen(occ,inx)"
                (keyup.enter)="modalOpen(occ,inx)">
                <div class="active-image"
                  [ngClass]="{'active-bar' : !(((thumbTrue.indexOf(occ.occId)===-1) && (thumbFalse.indexOf(occ.occId)===-1)) && !occ.viewed)}">
                  <img class="w-100" src="{{occUrl}}{{occ?.media?.images?.card}}" alt="">
                  <div class="opacity-text">
                    <p m-0 flex v-center>
                      <span class="w-100 text-left">{{occ?.title}}</span>
                      <!-- <img  alt="thumb-up-filled icon" src="assets/images/thumbsup.png">  -->
  
                      <img alt="thumb-up-filled icon" *ngIf="thumbTrue.indexOf(occ?.occId)>=0"
                        src="{{assetsUrl}}thumbs-up-outline-white.svg">
                      <!-- <img alt="thumb-down-filled icon" *ngIf="thumbFalse.indexOf(occ?.occId)>=0"
                        src="{{assetsUrl}}thumbs-down-outline-white.svg"> -->
                    </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="career-creator-nav">
            <div flex v-center h-center mb-10>
              <img src="assets/images/lock-icon.svg" alt="lock-icon" />
              <div class="creator-text" text-color="primary" font-bold>
                {{keywords?.dictionary?.unlockedCreator}}
              </div>
            </div>
            <div flex v-center h-center>
              <span>
                <img class="shake-animation" src="./assets/Svg/scout-pencil-icon.svg" alt="createOcc" />
              </span>
              <button mat-raised-button background-color="primary" class="creator-button" (click)="careerCreatorModelOpen('scout', 7)">
                {{keywords?.dictionary?.createOcc}}
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>