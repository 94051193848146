<div class="Career-trek-content">
    <div class="container">
        <h4 class="landing-page-heading">
           {{pageData.header}}
           <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
           (click)="audioClick('inside',pageData)"></app-audio-button>
        </h4>
    </div>
    <div class="container">
        <div class="navigator_top-two-groups content-box">
            <div class="mobile-heading mobile-design-show" flex v-center justify-end>
                <button mat-fab color="primary" [@flipAnimation]="completed" (click)="navigateToResult()">
                    <i class="material-icons">arrow_forward</i>
                </button>
            </div>
            <div class="top-two-groups_body">
                <div class="top-two-values">
                    <div class="selected-work" *ngFor="let card of topTwoGroups; let i = index">
                        <div class="inner-section">
                            <div font-bold>{{card.title}}</div>
                            <img src="./assets/Svg/{{ card.icon }}.svg" alt="work-selected-icon">
                        </div>
                        <div class="dashed-line"></div>
                    </div>
                    <!-- <div class="selected-work">
                        <div class="inner-section">
                            <div font-bold>Recognition</div>
                            <img src="./assets/Svg/recognition.svg" alt="work-selected-icon">
                        </div>
                        <div class="dashed-line"></div>
                    </div> -->
                </div>
                <div class="navigator-activity-block">
                    <div class="emoji-outer">
                        <div class="activity-emoji">
                            <img src="{{emoji}}" alt="profile-emoji">
                        </div>
                    </div>
                </div>
            </div>
            <div class="desktop-design-show">
                <button mat-fab color="primary"  class="next-level" [@flipAnimation]="completed" (click)="navigateToResult()">
                    <i class="material-icons">arrow_forward</i>
                </button>
            </div>
        </div>
    </div>
</div>