import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import {
  OnInitPopUpComponent,
  navigateToChallengePopupPopUpComponent,
  CareerTrekkerFilterPopUpComponent,
  OccupationsFilterPopUpComponent,
  QuickPicMobilePopupComponent,
  ScoutActivityYesModalComponent,
  ScoutActivityNoModalComponent,
  NavigatorActivitySuccessModalCompnent
} from '../../shared/common-modal/modalpopup.component';
import {
  CongratulationsExplorerPopUpComponent,
  ExplorerOccupationsPopUpComponent
} from '../../shared/common-modal/modalpopup.component';
import { CongratulationsPathfinderPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import { CongratulationsCareerTrekkerPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import { PathfinderOccupationsPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import {
  SessionExpireComponent,ErrorPopUpComponent,
  LandingCreatePopUpComponent, DetailOccupationsPopUpComponent,
  QuickpicDeletePopUpComponent,
  QuickpicResultPopUpComponent,
  QuickpicIntroPopUpComponent
} from '../../shared/common-modal/modalpopup.component';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';

import { StaticHeaderComponent } from '../common-header-footer/header.component';
import { StaticFooterComponent } from '../common-header-footer/footer.component'; 
import { DialogopenService } from '../common-modal/modalpopup.service';
import { ApiCallService } from '../common-services/api-call-service';
import { Utilities } from '../common-services/utilities.service';
import { ApiCallClass } from '../common-services/api-call-model';
import { AuthInterceptor } from '../common-services/authentication-interceptors';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OnLoadPopUpService } from '../common-services/onloadpopup.service';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {
  SharedOccupationsPipe,
  FooterYearPipe,
  ScoreSentencePipe,
  OccPipe,
  CustomDate,
  ModuleNamePipe,
  TaskSentence,
  ButtonTextChange,
  QuestionsListPipe,
  challengePathfinderPipe,
  libraryCharacteristicPipe,
  libraryPathFinderPipe,
  libraryScoutPipe,
  libraryNavigatorPipe,
  librarySkillPipe,
  SpanishAndEnglishDatePipe,
  AttributeNamePipe
} from '../common-pipes.pipes';
import { AvatarComponent } from '../../modules/landing-module/profile-component';
import { OrderModule } from 'ngx-order-pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { AudioButtonComponent } from '../audio-button/audio-button.component';

@NgModule({
    declarations: [
        StaticHeaderComponent,
        StaticFooterComponent,
        OnInitPopUpComponent,
        navigateToChallengePopupPopUpComponent,
        SharedOccupationsPipe,
        QuestionsListPipe,
        ScoreSentencePipe,
        challengePathfinderPipe,
        OccPipe,
        FooterYearPipe,
        SpanishAndEnglishDatePipe,
        ModuleNamePipe,
        AttributeNamePipe,
        libraryCharacteristicPipe,
        libraryPathFinderPipe,
        libraryScoutPipe,
        libraryNavigatorPipe,
        librarySkillPipe,
        CustomDate, ButtonTextChange,
        TaskSentence,
        CongratulationsExplorerPopUpComponent,
        ExplorerOccupationsPopUpComponent,
        CongratulationsPathfinderPopUpComponent,
        CongratulationsCareerTrekkerPopUpComponent,
        PathfinderOccupationsPopUpComponent,
        SessionExpireComponent,
        ErrorPopUpComponent,
        AvatarComponent,
        LandingCreatePopUpComponent,
        DetailOccupationsPopUpComponent,
        QuickpicDeletePopUpComponent,
        QuickpicResultPopUpComponent,
        QuickPicMobilePopupComponent,
        QuickpicIntroPopUpComponent,
        CareerTrekkerFilterPopUpComponent,
        OccupationsFilterPopUpComponent,
        ScoutActivityYesModalComponent,
        ScoutActivityNoModalComponent,
        NavigatorActivitySuccessModalCompnent,
        AudioButtonComponent
    ],
    imports: [
        OrderModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatSnackBarModule, MatBottomSheetModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        MatGridListModule,
        MatTabsModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSidenavModule,
        MatExpansionModule,
        MatChipsModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatMenuModule,
        InfiniteScrollModule,
        MatListModule,
        MatStepperModule,
        CanvasWhiteboardModule,
        ColorPickerModule
    ],
    providers: [
        DialogopenService,
        EventdispatchService,
        ApiCallService, Utilities,
        OnLoadPopUpService
    ],
    exports: [
        StaticHeaderComponent,
        StaticFooterComponent,
        OrderModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        SharedOccupationsPipe,
        QuestionsListPipe,
        ScoreSentencePipe,
        challengePathfinderPipe,
        libraryCharacteristicPipe,
        libraryPathFinderPipe,
        libraryScoutPipe,
        libraryNavigatorPipe,
        librarySkillPipe,
        OccPipe,
        FooterYearPipe,
        SpanishAndEnglishDatePipe,
        CustomDate,
        ModuleNamePipe,
        AttributeNamePipe,
        TaskSentence, ButtonTextChange,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSnackBarModule, MatBottomSheetModule,
        MatCardModule,
        MatSliderModule,
        MatGridListModule,
        MatTabsModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSidenavModule,
        MatExpansionModule,
        MatChipsModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatMenuModule,
        OnInitPopUpComponent,
        navigateToChallengePopupPopUpComponent,
        CongratulationsExplorerPopUpComponent,
        ExplorerOccupationsPopUpComponent,
        CongratulationsPathfinderPopUpComponent,
        CongratulationsCareerTrekkerPopUpComponent,
        InfiniteScrollModule,
        PathfinderOccupationsPopUpComponent,
        SessionExpireComponent,
        ErrorPopUpComponent,
        AvatarComponent,
        LandingCreatePopUpComponent,
        DetailOccupationsPopUpComponent,
        QuickpicDeletePopUpComponent,
        QuickpicResultPopUpComponent,
        QuickPicMobilePopupComponent,
        QuickpicIntroPopUpComponent,
        MatListModule,
        MatStepperModule,
        CanvasWhiteboardModule,
        OccupationsFilterPopUpComponent,
        ColorPickerModule,
        ScoutActivityYesModalComponent,
        ScoutActivityNoModalComponent,
        NavigatorActivitySuccessModalCompnent,
        AudioButtonComponent
    ],
    bootstrap: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [
            CustomDate,
            ModuleNamePipe,
            AttributeNamePipe,
            // services that you want to share across modules
            ApiCallService,
            ApiCallClass
        ]
    };
}

}
