<div class="Career-trek-content">
    <div class="container">
        <h4 class="landing-page-heading">
            {{pageData.header}}
            <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
            (click)="audioClick('inside',pageData)"></app-audio-button>
        </h4>
    </div>
    <div class="container">
        <div class="navigator-results pathfinder-result content-box">
            <div class="result-body">
                <div class="card-main-body">
                    <div class="navigator-result-block" *ngFor="let card of topTwoGroups; let i = index;">
                        <mat-card class="card text-center">
                            <mat-card-title class="card-header-icon" flex v-center>
                                <h3 text-color="primary" mb-0 mr-10>{{card.title}}</h3>
                                <img src="./assets/Svg/{{ card.icon }}.svg" alt=""/>
                            </mat-card-title>
                            <mat-card-content class="card-body text-center">
                                <div class="card image-card" *ngFor="let list of card.list; let inx = index" pointer flex v-center h-center>
                                    <div (click)="modalOpen(list, i, inx)">
                                        <div class="active-image" *ngIf="inx < 3"
                                        [ngClass]="{'active-bar' : !(((thumbTrue.indexOf(list.occId)===-1) && (thumbFalse.indexOf(list.occId)===-1)) && !list.viewed)}"
                                        >
                                        <div class="image-box" >
                                            <img src="{{occCardAPIUrl}}{{list.media.images.card}}" />
                                        </div>
                                        <div class="opacity-text">
                                            <p class="w-100" m-0>
                                                <span class="w-100">{{list.title}}</span>
                                                <img *ngIf="thumbTrue.indexOf(list?.occId)>=0" src="{{assetsURL}}thumbs-up-outline-white.svg" alt="thumb-up-filled icon" />
                                                <!-- <img  *ngIf="thumbFalse.indexOf(list?.occId)>=0" src="{{assetsURL}}thumbs-down-outline-white.svg" alt="thumb-down-filled icon" /> -->
                                            </p>
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div class="navigator-challenge">
                    <div class="you-are-text" text-color="primary">
                        {{keywords?.dictionary?.afterExploring}}
                    </div>
                    <div class="challenge-block" flex v-center h-center>
                        <img src="{{assetsURL}}star.svg" class="rotate-animation" alt="star-icon" />
                        <button mat-raised-button background-color="primary" class="challenge-button" (click)="navigateToChallenge()">
                            {{keywords?.dictionary?.challenge}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>