
/**Import angular core packages */
import { Component, OnInit, ElementRef, Renderer2, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { FactoidDataState, PageDataState, AttributeDataState, AudioState, TrekkerChallengeState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { element } from 'protractor';
import { Next } from '../../../shared/common-animations/CT_animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-careertrekker-challenge',
	templateUrl: './challenge-layout.html',
	animations: [Next]
})

export class CareerTrekkerChallengeComponent implements OnInit, OnDestroy {
	languageKey: any;
	completed = 'start';
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	correctopt = 0;
	trueopt = 0;
	questions = 0;
	progress = 0;
	score = 0;
	clickEvent = [];
	attempts = 0;
	lang;
	factoidData;
	startInx: number;
	answerStyle: boolean;
	headerAudioState = 'stoped';
	quesAudioState = 'stoped';
	audionotfound = false;
	checkedVar;
	theme_name;
	attemptedquestions = [];
	attemptedFactoids = [];
	audio: any;
	playPromise: any;
	cardsData: any = [];
	assetsUrl: string;
	compActive = true;
	addScore: number; //While choosing the options this variable is storing that particular question score.
	startInxTemp = [];
	scoreArray = [];
	forIncScore = false; // Clicking back button, based on this value decreasing the scores.
	trekkerChallengeText: any;
	audioType: string;
	constructor(private _location: Location,
		private renderer: Renderer2,
		private elementref: ElementRef,
		public activatedRoute: ActivatedRoute,
		private audioStore: Store<AudioState>, private audioService: AudioService,
		public dialogService: DialogopenService, private attrstore: Store<AttributeDataState>,
		private store: Store<FactoidDataState>, private router: Router,
		private store1: Store<PageDataState>,
		private trekkerChallengeStore: Store<TrekkerChallengeState>,
		private oninitpopupservice: OnLoadPopUpService,
		private eventService: EventdispatchService,
		private apiModel: ApiCallClass, private apiJson: ApiCallService, private utils: Utilities,
		@Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		private titleService: Title) {
		this.assetsUrl = assetsMediaUrl;
		this.attrstore.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
			this.cardsData = (res.attribute);
		});
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[2]);
		// sessionStorage.setItem('pageTitle', '3');
		// sessionStorage.setItem('moduleKey', 'CAREER_TREKKER##TREKKER_FILTER'); // Since using numbers is not a good way we have used pagecode and module name for page heading
		this.store1.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'CAREER_TREKKER', pageCode: 'TREKKER_CHALLENGE' } });
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		// const currentDate = new Date();
		// const currentTime = currentDate.getTime();
		// 'start Time in CareerTrekker-challenge is' + currentTime);
		this.dialogService.showLoading();
		this.store.dispatch({
			type: 'FACTOIDS_CALL', payload: {
				methodVal: 'GET', module_Name: 'ct-challenge',
				path_params: [], query_params: {}, sessionID: this.lang,
				body_Params: {}, endUrlVal: 'factoids'
			}
		});
		// this.infopopup();
		const elem = this.elementref.nativeElement;
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/careertrekker/challenge')) {
				// sessionStorage.getItem('pathurl'));
				if (e.type === 'languageChanged') {
					// 'languageChanged----------->');
					this.languageKey = this.factoidData[0].language;
					// this.oninitpopupservice.pageCallText(this.lang, 'pages');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.lang = sessionStorage.getItem('session_token');
					this.store.dispatch({
						type: 'FACTOIDS_CALL', payload: {
							methodVal: 'GET', module_Name: 'ct-challenge',
							path_params: [], query_params: {}, sessionID: this.lang,
							body_Params: {}, endUrlVal: 'factoids'
						}
					});
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					const evnt = document.createEvent('CustomEvent');
					this.eventService.dispatchAudioStop(evnt);
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}
		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe(result => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.headerAudioState = result.audioState;
				this.quesAudioState = result.audioState;
				this.audionotfound = result.audionotfound;
				/*If two audio icons are in same page when clicking one audio icon another audio icon must be in rest position(i.e. showing the 'volume_up icon')
				below code is for differentiating audio icons in both the conditions(i.e. playing instructional text audio and questions audio)*/
				if (this.audioType == 'headerAudio') {
					this.quesAudioState = 'stoped';
				} else if (this.audioType == 'quesAudio') {
					this.headerAudioState = 'stoped';
				}
			}
		})
	}

	audioClick(text, data, audioType) {
		this.audioType = audioType;
		this.audioService.audioFunction(text, data);
	}

	infopopup() {
	this.stopAudio();
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp('TREKKER_CHALLENGE');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioType = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	ngOnInit() {
		// this.trekkerChallengeText = this.oninitpopupservice.getKeyWordData('TREKKER_CHALLENGE');
		this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.trekkerChallengeText = data.find((obj => obj.pageCode === 'TREKKER_CHALLENGE'));
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
			}

		});
		// history.pushState(null, null, location.href);
		// window.onpopstate = function (event) {
		// 	history.go(1);
		// };
		this.factoidData = [];
		this.attemptedquestions = [];
		this.completed = 'start';
		this.correctopt = 0;
		this.trueopt = 0;
		this.questions = 0;
		this.progress = 0;
		this.score = 0;
		this.clickEvent = [];
		this.attempts = 0;
		this.checkedVar = '';


		this.store.select('factoid').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
			if (res != null && !res.factoid && res != undefined) {
				// res);
				this.factoidData = res;
				for (let j, x, i = this.factoidData.length; i;) {
					j = Math.floor(Math.random() * i);
					x = this.factoidData[--i];
					this.factoidData[i] = this.factoidData[j];
					this.factoidData[j] = x;
					this.dialogService.hideLoading();
				}
				this.startInx = 0;
				// this.factoidData[this.startInx].title;
				this.calcTrue();
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				// 'factoidData language' + this.factoidData[0].language +
				// 'language of defualt ' + this.languageKey);
				if (this.factoidData[0].language != this.languageKey) {
					this.languageKey = this.factoidData[0].language;
					// 'coming in my ifffffffffff');
					this.dialogService.hideLoading();
				}
				// 'this.startInx' + this.startInx);
			}
			// const currentDate = new Date();
			// const currentTime = currentDate.getTime();
			// 'end Time in CareerTrekker-Challenge is' + currentTime);
			// 'res-------------->'+this.factoidData.indexOf('workothers'));
		});
		let initialVal = true; // clicking back button from Career Library page, based on this value model-pop will be open.
		this.trekkerChallengeStore.select('trekkerChallengeQues').pipe(take(1)).subscribe(challengeResp => {
			if (challengeResp['trekkerChallengeQues'].attemptedquestions && challengeResp['trekkerChallengeQues'].attemptedquestions.length >= 10 && initialVal) {
				this.score = challengeResp['trekkerChallengeQues'].score;
				this.questions = challengeResp['trekkerChallengeQues'].questions;
				this.modalOpen();
				initialVal = false;
			}
		});
		this.setTrekkerChallengeTitle();
	}
	calcTrue() {
		// 'came to calTrue--------');
		this.correctopt = 0;
		this.trueopt = 0;
		this.clickEvent = [];
		for (let i = 0; i < this.factoidData[this.startInx].Options.length; i++) {
			this.clickEvent.push(false);
			if (this.factoidData[this.startInx].Options[i].optionSelected != undefined) {
				this.factoidData[this.startInx].Options[i].optionSelected = false;
				// this.factoidData[this.startInx].Options[i].isCorrect = false;
			}
			if (this.factoidData[this.startInx].Options[i].isCorrect) {
				this.trueopt++;
				// 'true condition in calTrue--------' + this.trueopt);
			}
		}
	}
	modalOpen() {
		this.dialogService.careerTrekkerChallengePopup(this.score);
		this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			this.saveChallenge();
			if (result === 'closed') {
				//Once answered the Challenge, the values are stored in ngrx store. So, dispatching empty values while clicking 'playAgain' button on model-pop.
				this.trekkerChallengeStore.dispatch({ type: 'TREKKER_CHALLENGE_VAL', payload: { trekkerChallengeQues: { attemptedquestions: [], score: 0, questions: 0 } } });
				this.ngOnInit();
			}
			if (result === 'library') {
				sessionStorage.setItem('modulename', 'Careertrekker');
				this.router.navigateByUrl('/app/library-occupations');
				this.router.navigate(['/app/library-occupations'], {
					relativeTo: this.activatedRoute,
					queryParams: {
						'module': 'Careertrekker'
					}
				});
			}
		});
	}

	callFactoidsAttempt(innerItem, index) {
		innerItem['optionSelected'] = true;
		this.attempts++;
		if (innerItem.isCorrect && innerItem['optionSelected']) {
			this.correctopt++;
			this.clickEvent[index] = true;
			if (this.trueopt == this.correctopt) {
				this.checkedVar = 'yes';
				this.addScore = (10 - (Math.floor((10 / this.factoidData[this.startInx].Options.length) * (this.attempts - this.trueopt))));
				this.scoreArray.push(this.addScore);
				this.score = this.score + this.addScore;
				// 'score-----------' + this.score);
				this.progress = this.progress + 10;
				this.forIncScore = true;
				this.questions++;
				this.attempts = 0;
				// 'attempts-----------' + this.attempts);
				this.attemptedquestions.push(this.factoidData[this.startInx].title);
				this.attemptedFactoids.push(this.factoidData[this.startInx].factoidId);
			}
		}
	}

	// toggleSelected(options) {
	// 	let selectedValue = true;
	// 	if (options.optionSelected) {
	// 		selectedValue = false;
	// 	}
	// 	return selectedValue;
	// }

	goToNextFactoid(inx) {
		/* The below condition is very importent when clicking back and then going forward */
		if (this.attemptedquestions.indexOf(this.factoidData[this.startInx].title) < 0) {
			this.attemptedquestions.push(this.factoidData[this.startInx].title);
		}
		this.audionotfound = false;
		this.headerAudioState = 'stoped';
		this.quesAudioState = 'stoped';
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.audioStore.dispatch({
			type: 'SET_AUDIO_STOPED'
		});
		if (this.checkedVar == 'yes') {
			this.completed = 'completed';
		} else {
			this.completed = 'start';
		}
		this.forIncScore = false;
		if (this.questions < 10) {
			// 'this.attemptedquestions---' + JSON.stringify(this.attemptedquestions));
			for (this.startInx = inx; this.startInx < this.factoidData.length; this.startInx++) {
				if (this.attemptedquestions.indexOf(this.factoidData[this.startInx].title) < 0) {
					// 'this.startInx----' + this.startInx);
					if (this.startInxTemp.indexOf(this.startInx) < 0) {
						this.startInxTemp.push(this.startInx);
					}
					this.calcTrue();
					this.checkedVar = '';
					break;
				}
			}
		}
		if (this.questions >= 10) {
			this.modalOpen();
		}
		this.trekkerChallengeStore.dispatch({
			type: 'TREKKER_CHALLENGE_VAL', payload: {
				trekkerChallengeQues: {
					attemptedquestions: this.attemptedquestions,
					score: this.score, questions: this.questions
				}
			}
		});
	}
	// SelectedOption(optionsArr){
	// 	let correctAnswerArray=[];
	// 	let incorrectAnswerArray=[];
	// 	optionsArr);
	// 	for(let i=0; i<=optionsArr.length;i++){
	// 		// if((optionsArr[i].isCorrect == true) && (optionsArr[i].optionSelected == true)){
	// 		// 	correctAnswerArray.push('correct');
	// 		// }
	// 		// else{
	// 		// 	incorrectAnswerArray.push('incorrect');
	// 		// }
	// 	}
	// }
	saveChallenge() {
		// 'attemptedFactoids----->' + JSON.stringify(this.attemptedFactoids));
		this.apiModel.moduleName = 'careerTrekker';
		this.apiModel.endUrl = 'user/skills/challenge/add';
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'POST';
		this.apiModel.data = {
			'score': this.score,
			'factoidIds': this.attemptedFactoids
		};
		try {
			this.apiJson.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp != undefined && resp != null) {
					// 'save data---------->' + JSON.stringify(resp));
				}
			});
		} catch (e) {
			this.utils.handleError(`challenge-component.ts saveChallenge: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	unsavedChanges() {
		this.quesAudioState = 'stoped';
		this.headerAudioState = 'stoped';
		if (this.startInx === 0) {
			this.utils.backRoute();
		} else if (this.startInx !== 0) {
			this.checkedVar = 'yes';
			this.completed = 'completed';
			if (this.forIncScore) {
				this.questions--;
				this.addScore = this.scoreArray.pop();
				this.score = this.score - this.addScore;
			}
			this.forIncScore = true;
			this.startInxTemp.pop();
			this.attemptedquestions.pop();
			if (!this.startInxTemp.length) {
				this.startInx = 0;
			} else {
				this.startInx = this.startInxTemp[this.startInxTemp.length - 1];
			}
		}
	}

	ngOnDestroy() {
		this.compActive = false;
		this.audioStore.dispatch({
			type: 'SET_AUDIO_STOPED'
		});
	}

	private setTrekkerChallengeTitle(){
		this.titleService.setTitle("Trekker Challenge - CareerTrek");
	}
}
