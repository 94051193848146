import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CareerCornerModuleComponent } from './careercorner-module.component';
import { CareerCornerIntroComponent } from './intro/careercorner-intro.component';
import { SharedModule } from '../../shared/common-module/shared-common-module';


const routes: Routes = [{
  path: 'app/careercorner', component: CareerCornerModuleComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: CareerCornerIntroComponent }
  ]
}];
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    SharedModule.forRoot(),
  ],

  declarations: [
    CareerCornerModuleComponent,
    CareerCornerIntroComponent
  ],
  providers: [],
  exports: [RouterModule]

})



export class CareerCornerModule { }
