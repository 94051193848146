import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { ScoutModuleComponent } from './scout-module.component';
import { RouterModule, Routes } from '@angular/router';
import { ScoutActivityOneComponent } from './activity_one/scout-activity_one.component';
import { ScoutActivityTwoComponent } from './activity_two/scout-activity_two.component';
import { ScoutResultsComponent } from './results/scout-results.component';
import { ScoutIntroComponent } from './intro/scout-intro.component';
import { ScoutTieBreakerComponent } from './scout-tie/scout-tie.component';


const routes: Routes= [{
  path: 'app/scout', component: ScoutModuleComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: ScoutIntroComponent},
    { path: 'activity_one', component: ScoutActivityOneComponent },
    { path: 'activity_two', component: ScoutActivityTwoComponent },
    { path: 'results', component: ScoutResultsComponent},
    { path: 'tieBreaker', component: ScoutTieBreakerComponent}  
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    SharedModule.forRoot()
  ],

  declarations: [
    ScoutModuleComponent,
    ScoutActivityOneComponent,
    ScoutActivityTwoComponent,
    ScoutResultsComponent,
    ScoutIntroComponent,
    ScoutTieBreakerComponent
  ],

  providers: [],
  exports: [RouterModule]
})

export class ScoutModule {}