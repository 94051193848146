
/**Import angular core packages */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { AttributeDataState,AudioState,PageDataState, TrekkerAssessmentState } from '../../../state-management/state/main-state';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { Next } from '../../../shared/common-animations/CT_animations';
import { takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-careertrekker-intro',
	templateUrl: './careertrekker-intro-layout.html',
	animations: [Next]
})

export class CareerTrekkerIntroComponent implements OnInit, OnDestroy {
	title: string;
	languageKey = 'en';
	lang = '';
	cardsData = [];
	theme_name;
	//The completed variable to set to true, to turn on the flip animation of the button
	completed : boolean;
	assetsUrl : string;
	trekkerIntroHeading: any;
	compActive = true;
	pagesText: Subscription;
	audioState = 'stoped';
	audionotfound = false;
	constructor(private _location: Location, private router: Router, private activatedRoute: ActivatedRoute,
		private store: Store<AttributeDataState>,
		private store1: Store<PageDataState>,
		private trekkerAssessmentStore:Store<TrekkerAssessmentState>,
		private audioService: AudioService,
		private oninitpopupservice: OnLoadPopUpService,
		public dialogService: DialogopenService,
		private audioStore: Store<AudioState>,
		private eventService: EventdispatchService, private utils: Utilities, @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		private titleService: Title) {
			this.assetsUrl = assetsMediaUrl;
			let colors = environment.THEME_COLORS;
			sessionStorage.setItem('theme_color', colors[2]);
			this.store1.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'CAREER_TREKKER',pageCode:'TREKKER_INTRO'}});
			const event = document.createEvent('CustomEvent');
			event.initEvent('themeChanged', true, true);
			this.eventService.dispatch(event);
			sessionStorage.setItem('isModalActive', 'false');
			eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
				if (sessionStorage.getItem('pathurl') == '/app/careertrekker/intro') {
					if (e.type === 'languageChanged') {
						this.languageKey = this.cardsData[0].language;
						this.lang = sessionStorage.getItem('session_token');
						this.ngOnInit();
					}
					if (e.type === 'themeChanged') {
						this.theme_name = sessionStorage.getItem('theme_color');
					}
					if (e.type === 'infoModal') {
						if (sessionStorage.getItem('infopopup') == 'true') {
							this.infopopup();
							sessionStorage.setItem('infopopup', 'false');
						}
					}
					if (e.type === 'backClicked') {
						this.unsavedChanges();
					}
				}
			});
			if (this.lang === '' || this.lang == null) {
				this.lang = sessionStorage.getItem('session_token');
			}
			this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
				if (result && sessionStorage.getItem('isModalActive')==='false') {
					this.audioState = result.audioState;
					this.audionotfound = result.audionotfound;
				}
			}
		)
	}

	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function() {
			this.oninitpopupservice.getOnLoadPopUp('TREKKER_INTRO');
		}.bind(this));
	
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	ngOnInit() {
		this.pagesText = this.store1.select('pages').subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.trekkerIntroHeading = data.find((obj => obj.pageCode === 'TREKKER_INTRO'));
			}

		});
		this.completed = !this.completed;
		this.getAttributeData();
		this.setTrekkerIntroTitle();
	}
	clickRoute(name) {
		this.trekkerAssessmentStore.dispatch({type:'TREKKER_ASSESSMENT_VAL',payload:{trekkerAssessmentques:{name:'',topSkill:[],ImpSkill:[],quesSkill:[]}}});  
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
        this.router.navigateByUrl('/app/careertrekker/assessment');
		try {
			this.router.navigate(['/app/careertrekker/assessment'], {
				relativeTo: this.activatedRoute,
				queryParams: {
					skillname: name
				}
			});
		} catch (e) {
			this.utils.handleError(`careertrekker-intro-component.ts clickRoute: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	getAttributeData() {
		this.dialogService.showLoading();
		this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
		 this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
			const data = (res.attribute);
			if (data[0]) {
				if (data[0].type == 'skill') {
					this.cardsData = [];
					for (let i = 0; i < data.length; i++) {
						this.cardsData.push(data[i]);
					}
				}
				this.title = sessionStorage.getItem('formobileheading');
			}
			if (this.cardsData[0] ) {
				if (this.languageKey != this.cardsData[0].language) {
					this.languageKey = this.cardsData[0].language;					
				}
			}
			if (this.cardsData && this.cardsData.length>0){
			   this.dialogService.hideLoading();
			}
		});

	}

	unsavedChanges() {
		if (this.cardsData[0] != undefined) {
			const evnt = document.createEvent('CustomEvent');
		    this.eventService.dispatchAudioStop(evnt);
			this.utils.backRoute();
		}
	}
	ngOnDestroy() {
		this.compActive = false;
		this.pagesText.unsubscribe();
	}

	private setTrekkerIntroTitle(){
		this.titleService.setTitle("Trekker Intro - CareerTrek");
	}

}
