import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { MatSnackBar} from "@angular/material/snack-bar";
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-quickpic-restore',
  templateUrl: './quickpic-restore-layout.html',
  styles: [],
})
export class QuickpicRestoreComponent implements OnInit, OnDestroy {
  buttonsText: any = [];
  keyWords: any = [];
  theme_name: string;
  lang: string;
  QuestionsAtt: any;
  completed: any;
  defaultdata: any;
  list: any;
  name: any;
  activeset: number;
  listShow = true;
  compActive = true;
  hideMagnify = false;
  language: string;
  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    public eventService: EventdispatchService,
    private router: Router,
    private store: Store<PageDataState>,
    public snackBar: MatSnackBar,
    public bottomSheet: MatBottomSheet,
    private utils: Utilities,
    private oninitpopupservice: OnLoadPopUpService,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[4]);
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'QUICKPIC', pageCode: 'QUICKPIC_RESULTS' },
    }); //If pageCode:'QUICKPIC_RESTORE', then the header text for QuickPic Restore is missing.
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
    this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') == '/app/quickpic/restore') {
          if (e.type === 'languageChanged') {
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.store
              .select('pages')
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                if (resp.pages != undefined && resp.pages[0] != null) {
                  const data = resp.pages;
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].pageCode == 'GLOBAL_SETTINGS') {
                      sessionStorage.removeItem('GLOBAL_SETTINGS');
                      sessionStorage.setItem(
                        'GLOBAL_SETTINGS',
                        JSON.stringify(data[i])
                      );
                    }
                  }
                }
                this.buttonsText = JSON.parse(
                  sessionStorage.getItem('GLOBAL_SETTINGS')
                );
                this.keyWords =
                  this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
              });

            this.ngOnInit();
            this.language = sessionStorage.getItem('Language');
            this.activatedRoute.queryParams
              .pipe(takeWhile(() => this.compActive))
              .subscribe((params) => {
                this.completed = params['completed'];
                if (this.completed === 'completed') {
                  let date;
                  date = new Date();
                  date =
                    date.getUTCFullYear() +
                    '-' +
                    ('00' + (date.getUTCMonth() + 1)).slice(-2) +
                    '-' +
                    ('00' + date.getUTCDate()).slice(-2) +
                    ' ' +
                    ('00' + date.getUTCHours()).slice(-2) +
                    ':' +
                    ('00' + date.getUTCMinutes()).slice(-2) +
                    ':' +
                    ('00' + date.getUTCSeconds()).slice(-2);
                  this.defaultdata = [{ dateSaved: date, name: this.name }];
                  this.listShow = false;
                }
              });
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type == 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            this.unsavedChanges();
          }
        }
      });

    if (this.lang === '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.dialogService.showLoading();
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.completed = params['completed'];
        if (this.completed === 'completed') {
          let date;
          date = new Date();
          date =
            date.getUTCFullYear() +
            '-' +
            ('00' + (date.getUTCMonth() + 1)).slice(-2) +
            '-' +
            ('00' + date.getUTCDate()).slice(-2) +
            ' ' +
            ('00' + date.getUTCHours()).slice(-2) +
            ':' +
            ('00' + date.getUTCMinutes()).slice(-2) +
            ':' +
            ('00' + date.getUTCSeconds()).slice(-2);
          this.defaultdata = [{ dateSaved: date, name: this.name }];
          this.listShow = false;
        }
      });
  }
  unsavedChanges() {
    this.utils.backRoute();
  }
  partialData() {
    this.keyWords = this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
    if (this.defaultdata.reflection == undefined) {
      this.apiModel.moduleName = 'quick-pic';
      this.apiModel.endUrl = '/quick-pic/restore/' + this.defaultdata._id;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'GET';
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            this.keyWords =
              this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
            this.buttonsText = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            resp = JSON.parse(resp);
            if (resp.length > 48) {
              this.QuestionsAtt = 0;
            } else {
              this.QuestionsAtt = resp.length;
            }
          });
      } catch (e) {
        this.utils.handleError(`quickpic-restore-component.ts partialData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    } else {
      this.name = this.defaultdata.reflection;
      if (this.name == ' ') {
        this.name = '';
      }
    }
  }
  ngOnInit() {
    this.language = sessionStorage.getItem('Language');
    this.keyWords = this.oninitpopupservice.getKeyWordData('QUICKPIC_RESULTS');
    this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    this.apiModel.moduleName = 'quick-pic';
    this.apiModel.endUrl = 'quick-pic/list';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.list = JSON.parse(resp);
          if (this.list && this.list[0]) {
            this.dialogService.hideLoading();
            this.defaultdata = this.list[0];
            this.name = this.defaultdata.reflection;
            if ((this.name = ' ')) {
              this.name = '';
            }
            this.partialData();
            this.dialogService.hideLoading();
          } else {
            this.hideMagnify = true;
            this.defaultdata = undefined;
            this.dialogService.hideLoading();
          }
        });
    } catch (e) {
      this.utils.handleError(`quickpic-restore-component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }

    this.setQuickPicRestoreTitle();
  }
  routeUrl(url) {
    try {
      this.router.navigate(['/app/quickpic/' + url], {
        relativeTo: this.activatedRoute,
        queryParams: {
          id: this.defaultdata._id,
        },
      });
    } catch (e) {
      this.utils.handleError(`quickpic-restore-component.ts routeUrl: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  completedPost() {
    this.completed = 'list';
    if (this.name == '') {
      this.name = ' '; //if this.name = '-', while saving the text the '-' is adding to the notes field.
    }
    this.apiModel.moduleName = 'quick-pic';
    this.apiModel.endUrl = '/quick-pic/reflection/' + this.defaultdata._id;
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'PUT';
    this.apiModel.data = {
      reflection: this.name,
    };
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp !== undefined && resp != null) {
            //Calling this.ngOnInit() will save the text which comment in the 'share your thoughts' text box, even after going to different assessment and then coming back to completed assessment you can see the previously saved text.
            this.ngOnInit();
            this.snackBar.open(this.buttonsText.dictionary.savedThoughts, '', {
              duration: 1000,
              panelClass: ['success-snackbar'],
            });
          }
        });
    } catch (e) {
      this.utils.handleError(`quickpic-restore-component.ts completedPost: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  modalOpen(defaultdata, activeset) {
    this.dialogService.quickpicDeletePopup({
      activeset: activeset + '?',
      set: this.keyWords.dictionary.deleteAnswerSet,
    });
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result == true) {
          this.dialogService.showLoading();
          this.apiModel.moduleName = 'quick-pic';
          this.apiModel.endUrl = '/quick-pic/delete/' + defaultdata._id;
          this.apiModel.sessionID = sessionStorage.getItem('session_token');
          this.apiModel.method = 'DELETE';
          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(takeWhile(() => this.compActive))
              .subscribe((resp) => {
                if (resp != undefined && resp != null) {
                  this.dialogService.hideLoading();
                  this.listShow = true;
                  this.ngOnInit();
                }
              });
          } catch (e) {
            this.utils.handleError(`quickpic-restore-component.ts modalOpen: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  openResultBottomSheet() {
    this.listShow = false;
    const bottomSheetRef = this.bottomSheet.open(ResultBottomSheet, {
      data: { list: this.list },
    });
    bottomSheetRef
      .afterDismissed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result != undefined) {
          this.defaultdata = result.item;
          this.name = this.defaultdata.reflection;
          this.completed = 'list';
        }
      });
  }
  ngOnDestroy() {
    this.compActive = false;
  }
  private setQuickPicRestoreTitle() {
    this.titleService.setTitle('Quick Pic Restore - CareerTrek');
  }
}

import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import {MatBottomSheet } from '@angular/material/bottom-sheet';

import { Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { PageDataState } from "../../../state-management/state/main-state";
import { RouteUrls } from "../../../shared/common-constants/route.constants";
import { environment } from "../../../../environments/environment";
import { takeWhile } from "rxjs/operators";
import { config } from "rxjs";
@Component({
  selector: "app-result-bottom-sheet",
  template: `
    <div class="{{ theme_name }}">
      <div class="quickpic-restore" mt-5>
        <mat-card class="card h-100 answer-set-card" *ngIf="data != undefined">
          <ul class="side-task-list list-style-none" m-0 p-5>
            <li
              class="answer-set-list"
              *ngFor="let item of data.list; index as i"
              pointer
            >
              <mat-card
                class="card"
                flex
                v-center
                [ngClass]="{
                  green: item.reflection != undefined,
                  brown: !(item.reflection != undefined)
                }"
              >
                <div class="left-border w-100" flex>
                  <p text-color="primary" class="w-100">
                    {{ keyWords.dictionary.answerSet }} {{ item.answerSet }}
                  </p>
                  <button
                    mat-raised-button
                    text-color="primary"
                    class="readmore-mobile"
                    flex
                    v-center
                    h-center
                    ngStyle.xs="background-color: #dedede;margin:0px 5px;"
                    (click)="openLink(item, i)"
                  >
                    <div flex v-center>
                      <div
                        class="circle-eye"
                        ngStyle.xs="left: -10px;background-color: #ccc;"
                        flex
                        v-center
                        h-center
                      >
                        <i
                          class="material-icons"
                          text-color="primary"
                          ngStyle.xs="font-size: 12px;margin:0px;"
                          >remove_red_eye</i
                        >
                      </div>
                      <span class="w-100" text-color="primary">view</span>
                    </div>
                  </button>
                </div>
              </mat-card>
            </li>
          </ul>
        </mat-card>
      </div>
    </div>
  `,
})
export class ResultBottomSheet {
  buttonsText: any = [];
  keyWords: any = [];
  theme_name: string;

  constructor(
    private ResultBottomSheetRef: MatBottomSheetRef<ResultBottomSheet>,
    private oninitpopupservice: OnLoadPopUpService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.theme_name = sessionStorage.getItem("theme_color");
    this.keyWords = this.oninitpopupservice.getKeyWordData("QUICKPIC_RESULTS");
    this.buttonsText = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
  }
  openLink(item, i) {
    this.ResultBottomSheetRef.dismiss({ item: item, i: i });
  }
}
