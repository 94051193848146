
/**Import angular core packages */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { GridAnimation, CardFlipAnimation } from '../../../shared/common-animations/CT_animations';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AttributeDataState, AudioState, PageDataState, PathFinderChallengeState } from '../../../state-management/state/main-state';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-pathfinder-challenge',
	templateUrl: './challenge-layout.html',
	styles: [],
	animations: [GridAnimation,CardFlipAnimation]
})

export class PathFinderChallengeComponent implements OnInit,OnDestroy {
	languageKey: any;
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	lang: string;
	matchedEle2 = [];
	j = 0;
	stateName: string;
	myClassbtn: any = [];
	trasition1: any = [];
	trasition2: any = [];
	myClassbtn2: any = [];
	i = 0;
	var1;
	matchedEle = [];
	variableIndex2;
	variableIndex1;
	var2;
	isFlipped = true;
	attempts = 0;
	matched = 0;
	progress = 0;
	score = 0;
	theme_name;
	cards = [];
	cards2 = [];
	selectedcard1 = '';
	selectedcard2 = '';
	occCardAPIUrl :  string;
	//This varibale to know the previous selected card
	prevCard = '';
	pathFinderHeading : any;
	compActive = true;
	imageSelect1 = false;
	imageSelect2 = false;
	counter1 = false;
	counter2 = false;
	audioState = 'stoped';
	audionotfound = false;
	assetsURL:string;
	isChallengeDone: boolean;
	constructor(private _location: Location, public dialogService: DialogopenService,
		private oninitpopupservice: OnLoadPopUpService,
		private apiModel: ApiCallClass, private router: Router,
		private activatedRoute: ActivatedRoute,
		private audioStore: Store<AudioState>, private audioService: AudioService,
		private store: Store<PageDataState>,private pathFinderChallengeStore : Store<PathFinderChallengeState>,
		private apiCall: ApiCallService, private utils: Utilities,
		private eventService: EventdispatchService, 
		@Inject('OCC_CARD_URL') private occCardUrl: string,
		@Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
		private titleService: Title) {
			this.assetsURL = assetsUrl;
            this.occCardAPIUrl = occCardUrl;
		    let colors = environment.THEME_COLORS;
		    sessionStorage.setItem('theme_color', colors[1]);
       this.store.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'PATHFINDER',pageCode:'PATHFINDER_CHALLENGE'}});

		 const event = document.createEvent('CustomEvent');
		 event.initEvent('themeChanged', true, true);
		 this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
	 eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/pathfinder/challenge')) {
				if (e.type === 'languageChanged') {
					this.languageKey = this.cards[0].language;
					this.lang = sessionStorage.getItem('session_token');
					 this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
						if (resp.pages != undefined && resp.pages[0] != null) {
							const data = (resp.pages);
							for (let i = 0; i < data.length; i++) {
								if (data[i].pageCode == 'GLOBAL_SETTINGS') {
									sessionStorage.removeItem('GLOBAL_SETTINGS');
									sessionStorage.setItem('GLOBAL_SETTINGS', JSON.stringify(data[i]));
									this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
									if (this.cards[0] != undefined && this.languageKey != this.cards[0].language
										&& this.keywords.language != this.languageKey) {
										this.languageKey = this.cards[0].language;
										this.dialogService.hideLoading();
									}
								}
							}
						}
					});
					this.ngOnInit();
					this.saveChallenge();         // To flip back the card after changing the language.
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}

		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe(result => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function() {
			this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_CHALLENGE');
		}.bind(this));
	}
	ngAfterViewInit() {     
		if(!this.isChallengeDone){  // to restrict the help popup when doing back navigation for challenge completed popup
		this.infopopup();
		}
	}
	getAttrData() {
		this.apiModel.moduleName = 'pathfinder';
		this.apiModel.endUrl = 'occs/holland/random';
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				this.cards2 = JSON.parse(resp);
				this.cards = JSON.parse(resp);
				this.cards.map(val=>val.state=false);
				this.cards2.map(val=>val.state=false);
				this.shuffle();
			});
		} catch (e) {
			this.utils.handleError(`challenge-component.ts getAttrData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	ngOnInit() {
		this.dialogService.showLoading();
		this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.pathFinderHeading = data.find((obj => obj.pageCode === 'PATHFINDER_CHALLENGE'));
				this.dialogService.hideLoading();
			}}
		);
		this.myClassbtn = [];
		this.myClassbtn2 = [];
		this.imageSelect1 = false;
		this.imageSelect2 = false;
		this.i = 0;
		this.matchedEle2 = [];
		this.j = 0;
		this.var1 = '';
		this.matchedEle = [];
		this.variableIndex2 = null;
		this.variableIndex1 = null;
		this.trasition1 = [];
		this.trasition2 = [];
		this.var2 = '';
		this.isFlipped = true;
		this.attempts = 0;
		this.matched = 0;
		this.progress = 0;
		this.score = 0;
		this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
		this.getAttrData();
		this.setPathfinderChallengeTitle();
	}
	modalOpen() {
		this.dialogService.pathfinderChallengePopup(this.attempts, this.pathFinderHeading);
		this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			this.saveChallenge();
			if (result == 'closed') {
				this.infopopup();
				this.cards = [];
				this.cards2 = [];
				this.matchedEle2 = [];
				this.j = 0;
				this.stateName = '';
				this.myClassbtn = [];
				this.myClassbtn2 = [];
				this.i = 0;
				this.var1 = '';
				this.matchedEle = [];
				this.variableIndex2 = 0;
				this.variableIndex1 = 0;
				this.var2 = '';
				this.isFlipped = true;
				this.attempts = 0;
				this.matched = 0;
				this.progress = 0;
				this.score = 0;
				this.getAttrData();
				//The following dispatch will make sure that by starting a new game after cliking the 'play again' button and changing to other language at app level will still continue to keep the "state" of new Game.
				this.pathFinderChallengeStore.dispatch({
					type:'PATHFINDER_CHALLENGE_VAL'
					,payload:{
						pathFinderChallenge:{
							attempts:this.attempts,
							progress:this.progress,
							matchedEle:this.matchedEle,
							matchedEle2:this.matchedEle2,
							cards:this.cards,
							cards2:this.cards2,
						}
					}
				});
			}
			if (result == 'library') {
				sessionStorage.setItem('modulename', 'Pathfinder');
				this.router.navigateByUrl('/app/library-occupations');
				this.router.navigate(['/app/library-occupations'], {
					relativeTo: this.activatedRoute,
					queryParams: {
						'module': 'Pathfinder'
					}
				});
			}
		});
	}
	flip() {
		this.stateName = (this.stateName == 'normal') ? 'spin' : 'normal';
	}
	shuffle() {
		for (let j, x, i = this.cards.length; i;) {
			j = Math.floor(Math.random() * i);
			x = this.cards[--i];
			this.cards[i] = this.cards[j];
			this.cards[j] = x;
			this.cards[j].state=false;
		}
		for (let j, x, i = this.cards2.length; i;) {
			j = Math.floor(Math.random() * i);
			x = this.cards2[--i];
			this.cards2[i] = this.cards2[j];
			this.cards2[j] = x;
			this.cards2[j].state=false;

		}
	}
	event(selectedCardObj, inx, set) {
		if (set == 1) {
			this.selectedcard1 = selectedCardObj.title;
			if (this.matchedEle.indexOf(inx) < 0) {
				if (this.i == 0) {
					this.myClassbtn[inx] = true;
					this.var1 = selectedCardObj.hollandCode;
					this.variableIndex1 = inx;
					this.i = 1;
					this.imageSelect1 = true;					
				} 
			}
		} else {
			this.selectedcard2 = selectedCardObj.title;
			if (this.matchedEle2.indexOf(inx) < 0) {
				if (this.j == 0) {
						this.myClassbtn2[inx] = true;
					this.var2 = selectedCardObj.hollandCode;
					this.variableIndex2 = inx;
					this.j = 1;
					this.imageSelect2 = true;					
				}
			}
		}
		selectedCardObj.state=true;
	}

	transitionEnd(image, inx, set){

		if(this.imageSelect1 || this.imageSelect2){
			if(set === 1) {
				this.counter1 = true;
			} else {
				this.counter2 = true;
			}
		}
		if (this.i == 1 && this.j == 1 && this.counter1 && this.counter2) {
			this.attempts++;
			this.myClassbtn2[this.variableIndex2] = true;
			this.myClassbtn[this.variableIndex1] = true;
			if (this.var1 == this.var2) {			
				this.selectedcard1 = '';
				this.selectedcard2 = '';
				this.matched++;
				this.progress = this.matched * 16.6667;
				this.matchedEle.push(this.variableIndex1);
				this.matchedEle2.push(this.variableIndex2);
				this.trasition1[this.variableIndex1] = 'matched';
				this.trasition2[this.variableIndex2] = 'matched';
				this.variableIndex1 = null;
				this.var1 = null;
				this.variableIndex2 = null;
				this.var2 = null;
				this.i = 0;
				this.j = 0;
				this.imageSelect1 = false;
				this.imageSelect2 = false;	
			} 
			else {
				setTimeout(() => {  

					this.selectedcard1 = '';
					this.selectedcard2 = '';
					this.myClassbtn2[this.variableIndex2] = false;
					this.myClassbtn[this.variableIndex1] = false;
					this.cards2[this.variableIndex2].state=false;
                    this.cards[this.variableIndex1].state=false;
					this.variableIndex1 = null;
					this.var1 = null;
					this.variableIndex2 = null;
					this.var2 = null;
					this.i = 0;
					this.j = 0;
					this.imageSelect1 = false;
					this.imageSelect2 = false;	
				}, 1000)
			}
			this.counter1 = false;
			this.counter2 = false;
			/* This condition is to find out the total matches to open model when challenge is completed */
			if (this.matched == 6) {
				this.score = 30 - (this.attempts - 6);
				this.modalOpen();
			}
		} 
	}

	saveChallenge() {
		const savedIds = [];
		for (let i = 0; i < this.cards.length; i++) {
			savedIds.push(this.cards[i].occId);
		}
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = 'user/holland/challenge/add';
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'POST';
		this.apiModel.data = {
			'score': this.score,
			'occIds': savedIds
		};
		this.pathFinderChallengeStore.dispatch({
			type:'PATHFINDER_CHALLENGE_VAL'
			,payload:{
				pathFinderChallenge:{
					attempts:this.attempts,
					progress:this.progress,
					matchedEle:this.matchedEle,
					matchedEle2:this.matchedEle2,
					cards:this.cards,
					cards2:this.cards2
				}
			}
		});
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe();
		} catch (e) {
			this.utils.handleError(`challenge-component.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	unsavedChanges() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.utils.backRoute();
	}

	audioClick(text,data) {
		this.audioService.audioFunction(text, data);
	}
	// Stop audio event method
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
			type: "SET_AUDIO_STOPED",
		});
		const evnt = document.createEvent("CustomEvent");
		this.eventService.dispatchAudioStop(evnt);
	}

	ngOnDestroy(){
		this.stopAudio();
		this.compActive = false;
    }

	private setPathfinderChallengeTitle(){
		this.titleService.setTitle("Pathfinder Challenge - CareerTrek");
	}
}
