<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{scoutHeader}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="audioClick('inside',pageData)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="scout-intro content-box">
      <div class="mobile-heading mobile-design-show">
        <div flex v-center justify-end>
          <button mat-fab color="primary" (click)="clickRoute()" [@flipAnimation]="completed">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
      <div class="scout-intro-body" fxLayout.gt-sm="row" fxLayout="column" fxLayoutWrap="wrap" fxLayoutAlign="center"
        *ngIf="categories">
        <mat-card fxFlex.gt-sm="30%" class="scout-intro-cards" *ngFor="let category of categories;let inx = index;">
          <mat-card-content class="card-body text-center">
            <img src="{{assetsUrl}}{{category.icon}}.svg" alt="explore-icon">
            <mat-card-title class="card-title" text-color="primary">
              <p mb-0 font-bold>{{category.title}}</p>
            </mat-card-title>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="desktop-design-show">
        <button mat-fab color="primary" (click)="clickRoute()" class="next-level" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>