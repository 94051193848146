import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject } from '@angular/core';
import * as jspdf from 'jspdf';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import { AvatarListState, AvatarProfileDataState, OccsDataState, PageDataState, UserAvatarState, AudioState, ModulesDataState, DreamCareerListState } from '../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiCallService } from '../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../shared/common-services/api-call-model';
import { OnLoadPopUpService } from '../../shared/common-services/onloadpopup.service';
import { OrderPipe } from 'ngx-order-pipe';
import { CustomDate, ModuleNamePipe } from '../../shared/common-pipes.pipes';
import html2pdf from 'html2pdf.js';
import { RouteUrls } from '../../shared/common-constants/route.constants';
import { Utilities } from '../../shared/common-services/utilities.service';
import { take, takeWhile } from 'rxjs/operators';
import { ProfileDataState } from '../../state-management/state/main-state';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AudioService } from '../../shared/common-constants/audioLoading.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from "@angular/platform-browser";

const base64 = require('base-64');
var encodedAvatar: string;
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-layout.html',
})
export class ProfileComponent {
  tabnames: any = [];
  keyWords: any = [];
  attributesData: any;
  myPic: any;
  CreateOwnOccs = [];
  quickpicCompleted: boolean = false;
  quickpic: any = [];
  theme_name: string;
  lang: string;
  subscription: any;
  favouriteoccs = true;
  ownoccs = false;
  dreamOccs = false;
  ImproveSkill: any = [];
  goodSkill: any = [];
  likedholland: any = [];
  UnlikeOccs: any = [];
  Likeoccs: any = [];
  thumbFalse: any = [];
  thumbTrue: any = [];

  safari = false;
  occList: any;
  careertrekkerScore: any;
  pathfinderScore: any;
  careertrekker: any = [];
  pathfinder: any = [];
  peopleScores: any = [];
  thingsScores: any = [];
  ideaScores: any = [];
  profileImage = '';
  UserDetails: any = [];
  thingsScore: any;
  peopleScore: any;
  ideaScore: any;
  audioState = 'stoped';
  audionotfound = false;
  @ViewChild('contentToConvert')
  contentToConvert: ElementRef;
  assetsURL: string;
  assetsPNGUrl: string;
  private sanitizer: DomSanitizer;
  private image: any;
  private readonly imageType: string = 'data:image/PNG;base64,';
  globalText: any;
  compActive = true;
  occMediaUrlForSmallRes: string;
  modules = ['compass', 'direction', 'globe', 'quick-pic'];
  emojiLists: AvatarListState['emojiFaceData'];
  userPic: any;
  loadCustomdata = false;
  activateModuleList = [];
  enabledModulesList = [];
  dreamCareersList = [];
  scoutCompletionSet: any = [];
  discovererCompletionSet: any = [];
  discovererCompletionText = '';
  careerCornerCount: any = [];
  navigatorCompletionText: string;
  navigatorCompletionSet: any = [];
  scoutGroupValue: string;
  // scoutGroupName: String;
  scoutGroupName: any = [];
  categories: any = [];
  scoutCompletionArray = [];
  navigationCompletionArray = [];
  images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
  careerCornerNothingShared: any;
  careerCornerShared: any;
  currentCareer: any;
  constructor(
    public dialog: MatDialog,
    private profileStore: Store<ProfileDataState>,
    public dialogService: DialogopenService,
    private snackBar: MatSnackBar,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private store: Store<OccsDataState>,
    private store1: Store<PageDataState>,
    private apiCall: ApiCallService,
    private orderPipe: OrderPipe,
    private customDate: CustomDate,
    private moduleNamePipe: ModuleNamePipe,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private apiModel: ApiCallClass,
    private utils: Utilities,
    private deviceService: DeviceDetectorService,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
    @Inject('ASSETS_MEDIA_PNG_URL') private assetsPNG_URL: string,
    @Inject('OCC_CARD_URL') private occMediaUrl: string,
    private avatarListStore: Store<AvatarListState>,
    private userAvatarData: Store<UserAvatarState>,
    private avatarProfileStore: Store<AvatarProfileDataState>,
    private moduleStore: Store<ModulesDataState>,
    private dreamCarrerList: Store<DreamCareerListState>,
    private titleService: Title
  ) {
    this.assetsURL = assetsUrl;
    this.assetsPNGUrl = assetsPNG_URL;
    this.occMediaUrlForSmallRes = occMediaUrl;
    // history.pushState(null, null, location.href);
    // window.onpopstate = function (event) {
    //     history.go(1);
    // };
    sessionStorage.setItem('theme_color', '');
    // sessionStorage.setItem('pageTitle', '');
    // sessionStorage.setItem('moduleKey', 'PROFILE##PROFILE');
    this.store1.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'PROFILE', pageCode: 'PROFILE' },
    });

    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') == '/app/profile') {
          if (e.type === 'languageChanged') {
            this.lang = sessionStorage.getItem('session_token');
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            this.keyWords = this.oninitpopupservice.getKeyWordData('PROFILE');
            this.globalText = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            this.tabnames = JSON.parse(
              sessionStorage.getItem('librarytabnames')
            );
            this.apiData();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            const evnt = document.createEvent('CustomEvent');
            this.eventService.dispatchAudioStop(evnt);
            this.unsavedChanges();
          }
        }
      });
    eventService
      .listenAudioStop()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        this.audioState = 'stoped';
        this.audionotfound = false;
      });

    if (this.lang === '' || this.lang == null) {
      this.lang = sessionStorage.getItem('session_token');
    }
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result) {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
    // Created store for profile Image
    this.profileStore.dispatch({
      type: 'PROFILE_IMAGE',
      payload: {
        methodVal: 'GET',
        module_Name: 'avatar',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/avatar/generate/me.png',
      },
    });

    this.avatarListStore.dispatch({
      type: 'AVATAR_TOTAL_LIST',
      payload: {
        methodVal: 'GET',
        module_Name: 'avatar',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'avatar/listParts',
      },
    });

    this.userAvatarData.dispatch({
      type: 'USER_AVATAR_DATA',
      payload: {
        methodVal: 'GET',
        module_Name: 'avatar',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/avatar',
      },
    });

    this.userAvatarData
      .select('userEmojiData')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((response) => {
        this.userPic = response;
        let endUrl = '';
        if (this.userPic && this.userPic.userEmojiData) {
          if (
            !(
              !this.userPic.userEmojiData.eyes &&
              !this.userPic.userEmojiData.nose &&
              !this.userPic.userEmojiData.mouth &&
              !this.userPic.userEmojiData.color
            )
          ) {
            endUrl = `avatar/generate/from/${this.userPic.userEmojiData.eyes}/${this.userPic.userEmojiData.nose}/${this.userPic.userEmojiData.mouth}/${this.userPic.userEmojiData.color}`;
            this.avatarProfileStore.dispatch({
              type: 'AVATAR_PROFILE_IMAGE',
              payload: {
                methodVal: 'GET',
                module_Name: 'avatar',
                path_params: [],
                query_params: {},
                sessionID: sessionStorage.getItem('session_token'),
                body_Params: {},
                endUrlVal: endUrl,
              },
            });
            // this.userPic.userEmojiData.eyes = 'eyes1'
            // this.userPic.userEmojiData.nose = 'nose1'
            // this.userPic.userEmojiData.mouth = 'mouth1'
            // this.userPic.userEmojiData.color = '#81bef1'
          }
        }
      });

    // this.store.dispatch({
    //     type: 'AVATAR_PROFILE_IMAGE', payload: {
    //       methodVal: 'GET', module_Name: 'avatar',
    //       path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
    //       body_Params: {}, endUrlVal: 'avatar/generate/from'
    //     }
    //   });

    this.moduleStore.dispatch({
      type: 'GET_MODULES',
      payload: {
        methodVal: 'GET',
        module_Name: '',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/components',
      },
    });

    this.dreamCarrerList.dispatch({
      type: 'GET_DREAMCAREERS',
      payload: {
        methodVal: 'GET',
        module_Name: 'discoverer',
        path_params: [],
        query_params: {},
        sessionID: sessionStorage.getItem('session_token'),
        body_Params: {},
        endUrlVal: 'user/dream-occs/list',
      },
    });

    this.avatarListStore
      .select('emojiFaceData')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((avatarFaceList) => {
        this.emojiLists = avatarFaceList;
      });

    this.apiData();
  }

  apiData() {
    this.dialogService.showLoading();
    this.keyWords = this.oninitpopupservice.getKeyWordData('PROFILE');
    this.tabnames = JSON.parse(sessionStorage.getItem('librarytabnames'));
    this.globalText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    this.apiModel.moduleName = 'explorer';
    this.apiModel.endUrl = '/user/profile/provide/portfolio';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.UserDetails = JSON.parse(resp);
          this.ideaScores = [];
          this.peopleScores = [];
          this.thingsScores = [];
          if (this.UserDetails) {

            if (this.UserDetails.modules.assessments.EXPLORER){
              const data =
                this.UserDetails.modules.assessments.EXPLORER.secondary.data;
            if (data.length >= 1 && data[0] != undefined) {
              for (let i = 0; i < data.length; i++) {
                if (data[i].name == 'EXPLORER_IDEAS') {
                  this.ideaScores = data[i].completions;
                }
                if (data[i].name == 'EXPLORER_PEOPLE') {
                  this.peopleScores = data[i].completions;
                }
                if (data[i].name == 'EXPLORER_THINGS') {
                  this.thingsScores = data[i].completions;
                }
              }
            }
          }

            if (
              this.UserDetails.modules.assessments.PATHFINDER &&
              this.UserDetails.modules.assessments.PATHFINDER.completions
                .length > 0
            ) {
              this.pathfinder =
                this.UserDetails.modules.assessments.PATHFINDER.completions;
            }
            if (
              this.UserDetails.modules.assessments.TREKKER &&
              this.UserDetails.modules.assessments.TREKKER.completions.length >
                0
            ) {
              this.careertrekker =
                this.UserDetails.modules.assessments.TREKKER.completions;
            }
            if (
              this.UserDetails.modules.assessments.QUICKPIC &&
              this.UserDetails.modules.assessments.QUICKPIC.completions.length >
                0
            ) {
              this.quickpic =
                this.UserDetails.modules.assessments.QUICKPIC.completions;
            }
            if (
              this.UserDetails.modules.assessments.SCOUT &&
              this.UserDetails.modules.assessments.SCOUT.secondary.data.length >
                0
            ) {
              this.scoutCompletionSet =
                this.UserDetails.modules.assessments.SCOUT.secondary.data;
              this.scoutGroupValue =
                this.UserDetails.modules.assessments.SCOUT.secondary.data[0].groups[0].displayName;
              this.scoutCompletionArray =
                this.UserDetails.modules.assessments.SCOUT.completion;

              this.apiModel.moduleName = 'Favoccs';
              this.apiModel.endUrl = '/occs/categories';
              this.apiModel.method = 'GET';
              this.apiModel.sessionID = this.lang;
              try {
                this.apiCall
                  .getData([this.apiModel])
                  .pipe(takeWhile(() => this.compActive))
                  .subscribe((resp) => {
                    this.categories = JSON.parse(resp);
                    if (this.categories) {
                      this.categories.forEach((element, inx) => {
                        if (element.title === this.scoutGroupValue) {
                          this.scoutGroupName = this.images[inx];
                        } else if (element.title === this.scoutGroupValue) {
                          this.scoutGroupName = this.images[inx];
                        } else if (element.title === this.scoutGroupValue) {
                          this.scoutGroupName = this.images[inx];
                        }
                      });
                    }
                  });
              } catch (e) {
                this.utils.handleError(`profile-component.ts apiData Favoccs: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
              }
            }

            if (
              this.UserDetails.modules.assessments.DISCOVERER &&
              this.UserDetails.modules.assessments.DISCOVERER.completions
                .length > 0
            ) {
              this.discovererCompletionSet =
                this.UserDetails.modules.assessments.DISCOVERER.completions;
              if (
                this.UserDetails.modules.assessments.DISCOVERER.secondary.data
                  .length > 0
              ) {
                this.discovererCompletionText =
                  this.UserDetails.modules.assessments.DISCOVERER.secondary.data[0].display;
              }
            }
            if (
              this.UserDetails.modules.assessments.CAREERCORNER &&
              this.UserDetails.modules.assessments.CAREERCORNER.completion
            ) {
              if (
                this.UserDetails.modules.assessments.CAREERCORNER.completion ===
                'Nothing shared'
              ) {
                this.careerCornerNothingShared =
                  this.UserDetails.modules.assessments.CAREERCORNER.completion;
              } else {
                this.careerCornerShared =
                  this.UserDetails.modules.assessments.CAREERCORNER.completion;
              }
            }
            // if (this.UserDetails.modules.assessments.CAREERCORNER && this.UserDetails.modules.assessments.CAREERCORNER.completions &&
            //     this.UserDetails.modules.assessments.CAREERCORNER.completions.length > 0) {
            //     this.careerCornerShared = this.UserDetails.modules.assessments.CAREERCORNER.completions;
            // }

            if (
              this.UserDetails.modules.assessments.CAREERCORNER &&
              this.UserDetails.modules.assessments.CAREERCORNER.ribbon.count
                .length > 0
            ) {
              if (
                this.UserDetails.modules.assessments.CAREERCORNER.ribbon.count
                  .length > 0
              ) {
                this.careerCornerCount =
                  this.UserDetails.modules.assessments.CAREERCORNER.ribbon.count;
              }
            }
            if (this.UserDetails.modules.assessments.NAVIGATOR) {
              if (
                this.UserDetails.modules.assessments.NAVIGATOR.secondary.data
                  .length > 0
              ) {
                this.navigatorCompletionText =
                  this.UserDetails.modules.assessments.NAVIGATOR.secondary.display;
                this.navigatorCompletionSet =
                  this.UserDetails.modules.assessments.NAVIGATOR.secondary.data;
                this.navigationCompletionArray =
                  this.UserDetails.modules.assessments.NAVIGATOR.completion;
              }
            }
            if (this.UserDetails.modules.assessments.NAVIGATOR) {
              if (
                this.UserDetails.modules.assessments.NAVIGATOR.secondary.data
                  .length > 0
              ) {
                this.navigatorCompletionText =
                  this.UserDetails.modules.assessments.NAVIGATOR.secondary.display;
                this.navigatorCompletionSet =
                  this.UserDetails.modules.assessments.NAVIGATOR.secondary.data;
                this.navigationCompletionArray =
                  this.UserDetails.modules.assessments.NAVIGATOR.completion;
              }
            }
            if (
              this.UserDetails &&
              this.UserDetails.modules &&
              this.UserDetails.modules.occupations &&
              this.UserDetails.modules.occupations.custom.count >= 0
            ) {
              this.apiModel.moduleName = 'explorer';
              this.apiModel.endUrl = '/user/custom-occs/list';
              this.apiModel.sessionID = sessionStorage.getItem('session_token');
              this.apiModel.method = 'GET';
              try {
                this.apiCall
                  .getData([this.apiModel])
                  .pipe(takeWhile(() => this.compActive))
                  .subscribe(
                    (resp) => {
                      this.CreateOwnOccs = JSON.parse(resp);
                      this.CreateOwnOccs = this.orderPipe.transform(
                        this.CreateOwnOccs,
                        'title'
                      );
                      if (this.CreateOwnOccs.length >= 0) {
                        this.dialogService.hideLoading();
                      }
                    },
                    (e) => {
                      this.dialogService.hideLoading();
                      this.utils.handleError(`profile-component.ts explorer: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                    }
                  );
              } catch (e) {
                this.utils.handleError(`profile-component.ts explorer exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
              }
            }
          }
          if (this.UserDetails.modules.occupations.favorite.count > 0) {
            // This condition is to stop executing the inside method when the custom career is getting deleted in the detailed view.
            if (!this.loadCustomdata) {
              this.occsCallText();
            }
          }
          // this.UserDetails.avatar;
          this.profileStore
            .select('profileImage')
            .pipe(takeWhile(() => this.compActive))
            .subscribe((profileState) => {
              if (profileState && profileState.profileImage) {
                try {
                  const reader = new FileReader();
                  reader.readAsDataURL(profileState.profileImage);
                  reader.onloadend = () => {
                    this.myPic = reader.result;
                  };
                } catch (e) {
                  this.utils.handleError(`profile-component.ts profileImage: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                }
              }
            });
          // This condition is to stop executing the inside method when the custom career is getting deleted in the detailed view.
          if (!this.loadCustomdata) {
            this.attributesCall();
          }
        });
    } catch (e) {
      this.utils.handleError(`profile-component.ts apiData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }

  ngOnInit() {
    this.moduleStore
      .select('modules')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        this.activateModuleList = [];
        if (result) {
          this.enabledModulesList = result;
          if (this.enabledModulesList['modules'].length > 0) {
            this.activateModuleList = this.enabledModulesList['modules'];
          }
        }
      });

    this.dreamCarrerList
      .select('dreamCareerList')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((list) => {
        this.dreamCareersList = list['dreamCareerList'];
      });
    //set profile view title
    this.setMyProfileTitle();
  }

  occsCallText() {
    this.dialogService.showLoading();
    this.apiModel.moduleName = 'Favoccs';
    this.apiModel.endUrl = 'user/occ/rate/list/filter/by/liked?lean=false';
    this.apiModel.sessionID = this.lang;
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          const data = JSON.parse(resp);
          if (data != undefined) {
            this.Likeoccs = [];
            if (data[0]) {
              for (let i = 0; i < data[0].occs.length; i++) {
                this.Likeoccs.push(data[0].occs[i]);
              }
            }
            this.keyWords = this.oninitpopupservice.getKeyWordData('PROFILE');
            this.tabnames = JSON.parse(
              sessionStorage.getItem('librarytabnames')
            );
            this.globalText = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
            this.Likeoccs = this.orderPipe.transform(this.Likeoccs, 'title');
            this.dialogService.hideLoading();
          }
        });
    } catch (e) {
      this.utils.handleError(`profile-component.ts occsCallText: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  attributesCall() {
    this.goodSkill = [];
    this.ImproveSkill = [];
    if (
      this.UserDetails.modules.assessments.PATHFINDER &&
      this.UserDetails.modules.assessments.PATHFINDER.secondary.data.length > 0
    ) {
      this.likedholland =
        this.UserDetails.modules.assessments.PATHFINDER.secondary.data;
    }
    if (
      this.UserDetails.modules.assessments.TREKKER &&
      this.UserDetails.modules.assessments.TREKKER.secondary.data.length > 0
    ) {
      for (
        let i = 0;
        i < this.UserDetails.modules.assessments.TREKKER.secondary.data.length;
        i++
      ) {
        if (
          this.UserDetails.modules.assessments.TREKKER.secondary.data[i].name ==
          'GOODAT'
        ) {
          for (
            let j = 0;
            j <
            this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
              .skills.length;
            j++
          ) {
            if (
              this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
                .skills[j].type == 'skill'
            ) {
              this.goodSkill.push(
                this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
                  .skills[j]
              );
            }
          }
        }
        if (
          this.UserDetails.modules.assessments.TREKKER.secondary.data[i].name ==
          'NEEDSIMPROVEMENT'
        ) {
          for (
            let j = 0;
            j <
            this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
              .skills.length;
            j++
          ) {
            if (
              this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
                .skills[j].type == 'skill'
            ) {
              this.ImproveSkill.push(
                this.UserDetails.modules.assessments.TREKKER.secondary.data[i]
                  .skills[j]
              );
            }
          }
        }
      }
    }

    this.apiModel.moduleName = 'attributes';
    this.apiModel.endUrl = '/attributes';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp != '' && resp != undefined && resp != null) {
            resp = JSON.parse(resp);
            this.attributesData = resp;
            this.keyWords = this.oninitpopupservice.getKeyWordData('PROFILE');
            this.tabnames = JSON.parse(
              sessionStorage.getItem('librarytabnames')
            );
            this.globalText = JSON.parse(
              sessionStorage.getItem('GLOBAL_SETTINGS')
            );
          }
        });
    } catch (e) {
      this.utils.handleError(`profile-component.ts attributesCall: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  unsavedChanges() {
    this.utils.backRoute();
    // this.router.navigateByUrl('/app/landing');
  }
  modalOpen(item, text:string, option?:string) {
    this.dialogService.showLoading();
    if (text === 'custom') {
      this.apiModel.moduleName = 'createownocc';
      this.apiModel.endUrl = 'user/custom-occ/' + item.occId;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'GET';
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            if (resp) {
              resp = JSON.parse(resp);
              item = resp;
              this.currentCareer = item;
              this.dialogService.hideLoading();
              this.dialogService.DetailOccPopup(
                item,
                this.attributesData,
                text,
                this.keyWords,
                this.categories
              );
              this.modalClose(item.occId, text);
            }
          });
      } catch (e) {
        this.utils.handleError(`profile-component.ts modalOpen: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    } else {
      this.dialogService.hideLoading();
      this.dialogService.DetailOccPopup(
        item,
        this.attributesData,
        text,
        this.keyWords,
        this.categories
      );
      this.modalClose(item.occId, text);
    }
  }

  modalClose(occId, occValue) {
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result === 'detailOccDelete') {
          this.deleteCustomCarrer(occId, occValue);
        } else if (result === 'careerShare') {
          this.shareCareer(occId);
        }
      });
  }

  shareCareer(occID) {
    if (this.currentCareer.shared) {
      this.snackBar.open('This Career is already shared!', '', {
        duration: 1000,
        panelClass: ['failure-snackbar'],
      });
    } else {
      this.currentCareer.shared = true;
      this.apiModel.moduleName = 'custom-occ';
      this.apiModel.endUrl = '/user/custom-occ/update/' + occID;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'PUT';
      this.apiModel.data = {
        title: this.currentCareer.title,
        description: this.currentCareer.description,
        shared: this.currentCareer.shared,
        media: {
          images: {
            title: 'string',
            sprite: this.currentCareer.media.images.sprite,
          },
        },
        tasks: {
          title: 'string',
          items: ['string'],
        },
        attributes: this.currentCareer.attributes,
      };
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            if (resp != undefined && resp != null) {
              this.snackBar.open('Career got shared successfully', '', {
                duration: 1000,
                panelClass: ['success-snackbar'],
              });
              this.currentCareer = {};
            }
          });
      } catch (e) {
        this.utils.handleError(`profile-component.ts shareCareer: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    }
  }
  // for deleting cutom carrer
  deleteCustomCarrer(id, occType) {
    if (occType !== 'like') {
      this.loadCustomdata = true;
      let moduleName = '';
      let endUrl = '';
      if (occType === 'custom') {
        endUrl = 'custom-occ';
      } else {
        endUrl = 'dream-occ';
      }
      this.dialogService.showLoading();
      this.apiModel.moduleName = occType;
      this.apiModel.endUrl = `user/${endUrl}/delete/${id}`;
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'DELETE';
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            this.dreamCarrerList.dispatch({
              type: 'GET_DREAMCAREERS',
              payload: {
                methodVal: 'GET',
                module_Name: 'discoverer',
                path_params: [],
                query_params: {},
                sessionID: sessionStorage.getItem('session_token'),
                body_Params: {},
                endUrlVal: 'user/dream-occs/list',
              },
            });

            this.apiData();
          });
      } catch (e) {
        this.utils.handleError(`profile-component.ts deleteCustomCarrer: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    } else {
      this.deletFavOcc(id);
    }
  }

  deletFavOcc(occId) {
    this.apiModel.endUrl = `user/occ/${occId}`;
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'DELETE';
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.occsCallText();
        });
    } catch (e) {
      this.utils.handleError(`profile-component.ts deletFavOcc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  avtarpopup() {
    const dialogRef = this.dialog.open(AvatarComponent, {
      width: '45vw',
      data: {
        dialog_data: this.emojiLists,
        page_theme_color: this.theme_name,
        myPic: this.myPic,
      },
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {});
  }
  async DownloadPDF() {
    this.deviceDetector();
    this.dialogService.showLoading();
    const data = document.getElementById('contentToConvert');
    const options = {
      filename: this.keyWords.menuTitle + '.pdf',
      pagebreak: {
        after: '.after-break',
        avoid: '.avoid-break',
        mode: ['auto', 'css', 'legacy'],
      },
      image: { type: 'jpeg', quality: 0.98 },
      margin: 0,
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      html2canvas: { scale: this.deviceDetector(), useCORS: true },
    };
    await html2pdf(data, options);
    this.dialogService.hideLoading();
  }
  deviceDetector() {
    const isDesktop = this.deviceService.isDesktop();
    const isTablet = this.deviceService.isTablet();
    if (
      (this.deviceService.getDeviceInfo().browser.toLowerCase() === 'safari' && isDesktop) ||
      (this.deviceService.getDeviceInfo().browser.toLowerCase() === 'chrome' && isTablet)
    ) {
      this.safari = true;
      return 1.8;
    } else {
      return 1.5;
    }
  }

  exploreOccLibrary() {
    this.router.navigate(['/app/library-occupations'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        module: 'All',
      },
    });
  }

  navigateToModule(module) {
    this.router.navigate(['../' + module + '/intro'], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy() {
    this.compActive = false;
  }

  private setMyProfileTitle() {
    this.titleService.setTitle('My Profile - CareerTrek');
  }
}

/********* start of avatar pop up ******* */
@Component({
    selector: 'app-avatar',
    template: `
    <div class="avatar-popup common-popup">
        <div class="common-popup-header">
            <h2 m-0 class="w-100" flex v-center h-center background-color="primary">
                <button mat-fab flex v-center h-center mat-dialog-close class="close-icon-button volume-up" (click)=modalClose()>
                    <i flex v-center class="material-icons" >close</i>
                </button>
                <span class="header-text w-100" flex v-center h-center>{{avatarData?.emojiBuilder}}</span>
            </h2>
        </div>
        <div class="avatar-popup-body">
            <div class="user-select-list">
                <div flex justify-between v-center>
                <h5>{{avatarData?.startEmoji}}</h5>
                <button mat-raised-button="" (click)="saveEmoji()" class="mat-raised-button save-button" ng-reflect-color="primary">
                <span class="mat-button-wrapper">{{globalSetting?.dictionary?.save}}</span>
                <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
                </div>
                <div fxLayout="row" fxLayout.xs="" fxFlexFill fxLayoutWrap="wrap" fxLayoutAlign="center" flex v-center h-center
                class="image-list">
                        <div class="profile-circle-image" flex v-center h-center>
                        <img class="w-100" src="{{myPic}}" />
                        </div>
                </div>
                <div class="color-sec">
                  <p>{{avatarData?.chooseColor}}:<img [(colorPicker)]="colorCode" cpOutputFormat="hex" (colorPickerChange)="avatarImageSelected($event,'picker','')" src="assets/Svg/colorpicker.png" width="30px"></p>
                </div>
                <div class="eyes-sec">
                <p>{{avatarData?.choosEyes}}:</p>
                    <div fxLayout="row" fxLayout.xs=""  fxLayoutWrap="wrap" flex v-center 
                    class="image-list">
                        <div *ngFor="let item of eyesArray;index as inx">
                            <div [ngClass]="(item.selected)? 'image-selected': ''" 
                            (click)="avatarImageSelected(item.imgName,'eyes',inx)" class="circle-image" pointer 
                            [class.active]="item.selected" flex v-center >
                            <img  class="w-100" [src]="item.img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="mouth-sec">
                    <p>{{avatarData?.chooseMouth}}:</p>
                    <div fxLayout.xs=""  fxLayoutWrap="wrap" flex v-center  
                    class="image-list">
                       <div *ngFor="let item of mouthArray;index as inx">
                            <div [ngClass]="(item.selected)? 'image-selected': ''"  
                            class="circle-image" pointer [class.active]="item.selected" flex v-center 
                            (click)="avatarImageSelected(item.imgName,'mouth',inx)">
                            <img class="w-100" [src]="item.img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `
})

export class AvatarComponent implements OnInit, OnDestroy {
    resp: any = [];
    subscription: any;
    images = [];
    ImgSelected = this.images[0];
    inx = 0;
    reader = [];
    compActive = true;
    profileData: any;
    statusClass = '';
    selectedIndex;
    myPic: any;
    avatarItem: any;
    emojiData: any;
    useEmojiData: any;
    eyesArray = [];
    mouthArray = [];
    userPic: any;
    selectedMouth = "";
    selectedEye = "";
    colorCode = "";
    pages: any;
    avatarData: any;
    globalSetting: any;

    constructor(
        public dialogRef: MatDialogRef<DialogopenService>,
        private avatarProfileStore: Store<AvatarProfileDataState>,
        private userAvatarData: Store<UserAvatarState>,
        private apiCall: ApiCallService,
        public eventService: EventdispatchService,
        private apiModel: ApiCallClass, private oninitpopupservice: OnLoadPopUpService,
        public dialogService: DialogopenService,
        private profileStore: Store<ProfileDataState>,
        public snackBar: MatSnackBar,
        private pagesData: Store<PageDataState>,
        private utils: Utilities,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.dialog_data != '') {
            this.dialogService.showLoading();
            this.emojiData = data.dialog_data;
            this.myPic = data.myPic
        }
        this.pagesData.select('pages').pipe(takeWhile(() => this.compActive)).subscribe(response => {
            this.pages = response.pages;
            if (response.pages) {
                const avatarIndex = this.pages.findIndex(x => x.pageCode === 'AVATAR_CREATOR');
                const globalIndex = this.pages.findIndex(x => x.pageCode === 'GLOBAL_SETTINGS');
                if (avatarIndex !== -1) {
                    this.avatarData = this.pages[avatarIndex].media;
                }
                if (globalIndex !== -1) {
                    this.globalSetting = this.pages[globalIndex];
                }
            }
        });

        this.userAvatarData.select('userEmojiData').pipe(takeWhile(() => this.compActive)).subscribe(response => {
            this.userPic = response;
            if (this.userPic && this.userPic.userEmojiData) {
                if (!this.userPic.userEmojiData.eyes && !this.userPic.userEmojiData.nose &&
                    !this.userPic.userEmojiData.mouth && !this.userPic.userEmojiData.color) {
                    this.selectedEye = 'eyes1';
                    this.selectedMouth = 'mouth1';
                    this.colorCode = '#81bef1';
                } else {
                    this.selectedEye = this.userPic.userEmojiData.eyes;
                    this.selectedMouth = this.userPic.userEmojiData.mouth;
                    this.colorCode = '#' + this.userPic.userEmojiData.color;
                }
            }
            if (!this.selectedEye && !this.selectedMouth && !this.colorCode) {
                this.selectedEye = 'eyes1'
                this.selectedMouth = 'mouth1'
                this.colorCode = '#d9edfe'
            }
        });

        this.avatarProfileStore.select('avatarProfileImage').pipe(takeWhile(() => this.compActive))
            .subscribe((profileState) => {
                if (profileState && profileState.avatarProfileImage) {
                    try {
                        const reader = new FileReader();
                        reader.readAsDataURL(profileState.avatarProfileImage);
                        reader.onloadend = () => {
                            this.myPic = reader.result;
                        };
                    } catch (e) {
                      this.utils.handleError(`profile-component.ts avatarProfileImage: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                    }
                }
            });

        this.getAvatarImage(this.emojiData.emojiFaceData.face.eyes, 'eyes');
        this.getAvatarImage(this.emojiData.emojiFaceData.face.mouth, 'mouth');


        //this.refreshMethod();
    }
    ngOnInit() {
        this.profileData = this.oninitpopupservice.getKeyWordData('PROFILE');
    }
    getAvatarImage(items, type) {
        let apiArray = [];
        items.map((item) => {
            let locApiMod: ApiCallClass = new ApiCallClass();
            locApiMod.moduleName = 'avatar';
            locApiMod.endUrl = 'avatar/parts/' + item;
            locApiMod.sessionID = sessionStorage.getItem('session_token');
            locApiMod.method = 'GET';
            apiArray.push(locApiMod)
        });
        try {
            let inx = 0;
            this.apiCall.getImage(apiArray).subscribe((responses: any) => {
                responses.map((resp) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(resp);
                    reader.onloadend = () => {
                        if (type === 'eyes') {
                            this.eyesArray.push({ imgName: this.emojiData.emojiFaceData.face.eyes[inx], img: reader.result, selected: false });
                        }
                        else if (type === 'mouth') {
                            this.mouthArray.push({ imgName: this.emojiData.emojiFaceData.face.mouth[inx], img: reader.result, selected: false });

                        }
                        if (this.eyesArray.length === this.emojiData.emojiFaceData.face.eyes.length) {
                            if (this.userPic && this.userPic.userEmojiData && this.userPic.userEmojiData.eyes) {
                                let index = this.emojiData.emojiFaceData.face.eyes.indexOf(this.userPic.userEmojiData.eyes);

                                if (index != -1)
                                    this.eyesArray[index].selected = true;
                            } else {
                                this.eyesArray[0].selected = true;
                            }
                        }
                        if (this.mouthArray.length === this.emojiData.emojiFaceData.face.mouth.length) {
                            if (this.userPic && this.userPic.userEmojiData && this.userPic.userEmojiData.mouth) {
                                let index = this.emojiData.emojiFaceData.face.mouth.indexOf(this.userPic.userEmojiData.mouth);

                                if (index != -1)
                                    this.mouthArray[index].selected = true;
                            } else {
                                this.mouthArray[0].selected = true;
                            }

                            this.dialogService.hideLoading();
                        }
                        inx++;
                    };

                })

            });
        } catch (e) {
          this.utils.handleError(`profile-component.ts getAvatarImage: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    ngOnDestroy() {
        this.compActive = false;
    }

    avatarImageSelected(item, type, inx) {
        if (type === 'eyes') {
            this.selectedEye = item;
            this.eyesArray.forEach(function (obj, i) {
                if (i === inx) {
                    obj.selected = true;
                } else {
                    obj.selected = false;
                }
            });
            this.colorCode = this.colorCode.replace('#', '');
        } else if (type === 'mouth') {
            this.selectedMouth = item;
            this.mouthArray.forEach(function (obj, i) {
                if (i === inx) {

                    obj.selected = true;
                } else {
                    obj.selected = false;
                }
            });
            this.colorCode = this.colorCode.replace('#', '');
        } else {
            this.colorCode = item.replace('#', '');
        }
        this.userPic.userEmojiData = {
            nose: 'nose1'
        }
        const endUrl = `avatar/generate/from/${this.selectedEye}/${this.userPic.userEmojiData.nose}/${this.selectedMouth}/${this.colorCode}`;
        this.avatarProfileStore.dispatch({
            type: 'AVATAR_PROFILE_IMAGE', payload: {
                methodVal: 'GET', module_Name: 'avatar',
                path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
                body_Params: {}, endUrlVal: endUrl
            }
        });

    }

    saveEmoji() {
        this.colorCode = this.colorCode.replace('#', '');
        let encodedUrl = `adorable/${this.selectedEye}/nose2/${this.selectedMouth}/${this.colorCode}`;
        this.apiModel.moduleName = 'avatar';
        this.apiModel.endUrl = 'user/avatar';
        this.apiModel.sessionID = sessionStorage.getItem('session_token');
        this.apiModel.method = 'PUT';
        this.apiModel.data = {
            'encodedAvatar': base64.encode(encodedUrl)
        };
        try {
            this.apiCall.saveProfileEmoji([this.apiModel]).pipe(take(1)).subscribe((resp) => {
                if (resp != undefined && resp != null) {
                    this.snackBar.open(this.avatarData.savedEmoji, '', {
                        duration: 3000,
                        panelClass: ['success-snackbar']
                    });
                    this.userAvatarData.dispatch({
                        type: 'USER_AVATAR_DATA', payload: {
                            methodVal: 'GET', module_Name: 'avatar',
                            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
                            body_Params: {}, endUrlVal: 'user/avatar'
                        }
                    });
                    this.profileStore.dispatch({
                        type: 'PROFILE_IMAGE', payload: {
                            methodVal: 'GET', module_Name: 'avatar',
                            path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
                            body_Params: {}, endUrlVal: 'user/avatar/generate/me.png'
                        }
                    });


                }
            });
        } catch (e) {
          this.utils.handleError(`profile-component.ts saveEmoji: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }
    modalClose() {
        this.userAvatarData.dispatch({
            type: 'USER_AVATAR_DATA', payload: {
                methodVal: 'GET', module_Name: 'avatar',
                path_params: [], query_params: {}, sessionID: sessionStorage.getItem('session_token'),
                body_Params: {}, endUrlVal: 'user/avatar'
            }
        });
    }
}