
/**Import angular core packages */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-quickpic',
	template: `<app-static-header></app-static-header>
	<router-outlet></router-outlet>
	<app-static-footer></app-static-footer>`
})

export class QuickpicComponent implements OnInit {


	constructor() {
		// history.pushState(null, null, location.href);
		// window.onpopstate = function (event) {
		// 	history.go(1);
		// };
	}

	ngOnInit() {

	}
}
