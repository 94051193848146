import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import { EventdispatchService } from '../common-modal/eventdispach.service';
import { Utilities } from './utilities.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public dialogPopUp: DialogopenService,
    private eventService: EventdispatchService, private utils: Utilities) {

  }
  errorStatus = {
    401: 'Unauthorized',
    404: 'Not found',
    500: 'Internal server error',
    502: 'HTTP Error (Bad Gateway Errors)',
    304: 'Not Modified',
    305: 'Use proxy',
    503: 'HTTP Error (Service Unavailable)',
    504: 'HTTP Error (Gateway Timeout Error)',
    204: 'No content',
  };
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = sessionStorage.getItem('session_token');

    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }
    req = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache')});
    req = req.clone({ headers:req.headers.set('Pragma', 'no-cache')});
    req = req.clone({ headers:req.headers.set('Expires', '0')});
    if (!req.headers.has('Content-Type') && sessionStorage.getItem('Imageurl') != 'true') {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
      req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    } else if (!req.headers.has('Content-Type') && sessionStorage.getItem('Imageurl') == 'true') {
      req = req.clone({ headers: req.headers.set('Content-Type', 'image/png') });
      req = req.clone({ headers: req.headers.set('Content-Encoding', 'image/png') });
      sessionStorage.setItem('Imageurl', 'false');
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (e: any) => {
        this.utils.handleError(`authentication-interceptors.ts: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }));
  }
}
