<app-static-header>
</app-static-header>
<div class="Career-trek-content landing-img scroll-landing">
  <div class="conatiner text-center">
    <h4 class="landing-page-heading">{{launchHeader}}</h4>
  </div>
  <div class="container">
    <div class="landing-page content-box" *ngIf="pageTitle[1]!=undefined&&pageTitle[1]!=null">
      <div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="center" fxFlexFill class="common-row carousel-section">
        <div #landingCarouselId class="shared-occs-carousel" [ngClass]="{
          'shared-occs-carousel':activateChildModuleList.length>=2,
          'carousel-onecard-width':activateChildModuleList.length === 1
          
        }" flex [ngStyle]="{'left': left + 'px','right': right + 'px','transition':'500ms ease-in-out left'}">


          <!--Trying Carousel with One Card-->
          <div *ngFor='let item of activateChildModuleList;let index = index'>
            <div class="carousel">
              <div class="carousel-card">
                <div class="carousel-width m-auto" [ngClass]="{
              'carousel-width':activateChildModuleList.length>3,
              'carousel-onecard-width':activateChildModuleList.length === 1,
              'carousel-twocard-width':activateChildModuleList.length === 2,
              'carousel-threecard-width':activateChildModuleList.length >= 3
            }">
                  <div class="card image-card" tabindex="0">
                    <div fxFlex="" fxFlex.sm="49%" fxFlex.xs="49%">
                      <mat-card class="card carousel-card-width" [ngClass]="{
                      'carousel-card-width':activateChildModuleList.length>3,
                      'carousel-onecard-width':activateChildModuleList.length === 1,
                      'carousel-twocard-width':activateChildModuleList.length === 2,
                      'carousel-threecard-width':activateChildModuleList.length >= 3
                    }">
                        <div pointer (click)="changeTheme(index,index + 1);routeChange(item?.routeChange)">
                          <div class="card-header {{item.headerColor}}">
                            <span>{{item?.displayName}}</span>
                            <span class="checkmark-box" 
                              *ngIf="(item?.name=='Explorer' && ideaScores==true && thingsScores==true && peopleScores==true)"><i
                                class="material-icons success-tick">done</i></span>
                            <span class="checkmark-box" *ngIf="item?.name=='Pathfinder' && pathfinder?.length>0"><i
                                class="material-icons success-tick">done</i></span>
                            <span class="checkmark-box" *ngIf="item?.name=='Trekker' && careertrekker?.length>0"><i
                                class="material-icons success-tick">done</i></span>
                            <span class="checkmark-box" *ngIf="item?.name=='Discoverer' && discoverer?.length>0"><i
                                class="material-icons success-tick">done</i></span>
                            <span class="checkmark-box" *ngIf="item?.name=='QuickPic' && quickPicCompletionSet?.length>0"><i
                                  class="material-icons success-tick">done</i></span>
                            <span class="checkmark-box" *ngIf="item?.name=='Scout' && scout?.length>0"><i
                                class="material-icons success-tick">done</i></span>
                                <span class="checkmark-box" *ngIf="item?.name=='Navigator' && navigatorCompletionSet?.length>0"><i
                                  class="material-icons success-tick">done</i></span>
                            <!-- <span class="checkmark-box" *ngIf="item?.name=='CareerCorner' && careercorner?.length>0"><i
                                class="material-icons success-tick">done</i></span> -->
                          </div>
                          <div class=" card-body {{item?.bodyColor}} " flex v-center h-center tabindex="0"
                            (keyup.enter)="changeTheme(index,index + 1);routeChange(item?.routeChange)">
                            <div class="circle-image {{item.bodyColor}}" flex v-center h-center
                            [ngClass]="(item?.name==='Navigator')? 'navigator-module-image': ''">
                              <img class="jump"
                                src="{{assetsSVG_URL}}{{item?.imageUrl}}" alt="{{item?.moduleName}}" />
                              <!-- <img *ngIf="item?.name==='Pathfinder'" class="jump" src="assets/Svg/{{item?.imageUrl}}"
                                alt="{{item?.moduleName}}" /> -->
                            </div>
                          </div>
                        </div>
                        <!----------------->
                        <div *ngIf="item?.name==='Pathfinder'" class="create-own-occ" pointer
                          (click)="modelOpen('pathfinder', 1)" (keyup.enter)="modelOpen('pathfinder', 1)">
                          <div class="occupation-section path-finder" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img *ngIf="pathfinder?.length>0" src="assets/images/pencil-icon-Pathfinder.svg"
                                alt="create-occupation-image" />
                              <i class="material-icons" *ngIf="pathfinder?.length==0">lock</i>
                              <span flex v-center h-center class="text-center skyblue-text" font-bold>
                                {{keywords?.dictionary.createOcc}}</span>
                            </p>
                          </div>
                        </div>
                        <!------------------>
                        <div *ngIf="item?.name==='Trekker' " class="create-own-occ" pointer
                          (click)="modelOpen('careertrekker', 2)" (keyup.enter)="modelOpen('careertrekker',2)">
                          <div class="occupation-section career-trekker" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img *ngIf="careertrekker?.length>0" src="assets/images/pencil-icon-Career-trek.svg"
                                alt="create-occupation-image" />
                              <i class="material-icons" *ngIf="careertrekker?.length==0">lock</i>
                              <span flex v-center h-center class="text-center pink-text" font-bold>
                                {{keywords?.dictionary.createOcc}}</span>
                            </p>
                          </div>
                        </div>
                        <!------------------>
                        <div *ngIf="item?.name==='Explorer'" class="create-own-occ" pointer
                          (click)="modelOpen('explorer', 0)" (keyup.enter)="modelOpen('explorer',0)">
                          <div class="occupation-section explorer" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img *ngIf="(ideaScores==true  && thingsScores==true && peopleScores==true)"
                                src="assets/images/pencil-icon-Explorer.svg" alt="create-occupation-image" />
                              <i class="material-icons"
                                *ngIf="!(ideaScores==true  && thingsScores==true&&peopleScores==true)">lock</i>
                              <span flex v-center h-center class=" text-center yellow-text" font-bold>
                                {{keywords?.dictionary.createOcc}}</span>
                            </p>
                          </div>
                        </div>
                        <!------------------>
                        <div *ngIf="item?.moduleName==='Discoverer'" class="create-own-occ" pointer
                          (click)="modelOpen('discoverer', 6)" (keyup.enter)="modelOpen('discoverer',6)">
                          <div class="occupation-section violet" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img *ngIf="discoverer.length>0" src="{{assetsSVG_URL}}discover-pencil-icon.svg" alt="pencil-icon" />
                              <i class="material-icons"
                              *ngIf="discoverer.length == 0">lock</i>
                              <span flex v-center h-center class="text-center career-title" font-bold>
                                {{keywords?.dictionary?.createOcc}}</span>
                            </p>
                          </div>
                        </div>
                        <div *ngIf="item?.moduleName==='Navigator'" class="create-own-occ" pointer
                          (click)="modelOpen('navigator', 8)" (keyup.enter)="modelOpen('navigator',8)">
                          <div class="occupation-section red-orange" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img  *ngIf="navigatorCompletionSet.length>0" src="./assets/Svg/pencil_navigator.svg" alt="pencil-icon" />
                              <i class="material-icons"
                              *ngIf="navigatorCompletionSet.length == 0">lock</i>
                              <span flex v-center h-center class="text-center career-title" font-bold>
                                {{keywords?.dictionary?.createOcc}}</span>
                            </p>
                          </div>
                        </div>

                        <div *ngIf="item?.moduleName==='Scout'" class="create-own-occ" pointer
                          (click)="modelOpen('scout', 7)" (keyup.enter)="modelOpen('scout',7)">
                          <div class="occupation-section apple-green" flex v-center tabindex="0">
                            <p m-0 class="w-100" flex v-center h-center>
                              <img  *ngIf="scout.length>0" src="{{assetsSVG_URL}}scout-pencil-icon.svg" alt="pencil-icon" />
                              <i class="material-icons"
                              *ngIf="scout.length == 0">lock</i>
                              <span flex v-center h-center class="text-center career-title" font-bold>
                                {{keywords?.dictionary?.createOcc}}</span>
                            </p>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="activateChildModuleList.length>3"class="left lg-down-chevrolet" 
            (click)="indexForCarousel > 0? indexForCarousel=indexForCarousel-1:'';indexForCarousel >= 0 && activateChildModuleList.length===1? left = left +  600:'';indexForCarousel >= 0 && activateChildModuleList.length===1? right = right +  600:'';indexForCarousel >= 0 && activateChildModuleList.length===2? left = left +  401:'';indexForCarousel >= 0 && activateChildModuleList.length===2? right = right +  401:'';indexForCarousel >= 0 && activateChildModuleList.length>=3? left = left +  234:'';indexForCarousel >= 0 && activateChildModuleList.length>=3? right = right +  234:'' "
            (keyup.enter)="indexForCarousel > 0? indexForCarousel=indexForCarousel-1:'';indexForCarousel >= 0? left = left +  600:'';indexForCarousel >= 0? right = right +  600:'' ">
            <i class="material-icons" pointer text-color="primary">keyboard_arrow_left</i>
            </div> -->
          <!-- <div *ngIf="activateChildModuleList.length>3"class="right lg-down-chevrolet" 
              (click)="indexForCarousel <= arrayForCarousel.length-1? indexForCarousel=indexForCarousel+1:''; indexForCarousel <= arrayForCarousel.length-1 && activateChildModuleList.length===1? left = left - 600:'';indexForCarousel <= arrayForCarousel.length-1&& activateChildModuleList.length===1? right = right - 600:'';indexForCarousel <= arrayForCarousel.length-1 && activateChildModuleList.length===2? left = left - 401:'';indexForCarousel <= arrayForCarousel.length-1&& activateChildModuleList.length===2? right = right - 401:'';indexForCarousel <= arrayForCarousel.length-1 && activateChildModuleList.length>=3? left = left - 234:'';indexForCarousel <= arrayForCarousel.length-1&& activateChildModuleList.length>=3? right = right - 234:''; "
              (keyup.enter)="indexForCarousel <= arrayForCarousel.length-1? indexForCarousel=indexForCarousel+1:'';indexForCarousel <=  arrayForCarousel.length-1? left = left -  600:'';indexForCarousel <=  arrayForCarousel.length-1? right = right -  600:'' ">
              <i class="material-icons" pointer text-color="primary">keyboard_arrow_right</i>

            </div> -->
        </div>
        <div *ngIf="activateChildModuleList.length>3" class="left" (click)=" leftNavigation()"
          (keyup.enter)="indexForCarousel > 0? indexForCarousel=indexForCarousel-1:'';indexForCarousel >= 0? left = left +  600:'';indexForCarousel >= 0? right = right +  600:'' ">
          <i class="material-icons" pointer text-color="primary">keyboard_arrow_left</i>
        </div>
        <div *ngIf="activateChildModuleList.length>3" class="right" (click)="rightNavigation()"
          (keyup.enter)="indexForCarousel <= activateChildModuleList.length-1? indexForCarousel=indexForCarousel+1:'';indexForCarousel <=  activateChildModuleList.length-1? left = left -  600:'';indexForCarousel <=  activateChildModuleList.length-1? right = right -  600:'' ">
          <i class="material-icons" pointer text-color="primary">keyboard_arrow_right</i>
        </div>
        <!-- <div fxFlex="" fxFlex.sm="49%" fxFlex.xs="49%" class="two-cards desktop-design-show">
          <mat-card class="card occupation-library"  pointer (click)="changeTheme(3,5);explorerOccs()" (keyup.enter)="changeTheme(3,5);explorerOccs()">
            <div class="card-header header-teal desktop-design-show">{{keywords?.dictionary?.occupationLibrary}}</div>
            <div class=" card-body teal" flex v-center h-center  tabindex="0"
              flex v-center h-center>
              <div class="circle-image teal" flex v-center h-center>
                <img class="jump" src="assets/Svg/binoculars.svg" alt="occlibrary" />
              </div>
            </div>
            <div class="mobile-design-show opacity-color teal" flex v-center h-center>
              <p m-0>{{keywords?.dictionary?.occupationLibrary}}</p>
            </div>
          </mat-card>
          <mat-card class="card desktop-design-show create-occupation" pointer (click)="changeTheme(4,4);routequicpic()" (keyup.enter)="changeTheme(4,4);routequicpic()">
            <div class="card-header header-indigo desktop-design-show">
              <span>{{pageTitle[3]}}</span>
              <span *ngIf="quickpicCompletion?.length>0" class="checkmark-box"><i class="material-icons success-tick">done</i></span>
            </div>
            <div class=" card-body indigo" flex v-center h-center tabindex="0" >
              <div class="circle-image indigo" flex v-center h-center>
                <img class="jump" src="assets/Svg/quick-pic.svg" alt="quickpic" />
                
              </div>
            </div>
          </mat-card>
          <mat-card class="card tab-design-show" pointer (click)="changeTheme(3,5);explorerOccs()" (keyup.enter)="changeTheme(3,5);explorerOccs()">
            <div class="card-header header-teal">{{keywords?.dictionary?.occupationLibrary}}</div>
            <div class=" card-body teal" flex v-center h-center>
              <div class="circle-image teal" flex v-center h-center pointer tabindex="0" >
                <img class="jump" src="assets/Svg/binoculars.svg" alt="quickpic" />
              </div>
            </div>
          </mat-card>
        </div>
        <div fxFlex="" fxFlex.sm="49%" class="tab-design-show">
          <mat-card class="card" pointer (click)="changeTheme(4,4);routequicpic()">
            <div class="card-header header-indigo">                  
              <span>{{pageTitle[3]}}</span>
              <span *ngIf="quickpicCompletion?.length>0" class="checkmark-box"><i class="material-icons success-tick">done</i></span>
            </div>
            <div class=" card-body indigo" flex v-center h-center>
              <div class="circle-image indigo" flex v-center h-center  >
                <img class="jump" src="assets/Svg/quick-pic.svg" alt="quickpic" />
              </div>
            </div>
          </mat-card>
        </div>
      </div>
       -->
      </div>
    </div>
  </div>
  <app-static-footer *ngIf="pageTitle[1]!=undefined&&pageTitle[1]!=null"></app-static-footer>