import { Component, OnInit } from "@angular/core";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";
import { Next } from "../../../shared/common-animations/CT_animations";
import { takeWhile } from "rxjs/operators";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { AudioState, NavigatorChallengeState, PageDataState } from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { Router, ActivatedRoute } from '@angular/router';
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-challenge",
  templateUrl: "./navigator-challenge.component.html",
  animations: [Next],
})
export class NavigatorChallengeComponent implements OnInit {
  headerAudioState = 'stoped';
  headerAudionotfound = false;
  quesAudioState = 'stoped';
  quesAudionotfound = false;
  ansAudioState = ['stoped', 'stoped'];
  ansAudionotfound = [false, false];
  completed: boolean;
  nextIcon = "";
  theme_name: string;
  keywords: any;
  compActive = true;
  navigatorChallengeText: any = {};
  challengeQuestions = [];
  challengeQuestions2 = [];
  challengeQuestionsOption: any = {};
  indexVal = 0;
  selectedAnswers = [];
  selectedRadio = '';
  curAudio: string;
  ansInx: any;
  languageChanged = "";
  language : any;
  constructor(
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    private navigatorChallengeState: Store<NavigatorChallengeState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public store: Store<PageDataState>, private apiModel: ApiCallClass,
    private apiCall: ApiCallService, public dialogService: DialogopenService, private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.languageChanged = sessionStorage.getItem("languageSet");
    if(!this.languageChanged){
      this.languageChanged = "en";
    }
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_CHALLENGE" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/challenge") {
          if (e.type === "languageChanged") {
            this.language = sessionStorage.getItem('language')
            this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
            this.languageChanged = sessionStorage.getItem("languageSet");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.getNavigatorChallengeAfterLanguageChange();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.stopAudio();
            if (this.indexVal <= 0) {
              this.unsavedChanges();
            } else {
              this.indexVal--;
              this.selectedRadio = this.selectedAnswers[this.indexVal];
              this.challengeQuestionsOption = this.challengeQuestions[this.indexVal];
              this.nextIcon = 'yes';
            }
            // this.unsavedChanges();
          }
        }
      });
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
            this.quesAudioState = "stoped";
            this.quesAudionotfound = false;
            this.ansAudioState = ["stoped", "stoped"];
            this.ansAudionotfound = [false, false];
          } else if (this.curAudio === "question") {
            this.quesAudioState = result.audioState;
            this.quesAudionotfound = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
            this.ansAudioState = ["stoped", "stoped"];
            this.ansAudionotfound = [false, false];
          } else if (this.curAudio === "answer") {
            this.ansAudioState[this.ansInx] = result.audioState;
            this.ansAudionotfound[this.ansInx] = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
            this.quesAudioState = "stoped";
            this.quesAudionotfound = false;
          }
        }
      });
    this.navigatorChallengeState.select('navigatorChallengeData').pipe(takeWhile(() => this.compActive)).subscribe(storeresp => {
      this.selectedAnswers = storeresp['navigatorChallengeData'].storedData;
      this.challengeQuestions = storeresp['navigatorChallengeData'].challengeQuestions;
      if (this.selectedAnswers.length > 0) {
        this.indexVal = this.selectedAnswers.length - 1
        this.challengeQuestionsOption = this.challengeQuestions[this.indexVal];
        this.selectedRadio = this.selectedAnswers[this.indexVal];
        this.nextIcon = 'yes';
      } else {
        this.getNavigatorChallengeQuestions();
      }
    });
  }

  ngOnInit() {
    this.language = sessionStorage.getItem('language')
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.keywords = JSON.parse(
            sessionStorage.getItem("GLOBAL_SETTINGS")
          );
          this.navigatorChallengeText = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_CHALLENGE"
          );
        }
      });

      this.setNavigatorChallengeTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }
// This method will update the challenge questions language and selected answers after language changed.
   getNavigatorChallengeAfterLanguageChange(){
    this.challengeQuestions2 = [];
    let latestChallengeQuestions: any = [];
    this.apiModel.moduleName = 'navigatorChallenge';
    this.apiModel.endUrl = 'Rathers/random?numbItems=53';
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
        if (resp) {
          this.challengeQuestions2 = JSON.parse(resp);
          this.dialogService.hideLoading();
          this.challengeQuestions.forEach(item => {
            let inx = this.challengeQuestions2.findIndex(obj => obj.ratherId === item.ratherId)
            latestChallengeQuestions.push(this.challengeQuestions2[inx])
          });
          this.selectedAnswers.forEach((item, index) => {
            let selectedInx = this.challengeQuestions[index].options.findIndex(ans => ans.answer == item)
            this.selectedAnswers[index] = latestChallengeQuestions[index].options[selectedInx].answer
          })
          this.selectedRadio = this.selectedAnswers[this.indexVal]
          this.challengeQuestions = latestChallengeQuestions;
          this.challengeQuestionsOption = latestChallengeQuestions[this.indexVal];
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-component.ts getNavigatorChallengeAfterLanguageChange: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  getNavigatorChallengeQuestions() {
    this.dialogService.showLoading();
    this.stopAudio();
    this.challengeQuestions = [];
    this.challengeQuestionsOption = [];
    this.apiModel.moduleName = 'navigatorChallenge';
    this.apiModel.endUrl = 'Rathers/random?numbItems=8';
    this.apiModel.method = 'GET';
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
        if (resp) {
          this.challengeQuestions = JSON.parse(resp);
          if ((new Set(this.challengeQuestions)).size != this.challengeQuestions.length) {
            this.getNavigatorChallengeQuestions()
          }
          this.challengeQuestionsOption = this.challengeQuestions[this.indexVal];
          this.dialogService.hideLoading();
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-challenge.component.ts getNavigatorChallengeQuestions: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  addActivity(inxVal, optionKey) {
    if (this.selectedAnswers[inxVal] === optionKey) {
      //Here setTimeout is required without this empty value is not getting assigned
      setTimeout(() => this.selectedRadio = '', 100);
      this.selectedAnswers.splice(inxVal, 1);
      this.nextIcon = 'no';
    } else {
      this.nextIcon = 'yes';
      if (this.selectedAnswers[inxVal]) {
        this.selectedAnswers[inxVal] = optionKey;
      } else {
        this.selectedAnswers.push(optionKey);
      }
    }
  }

  clickArrowRoute() {
    this.nextIcon = 'no';
    this.indexVal++;
    this.selectedRadio = ''
    this.stopAudio();
    this.challengeQuestionsOption = this.challengeQuestions[this.indexVal];
    if (this.indexVal === this.challengeQuestions.length) {
      this.dialogService.navigatorActivitySuccessModal(this.navigatorChallengeText.dictionary.anotherRound);
      this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
        if (result === 'nextRound') {
          this.navigatorChallengeState.dispatch({
            type: 'NAVIGATOR_CHALLENGE_VAL'
            , payload: {
              navigatorChallengeData: {
                storedData: this.selectedAnswers,
                challengeQuestions: this.challengeQuestions
              }
            }
          });
          //Navigate to occs list page
          sessionStorage.setItem('modulename', 'navigator');
          this.router.navigate(['/app/library-occupations'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              'module': 'Navigator'
            }
          });
        }
        else {
          if (result === 'playAgainRound') {
            this.indexVal = 0;
            this.navigatorChallengeState.dispatch({
              type: 'NAVIGATOR_CHALLENGE_VAL'
              , payload: {
                navigatorChallengeData: {
                  storedData: [],
                  challengeQuestions: []
                }
              }
            });
            this.getNavigatorChallengeQuestions();
          }
        }
      });
    }

  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_CHALLENGE");
      }.bind(this)
    );
  }

  headerAudioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }
  quesAudioClick(text, data) {
    this.curAudio = "question";
    this.audioService.audioFunction(text, data);
  }
  ansAudioClick(text, data, ansInx) {
    this.curAudio = "answer";
    this.ansInx = ansInx
    var data1 = [];
    data1[0] = data[0].src;
    data1[1] = data[1].src;
    this.ansAudioState = ["stoped", "stoped"];
    this.ansAudionotfound = [false, false];
    this.audioService.audioFunction(text, data1, false, "pipe");
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.quesAudioState = "stoped";
    this.quesAudionotfound = false;
    this.ansAudioState = ["stoped", "stoped"];
    this.ansAudionotfound = [false, false];
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setNavigatorChallengeTitle(){
    this.titleService.setTitle("Navigator Challenge - CareerTrek");
  }
}
