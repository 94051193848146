import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
/** rxjs imports */
import { Observable, of, throwError } from 'rxjs';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { forkJoin, combineLatest, map, filter } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { ApiCallClass } from '../../shared/common-services/api-call-model';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';
import * as jwt_decode from 'jwt-decode';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import {
  OccsDataState,
  SiteDataState,
} from '../../state-management/state/main-state';
import { Utilities } from './utilities.service';
import { take } from 'rxjs/operators';
import { LogOutPostData, LogoutResponse } from '../models/sharedModels';
const api_url = environment.API_BASE_URL;
const auth_url = environment.AUTH_BASE_URL;
@Injectable()
export class ApiCallService {
  subscription = new Subscription();
  baseurl = '';
  expiredTime: any;
  eventTime: any;
  newToken;

  cardsArray = [
    {
      routeChange: '/app/explorer/intro',
      name: 'Explorer',
      imageUrl: 'globe.svg',
      headerColor: 'header-yellow',
      bodyColor: 'yellow',
    },
    {
      routeChange: '/app/pathfinder/intro',
      name: 'Pathfinder',
      imageUrl: 'compass.svg',
      headerColor: 'header-sky-blue',
      bodyColor: 'sky-blue',
    },
    {
      routeChange: '/app/careertrekker/intro',
      name: 'Trekker',
      imageUrl: 'direction.svg',
      headerColor: 'header-pink',
      bodyColor: 'pink',
    },
    {
      routeChange: '/app/discoverer/intro',
      name: 'Discoverer',
      imageUrl: 'discoverer.svg',
      headerColor: 'header-violet',
      bodyColor: 'violet',
    },
    {
      routeChange: '/app/navigator/intro',
      name: 'Navigator',
      imageUrl: 'navigator.svg',
      headerColor: 'header-red-orange',
      bodyColor: 'red-orange',
    },
    {
      routeChange: '/app/careercorner/intro',
      name: 'CareerCorner',
      imageUrl: 'career-corner.svg',
      headerColor: 'header-aqua',
      bodyColor: 'aqua',
    },
    {
      routeChange: '/app/library-occupations?module=All',
      name: 'CareerLibrary',
      imageUrl: 'binoculars.svg',
      headerColor: 'header-teal',
      bodyColor: 'teal',
    },
    {
      routeChange: '/app/quickpic/intro',
      name: 'QuickPic',
      imageUrl: 'quick-pic.svg',
      headerColor: 'header-indigo',
      bodyColor: 'indigo',
    },
    {
      routeChange: '/app/scout/intro',
      name: 'Scout',
      imageUrl: 'Scout.svg',
      headerColor: 'header-apple-green',
      bodyColor: 'apple-green',
    },
  ];

  constructor(
    public http: HttpClient,
    private store: Store<SiteDataState>,
    private store1: Store<OccsDataState>,
    private utils: Utilities,
    private apiModel: ApiCallClass,
    private dialogPopup: DialogopenService,
    private eventService: EventdispatchService
  ) {
    this.subscription = eventService.listen().subscribe((e) => {
      /** After event listen it will check whether user want to save partially or completely */
      if (e.type == 'sessionExtend') {
        this.extendTime();
      }
    });
    this.extendTime();
  }

  public extractData = function (res: Response) {
    const body = JSON.stringify(res);
    try {
      /**Here we are making sure that the session expiry modal is called only when an API response having token. 
               Previously the session popup was called for every API response because of this the eventTime was cleared by clearTime(). 
               So, the modal can never be called. Thus, the actual token expires and resulting to application crash */
      if (res['token']) {
        const tokenInfo = this.getDecodedAccessToken(res['token']);
        sessionStorage.setItem('Language', tokenInfo.language); //Setting the language in the session once the token generated.
        // decode token
        this.extendExpiration(tokenInfo.exp, tokenInfo.iat, this);
      }
    } catch (e) {
      this.utils.handleError(
        `api-call-service.ts extractData: ${JSON.stringify(
          e,
          Object.getOwnPropertyNames(e)
        )}`
      );
    }

    return body || {};
  }.bind(this);
  getImage(data): Observable<Blob[]> {
    const observableBatch = [];
    if (data[0].endUrl == 'auth/token/create') {
      this.baseurl = auth_url;
    } else {
      this.baseurl = api_url;
    }
    if (data[0].method === 'PUT') {
      try {
        return this.http
          .put(this.baseurl + data[0].endUrl, data[0].data, {
            responseType: 'text',
          })
          .pipe(map(this.extractData));
      } catch (e) {
        this.utils.handleError(
          `api-call-service.ts getImage: ${JSON.stringify(
            e,
            Object.getOwnPropertyNames(e)
          )}`
        );
      }
    } else {
      data.forEach((componentarray, inx) => {
        observableBatch.push(
          this.http.get(this.baseurl + data[inx].endUrl, {
            responseType: 'blob',
          })
        );
      });
      return forkJoin<any[]>(observableBatch);
    }
  }

  getData(data): Observable<any> {
    const observableBatch = [];
    if (data[0].endUrl == 'auth/token/create') {
      this.baseurl = auth_url;
    } else {
      this.baseurl = api_url;
    }
    if (data[0].method === 'POST') {
      try {
        return this.http
          .post(this.baseurl + data[0].endUrl, data[0].data)
          .pipe(map(this.extractData));
      } catch (e) {
        this.utils.handleError(
          `api-call-service.ts getData Post: ${JSON.stringify(
            e,
            Object.getOwnPropertyNames(e)
          )}`
        );
      }
    } else if (data[0].method === 'DELETE') {
      return this.http
        .delete(this.baseurl + data[0].endUrl)
        .pipe(map(this.extractData));
    } else if (data[0].method === 'PUT') {
      return this.http
        .put(this.baseurl + data[0].endUrl, data[0].data)
        .pipe(map(this.extractData));
    } else {
      try {
        data.forEach((componentarray, inx) => {
          observableBatch.push(
            this.http.get(this.baseurl + data[inx].endUrl).pipe(
              map(this.extractData),
              catchError((e) => {
                return this.utils.handleError(
                  `api-call-service.ts getImage: ${JSON.stringify(
                    e,
                    Object.getOwnPropertyNames(e)
                  )}`
                );
              })
            )
          );
        });
      } catch (e) {
        this.utils.handleError(
          `api-call-service.ts getData get: ${JSON.stringify(
            e,
            Object.getOwnPropertyNames(e)
          )}`
        );
      }
      return forkJoin<any>(observableBatch);
    }
  }

  // When we save Emoji, The PUT api is returning back the response with statusText "Ok" not JSON responsne. For that, We have created this method to handle "response type text".
  saveProfileEmoji(data: any): Observable<any> {
    return this.http
      .put(this.baseurl + data[0].endUrl, data[0].data, {
        responseType: 'text',
      })
      .pipe(map(this.extractData));
  }
  getUsersItems(): Observable<any> {
    const sites$: Observable<[{}]> = this.store.select('sites');
    const occs$: Observable<[{}]> = this.store1.select('occs');
    return combineLatest([sites$, occs$])
      .pipe(take(1))
      .pipe(
        map(([sites$, occs$]) => {
          //here used the pipe(take(1)) as three times the observable was getting subscribed
          return {
            sites: sites$,
            occs: occs$,
          };
        })
      );
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (e) {
      this.utils.handleError(
        `api-call-service.ts getDecodedAccessToken: ${JSON.stringify(
          e,
          Object.getOwnPropertyNames(e)
        )}`
      );
      return null;
    }
  }

  extendExpiration(exp, iat, ref) {
    clearTimeout(this.expiredTime);
    this.eventTime = (exp - iat) * 1000 - 2.5 * 60 * 1000;
    const onstart = new Date().getTime();
    this.expiredTime = setTimeout(() => {
      const authkey = sessionStorage.getItem('session_token');
      if (authkey != '' && authkey != undefined && authkey != null) {
        this.dialogPopup.sessionExpire(
          JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'))
        );
      }
    }, this.eventTime);
  }

  extendTime() {
    this.apiModel.moduleName = 'auth';
    this.apiModel.endUrl = 'user/auth/renew';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'GET';
    try {
      this.getData([this.apiModel]).subscribe((resp) => {
        this.newToken = JSON.parse(resp);
        if (this.newToken != null && this.newToken != '') {
          sessionStorage.removeItem('session_token');
          sessionStorage.setItem('session_token', this.newToken.token);
          const tokenInfo = this.getDecodedAccessToken(this.newToken.token);
          this.extendExpiration(tokenInfo.exp, tokenInfo.iat, this);
        } else {
          this.sessionExpired();
        }
      });
    } catch (e) {
      this.utils.handleError(
        `api-call-service.ts extendTime: ${JSON.stringify(
          e,
          Object.getOwnPropertyNames(e)
        )}`
      );
    }
  }

  sessionExpired() {
    window.location.href = '/logout';
    sessionStorage.setItem('theme_color', '');
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    window.sessionStorage.clear();
  }

  getDataLanguageChangeForShuffle(current, newData) {
    let dummycards = [];
    current.forEach((obj) => {
      const dataIndex = newData.findIndex((item) => item.occId === obj.occId);
      dummycards.push(newData[dataIndex]);
    });
    return dummycards;
  }

  /** */
  logoutApiCall(logoutdata: LogOutPostData):void {
      let options = {
       headers: new HttpHeaders().set(
         'Content-Type',
         'application/json'
       ),
     };
    const body = JSON.stringify(logoutdata);

    // const postRep = this.http.post(api_url + 'user/auth/logoutCT', body);
     this.http.post(auth_url + 'auth/logoutCT', body, options)
    .subscribe (res=>{
      console.log(res);
    },
  err=>{
    console.log
  });
    
    // return postRep;

    // let logoutRep;
    // this.apiModel.moduleName = 'auth';
    // this.apiModel.endUrl = 'user/auth/logoutCT';
    // this.apiModel.sessionID = sessionStorage.getItem('session_token');
    // this.apiModel.method = 'POST';
    // this.apiModel.data = logoutdata;
    // try {
    //   this.getData([this.apiModel]).subscribe((resp) => {

    //   });
    // } catch (e) {
    //     console.log()
    //   this.utils.handleError(
    //     `api-call-service.ts extendTime: ${JSON.stringify(
    //       e,
    //       Object.getOwnPropertyNames(e)
    //     )}`
    //   );
    // }
    // // console.log("outside: ", logoutRep);
    // // return logoutRep;
    // return of(logoutRep);
  }

  //   private handleError(error: HttpErrorResponse) {
  //     if (error.status === 0) {
  //       // A client-side or network error occurred. Handle it accordingly.
  //       console.error('An error occurred:', error.error);
  //     } else {
  //       // The backend returned an unsuccessful response code.
  //       // The response body may contain clues as to what went wrong.
  //       console.error(
  //         `Backend returned code ${error.status}, body was: `,
  //         error.error
  //       );
  //     }
  //     // Return an observable with a user-facing error message.
  //     return throwError(
  //       () => new Error('Something bad happened; please try again later.')
  //     );
  //   }
}
