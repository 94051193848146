
/**Import angular core packages */
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { interval} from 'rxjs';
import { Pointer, Next, VibrateAnimation } from '../../../shared/common-animations/CT_animations';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { environment } from '../../../../environments/environment';
import { ExplorerChallengeState, OccsDataState, PageDataState, AudioState } from '../../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile, take  } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
const imageurl = environment.ASSETS_BASE_URL;

@Component({
	selector: 'app-explorer-challenge',
	templateUrl: './challenge-layout.html',
	animations: [Pointer, Next, VibrateAnimation]
})

export class ExplorerChallengeComponent implements OnInit, OnDestroy {
	nextId: any;
	category: any;
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	languageKey: string;
	loading: string;
	unsavedArray = [];
	storeDataIds = [];
	moving = 'forward';
	savedIds = [];
	savedData = [];
	prev = 0;
	clickdone = false;
	lang: string;
	occsdata = [];
	attributesdata = [];
	attempts = 0;
	icon: any;
	activeIds: any = [];
	// sideImages: any = [];
	storedata: any = [];
	id: any;
	sentence: any = [];
	val = 3;
	score = 0;
	progress = 0;
	barProgress = 0;
	selectedIndex = 1;
	i = 1; j = 0; m = 0; l = 0; k = 0;
	present: any;
	resp: any;
	highbutton = false;
	mediumbutton = false;
	lowbutton = false;
	attributes: any = [];
	data: any;
	highdata: any = [];
	mediumdata: any = [];
	lowdata: any = [];
	high = 'high';
	low = 'low';
	medium = 'medium';
	completed = 'start';
	modaldata: any;
	attr: any = [];
	theme_name;
	nextQues = false;
	Ques = 0;
	wrongchoice = false;
	occCardAPIUrl : string;
	compActive = true;
	explorerChallengeText: any;
	iconName: string;
	headerAudioState = 'stoped';
	headerAudionotfound = false;
	careerAudioState = 'stoped';
	careerAudionotfound = false;
	attributeAudioState = 'stoped';
	attributeAudionotfound = false;
	audioSelected;
	curAudio = '';
	totalLength: number;
	modalOpen() {
		this.dialogService.explorerChallengePopup(this.score);
		this.id = this.activeIds[this.activeIds.length-1];
		this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			this.saveChallenge();
			if (result == 'closed') {
				this.loading = 'loading';
				this.unsavedArray = [];
				this.storeDataIds = [];
				this.moving = 'forward';
				this.savedIds = [];
				this.savedData = [];
				this.prev = 0;
				this.Ques = 0;
				this.nextQues = false;
				this.attempts = 0;
				this.val = 3;
				this.score = 0;
				this.progress = 0;
				this.highbutton = false;
				this.mediumbutton = false;
				this.lowbutton = false;
				this.highdata = [];
				this.mediumdata = [];
				this.lowdata = [];
				this.high = 'high';
				this.low = 'low';
				this.medium = 'medium';
				this.completed = 'start';
				this.barProgress = 0;
				this.id = this.activeIds[0];
				this.getapidata('attributes', this.lang);
				// this.ngOnInit();
				// this.getapidata('attributes', this.lang);
			}
			if (result == 'library') {
				const evnt = document.createEvent('CustomEvent');
				this.eventService.dispatchAudioStop(evnt);
				sessionStorage.setItem('modulename', 'Explorer');
				this.router.navigateByUrl('/app/library-occupations');
				this.router.navigate(['/app/library-occupations'], {
					relativeTo: this.activatedRoute,
					queryParams: {
						'module': 'Explorer'
					}
				});			
			}
		});
	}
	constructor(private _location: Location,
		public dialogService: DialogopenService,
		private oninitpopupservice: OnLoadPopUpService,
		private activatedRoute: ActivatedRoute,
		private apiCall: ApiCallService,
		private apiModel: ApiCallClass,
		private explorerChallengeState:Store<ExplorerChallengeState>,
		private store: Store<OccsDataState>,
		private audioStore: Store<AudioState>,
        private audioService: AudioService,
		private store1: Store<PageDataState>,
		public eventService: EventdispatchService,
		private router: Router, private utils: Utilities,  @Inject('OCC_LARGE_CARD_URL') private occCardUrl: string,
		private titleService: Title
	) {
		this.occCardAPIUrl = occCardUrl;
     		let colors = environment.THEME_COLORS;
			sessionStorage.setItem('theme_color', colors[0]);
			// sessionStorage.setItem('pageTitle', '1');
		    // sessionStorage.setItem('moduleKey', 'EXPLORER##EXPLORER_CHALLENGE'); 
		    this.store1.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'EXPLORER',pageCode:'EXPLORER_CHALLENGE'}});

			const event = document.createEvent('CustomEvent');
			event.initEvent('themeChanged', true, true);
			this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		/*logic for continus animation*/
		interval(5000).subscribe(() => {
			if (this.clickdone == false) {
				this.animation('no');
			}
		});
		/*end of logic for continus animation*/
		this.lang = sessionStorage.getItem('session_token');
		this.highdata = [];
		this.lowdata = [];
		this.mediumdata = [];
		this.infopopup();
		 eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/explorer/challenge')) {
				if (e.type === 'languageChanged') {
					this.loading = 'loading';
					this.unsavedArray = [];
					this.storeDataIds = [];
					this.moving = 'forward';
					this.savedIds = [];
					this.savedData = [];
					this.prev = 0;
					this.Ques = 0;
					this.nextQues = false;
					this.attempts = 0;
					this.val = 3;
					this.score = 0;
					this.progress = 0;
					this.highbutton = false;
					this.mediumbutton = false;
					this.lowbutton = false;
					this.highdata = [];
					this.mediumdata = [];
					this.lowdata = [];
					this.high = 'high';
					this.low = 'low';
					this.medium = 'medium';
					this.completed = 'start';
					this.languageKey = this.resp[0].language;
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					// this.oninitpopupservice.pageCallText(this.lang, 'pages');
					this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					this.unsavedChanges();
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}

		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				if (this.curAudio === 'header') {
					this.headerAudioState = result.audioState;
					this.headerAudionotfound = result.audionotfound;
					this.careerAudioState = 'stoped';
					this.careerAudionotfound = false;
					this.attributeAudioState = 'stoped';
					this.attributeAudionotfound = false;
				} else if (this.curAudio === 'career') {
					this.careerAudioState = result.audioState;
					this.careerAudionotfound = result.audionotfound;
					this.headerAudioState = 'stoped';
					this.headerAudionotfound = false;
					this.attributeAudioState = 'stoped';
					this.attributeAudionotfound = false;
				} else if (this.curAudio === 'attribute') {
					this.attributeAudioState = result.audioState;
					this.attributeAudionotfound = result.audionotfound;
					this.headerAudioState = 'stoped';
					this.headerAudionotfound = false;
					this.careerAudioState = 'stoped';
					this.careerAudionotfound = false;
				}
			}
		})


	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function() {
			this.oninitpopupservice.getOnLoadPopUp('EXPLORER_CHALLENGE');
		}.bind(this));
	}

	getapidata(endurl, lang) {
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = endurl;
		this.apiModel.sessionID = lang;
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				this.resp = JSON.parse(resp);
				if (resp != null && resp != '') {
					this.attributesdata = this.resp;
					this.attr = JSON.parse(resp);
					for (let i = 0; i < this.activeIds.length; i++) {
						this.getoccdata(i);
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`challenge-component.ts getapidata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	getoccdata(i) {
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = 'occ/' + this.activeIds[i];
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'GET';
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp != undefined) {
					this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
					this.occsdata.push(JSON.parse(resp));
					this.resp.push(JSON.parse(resp));
					if (this.resp[2] != undefined) {
						this.score = 0;
						this.val = 3;
						this.progress = 0;
						this.i = 1;
						this.m = 0;
						this.l = 0;
						this.k = 0;
						this.highdata = [];
						this.lowdata = [];
						this.mediumdata = [];
						this.getOnLoadData();
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`challenge-component.ts getoccdata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	onQuesClick(id, text, inx) {
			this.addData(text, inx);
	}
	getOnLoadData() {
		if (this.resp[0].language != this.languageKey || this.keywords.language != this.languageKey) {
			this.languageKey = this.resp[0].language;
			this.dialogService.hideLoading();
		}
		if (this.moving == 'forward' && this.unsavedArray.length == 0) {
			this.i = 1;
			this.m = 0;
			this.l = 0;
			this.k = 0;
			this.highdata = [];
			this.lowdata = [];
			this.mediumdata = [];
		}

		this.attributes = [];
		this.sentence = [];
		this.resp = this.occsdata;
		for (let i = 0; i < this.resp.length; i++) {
			if (this.resp[i].occId == this.id) {
				this.data = [];
				this.data = this.resp[i];
				this.loading = '';
			}
		}
		/*start of attributes data call */
		this.attr = this.attributesdata;
		for (let i = 0; i < this.attr.length; i++) {
			if (this.attr[i].type === 'characteristic') {
				this.sentence.push(this.attr[i]);
			}
		}
		/* end of attributes data call*/
		/*start of logic  for display the first onload data*/
		if (this.data !== undefined) {
			for (let i = 0; i < this.data.attributes.length; i++) {
				if (this.data.attributes[i].type === 'characteristic') {
					this.attributes.push(this.data.attributes[i]);
				}
			}
			for (let i = 0; i < this.attributes.length; i++){
				if (this.moving == 'forward') {
					if (this.attributes[i].weight === this.high) {
						if (i === 0) {
							this.highbutton = true;
							this.mediumbutton = false;
							this.lowbutton = false;
						}
						this.highdata.length++;
					}
					if (this.attributes[i].weight === this.medium) {
						if (i === 0) {
							this.highbutton = false;
							this.mediumbutton = true;
							this.lowbutton = false;
						}
						this.mediumdata.length++;
					}
					if (this.attributes[i].weight === this.low) {
						if (i === 0) {
							this.highbutton = false;
							this.mediumbutton = false;
							this.lowbutton = true;
						}
						this.lowdata.length++;
					}
				} else {
					this.highbutton = false;
					this.mediumbutton = false;
					this.lowbutton = false;
				}
			}
				for (let j = 0; j < this.sentence.length; j++) {
					if (this.sentence[j].name === this.attributes[0].name) {
						this.present = this.sentence[j].title;
						this.icon =  this.sentence[j].icon;
						this.audioSelected = this.sentence[j];
					}
				}
			this.dialogService.hideLoading();
		}
		/*end of logic for display the first onload data*/
	}
	next() {
		this.stopAudio();
		this.completed = 'start';
		this.moving = 'forward';
		if (this.attempts < this.activeIds.length) {
			this.i = 1;
			this.m = 0;
			this.l = 0;
			this.k = 0;
			this.prev = 0;
			if (this.storeDataIds.indexOf(this.id) < 0) {
				this.savedData.push({
					'Id': this.id,
					'HighData': this.highdata,
					'MediumData': this.mediumdata,
					'LowData': this.lowdata
				});
				this.storeDataIds.push(this.id);			
			}
			if (this.savedIds.indexOf(this.id) < 0) {
			this.savedIds.push(this.id);
			}			
			this.attempts++;
			this.highdata = [];
			this.lowdata = [];
			this.mediumdata = [];
			this.attributes = [];
			this.sentence = [];
			this.completed = 'start';
			this.highbutton = false;
			this.mediumbutton = false;
			this.lowbutton = false;
			this.id = this.activeIds[this.attempts];
		} else {
			this.modalOpen();
		}
		if (this.attempts == this.activeIds.length) {
			this.modalOpen();
		}		
		for (let i = 0; i < this.savedData.length; i++) {
			if (this.id == this.savedData[i].Id) {
				this.moving = 'backword';
				this.highdata = this.savedData[i].HighData;
				this.mediumdata = this.savedData[i].MediumData;
				this.lowdata = this.savedData[i].LowData;
				this.highbutton = false;
				this.mediumbutton = false;
				this.lowbutton = false;
				this.prev = this.i;
				this.completed = 'completed';
				this.getOnLoadData();
		    } else if (this.unsavedArray.length !== 0) {		   
					if (this.unsavedArray[0].Id == this.id) {					
					this.i = this.unsavedArray[0].i - 1;
					this.m = this.unsavedArray[0].m;
					this.l = this.unsavedArray[0].l;
					this.k = this.unsavedArray[0].k;
					this.prev = this.unsavedArray[0].prev;
					this.attempts = this.unsavedArray[0].Attempts;
					this.highdata = this.unsavedArray[0].HighData;
					this.mediumdata = this.unsavedArray[0].MediumData;
					this.lowdata = this.unsavedArray[0].LowData;
					this.completed = 'completed';
					this.attributes = this.unsavedArray[0].Attributes;
					this.resp = this.occsdata;
					this.sentence = [];
					for (let k = 0; k < this.resp.length; k++) {
						if (this.resp[k].occId == this.id) {
							this.data = this.resp[k];
						}
					}
					/*start of attributes data call */
					this.attr = this.attributesdata;
					for (let j = 0; j < this.attr.length; j++) {
						if (this.attr[j].type === 'characteristic') {
							this.sentence.push(this.attr[j]);
						}
					}
					this.datashow();
				}
			} else {
				this.getOnLoadData();
			}
		}
		if(this.moving == 'forward' && this.unsavedArray.length){
			this.unsavedArray = [];
		}
		// Showing next career when  navigating back and clicking next 
		this.nextId = this.resp.find((obj => obj.occId == this.activeIds[this.attempts + 1]));

		this.totalLength = this.activeIds.length;
		this.explorerChallengeState.dispatch({ type: 'EXPLORER_CHALLENGE_VAL', payload: { 
			explorerChallenge:{
				savedIds:this.savedIds,
				totalLength:this.totalLength,
				score:this.score,
				activeIds:this.activeIds
			}
		}});
	}

	/*start of ngoninit*/
	ngOnInit() {		
	  let intialVal = true;

    // setTimeout(()=>{
	  this.explorerChallengeState.select('explorerChallenge').pipe(take(1)).subscribe(challengeresp =>  {
		if (challengeresp['explorerChallenge']){
     	this.attempts = challengeresp['explorerChallenge'].savedIds.length;
		this.totalLength = challengeresp['explorerChallenge'].totalLength;
        this.score = challengeresp['explorerChallenge'].score;
	
		if(this.totalLength && this.attempts){
			if(this.totalLength === this.attempts && intialVal == true){
				this.activeIds =  challengeresp['explorerChallenge'].activeIds;
		        this.id = this.activeIds[this.activeIds.length-1];
			    this.modalOpen();
				intialVal = false;
			}
		}	

	}
	 });

		//The below store is subscribed and the value for 'this.explorerChallengeText' is set to avoid the language mismatch on language change.
		this.store1.select('pages').pipe(takeWhile(()=>this.compActive)).subscribe((pages)=>{
			if (pages) {
				this.explorerChallengeText = this.oninitpopupservice.getKeyWordData('EXPLORER_CHALLENGE');
			}
		})
		this.loading = 'loading';
		this.getapidata('attributes', this.lang);
		this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
			const ids = params['taskActiveIds'];
			this.category = params['category'];
			
			if(this.activeIds.length === 0){
			this.activeIds = ids.split('&');
			this.id = this.activeIds[0];	
			
			 }
		});
		if (parseInt(this.category) === 1) {
			this.iconName = 'EXPLORER_PEOPLE';
		} else if (parseInt(this.category) === 2) {
			this.iconName = 'EXPLORER_IDEAS';
		} else if(parseInt(this.category) === 3){
			this.iconName = 'EXPLORER_THINGS';
		}
		
		this.setExploerChallengeTitle(this.iconName);
	}
	animation(inx) {
		let x = 0;
		if (inx != 'no') {
			this.clickdone = false;
		} else {
			this.clickdone = true;
			interval(11400)
				.subscribe(() => {
					if (this.clickdone == true) {
						x % 2 === 0 ? this.selectedIndex++ : this.selectedIndex--;
						x++;
						this.clickdone = false;
					}
				});
		}

	}
	/*end of ngoninit*/
	/*start of logic for buttons two show and to add those to array*/
	datashow() {
		if ((this.i) < this.attributes.length) {
			for (let j = 0; j < this.sentence.length; j++) {
				if (this.sentence[j].name === this.attributes[this.i].name) {
					this.present = this.sentence[j].title;
					this.icon =  this.sentence[j].icon;	
					this.audioSelected = this.sentence[j];
				}
			}
			if (this.attributes[this.i].weight === this.high) {				
				this.highbutton = true;
				this.mediumbutton = false;
				this.lowbutton = false;				
			}
			if (this.attributes[this.i].weight === this.medium) {
				this.highbutton = false;
				this.mediumbutton = true;
				this.lowbutton = false;
			}
			if (this.attributes[this.i].weight === this.low) {
				this.mediumbutton = false;
				this.highbutton = false;
				this.lowbutton = true;
			}
		} else {
			this.highbutton = false;
			this.mediumbutton = false;
			this.lowbutton = false;
		}
		this.i++;
	}
	nextquestion() {
		this.Ques++;
		this.datashow();
		this.nextQues = false;		
		if (this.i > this.attributes.length) {
			this.completed = 'completed';
		} else {
			this.completed = 'start';
		}
	}
	addData(type, inx) {
		this.animation('yes');
		if (this.prev != this.i) {
			for (; this.i <= this.attributes.length;) {
				if (this.attributes[this.i - 1].weight === this.high && type === this.high) {
					for (let j = 0; j < this.sentence.length; j++) {
						if (this.sentence[j].name === this.attributes[this.i - 1].name) {
							this.highdata[inx] = this.sentence[j].icon;
						}
					}
					this.nextQues = true;
					this.k++;
					this.prev++;
					this.progress = (this.progress + 5.55);
					this.score = this.score + this.val;
					this.barProgress = this.score/54 * 100;
					this.val = 3;
					this.nextquestion();
					break;
				} else if (this.attributes[this.i - 1].weight === this.medium && type === this.medium) {
					for (let j = 0; j < this.sentence.length; j++) {
						if (this.sentence[j].name === this.attributes[this.i - 1].name) {
							this.mediumdata[inx] =  this.sentence[j].icon;
						}
					}
					this.l++;
					this.prev++;
					this.nextQues = true;
					this.progress = (this.progress + 5.55);
					this.score = this.score + this.val;
					this.barProgress = this.score/54 * 100;
					this.val = 3;
					this.nextquestion();
					break;
				} else if (this.attributes[this.i - 1].weight === this.low && type === this.low) {
					for (let j = 0; j < this.sentence.length; j++) {
						if (this.sentence[j].name === this.attributes[this.i - 1].name) {
							this.lowdata[inx] =  this.sentence[j].icon;
						}
					}
					this.m++;
					this.prev++;
					this.nextQues = true;
					this.progress = (this.progress + 5.55);
					this.score = this.score + this.val;
					this.barProgress = this.score/54 * 100;
					this.val = 3;
					this.nextquestion();
					break;
				} else {
					if (this.val > 1) {
						this.val--;
					}
					this.wrongchoice = !this.wrongchoice;
					break;
				}
			}
		}		
		if (this.i == this.attributes.length) {
			for (let i = 0; i < this.resp.length; i++) {
				if (this.resp[i].occId == this.activeIds[this.attempts + 1]) {
					this.nextId = this.resp[i];
	
				}
			}
		}
	}
	/*end of logic  for buttons two show and to add those to array*/
	unsavedChanges() {
		if (this.i != 1) {
			if (this.unsavedArray.indexOf(this.id) < 0) {
				this.unsavedArray.push({
					'Id': this.id,
					'HighData': this.highdata,
					'LowData': this.lowdata,
					'MediumData': this.mediumdata,
					'Attributes': this.attributes,
					'i': this.i,
					'm': this.m,
					'k': this.k,
					'l': this.l,
					'prev': this.prev,
					'Attempts': this.attempts
				});
			}
		}		
		if (this.attempts == 0) {
			const evnt = document.createEvent('CustomEvent');
			this.eventService.dispatchAudioStop(evnt);
			this.utils.backRoute();
		} else if (this.attempts != 0) {
			this.id = this.savedIds[this.savedIds.length - 1];
			this.moving = 'backword';
			for (let i = 0; i < this.savedData.length; i++) {
				if (this.savedData[i].Id == this.id) {
					this.highdata = this.savedData[i].HighData;
					this.mediumdata = this.savedData[i].MediumData;
					this.lowdata = this.savedData[i].LowData;
				}
			}
			this.attempts--;
			// While navigating back to show next carrer
			this.nextId = this.resp.find((obj => obj.occId == this.activeIds[this.attempts + 1]));
			this.highbutton = false;
			this.mediumbutton = false;
			this.lowbutton = false;
			this.prev = this.i;
			this.completed = 'completed';
			this.savedIds.pop();
			this.getOnLoadData();
		}	
	}

	saveChallenge() {
		let challengeType = '';
		if (this.data.category.title == 'People') {
			challengeType = 'EXPLORER_PEOPLE';
		} else if (this.data.category.title == 'Things') {
			challengeType = 'EXPLORER_THINGS';
		} else {
			challengeType = 'EXPLORER_IDEAS';
		}
		this.apiModel.moduleName = 'explorer';
		this.apiModel.endUrl = 'user/characteristics/challenge/add/' + challengeType;
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'POST';
		this.apiModel.data = {
			'score': this.score,
			'occIds': this.savedIds
		};
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe();
		} catch (e) {
			this.utils.handleError(`challenge-component.ts saveChallenge: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	audioClick(text, data) {
		this.curAudio = 'header';
		this.audioService.audioFunction(text, data);
	}

	indAttribAudioClick(text, data) {
		this.curAudio = 'attribute';
		this.audioService.audioFunction(text, data);
	}

	indCareerAudioClick(text, data) {
		this.curAudio = 'career';
		this.audioService.audioFunction(text, data, true);
	}

	// Stop audio event method
	stopAudio() {
		this.headerAudioState = 'stoped';
		this.headerAudionotfound = false;
		this.careerAudioState = 'stoped';
		this.careerAudionotfound = false;
		this.attributeAudioState = 'stoped';
		this.attributeAudionotfound = false;
		this.audioStore.dispatch({
			type: 'SET_AUDIO_STOPED'
		});
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
	}

	ngOnDestroy() {
		this.compActive = false;
	}

	private setExploerChallengeTitle(title: string){
		this.titleService.setTitle("Explorer Challenge "+ title.replace("_", " ") +" - CareerTrek");
	}
}
