
/**Import angular core packages */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { Next } from '../../../shared/common-animations/CT_animations';
import { Store } from '@ngrx/store';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { AttributeDataState, TrekkerTopSkillsState, AudioState} from '../../../state-management/state/main-state';
import { PageDataState } from '../../../state-management/state/main-state';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-careertrekker-top-skills',
	templateUrl: './top-skills-layout.html',
	animations: [Next]
})

export class CareerTrekkerTopSkillsComponent implements OnInit, OnDestroy {
	trekkerTopSkills : any;
	pagesresp: any = [];
	selectedlike = [];
	selectedUnlike = [];
	languageKey: string;
	keywords: any;
	skillText: any;
	goodAtText: any[];
	lang: string;
	completed = 'start';
	data: any;
	cardsSelected = [{ 'name': '', 'icon': '', 'title': '' }, { 'name': '', 'icon': '', 'title': '' }];
	cards = [];
	like = [];
	unlike = [];
	i = 0;
	j = 0;
	change = [];
	delay1 = [0, 0];
	sortedarray = [];
	prev1inx;
	prev2inx;
	l = 0;
	theme_name;
	weights = ['High','High','All','All']; 
	assetsUrl : string;
	compActive = true;
	audioState = 'stoped';
	audionotfound = false;
	pagerespGoodAt;
	pagerespImpAt;
	goodSkill: any;
	impSkill: any;
	constructor(private _location: Location, private oninitpopupservice: OnLoadPopUpService,
		private store: Store<AttributeDataState>,
		private store1: Store<PageDataState>,
		private apiModel: ApiCallClass,
		private activatedRoute: ActivatedRoute,
		public dialogService: DialogopenService,
		private trekkerTopSkillsStore:Store<TrekkerTopSkillsState>,
		private router: Router, private utils: Utilities,
		private audioService: AudioService,
		private audioStore: Store<AudioState>,
		private apiCall: ApiCallService, private eventService: EventdispatchService,
		private pageStore: Store<PageDataState>,  @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		private titleService: Title) {
			this.goodSkill = JSON.parse(sessionStorage.getItem('goodSkill'));
			this.impSkill = JSON.parse(sessionStorage.getItem('ImpSkill'));
			this.assetsUrl = assetsMediaUrl;
			let colors = environment.THEME_COLORS;
			sessionStorage.setItem('theme_color', colors[2]);
			this.store1.dispatch({type:'TRIGGER_TEXT_CHANGE_EVENT',payload:{module:'CAREER_TREKKER',pageCode:'TREKKER_TOP'}});
			const event = document.createEvent('CustomEvent');
			event.initEvent('themeChanged', true, true);
			this.eventService.dispatch(event);
			sessionStorage.setItem('isModalActive', 'false');
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/careertrekker/topskills')) {
				if (e.type === 'languageChanged') {
					if (this.data[0] != undefined) {
						this.languageKey = this.data[0].language;
					}
					this.cardsSelected = [{ 'name': '', 'icon': '', 'title': '' }, { 'name': '', 'icon': '', 'title': '' }];
					this.selectedlike = [];
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type === 'backClicked') {
					if (this.cards[0] != undefined) {
						this.unsavedChanges();
					}
				}
			}
		});
		if (this.lang === '' || this.lang == null) {
			this.lang = sessionStorage.getItem('session_token');
		}
		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
		this.getTrekkerTopSkills();
	}

	//The below function is used to get the Trekker top skills and imporve skills from pages api call
	getTrekkerTopSkills(){
		this.pageStore.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
		  if (resp.pages && resp.pages[0]) {
			this.pagesresp = resp.pages;
			if (this.pagesresp) {
				for (let i = 0; i <  this.pagesresp.length; i++) {
				  	if ( this.pagesresp[i].pageCode == 'TREKKER_TOP') {
						this.trekkerTopSkills = this.pagesresp[i].dictonary;
						this.pagerespGoodAt = this.pagesresp[i];
				  	}
				  	if(this.pagesresp[i].pageCode == 'TREKKER_FILTER'){
						this.pagerespImpAt = this.pagesresp[i];
				  	}
				}
			}
		}
	});  
	}

	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function() {
            this.oninitpopupservice.getOnLoadPopUp('TREKKER_TOP');
		}.bind(this));
	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	getAttributes(arrayselected) {
		try {
		 this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
				/* */
				// To change title text with respective language when change language
				let result = res.attribute.filter(o1 => this.cardsSelected.some(o2 => o1.attributeId === o2['attributeId']));
				if(result.length !== 0) {
					this.cardsSelected = result;
				}
				/* */
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				this.data = (res.attribute);
				this.dataget(arrayselected);
				if (this.data[0] != undefined) {
					if (this.languageKey != this.data[0].language && this.languageKey != this.keywords.language) {
						this.languageKey = this.data[0].language;
						this.dialogService.hideLoading();
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`top-skills-component.ts getAttributes: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	dataget(arrayselected) {
		this.cards = [];
		for (let i = 0; i < this.data.length; i++) {
			if (arrayselected.length > 0) {
				if (arrayselected.indexOf(this.data[i].name) >= 0) {
					this.change.push(1);
					this.cards.push(this.data[i]);
				}
			} else {
				if (arrayselected.length === 1) {
					if (arrayselected.indexOf(this.data[i].name) >= 0) {
						this.cardsSelected[0] = this.data[i];
						this.i = 1;
						this.j++;
					}

				} else if (arrayselected.length === 2) {
					if (arrayselected.indexOf(this.data[i].name) >= 0) {
						this.cardsSelected[this.i] = this.data[i];
						if (this.i == 0) {
							this.i = 1;
						} else {
							this.i = 0;
						}
						this.j++;
					}
					if (this.l == 0 && this.i == 0) {
						this.selectedlike = this.cardsSelected;
						this.l++;
					}
				}
				this.change.push(1);
				this.cards.push(this.data[i]);
			}
		}
	}
	ngOnInit() {
		this.oninitpopupservice.pageCallText(this.lang, 'attributes/skill');
		this.like = [];
		this.unlike = [];
		this.change = [];
		this.cards = [];
		this.l = 0;
		this.like = JSON.parse(sessionStorage.getItem('goodSkill'));
		this.unlike = JSON.parse(sessionStorage.getItem('ImpSkill'));
		this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
		setTimeout(() => {
			if(this.goodSkill.length > 2) {
				this.getAttributes(this.like);
			} else if (this.impSkill.length > 0) {
				this.l = 1;
				this.goodAtText = this.keywords.dictionary.needToImprove;
				try {
					this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
						this.data = (res.attribute);
						this.dataget(this.like);
						this.selectedlike = this.cards;
					});
				} catch (e) {
					this.utils.handleError(`top-skills-component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}	
				this.cardsSelected = [{ 'name': '', 'icon': '', 'title': '' }, { 'name': '', 'icon': '', 'title': '' }];
				this.getAttributes(this.unlike);
			}
			/*In trekker top skills page cards are displaying based on the assessment answers and  the selected trekker top skills cards are stored in ngrx store,
              so that while clicking the back button at Trekker result page the previously stored values will be retrieving from ngrx store. */ 
			this.trekkerTopSkillsStore.select('trekkerCardsSelected').pipe(takeWhile(() => this.compActive)).subscribe(skillsresp => {
				if (skillsresp['trekkerCardsSelected'].selectedUnlike && 
					skillsresp['trekkerCardsSelected'].selectedUnlike.length !==0) {
					if(this.like.length>2 && this.unlike.length>2 && this.cardsSelected){ 
						this.cards = skillsresp['trekkerCardsSelected'].cards;	
						this.l = 1;
						this.cardsSelected = skillsresp['trekkerCardsSelected'].selectedUnlike;
						this.selectedlike = skillsresp['trekkerCardsSelected'].selectedLike;
					}
				}
				if (skillsresp['trekkerCardsSelected'].selectedUnlike && 
					skillsresp['trekkerCardsSelected'].selectedUnlike.length!==0 && this.like.length<=2) {
					this.dataget(this.unlike);
					this.l = 1;
					this.cardsSelected = skillsresp['trekkerCardsSelected'].selectedUnlike;
				}
				if (skillsresp['trekkerCardsSelected'].selectedLike && 
					skillsresp['trekkerCardsSelected'].selectedLike.length!==0 && 
					this.unlike.length<=2) {
					this.dataget(this.like);
					this.cardsSelected = skillsresp['trekkerCardsSelected'].selectedLike;	
				}
			});
		});
		this.setTrekkerTopSkillsTitle();
	}

	getUnselectedData(){

	}

	cardSelected(card, inx) {
		if (this.i == 0 && this.cardsSelected[this.i + 1] != card) {
			this.cardsSelected[this.i] = card;
			this.i = 1;
			this.j++;
		} else if (this.i == 1 && this.cardsSelected[this.i - 1] != card) {
			this.cardsSelected[this.i] = card;
			this.i = 0;
			this.j++;
		}
	}
	clickNext(deviceRes) {
		this.audionotfound = false;
		this.audioState = 'stoped';
		this.audioStore.dispatch({
		  type: 'SET_AUDIO_STOPED'
		});
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		if (this.l == 0) {
			this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
			this.l++;
			this.selectedlike = this.cardsSelected;
			this.cardsSelected = [{ 'name': '', 'icon': '', 'title': '' }, { 'name': '', 'icon': '', 'title': '' }];
			if(this.unlike.length > 2) {
				this.getAttributes(this.unlike);
			} else {
				try {
					this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
						this.data = (res.attribute);
						this.cardsSelected = this.cards;
					});
				} catch (e) {
					this.utils.handleError(`top-skills-component.ts clickNext: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}	
				this.clickNext('desktopRes');
			}
		} else {
			this.unlike = [];
			this.unlike = this.cardsSelected;
			this.selectedUnlike = this.cardsSelected;
			if(this.selectedlike.length===0){
				this.selectedlike = this.cards;
			}
			sessionStorage.setItem('twoGood', JSON.stringify(this.selectedlike));
			sessionStorage.setItem('twoImprove', JSON.stringify(this.unlike));
			sessionStorage.setItem('Careertrekkerlike', JSON.stringify(this.selectedlike));
			sessionStorage.setItem('weights', JSON.stringify(this.weights));
			const datasave = [{
				'name': this.selectedlike[0].name,
				'goodAt': true,
				'top': true
			}, {
				'name': this.selectedlike[1].name,
				'goodAt': true,
				'top': true
			},
			{
				'name': this.unlike[0].name,
				'goodAt': true,
				'top': false
			}, {
				'name': this.unlike[1].name,
				'goodAt': true,
				'top': false
			}];
			this.saveChanges(datasave);
			if(deviceRes === 'mobileRes'){
				this.router.navigate(['/app/library-occupations'], {
					relativeTo: this.activatedRoute,
					queryParams: {
						'module': 'Careertrekker',
						'deviceRes': deviceRes
					}
				});
			}
			else{
			   this.router.navigateByUrl('/app/careertrekker/result');
			}
		}
		/* This dispatch is for storing the values for back issue. */
        this.trekkerTopSkillsStore.dispatch({ type: 'TREKKER_TOPSKILLS_VAL', payload: { 
		trekkerCardsSelected:{selectedLike:this.selectedlike,selectedUnlike:this.selectedUnlike,cards:this.cards}}});
	}
	saveChanges(skills) {
		this.apiModel.moduleName = 'careertrekker';
		this.apiModel.endUrl = 'user/skills/add';
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'POST';
		this.apiModel.data = skills;
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe();
		} catch (e) {
			this.utils.handleError(`top-skills-component.ts saveChanges: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	unsavedChanges() {
			const evnt = document.createEvent('CustomEvent');
		    this.eventService.dispatchAudioStop(evnt);
	/*if 'goodAtSkills' list is less then or equal to 2, then this.selectedlike = [], so that the 'goodAtSkills' top skills page will not be visible when we click backbutton at 'NeedToImproveSkills' top skills page.*/ 
		if(this.like.length<=2){
           this.selectedlike = [];  
		}
        if ((this.cardsSelected[1].name === '' && this.cardsSelected[0].name === '') && (this.selectedlike.length === 0)) {
			this.utils.backRoute();
        } else if (this.selectedlike.length===2 &&  this.cardsSelected[0].name !== '') {
            if ((this.cardsSelected[1].name !== '' || this.cardsSelected[0].name !== '')) {
                if (this.cardsSelected[1].name === '') {
					this.cardsSelected[0] = { 'name': '', 'icon': '', 'title': '' };
                } else {
                    this.cardsSelected[1] = { 'name': '', 'icon': '', 'title': '' };
                }
            }
        } else if (this.selectedlike.length===0) {
            if ((this.cardsSelected[1].name !== '' || this.cardsSelected[0].name !== '')) {
                if (this.cardsSelected[1].name === '') {
                    this.cardsSelected[0] = { 'name': '', 'icon': '', 'title': '' };
                } else {
                    this.cardsSelected[1] = { 'name': '', 'icon': '', 'title': '' };
				}
            }
        } else {
			if(this.like.length>2){
			this.cardsSelected = this.selectedlike;
			}
            this.selectedlike = [];
            this.l = 0;
			this.like = JSON.parse(sessionStorage.getItem('goodSkill'));
            this.unlike = JSON.parse(sessionStorage.getItem('ImpSkill'));
            setTimeout(() => {
                this.dataget(this.like);
                if (this.like.length == 2) {
                    this.dataget(this.unlike);
                }
            });
        }
    }
	ngOnDestroy() {
		this.compActive = false;
	}

	private setTrekkerTopSkillsTitle(){
		this.titleService.setTitle("Trekker Top Skills - CareerTrek");
	}
}
