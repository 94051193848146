/**Import angular core packages */
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { Observable } from "rxjs";
import {
  Pointer,
  Pointer_mobile,
} from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { RouteUrls } from "../../../shared/common-constants/route.constants";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { Next } from "../../../shared/common-animations/CT_animations";
import { takeWhile } from "rxjs/operators";
import {
  AttributeDataState,
  ExplorerChallengeState,
  ExplorerTaskStoreState,
  PageDataState,
  AudioState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
const imageurl = environment.ASSETS_BASE_URL;

@Component({
  selector: "app-explorer-task",
  templateUrl: "./about-occupations.html",
  styles: [],
  animations: [Pointer, Pointer_mobile, Next],
})
export class ExplorerTaskComponent implements OnInit, OnDestroy {
  currAttr: any = "";
  currweight: any = "";
  nextId: any;
  languageKey: any;
  keywords: any;
  savedDataIds: Array<string> = [];
  category: number;
  occtext: any;
  storedata: Array<string> = [];
  a = 0;
  sentence: any = [];
  sideImages: any = [];
  data: any;
  icon: any;
  imageUrl = imageurl;
  selectedIndex = 1;
  curOccAtrLen = 0;
  m = 0;
  l = 0;
  k = 0;
  present: any;
  resp: any;
  highbutton: boolean;
  mediumbutton: boolean;
  lowbutton: boolean;
  attributes: any = [];
  highdata: any = [];
  mediumdata: any = [];
  lowdata: any = [];
  highCheck: any = [];
  mediumCheck: any = [];
  lowCheck: any = [];
  high = "high";
  low = "low";
  medium = "medium";
  navactiveIds: Array<string> = [];
  activeIds: Array<number> = [];
  id: any;
  attr: any[];
  occsdata = [];
  attributesdata = [];
  lang = "";
  theme_name;
  savedData = [];
  savedData1 = [];
  moving = "forward";
  nextQues = false;
  prev = 0;
  savedIds = [];
  nexttitle: any;
  upnextId: any;
  curSelWeight = "";
  // The completed variable to set to true, to turn on the flip animation of the button
  completed: boolean;
  occCardAPIUrl: string;
  occLargeCardAPIUrl: any;
  compActive = true;
  explorerCategoryText: any;
  pageCode: string;
  headerAudioState = "stoped";
  careerAudioState = "stoped";
  attributeAudioState = "stoped";
  headerAudionotfound = false;
  careerAudionotfound = false;
  attributeAudionotfound = false;
  assetsUrl: string;
  curIndex = 0;
  curAudio = "";
  audioSelected: any;
  curOccId: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiCall: ApiCallService,
    public eventService: EventdispatchService,
    private apiModel: ApiCallClass,
    private router: Router,
    private explorerChallengeState: Store<ExplorerChallengeState>,
    private explorerTaskStore: Store<ExplorerTaskStoreState>,
    private store: Store<AttributeDataState>,
    private store1: Store<PageDataState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public dialogService: DialogopenService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    @Inject("OCC_CARD_URL") private occCardUrl: string,
    @Inject("OCC_LARGE_CARD_URL") private occLargeCardUrl: string,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string,
    private titleService: Title
  ) {
    this.occCardAPIUrl = occCardUrl;
    this.occLargeCardAPIUrl = occLargeCardUrl;
    this.assetsUrl = assetsMediaUrl;
    const colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[0]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.id = params["occId"];
        this.pageCode = params["pageCode"];
        this.category = parseInt(params["category"], 10);
        if (this.pageCode === "EXPLORER_PEOPLE") {
          this.explorerCategoryText = this.oninitpopupservice.getKeyWordData(
            this.pageCode
          );
        } else if (this.pageCode === "EXPLORER_IDEAS") {
          this.explorerCategoryText = this.oninitpopupservice.getKeyWordData(
            this.pageCode
          );
        } else if (this.pageCode === "EXPLORER_THINGS") {
          this.explorerCategoryText = this.oninitpopupservice.getKeyWordData(
            this.pageCode
          );
        }
        const ids = params["data"];
        if (ids !== "" && ids !== null && ids !== undefined) {
          this.navactiveIds = ids.split("&");
          this.activeIds = ids.split("&").map((i) => Number(i));
        }
      });
    this.lang = sessionStorage.getItem("session_token");
    this.store1.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "EXPLORER", pageCode: this.pageCode },
    });
    /* Here infopopup commented , because pop got called from into page based on categories */
    // this.infopopup(this.pageCode);
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/explorer/task") {
          if (e.type === "languageChanged") {
            if (
              this.sideImages[0] !== undefined &&
              this.sideImages[0] !== null
            ) {
              this.languageKey = this.sideImages[0].language;
            }
            this.lang = sessionStorage.getItem("session_token");
            // this.oninitpopupservice.pageCallText(this.lang, 'pages');
            this.oninitpopupservice.getOnLoadPopUp("GLOBAL_SETTINGS");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            this.infopopup(this.pageCode);
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });

    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
            this.careerAudioState = "stoped";
            this.careerAudionotfound = false;
            this.attributeAudioState = "stoped";
            this.attributeAudionotfound = false;
          } else if (this.curAudio === "career") {
            this.careerAudioState = result.audioState;
            this.careerAudionotfound = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
            this.attributeAudioState = "stoped";
            this.attributeAudionotfound = false;
          } else if (this.curAudio === "attribute") {
            this.attributeAudioState = result.audioState;
            this.attributeAudionotfound = result.audionotfound;
            this.headerAudioState = "stoped";
            this.headerAudionotfound = false;
            this.careerAudioState = "stoped";
            this.careerAudionotfound = false;
          }
        }
      });
  }
  infopopup(pageCode) {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp(pageCode);
      }.bind(this)
    );
  }
  ngOnInit() {
    this.dialogService.showLoading();
    this.store1
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          if (this.pageCode === "EXPLORER_PEOPLE") {
            this.explorerCategoryText = data.find(
              (obj) => obj.pageCode === "EXPLORER_PEOPLE"
            );
          } else if (this.pageCode === "EXPLORER_IDEAS") {
            this.explorerCategoryText = data.find(
              (obj) => obj.pageCode === "EXPLORER_IDEAS"
            );
          } else if (this.pageCode === "EXPLORER_THINGS") {
            this.explorerCategoryText = data.find(
              (obj) => obj.pageCode === "EXPLORER_THINGS"
            );
          }
          this.keywords = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
        }
      });
    setTimeout(() => {
      this.getapidata("attributes", this.lang);
    });
    this.setExplorerTaskTitle();
  }
  /*end of ngoninit*/

  unsavedChanges() {
    if (this.savedIds.length === 0 && this.curIndex === 0) {
      this.stopAudio();
      this.utils.backRoute();
    } else if (this.curIndex > 0) {
      this.moving = "backword";
      this.highbutton = false;
      this.mediumbutton = false;
      this.lowbutton = false;
      this.prev = this.curOccAtrLen;
      this.onClickChangeId(this.activeIds[this.curIndex - 1]);
      this.nexttitle = this.sideImages[this.curIndex + 1].title;
      this.nextQues = true;
    } else {
      this.router.navigateByUrl("/app/explorer/intro");
    }
  }

  getapidata(endurl, lang) {
    this.apiModel.moduleName = "explorer";
    this.apiModel.endUrl = endurl;
    this.apiModel.sessionID = lang;
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.resp = JSON.parse(resp);
          if (resp !== null && resp !== "") {
            this.attributesdata = this.resp;
            this.attr = JSON.parse(resp);
            this.occsdata = [];
            this.resp = [];
            for (let i = 0; i < this.navactiveIds.length; i++) {
              this.getoccdata(i);
            }
          }
        });
    } catch (e) {
      this.utils.handleError(`about-occupations-component.ts getapidata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  getoccdata(i) {
    this.apiModel.moduleName = "explorer";
    this.apiModel.endUrl = "occ/" + this.navactiveIds[i];
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp !== undefined && resp !== null) {
            const data = JSON.parse(resp);
            this.occsdata.push(data);
            this.resp.push(data);
            if (this.resp[2] !== undefined) {
              this.keywords = JSON.parse(
                sessionStorage.getItem("GLOBAL_SETTINGS")
              );
              this.sideImages = [];
              this.sideImages = this.mapOrder(
                this.resp,
                this.activeIds,
                "occId"
              );
              this.occsdata = this.sideImages;
              this.resp = this.occsdata;
              this.id = this.activeIds[0];
              this.explorerTaskStore
                .select("explorerTask")
                .pipe(takeWhile(() => this.compActive))
                .subscribe((taskresp) => {
                  if (
                    taskresp["explorerTask"].savedData &&
                    taskresp["explorerTask"].savedDataIds
                  ) {
                    this.savedData1 = taskresp["explorerTask"].savedData;
                    this.savedDataIds = taskresp["explorerTask"].savedDataIds;
                    this.id = this.activeIds[2];
                    this.onClickChangeId(this.activeIds[2]);
                  } else {
                    this.getOnLoadData();
                  }
                });
            }
            if (
              this.nextId !== "" &&
              this.nextId !== undefined &&
              this.nextId !== null
            ) {
              for (let k = 0; k < this.resp.length; k++) {
                if (this.resp[k].occId === this.nextId.occId) {
                  this.nextId = this.resp[k];
                  this.upnextId = this.nextId.occId;
                  this.nexttitle = this.nextId.title;
                }
              }
            }
          }
        });
    } catch (e) {
      this.utils.handleError(`about-occupations-component.ts getoccdata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  mapOrder(a, order, key) {
    const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
    return a.sort((a, b) => map[a[key]] - map[b[key]]);
  }

  getOnLoadData() {
    this.resp = this.occsdata;
    for (let i = 0; i < this.resp.length; i++) {
      if (this.resp[i].occId === parseInt(this.id, 10)) {
        this.data = this.resp[i];
      }
    }
    this.sentence = [];
    /*start of attributes data call */
    this.attr = this.attributesdata;
    for (let i = 0; i < this.attr.length; i++) {
      if (this.attr[i].type === "characteristic") {
        this.sentence.push(this.attr[i]);
      }
    }
    if (this.moving !== "backword") {
      this.attributes = [];
      this.mediumdata = [];
      this.lowdata = [];
      this.highdata = [];
      /* end of attributes data call*/
      /*start of logic  for display the first onload data*/
      if (this.data !== undefined) {
        this.attributes = this.data.attributes.filter((val) => val.type == "characteristic");
        if( this.attributes &&  this.attributes.length>0){
        for(let i = 0; i <this.attributes.length; i++){
          switch (this.attributes[i].weight) {
            case "high":
              if (i === 0) {
                this.highbutton = true;
                this.mediumbutton = false;
                this.lowbutton = false;
              }
              this.highdata.length++;
              break;
            case "medium":
              if (i === 0) {
                this.highbutton = false;
                this.mediumbutton = true;
                this.lowbutton = false;
              }
              this.mediumdata.length++;
              break;
            case "low":
              if (i === 0) {
                this.highbutton = false;
                this.mediumbutton = false;
                this.lowbutton = true;
              }
              this.lowdata.length++;
              break;
          }

      }
    }
          this.curSelWeight = "";
      }
    } else {
      /* Below code is to get the data of selected career even on backword */
      this.attributes = [];
      this.mediumdata = [];
      this.lowdata = [];
      this.highdata = [];
      this.curOccAtrLen = this.savedData1[this.id].curOccAtrLen;
      if (this.data !== undefined && this.curOccAtrLen < 6) {
          this.attributes = this.data.attributes.filter((val) => val.type == "characteristic");
          if( this.attributes &&  this.attributes.length>0){
            for(let i = 0; i <this.attributes.length; i++){
              switch (this.attributes[i].weight) {
                case "high":
                  if (i === 0) {
                    this.highbutton = true;
                    this.mediumbutton = false;
                    this.lowbutton = false;
                  }
                  this.highdata.length++;
                  break;
                case "medium":
                  if (i === 0) {
                    this.highbutton = false;
                    this.mediumbutton = true;
                    this.lowbutton = false;
                  }
                  this.mediumdata.length++;
                  break;
                case "low":
                  if (i === 0) {
                    this.highbutton = false;
                    this.mediumbutton = false;
                    this.lowbutton = true;
                  }
                  this.lowdata.length++;
                  break;
              }
            }
          this.curSelWeight = "";
        }
      }
      this.highdata = this.savedData1[this.id].HighData;
      this.mediumdata = this.savedData1[this.id].MediumData;
      this.lowdata = this.savedData1[this.id].LowData;
      this.highCheck = this.savedData1[this.id].HighCheck;
      this.mediumCheck = this.savedData1[this.id].MediumCheck;
      this.lowCheck = this.savedData1[this.id].LowCheck;
    }
    if (this.sideImages[0] !== undefined) {
      if (this.sideImages[0].language !== this.languageKey) {
        this.languageKey = this.sideImages[0].language;
        if (this.curIndex > 2) {
          this.nexttitle = this.sideImages[this.curIndex + 1].title;
        }
        this.dialogService.hideLoading();
      }
    }
    /*end of logic for display the first onload data*/
  }

  // Text does not show until this method trigger by click any '?' icon and set to respective attribute weight
  onQuesClick(id, text, inx) {
    this.curOccId = id;
    this.stopAudio();
    // condition to check weather it is completed career or not
    if (!(!this.highbutton && !this.mediumbutton && !this.lowbutton)) {
      this.curSelWeight = text;
      this.attributes.forEach((element) => {
        if (this.curOccAtrLen === this.attributes.length) {
          this.completed = !this.completed;
        }
        if (element.weight === text) {
          this.currweight = element.weight;
          for (let j = 0; j < this.sentence.length; j++) {
            if (this.sentence[j].name === element.name) {
              const highIndex = this.highdata.findIndex(
                (x) => x === this.sentence[j].icon
              );
              const medIndex = this.mediumdata.findIndex(
                (x) => x === this.sentence[j].icon
              );
              const lowIndex = this.lowdata.findIndex(
                (x) => x === this.sentence[j].icon
              );
              if (
                this.currweight === this.high &&
                highIndex.toString() === "-1"
              ) {
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.highdata[inx] = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              } else if (this.currweight === this.high && highIndex === inx) {
                // To show when clicked on revealed option
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              }
              if (
                this.currweight === this.medium &&
                medIndex.toString() === "-1"
              ) {
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.mediumdata[inx] = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              } else if (this.currweight === this.medium && medIndex === inx) {
                // To show when clicked on revealed option
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              }
              if (
                this.currweight === this.low &&
                lowIndex.toString() === "-1"
              ) {
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.lowdata[inx] = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              } else if (this.currweight === this.low && lowIndex === inx) {
                // To show when clicked on revealed option
                this.currAttr = this.sentence[j].title;
                this.icon = this.sentence[j].icon;
                this.audioSelected = this.sentence[j];

                break;
              }
            }
          }
        }
      });
      // Conditon to check weather its pre-selected option are not
      // tslint:disable-next-line:max-line-length
      if (
        (!this.highCheck[inx] && text === this.high) ||
        (!this.mediumCheck[inx] && text === this.medium) ||
        (!this.lowCheck[inx] && text === this.low)
      ) {
        if (text === this.high) {
          this.highCheck[inx] = this.icon;
        } else if (text === this.medium) {
          this.mediumCheck[inx] = this.icon;
        } else if (text === this.low) {
          this.lowCheck[inx] = this.icon;
        }
        this.curOccAtrLen++;
        this.addData(inx);
      }
    }
  }
  onClickChangeId(id) {
    /*this is for reasigning the data when we click on the side iages or next */
    this.id = id;
    this.curIndex = this.activeIds.indexOf(id);
    this.stopAudio();
    this.moving = "forward";
    for (let i = 0; i < this.savedData.length; i++) {
      if (parseInt(this.id, 10) === parseInt(this.savedData[i].Id, 10)) {
        this.a--;
        this.highbutton = false;
        this.mediumbutton = false;
        this.lowbutton = false;
        this.moving = "backword";
        this.highdata = this.savedData[i].HighData;
        this.mediumdata = this.savedData[i].MediumData;
        this.lowdata = this.savedData[i].LowData;
      }
    }
    if (this.savedData1[this.id] !== undefined) {
      this.moving = "backword";
    } else if (this.moving === "forward") {
      for (let i = 0; i < this.savedData.length; i++) {
        if (this.savedIds.indexOf(this.savedData[i].Id) < 0) {
          this.savedIds.push(this.savedData[i].Id);
        }
      }
      this.curOccAtrLen = 0;
      this.prev = 0;
      this.m = 0;
      this.l = 0;
      this.k = 0;
      this.highdata = [];
      this.lowdata = [];
      this.mediumdata = [];
      this.highCheck = [];
      this.lowCheck = [];
      this.mediumCheck = [];
      this.attributes = [];
      this.sentence = [];
    }
    this.nextQues = false;
    this.getOnLoadData();
  }
  next(inx) {
    this.nextQues = false;
    if (this.curIndex < 2) {
      this.a++;
      if (this.curIndex < 1) {
        this.nexttitle = this.sideImages[this.curIndex + 2].title;
      }
      this.onClickChangeId(this.activeIds[this.curIndex + 1]);
    } else {
      this.navigateToChallengeWithPopup();
    }
  }

  /*start of logic for buttons two show and to add those to array*/
  addData(inx) {
    /**
     * Commented below code, it makes the selection based text and icon, not based on selected option weight
     */
    /*This array is used to store the career data,
		 even its not completed or changed  career in the middle of assessment by clicking on side image */
    this.savedData1[this.id] = {
      Id: this.id,
      HighData: this.highdata,
      MediumData: this.mediumdata,
      LowData: this.lowdata,
      curOccAtrLen: this.curOccAtrLen,
      HighCheck: this.highCheck,
      MediumCheck: this.mediumCheck,
      LowCheck: this.lowCheck,
    };
    if (this.curOccAtrLen === this.attributes.length) {
      if (this.a <= 2) {
        this.highbutton = false;
        this.mediumbutton = false;
        this.lowbutton = false;
        this.curSelWeight = "";
        if (
          this.storedata.indexOf(this.id) < 0 &&
          this.storedata.indexOf(this.id.toString()) < 0
        ) {
          this.storedata.push(this.id);
          this.savedIds.push(this.id);
        }
      }
      this.moving = "forward";
      if (
        this.savedDataIds.indexOf(this.id.toString()) < 0 ||
        this.savedDataIds.indexOf(this.id) < 0
      ) {
        if (
          this.savedData.length >= 1 &&
          this.savedData.length !== this.activeIds.length &&
          (this.highdata !== null ||
            this.mediumdata !== null ||
            this.lowdata !== null)
        ) {
          for (let i = 0; i < this.savedData.length; i++) {
            if (
              this.savedData[i].Id !== parseInt(this.id, 10) &&
              i === this.savedData.length - 1 &&
              (this.highdata !== null ||
                this.mediumdata !== null ||
                this.lowdata !== null)
            ) {
              this.savedDataIds.push(this.id);
              this.savedData.push({
                Id: this.id,
                HighData: this.highdata,
                MediumData: this.mediumdata,
                LowData: this.lowdata,
              });
            }
          }
        } else if (
          this.savedData.length !== this.activeIds.length &&
          (this.highdata !== null ||
            this.mediumdata !== null ||
            this.lowdata !== null)
        ) {
          this.savedDataIds.push(this.id);
          this.savedData.push({
            Id: this.id,
            HighData: this.highdata,
            MediumData: this.mediumdata,
            LowData: this.lowdata,
          });
        }
      } else {
        this.a--;
      }
      let nextid = "";
      nextid = this.navactiveIds[this.curIndex + 1];
      for (let i = 0; i < this.resp.length; i++) {
        if (this.resp[i].occId === parseInt(nextid, 10)) {
          this.nextId = this.resp[i];
          this.upnextId = this.nextId.occId;
          this.nexttitle = this.nextId.title;
        }
      }
      if (this.savedData.length === this.activeIds.length) {
        this.a = 3;
      }
    }
  }
  nextquestion() {
    if (this.curOccAtrLen < this.attributes.length) {
      for (let j = 0; j < this.sentence.length; j++) {
        if (this.sentence[j].name === this.attributes[this.curOccAtrLen].name) {
          this.currweight = this.attributes[this.curOccAtrLen].weight;
          this.currAttr = this.sentence[j].title;
          this.icon = this.sentence[j].icon;
          break;
        }
      }
      if (this.attributes[this.curOccAtrLen].weight === this.high) {
        this.highbutton = true;
        this.mediumbutton = false;
        this.lowbutton = false;
      }
      if (this.attributes[this.curOccAtrLen].weight === this.medium) {
        this.highbutton = false;
        this.mediumbutton = true;
        this.lowbutton = false;
      }
      if (this.attributes[this.curOccAtrLen].weight === this.low) {
        this.mediumbutton = false;
        this.highbutton = false;
        this.lowbutton = true;
      }
    }
    this.curOccAtrLen++;
    this.nextQues = false;
  }
  navigateToChallengeWithPopup() {
    const data = {
      page_theme_color: "explorer-theme",
    };
    const model_data = {
      category: this.category,
      activeIds: this.activeIds,
    };
    this.stopAudio();
    this.dialogService.navigateToChallengePopup(data, model_data);
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result === "Challenge") {
          try {
            /*After completion of challenge, Navigating through careerLibrary to Landing page while clicking
                    on Icon (i.e. navigating to landing page) previous challenge value storing. So here making empty*/

            this.explorerChallengeState.dispatch({
              type: "EXPLORER_CHALLENGE_VAL",
              payload: {
                explorerChallenge: {
                  savedIds: [],
                  totalLength: 0,
                  score: 0,
                },
              },
            });

            this.explorerTaskStore.dispatch({
              type: "EXPLORER_TASK_STORE_VAL",
              payload: {
                savedData: this.savedData1,
                savedDataIds: this.savedDataIds,
              },
            });

            this.router.navigate(["../challenge"], {
              relativeTo: this.activatedRoute,
              queryParams: {
                category: this.category,
                taskActiveIds:
                  this.activeIds[0] +
                  "&" +
                  this.activeIds[1] +
                  "&" +
                  this.activeIds[2],
              },
            });
          } catch (e) {
            this.utils.handleError(`about-occupations-component.ts navigateToChallengeWithPopup: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  navigateToChallenge() {
    this.stopAudio();
    /*After completion of challenge, Navigating through careerLibrary to Landing page while clicking
	   on Icon (i.e. navigating to landing page) previous challenge value storing. So here making empty*/

    this.explorerChallengeState.dispatch({
      type: "EXPLORER_CHALLENGE_VAL",
      payload: {
        explorerChallenge: {
          savedIds: [],
          totalLength: 0,
          score: 0,
        },
      },
    });
    this.explorerTaskStore.dispatch({
      type: "EXPLORER_TASK_STORE_VAL",
      payload: {
        savedData: this.savedData1,
        savedDataIds: this.savedDataIds,
      },
    });
    try {
      this.router.navigate(["../challenge"], {
        relativeTo: this.activatedRoute,
        queryParams: {
          category: this.category,
          taskActiveIds:
            this.activeIds[0] +
            "&" +
            this.activeIds[1] +
            "&" +
            this.activeIds[2],
        },
      });
    } catch (e) {
      this.utils.handleError(`about-occupations-component.ts navigateToChallenge: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.careerAudioState = "stoped";
    this.careerAudionotfound = false;
    this.attributeAudioState = "stoped";
    this.attributeAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }
  /*end of logic  for buttons two show and to add those to array*/
  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }
  indCareerAudioClick(text, data) {
    this.curAudio = "career";
    this.audioService.audioFunction(text, data, true);
  }
  indAttribAudioClick(text, data) {
    this.curAudio = "attribute";
    var data1 = [];
    data1[0] = data.media.occAudio[0].src
      .replace("< <", "<<")
      .replace("> >", ">>")
      .replace(/<<occId>>/g, this.curOccId);
    data1[1] = data.media.occAudio[1].src
      .replace("< <", "<<")
      .replace("> >", ">>")
      .replace(/<<occId>>/g, this.curOccId);
    this.audioService.audioFunction(text, data1, "", "pipe");
  }
  ngOnDestroy() {
    this.compActive = false;
  }

  private setExplorerTaskTitle(){
    this.titleService.setTitle("Explorer Task Exercise - CareerTrek");
  }
}
