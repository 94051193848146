<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{trekkerChallengeText?.header}}
      <app-audio-button
        [audionotfound]="audionotfound"
        [audioState]="headerAudioState"
        (click)="audioClick('inside',trekkerChallengeText,'headerAudio')"
      ></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && headerAudioState=='warning'" 
        (click)="audioClick('inside',trekkerChallengeText,'headerAudio')">
        <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && headerAudioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && headerAudioState=='warning'">warning</i>
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div
      class="Career-trek-challenge content-box"
      *ngIf="factoidData!=undefined&&factoidData!=''&&factoidData!=null"
    >
      <div>
        <div class="desktop-design-show">
          <div flex v-center h-center>
            <div class="number-score" font-bold>{{questions}}/10</div>
            <div class="w-100" flex v-center h-center>
              <div class="image-text">
                <img
                  src="{{assetsUrl}}{{factoidData[startInx]?.icon}}.svg"
                  alt="{{factoidData[startInx]?.title}}"
                  class="text-center"
                />
              </div>
              <h2 class="career-treker-heading">
                {{factoidData[startInx]?.title}}
              </h2>
              <app-audio-button
                [audionotfound]="audionotfound"
                [audioState]="quesAudioState"
                (click)="audioClick('inside',factoidData[startInx],'quesAudio')"
              ></app-audio-button>

              <!-- <button mat-raised-button [disabled]="audionotfound==true && quesAudioState=='warning'" color="primary" class="audio-box"
                (click)="audioClick('inside',factoidData[startInx],'quesAudio')">
                <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='stoped'">volume_up</i>
                <i class="material-icons" *ngIf="audionotfound==false && quesAudioState=='playing'">stop</i>
                <div class="audio-loading" *ngIf="audionotfound==false && quesAudioState=='loading'"></div>
                <i class="material-icons" *ngIf="audionotfound==true && quesAudioState=='warning'">warning</i>   
              </button> -->
            </div>
          </div>
          <div class="inform-text" flex v-center h-center>
            <p flex v-center h-center m-0>
              {{factoidData[startInx]?.question}}
            </p>
          </div>
        </div>
        <div class="mobile-design-show">
          <div flex v-center>
            <span class="number-score" font-bold>{{questions}}/10</span>
            <div class="image-text w-100" flex v-center h-center>
              <img
                src="{{assetsUrl}}{{factoidData[startInx]?.icon}}.svg"
                alt="{{factoidData[startInx]?.title}}"
              />
              <h1 class="career-treker-heading">
                {{factoidData[startInx]?.title}}
              </h1>
              <app-audio-button
                [audionotfound]="audionotfound"
                [audioState]="quesAudioState"
                (click)="audioClick('inside',factoidData[startInx],'quesAudio')"
              ></app-audio-button>
              <!-- <button
                mat-raised-button
                color="primary"
                [disabled]="audionotfound==true && quesAudioState=='warning'"
                class="audio-box"
                (click)="audioClick('inside',factoidData[startInx],'quesAudio')"
                flex
                v-center
                h-center
              >
                <i
                  class="material-icons"
                  *ngIf="audionotfound==false && quesAudioState=='stoped'"
                  >volume_up</i
                >
                <i
                  class="material-icons"
                  *ngIf="audionotfound==false && quesAudioState=='playing'"
                  >stop</i
                >
                <div
                  class="audio-loading"
                  *ngIf="audionotfound==false && quesAudioState=='loading'"
                ></div>
                <i
                  class="material-icons"
                  *ngIf="audionotfound==true && quesAudioState=='warning'"
                  >warning</i
                >
              </button> -->
            </div>
            <div ngStyle.xs="width: 35px;">
              <div *ngIf="(checkedVar == 'yes')">
                <button
                  mat-fab
                  color="primary"
                  (click)="goToNextFactoid(startInx)"
                  [@flipAnimation]="completed"
                >
                  <i class="material-icons">arrow_forward</i>
                </button>
              </div>
            </div>
          </div>
          <p
            class="text-center"
            flex
            v-center
            h-center
            ngStyle.xs="margin-top:5px;margin-bottom:0;"
          >
            {{factoidData[startInx]?.question}}
          </p>
        </div>
        <div class="multi-options">
          <div
            *ngFor="let innerItem of factoidData[startInx].Options;let inx = index;"
            tabindex=" innerItem.optionSelected || checkedVar == 'yes'  ?-1:0"
          >
            <button
              class="options-cards"
              tabindex=" innerItem.optionSelected || checkedVar == 'yes'  ?-1:0"
              [ngClass]="{'green':((innerItem.isCorrect == true) &&  innerItem.optionSelected) , 'red':(!innerItem.isCorrect) && innerItem.optionSelected} "
              [ngStyle]="(checkedVar == 'yes' || clickEvent[inx] == true  && innerItem.optionSelected )? {'pointer-events': 'none','cursor':'not-allowed'}:{'cursor': 'pointer'}"
              (click)="(innerItem.optionSelected) ? '' : callFactoidsAttempt(innerItem,inx)"
              flex
              v-center
              ngStyle.xs="margin:5px auto;"
            >
              <div
                class="question w-100"
                flex
                v-center
                tabindex="innerItem.optionSelected || checkedVar == 'yes'  ?-1:0"
              >
                <div class="options" flex v-center>
                  {{innerItem?.optionKey}}
                </div>
                <p
                  class="w-100 text-left"
                  m-0
                  ngStyle.xs="font-size: 0.75rem;"
                  flex
                  v-center
                >
                  {{innerItem?.answer}}
                </p>
                <i
                  flex
                  v-center
                  *ngIf="(!innerItem.isCorrect) && innerItem.optionSelected"
                  class="material-icons"
                  ngStyle.xs="font-size: 0.75rem;"
                  >block</i
                >
                <i
                  flex
                  v-center
                  *ngIf="(innerItem.isCorrect) && innerItem.optionSelected"
                  class="material-icons"
                  ngStyle.xs="font-size: 0.75rem;"
                  >done</i
                >
              </div>
            </button>
          </div>
          <div class="desktop-design-show text-center">
            <ul class="score-data list-style-none" flex v-center h-center p-0>
              <li>
                <div class="score" flex v-center h-center>0</div>
              </li>
              <li class="progress-bar">
                <mat-progress-bar
                  mode="determinate"
                  value="{{score}}"
                ></mat-progress-bar>
              </li>
              &nbsp;
              <li>
                <div class="score" flex v-center h-center>100</div>
              </li>
            </ul>
            <span class="progress-bar-title" font-bold
              >{{keywords?.dictionary?.score}} {{score}}</span
            >
          </div>
          <ul
            class="score-data list-style-none mobile-design-show text-center"
            p-0
            m-0
          >
            <li>
              <div flex v-center justify-between>
                <div class="score" flex v-center h-center>0</div>
                <div class="progress-bar">
                  <mat-progress-bar
                    mode="determinate"
                    value="{{score}}"
                  ></mat-progress-bar>
                </div>
                <div class="score" flex v-center h-center>100</div>
              </div>
              <span class="progress-bar-title" font-bold
                >{{keywords.dictionary.score}} {{score}}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- (click)="modalOpen()" -->
      <div class="desktop-design-show" *ngIf="(checkedVar == 'yes')">
        <button
          class="next-level dark-pink"
          mat-fab
          color="primary"
          (click)="goToNextFactoid(startInx)"
          [@flipAnimation]="completed"
        >
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>
