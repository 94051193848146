/** Angular imports */
import { Injectable } from '@angular/core';

/** Rxjs imports */
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
/** This service is used to track all the popup events and dispatch click events */
export class EventdispatchService {

        constructor() { }

        trackEvnt: Subject<Event> = new Subject<Event>();
        imgTrackEvnt: Subject<any> = new Subject<any>();
        stopAudio: Subject<Event> = new Subject<Event>();
        thumbUpEvnt: Subject<Event> = new Subject<Event>();
        thumbUpDownEvnt: Subject<Event> = new Subject<Event>();// For pathfinder modal popup


        // Below method used to dispatch events.
        dispatch(data: Event) {
                this.trackEvnt.next(data);
        }
        dispatchAudioStop(data: Event) {
                this.stopAudio.next(data);
        }
        // Below method is used to listen to the dispatched events.
        // We have to use .subscribe() to track the method success callback in calledcall.
        listen(): Observable<Event> {
                return this.trackEvnt.asObservable();
        }
        listenAudioStop(): Observable<Event> {
                return this.stopAudio.asObservable();
        }
        // Below method used to dispatch events.
        dispatchImg(data: string) {
                this.imgTrackEvnt.next({ text: data });
        }

        // Below method is used to listen to the dispatched events.
        // We have to use .subscribe() to track the method success callback in calledcall.
        listenImg(): Observable<any> {
                return this.imgTrackEvnt.asObservable();
        }
        //Below dispatch is from exploreoccdialog;
        dispatchThumbUpEvent(data){
                this.thumbUpEvnt.next(data);
        }
        //Listening to the dispatch from exploreoccdialog;
        listenThumbUpEvent(): Observable<any>{
                return this.thumbUpEvnt.asObservable();
        }
        //Below dispatch is from pathfinder dailog;
        dispatchPathFinderThumbUpDownEvent(data){
                this.thumbUpDownEvnt.next(data);
        }
        //Listening to the dispatch from pathfinderoccdialog;
        listenPathFinderThumbUpDownEvent(): Observable<any>{
                return this.thumbUpDownEvnt.asObservable();
        }
        
}
