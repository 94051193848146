import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-discoverer-module',
  template: `<app-static-header></app-static-header>
	<router-outlet></router-outlet>
	<app-static-footer></app-static-footer>`
})
export class DiscovererModuleComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Discoverer Intro - CareerTrek");
  }

}
