export const environment = {
  production: true,
  AUTH_BASE_URL: 'https://qa.services.careertrek.org/',
  API_BASE_URL: 'https://qa.services.careertrek.org/api/v1/',
  OCC_CARD_URL: 'https://images.intocareers.org/careertrek/Career250x175/',
  OCC_LARGE_DIMENSIONS_URL: 'https://images.intocareers.org/careertrek/Career600x300/',
  ASSETS_MEDIA_URL : 'https://images.intocareers.org/careertrek/svg/',
  ASSETS_MEDIA_PNG_URL:'https://images.intocareers.org/careertrek/png',
  ASSETS_BASE_URL: sessionStorage.getItem('assetsData'),
  THEME_COLORS: ['explorer-theme', 'pathfinder-theme', 'careertrekker-theme', 'occ-explorer-theme', 'quick-pic-theme', 'own-occ-theme', 'discoverer-theme', 'scout-theme', 'navigator-theme', 'career-corner-theme'],
  enableGA4:false
};
