<div class="Career-trek-content">
  <div class="container content-inner-header">
    <div class="text-right">
      <button
        *ngIf="restoreActive"
        mat-raised-button
        color="primary"
        (click)="restore()"
        class="restore-button"
      >
        {{keyWords?.dictionary?.restore}}
      </button>
      <h4 class="landing-page-heading text-center">
        {{modalData?.header}}
        <app-audio-button
          [audionotfound]="audionotfound"
          [audioState]="audioState"
          (click)="audioClick('inside',modalData)"
        ></app-audio-button>
        <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',modalData)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>    
      </button> -->
      </h4>
    </div>
  </div>
  <div class="container">
    <div class="quickpic-intro content-box">
      <div class="mobile-heading mobile-design-show" flex v-center>
        <div class="w-100">
          <h1 class="text-center" font-normal text-color="primary">
            {{modalData?.menuTitle}}
          </h1>
          <hr />
        </div>
        <!-- <button mat-raised-button text-color="primary" class="readmore-button" >
          <div flex v-center>
            <span>Read More</span>
            <div class="circle-eye" ngStyle.xs="right:-10px" flex v-center h-center background-color="primary">
              <i class="material-icons" ngStyle.xs="font-size:12px;">remove_red_eye</i>
            </div>
          </div>
        </button> -->
      </div>
      <p class="text-center mobile-design-show">
        {{modalData?.pageText?.expressions?.items[0].text}}
      </p>
      <mat-card class="card sub-card w-100">
        <div
          class="mobile-heading desktop-design-show"
          flex
          *ngIf="modalData!=undefined"
        >
          <h1 class="text-center w-100" font-normal text-color="primary">
            {{modalData?.menuTitle}}
          </h1>
          <!-- <button *ngIf="restoreActive" mat-raised-button color="primary" (click)="restore()" class="restore-button">
            {{keyWords.dictionary.restore}}
          </button> -->
        </div>
        <p class="text-center desktop-design-show" ngStyle.xs="0 10px;">
          {{modalData?.pageText?.expressions?.items[0].text}}
        </p>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayout.sm="column"
          fxFlexFill
          class="common-row-sub"
          fxLayoutGap="20px"
          fxLayoutGap.sm="10px"
          fxLayoutGap.xs="5px"
        >
          <div fxFlex="" class="data-card desktop-design-show" flex v-center>
            <img
              src="./assets/images/baby_CCI.png"
              class="m-auto"
              width="100px"
            />
          </div>
          <div fxFlex="" class="data-card">
            <h4 font-bold>{{modalData?.pageText?.sections[0]?.title}}:</h4>
            <p>{{modalData.pageText?.sections[1]?.title}}:</p>
            <ul p-0 class="list-style-none w-100 smile-icons" m-0 flex>
              <li m-0>
                <!-- <img src="./assets/images/very-much.png" class="m-auto" flex> -->
                <i
                  class="material-icons w-100 text-center"
                  text-color="primary"
                >
                  mood
                </i>
                <span
                  class="text-center w-100 like-text"
                  flex
                  h-center
                  font-bold
                  >{{modalData?.pageText?.sections[1]?.items[0]?.text}}</span
                >
              </li>
              <li m-0>
                <!-- <img src="./assets/images/like.png" class="m-auto" flex> -->
                <i
                  class="material-icons w-100 text-center"
                  text-color="primary"
                >
                  sentiment_satisfied
                </i>
                <span
                  class="text-center w-100 like-text"
                  flex
                  h-center
                  font-bold
                  >{{modalData?.pageText?.sections[1]?.items[1]?.text}}</span
                >
              </li>
              <li m-0>
                <!-- <img src="./assets/images/not-sure.png" class="m-auto" flex> -->
                <i
                  class="material-icons w-100 text-center"
                  text-color="primary"
                >
                  sentiment_very_dissatisfied
                </i>
                <span
                  class="text-center w-100"
                  font-bold
                  flex
                  h-center
                  like-text
                  >{{modalData?.pageText?.sections[1]?.items[2]?.text}}</span
                >
              </li>
              <li m-0>
                <!-- <img src="./assets/images/dislike.png" class="m-auto" flex> -->
                <i
                  class="material-icons w-100 text-center"
                  text-color="primary"
                >
                  mood_bad
                </i>
                <span class="text-center w-100" flex h-center font-bold
                  >{{modalData?.pageText?.sections[1]?.items[3]?.text}}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </mat-card>

      <div class="desktop-design-show">
        <button
          mat-fab
          color="primary"
          [@flipAnimation]="completed"
          (click)="navigateToAssessment()"
          class="next-level"
        >
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <div class="mobile-design-show">
        <button
          mat-fab
          color="primary"
          class="quick-arrow"
          [@flipAnimation]="completed"
          (click)="navigateToAssessment()"
        >
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>
