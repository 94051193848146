<app-static-header></app-static-header>
<div class="Career-trek-content occs-content-page occ-explorer-card" *ngIf="cards!=[]" (scroll)="scrollHandler()">
  <div class="container text-center heading-height">
    <h4 class="landing-page-heading">
      {{instructionText?.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState"
        (click)="audioClick('inside',instructionText)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',instructionText)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>    
        </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="content-box occupation-explorer">
      <div class="filter-list infinet-scroll-height">
        <div fxLayout="row" fxLayout.xs="column" fxFlexFill class="common-row-sub"
        fxLayoutGap="5px">
        <div fxFlex="75%" fxFlex.lg="70%" fxFlex.md="62%" fxFlex.xl="60%" fxFlex.sm="50%"
          class="card-list largedevice-design-show"
          [ngClass]="(cards.length == 0) && !((module=='All') || (module=='Explorer') || (module=='Pathfinder') || (module=='Careertrekker'))? 'occ-card-no-result':''">
          <!-- infinite-scroll-block -->
          <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
            class="w-100 text-center">
            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutWrap="wrap" fxLayoutGap="2px">
              <div *ngIf="cards.length == 0" v-end class="no-data-div custom-nodata-available-text d-flex">
                <p font-bold class="w-100 text-center" m-0>
                  {{keywords?.dictionary?.noResults}}
                  <br />{{keywords?.dictionary?.changeFilter}}
                </p>
              </div>
              <!-- cards:{{cards|json}} -->
              <div fxFlex.md="31%" fxFlex.lg="30%" fxFlex.xl="32%" fxFlex.sm="49%"
                *ngFor="let a of cards  | orderBy : 'title' ;let i = index">
                <mat-card class="card text-center" pointer tabindex="0" (click)="modalOpen(a,i)"
                  (keyup.enter)="modalOpen(a,i)">
                  <div>
                    <img src="{{occCardAPIUrl}}{{a?.media?.images.card}}"
                      (load)="i == cards.length-1?hideLoadingSpinner():''" />
                  </div>
                  <div class="opacity-text">
                    <!-- class="{{themeColor}}" -->
                    <!-- [class.{{themeColor}}]="!((thumbTrue.indexOf(a.occId)===-1) && (thumbFalse.indexOf(a.occId)===-1)) || a.viewed" -->
                    <!-- [ngClass]="{ 'themeColor' : !((thumbTrue.indexOf(a.occId)===-1) && (thumbFalse.indexOf(a.occId)===-1)) || a.viewed}" -->
                    <p m-0 flex v-center [ngClass]="getThemeColorForActiveBar(a)">
                      <span class=" w-100">{{a?.title}}</span>
                      <img *ngIf="thumbTrue.indexOf(a?.occId)>=0" alt="thumb-up-filled icon"
                        src="{{assetsURL}}thumbs-up-outline-white.svg" />
                      <!-- <img *ngIf="thumbFalse.indexOf(a?.occId)>=0" alt="thumb-down-filled icon"
                        src="{{assetsURL}}thumbs-down-outline-white.svg" /> -->
                    </p>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="" fxFlex.xs="50%" flex-basis>
          <div class="filter-option largedevice-design-show" flex v-center *ngIf="module=='All'">
            <p class="w-100" m-0>
              {{keywords?.dictionary?.favoriteOccupations}}
            </p>
            <button matTooltip="{{keywords?.dictionary?.rateOcc}}" matTooltipClass="custom-tooltip" mat-mini-fab
              [ngClass]="filterThumb!='thumb_up'?'blue-unfill':'blue-fill'" #tooltip11="matTooltip"
              (click)="favouriteOccupationFilter('thumb_up');tooltip11.show()" (blur)="tooltip11.hide()">
              <img *ngIf="filterThumb!=='thumb_up'" alt="thumb-up icon" src="{{assetsURL}}thumbs-up-outline-grey.svg" />
              <img *ngIf="filterThumb=='thumb_up'" alt="thumb-up-filled icon"
                src="{{assetsURL}}thumbs-up-hover-gray.svg" />
            </button>
            <!-- <button matTooltip="{{keywords?.dictionary?.low}}" matTooltipClass="custom-tooltip" mat-mini-fab
              [ngClass]="filterThumb!='thumb_down'?'red-unfill':'red-fill'" #tooltip12="matTooltip"
              (click)="favouriteOccupationFilter('thumb_down');tooltip12.show()" (blur)="tooltip12.hide()">
              <img *ngIf="filterThumb!=='thumb_down'" alt="thumb-down icon"
                src="{{assetsURL}}thumbs-Down-outline-grey.svg" />
              <img *ngIf="filterThumb=='thumb_down'" alt="thumb-down-filled icon"
                src="{{assetsURL}}thumbs-down-hover-gray.svg" />
            </button> -->
            <button matTooltip="{{keywords?.dictionary?.notRated}}" matTooltipClass="custom-tooltip" mat-mini-fab
              [ngClass]="filterThumb!='undesided'?'black-unfill':'black-fill'" #tooltip13="matTooltip"
              (click)="favouriteOccupationFilter('undesided');tooltip13.show()" (blur)="tooltip13.hide()">
              <img *ngIf="filterThumb!=='undesided'" alt="hand icon" src="{{assetsURL}}Hand-icon-outline.svg" />
              <img *ngIf="filterThumb=='undesided'" alt="hand-filled icon" src="{{assetsURL}}Hand-icon-filled.svg" />
            </button>
            <!-- <button matTooltip="{{keywords?.dictionary?.selectAll}}" matTooltipClass="custom-tooltip" mat-mini-fab
              [ngClass]="filterThumb!='all'?'red-unfill':'red-fill'" #tooltip14="matTooltip"
              (click)="favouriteOccupationFilter('all');tooltip14.show()" (blur)="tooltip14.hide()">
              <img *ngIf="filterThumb!=='all'" alt="thumb-down icon" src="{{assetsURL}}plus-outline.svg" />
              <img *ngIf="filterThumb=='all'" alt="thumb-down-filled icon" src="{{assetsURL}}plus-outline-filled.svg" />
            </button> -->
          </div>
          <div class="custom-tabs largedevice-design-show">
            <mat-tab-group [(selectedIndex)]="tabSelectedIndex" class="{{themeColor}}">
              <mat-tab *ngIf="module=='Scout'||module=='All' ">
                <ng-template mat-tab-label>
                  <div class="tab-button scout" #tooltip="matTooltip" flex v-center h-center
                    (click)="tabClick('group');changeTheme('scout');tooltip.show()" (blur)='tooltip.hide()'
                    matTooltipClass="custom-tooltip"
                    matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.scout?.tab | libraryScoutPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.group}}">
                    <img src="{{assetsURL}}Scout.svg" alt="scout-image"
                      [ngClass]="(tabSelectedIndex == 0)?'rotate-animation':''">
                    <span class="tab-name" [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[8]}}</span>
                  </div>
                </ng-template>
                <div class="card circle-image-list-status custom-library-circle scout-body" flex v-center
                  *ngFor="let a of scout;index as i">
                  <div class=" circle-img " flex v-center h-center *ngIf="scout!=undefined">
                    <img *ngIf="a.icon" src="{{assetsURL}}{{a.icon}}.svg" alt="{{a.name}}">
                  </div>
                  <div (click)="scoutClick(a.title,i);modalarray[i] == ''? modalarray[i] = 'All' :modalarray[i] = ''">
                    <mat-checkbox [checked]="true" *ngIf="modalarray[i] == 'All'">
                    </mat-checkbox>
                    <mat-checkbox *ngIf="modalarray[i] == ''">
                    </mat-checkbox>
                  </div>
                  <div class="radio-options">
                    <p m-0>{{a?.title | titlecase}}</p>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="module === 'Navigator'|| module=='All' ">
                <ng-template mat-tab-label>
                  <div class="tab-button navigator" #tooltip="matTooltip" flex v-center h-center
                    (click)="tabClick('value');changeTheme('navigator');tooltip.show()" (blur)='tooltip.hide()'
                    matTooltipClass="custom-tooltip"
                    matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.navigator?.tab | libraryNavigatorPipe : keywords?.dictionary?.filterBy : keywords?.dictionary?.workValue}}">
                    <img src="{{assetsURL}}navigator.svg" alt="navigator"
                      [ngClass]="(tabSelectedIndex == 1)?'rotate-animation':''">
                    <span class="tab-name" [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[6]}}</span>
                  </div>
                </ng-template>
                <div *ngFor="let nav of navigatorData;index as i"
                  class="card circle-image-list-status custom-library-circle navigator-body" flex v-center>
                  <div class="circle-img " flex v-center h-center>
                    <img src="./assets/Svg/{{ nav.icon }}.svg" alt="navigator">
                  </div>
                  <div
                    (click)="navigatorClick(nav.name,i);modalarray[i] == ''? modalarray[i] = 'All' :modalarray[i] = ''">
                    <mat-checkbox [checked]="true" *ngIf="modalarray[i] == 'All'">
                    </mat-checkbox>
                    <mat-checkbox *ngIf="modalarray[i] == ''">
                    </mat-checkbox>
                  </div>
                  <div class="radio-options">
                    <p m-0>{{nav.title}}</p>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="module=='Explorer'||module=='All'">
                <ng-template mat-tab-label>
                  <div class="tab-button explorer" #tooltip="matTooltip" flex v-center h-center
                    (click)="tabClick('characteristic');changeTheme('explorer');tooltip.show()" (blur)="tooltip.hide()"
                    matTooltipClass="custom-tooltip"
                    matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.explorer?.tab | libraryCharacteristicPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.characteristic}}">
                    <img src="assets/Svg/gloab.svg" alt="explorer icon"
                      [ngClass]="(tabSelectedIndex == 2)?'rotate-animation':''">
                    <span class="tab-name" [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[0]}}</span>
                  </div>
                </ng-template>
                <div class="card circle-image-list-status custom-library-circle explorer-body" flex v-center
                  *ngFor="let a of arr">
                  <div class="circle-img" flex v-center h-center>
                    <img src="assets/Svg/gloab.svg" alt="explorer icon" />
                  </div>
                  <div class="radio-options">
                    <p>{{keywords?.dictionary?.occGroup}}</p>
                    <ul class="list-style-none" flex p-0 m-0>
                      <li *ngFor="let l of list1">
                        <mat-radio-group [(ngModel)]="occgroup2">
                          <mat-radio-button [value]="l.categoryId" (click)="occGroupClick(l.categoryId)">
                            <p>{{l.title | titlecase}}</p>
                          </mat-radio-button>
                        </mat-radio-group>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card circle-image-list-status custom-library-circle explorer-body" flex v-center
                  *ngFor="let a of explorer;index as i">
                  <div class="circle-img" flex v-center h-center>
                    <img class="w-100" src="assets/Svg/{{a.icon}}.svg" />
                  </div>
                  <div class="radio-options">
                    <p>{{a.title | titlecase}}</p>
                    <ul class="list-style-none" flex p-0 m-0>
                      <li *ngFor="let l of list">
                        <mat-radio-group [(ngModel)]="modalarray[i]">
                          <mat-radio-button (click)="explorerClick(l,a.name,i)" value="{{l}}">
                            <p>
                              {{l | buttonTextChange : keywords?.dictionary}}
                            </p>
                          </mat-radio-button>
                        </mat-radio-group>
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="module=='Pathfinder'||module=='All' ">
                <ng-template mat-tab-label>
                  <div class="tab-button pathfinder" #tooltip="matTooltip" flex v-center h-center
                    (click)="tabClick('hollandcode');changeTheme('pathfinder');tooltip.show()" (blur)="tooltip.hide()"
                    matTooltipClass="custom-tooltip"
                    matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.pathfinder?.tab | libraryPathFinderPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.personalityType}}">
                    <img src="./assets/Svg/compass.svg" alt="compass-image"
                      [ngClass]="(tabSelectedIndex == 3)?'rotate-animation':''">
                    <span class="tab-name" [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[1]}}</span>
                  </div>
                </ng-template>
                <div class="card circle-image-list-status custom-library-circle pathfinder-body" flex v-center
                  *ngFor="let a of pathfinder;index as i">
                  <div class="circle-img" flex v-center h-center *ngIf="pathfinder!=undefined">
                    <img *ngIf="a.icon" src="assets/pathfinder/holland/{{a.icon}}.svg" alt="{{a.name}}" />
                  </div>
                  <div
                    (click)="pathFinder(a.hollandCode,i);modalarray[i] == ''? modalarray[i] = 'All' :modalarray[i] = ''">
                    <mat-checkbox [checked]="true" *ngIf="modalarray[i] == 'All'">
                    </mat-checkbox>
                    <mat-checkbox *ngIf="modalarray[i] == ''">
                    </mat-checkbox>
                  </div>
                  <div class="radio-options">
                    <p m-0>{{a?.displayName | titlecase}}</p>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="module=='Careertrekker'||module=='All'">
                <ng-template mat-tab-label>
                  <div class="tab-button careertrekker" flex v-center h-center
                    (click)="tabClick('skill');changeTheme('careertrekker');tooltip.show()" (blur)="tooltip.hide()"
                    #tooltip="matTooltip" matTooltipClass="custom-tooltip"
                    matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.careertrekker?.tab | librarySkillPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.skill}}">
                    <img src="./assets/Svg/direction.svg" alt="direction-image"
                      [ngClass]="(tabSelectedIndex == 4)?'rotate-animation':''">
                    <span class="tab-name" [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[2]}}</span>
                  </div>
                </ng-template>
                <div class="card circle-image-list-status custom-library-circle trekker-body" flex v-center
                  *ngFor="let a of careertrekker;index as i ">
                  <div class="circle-img" flex v-center h-center *ngIf="careertrekker!=undefined">
                    <img src="{{assetsURL}}{{a?.icon}}.svg" alt="{{a?.title}}" />
                  </div>
                  <div class="radio-options">
                    <p>{{a?.title | titlecase}}</p>
                    <ul class="list-style-none" flex p-0 m-0>
                      <li *ngFor="let l of list ">
                        <mat-radio-group [(ngModel)]="modalarray2[i]">
                          <mat-radio-button (click)="careerTrekker(l,a.name,i)" value="{{l}}">
                            <p>
                              {{l | buttonTextChange : keywords?.dictionary}}
                            </p>
                          </mat-radio-button>
                        </mat-radio-group>
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <div pt-2 class="{{themeColor}}">
              <h2>{{keywords?.dictionary.thoughtBox}}</h2>
              <form>
                <textarea rows="10" class="thought-box-input" [ngModelOptions]="{standalone: true}" name="thoughtBox"
                  [(ngModel)]="valueInTheTextBox" (ngModelChange)="updateTheText($event )"></textarea>
              </form>
            </div>
          </div>
          <div class="tab-design-show">
              <div flex v-center>
                  <div class="filter-option" *ngIf="module=='All'" flex h-center>
                    <div flex v-center h-center>
                      <button matTooltip="{{keywords?.dictionary?.rateOcc}}" matTooltipClass="custom-tooltip" mat-mini-fab
                        [ngClass]="filterThumb!='thumb_up'?'blue-unfill':'blue-fill'" #tooltip1="matTooltip"
                        (click)="favouriteOccupationFilter('thumb_up');tooltip1.show()" (blur)="tooltip1.hide()">
                        <img *ngIf="filterThumb!=='thumb_up'" alt="thumb-up icon"
                          src="{{assetsURL}}thumbs-up-outline-grey.svg" />
                        <img *ngIf="filterThumb=='thumb_up'" alt="thumb-up-filled icon"
                          src="{{assetsURL}}thumbs-up-hover-gray.svg" />
                      </button>
                      <!-- <button matTooltip="{{keywords?.dictionary?.low}}" matTooltipClass="custom-tooltip" mat-mini-fab
                        [ngClass]="filterThumb!='thumb_down'?'red-unfill':'red-fill'" #tooltip2="matTooltip"
                        (click)="favouriteOccupationFilter('thumb_down');tooltip2.show()" (blur)="tooltip2.hide()">
                        <img *ngIf="filterThumb!=='thumb_down'" alt="thumb-down icon"
                          src="{{assetsURL}}thumbs-Down-outline-grey.svg" />
                        <img *ngIf="filterThumb=='thumb_down'" alt="thumb-down-filled icon"
                          src="{{assetsURL}}thumbs-down-hover-gray.svg" />
                      </button> -->
                      <button matTooltip="{{keywords?.dictionary?.notRated}}" matTooltipClass="custom-tooltip"
                        mat-mini-fab [ngClass]="filterThumb!='undesided'?'black-unfill':'black-fill'"
                        #tooltip3="matTooltip" (click)="favouriteOccupationFilter('undesided');tooltip3.show()"
                        (blur)="tooltip3.hide()">
                        <img *ngIf="filterThumb!=='undesided'" alt="hand icon"
                          src="{{assetsURL}}Hand-icon-outline.svg" />
                        <img *ngIf="filterThumb=='undesided'" alt="hand-filled icon"
                          src="{{assetsURL}}Hand-icon-filled.svg" />
                      </button>
                      <!-- <button matTooltip="{{keywords?.dictionary?.selectAll}}" matTooltipClass="custom-tooltip"
                        mat-mini-fab [ngClass]="filterThumb!='all'?'red-unfill':'red-fill'" #tooltip4="matTooltip"
                        (click)="favouriteOccupationFilter('all');tooltip4.show()" (blur)="tooltip4.hide()">
                        <img *ngIf="filterThumb!=='all'" alt="thumb-down icon" src="{{assetsURL}}plus-outline.svg" />
                        <img *ngIf="filterThumb=='all'" alt="thumb-down-filled icon"
                          src="{{assetsURL}}plus-outline-filled.svg" />
                      </button> -->
                    </div>
                  </div>
                  <div class="filter-option" pointer (click)="filterPopup()"
                    [ngStyle]="module!='All'?{'width':'100%'}:{}" flex v-center>
                    <p class="w-100" m-0 ngStyle.xs="font-size:0.75rem;margin: 0px ;width:100%;">
                      {{keywords?.dictionary?.filterSkills}}
                    </p>
                    <button mat-raised-button color="primary" class="audio-box filter-button" flex v-center>
                      <img src="assets/images/FILTER.png" alt="filter icon" />
                    </button>
                  </div>
                </div>

                <div class="filter-list-height-mobile">
                  <div infiniteScroll class="w-100" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
                     [fromRoot]="true" (scrolled)="onScroll()">
                     <!-- [infiniteScrollContainer]="selector2" -->
                    <div class="h-100" flex v-center h-center *ngIf="cards.length == 0" fxLayoutWrap="wrap">
                      <div text-center m-auto>
                        <p font-bold class="w-100 text-center" *ngIf="cards.length == 0">
                          {{keywords?.dictionary?.noResults}}
                          <br />{{keywords?.dictionary?.changeFilter}}
                        </p>
                      </div>
                    </div>
                    <mat-list role="list" *ngFor="let a of cards | orderBy : 'title';let i = index">
                      <mat-list-item role="listitem" (click)="modalOpen(a,i)" tabindex="0" (keypress)="modalOpen(a,i)">
                        <span class="{{themeColor}} w-100">{{a?.title}}</span>
                        <img class="thumb-icon" alt="thumb-up icon" *ngIf="thumbTrue.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-up-hover-mob.svg" />
                        <!-- <img class="thumb-icon" alt="thumb-down icon" *ngIf="thumbFalse.indexOf(a?.occId)>=0"
                          src="{{assetsURL}}thumbs-down-hover-mob.svg" /> -->
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
                <div class="custom-tabs mobile-tab-selected-block" >
                  <mat-tab-group [(selectedIndex)]="tabSelectedIndex" class="{{themeColor}}">
                    <mat-tab *ngIf="module=='Scout'||module=='All' ">
                      <ng-template mat-tab-label>
                        <div class="tab-button scout" #tooltip="matTooltip" flex v-center h-center
                          (click)="tabClick('group');changeTheme('scout');tooltip.show()" (blur)='tooltip.hide()'
                          matTooltipClass="custom-tooltip"
                          matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.scout?.tab | libraryScoutPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.group}}">
                          <img src="{{assetsURL}}Scout.svg" alt="compass-image"
                            [ngClass]="(tabSelectedIndex == 0)?'rotate-animation':''">
                          <span class="tab-name"
                            [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[8]}}</span>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="module === 'Navigator'|| module=='All' ">
                      <ng-template mat-tab-label>
                        <div class="tab-button navigator" #tooltip="matTooltip" flex v-center h-center
                          (click)="tabClick('value');changeTheme('navigator');tooltip.show()" (blur)='tooltip.hide()'
                          matTooltipClass="custom-tooltip"
                          matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.navigator?.tab | libraryNavigatorPipe : keywords?.dictionary?.filterBy : keywords?.dictionary?.workValue}}">
                          <img src="{{assetsURL}}navigator.svg" alt="navigator"
                            [ngClass]="(tabSelectedIndex == 1)?'rotate-animation':''">
                          <span class="tab-name"
                            [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[6]}}</span>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="module=='Explorer'||module=='All'">
                      <ng-template mat-tab-label>
                        <div class="tab-button explorer" #tooltip="matTooltip" flex v-center h-center
                          (click)="tabClick('characteristic');changeTheme('explorer');tooltip.show()"
                          (blur)="tooltip.hide()" matTooltipClass="custom-tooltip"
                          matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.explorer?.tab | libraryCharacteristicPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.characteristic}}">
                          <img src="assets/Svg/gloab.svg" alt="explorer icon"
                            [ngClass]="(tabSelectedIndex == 2)?'rotate-animation':''">
                          <span class="tab-name"
                            [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[0]}}</span>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="module=='Pathfinder'||module=='All' ">
                      <ng-template mat-tab-label>
                        <div class="tab-button pathfinder" #tooltip="matTooltip" flex v-center h-center
                          (click)="tabClick('hollandcode');changeTheme('pathfinder');tooltip.show()"
                          (blur)="tooltip.hide()" matTooltipClass="custom-tooltip"
                          matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.pathfinder?.tab | libraryPathFinderPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.personalityType}}">
                          <img src="./assets/Svg/compass.svg" alt="compass-image"
                            [ngClass]="(tabSelectedIndex == 3)?'rotate-animation':''">
                          <span class="tab-name"
                            [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[1]}}</span>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="module=='Careertrekker'||module=='All'">
                      <ng-template mat-tab-label>
                        <div class="tab-button careertrekker" flex v-center h-center
                          (click)="tabClick('skill');changeTheme('careertrekker');tooltip.show()"
                          (blur)="tooltip.hide()" #tooltip="matTooltip" matTooltipClass="custom-tooltip"
                          matTooltip="{{commonLibraryHeading?.dictionary?.submodule?.careertrekker?.tab | librarySkillPipe : keywords?.dictionary?.filterBy :keywords?.dictionary?.skill}}">
                          <img src="./assets/Svg/direction.svg" alt="direction-image"
                            [ngClass]="(tabSelectedIndex == 4)?'rotate-animation':''">
                          <span class="tab-name"
                            [ngClass]="(!(module=='All'))? 'tab-pane-title':''">{{tabnames[2]}}</span>
                        </div>
                      </ng-template>
                    </mat-tab>
                  </mat-tab-group>
                </div>
                <div class="career-challenge-block" *ngIf="module=='Careertrekker' && deviceRes">
                  <mat-card class="card w-100">
                    <mat-card-title class="card-header" background-color="primary">
                      {{keywords?.dictionary?.startChallenge}}</mat-card-title>
                    <mat-card-content class="card-body text-center" flex v-center>
                      <button mat-raised-button color="warn" class="challenge-button" (click)="navigateToChallenge()">
                        {{keywords?.dictionary?.challenge}}
                      </button>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            <!-- <mat-horizontal-stepper>
              <mat-step>
              </mat-step>
            </mat-horizontal-stepper> -->
          <div class="text-center career-creator-nav" *ngIf="!(module=='All') && (module == 'Explorer' && this.ideaScores == true && this.thingsScores == true && this.peopleScores == true) ||
          (module == 'Pathfinder' && this.pathfinder?.length > 0) ||
        (module == 'Careertrekker'  && this.careertrekker?.length > 0) && !deviceRes || (module == 'Navigator')">
            <div flex v-center h-center mb-10>
              <img src="assets/images/lock-icon.svg" alt="lock-icon" />
              <div class="creator-text" text-color="primary" font-bold>
                {{keywords?.dictionary?.unlockedCreator}}
              </div>
            </div>
            <div flex v-center h-center>
              <span *ngIf="(module == 'Explorer')"><img class="shake-animation"
                  src="{{assetsURL}}pencil-icon-Explorer.svg" alt="createOcc" /></span>
              <span *ngIf="(module == 'Pathfinder')"><img class="shake-animation"
                  src="{{assetsURL}}pencil-icon-Pathfinder.svg" alt="createOcc" /></span>
              <span *ngIf="(module == 'Careertrekker')"><img class="shake-animation"
                  src="{{assetsURL}}pencil-icon-Career-trek.svg" alt="createOcc" /></span>
              <span *ngIf="(module == 'Navigator')"><img class="shake-animation" src="./assets/Svg/pencil_navigator.svg"
                  alt="createOcc" /></span>
              <button mat-raised-button background-color="primary" class="creator-button"
                (click)="navigateToCreateOcc(module)">
                {{keywords?.dictionary?.createOcc}}
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
<app-static-footer></app-static-footer>