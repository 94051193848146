import { Component, Inject, OnInit } from '@angular/core';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Next } from '../../../shared/common-animations/CT_animations';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { Store } from '@ngrx/store';
import { AudioState, PageDataState, ScoutActivityOneState, ScoutActivityTwoState } from '../../../state-management/state/main-state';
import { take, takeWhile } from 'rxjs/operators';
import { Utilities } from "../../../shared/common-services/utilities.service";
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-scout-activity_one',
	templateUrl: './scout-activity_one.component.html',
	animations: [Next]
})
export class ScoutActivityOneComponent implements OnInit {
	noOfSelection = [{ index: 0, select: 0 }, { index: 0, select: 0 }];
	routeSelection = 1;
	pages: any;
	scoutSubHeader = [];
	scoutHeader: string;
	completed = false;
	assetsUrl: string;
	compActive = true;
	lang = "";
	cat=[];
	categories = [];
	images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
	keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
	theme_name: string;
	storedData = [];
	completedActivity = []
	curIndex = 0;
	headerAudioState = 'stoped';
	headerAudionotfound = false;
	subHeaderAudioState = 'stoped';
	subHeaderAudionotfound = false;
	curAudio: string;
	pageData: any;
	indexValue: string;
	isModalActive: boolean = false;
	constructor(public eventService: EventdispatchService,
		public router: Router,
		private apiModel: ApiCallClass,
		private apiCall: ApiCallService,
		private store: Store<PageDataState>,
		public activatedRoute: ActivatedRoute,
		private audioStore: Store<AudioState>,
		private audioService: AudioService,
		private oninitpopupservice: OnLoadPopUpService,
		private scoutActivityOneState: Store<ScoutActivityOneState>,
		private scoutActivityTwoState: Store<ScoutActivityTwoState>,
		private utils: Utilities,
		@Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		@Inject('OCC_CARD_URL') public occCardUrl: string,
		public dialogService: DialogopenService,
		private titleService: Title) {
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[7]);
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		this.lang = sessionStorage.getItem("session_token");
		this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe(response => {
			this.pages = response.pages;
		});
		this.keywords = JSON.parse(
			sessionStorage.getItem("GLOBAL_SETTINGS")
		);
		eventService
			.listen()
			.pipe(takeWhile(() => this.compActive))
			.subscribe((e) => {

				if (sessionStorage.getItem("pathurl") === "/app/scout/activity_one") {
					if (e.type === "languageChanged") {
						this.lang = sessionStorage.getItem("session_token");
						this.cat = [];
						this.categories.forEach(ele => {
							this.getoccdata(ele.occId);
						});
						this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
						this.pagesOnLoad();
						this.dialogService.hideLoading();

					}
					if (e.type === "themeChanged") {
						this.theme_name = sessionStorage.getItem("theme_color");
					}
					if (e.type === 'infoModal') {
						if (sessionStorage.getItem('infopopup') == 'true') {
							this.infopopup();
							sessionStorage.setItem('infopopup', 'false');
						}
					}
					if (e.type === 'backClicked') {
						this.stopAudio();
						if (this.curIndex <= 0) {
							this.unsavedChanges();
						} else {
							this.curIndex--;
							this.routeSelection = this.curIndex + 2;
							this.categories = this.storedData[this.curIndex];
							let index = this.getIndexOfCategory(this.curIndex + 1);
							this.pageData = this.pages[index];
							this.getIndexValue();
							if (this.pages) {
								this.categories.map(ele => {
									ele.job = this.pages[index].dictionary.goodJob;
									ele.text = this.pages[index].dictionary.workWith;
									ele.donttext = this.pages[index].dictionary.dontWork;
									ele.iconImage = this.images[this.curIndex];
								});
								this.scoutSubHeader = this.pages[index].subHeader.split('.');
								this.scoutHeader = this.pages[index].header;
							}
							this.noOfSelection = [{ index: 1, select: 1 }, { index: 1, select: 1 }];
							this.completed = true;
							this.infopopup()
						}
					}
				}
			});
		this.audioStore
			.select("audio")
			.pipe(takeWhile(() => this.compActive))
			.subscribe((result) => {
				if (result && sessionStorage.getItem('isModalActive') === 'false') {
					if (this.curAudio === "header") {
						this.headerAudioState = result.audioState;
						this.headerAudionotfound = result.audionotfound;
						this.subHeaderAudioState = "stoped";
						this.subHeaderAudionotfound = false;
					} else if (this.curAudio === "subheader") {
						this.subHeaderAudioState = result.audioState;
						this.subHeaderAudionotfound = result.audionotfound;
						this.headerAudioState = "stoped";
						this.headerAudionotfound = false;
					}
				}
			});

		this.assetsUrl = assetsMediaUrl;
	}

	pagesOnLoad() {
		this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe(response => {
			this.pages = response.pages;
			let inx = this.getIndexOfCategory(this.curIndex + 1);
			this.pageData = this.pages[inx];
			this.getIndexValue();
			if (this.pages) {
				this.categories.map(ele => {
					ele.job = this.pages[inx].dictionary.goodJob;
					ele.text = this.pages[inx].dictionary.workWith;
					ele.donttext = this.pages[inx].dictionary.dontWork;
					ele.iconImage = this.images[this.curIndex];
				});
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				this.scoutSubHeader = this.pages[inx].subHeader.split('.');
				this.scoutHeader = this.pages[inx].header;

			}
		});
	}

	ngOnInit() {

		this.scoutActivityOneState.select('scoutActivityOneData').pipe(take(1)).subscribe(storeresp => {
			this.storedData = storeresp['scoutActivityOneData'].storedData;
			if (this.storedData.length) {
				this.getCompletedActivity()
				this.curIndex = this.completedActivity.length - 1
				this.categories = this.storedData[this.curIndex];
				this.noOfSelection = [{ index: 1, select: 1 }, { index: 1, select: 1 }];
				this.completed = true;
				let index = this.getIndexOfCategory(this.curIndex + 1);
				this.pageData = this.pages[index];
				this.getIndexValue();
				if (this.pages) {
					this.categories.map(ele => {
						ele.job = this.pages[index].dictionary.goodJob;
						ele.text = this.pages[index].dictionary.workWith;
						ele.donttext = this.pages[index].dictionary.dontWork;
						ele.iconImage = this.images[this.curIndex];
					});
					this.scoutSubHeader = this.pages[index].subHeader.split('.');
					this.scoutHeader = this.pages[index].header;
				}
			} else {
				this.getCategoryDetails(1);
			}
		});

		this.setScoutActivityOneTitle();
	}
	getCompletedActivity() {
		for (let index in this.storedData) {
			let title = this.storedData[index][0].category.title
			if (!this.completedActivity.includes(title)) {
				this.completedActivity.push(title)
			}
		}
	}

	getoccdata(occID) {
		this.apiModel.moduleName = "explorer";
		this.apiModel.endUrl = "occ/" + occID;
		this.apiModel.sessionID = sessionStorage.getItem("session_token");
		this.apiModel.method = "GET";
		try {
			this.apiCall
				.getData([this.apiModel])
				.pipe(takeWhile(() => this.compActive))
				.subscribe((resp) => {
					if (resp !== undefined && resp !== null) {
						const data = JSON.parse(resp[0]);
						this.cat.push(data);
						if(this.cat.length==2){
						const newData=this.apiCall.getDataLanguageChangeForShuffle(this.categories,this.cat)
						this.categories=newData;	
						}
					}
				});
				} catch (e) {
					this.utils.handleError(`scout-activity_one.component.ts getoccdata: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	// id= People-1 ,Ideas-2, Things-3
	getCategoryDetails(card) {
		this.dialogService.showLoading();
		this.routeSelection++;
		this.noOfSelection = [{ index: 0, select: 0 }, { index: 0, select: 0 }];
		this.completed = false;
		this.getRandomData(card);
	}
	getRandomData(card) {
		this.categories = [];
		try {
			this.apiModel.moduleName = 'Favoccs';
			this.apiModel.endUrl = `occs/category/random/${card}/2`;
			this.apiModel.method = 'GET';

			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
				if (resp) {
					const response = JSON.parse(resp);

					this.categories = response;
					if (this.pages && this.categories) {
						let index = this.getIndexOfCategory(card);
						this.pageData = this.pages[index];
						this.getIndexValue();
						this.categories.map(ele => {
							ele.job = this.pages[index].dictionary.goodJob;
							ele.text = this.pages[index].dictionary.workWith;
							ele.donttext = this.pages[index].dictionary.dontWork;
							ele.iconImage = this.images[this.curIndex];
						});
						this.scoutSubHeader = this.pages[index].subHeader.split('.');
						this.scoutHeader = this.pages[index].header;
						this.dialogService.hideLoading();
						this.infopopup()
					}
				}
			});
		} catch (e) {
			this.utils.handleError(`scout-activity_one.component.ts getRandomData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}

	getIndexValue() {
		if (this.pageData.pageCode === 'SCOUT_PEOPLE') {
			this.indexValue = 'People'
		} else if (this.pageData.pageCode === 'SCOUT_IDEAS') {
			this.indexValue = 'Ideas'
		} else if (this.pageData.pageCode === 'SCOUT_THINGS') {
			this.indexValue = 'Things'
		}
	}

	getIndexOfCategory(id) {
		let index = -1;
		if (id === 1) {
			index = this.pages.findIndex(x => x.pageCode === 'SCOUT_PEOPLE');
		} else if (id === 2) {
			index = this.pages.findIndex(x => x.pageCode === 'SCOUT_IDEAS');
		} else if (id === 3) {
			index = this.pages.findIndex(x => x.pageCode === 'SCOUT_THINGS');
		}

		return index;
	}

	clickRoute(id) {
		this.stopAudio();
		if (this.storedData[this.curIndex + 1]) {
			this.curIndex++;
			this.routeSelection = this.curIndex + 2
			this.categories = this.storedData[this.curIndex];
			let index = this.getIndexOfCategory(this.curIndex + 1);
			this.pageData = this.pages[index];
			this.getIndexValue();
			if (this.pages) {
				this.categories.map(ele => {
					ele.job = this.pages[index].dictionary.goodJob;
					ele.text = this.pages[index].dictionary.workWith;
					ele.donttext = this.pages[index].dictionary.dontWork;
					ele.iconImage = this.images[this.curIndex];
				});
				this.scoutSubHeader = this.pages[index].subHeader.split('.');
				this.scoutHeader = this.pages[index].header;
			}
			this.noOfSelection = [{ index: 1, select: 1 }, { index: 1, select: 1 }];
			this.completed = true;

		} else {
			if (!this.completedActivity.includes(this.categories[0].category.title)) {
				this.storedData.push(this.categories);
				this.completedActivity.push(this.categories[0].category.title)
			}
			this.scoutActivityOneState.dispatch({
				type: 'SCOUT_ACTIVITY_ONE_VAL'
				, payload: {
					scoutActivityOneData: {
						storedData: this.storedData
					}
				}
			});
			if (this.curIndex < 2) {
				this.curIndex++;
				this.getCategoryDetails(id);

			} else {
				this.scoutActivityTwoState.dispatch({
					type: 'SCOUT_ACTIVITY_TWO_VAL'
					, payload: {
						scoutActivityTwoData: {
							storedData: []
						}
					}
				});
				this.router.navigate(['../activity_two'], { relativeTo: this.activatedRoute });
			}
		}
	}
	actionButton(type, inx, popupData) {
		this.stopAudio();
		if (type === 'yes') {
			this.dialogService.scoutActivityYesModal(popupData);
		} else {
			this.dialogService.scoutActivityNoModal(popupData);
		}
		this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
			if (inx === 0) {
				this.noOfSelection[0].index = 1;
				if (type === 'yes') {
					this.noOfSelection[0].select = 1;
				} else {
					this.noOfSelection[0].select = 2;
				}
			}
			if (inx === 1) {
				this.noOfSelection[1].index = 1;
				if (type === 'yes') {
					this.noOfSelection[1].select = 1;
				} else {
					this.noOfSelection[1].select = 2;
				}
			}
			if (this.noOfSelection[0].select === 1 && this.noOfSelection[1].select === 1) {
				this.completed = true;
			} else {
				this.completed = false;
			}
		});
	}

	scoutCareerDetailView(occId) {
		this.stopAudio();
		// This condition is to restrict mutliple popups opening when click on career rapidly
		if (sessionStorage.getItem('isModalActive') === 'false' && !this.isModalActive) {
			sessionStorage.setItem('isModalActive', 'true');
			this.isModalActive = true;
			this.apiModel.moduleName = "scout";
			this.apiModel.endUrl = "occ/" + occId;
			this.apiModel.sessionID = sessionStorage.getItem("session_token");
			this.apiModel.method = "GET";
			try {
				this.apiCall
					.getData([this.apiModel])
					.pipe(takeWhile(() => this.compActive))
					.subscribe((resp) => {
						if (resp) {
							this.dialogService.quickpicResultPopup(JSON.parse(resp));
							this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
								sessionStorage.setItem('isModalActive', 'false');
								this.isModalActive = false;
							});
						}

					});
			}
			catch (e) {
				this.utils.handleError(`scout-activity_one.component.ts scoutCareerDetailView: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
			}
		}
	}

	infopopup() {
		this.stopAudio();
		let infoCategory: string;
		switch (this.curIndex) {
			case 0:
				infoCategory = 'SCOUT_PEOPLE'
				break;
			case 1:
				infoCategory = 'SCOUT_IDEAS'
				break;
			case 2:
				infoCategory = 'SCOUT_THINGS'
				break;
		}
		Promise.resolve().then(function () {
			this.oninitpopupservice.getOnLoadPopUp(infoCategory);
		}.bind(this));
	}

	unsavedChanges() {
		const evnt = document.createEvent('CustomEvent');
		this.eventService.dispatchAudioStop(evnt);
		this.utils.backRoute();
	}

	headeraudioClick(text, data) {
		this.curAudio = "header";
		this.audioService.audioFunction(text, data);
	}

	subheaderAudioClick(text, data) {
		this.curAudio = "subheader";
		this.audioService.audioFunction(text, data, true);
	}

	// Stop audio event method
	stopAudio() {
		this.headerAudioState = "stoped";
		this.headerAudionotfound = false;
		this.subHeaderAudioState = "stoped";
		this.subHeaderAudionotfound = false;
		this.audioStore.dispatch({
			type: "SET_AUDIO_STOPED",
		});
		const evnt = document.createEvent("CustomEvent");
		this.eventService.dispatchAudioStop(evnt);
	}

	ngOnDestroy() {
		this.stopAudio();
		this.compActive = false;
	}

	private setScoutActivityOneTitle() {
		this.titleService.setTitle("Scout Activity One - CareerTrek");
	}

}