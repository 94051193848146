<app-static-header> </app-static-header>
<div class="desktop-design-show">
  <div class="Career-trek-content landing-img scroll-profile" *ngIf="UserDetails != undefined">
    <div class="container profile-page-header">
      <div class="download-section" flex>
        <button mat-button class="download-button" *ngIf="CreateOwnOccs.length>=0" (click)="DownloadPDF()">
          <span class="w-100">{{globalText.dictionary.download}}</span>
          <i class="material-icons">picture_as_pdf</i>
        </button>
      </div>
      <div>
        <h4 class="landing-page-heading">
          {{keyWords.header}}
          <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" [styleClass]="'custom-occ-audio'"
            (click)="audioClick('inside',keyWords)"></app-audio-button>
          <!-- <button mat-raised-button class="audio-box volume-up custom-occ-audio"
            [disabled]="audionotfound==true && audioState=='warning'" 
            (click)="audioClick('inside',keyWords)">
            <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
            <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
            <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
            <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>  
          </button> -->
        </h4>
      </div>
    </div>
    <div class="container content-box">
      <!-- Profile print start -->
      <div [hidden]="true">
        <div class="profile-page profile-pdf" id="contentToConvert">
          <div class="profile-page-content">
            <mat-card class="person-details card m-auto" flex v-center h-center>
              <div class="about-person w-100" fxLayout="row" flex v-center h-center>
                <div fxFlex="25%" flex v-center h-center>
                  <div class="person-image" flex v-center h-center>
                    <div *ngIf="profileImage!=''" class="icon-{{profileImage}}"></div>
                    <i *ngIf="myPic==undefined" class="material-icons">person</i>
                    <img *ngIf="myPic!=undefined" src="{{myPic}}" />
                  </div>
                  <div data-html2canvas-ignore="true" class="person-image-edit" flex v-center h-center tabindex="0"
                    (click)="avtarpopup()" (keypress)="avtarpopup()">
                    <i class="material-icons">mode_edit</i>
                  </div>
                </div>
                <div fxFlex="55%">
                  <ul class="m-auto list-style-none" p-0>
                    <li flex v-center h-center>
                      <div class="w-100" flex v-center font-bold m-0>
                        <!-- <span class="pf-details">Name</span>:&nbsp; -->
                        <span>{{UserDetails.user?.givenName}}
                          {{UserDetails.user?.middleName}}
                          {{UserDetails.user?.familyName}}</span>
                      </div>
                    </li>
                    <li flex v-center h-center>
                      <p class="w-100" flex v-center font-bold m-0>
                        <span class="pf-details">{{keyWords.dictionary.gradeLevel}}:
                          {{UserDetails.user?.grade}}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </mat-card>
            <!-- module Cards -->
            <div fxLayout="row" fxFlexFill class="common-row-sub score-cards" fxLayoutWrap="wrap">
              <div class="score-active-cards" fxFlex="" *ngFor="let moduleTitle of activateModuleList">
                <!-- Discoverer card -->
                <mat-card class="card card-discoverer" *ngIf="moduleTitle.moduleName === 'Discoverer'">
                  <div pointer matTooltip="{{keyWords.dictionary.discovererHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('discoverer')">
                    <div class="circle-image m-auto discoverer-pink" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/discoverer.png" alt="discoverer-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>{{tabnames[0]}}</p>
                    <div class="badge">
                      <img *ngIf="(discovererCompletionSet.length>0)" src="./assets/images/completed.png"
                        alt="badge-image" />
                      <!-- <img *ngIf="(ideaScores.length==0 || peopleScores.length==0 || thingsScores.length==0)" src="./assets/images/completed.png"
                      alt="badge-image" /> -->
                    </div>
                  </div>

                  <div flex v-center class="personality-types" *ngIf="(discovererCompletionSet.length>0 )">
                    <p m-0 class="w-100 text-center" *ngIf="discovererCompletionSet.length > 0">
                      {{
                      UserDetails.modules.assessments.DISCOVERER.secondary.data[0].display}}:
                      {{discovererCompletionSet[discovererCompletionSet.length-1] | customDate}}
                    </p>
                    <!-- <p m-0 class="w-100 text-center">  {{
                      UserDetails.modules.assessments.DISCOVERER.secondary.data[0].display}}:
                      {{DISCOVERER[DISCOVERER.length-1] | customDate}}</p> -->
                  </div>
                  <div class="badge-section text-center personality-types" flex v-center h-center
                    *ngIf="(discovererCompletionSet.length == 0 )">
                    <p m-0 class="text-center not-saved-text">Not Completed
                    </p>
                  </div>
                </mat-card>
                <!-- Scout card -->
                <mat-card class="card card-scout" *ngIf="moduleTitle.moduleName === 'Scout'">
                  <div pointer matTooltip="{{keyWords.dictionary.scoutGroupHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('scout')">
                    <div class="circle-image m-auto scout-green" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/scout.png" alt="scout-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>{{tabnames[1]}}</p>
                    <div class="top-score text-center" *ngIf="scoutCompletionSet.length<=0">
                    </div>
                    <div class="badge">
                      <img *ngIf="scoutCompletionSet.length>0" src="./assets/images/completed.png" alt="badge-image" />
                    </div>
                  </div>
                  <div *ngIf="(scoutCompletionSet.length>0)" class="text-center personality-types scout-career">
                    <p font-bold class="career-header">{{globalText.dictionary.group}}:</p>
                    <div class="career-group-card">
                      <div class="card-body text-center" flex v-center h-center>
                        <div class="career-icon">
                          <img src="{{assetsPNGUrl}}/{{scoutGroupName}}.png" alt="{{scoutGroupName}}" *ngIf="scoutGroupName && scoutGroupName.length>0"/>
                        </div>
                        <p m-0 font-bold class="career-icon_text">{{scoutGroupValue}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="badge-section text-center personality-types" flex v-center h-center
                    *ngIf="scoutCompletionSet.length<= 0">
                    <p m-0 class="text-center not-saved-text">Not Completed
                    </p>
                  </div>

                </mat-card>

                <!--Navigator card -->

                <mat-card class="card card-navigator" *ngIf="moduleTitle.moduleName === 'Navigator'">
                  <div pointer matTooltip="{{keyWords.dictionary.workValuesHover}}" matTooltipClass="custom-tooltip">
                    <div class="circle-image m-auto navigator-pink" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/navigator.png" alt="navigator-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>{{tabnames[2]}}</p>
                    <div class="top-score text-center" *ngIf="careertrekker.length<=0">
                    </div>
                    <div class="badge">
                      <img src="./assets/images/completed.png" alt="badge-image"
                        *ngIf="navigatorCompletionSet!=undefined && navigatorCompletionSet.length>0" />
                    </div>
                    <div class="personality-types">
                      <p class="text-center" m-0 font-bold>
                        {{navigatorCompletionText}}:
                      </p>
                      <div flex v-center h-center>
                        <p m-0 font-bold class="text-center types" *ngFor="let item of navigatorCompletionSet">
                          <img *ngIf="item.icon && item.icon.length>0" flex v-center h-center src="{{assetsPNGUrl}}/{{item.icon}}.png" alt="navigator-image" />
                          <span>{{item.displayName}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="personality-types" flex v-center *ngIf="navigatorCompletionSet.length == 0">
                    <p m-0 class="w-100 text-center">Coming Soon</p>
                    <div class="m-auto" flex v-center h-center>
                      <p flex v-center class="w-100 not-saved-text text-center">Not Completed
                      </p>
                    </div>
                  </div>
                </mat-card>

                <!-- Explorer Card -->

                <mat-card class="card card-explorer" *ngIf="moduleTitle.moduleName === 'Explorer'">
                  <div pointer matTooltip="{{keyWords.dictionary.careerGroupHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('explorer')">
                    <div class="circle-image m-auto explorer" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/explorer.png" alt="explorer-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>{{tabnames[3]}}</p>
                    <div class="badge">
                      <img *ngIf="(ideaScores.length>0 && peopleScores.length>0 && thingsScores.length>0)"
                        src="./assets/images/completed.png" alt="badge-image" />
                      <!-- <img *ngIf="(ideaScores.length==0 || peopleScores.length==0 || thingsScores.length==0)" src="./assets/images/completed.png"
                      alt="badge-image" /> -->
                    </div>
                  </div>

                  <div class="text-center personality-types"
                    *ngIf="(ideaScores.length>0 || peopleScores.length>0 || thingsScores.length>0)">
                    <p class="text-center" m-0 font-bold>
                      {{keyWords.dictionary.careerGroup}}:
                    </p>
                    <div flex v-center>
                      <div class="w-100 career-group-content">
                        <p m-0 class="w-100" font-bold>
                          {{keyWords.dictionary.peopleTrack}}
                        </p>
                        <i *ngIf="ideaScores!=undefined && peopleScores.length>0 "
                          class="material-icons success-tick">done</i>
                      </div>
                      <div class="w-100 career-group-content">
                        <p m-0 class="w-100" font-bold>
                          {{keyWords.dictionary.ideasTrack}}
                        </p>
                        <i *ngIf="ideaScores!=undefined && ideaScores.length>0 "
                          class="material-icons success-tick">done</i>
                      </div>

                      <div class="w-100 career-group-content">
                        <p m-0 class="w-100" font-bold>
                          {{keyWords.dictionary.thingsTrack}}
                        </p>
                        <i *ngIf="ideaScores!=undefined && thingsScores.length>0"
                          class="material-icons success-tick">done</i>
                      </div>
                    </div>
                  </div>
                  <div class="badge-section text-center personality-types" flex v-center h-center
                    *ngIf="(ideaScores.length == 0 && peopleScores.length == 0 && thingsScores.length == 0)">
                    <p m-0 class="text-center not-saved-text">
                      Not Completed
                    </p>
                  </div>
                </mat-card>

                <!-- path-finder card -->

                <mat-card class="card card-pathfinder" *ngIf="moduleTitle.moduleName === 'Pathfinder'">
                  <div pointer matTooltip="{{keyWords.dictionary.personalityTypesHover}}"
                    matTooltipClass="custom-tooltip" (click)="navigateToModule('pathfinder')">
                    <div class="circle-image m-auto pathfinder" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/pathfinder.png" alt="pathfinder-image" />
                    </div>

                    <p m-0 class="w-100 text-center tabname" p-5>
                      {{tabnames[4]}}
                    </p>
                    <div class="top-score text-center" *ngIf="pathfinder.length<=0"></div>
                    <div class="badge">
                      <img *ngIf="pathfinder!=undefined && pathfinder.length>0" src="./assets/images/completed.png"
                        alt="badge-image" />
                    </div>
                  </div>
                  <div class="badge-section text-center personality-types" d-flex v-center h-center
                    [ngClass]='(pathfinder.length===0)? "badgeFlex":""'>
                    <p class="text-center" m-0 font-bold *ngIf="pathfinder.length>0">
                      {{keyWords.dictionary.personalityTypes}}:
                    </p>
                    <div flex v-center h-center *ngIf="pathfinder.length>0">
                      <p m-0 font-bold class="text-center types" *ngFor="let holland of likedholland">
                        <img flex v-center h-center src="{{assetsPNGUrl}}/{{holland.icon}}.png"
                          alt="personality-image" *ngIf="holland.icon && holland.icon.length>0" />
                        <span>{{holland.displayName}}</span>
                      </p>
                    </div>
                    <div class="badge-section text-center personality-types" flex v-center
                      *ngIf="pathfinder.length == 0">
                      <p m-0 class="text-center not-saved-text">
                        Not Completed
                      </p>
                    </div>
                  </div>
                </mat-card>

                <!--carrer-trekker card -->

                <mat-card class="card card-trekk" *ngIf="moduleTitle.moduleName === 'Trekker'">
                  <div pointer matTooltip="{{keyWords.dictionary.topTwoSkillsHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('careertrekker')">
                    <div class="circle-image m-auto trekker" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/Trekker.png" alt="trekker-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>
                      {{tabnames[5]}}
                    </p>
                    <div class="top-score text-center" *ngIf="careertrekker.length<=0"></div>
                    <div class="badge">
                      <img src="./assets/images/completed.png" alt="badge-image"
                        *ngIf="careertrekker!=undefined && careertrekker.length>0" />
                      <!-- <img src="./assets/images/not-completed.png" alt="badge-image" *ngIf="careertrekker?.length==0" /> -->
                    </div>
                  </div>
                  <div class="skills" flex h-center>
                    <div class="good-skills" *ngIf="careertrekker.length>0">
                      <p font-bold class="skill-header text-center">
                        {{keyWords.dictionary.goodAt}}:
                      </p>
                      <p m-0 flex v-center h-center *ngFor="let skill of goodSkill">
                        <span flex v-center class="w-100">{{skill.title}}</span>
                        <img *ngIf="skill.icon && skill.icon.length>0 " src="{{assetsPNGUrl}}/{{skill.icon}}.png" alt="skills-image" />
                      </p>
                    </div>
                    <div class="improve-skills" *ngIf="careertrekker.length>0">
                      <p font-bold class="skill-header text-center">
                        {{keyWords.dictionary.needsImprovement}}:
                      </p>
                      <p m-0 flex v-center h-center *ngFor="let skill of ImproveSkill">
                        <span flex v-center class="w-100">{{skill.title}}</span>
                        <img *ngIf="skill.icon && skill.icon.length>0" src="{{assetsPNGUrl}}/{{skill.icon}}.png" alt="skills-image" />
                      </p>
                    </div>
                    <div class="m-auto" flex v-center h-center *ngIf="careertrekker.length == 0">
                      <p flex v-center class="w-100 not-saved-text text-center">
                        Not Completed
                      </p>
                    </div>
                  </div>
                </mat-card>

                <!-- cci card -->

                <mat-card class="card card-quickpic" *ngIf="moduleTitle.moduleName === 'QuickPic'">
                  <div pointer matTooltip="{{keyWords.dictionary.quickPicHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('quickpic')">
                    <div class="circle-image m-auto quickPic" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/quickpic.png" alt="quick-pic-image">
                    </div>

                    <p m-0 class="w-100 text-center tabname" p-5>
                      {{tabnames[6]}}
                    </p>
                    <div class="badge">
                      <img src="./assets/images/completed.png" alt="badge-image"
                        *ngIf="quickpic!=undefined && quickpic.length>0" />
                      <!-- <img src="./assets/images/not-completed.png" alt="badge-image" *ngIf="quickpic==undefined" /> -->
                    </div>
                  </div>
                  <div class="top-score personality-types" flex v-center h-center>
                    <!-- <p font-bold *ngIf="quickpic==undefined">{{globalText.dictionary.noResults}}</p> -->
                    <p m-0 *ngIf="quickpic.length > 0">
                      {{
                      UserDetails.modules.assessments.QUICKPIC.secondary.data[0].display}}:
                      {{quickpic[quickpic.length-1] | customDate}}
                    </p>
                    <p class="not-saved-text" m-0 *ngIf="quickpic.length == 0">
                      Not Completed
                    </p>
                  </div>
                </mat-card>

                <!-- Career corner Card -->

                <mat-card class="card card-career-corner" *ngIf="moduleTitle.moduleName === 'CareerCorner'">
                  <div pointer matTooltip="{{keyWords.dictionary.careerCornerHover}}" matTooltipClass="custom-tooltip"
                    (click)="navigateToModule('careercorner')">
                    <div class="circle-image m-auto career-corner" flex v-center h-center>
                      <img src="{{assetsPNGUrl}}/career-corner.png" alt="career-corner-image">
                    </div>
                    <p m-0 class="w-100 text-center tabname" p-5>{{moduleTitle.displayName}}</p>
                    <div class="badge">
                      <img *ngIf="careerCornerCount.length>0" src="./assets/images/completed.png" alt="badge-image" />
                      <!-- <img *ngIf="(ideaScores.length==0 || peopleScores.length==0 || thingsScores.length==0)" src="./assets/images/completed.png"
                      alt="badge-image" /> -->
                    </div>
                  </div>

                  <div flex v-center class="top-score personality-types" *ngIf="careerCornerCount.length>0">
                    <p m-0 class="w-100 text-center" *ngIf="careerCornerNothingShared">{{careerCornerNothingShared}}</p>
                    <p m-0 class="w-100 text-center" *ngIf="careerCornerShared">
                      {{globalText.dictionary.shared}}:
                      {{careerCornerShared | customDate}}
                    </p>
                  </div>
                  <div class="badge-section text-center personality-types" flex v-center h-center
                    *ngIf="careerCornerCount.length == 0">
                    <p m-0 class="text-center not-saved-text">Not Completed
                    </p>
                  </div>
                </mat-card>
              </div>
            </div>
            <!-- Occupations Cards -->
            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutWrap="wrap" fxLayoutAlign="center"
              fxLayoutGap="5px" [ngClass]='((Likeoccs==6)||(CreateOwnOccs.length==6))? "after-break":""'>
              <div fxFlex="98%">
                <mat-card class="card Occupations-cards">
                  <div class="common-occupation-head m-auto" flex v-center h-center>
                    <div flex v-center h-center m-0 class="w-100">
                      <div class="circle-image thumb-icon" flex v-center h-center>
                        <img src="{{assetsPNGUrl}}/thumbs-up-hover.png" flex v-center h-center
                          alt="Occupations-image" />
                      </div>
                      <span class="w-100" flex v-center h-center
                        font-bold>{{keyWords.dictionary.occupationLibrary}}</span>
                      <span class="count" flex v-center h-center *ngIf="Likeoccs!=undefined">{{Likeoccs.length}}</span>
                    </div>
                  </div>
                  <div class="mat-card-section pdf-design">
                    <div class="h-100" flex v-center h-center pointer *ngIf="Likeoccs==undefined || Likeoccs.length==0">
                      <div>
                        <div class="w-100 text-center empty-block">
                          <p class="white-space">
                            {{keyWords.dictionary.noFavoriteOccs[0]}}
                          </p>
                          <p class="white-space">
                            {{keyWords.dictionary.noFavoriteOccs[1]}}
                          </p>
                        </div>
                        <img src="{{assetsPNGUrl}}/binoculars.png" alt="noOccs" class="w-100 no-data-found m-auto"
                          (click)="exploreOccLibrary()" flex />
                      </div>
                    </div>
                    <mat-card class="
                        card
                        own-occ-card
                        fav-occ-cards
                        custom-career-cards
                        avoid-break
                      " pointer p-0 flex *ngFor="let item of Likeoccs | orderBy : 'title' : false : true" tabindex="1"
                      (keyup.enter)="modalOpen(item,'like')" (click)="modalOpen(item,'like')">
                      <div class="own-occ-image">
                        <img src="{{occMediaUrlForSmallRes}}{{item.media?.images.card}}" alt="image-missing" />
                      </div>
                      <div class="w-100 career-cards-text">
                        <p m-0 class="w-100" font-bold>{{item.title}}</p>
                        <p m-0 class="w-100" font-normal>
                          {{item.description}}
                        </p>
                      </div>
                    </mat-card>
                  </div>
                  <!-- cci card -->
                </mat-card>
              <div class="html2pdf__page-break"></div>
              </div>
              <div fxFlex="98%">
                <mat-card class="card Occupations-cards">
                  <div class="common-occupation-head m-auto" flex v-center h-center>
                    <div flex v-center h-center m-0 class="w-100">
                      <div class="circle-image" flex v-center h-center>
                        <img src="{{assetsPNGUrl}}/pencil-icon-green.png" flex v-center h-center
                          alt="Occupations-image" />
                      </div>
                      <span flex v-center h-center font-bold
                        class="w-100">{{keyWords.dictionary.customOccupations}}</span>
                      <!-- <img flex v-center h-center src="./assets/images/create.png" flex v-center h-center alt="Occupations-image"> -->
                      <span class="count" flex v-center h-center
                        *ngIf="CreateOwnOccs!=undefined">{{CreateOwnOccs.length}}</span>
                    </div>
                  </div>
                  <div class="mat-card-section pdf-design">
                    <div class="h-100" flex v-center h-center
                      *ngIf="CreateOwnOccs==undefined || CreateOwnOccs.length==0">
                      <div>
                        <div class="w-100 text-center empty-block">
                          <p class="white-space">
                            {{keyWords.dictionary.noOwnOccupations[0]}}
                          </p>
                          <p class="white-space">
                            {{keyWords.dictionary.noOwnOccupations[1]}}
                          </p>
                        </div>
                        <img src="{{assetsPNGUrl}}/pencil-icon-green.png" alt="noOccs"
                          class="w-100 no-data-found m-auto" (click)="exploreOccLibrary()" flex />
                      </div>
                    </div>
                    <mat-card class="own-occ-card m-auto avoid-break" pointer p-0 flex
                      *ngFor="let createOcc of CreateOwnOccs | orderBy : 'title' : false : true"
                      (keyup.enter)="modalOpen(createOcc,'custom')" (click)="modalOpen(createOcc,'custom')"
                      tabindex="1">
                      <div class="own-occ-image">
                        <img src="{{createOcc.media?.images.sprite}}" alt="{{createOcc.title}}" alt="smile-image" />
                      </div>
                      <div class="own-occ-text w-100">
                        <p font-bold>{{createOcc.title}}</p>
                        <!-- progress-bar -->
                        <div class="progress">
                          <div class="circle">
                            <span class="label" *ngIf="createOcc.modules.scout.completed==1"
                              [class.scout-active]="createOcc.modules.scout.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(createOcc.modules.scout.completed==1)"></span>
                            <span class="title">{{tabnames[8]}}</span>
                          </div>
                          <span class="bar"
                            [class.active]="createOcc.modules.scout.completed==1 && createOcc.modules.scout.completed==1"></span>
                          <div class="circle">
                            <span class="label" *ngIf="createOcc.modules.navigator.completed==1"
                              [class.navigator-active]="createOcc.modules.navigator.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(createOcc.modules.navigator.completed==1)"></span>
                            <span class="title">{{tabnames[6]}}</span>
                          </div>
                          <span class="bar"
                            [class.active]="createOcc.modules.navigator.completed==1 && createOcc.modules.navigator.completed==1"></span>
                          <div class="circle">
                            <span class="label" *ngIf="createOcc.modules.explorer.completed==1"
                              [class.explorer-active]="createOcc.modules.explorer.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(createOcc.modules.explorer.completed==1)"></span>
                            <span class="title">{{tabnames[0]}}</span>
                          </div>
                          <span class="bar"
                            [class.active]="createOcc.modules.explorer.completed==1 && createOcc.modules.pathfinder.completed==1"></span>
                          <div class="circle">
                            <span class="label" *ngIf="createOcc.modules.pathfinder.completed==1"
                              [class.pathfinder-active]="createOcc.modules.pathfinder.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(createOcc.modules.pathfinder.completed==1)"></span>
                            <span class="title">{{tabnames[1]}}</span>
                          </div>
                          <span class="bar"
                            [class.active]="createOcc.modules.pathfinder.completed==1 && createOcc.modules.pathfinder.completed==1"></span>
                          <div class="circle">
                            <span class="label" *ngIf="createOcc.modules.trekker.completed==1"
                              [class.trekker-active]="createOcc.modules.trekker.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(createOcc.modules.trekker.completed==1)"></span>
                            <span class="title">{{tabnames[2]}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </mat-card>
              <div class="html2pdf__page-break"></div>
              </div>
              <div fxFlex="98%">
                <mat-card class="card Occupations-cards">
                  <div class="common-occupation-head m-auto" flex v-center h-center>
                    <div flex v-center h-center m-0 class="w-100">
                      <div class="circle-image" flex v-center h-center>
                        <img src="{{assetsPNGUrl}}/pencil-icon-green.png" flex v-center h-center
                          alt="Occupations-image" />
                      </div>
                      <span flex v-center h-center font-bold
                        class="w-100">{{keyWords.dictionary.dreamOccupations}}</span>
                      <span class="count" flex v-center h-center
                        *ngIf="dreamCareersList!=undefined">{{dreamCareersList.length}}</span>
                    </div>
                  </div>
                  <div class="mat-card-section pdf-design">
                    <div class="h-100" flex v-center h-center pointer
                      *ngIf="dreamCareersList==undefined || dreamCareersList.length==0">
                      <div>
                        <p class="w-100 text-center empty-block">
                          {{keyWords.dictionary.noOwnOccupations[0]}}<br />{{keyWords.dictionary.noOwnOccupations[1]}}
                        </p>
                        <img src="{{keyWords.media.images.customOccImage[1].src}}" alt="ownOccs"
                          class="w-100 no-data-found m-auto" flex />
                      </div>
                    </div>
                    <mat-card class="own-occ-card m-auto avoid-break" pointer p-0 flex
                      *ngFor="let dreamOcc of dreamCareersList | orderBy : 'title' : false : true">
                      <div class="own-occ-image">
                        <img src="{{dreamOcc.media?.images.sprite}}" alt="{{dreamOcc.title}}" />
                      </div>
                      <div class="own-occ-text w-100 d-flex align-center">
                        <p font-bold>{{dreamOcc.title}}</p>
                      </div>
                    </mat-card>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Profile print end -->
      <div class="profile-page">
        <div class="profile-page-content">
          <mat-card class="person-details card m-auto" flex v-center h-center>
            <div class="about-person w-100" fxLayout="row" flex v-center h-center>
              <div fxFlex="25%" flex v-center h-center>
                <div class="person-image" flex v-center h-center>
                  <div *ngIf="profileImage!=''" class="icon-{{profileImage}}"></div>
                  <i *ngIf="!myPic" class="material-icons">person</i>
                  <img *ngIf="myPic" src="{{myPic}}" />
                </div>
                <div class="person-image-edit" flex v-center h-center tabindex="0" (click)="avtarpopup()"
                  (keypress)="avtarpopup()">
                  <i class="material-icons">mode_edit</i>
                </div>
              </div>
              <div fxFlex="55%" fxFlex.sm="">
                <ul class="m-auto list-style-none" p-0>
                  <li flex v-center h-center>
                    <div class="w-100" flex v-center font-bold m-0>
                      <!-- <span class="pf-details">Name</span>:&nbsp; -->
                      <span>{{UserDetails.user?.givenName}}
                        {{UserDetails.user?.middleName}}
                        {{UserDetails.user?.familyName}}</span>
                    </div>
                  </li>
                  <li flex v-center h-center>
                    <p class="w-100" flex v-center font-bold m-0>
                      <span class="pf-details">{{keyWords.dictionary.gradeLevel}}:
                        {{UserDetails.user?.grade}}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </mat-card>
          <!-- module Cards -->
          <div flex class="common-row-sub score-cards" fxLayoutWrap="wrap">
            <div class="score-active-cards" fxFlex="" *ngFor="let moduleTitle of activateModuleList">
              <!-- Discoverer card -->
              <mat-card class="card card-discoverer" *ngIf="moduleTitle.moduleName === 'Discoverer'">
                <div pointer matTooltip="{{keyWords.dictionary.discovererHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('discoverer')">
                  <div class="circle-image m-auto discoverer-pink" flex v-center h-center>
                    <img src="{{assetsURL}}Discoverer.svg" alt="globe-image">
                  </div>
                  <p m-0 class="w-100 text-center tabname" p-5>{{moduleTitle.displayName}}</p>
                  <div *ngIf="(discovererCompletionSet.length>0)" class="badge">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>

                <div flex v-center class="personality-types" *ngIf="(discovererCompletionSet.length>0 )">
                  <p m-0 class="w-100 text-center" *ngIf="discovererCompletionSet.length > 0">
                    {{
                    UserDetails.modules.assessments.DISCOVERER.secondary.data[0].display}}:
                    {{discovererCompletionSet[discovererCompletionSet.length-1] | customDate}}
                  </p>
                </div>
                <div class="badge-section text-center personality-types" flex v-center h-center
                  *ngIf="(discovererCompletionSet.length == 0 )">
                  <p m-0 class="text-center not-saved-text">Not Completed
                  </p>
                </div>
              </mat-card>
              <!-- Scout card -->
              <mat-card class="card card-scout" *ngIf="moduleTitle.moduleName === 'Scout'">
                <div pointer matTooltip="{{keyWords.dictionary.scoutGroupHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('scout')">
                  <div class="circle-image m-auto scout-green" flex v-center h-center>
                    <img src="{{assetsURL}}Scout.svg" alt="compass-image">
                  </div>
                  <p m-0 class="w-100 text-center tabname" p-5>{{moduleTitle.displayName}}</p>
                  <div class="top-score text-center" *ngIf="scoutCompletionSet.length<=0">
                  </div>
                  <div *ngIf="scoutCompletionSet.length>0" class="badge">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div *ngIf="(scoutCompletionSet.length>0)" class="text-center personality-types scout-career">
                  <p font-bold mb-1>{{globalText.dictionary.group}}:</p>
                  <div class="career-group-card" mb-1 p-0>
                    <div class="card-body text-center" flex v-center h-center>
                      <div class="career-icon">
                        <img src="{{assetsURL}}{{scoutGroupName}}.svg" alt="{{scoutGroupName}}" *ngIf="scoutGroupName && scoutGroupName.length>0 "/>
                      </div>
                      <p m-0 font-bold class="career-icon_text">{{scoutGroupValue}}</p>
                    </div>
                  </div>
                </div>
                <div class="badge-section text-center personality-types" flex v-center h-center
                  *ngIf="scoutCompletionSet.length<= 0">
                  <p m-0 class="text-center not-saved-text">Not Completed
                  </p>
                </div>
              </mat-card>

              <!--Navigator card -->

              <mat-card class="card card-navigator" *ngIf="moduleTitle.moduleName === 'Navigator'">
                <div pointer matTooltip="{{keyWords.dictionary.workValuesHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('navigator')">
                  <div class="circle-image m-auto navigator-pink" flex v-center h-center>
                    <img src="{{assetsURL}}Navigator.svg" alt="direction-image">
                  </div>
                  <p m-0 class="w-100 text-center tabname" p-5>{{moduleTitle.displayName}}</p>
                  <div class="top-score text-center" *ngIf="navigatorCompletionSet.length<=0">
                  </div>
                  <div *ngIf="navigatorCompletionSet.length>0" class="badge">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div class="personality-types" *ngIf="navigatorCompletionSet.length>0">
                  <p class="text-center" m-0 font-bold>
                    {{navigatorCompletionText}}:
                  </p>
                  <div flex v-center h-center>
                    <p m-0 font-bold class="text-center types" *ngFor="let item of navigatorCompletionSet">
                      <img flex v-center h-center src="./assets/Svg/{{item.icon}}.svg" alt="navigator-image" *ngIf="item.icon && item.icon.length>0" />
                      <span>{{item.displayName}}</span>
                    </p>
                  </div>
                </div>

                <div class="personality-types" flex v-center *ngIf="navigatorCompletionSet.length == 0">
                  <div class="m-auto" flex v-center h-center>
                    <p flex v-center class="w-100 not-saved-text text-center">Not Completed
                    </p>
                  </div>
                </div>
              </mat-card>

              <!-- Explorer Card -->

              <mat-card class="card card-explorer" *ngIf="moduleTitle.moduleName === 'Explorer'">
                <div pointer matTooltip="{{keyWords.dictionary.careerGroupHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('explorer')">
                  <div class="circle-image m-auto explorer" flex v-center h-center>
                    <img src="assets/Svg/globe.svg" alt="globe-image">
                  </div>
                  <p m-0 class="w-100 text-center tabname" p-5>{{tabnames[0]}}</p>
                  <div class="badge" *ngIf="(ideaScores.length>0 && peopleScores.length>0 && thingsScores.length>0)">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>

                <div class="text-center personality-types"
                  *ngIf="(ideaScores.length>0 || peopleScores.length>0 || thingsScores.length>0)">
                  <p class="text-center" m-0 font-bold>
                    {{keyWords.dictionary.careerGroup}}:
                  </p>
                  <div flex v-center>
                    <div class="w-100 career-group-content">
                      <p m-0 class="w-100" font-bold>
                        {{keyWords.dictionary.peopleTrack}}
                      </p>
                      <i *ngIf="ideaScores!=undefined && peopleScores.length>0 "
                        class="material-icons success-tick">done</i>
                    </div>
                    <div class="w-100 career-group-content">
                      <p m-0 class="w-100" font-bold>
                        {{keyWords.dictionary.ideasTrack}}
                      </p>
                      <i *ngIf="ideaScores!=undefined && ideaScores.length>0 "
                        class="material-icons success-tick">done</i>
                    </div>

                    <div class="w-100 career-group-content">
                      <p m-0 class="w-100" font-bold>
                        {{keyWords.dictionary.thingsTrack}}
                      </p>
                      <i *ngIf="ideaScores!=undefined && thingsScores.length>0"
                        class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div class="badge-section text-center personality-types" flex v-center h-center
                  *ngIf="(ideaScores.length == 0 && peopleScores.length == 0 && thingsScores.length == 0)">
                  <p m-0 class="text-center not-saved-text">
                    Not Completed
                  </p>
                </div>
              </mat-card>

              <!-- path-finder card -->

              <mat-card class="card card-pathfinder" *ngIf="moduleTitle.moduleName === 'Pathfinder'">
                <div pointer matTooltip="{{keyWords.dictionary.personalityTypesHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('pathfinder')">
                  <div class="circle-image m-auto pathfinder" flex v-center h-center>
                    <img src="assets/Svg/compass.svg" alt="compass-image" />
                  </div>

                  <p m-0 class="w-100 text-center tabname" p-5>
                    {{tabnames[1]}}
                  </p>
                  <div class="top-score text-center" *ngIf="pathfinder.length<=0"></div>
                  <div class="badge" *ngIf="pathfinder!=undefined && pathfinder.length>0">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div class="badge-section text-center personality-types" d-flex v-center h-center
                  [ngClass]='(pathfinder.length===0)? "badgeFlex":""'>
                  <p class="text-center" m-0 font-bold *ngIf="pathfinder.length>0">
                    {{keyWords.dictionary.personalityTypes}}:
                  </p>
                  <div flex v-center h-center *ngIf="pathfinder.length>0">
                    <p m-0 font-bold class="text-center types" *ngFor="let holland of likedholland">
                      <img *ngIf="holland.icon && holland.icon.length>0" flex v-center h-center src="./assets/pathfinder/holland/{{holland.icon}}.svg"
                        alt="personality-image" />
                      <span>{{holland.displayName}}</span>
                    </p>
                  </div>
                  <div class="badge-section text-center personality-types" flex v-center *ngIf="pathfinder.length == 0">
                    <p m-0 class="text-center not-saved-text">
                      Not Completed
                    </p>
                  </div>
                </div>
              </mat-card>

              <!--carrer-trekker card -->

              <mat-card class="card card-trekk" *ngIf="moduleTitle.moduleName === 'Trekker'">
                <div pointer matTooltip="{{keyWords.dictionary.topTwoSkillsHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('careertrekker')">
                  <div class="circle-image m-auto trekker" flex v-center h-center>
                    <img src="assets/Svg/direction.svg" alt="direction-image">
                  </div>

                  <p m-0 class="w-100 text-center tabname" p-5>
                    {{tabnames[2]}}
                  </p>
                  <div class="top-score text-center" *ngIf="careertrekker.length<=0"></div>
                  <div class="badge" *ngIf="careertrekker!=undefined && careertrekker.length>0">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div class="skills" flex h-center>
                  <div class="good-skills" *ngIf="careertrekker.length>0">
                    <p font-bold class="skill-header text-center">
                      {{keyWords.dictionary.goodAt}}:
                    </p>
                    <p m-0 flex v-center h-center *ngFor="let skill of goodSkill">
                      <span flex v-center class="w-100">{{skill.title}}</span>
                      <img *ngIf="skill.icon && skill.icon.length>0" src="{{assetsURL}}{{skill.icon}}.svg" alt="skills-image" />
                    </p>
                  </div>
                  <div class="improve-skills" *ngIf="careertrekker.length>0">
                    <p font-bold class="skill-header text-center">
                      {{keyWords.dictionary.needsImprovement}}:
                    </p>
                    <p m-0 flex v-center h-center *ngFor="let skill of ImproveSkill">
                      <span flex v-center class="w-100">{{skill.title}}</span>
                      <img *ngIf="skill.icon && skill.icon.length>0" src="{{assetsURL}}{{skill.icon}}.svg" alt="skills-image" />
                    </p>
                  </div>
                  <div class="m-auto" flex v-center h-center *ngIf="careertrekker.length == 0">
                    <p flex v-center class="w-100 not-saved-text text-center">
                      Not Completed
                    </p>
                  </div>
                </div>
              </mat-card>

              <!-- cci card -->

              <mat-card class="card card-quickpic" *ngIf="moduleTitle.moduleName === 'QuickPic'">
                <div pointer matTooltip="{{keyWords.dictionary.quickPicHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('quickpic')">
                  <div class="circle-image m-auto quickPic" flex v-center h-center>
                    <img src="assets/Svg/quick-pic.svg" alt="quick-pic-image">
                  </div>

                  <p m-0 class="w-100 text-center tabname" p-5>
                    {{tabnames[3]}}
                  </p>
                  <div class="badge" *ngIf="quickpic!=undefined && quickpic.length>0">
                    <div class="checkmark-box">
                      <i class="material-icons success-tick">done</i>
                    </div>
                  </div>
                </div>
                <div class="top-score personality-types" flex v-center h-center>
                  <!-- <p font-bold *ngIf="quickpic==undefined">{{globalText.dictionary.noResults}}</p> -->
                  <p m-0 *ngIf="quickpic.length > 0">
                    {{
                    UserDetails.modules.assessments.QUICKPIC.secondary.data[0].display}}:
                    {{quickpic[quickpic.length-1] | customDate}}
                  </p>
                  <p class="not-saved-text" m-0 *ngIf="quickpic.length == 0">
                    Not Completed
                  </p>
                </div>
              </mat-card>

              <!-- Career corner Card -->

              <mat-card class="card card-career-corner" *ngIf="moduleTitle.moduleName === 'CareerCorner'">
                <div pointer matTooltip="{{keyWords.dictionary.careerCornerHover}}" matTooltipClass="custom-tooltip"
                  (click)="navigateToModule('careercorner')">
                  <div class="circle-image m-auto career-corner" flex v-center h-center>
                    <img src="{{assetsURL}}career-corner.svg" alt="globe-image">
                  </div>
                  <p m-0 class="w-100 text-center tabname" p-5>{{moduleTitle.displayName}}</p>
                </div>

                <div flex v-center class="top-score personality-types" *ngIf="careerCornerCount.length>0">
                  <p m-0 class="w-100 text-center" *ngIf="careerCornerNothingShared">{{careerCornerNothingShared}}</p>
                  <p m-0 class="w-100 text-center" *ngIf="careerCornerShared">
                    {{globalText.dictionary.shared}}:
                    {{careerCornerShared | customDate}}
                  </p>
                </div>
                <div class="badge-section text-center personality-types" flex v-center h-center
                  *ngIf="careerCornerCount.length == 0">
                  <p m-0 class="text-center not-saved-text">Not Completed
                  </p>
                </div>
              </mat-card>
            </div>
          </div>
          <!-- Occupations Cards -->
          <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutWrap="wrap" fxLayoutAlign="center"
            fxLayoutGap.xs="2px" fxLayoutGap="5px" fxLayoutGap.sm="2px">
            <div fxFlex="49%" fxFlex.sm="96%" class="favorite-careers">
              <mat-card class="card Occupations-cards favorite-career-block">
                <div class="common-occupation-head m-auto" flex v-center h-center>
                  <div flex v-center h-center m-0 class="w-100"
                    matTooltip="{{keyWords.dictionary.favoriteOccupationsHover}}" matTooltipClass="custom-tooltip">
                    <div class="circle-image thumb-icon" flex v-center h-center>
                      <img src="{{assetsURL}}/thumbs-up-hover.svg" flex v-center h-center alt="Occupations-image" />
                    </div>
                    <span class="w-100" flex v-center h-center
                      font-bold>{{keyWords.dictionary.occupationLibrary}}</span>
                    <span class="count" flex v-center h-center *ngIf="Likeoccs!=undefined">{{Likeoccs.length}}</span>
                  </div>
                </div>
                <div class="mat-card-section">
                  <div class="h-100" flex v-center h-center pointer *ngIf="Likeoccs==undefined || Likeoccs.length==0">
                    <div>
                      <p class="w-100 text-center empty-block">
                        {{keyWords.dictionary.noFavoriteOccs[0]}}<br />{{keyWords.dictionary.noFavoriteOccs[1]}}
                      </p>
                      <img src="{{assetsURL}}binoculars.svg" alt="noOccs" class="w-100 no-data-found m-auto" pointer
                        (click)="exploreOccLibrary()" flex />
                    </div>
                  </div>
                  <mat-card class="card own-occ-card fav-occ-cards custom-career-cards" pointer p-0 flex
                    *ngFor="let item of Likeoccs | orderBy : 'title' : false : true" tabindex="1"
                    (keyup.enter)="modalOpen(item,'like')" (click)="modalOpen(item,'like')">
                    <!-- <div>
                      <span class="circle-image" flex v-center h-center font-bold>{{ item.title | slice:0:1}}</span>
                    </div> -->
                    <div class="own-occ-image">
                      <img src="{{occMediaUrlForSmallRes}}{{item.media?.images.card}}" alt="image-missing" />
                    </div>
                    <div class="w-100 career-cards-text">
                      <p m-0 class="w-100" font-bold>{{item.title}}</p>
                      <p m-0 class="w-100" font-normal>{{item.description}}</p>
                    </div>
                  </mat-card>
                </div>
              </mat-card>
            </div>
            <div fxFlex="49%" fxFlex.sm="96%" class="Occupations-cards career-occupations">
              <div class="circle-image pencil-career-icon" flex v-center h-center>
                <img src="{{keyWords.media.images.customOccImage[1].src}}" flex v-center h-center
                  alt="Occupations-image" />
              </div>
              <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="common-occupation-head" flex v-center h-center>
                      <div flex v-center justify-end m-0 class="w-100"
                        matTooltip="{{keyWords.dictionary.customOccupationsHover}}" matTooltipClass="custom-tooltip">
                        <span flex v-center font-bold
                          class="w-100 carrer-creation-title">{{keyWords.dictionary.customOccupations}}</span>
                        <!-- <img flex v-center h-center src="./assets/images/create.png" flex v-center h-center alt="Occupations-image"> -->
                        <span class="count carrer-creation-count" flex v-center h-center
                          *ngIf="CreateOwnOccs!=undefined">{{CreateOwnOccs.length}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <mat-card class="card Occupations-cards">
                    <div class="mat-card-section">
                      <div class="h-100" flex v-center h-center pointer
                        *ngIf="CreateOwnOccs==undefined || CreateOwnOccs.length==0">
                        <div>
                          <p class="w-100 text-center empty-block">
                            {{keyWords.dictionary.noOwnOccupations[0]}}<br />{{keyWords.dictionary.noOwnOccupations[1]}}
                          </p>
                          <img src="{{keyWords.media.images.customOccImage[1].src}}" alt="ownOccs"
                            class="w-100 no-data-found m-auto" flex />
                        </div>
                      </div>
                      <mat-card class="own-occ-card m-auto" pointer p-0 flex
                        *ngFor="let createOcc of CreateOwnOccs | orderBy : 'title' : false : true"
                        (keyup.enter)="modalOpen(createOcc,'custom','Scout')"
                        (click)="modalOpen(createOcc,'custom','Scout')" tabindex="1">
                        <div class="own-occ-image">
                          <img src="{{createOcc.media?.images.sprite}}" alt="{{createOcc.title}}" />
                        </div>
                        <div class="own-occ-text w-100">
                          <p font-bold>{{createOcc.title}}</p>
                          <!-- progress-bar -->
                          <div class="progress">
                            <div class="circle">
                              <span class="label" *ngIf="createOcc.modules.scout.completed==1"
                                [class.scout-active]="createOcc.modules.scout.completed==1">
                                <i class="material-icons">done</i>
                              </span>
                              <span class="label" *ngIf="!(createOcc.modules.scout.completed==1)"></span>
                              <span class="title">{{tabnames[8]}}</span>
                            </div>
                            <span class="bar"
                              [class.active]="createOcc.modules.scout.completed==1 && createOcc.modules.navigator.completed==1"></span>
                            <div class="circle">
                              <span class="label" *ngIf="createOcc.modules.navigator.completed==1"
                                [class.navigator-active]="createOcc.modules.navigator.completed==1">
                                <i class="material-icons">done</i>
                              </span>
                              <span class="label" *ngIf="!(createOcc.modules.navigator.completed==1)"></span>
                              <span class="title">{{tabnames[6]}}</span>
                            </div>
                            <span class="bar"
                              [class.active]="createOcc.modules.navigator.completed==1 && createOcc.modules.explorer.completed==1"></span>
                            <div class="circle">
                              <span class="label" *ngIf="createOcc.modules.explorer.completed==1"
                                [class.explorer-active]="createOcc.modules.explorer.completed==1">
                                <i class="material-icons">done</i>
                              </span>
                              <span class="label" *ngIf="!(createOcc.modules.explorer.completed==1)"></span>
                              <span class="title">{{tabnames[0]}}</span>
                            </div>
                            <span class="bar"
                              [class.active]="createOcc.modules.explorer.completed==1 && createOcc.modules.pathfinder.completed==1"></span>
                            <div class="circle">
                              <span class="label" *ngIf="createOcc.modules.pathfinder.completed==1"
                                [class.pathfinder-active]="createOcc.modules.pathfinder.completed==1">
                                <i class="material-icons">done</i>
                              </span>
                              <span class="label" *ngIf="!(createOcc.modules.pathfinder.completed==1)"></span>
                              <span class="title">{{tabnames[1]}}</span>
                            </div>
                            <span class="bar"
                              [class.active]="createOcc.modules.explorer.completed==1 && createOcc.modules.pathfinder.completed==1"></span>
                            <div class="circle">
                              <span class="label" *ngIf="createOcc.modules.trekker.completed==1"
                                [class.trekker-active]="createOcc.modules.trekker.completed==1">
                                <i class="material-icons">done</i>
                              </span>
                              <span class="label" *ngIf="!(createOcc.modules.trekker.completed==1)"></span>
                              <span class="title">{{tabnames[2]}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </mat-card>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="common-occupation-head dream-career-head">
                      <div flex v-center h-center m-0 class="w-100"
                        matTooltip="{{keyWords.dictionary.dreamOccupationsHover}}" matTooltipClass="custom-tooltip">
                        <span flex v-center h-center font-bold
                          class="w-100 ">{{keyWords.dictionary.dreamOccupations}}</span>
                        <span class="count" flex v-center h-center
                          *ngIf="dreamCareersList!=undefined">{{dreamCareersList.length}}</span>
                      </div>
                    </div>
                  </ng-template>

                  <mat-card class="card Occupations-cards">
                    <div class="mat-card-section">
                      <div class="h-100" flex v-center h-center pointer
                        *ngIf="dreamCareersList==undefined || dreamCareersList.length==0">
                        <div>
                          <p class="w-100 text-center empty-block">
                            {{keyWords.dictionary.noOwnOccupations[0]}}<br />{{keyWords.dictionary.noOwnOccupations[1]}}
                          </p>
                          <img src="{{keyWords.media.images.customOccImage[1].src}}" alt="ownOccs"
                            class="w-100 no-data-found m-auto" flex />
                        </div>
                      </div>
                      <mat-card class="own-occ-card m-auto" pointer p-0 flex
                        *ngFor="let dreamOcc of dreamCareersList | orderBy : 'title' : false : true"
                        (keyup.enter)="modalOpen(dreamOcc,'dreamOcc')" (click)="modalOpen(dreamOcc,'dreamOcc')"
                        tabindex="2">
                        <div class="own-occ-image">
                          <img src="{{dreamOcc.media?.images.sprite}}" alt="{{dreamOcc.title}}" />
                        </div>
                        <div class="own-occ-text w-100 d-flex align-center">
                          <p font-bold>{{dreamOcc.title}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </mat-card>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mobile-design-show">
  <div class="Career-trek-content landing-img scroll-profile" *ngIf="UserDetails != undefined">
  <div class="Career-trek-content landing-img profile-screen">
    <div>
      <h4 class="landing-page-heading">
        {{keyWords.header}}
        <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" [styleClass]="'custom-occ-audio'"
          (click)="audioClick('inside',keyWords)"></app-audio-button>
        <!-- <button mat-raised-button class="audio-box volume-up custom-occ-audio">
          <i class="material-icons">volume_up</i>
        </button> -->
      </h4>
    </div>
    <div class="container content-box profilepage-sm">
      <div class="profile-stepper">
        <mat-horizontal-stepper>
          <mat-step>
            <ng-template matStepLabel>{{keyWords.menuTitle}}</ng-template>
            <div class="profil" ngStyle.xs="padding:0 10px;">
              <mat-card class="card profil-card" flex v-center h-center>
                <div>
                  <div ngStyle.xs="position:relative;" mb-10>
                    <div class="profil-img" flex v-center h-center>
                      <!-- <div *ngIf="profileImage!=''" class="icon-{{profileImage}}"></div> -->
                      <i *ngIf="myPic==undefined" class="material-icons">person</i>
                      <img *ngIf="myPic!=undefined" src="{{myPic}}" />
                      <div class="person-image-edit" flex v-center h-center pointer (click)="avtarpopup()">
                        <i class="material-icons">mode_edit</i>
                      </div>
                    </div>
                  </div>
                  <!-- <h5 class="text-center">Name</h5> -->
                  <p class="text-center" p-5>
                    {{UserDetails.user?.givenName}}
                    {{UserDetails.user?.middleName}}
                    {{UserDetails.user?.familyName}}
                  </p>
                  <div p-5 class="text-center">
                    <h5>{{keyWords.dictionary.gradeLevel}}</h5>
                    <p ngStyle.xs="font-size:2rem;">
                      {{UserDetails.user?.grade}}
                    </p>
                  </div>
                </div>
              </mat-card>
              <!-- <mat-card class="card profil-card">
                      <div fxLayout="row" fxLayoutAlign="center" fxFlexFill class="common-row" ngStyle.xs="min-height:50px">
                       
                      </div>
                    </mat-card> -->
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{globalText.dictionary.score}}</ng-template>

            <div class="score">
              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap.sm="3px" fxFlexFill class="common-row"
                ngStyle.xs="height:auto;min-height:auto;margin-bottom:2px;display:block;">
                <div fxFlex="100%">
                  <!-- Discoverer-card -->
                  <mat-card class="card h-100 card-discoverer">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('discoverer')">
                      <p m-0 class="w-100">{{tabnames[7]}}</p>
                      <img src="{{assetsURL}}Discoverer.svg" alt="globe-image">
                    </mat-card-title>
                    <div class="badge" *ngIf="(discovererCompletionSet.length>0)">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>

                      <!-- <div *ngIf="(ideaScores.length>0 || peopleScores.length>0 || thingsScores.length>0)">
                        <p class="career-group-title" font-bold>{{keyWords.dictionary.careerGroup}}:</p>
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.peopleTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="peopleScores!=undefined && peopleScores.length>0">done</i>
                        </p>
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.ideasTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="ideaScores!=undefined && ideaScores.length>0">done</i>
                        </p>
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.thingsTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="thingsScores!=undefined && thingsScores.length>0">done</i>
                        </p>

                      </div> -->
                      <!-- <div *ngIf="(ideaScores.length == 0 && peopleScores.length == 0 && thingsScores.length == 0)">
                        <p flex v-center h-center>
                          <span
                            class="w-100 not-saved-text">{{keyWords.dictionary.notSavedYet[0]}}<br>{{keyWords.dictionary.notSavedYet[1]
                            | ModuleNamePipe : tabnames[1]}}</span>
                        </p>
                      </div> -->
                      <div flex v-center class="personality-types" *ngIf="(discovererCompletionSet.length>0 )">
                        <p font-bold m-0 class="w-100 text-center" *ngIf="discovererCompletionSet.length > 0">
                          {{
                          UserDetails.modules.assessments.DISCOVERER.secondary.data[0].display}}:
                          {{discovererCompletionSet[discovererCompletionSet.length-1] | customDate}}
                        </p>
                      </div>
                      <div class="badge-section text-center personality-types" flex v-center h-center
                        *ngIf="(discovererCompletionSet.length == 0 )">
                        <p m-0 class="text-center not-saved-text">Not Completed
                        </p>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div fxFlex="100%">
                  <!-- Scout Card -->
                  <mat-card class="card h-100 card-scout">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('scout')">
                      <p m-0 class="w-100">{{tabnames[8]}}</p>
                      <img src="{{assetsURL}}Scout.svg" alt="compass-image">
                    </mat-card-title>
                    <div class="badge" *ngIf="scoutCompletionSet.length>0">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <div *ngIf="(scoutCompletionSet.length>0)">
                        <p class="career-group-title" font-bold>{{globalText.dictionary.group}}:</p>
                        <div class="career-group-card" p-0>
                          <div class="text-center" flex v-center h-center>
                            <div class="career-icon">
                              <img src="{{assetsURL}}{{scoutGroupName}}.svg" alt="{{scoutGroupName}}" *ngIf="scoutGroupName && scoutGroupName.length>0"/>
                            </div>
                            <p m-0 font-bold class="career-icon_text">{{scoutGroupValue}}</p>
                          </div>
                        </div>
                        <!-- <div flex v-center h-center *ngIf="pathfinder.length==0">
                          <p class="not-saved-text" ngStyle.xs="padding:0;">
                            {{keyWords.dictionary.notSavedYet[0]}}<br>{{keyWords.dictionary.notSavedYet[1] |
                            ModuleNamePipe
                            : tabnames[2]}}</p>
                        </div> -->
                      </div>
                      <div class="badge-section text-center personality-types" flex v-center h-center
                        *ngIf="scoutCompletionSet.length<= 0">
                        <p m-0 class="text-center not-saved-text">Not Completed
                        </p>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" fxFlexFill class="common-row"
                ngStyle.xs="height:auto;min-height:auto;display:block;">
                <div fxFlex="100%">
                  <!-- Navigator card -->
                  <mat-card class="card h-100 card-navigator">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('navigator')">
                      <p m-0 class="w-100">{{tabnames[6]}}</p>
                      <img src="{{assetsURL}}Navigator.svg" alt="direction-image">
                    </mat-card-title>
                    <div class="badge" *ngIf="navigatorCompletionSet.length>0">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <div class="personality-types">
                        <p class="text-center" m-0 font-bold>
                          {{navigatorCompletionText}}:
                        </p>
                        <div flex v-center h-center>
                          <div class="card-inner-content" *ngFor="let item of navigatorCompletionSet">
                            <img *ngIf="item.icon && item.icon.length>0" flex v-center h-center src="./assets/Svg/{{item.icon}}.svg" alt="navigator-image" />
                            <p font-bold>{{item.displayName}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="personality-types" flex v-center *ngIf="navigatorCompletionSet.length == 0">
                        <p m-0 class="w-100 text-center">Coming Soon</p>
                        <div class="m-auto" flex v-center h-center>
                          <p flex v-center class="w-100 not-saved-text text-center">Not Completed
                          </p>
                        </div>
                      </div>
                      <!-- <div class="m-auto" *ngIf="careertrekker.length == 0">
                          <p class="w-100 not-saved-text" m-0 flex v-center h-center>
                            {{keyWords.dictionary.notSavedYet[0]}}<br>{{keyWords.dictionary.notSavedYet[1] |
                            ModuleNamePipe
                            : tabnames[3]}}
                          </p>
                        </div> -->
                    </mat-card-content>
                  </mat-card>
                </div>
                <div fxFlex="100%">
                  <!-- explorer card -->
                  <mat-card class="card h-100 card-explorer">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('explorer')">
                      <p m-0 class="w-100">{{tabnames[0]}}</p>
                      <img src="assets/Svg/globe.png" alt="globe-image">
                    </mat-card-title>
                    <div class="badge" *ngIf="(ideaScores.length>0 && peopleScores.length>0 && thingsScores.length>0)">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <div *ngIf="(ideaScores.length>0 || peopleScores.length>0 || thingsScores.length>0)">
                        <p class="career-group-title" font-bold>
                          {{keyWords.dictionary.careerGroup}}:
                        </p>
                        <!-- <p>Top 3 Scores:</p> -->
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.peopleTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="peopleScores!=undefined && peopleScores.length>0">done</i>
                        </p>
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.ideasTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="ideaScores!=undefined && ideaScores.length>0">done</i>
                        </p>
                        <p flex v-center h-center>
                          <span class="career-group-text w-100">{{keyWords.dictionary.thingsTrack}}</span>
                          <i class="material-icons success-tick"
                            *ngIf="thingsScores!=undefined && thingsScores.length>0">done</i>
                        </p>
                      </div>
                      <div *ngIf="(ideaScores.length == 0 && peopleScores.length == 0 && thingsScores.length == 0)">
                        <p flex v-center h-center>
                          <span
                            class="w-100 not-saved-text">{{keyWords.dictionary.notSavedYet[0]}}<br />{{keyWords.dictionary.notSavedYet[1]
                            | ModuleNamePipe : tabnames[0]}}</span>
                        </p>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <!-- second row -->
              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap.sm="3px" fxFlexFill class="common-row"
                ngStyle.xs="height:auto;min-height:auto;margin-bottom:2px;display:block;">
                <div fxFlex="100%">
                  <!-- path finder-card -->
                  <mat-card class="card h-100 card-pathfinder">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('pathfinder')">
                      <p m-0 class="w-100">{{tabnames[1]}}</p>
                      <img src="./assets/Svg/compass.png" alt="compass-image" />
                    </mat-card-title>
                    <div class="badge" *ngIf="pathfinder!=undefined && pathfinder.length>0">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <div class="personality-types">
                        <p *ngIf="pathfinder.length > 0" font-bold>
                          {{keyWords.dictionary.personalityTypes}}:
                        </p>
                        <div *ngIf="pathfinder.length>0" flex v-center h-center>
                          <div class="card-inner-content" *ngFor="let holland of likedholland">
                            <img *ngIf="holland.icon && holland.icon.length>0" src="./assets/pathfinder/holland/{{holland.icon}}.svg" />
                            <p font-bold>{{holland.displayName}}</p>
                          </div>
                        </div>
                        <div flex v-center h-center *ngIf="pathfinder.length==0">
                          <p class="not-saved-text" ngStyle.xs="padding:0;">
                            {{keyWords.dictionary.notSavedYet[0]}}<br />{{keyWords.dictionary.notSavedYet[1]
                            | ModuleNamePipe : tabnames[1]}}
                          </p>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div fxFlex="100%">
                  <!-- Career-trekker card -->
                  <mat-card class="card h-100 card-trekk">
                    <mat-card-title class="card-header" pointer (click)="navigateToModule('careertrekker')">
                      <p m-0 class="w-100">{{tabnames[2]}}</p>
                      <img src="./assets/Svg/direction.png" alt="direction-image" />
                    </mat-card-title>
                    <div class="badge" *ngIf="careertrekker!=undefined && careertrekker.length>0">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <div class="w-100 skills-sec d-flex">
                        <div class="good-skills" *ngIf="careertrekker && careertrekker.length>0">
                          <p class="skills-heading">
                            {{keyWords.dictionary.goodAt}}:
                          </p>
                          <div flex v-center h-center>
                            <div class="skills-block" *ngFor="let skill of goodSkill">
                              <img *ngIf="skill.icon && skill.icon.length>0"  src="{{assetsURL}}{{skill.icon}}.svg" />
                              <p>{{skill.title}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="improve-skills" *ngIf="careertrekker && careertrekker.length>0">
                          <p class="skills-heading">
                            {{keyWords.dictionary.needsImprovement}}:
                          </p>
                          <div flex v-center h-center>
                            <div class="skills-block" *ngFor="let skill of ImproveSkill">
                              <img *ngIf="skill.icon && skill.icon.length>0" src="{{assetsURL}}{{skill.icon}}.svg" />
                              <p>{{skill.title}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="m-auto" *ngIf="careertrekker.length == 0">
                          <p class="w-100 not-saved-text" m-0 flex v-center h-center>
                            {{keyWords.dictionary.notSavedYet[0]}}<br />{{keyWords.dictionary.notSavedYet[1]
                            | ModuleNamePipe : tabnames[2]}}
                          </p>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" fxFlexFill class="common-row"
                ngStyle.xs="height:auto;min-height:auto;display:block;">
                <div fxFlex="100%">
                  <!-- Quickpic card -->
                  <mat-card class="card h-100 card-quickpic">
                    <mat-card-title class="card-header" flex pointer (click)="navigateToModule('quickpic')">
                      <p m-0 class="w-100">{{tabnames[3]}}</p>
                      <img src="./assets/Svg/quick-pic.png" alt="quick-pic-image">
                    </mat-card-title>
                    <div class="badge" *ngIf="quickpic!=undefined && quickpic.length>0">
                      <div class="checkmark-box">
                        <i class="material-icons success-tick">done</i>
                      </div>
                    </div>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <p font-bold *ngIf="quickpic.length > 0"> {{
                        UserDetails.modules.assessments.QUICKPIC.secondary.data[0].display}}:
                        {{quickpic[quickpic.length-1]
                        | customDate}}</p>
                      <p class="not-saved-text" *ngIf="quickpic.length==0">
                        {{keyWords.dictionary.notSavedYet[0]}}<br>{{keyWords.dictionary.notSavedYet[1] | ModuleNamePipe
                        :
                        tabnames[3]}}
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div fxFlex="100%">
                  <!-- Career Corner card -->
                  <mat-card class="card h-100 card-career-corner">
                    <mat-card-title class="card-header" flex pointer (click)="navigateToModule('careercorner')">
                      <p m-0 class="w-100">Career Corner</p>
                      <img src="{{assetsURL}}career-corner.svg" alt="career-corner-image">
                    </mat-card-title>
                    <mat-card-content class="card-body text-center" flex v-center h-center>
                      <!-- <div flex v-center class="top-score personality-types" *ngIf="careerCornerCount.length>0"> -->
                      <p m-0 class="w-100 text-center" *ngIf="careerCornerNothingShared">{{careerCornerNothingShared}}
                      </p>
                      <p font-bold m-0 class="w-100 text-center" *ngIf="careerCornerShared">
                        {{globalText.dictionary.shared}}: {{careerCornerShared | customDate}}
                      </p>
                      <!-- </div> -->
                      <p class="not-saved-text" *ngIf="careerCornerCount.length == 0">
                        Not Completed
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{globalText.dictionary.occupations}}</ng-template>
            <div class="fav-occupations">
              <mat-accordion>
                <mat-expansion-panel m-0 [expanded]="favouriteoccs">
                  <mat-expansion-panel-header (click)="favouriteoccs=!favouriteoccs;ownoccs=!ownoccs">
                    <mat-panel-title>
                      <img src="./assets/Svg/thumbs-up-hover.png" alt="thumbs-image" />
                      <span class="w-100 text-center">{{keyWords.dictionary.favoriteOccupations}}</span>
                      <span *ngIf="favouriteoccs" class="material-icons" flex v-center h-center>keyboard_arrow_up</span>
                      <span *ngIf="!favouriteoccs" class="material-icons" flex v-center
                        h-center>keyboard_arrow_down</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="Occupations-cards">
                    <div class="mat-card-section">
                      <div class="h-100" flex v-center h-center pointer
                        *ngIf="Likeoccs==undefined || Likeoccs.length==0">
                        <div>
                          <p class="w-100 text-center">
                            {{keyWords.dictionary.noFavoriteOccs[0]}}<br />{{keyWords.dictionary.noFavoriteOccs[1]}}
                          </p>
                          <img src="{{assetsURL}}binoculars.svg" alt="noOccs" class="w-100 no-data-found m-auto"
                            (click)="exploreOccLibrary()" flex />
                        </div>
                      </div>
                      <mat-card class="
                              card
                              own-occ-card
                              fav-occ-cards
                              custom-career-cards
                            " pointer p-0 flex *ngFor="let item of Likeoccs | orderBy : 'title': false : true"
                        tabindex="1" (click)="modalOpen(item,'like')">
                        <div class="own-occ-image">
                          <img src="{{occMediaUrlForSmallRes}}{{item.media?.images.card}}" alt="image-missing" />
                        </div>
                        <div class="w-100 career-cards-text">
                          <p m-0 class="w-100" font-bold>{{item.title}}</p>
                          <p m-0 class="w-100" font-normal>
                            {{item.description}}
                          </p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel m-0 [expanded]="ownoccs">
                  <mat-expansion-panel-header (click)="favouriteoccs=!favouriteoccs;ownoccs=!ownoccs">
                    <mat-panel-title>
                      <img src="./assets/Svg/pencil-icon-green.png" alt="pencil-icon" />
                      <span class="w-100 text-center" flex v-center
                        h-center>{{keyWords.dictionary.customOccupations}}</span>
                      <span *ngIf="ownoccs" class="material-icons" flex v-center h-center>keyboard_arrow_down</span>
                      <span *ngIf="!ownoccs" class="material-icons" flex v-center h-center>keyboard_arrow_up</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="w-100 h-100" [ngClass]="ownoccs?'Occupations-cards':''">
                    <div class="h-100" flex v-center h-center
                      *ngIf="CreateOwnOccs==undefined || CreateOwnOccs.length==0">
                      <div>
                        <p class="w-100 text-center">
                          {{keyWords.dictionary.noOwnOccupations[0]}}<br />{{keyWords.dictionary.noOwnOccupations[1]}}
                        </p>
                        <img src="{{keyWords.media.images.customOccImage[1].src}}" alt="ownOccs"
                          class="w-100 no-data-found m-auto" flex />
                      </div>
                    </div>

                    <mat-card class="own-occ-card m-auto" pointer p-0 flex
                      *ngFor="let occ of CreateOwnOccs | orderBy : 'title' : false : true" tabindex="1"
                      (click)="modalOpen(occ,'custom')">
                      <div class="own-occ-image" flex v-center h-center>
                        <img src="{{occ.media?.images.sprite}}" alt="image-missing" />
                      </div>
                      <div class="own-occ-text w-100">
                        <p font-bold>{{occ.title}}</p>
                        <!-- progress-bar -->
                        <div class="progress">
                          <button class="circle">
                            <span class="label" *ngIf="occ.modules.scout.completed==1"
                              [class.scout-active]="occ.modules.scout.completed==1"><i
                                class="material-icons">done</i></span>
                            <span class="label" *ngIf="!(occ.modules.scout.completed==1)"></span>
                            <span class="title">{{tabnames[8]}}</span>
                          </button>
                          <span class="bar"
                            [class.active]="occ.modules.scout.completed==1 && occ.modules.navigator.completed==1"></span>
                          <div class="circle">
                            <span class="label" *ngIf="occ.modules.navigator.completed==1"
                              [class.navigator-active]="occ.modules.navigator.completed==1">
                              <i class="material-icons">done</i>
                            </span>
                            <span class="label" *ngIf="!(occ.modules.navigator.completed==1)"></span>
                            <span class="title">{{tabnames[6]}}</span>
                          </div>
                          <span class="bar"
                            [class.active]="occ.modules.navigator.completed==1 && occ.modules.explorer.completed==1"></span>
                          <button class="circle">
                            <span class="label" *ngIf="occ.modules.explorer.completed==1"
                              [class.explorer-active]="occ.modules.explorer.completed==1"><i
                                class="material-icons">done</i></span>
                            <span class="label" *ngIf="!(occ.modules.explorer.completed==1)"></span>
                            <span class="title">{{tabnames[0]}}</span>
                          </button>
                          <span class="bar"
                            [class.active]="occ.modules.explorer.completed==1 && occ.modules.pathfinder.completed==1"></span>
                          <button class="circle">
                            <span class="label" *ngIf="occ.modules.pathfinder.completed==1"
                              [class.pathfinder-active]="occ.modules.pathfinder.completed==1"><i
                                class="material-icons">done</i></span>
                            <span class="label" *ngIf="!(occ.modules.pathfinder.completed==1)"></span>
                            <span class="title">{{tabnames[1]}}</span>
                          </button>
                          <span class="bar"
                            [class.active]="occ.modules.pathfinder.completed==1 && occ.modules.trekker.completed==1"></span>
                          <button class="circle">
                            <span class="label" *ngIf="occ.modules.trekker.completed==1"
                              [class.trekker-active]="occ.modules.trekker.completed==1">
                              <i class="material-icons">done</i></span>
                            <span class="label" *ngIf="!(occ.modules.trekker.completed==1)"></span>
                            <span class="title">{{tabnames[2]}}</span>
                          </button>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel m-0 [expanded]="dreamOccs">
                  <mat-expansion-panel-header
                    (click)="favouriteoccs=!favouriteoccs;ownoccs=!ownoccs;dreamOccs=!dreamOccs;">
                    <mat-panel-title>
                      <img src="./assets/Svg/pencil-icon-green.png" alt="pencil-icon" />
                      <span class="w-100 text-center">{{keyWords.dictionary.dreamOccupations}}</span>
                      <span *ngIf="dreamOccs" class="material-icons" flex v-center h-center>keyboard_arrow_up</span>
                      <span *ngIf="!dreamOccs" class="material-icons" flex v-center h-center>keyboard_arrow_down</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="Occupations-cards">
                    <div class="mat-card-section">
                      <div class="h-100" flex v-center h-center pointer
                        *ngIf="dreamCareersList==undefined || dreamCareersList.length==0">
                        <div>
                          <p class="w-100 text-center">
                            {{keyWords.dictionary.noOwnOccupations[0]}}<br />{{keyWords.dictionary.noOwnOccupations[1]}}
                          </p>
                          <img src="{{assetsURL}}binoculars.svg" alt="noOccs" class="w-100 no-data-found m-auto"
                            (click)="exploreOccLibrary()" flex />
                        </div>
                      </div>
                      <mat-card class="
                              card
                              own-occ-card
                              fav-occ-cards
                              custom-career-cards
                            " pointer p-0 flex
                        *ngFor="let dreamOcc of dreamCareersList | orderBy : 'title' : false : true" tabindex="3"
                        (keyup.enter)="modalOpen(dreamOcc,'dreamOcc')" (click)="modalOpen(dreamOcc,'dreamOcc')">
                        <div class="own-occ-image">
                          <img src="{{dreamOcc.media?.images.sprite}}" alt="image-missing" />
                        </div>
                        <div class="w-100 career-cards-text">
                          <p m-0 class="w-100" font-bold>{{dreamOcc.title}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>

      <div class="download-button-mobile" (click)="DownloadPDF()">
        <button mat-fab class="pdf-button" flex v-center h-center>
          <span>
            <i class="material-icons">picture_as_pdf</i>
          </span>
        </button>
      </div>
    </div>
  </div>
  </div>
</div>

<app-static-footer></app-static-footer>