import { Component, Inject, OnInit } from "@angular/core";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { Next } from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { takeWhile } from "rxjs/operators";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { AudioState, PageDataState, ScoutActivityOneState } from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-scout-intro",
  templateUrl: "./scout-intro.component.html",
  animations: [Next],
})
export class ScoutIntroComponent implements OnInit {
  completed = true;
  assetsUrl: string;
  pages: any;
  compActive = true;
  categories = [];
  scoutHeader: string;
  images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
  theme_name: string;
  keywords: any;
  pageData: any;
  curAudio: string;
  headerAudioState = "stoped";
  headerAudionotfound = false;

  constructor(
    public eventService: EventdispatchService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private pagesData: Store<PageDataState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private scoutActivityOneState: Store<ScoutActivityOneState>,
    private oninitpopupservice: OnLoadPopUpService,
    public store: Store<PageDataState>,
    private utils: Utilities,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[7]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "SCOUT", pageCode: "SCOUT_INTRO" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/scout/intro") {
          if (e.type === "languageChanged") {
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });
    this.assetsUrl = assetsMediaUrl;
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });
  }

  ngOnInit() {
    this.pagesData
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((response) => {
        this.pages = response.pages;
        if (response.pages) {
          const index = this.pages.findIndex(
            (x) => x.pageCode === "SCOUT_INTRO"
          );
          this.pageData = this.pages[index];
          this.scoutHeader = this.pages[index].header;
        }
      });
    this.apiModel.moduleName = "Favoccs";
    this.apiModel.endUrl = "/occs/categories";
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.categories = JSON.parse(resp);
          if (this.categories) {
            this.categories.forEach((element, inx) => {
              element["icon"] = this.images[inx];
            });
          }
        });
    } catch (e) {
      this.utils.handleError(`scout-intro.component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
    this.setScoutIntroTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  clickRoute() {
    this.scoutActivityOneState.dispatch({
      type: 'SCOUT_ACTIVITY_ONE_VAL'
      , payload: {
        scoutActivityOneData: {
          storedData: []
        }
      }
    });
    this.router.navigate(["../activity_one"], {
      relativeTo: this.activatedRoute,
    });
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("SCOUT_INTRO");
      }.bind(this)
    );
  }

  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }
  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setScoutIntroTitle(){
    this.titleService.setTitle("Scout Intro - CareerTrek");
  }
}
