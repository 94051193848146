/**Angular core packages */
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AudioState } from "../../state-management/state/main-state";
import { EventdispatchService } from "../common-modal/eventdispach.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Utilities } from "../common-services/utilities.service";

/* audio loading for template.*/
@Injectable()
export class AudioService implements OnDestroy {
  audionotfound;
  audioState;
  audio: any;
  playPromise;
  audioUnsubscribe = new Subscription();
  constructor(
    private store: Store<AudioState>,
    private audiostopevent: EventdispatchService,
    private deviceService: DeviceDetectorService,
    private utils: Utilities
  ) {
    this.audioUnsubscribe = this.audiostopevent
      .listenAudioStop()
      .subscribe((e) => {
        if (this.audio) {
          this.audio.pause();
          this.audio = '';
        }
      });
    this.store.select('audio').subscribe((result) => {
      if (result) {
        this.audioState = result.audioState;
        this.audionotfound = result.audionotfound;
      } else {
        this.audionotfound = false;
        this.audioState = 'loading';
      }
    });
  }
  audioFunction(text, data, des?, pipe?) {
    var deviceBrowser = this.deviceService.getDeviceInfo();
    if (this.audioState === 'stoped') {
      this.audio = '';
    }
    if (this.audioState != 'warning') {
      this.store.dispatch({
        type: 'SET_AUDIO_LOADING',
      });
      if (!this.audio) {
        this.audio = new Audio();

        try {
          if (pipe) {
            this.audio.src = data[0];
          } else if (des) {
            if (data.audio) {
              this.audio.src = data.audio[0].src;
            } else if (data.media.descAudio) {
              this.audio.src = data.media.descAudio[0].src;
            } else if (data.media.audio2) {
              this.audio.src = data.media.audio2[0].src;
            }
          } else {
            this.audio.src = data.media.audio[0].src;
          }
          if (
            deviceBrowser.browser.toLowerCase() === 'safari' ||
            navigator.userAgent.match('iOS')
          ) {
            this.audioState = 'loading';
            this.store.dispatch({
              type: 'SET_AUDIO_LOADING',
            });
            // Here no need to change the audiostate, cause in below condition audio will unlock and play for safari
            this.audionotfound = false;
          } else {
            if (!this.audio.load()) {
              if (pipe) {
                this.audio.src = data[1];
              } else if (des) {
                if (data.audio) {
                  this.audio.src = data.audio[0].src;
                } else if (data.media.descAudio) {
                  this.audio.src = data.media.descAudio[0].src;
                } else if (data.media.audio2) {
                  this.audio.src = data.media.audio2[0].src;
                }
              } else {
                this.audio.src = data.media.audio[1].src;
              }
            }
            this.audio.onerror = (error) => {
              this.audionotfound = true;
              this.audioState = 'warning';
              this.store.dispatch({
                type: 'SET_AUDIO_WARNING',
              });
            };
          }
        } catch (e) {
          this.audionotfound = true;
          this.audioState = 'warning';
          this.store.dispatch({
            type: 'SET_AUDIO_WARNING',
          });
          this.utils.handleError(`audioLoading.service.ts warning: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      }
      if (this.audionotfound === false) {
        if (text === 'inside') {
          if (!this.audio.paused) {
            if (this.playPromise) {
              this.playPromise
                .then(() => {
                  // Automatic playback started!
                  // Show playing UI.
                  this.audio.pause();
                  this.audioState = 'stoped';
                  this.store.dispatch({
                    type: 'SET_AUDIO_STOPED',
                  });
                })
                .catch ((e) => {
                  this.utils.handleError(`audioLoading.service.ts inside: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                });
            }
          } else {
            this.playPromise = this.audio.play();
            this.playPromise
              .then(() => {
                this.audioState = 'playing';
                this.store.dispatch({
                  type: 'SET_AUDIO_PLAYING',
                });
              })
              .catch(() => {
                if (pipe) {
                  this.audio.src = data[1];
                } else if (des) {
                  if (data.audio) {
                    this.audio.src = data.audio[0].src;
                  } else if (data.media.descAudio) {
                    this.audio.src = data.media.descAudio[0].src;
                  } else if (data.media.audio2) {
                    this.audio.src = data.media.audio2[0].src;
                  }
                } else {
                  this.audio.src = data.media.audio[1].src;
                }
                this.playPromise = this.audio.play();
                this.playPromise
                  .then(() => {
                    this.audioState = 'playing';
                    this.store.dispatch({
                      type: 'SET_AUDIO_PLAYING',
                    });
                  })
                  .catch((e) => {
                    this.audioState = 'warning';
                    this.store.dispatch({
                      type: 'SET_AUDIO_WARNING',
                    });
                    this.utils.handleError(`audioLoading.service.ts playing warning: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                  });
              });
            this.audio.onended = function () {
              this.audioState = 'stoped';
              this.store.dispatch({
                type: 'SET_AUDIO_STOPED',
              });
            }.bind(this);
          }
        } else if (text === 'start') {
          if (!this.audio.paused) {
            if (this.playPromise) {
              this.playPromise
                .then(() => {
                  this.audio.pause();
                  this.audioState = 'stoped';
                  this.store.dispatch({
                    type: 'SET_AUDIO_STOPED',
                  });
                })
                .catch((e) => {
                  this.utils.handleError(`audioLoading.service.ts stopped: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
                });
            }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.audio = '';
    this.audioUnsubscribe.unsubscribe();
  }
}
