<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{trekkerIntroHeading?.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" (click)="audioClick('inside',trekkerIntroHeading)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
            (click)="audioClick('inside',trekkerIntroHeading)">
            <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
            <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
            <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
            <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="content-box career-trekker-into">
      <div class="mobile-heading mobile-design-show">
        <div flex v-center justify-end>
          <button mat-fab color="primary" (click)="clickRoute(cardsData[0].name)" [@flipAnimation]="completed"> 
            <i class="material-icons">arrow_forward</i>
          </button> 
        </div>
      </div>
      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutWrap="wrap" fxLayoutAlign="center" fxLayoutGap.xs="2px" fxLayoutGap="10px"
        fxLayoutGap.sm="2px">
        <div fxFlex="30%" fxFlex.sm="40%" fxFlex.xs="46%" *ngFor="let a of cardsData" [ngStyle]="{'pointer-events': 'none'}" class="ct-cards">
          <mat-card class="card" *ngIf="a!=''&&a!=null&&a!=undefined">
            <mat-card-title class="card-header desktop-design-show" text-color="primary"> {{a.title}} </mat-card-title>
            <mat-card-content class="card-body text-center" flex v-center h-center ngStyle.xs="justify-content:left;display:block;">
              <div class="" *ngIf="a.icon!=''&&a.icon!=null&&a.icon!=undefined">
                <img src="{{assetsUrl}}{{a.icon}}.svg" alt=" {{a.title}}">
              </div>
              <p m-0 class="mobile-design-show text-left w-100" flex text-color="primary"> {{a.title}} </p>
            </mat-card-content>
          </mat-card>
        </div>
        <button mat-fab color="primary" class="next-level desktop-design-show" [@flipAnimation]="completed" (click)="clickRoute(cardsData[0].name)">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>