<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{trekkerResultHeading.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" (click)="audioClick('inside',trekkerResultHeading)"></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',trekkerResultHeading)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>
      </button> -->
    </h4>
  </div>
  <div class="container " >
    <div class="content-box career-trekker-result">
      <!-- Desktop design Start -->
      <div class="filter-list infinet-scroll-height">
      <div fxLayout="row" fxLayout.xs="column"  class="common-row-sub desktop-design-show"  v-top>
          <div fxFlex="90%" fxFlex.xs="" fxFlex.sm="49%" class="card-list infinet-scroll-height" v-center h-center flex>
            <div class="w-100 h-100" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100" [infiniteScrollContainer]="selector"
            [fromRoot]="true" (scrolled)="onScroll()">
            <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutWrap="wrap" fxLayoutAlign="center" fxLayoutGap="2px">
              <div class="h-100" *ngIf="cards.length==0" flex v-center h-center>
                <div class="text-center"> <p class="w-100" font-bold>{{keywords?.dictionary?.noResults}}
                  <br>{{keywords?.dictionary?.changeFilter}}</p>
                <!-- <img *ngIf="cards.length==0" class="no-data-image" [src]="keywords.media.images.noResults[0].src"  [alt]="keywords.dictionary.noResults"> -->
              </div></div>
              <div fxFlex="33%" fxFlex.xs="45%" fxFlex.md="32%" fxFlex.sm="48%" *ngFor="let a of cards;let i = index">
                <div class="card" tabindex="0" pointer (click)="modalOpen(a,i)" (keyup.enter)="modalOpen(a,i)">

                  <img class="w-100" src="{{occCardAPIUrl}}{{a.media?.images.card}}">
                  <div [ngClass]="{'image-card-inactive' : (((thumbTrue.indexOf(a.occId)===-1) && (thumbFalse.indexOf(a.occId)===-1)) && !a.viewed)}" class="opacity-text">
                    <div flex>
                      <p m-0 class="w-100">
                        <span class="w-100">{{a?.title}}</span>
                        <!-- *ngIf="a.viewed" -->
                        <!-- <i class="material-icons success-tick" *ngIf="a?.viewed">done</i> -->
                        <!-- *ngIf="thumbTrue.indexOf(a.occId)>=0" -->
                        <!-- *ngIf="thumbFalse.indexOf(a.occId)>=0" -->
                        <img  alt="thumb-up-filled icon" *ngIf="thumbTrue.indexOf(a?.occId)>=0" src="{{assetsUrl}}thumbs-up-outline-white.svg">
                        <!-- <img  alt="thumb-down-filled icon" *ngIf="thumbFalse.indexOf(a?.occId)>=0" src="{{assetsUrl}}thumbs-down-outline-white.svg"> -->
                      </p>
                      <!-- <i class="material-icons">{{thumb[i]}}</i> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="" fxFlex.sm="52%" class="questions-list">
          <div class="questions-list__block">
            <h3 class="career-treker-heading text-center">"{{keywords?.dictionary?.goodAt}}" {{keywords?.dictionary?.skills}}</h3>
            <div class="card circle-image-list-status" flex v-center *ngFor="let a of good;let i=index">
              <div class="circle-img " flex v-center h-center>
                <img src="{{assetsUrl}}{{a?.icon}}.svg" alt="{{a?.title}}">
              </div>
              <div class="radio-options">
                <p>{{a?.title}}</p>
                <ul class="list-style-none" flex v-center p-0 m-0>
                  <li *ngFor="let l of list1">
                    <mat-radio-group [(ngModel)]="goodSkill[i]" >
                      <mat-radio-button value="{{l}}"(change)="careerTrekker(l,a.name,i)">
                        <span class="radio-button-text">{{l | buttonTextChange : keywords?.dictionary}}</span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="questions-list__block">
            <h3 class="career-treker-heading text-center">"{{keywords?.dictionary?.needToImprove}}" {{keywords?.dictionary?.skills}}</h3>
            <div class="card circle-image-list-status" flex v-center *ngFor="let a of imp;let i=index">
              <div class="circle-img " flex v-center h-center>
                <img src="{{assetsUrl}}{{a?.icon}}.svg" alt="{{a?.title}}">
              </div>
              <div class="radio-options">
                <p>{{a?.title}}</p>
                <ul class="list-style-none" flex v-center p-0 m-0>
                  <li *ngFor="let l of list1">
                    <mat-radio-group  [(ngModel)]="impSkill[i]">
                      <mat-radio-button value="{{l}}" (change)="careerTrekker(l,a.name,i+2)">
                        <span class="radio-button-text">{{l | buttonTextChange : keywords?.dictionary}}</span>
                      </mat-radio-button>
    
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="text-center">     
            <p text-color="primary" class="challenge-start" font-bold>{{keywords?.dictionary?.afterExploring}}</p>
            <div class="challenge-block" flex v-center h-center>
              <img src="{{assetsUrl}}star.svg" class="rotate-animation" alt="star-icon">
              <button mat-raised-button color="warn" class="challenge-button" (click)="goToChallenge()">{{keywords?.dictionary?.challenge}}</button>
            </div>              
          </div>
        </div>
      </div>
      <!-- Desktop design End -->
    
      <!-- Mobile design Start -->
      <div class="mobile-design-show mobile-part-ct">
        <mat-horizontal-stepper>
          <mat-step>
            <p text-color="primary" class="text-center" font-bold>{{keywords?.dictionary?.filterSkills}}</p>
            <mat-accordion>
              <mat-expansion-panel m-0 [expanded]="goodtoogle">
                <mat-expansion-panel-header background-color="primary" (click)="goodtoogle=true;imptoogle=false">
                  <mat-panel-title>
                    "{{keywords?.dictionary?.goodAt}}" {{keywords?.dictionary?.skills}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card circle-image-list-status" flex v-center *ngFor="let a of good;let i=index">
                  <div class="circle-img" flex v-center h-center>
                    <img src="{{assetsUrl}}{{a?.icon}}.svg" alt="{{a?.title}}">
                  </div>
                  <div class="radio-options">
                    <p>{{a.title}}</p>
                    <ul class="list-style-none" flex v-center p-0 m-0>
                      <li *ngFor="let l of list1">
                        <mat-radio-group (click)="careerTrekker(l,a.name,i)" [(ngModel)]="goodSkill[i]">
                          <mat-radio-button value="{{l}}">
                            <p>{{l | buttonTextChange : keywords.dictionary}}</p>
                          </mat-radio-button>
                        </mat-radio-group>
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel m-0 [expanded]="imptoogle">
                <mat-expansion-panel-header background-color="primary" (click)="goodtoogle=false;imptoogle=true">
                  <mat-panel-title>
                    "{{keywords?.dictionary?.needToImprove}}" {{keywords?.dictionary?.skills}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card circle-image-list-status" flex v-center *ngFor="let a of imp;let i=index">
                  <div class="circle-img" flex v-center h-center>
                    <img src="{{assetsUrl}}{{a?.icon}}.svg" alt="{{a?.title}}">
                  </div>
                  <div class="radio-options">
                    <p>{{a?.title}}</p>
                    <ul class="list-style-none" flex v-center p-0 m-0>
                      <li *ngFor="let l of list1">
                        <mat-radio-group (click)="careerTrekker(l,a.name,i+2)" [(ngModel)]="impSkill[i]">
                          <mat-radio-button value="{{l}}">
                            <p>{{l | buttonTextChange : keywords?.dictionary}}</p>
                          </mat-radio-button>
                        </mat-radio-group>
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <button class="next-level" mat-fab color="accent" [@flipAnimation]="completed" matStepperNext>
              <i class="material-icons">arrow_forward</i>
            </button>
          </mat-step>
          <mat-step>
            <div class="filter-option" flex v-center>
              <p class="w-100" m-0 text-color="primary">{{keywords.dictionary.filterSkills}}</p>
              <button mat-raised-button color="accent" class="audio-box" flex v-center (click)="filterModal()">
                <img src="assets/images/FILTER.png" alt="filter image">
              </button>
            </div>
            <div class="filter-list infinte-scroll-height-mobile" flex v-center>
              <div class="w-100 h-100" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100" [infiniteScrollContainer]="selector2"
                [fromRoot]="true" (scrolled)="onScroll()">
             
                <div class="h-100" *ngIf="cards.length==0" flex v-center h-center>
                  <div class="text-center">
                    <p class="w-100" font-bold>{{keywords?.dictionary?.noResults}}
                      <br>{{keywords?.dictionary?.changeFilter}}</p>
                    <!-- <img *ngIf="cards.length==0" class="no-data-image m-auto" flex v-center h-center [src]="keywords.media.images.noResults[0].src"  [alt]="keywords.dictionary.noResults"> -->
                  </div>
                </div>
                <mat-list role="list" *ngFor="let a of cards;let i = index">
                  <mat-list-item role="listitem" (click)="modalOpen(a,i)">{{a?.title}}
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
            <mat-card class="card w-100">
              <mat-card-title class="card-header" background-color="primary">{{keywords?.dictionary?.startChallenge}}</mat-card-title>
              <mat-card-content class="challenge-block card-body" flex v-center h-center>
                <img src="{{assetsUrl}}star.svg" class="rotate-animation" alt="star-icon">
                <button mat-raised-button color="warn" class="challenge-button" (click)= "goToChallenge()">{{keywords?.dictionary?.challenge}}</button>
              </mat-card-content>
            </mat-card>
           </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>