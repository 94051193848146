import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/switchMap';
// import 'rxjs/add/operator/catch';
import{of, map, switchMap, catchError} from 'rxjs'
import { ApiCallService } from './../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../shared/common-services/api-call-model';

export function toPayload(action: Action): any {
    return (action as any).payload;
}
@Injectable()
export class CTEffects {
    constructor(
        private apiCall: ApiCallService, private apiJson: ApiCallClass,
        private actions$: Actions
    ) { }

    @Effect() siteData$ = this.actions$.pipe
        (ofType('SITE_PROFILE_TEXT')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;
        
            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_SITE_TEXT', payload: result });
                }),
                catchError(error => {
                    return new Observable();
                }));
        }));

    //For User Profile data.
    @Effect() profileImgData$ = this.actions$
        .pipe(ofType('PROFILE_IMAGE')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getImage([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_PROFILE_IMAGE', payload: result[0] });
                }),
                catchError(error => {
                    return new Observable();
                }));
        }));


    @Effect() pageData$ = this.actions$
        .pipe(ofType('PAGES_PROFILE_TEXT')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;
            let pageData = window.sessionStorage.getItem('pagesData');
            let selectedPage;
            if (pageData) {
                pageData = JSON.parse(pageData);
                selectedPage = pageData['pages'].find(val => val.selected);
                of({ type: 'SET_PAGE_TEXT', payload: (pageData) });
            }
            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    result = JSON.parse(result);
                    result.map(val => {
                        if (selectedPage && val.module === selectedPage.module && val.pageCode === selectedPage.pageCode) {
                            val.selected = true;
                        } else {
                            val.selected = false;
                        }
                        return val;
                    })
                    return of({ type: 'SET_PAGE_TEXT', payload: result });
                }),catchError(error => {
                    return new Observable();
                }));
            // }
        }));

    @Effect() occListData$ = this.actions$
        .pipe(ofType('OCC_LIST_TEXT')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_OCC_LIST_TEXT', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() occIndexData$ = this.actions$
        .pipe(ofType('OCC_TOTAL_LIST_TEXT')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_OCC_TOTAL_LIST_TEXT', payload: result });
                }
                ), catchError(error => {
                    return new Observable();
                }));

        }));

    @Effect() attributesData$ = this.actions$
        .pipe(ofType('ATTRIBUTES_TEXT')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_ATTRIBUTES_TEXT', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() factoidsData$ = this.actions$
        .pipe(ofType('FACTOIDS_CALL')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_FACTOIDS_TEXT', payload: result });
                }
                ), catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() explorerOccupationViewedList$ = this.actions$
        .pipe(ofType('GET_EXPLORER_OCC_VIEWED_LIST')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_EXPLORER_OCC_VIEWED_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() pathwayOccupationViewedList$ = this.actions$
        .pipe(ofType('GET_PATHWAY_OCC_VIEWED_LIST')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_PATHWAY_OCC_VIEWED_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() careerTrekkerOccupationViewedList$ = this.actions$
        .pipe(ofType('GET_TREKER_OCC_VIEWED_LIST')).pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson]).pipe(
                switchMap(result => {
                    return of({ type: 'SET_TREKKER_OCC_VIEWED_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));

        }));

    @Effect() scoutOccupationViewedList$ = this.actions$
        .pipe(ofType('GET_SCOUT_OCC_VIEWED_LIST'))
        .pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_SCOUT_OCC_VIEWED_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));

        }));

    //Navigator Occ viewed List
    @Effect() navigatorOccupationViewedList$ = this.actions$
        .pipe(ofType('GET_NAVIGATOR_OCC_VIEWED_LIST'))
        .pipe(
        map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(
                switchMap(result => {
                    return of({ type: 'SET_NAVIGATOR_OCC_VIEWED_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() explorerOccViewedCareer$ = this.actions$
        .pipe(ofType('GET_EXPLORER_OCC_VIEWED_CAREER'))
        .pipe(
            map(toPayload),
            switchMap(payload => {
                this.apiJson.method = payload.methodVal;
                this.apiJson.moduleName = payload.module_Name;
                this.apiJson.sessionID = payload.sessionID;
                this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_EXPLORER_OCC_VIEWED_CAREER', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() pathwayOccViewedCareer$ = this.actions$
        .pipe(ofType('GET_PATHFINDER_OCC_VIEWED_CAREER'))
        .pipe(
            map(toPayload),
            switchMap(payload => {
                this.apiJson.method = payload.methodVal;
                this.apiJson.moduleName = payload.module_Name;
                this.apiJson.sessionID = payload.sessionID;
                this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_PATHFINDER_OCC_VIEWED_CAREER', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() trekkerOccViewedCareer$ = this.actions$
        .pipe(ofType('GET_TREKKER_OCC_VIEWED_CAREER'))
        .pipe(
            map(toPayload),     
            switchMap(payload => {
                this.apiJson.method = payload.methodVal;
                this.apiJson.moduleName = payload.module_Name;
                this.apiJson.sessionID = payload.sessionID;
                this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(
                    switchMap(result => {
                    return of({ type: 'SET_TREKKER_OCC_VIEWED_CAREER', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() scoutOccViewedCareer$ = this.actions$
        .pipe(ofType('GET_SCOUT_OCC_VIEWED_CAREER'))
        .pipe(
            map(toPayload),
            switchMap(payload => {
                this.apiJson.method = payload.methodVal;
                this.apiJson.moduleName = payload.module_Name;
                this.apiJson.sessionID = payload.sessionID;
                this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(
                    switchMap(result => {
                    return of({ type: 'SET_SCOUT_OCC_VIEWED_CAREER', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    //Navigator Occ viewed careers
    @Effect() navigatorOccViewedCareer$ = this.actions$
        .pipe(ofType('GET_NAVIGATOR_OCC_VIEWED_CAREER'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_NAVIGATOR_OCC_VIEWED_CAREER', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() occThumb$ = this.actions$
        .pipe(ofType('GET_OCC_THUMB'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_OCC_THUMB', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() userProfileData$ = this.actions$
        .pipe(ofType('GET_USER_PROFILE_DATA'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_USER_PROFILE_DATA', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));


    @Effect() createOwnOccData$ = this.actions$
        .pipe(ofType('GET_OWN_OCC_TEXT'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_OWN_OCC_TEXT', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() modulesData$ = this.actions$
        .pipe(ofType('GET_MODULES'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_MODULES', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() communityOccsData$ = this.actions$
        .pipe(ofType('GET_COMMUNITYOCCS'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_COMMUNITYOCCS', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() dreamCareerListData$ = this.actions$
        .pipe(ofType('GET_DREAMCAREERS'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_DREAMCAREERS', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));


    @Effect() moduleCompletionSetData$ = this.actions$
        .pipe(ofType('GET_MODULE_COMPLETION_DATA'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_MODULE_COMPLETION_DATA', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() getAttributesData$ = this.actions$
        .pipe(ofType('GET_ATTRIBUTES_DATA'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_ATTRIBUTES_DATA', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() getCategoriesCall$ = this.actions$
        .pipe(ofType('GET_CATEGORIES_DATA'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_CATEGORIES_DATA', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    // Avatar Effects Implementation Started Here

    @Effect() avatarListData$ = this.actions$
        .pipe(ofType('AVATAR_TOTAL_LIST'))
        .pipe(map(toPayload),   
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_AVATAR_TOTAL_LIST', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));


    @Effect() userAvatarData$ = this.actions$
        .pipe(ofType('USER_AVATAR_DATA'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_USER_AVATAR_DATA', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() avatarProfileImgData$ = this.actions$
        .pipe(ofType('AVATAR_PROFILE_IMAGE'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getImage([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_AVATAR_PROFILE_IMAGE', payload: result[0] });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));


    @Effect() getRibbonValueCareer$ = this.actions$
        .pipe(ofType('GET_RIBBON_VALUE'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_RIBBON_VALUE', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() getRibbonDeleteValueCareer$ = this.actions$
        .pipe(ofType('GET_RIBBON_DELETE_VALUE'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'DELETE_RIBBON_VALUE', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() saveCallForCareerCornerThought$ = this.actions$
        .pipe(ofType('SAVE_THOUGHT_BOX'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;
            this.apiJson.data = payload.body;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_THOUGHT_BOX', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));

    @Effect() getCallForCareerCornerThought$ = this.actions$
        .pipe(ofType('GET_SAVE_THOUGHT_BOX'))
        .pipe(map(toPayload),
        switchMap(payload => {
            this.apiJson.method = payload.methodVal;
            this.apiJson.moduleName = payload.module_Name;
            this.apiJson.sessionID = payload.sessionID;
            this.apiJson.endUrl = payload.endUrlVal;

            return this.apiCall.getData([this.apiJson])
                .pipe(switchMap(result => {
                    return of({ type: 'SET_THOUGHT_BOX_VALUE', payload: result });
                }
                ),catchError(error => {
                    return new Observable();
                }));
        }));
}
