<div class="Career-trek-content">
  <div class="container">
    <div *ngIf="explorerCategoryText && explorerCategoryText?.header">
      <h4 class="landing-page-heading">
        {{explorerCategoryText?.header}}
        <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
          (click)="audioClick('inside',explorerCategoryText)"></app-audio-button>
      </h4>
    </div>
  </div>
  <div class="container">
    <div class="explorer-occuptation content-box" *ngIf="data!=undefined" ngStyle.sm="padding: 10px;">
      <div fxLayout="row" fxLayout.xs="column" fxFlexFill class="common-row-sub" fxLayoutGap="10px"
        fxLayoutGap.sm="2px">
        <div fxFlex="76%" fxFlex.sm="100%" fxFlex.xs="100%" flex-basis>
          <mat-card class="card questions-section" ngStyle.sm="height:100%;overflow:hidden;">
            <div class="image-content">
              <img src="{{occLargeCardAPIUrl}}{{data.media?.images.card}}" alt="{{data.title}}" class="w-100" />
              <div class="opacity-text mobile-design-show">
                <p m-0 text-color="primary">
                  <span>
                    <b>{{data?.title}}</b>
                  </span>
                  <app-audio-button [audionotfound]="careerAudionotfound" [audioState]="careerAudioState"
                    (click)="indCareerAudioClick('inside',resp[curIndex])"></app-audio-button>
                </p>
              </div>
              <div class="pagecode-image">
                <img src="assets/explorer/svg/{{pageCode}}.svg" alt="" />
              </div>
            </div>
            <div class="questions-section-princlipals">
              <div class="desktop-design-show text-center" mb-10 pt-12>
                <h2 class="explorer-top-header" text-color="primary" m-0 ngStyle.sm="font-size:1rem;">
                  {{data?.title}}
                </h2>
                <p font-bold>{{data?.description}}
                  <app-audio-button [audionotfound]="careerAudionotfound" [audioState]="careerAudioState"
                    (click)="indCareerAudioClick('inside',resp[curIndex])"></app-audio-button>
                </p>
              </div>
              <div class="question-sec-body">
                <div class="icon-sec question-block dark-yellow w-100" *ngIf="curSelWeight && curSelWeight != ''" flex v-center>
                  <div flex-shrink-zero *ngIf="curSelWeight && curSelWeight != ''" class="circle-icon" flex v-center
                    h-center>
                    <img src="./assets/Svg/{{icon}}.svg" alt="{{icon}}" />
                  </div>
                  <div *ngIf="curSelWeight && curSelWeight != ''" flex v-center class="circle-icon-text w-100">
                    <span font-bold>{{keywords.dictionary.occAttributeWeight | taskSentence :
                      data.title : currweight : currAttr : keywords.dictionary}}</span>
                    <app-audio-button [audionotfound]="attributeAudionotfound" [audioState]="attributeAudioState"
                      (click)="indAttribAudioClick('inside',audioSelected)"></app-audio-button>
                  </div>
                  <div *ngIf="curSelWeight && curOccAtrLen === (attributes.length) && savedData.length <= 2"
                    class="desktop-design-show">
                    <button mat-fab color="primary" class="light-red" v-center h-center [@flipAnimation]="completed"
                      (click)="next(data.occId)">
                      <i class="material-icons">arrow_forward </i>
                    </button>
                  </div>
                  <div *ngIf="curSelWeight && curOccAtrLen === (attributes.length)" class="mobile-design-show">
                    <button mat-fab color="primary" class="light-red" v-center h-center [@flipAnimation]="completed"
                      (click)="next(data.occId)">
                      <i class="material-icons">arrow_forward </i>
                    </button>
                  </div>
                  <div *ngIf="savedDataIds.length === activeIds.length" class="desktop-design-show"
                    ngStyle.md="display:none;" ngStyle.lg="display:none;" ngStyle.xl="display:none;">
                    <button mat-fab color="accent" class="light-red" v-center h-center [@flipAnimation]="completed"
                      (click)="navigateToChallengeWithPopup()">
                      <i class="material-icons">arrow_forward </i>
                    </button>
                  </div>
                </div>
                <div fxLayout="row" *ngIf="!highbutton&&!lowbutton&&!mediumbutton" fxLayout.xs="column"
                fxLayoutAlign="center">
                <div fxFlex="90%" fxFlex.xs="50%">
                  <div class="icon-sec result-block w-100" flex v-center>
                    <div class="custom-question-text" *ngIf="savedDataIds.length !== activeIds.length || curIndex < 2">
                      <span font-normal>{{keywords?.dictionary?.occComplete | nextOccPipe :
                        nexttitle }}</span>
                    </div>
                    <div class="custom-question-text" *ngIf="savedDataIds.length === activeIds.length && curIndex == 2">
                      <span font-normal>{{keywords?.dictionary?.startChallenge}}</span>
                    </div>
                    <div class="mobile-design-show">
                      <button mat-fab color="accent" class="light-red" v-center h-center [@flipAnimation]="completed"
                        (click)="next(data.occId)" ngStyle.xs="position:relative;left:10px;display:flex;">
                        <i class="material-icons">arrow_forward </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div fxFlex="" flex-basis class="m-auto desktop-design-show">
                  <div class="icon-sec status-block">
                    <ul class="list-style-none status-block" flex v-center m-0 ngStyle.sm="margin-top:0px;"
                      ngStyle.xs="margin-top:0px;">
                      <li>
                        <button mat-fab color="accent" class="light-red" [@flipAnimation]="completed"
                          (click)="next(data.occId)">
                          <i class="material-icons">arrow_forward </i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- status block end -->
              <div fxLayout="row" fxFlexFill class="common-row-sub rating-cards" fxLayoutGap="10px"
                fxLayoutGap.sm="5px">
                <div class="circle-rating-card" fxFlex="" *ngIf="highdata.length != 0">
                  <mat-card class="card green-shade-high">
                    <mat-card-title class="card-header green" flex v-center h-center>
                      {{keywords.dictionary.high}}</mat-card-title>
                    <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                      <li class="status-circle question-icon high-icon-bg"
                        *ngFor="let highdata1 of highdata; let i = index">
                        <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons"
                          (click)="onQuesClick(data.occId,'high', i);">
                          <img class="" *ngIf="highdata1!=undefined||highdata1!=null"
                            src="./assets/Svg/{{highdata1}}.svg" alt="{{highdata1}}" />
                          <!-- <div *ngIf="highdata1!=undefined||highdata1!=null" class="{{highdata1}}"></div> -->
                          <div flex v-center h-center *ngIf="highdata1==undefined||highdata1==null" pointer
                            class="occ-question-icon">
                            <img src="./assets/Svg/question-sign-green.svg" alt="question icon in high circle" />
                          </div>
                        </div>
                        <div class="mobile-design-show" (click)="onQuesClick(data.occId,'high', i);">
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}" [checked]="true"
                            *ngIf="highdata1!=undefined||highdata1!=null">
                          </mat-checkbox>
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                            *ngIf="highdata1==undefined||highdata1==null">
                            <img class="sign-icon" src="./assets/Svg/question-sign-green.svg"
                              alt="question icon in high circle" />
                          </mat-checkbox>
                        </div>
                      </li>
                    </ul>
                  </mat-card>
                </div>
                <div class="circle-rating-card" fxFlex="" *ngIf="mediumdata.length != 0">
                  <mat-card class="card orange-shade-medium">
                    <mat-card-title class="card-header orange" flex v-center h-center>
                      {{keywords.dictionary.medium}}</mat-card-title>
                    <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                      <li class="status-circle question-icon medium-icon-bg"
                        *ngFor="let mediumdata1 of mediumdata; let i = index">
                        <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons"
                          (click)="onQuesClick(data.occId,'medium',i);">
                          <img *ngIf="mediumdata1!=undefined||mediumdata1!=null" src="./assets/Svg/{{mediumdata1}}.svg"
                            alt="{{mediumdata1}}" />
                          <!-- <div *ngIf="mediumdata1!=undefined||mediumdata1!=null" class="{{mediumdata1}}"></div> -->
                          <div flex v-center h-center *ngIf="mediumdata1==undefined||mediumdata1==null" pointer
                            class="occ-question-icon">
                            <img src="./assets/Svg/question-sign-orange.svg" alt="question icon in medium circle" />
                          </div>
                        </div>
                        <div class="mobile-design-show" (click)="onQuesClick(data.occId,'medium',i);">
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                            *ngIf="mediumdata1!=undefined||mediumdata1!=null" [checked]="true">
                          </mat-checkbox>
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                            *ngIf="mediumdata1==undefined||mediumdata1==null">
                            <img class="sign-icon" src="./assets/Svg/question-sign-orange.svg"
                              alt="question icon in medium circle" />
                          </mat-checkbox>
                        </div>
                      </li>
                    </ul>
                  </mat-card>
                </div>
                <div class="circle-rating-card" fxFlex="" *ngIf="lowdata.length != 0">
                  <mat-card class="card purple-shade-low">
                    <mat-card-title class="card-header purple" flex v-center h-center>
                      {{keywords.dictionary.low}}</mat-card-title>

                    <ul class="list-style-none icon-sec status-block" flex v-center h-center p-0>
                      <li class="status-circle question-icon low-icon-bg"
                        *ngFor="let lowdata1 of lowdata; let i = index">
                        <div flex v-center h-center class="desktop-design-show h-100 explorer-occ-icons"
                          (click)="onQuesClick(data.occId,'low', i);">
                          <img *ngIf="lowdata1!=undefined||lowdata1!=null" src="./assets/Svg/{{lowdata1}}.svg"
                            alt="{{lowdata1}}.svg" />
                          <!-- <div *ngIf="lowdata1!=undefined||lowdata1!=null" class="{{lowdata1}}"></div> -->
                          <div flex v-center h-center *ngIf="lowdata1==undefined||lowdata1==null" pointer
                            class="occ-question-icon">
                            <img src="./assets/Svg/question-sign-purple.svg" alt="question icon in low circle" />
                          </div>
                        </div>

                        <div class="mobile-design-show" (click)="onQuesClick(data.occId,'low', i);">
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                            *ngIf="lowdata1!=undefined||lowdata1!=null" [checked]="true">
                          </mat-checkbox>
                          <mat-checkbox tabindex="-1" [ngStyle]="{'pointer-events': 'none'}"
                            *ngIf="lowdata1==undefined||lowdata1==null">
                            <img class="sign-icon" src="./assets/Svg/question-sign-purple.svg"
                              alt="question icon in low circle" />
                          </mat-checkbox>
                        </div>
                      </li>
                    </ul>
                  </mat-card>
                </div>
              </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxFlex="24%" class="desktop-design-show" ngStyle.sm="display:none;">
          <mat-card class="card h-100">
            <ul class="side-task-list list-style-none" m-0 p-0>
              <li *ngFor="let image of sideImages">
                <div class="image-card" [ngClass]="(id==image.occId)? 'active':' '" tabindex="0"
                  (click)="onClickChangeId(image.occId)" (keyup.enter)="onClickChangeId(image.occId)">
                  <div class="sprite-img-border">
                    <img src="{{occCardAPIUrl}}{{image?.media?.images?.card}}" />
                  </div>
                  <div class="opacity-text">
                    <p m-0 flex v-center h-center>
                      <span class="w-100">{{image?.title}}</span>
                      <i font-bold
                        *ngIf="(savedDataIds.indexOf(image.occId.toString())>=0 || savedDataIds.indexOf(image.occId)>=0)"
                        class="material-icons success-tick">done</i>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="savedDataIds.length === activeIds.length && curIndex === 2" class="text-center">
              <div class="you-are-text" text-color="primary" font-bold>
                {{keywords?.dictionary?.startChallenge}}
              </div>
              <div class="challenge-block" flex v-center h-center mb-10>
                <img src="{{assetsUrl}}star.svg" class="rotate-animation" alt="star-icon" />
                <button mat-raised-button background-color="primary" class="challenge-button"
                  (click)="navigateToChallenge()">
                  {{keywords?.dictionary?.challenge}}
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>