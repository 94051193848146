/**Import angular core packages */
import { Component, Inject, OnInit } from "@angular/core";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { takeWhile } from "rxjs/operators";
import { ApiCallClass } from "../../../shared/common-services/api-call-model";
import { ApiCallService } from "../../../shared/common-services/api-call-service";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { Store } from "@ngrx/store";
import { PageDataState } from "../../../state-management/state/main-state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-careercorner-intro",
  templateUrl: "./careercorner-intro.layout.html",
})
export class CareerCornerIntroComponent implements OnInit {
  attributesData: any = [];
  keyWords: any = [];
  compActive = true;
  assetsSVG_URL: any;
  careerCornerIntro: any = [];
  careerCornerResp: any = [];
  theme_name: string;
  keywords: any;

  constructor(
    public eventService: EventdispatchService,
    public dialogService: DialogopenService,
    public apiCall: ApiCallService,
    public apiModel: ApiCallClass,
    public store: Store<PageDataState>,
    private utils: Utilities,
    private oninitpopupservice: OnLoadPopUpService,
    @Inject("ASSETS_MEDIA_URL") private assetsSvg_URL: string,
    public snackBar: MatSnackBar,
    private titleService: Title
  ) {
    this.assetsSVG_URL = assetsSvg_URL;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[9]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "CAREER_CORNER", pageCode: "CAREER_CORNER" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/careercorner/intro") {
          if (e.type === "languageChanged") {
            this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
              if (resp.pages) {
                const data = (resp.pages);
                this.careerCornerIntro = data.find((obj => obj.pageCode === 'CAREER_CORNER'));
              }
            });
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });
  }

  ngOnInit() {
    this.careerCornerIntro =
      this.oninitpopupservice.getKeyWordData("CAREER_CORNER");
    this.apiModel.moduleName = "careerCornerOccs";
    this.apiModel.endUrl = "user/CareerCornerOccs";
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            this.careerCornerResp = JSON.parse(resp);
          if(this.careerCornerResp.length === 0){
            this.snackBar.open(this.careerCornerIntro.dictionary.noCareers,'', {
              duration: 5000,
              panelClass: ['success-snackbar']
          });
          }
          }
        });
    } catch (e) {
      this.utils.handleError(`careercorner-intro.component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
    this.attributesCall();
    this.setCareerCornerIntroTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }


  attributesCall() {
    this.apiModel.moduleName = "attributes";
    this.apiModel.endUrl = "/attributes";
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            resp = JSON.parse(resp);
            this.attributesData = resp;
          }
        });
    } catch (e) {
      this.utils.handleError(`careercorner-intro.component.ts attributesCall: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  modalOpenCareerCorner(occId, item) {
    this.dialogService.showLoading();
    this.apiModel.moduleName = "createownocc";
    this.apiModel.endUrl = "user/CareerCornerOccs/Custom-occ/" + occId;
    this.apiModel.sessionID = sessionStorage.getItem("session_token");
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          if (resp) {
            resp = JSON.parse(resp);
            item = resp;
            this.dialogService.hideLoading();
            this.dialogService.DetailOccPopup(
              item,
              this.attributesData,
              "CareerCorner",
              this.keyWords,
            );
            this.dialogService.dialogRef.afterClosed().pipe(takeWhile(() => this.compActive)).subscribe(result => {
              if(result === 'careerCornerClose'){
                this.ngOnInit();
              }
              
            });
          }
        });
    } catch (e) {
      this.utils.handleError(`careercorner-intro.component.ts modalOpenCareerCorner: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("CAREER_CORNER");
      }.bind(this)
    );
  }

  private setCareerCornerIntroTitle(){
    this.titleService.setTitle("Career Corner Intro - CareerTrek")
  }
}
