import { stagger } from '@angular/animations';
/**Import angular core packages */
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { OnLoadPopUpService } from '../../../shared/common-services/onloadpopup.service';
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Next } from '../../../shared/common-animations/CT_animations';
import { EventdispatchService } from '../../../shared/common-modal/eventdispach.service';
import { DialogopenService } from '../../../shared/common-modal/modalpopup.service';
import { Store } from '@ngrx/store';
import { AttributeDataState, AudioState, PageDataState, PathFinderTopSkillsState } from '../../../state-management/state/main-state';
import { RouteUrls } from '../../../shared/common-constants/route.constants';
import { environment } from '../../../../environments/environment';
import { Utilities } from '../../../shared/common-services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { AudioService } from '../../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-pathfinder-topskills',
	templateUrl: './top-skills-layout.html',
	styles: [],
	animations: [Next]
})


export class PathFinderTopSkillsComponent implements OnInit, OnDestroy {
	languageKey: any;
	keywords: any;
	theme_name: string;
	lang: string;
	completed = 'start';
	cardsSelected = [{ 'name': '', 'icon': '', 'hollandCode': '', 'displayName': '', 'attributeId': '' }, { 'name': '', 'displayName': '', 'icon': '', 'hollandCode': '', 'attributeId': '' }];
	cards = [];
	like = [];
	unlike = [];
	i = 0;
	j = 0;
	change = [];
	delay1 = [0, 0];
	prev1inx;
	prev2inx;
	compActive = true;
	pathFinderTopSkills: any;
	attributeId;
	audioState = 'stoped';
	audionotfound = false;
	assetsURL: string;
	dictionary: any;

	constructor(private oninitpopupservice: OnLoadPopUpService, private _location: Location,
		private apiModel: ApiCallClass,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AttributeDataState>,
		private store1: Store<PageDataState>,
		private audioStore: Store<AudioState>, private audioService: AudioService,
		private pathFinderTopSkillsStore: Store<PathFinderTopSkillsState>,
		private apiCall: ApiCallService,
		private eventService: EventdispatchService, private utils: Utilities,
		public dialogService: DialogopenService,
		@Inject('ASSETS_MEDIA_URL') private assetsUrl: string,
		private titleService: Title) {
		this.assetsURL = assetsUrl;
		let colors = environment.THEME_COLORS;
		sessionStorage.setItem('theme_color', colors[1]);
		this.store1.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'PATHFINDER', pageCode: 'PATHFINDER_TOP' } });
		const event = document.createEvent('CustomEvent');
		event.initEvent('themeChanged', true, true);
		this.eventService.dispatch(event);
		sessionStorage.setItem('isModalActive', 'false');
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == ('/app/pathfinder/topskills')) {
				if (e.type === 'languageChanged') {
					this.languageKey = this.cards[0] && this.cards[0].language;
					this.lang = sessionStorage.getItem('session_token');
					this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
					this.oninitpopupservice.pageCallText(this.lang, 'attributes/hollandcode');
					this.ngOnInit();
				}
				if (e.type == 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type == 'infoModal') {
					if (sessionStorage.getItem('infopopup') == 'true') {
						this.infopopup();
						sessionStorage.setItem('infopopup', 'false');
					}
				}
				if (e.type == 'backClicked') {
					this.stopAudio();
					this.unsavedChanges();
				}
			}
		});
		this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
			this.like = [...params['like']];
			this.attributeId = [...params['likeAttributeId']].map((value) => {
				return parseInt(value);
			});
		});

		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe(result => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
	}
	infopopup() {
		this.stopAudio();
		Promise.resolve().then(function () {
			if (this.cardsSelected[0].name === '' || this.cardsSelected[1].name === '') {
				this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_TOP');
			} else {
				this.oninitpopupservice.getOnLoadPopUp('PATHFINDER_SHARED');
			}
		}.bind(this));

	}
	ngAfterViewInit() {
		this.infopopup();
		}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
			const evnt = document.createEvent("CustomEvent");
			this.eventService.dispatchAudioStop(evnt);
		  }
	getAttributes() {
		try {
			this.store.select('attribute').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				const data = (resp.attribute);
				this.cards = [];
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				for (let i = 0; i < data.length; i++) {
					if (this.attributeId.length > 2) {
						if (this.attributeId.indexOf(data[i].attributeId) >= 0) {
							this.change.push(1);
							this.cards.push(data[i]);
						}
					} else {
						if (this.attributeId.length == 1) {
							if (this.attributeId.indexOf(data[i].attributeId) >= 0) {
								this.cardsSelected[0] = data[i];
								this.i = 1;
								this.j++;

							}
						}
						this.change.push(1);
						this.cards.push(data[i]);
					}
				}
				if (this.cards[0] != undefined && this.languageKey != this.cards[0].language
					&& this.keywords.language != this.languageKey) {
					this.languageKey = this.cards[0].language;
					this.dialogService.hideLoading();
				}
				// to set select card index based on selected cards
				if (this.cardsSelected[0].icon === '') {
					this.i = 0
				} else if (this.cardsSelected[1].icon === '') {
					this.i = 1
				}
				// while doing language change,change the selected cards data with current language cards data
				if (this.cardsSelected[0].icon !== '' || this.cardsSelected[1].icon !== '') {
					this.cardsSelected = this.cardsSelected.map(obj => this.cards.find(o => o.attributeId === obj.attributeId) || obj);
				}
			});
		} catch (e) {
			this.utils.handleError(`top-skills-component.ts getAttributes: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	ngOnInit() {
		this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
			if (resp.pages) {
				const data = (resp.pages);
				this.pathFinderTopSkills = data.find((obj => obj.pageCode === 'PATHFINDER_TOP'));
				this.dictionary = this.pathFinderTopSkills.dictionary
				this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
			}
		}
		);
		setTimeout(() => {
			this.completed = 'start';
			this.cardsSelected = [{ 'name': '', 'icon': '', 'hollandCode': '', 'displayName': '', 'attributeId': '' }, { 'name': '', 'displayName': '', 'icon': '', 'hollandCode': '', 'attributeId': '' }];
			this.cards = [];
			this.i = 0;
			this.j = 0;
			this.change = [];
			this.prev1inx = null;
			this.prev2inx = null;
			this.getAttributes();
			//After selected the cards when doing language change to replace the language change data to actual selected cards.
			this.pathFinderTopSkillsStore.select('cardsSelected').pipe(takeWhile(() => this.compActive)).subscribe(skillsresp => {
				if (skillsresp) {
					if (skillsresp['cardsSelected'] && (skillsresp['cardsSelected'].length === 2)) {
						this.cardsSelected = skillsresp['cardsSelected'];
					}
				}
			});
		});

		this.setPathfinderTopSkillTitle();
	}
	cardSelected(card, inx) {
		if ((this.i == 0 && this.cardsSelected[this.i + 1] != card) || (this.i == 1 && this.cardsSelected[this.i - 1] != card)) {
			this.change[inx]++;
			this.delay1[inx]++;
		}
		//to restrict selected item if it is already selected by using cardsSelected array icon key
		if (this.i == 0 && this.cardsSelected[0].icon != card.icon && this.cardsSelected[1].icon != card.icon) {
			this.cardsSelected[this.i] = card;
			this.i = 1;
			this.j++;
			if (this.prev1inx != undefined && this.prev1inx != null) {
				this.change[this.prev1inx] = 0;
			}
			this.prev1inx = inx;
		}else if (this.i == 1 && this.cardsSelected[0].icon != card.icon && this.cardsSelected[1].icon != card.icon) {
			this.cardsSelected[this.i] = card;
			this.i = 0;
			this.j++;
			if (this.prev2inx != undefined && this.prev2inx != null) {
				this.change[this.prev2inx] = 0;
			}
			this.prev2inx = inx;
		}

		if (this.cardsSelected[1].icon != '') {
			this.stopAudio();
			this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp.pages) {
					const data = (resp.pages);
					this.pathFinderTopSkills = data.find((obj => obj.pageCode === 'PATHFINDER_TOP_SELECTED'));
					this.pathFinderTopSkills['dictionary'] = this.dictionary;
				}
			});
		}
		//here storing the 2 personality types based on the selection.
		this.pathFinderTopSkillsStore.dispatch({ type: 'PATHFINDER_TOPSKILLS_VAL', payload: { cardsSelected: this.cardsSelected } });
		this.completed = 'start';
	}
	clickNext() {
		const data = [this.cardsSelected[0].name, this.cardsSelected[1].name];
		sessionStorage.setItem('pathfinderlike', JSON.stringify(data));
		const datasave = [
			{
				'hollandCode': this.cardsSelected[0].hollandCode,
				'likeMe': true,
				'top': true
			}, {
				'hollandCode': this.cardsSelected[1].hollandCode,
				'likeMe': true,
				'top': true
			},
		];
		this.saveChanges(datasave);
		this.router.navigateByUrl('/app/pathfinder/result');
		try {
			this.router.navigate(['/app/pathfinder/result'], {
				relativeTo: this.activatedRoute,
				queryParams: {
					like: this.cardsSelected[0].name + '&' + this.cardsSelected[1].name,
					hollandCode: this.cardsSelected[0].hollandCode + '&' + this.cardsSelected[1].hollandCode
				}
			});
		} catch (e) {
			this.utils.handleError(`top-skills-component.ts clickNext: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	saveChanges(hollandCode) {
		this.apiModel.moduleName = 'pathfinder';
		this.apiModel.endUrl = 'user/holland/add';
		this.apiModel.sessionID = sessionStorage.getItem('session_token');
		this.apiModel.method = 'POST';
		this.apiModel.data = {
			'holland': hollandCode
		};
		try {
			this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp != undefined && resp != null) {
				}
			});
		} catch (e) {
			this.utils.handleError(`top-skills-component.ts saveChanges: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	unsavedChanges() {
		if ((this.cardsSelected[0].name == '' && this.cardsSelected[1].name == '')) {
			const evnt = document.createEvent('CustomEvent');
			this.eventService.dispatchAudioStop(evnt);
			this.utils.backRoute();
		} else if ((this.cardsSelected[0].name !== '' || this.cardsSelected[1].name !== '')) {
			this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
				if (resp.pages) {
					const data = (resp.pages);
					this.pathFinderTopSkills = data.find((obj => obj.pageCode === 'PATHFINDER_TOP'));
					this.dictionary = this.pathFinderTopSkills.dictionary
					this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
				}
			}
			);
			if (this.cardsSelected[1].name !== '') {
				this.cardsSelected[1] = { 'name': '', 'icon': '', 'hollandCode': '', 'displayName': '', 'attributeId': '' };
				this.j = 0;
				this.i = 1;
			} else {
				this.cardsSelected[0] = { 'name': '', 'icon': '', 'hollandCode': '', 'displayName': '', 'attributeId': '' };
				this.j = 0;
				this.i = 0;
			}
			//here storing the 2 personality types based on the selection.
			this.pathFinderTopSkillsStore.dispatch({ type: 'PATHFINDER_TOPSKILLS_VAL', payload: { cardsSelected: this.cardsSelected } });
		}
	}

	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	ngOnDestroy() {
		this.compActive = false;
	}

	private setPathfinderTopSkillTitle(){
		this.titleService.setTitle("Pathfinder Top Skills - CareerTrek");
	}
}
