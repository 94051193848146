import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { RouterModule, Routes } from '@angular/router';
import { DiscovererModuleComponent } from './discoverer-module.component';
import { DiscovererIntroComponent } from './intro/discoverer-intro.component';
import { DiscovererActivityComponent } from './activity/discoverer-activity.component';


const routes: Routes= [{
  path: 'app/discoverer', component: DiscovererModuleComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: DiscovererIntroComponent },
    { path: 'activity', component: DiscovererActivityComponent}
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    SharedModule.forRoot()
  ],

  declarations: [
    DiscovererModuleComponent,
    DiscovererIntroComponent,
    DiscovererActivityComponent
  ],

  providers: [],
  exports: [RouterModule]
})

export class DiscovererModule {}