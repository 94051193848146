<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{trekkerHeading.header}}
      <app-audio-button
        [audionotfound]="audionotfound"
        [audioState]="headerAudioState"
        (click)="audioClick('inside',trekkerHeading,'headerAudio')"
      ></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && headerAudioState=='warning'" 
            (click)="audioClick('inside',trekkerHeading,'headerAudio')">
            <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='stoped'">volume_up</i>
            <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='playing' ">stop</i>
            <div class="audio-loading" *ngIf="audionotfound==false && headerAudioState=='loading'"></div>
            <i class="material-icons" *ngIf="audionotfound==true && headerAudioState=='warning' ">warning</i>
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="content-box career-trekker-assessment">
      <div
        class="mobile-design-show"
        *ngIf="cardsData[startInx]!=undefined&&cardsData[startInx]!=null"
      >
        <div class="mobile-heading" flex v-center>
          <div class="w-100" flex h-center v-center>
            <img
              src="{{assetsUrl}}{{cardsData[startInx]?.icon}}.svg"
              alt="{{cardsData[startInx]?.title}}"
              class="text-center"
            />
            <div>
              <h2 class="text-center trekker-mobile-heading" m-0 p-5>
                {{cardsData[startInx]?.title}}
              </h2>
              <hr class="mobile-design-show" />
            </div>
            <app-audio-button
              [audionotfound]="audionotfound"
              [audioState]="quesAudioState"
              [styleClass]="trekker_volume_btn"
              (click)="audioClick('inside',cardsData[startInx],'quesAudio')"
            ></app-audio-button>
            <!-- <button
              mat-raised-button
              color="primary"
              class="audio-box pink volume-up trekker-volume-btn"
              flex
              v-center
              h-center
              [disabled]="audionotfound==true && quesAudioState=='warning'"
              (click)="audioClick('inside',cardsData[startInx],'quesAudio')"
            >
              <i
                class="material-icons"
                *ngIf="audionotfound==false && quesAudioState=='stoped'"
                >volume_up</i
              >
              <i
                class="material-icons"
                *ngIf="audionotfound==false && quesAudioState=='playing'"
                >stop</i
              >
              <div
                class="audio-loading"
                *ngIf="audionotfound==false && quesAudioState=='loading'"
              ></div>
              <i
                class="material-icons"
                *ngIf="audionotfound==true && quesAudioState=='warning'"
                >warning</i
              >
            </button> -->
          </div>
        </div>
        <p class="text-center" m-0>{{cardsData[startInx]?.description}}</p>
      </div>
      <mat-card
        class="card ct"
        *ngIf="cardsData[startInx]!=undefined&&cardsData[startInx]!=null"
      >
        <div class="desktop-design-show" flex v-center h-center pt-3>
          <div class="question-img text-center" flex-grow-one>
            <img
              src="{{assetsUrl}}{{cardsData[startInx]?.icon}}.svg"
              alt="{{cardsData[startInx].title}}"
              class="text-center"
            />
          </div>
          <div flex flex-grow-one>
            <h2 m-0 flex v-center h-center text-color="primary">
              {{cardsData[startInx]?.title}}
            </h2>
            &nbsp;&nbsp;
            <app-audio-button
              [audionotfound]="audionotfound"
              [audioState]="quesAudioState"
              (click)="audioClick('inside',cardsData[startInx],'quesAudio')"
            ></app-audio-button>
            <!-- <button
              mat-raised-button
              flex
              v-center
              h-center
              color="primary"
              class="audio-box pink volume-up"
              (click)="audioClick('inside',cardsData[startInx],'quesAudio')"
              [disabled]="audionotfound==true && quesAudioState=='warning'"
            >
              <i
                class="material-icons"
                *ngIf="audionotfound==false && quesAudioState=='stoped'"
                >volume_up</i
              >
              <i
                class="material-icons"
                *ngIf="audionotfound==false && quesAudioState=='playing'"
                >stop</i
              >
              <div
                class="audio-loading"
                *ngIf=" audionotfound==false && quesAudioState=='loading'"
              ></div>
              <i
                class="material-icons"
                *ngIf=" audionotfound==true && quesAudioState=='warning'"
                >warning</i
              >
            </button> -->
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxFlexFill
          fxLayoutAlign="center"
          d-block-mobile
        >
          <div fxFlex="" class="information desktop-design-show">
            <p class="text-center h-100" flex v-center h-center m-0>
              {{cardsData[startInx]?.description}}
            </p>
          </div>
          <div fxFlex="" class="test-question">
            <div>
              <!-- This div structure for align equally(using flex to parent) both question section and nextQuestion button in mobile view in white card -->
              <p class="question" text-color="primary">
                {{cardsData[startInx]?.question}}
              </p>
              <ul class="list-style-none" m-0>
                <li>
                  <mat-checkbox
                    class="w-100"
                    [(ngModel)]="goodChoice"
                    name="cb1"
                    (click)="addSkills($event);goodChoice=true;improveChoice=false;"
                    (keyup.space)="addSkills($event);goodChoice=true;improveChoice=false;"
                    >{{cardsData[startInx]?.goodStatement}}</mat-checkbox
                  >
                </li>
                <li>
                  <mat-checkbox
                    class="w-100"
                    [(ngModel)]="improveChoice"
                    name="cb2"
                    (click)="addSkills($event);improveChoice=true;goodChoice=false;"
                    (keyup.space)="addSkills($event);improveChoice=true;goodChoice=false;"
                    >{{cardsData[startInx]?.improveStatement}}</mat-checkbox
                  >
                </li>
              </ul>
            </div>
            <div class="btn-next-mobile mobile-design-show">
              <div *ngIf="nextIcon == 'yes'">
                <button
                  mat-fab
                  color="primary"
                  flex
                  v-center
                  h-center
                  (click)="nextQuestion(cardsData[startInx].name,startInx)"
                  [@flipAnimation]="completed"
                >
                  <i class="material-icons">arrow_forward</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="nextIcon == 'yes'">
          <button
            class="next-level dark-pink desktop-design-show"
            mat-fab
            color="primary"
            (click)="nextQuestion(cardsData[startInx].name,startInx)"
            [@flipAnimation]="completed"
          >
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
