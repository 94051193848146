/**currently this model is for discoverer module only
 * It may apply to other modules
 *
 * Author: Jun zheng
 * Date: may 1, 2023
 */

export interface Categroy {
  categoryId?: Number;
  title?: String;
}

export interface AudioSource {
  type?: String;
  src?: String;
}

export interface VideoSource {
  type?: String;
  videoSrc?: String;
  textSrc?: String;
}

export interface CardSprite {
  card?: String;
  sprite?: String;
}

export interface Media {
  images?: CardSprite;
  audio?: AudioSource[];
  descAudio?: AudioSource[];
  video?: VideoSource[];
}

export interface CommunityOccupations {
  occId?: Number;
  source?: String;
  title?: String;
  description: String;
  category?: Categroy;
  media?: Media;
}
export interface CommunityList {
  communityOccs: Array<CommunityOccupations>;
} 


