<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading quicpic-question-header">
      {{pathFinderHeading?.header}}
      <app-audio-button
        [audionotfound]="audionotfound"
        [audioState]="audioState"
        (click)="audioClick('inside',pathFinderHeading)"
      ></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        (click)="audioClick('inside',pathFinderHeading)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i> 
      </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="pathfinder-challenge content-box">
      <div class="mobile-design-show">
        <mat-toolbar background-color="primary" class="timer-panal">
          <div class="" flex v-center>
            <p>{{keywords?.dictionary?.findMatches}}</p>
            <div
              class="timer"
              flex
              v-center
              h-center
              background-color="primary"
            >
              <span font-bold
                >{{attempts}} {{(attempts==1) ?
                pathFinderHeading?.dictionary?.try :
                pathFinderHeading?.dictionary?.tries}}</span
              >
            </div>
          </div>
        </mat-toolbar>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="center"
        d-block-mobile
      >
        <div fxFlex="80%" fxFlex.xs="100%">
          <div fxLayout.xs="row" fxLayoutWrap="wrap" fxLayoutAlign="center">
            <div fxFlex="100%">
              <div
                fxLayout="row"
                fxLayoutWrap="wrap"
                fxLayoutAlign="center"
                fxLayoutGap="1px"
              >
                <div
                  fxFlex="30%"
                  fxFlex.xs="29%"
                  class="flip-cards"
                  *ngFor="let a of cards;index as inx"
                >
                  <mat-card
                    class="card filp_container w-100"
                    (@CardFlip.done)="transitionEnd(a,inx,1)"
                    [@CardFlip]="a.state"
                  >
                    <div
                      id="filp_card"
                      class="text-center shadow"
                      tabindex="0"
                      (click)="!imageSelect1?event(a,inx,1):''"
                      (keyup.enter)="!imageSelect1?event(a,inx,1):''"
                      [class.Flipped]="myClassbtn[inx]"
                    >
                      <!-- <div class=" image-card" m-0> -->
                      <div
                        class="front_card front_card-ie"
                        background-color="primary"
                        flex
                        v-center
                        h-center
                      >
                      <!-- [ngStyle]=" selectedcard1!='' && selectedcard1 != a.title ? {'opacity':'0.2','pointer-events': 'none'}:{}" -->
                        <!-- <img src="assets/images/key.png"> -->
                        <i class="material-icons">vpn_key</i>
                      </div>
                      <div
                        class="back_card back_card-ie center"
                        flex
                        v-center
                        h-center
                      >
                        <div class="desktop-design-show">
                          <div class="above-tab-show">
                            <img
                              src="{{occCardAPIUrl}}{{a.media?.images.card}}"
                            />
                          </div>
                          <img
                            *ngIf="a.icon"
                            class="opacity-icon below-tab-show"
                            alt="{{a.title}}"
                            src="assets/pathfinder/holland/{{a.icon}}.svg"
                          />

                          <div class="opacity-text">
                            <p class="w-100 text-center" m-0>
                              <span>{{a?.title}} </span>
                              <img
                                *ngIf="a.icon"
                                class="opacity-icon above-tab-show"
                                alt="{{a?.title}}"
                                src="assets/pathfinder/holland/{{a?.icon}}.svg"
                              />
                            </p>
                          </div>
                        </div>
                        <div class="mobile-design-show w-100">
                          <p
                            class="w-100"
                            m-0
                            flex
                            v-center
                            ngStyle.xs="padding: 2px;"
                          >
                            <span text-color="primary" class="text-left w-100"
                              >{{a?.title}}</span
                            >
                            <img
                              [hidden]="!a.icon"
                              src="assets/pathfinder/holland/{{a?.icon}}.svg"
                              alt="{{a?.title}}"
                            />
                          </p>
                        </div>
                      </div>

                      <!-- </div> -->
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
            <div fxFlex="100%">
              <div
                fxLayout="row"
                fxLayoutWrap="wrap"
                fxLayoutAlign="center"
                fxLayoutGap="1px"
              >
                <div
                  fxFlex="30%"
                  fxFlex.xs="29%"
                  class="flip-cards"
                  *ngFor="let a of cards2;index as inx"
                >
                  <mat-card
                    (@CardFlip.done)="transitionEnd(a,inx,2)"
                    class="card filp_container w-100"
                    [@CardFlip]="a.state"
                  >
                    <div
                      id="filp_card"
                      class="text-center shadow"
                      tabindex="0"
                      (click)="!imageSelect2?event(a,inx,2):''"
                      (keyup.enter)="!imageSelect2?event(a,inx,2):''"
                      [class.Flipped]="myClassbtn2[inx]"
                    >
                      <!-- <div class=" image-card" m-0> -->
                      <div
                        class="front_card front_card-ie lock-bg-light-blue"
                        flex
                        v-center
                        h-center
                        pointer  
                      >
                      <!-- [ngStyle]=" selectedcard2!='' && selectedcard2 != a.title ? {'opacity':'0.2','pointer-events': 'none'}:{}" -->
                        <!-- <img src="assets/images/lock.png"> -->
                        <i class="material-icons">lock</i>
                      </div>
                      <div
                        class="back_card back_card-ie center"
                        flex
                        v-center
                        h-center
                        pointer
                      >
                        <div class="desktop-design-show">
                          <img
                            *ngIf="a.icon"
                            src="assets/pathfinder/holland/{{a?.icon}}.svg"
                            class="challange-lock-icon"
                            alt="{{a?.displayName}}"
                          />
                          <!-- <div class="box-{{a.icon}}"></div> -->
                          <div class="opacity-text">
                            <p m-0>
                              <span>{{a?.displayName}}</span>
                            </p>
                          </div>
                        </div>
                        <div class="mobile-design-show w-100">
                          <p
                            m-0
                            class="text-center"
                            flex
                            v-center
                            ngStyle.xs="padding: 2px;"
                          >
                            <span class="text-left w-100" flex v-center
                              >{{a?.displayName}}</span
                            >
                            <img
                              *ngIf="a.icon"
                              src="assets/pathfinder/holland/{{a?.icon}}.svg"
                              alt="{{a?.displayName}}"
                            />
                          </p>
                        </div>
                      </div>
                      <!-- </div> -->
                    </div>
                  </mat-card>
                </div>

                <!-- <div fxFlex="30%" fxFlex.xs="29%" class="flip-cards " *ngFor="let a of cards;index as inx">
              <div class="f1_container">
                <button id="f1_card" class="text-center shadow" (click)="event(a,inx)" [class.Flipped]="myClassbtn[inx]">
                  <mat-card class="card front face">
                    <img src="assets/images/gloab.png">
                  </mat-card>
                  <mat-card class="card face back">
                    <img src="{{a.img}}">
                  </mat-card>
                </button>
              </div>
            </div> -->
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="30%" class="score-card">
          <div class="desktop-design-show h-100">
            <mat-card class="card text-center h-100">
              <mat-card-content class="card-body text-center">
                <div class="progress-bar-content">
                  <h1 text-color="primary" *ngIf="matched!=6">
                    {{keywords.dictionary.findMatches}}
                  </h1>
                  <h2 class="pf-challange-tries">
                    {{pathFinderHeading.dictionary.tries}}
                  </h2>
                  <div
                    class="score-text"
                    background-color="primary"
                    flex
                    v-center
                    h-center
                  >
                    {{attempts}}
                  </div>
                  <mat-card-title class="card-header">
                    <img
                      class="filled-star"
                      src="{{assetsURL}}star.svg"
                      width="30px"
                    />&nbsp;<b class="pf-challange-matches"
                      >{{pathFinderHeading?.dictionary?.matches}}</b
                    >
                  </mat-card-title>
                  <mat-progress-bar
                    mode="determinate"
                    value="{{progress}}"
                  ></mat-progress-bar>
                  <ul class="progress-bar">
                    <li text-color="primary" class="text-left">0</li>
                    <li text-color="primary" class="text-center">3</li>
                    <li text-color="primary" class="text-right">6</li>
                  </ul>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div
            class="progress-bar-content mobile-design-show"
            ngStyle.xs="padding:0;"
          >
            <mat-card-title class="card-header text-center">
              <img
                class="filled-star"
                src="{{assetsURL}}star.svg"
                width="24px"
              />&nbsp;<b class="pf-challange-matches"
                >{{pathFinderHeading?.dictionary?.matches}}</b
              >
            </mat-card-title>
            <mat-progress-bar
              mode="determinate"
              value="{{progress}}"
            ></mat-progress-bar>
            <ul class="progress-bar mobile-view">
              <li text-color="primary" class="text-left">0</li>
              <li text-color="primary" class="text-center">3</li>
              <li text-color="primary" class="text-right">6</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
