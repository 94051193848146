import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { AppComponent } from './app.component';
import { LandingComponent } from './modules/landing-module/landing-component';


import { AppRoutingModule } from './app-routing.module';
import { ExplorerModule } from './modules/explorer-module/explorer-module.module';
import { PathFinderModule } from './modules/pathfinder-module/pathfinder-module.module';
import { CareerTrekkerModule } from './modules/careertrekker-module/careertrekker-module.module';
import { ExplorerOccupationsModule } from './modules/explore-occs-module/explore-occs-module.module';
import { QuickpicModule } from './modules/Quickpic/quickpic.module';
import { CustomOccDetailComponent } from './modules/custom-occ-detail/custom-occ-component';

import { SharedModule } from './shared/common-module/shared-common-module';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { AuthInterceptor } from './shared/common-services/authentication-interceptors';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  getpagesReducer,
  getsiteReducer,
  getoccsIndexReducer,
  getoccsReducer,
  getattributesReducer,
  getfactoidsReducer,
  getAudioState,
  getProfileDataReducer,
  getUserProfileDataReducer,
  getOwnOccListReducer,
  getPathwayOccViewedReducer,
  getTrekkerOccViewedCareerReducer,
  getPathwayOccViewedCareerReducer,
  getOccThumbReducer,
  getTrekkerOccViewedReducer,
  getModuleCompletionDataReducer,
  getdefaultPathFinderTopSkillsReducer,
  getCareerCreatorAnswersReducer,
  getCategoriesReducer,
  getPathFinderChallengeReducer,
  getTrekkerAssessmentReducer,
  getQuickpicQuesIdReducer,
  getPathFinderquesReducer,
  getAttributesReducer,
  getavatarListReducer,
  getUserAvatarReducer,
  getAvatarProfileDataReducer,
  getCareerCreatorAttrReducer,
  getPathFinderAttrReducer,
  getScoutActivityOneReducer,
  getScoutActivityTwoReducer,
  getExplorerChallengeReducer,
  getdefaultExpTaskStoreState,
  getdefaultTrekkerTopSkillsReducer,
  getdefaultTrekkerChallengeReducer,
  getmodulesReducer,
  getcommunityOccsReducer,
  getDreamCareerListReducer,
  getScoutOccViewedReducer,
  getScoutOccViewedCareerReducer,
  getAddRibbonToCareer,
  getDeleteRibbonToCareer,
  getNavigatorOccViewedCareerReducer,
  getNavigatorOccViewedReducer,
  saveThoughtBoxForCareerCorner,
  getThoughtBoxForCareerCorner,
  getdefaultNavigatorResultStoreState,
  getNavigatorWorkValuesReducer,
  getNavigatorChallengeReducer,
  getExplorerOccViewedCareerReducer,
  getExplorerOccViewedListReducer,
  getNavigatorTieBreakerReducer,
} from './state-management/reducers/main-reducer';
import { EffectsModule } from '@ngrx/effects';
import { CTEffects } from './state-management/effects/main-effect';

import { StoreService } from './state-management/services/store-service';
import { ProfileComponent } from './modules/landing-module/profile-component';
import { CreateOwnOccComponent } from './modules/save-own-occ-module/save-own-occ-component';
import { CreateOwnOccAttributeComponent } from './modules/save-own-occ-module/save-own-attribute-component';
import { OrderModule } from 'ngx-order-pipe';
import { AudioService } from './shared/common-constants/audioLoading.service';
import { environment } from '../environments/environment';
import { CareerCornerModule } from './modules/careercorner-module/careercorner-module.module';
import { ScoutModule } from './modules/scout-module/scout-module.module';
import { DiscovererModule } from './modules/discoverer-module/discoverer-module.module';
import { NavigatorModule } from './modules/navigator-module/navigator-module.module';
/**import title service to update document title */
import { Title } from "@angular/platform-browser";

/**to replace ng4-loading-spinner */
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CreateOwnOccComponent,
    ProfileComponent,
    CustomOccDetailComponent,
    CreateOwnOccAttributeComponent,
  ],
  imports: [
    HammerModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    FlexLayoutModule,
    SharedModule.forRoot(),
    // Ng2DeviceDetectorModule.forRoot(),
    StoreModule.forRoot({
      audio: getAudioState,
      pages: getpagesReducer,
      sites: getsiteReducer,
      occIndex: getoccsIndexReducer,
      occs: getoccsReducer,
      attribute: getattributesReducer,
      factoid: getfactoidsReducer,
      profileImage: getProfileDataReducer,
      ownOccList: getOwnOccListReducer,
      completionList: getModuleCompletionDataReducer,
      cardsSelected: getdefaultPathFinderTopSkillsReducer,
      pathwayOccViewedList: getPathwayOccViewedReducer,
      trekkerOccViewedList: getTrekkerOccViewedReducer,
      scoutOccViewedList: getScoutOccViewedReducer,
      userProfileData: getUserProfileDataReducer,
      pathwayOccViewedCareers: getPathwayOccViewedCareerReducer,
      OccThumbList: getOccThumbReducer,
      trekkerOccViewedCareers: getTrekkerOccViewedCareerReducer,
      scoutOccViewedCareers: getScoutOccViewedCareerReducer,
      navigatorOccViewedCareers: getNavigatorOccViewedCareerReducer,
      explorerOccsViewedCareer: getExplorerOccViewedCareerReducer,
      explorerOccsViewedList: getExplorerOccViewedListReducer,
      navigatorOccViewedList: getNavigatorOccViewedReducer,
      careerCreaterVals: getCareerCreatorAnswersReducer,
      attributesData: getAttributesReducer,
      categoriesData: getCategoriesReducer,
      trekkerAssessmentques: getTrekkerAssessmentReducer,
      quickpicAssessment: getQuickpicQuesIdReducer,
      pathFinderques: getPathFinderquesReducer,
      pathFinderChallenge: getPathFinderChallengeReducer,
      emojiFaceData: getavatarListReducer,
      userEmojiData: getUserAvatarReducer,
      avatarProfileImage: getAvatarProfileDataReducer,
      careerCreaterAttrVals: getCareerCreatorAttrReducer,
      pathFinderAttrVals: getPathFinderAttrReducer,
      scoutActivityOneData: getScoutActivityOneReducer,
      scoutActivityTwoData: getScoutActivityTwoReducer,
      explorerChallenge: getExplorerChallengeReducer,
      explorerTask: getdefaultExpTaskStoreState,
      navigatorResult: getdefaultNavigatorResultStoreState,
      navigatorWorkValuesData: getNavigatorWorkValuesReducer,
      navigatorTieData: getNavigatorTieBreakerReducer,
      navigatorChallengeData: getNavigatorChallengeReducer,
      trekkerCardsSelected: getdefaultTrekkerTopSkillsReducer,
      trekkerChallengeQues: getdefaultTrekkerChallengeReducer,
      modules: getmodulesReducer,
      communityOccs: getcommunityOccsReducer,
      dreamCareerList: getDreamCareerListReducer,
      addRibbonValue: getAddRibbonToCareer,
      deleteRibbonValue: getDeleteRibbonToCareer,
      saveThoughtValue: saveThoughtBoxForCareerCorner,
      getThoughtValue: getThoughtBoxForCareerCorner,
    }),
    StoreDevtoolsModule.instrument({
      name: 'Careertrek DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([CTEffects]),
    // Ng4LoadingSpinnerModule.forRoot(),
    // Ng2DeviceDetectorModule.forRoot(),
    AppRoutingModule,
    ExplorerModule,
    PathFinderModule,
    OrderModule,
    QuickpicModule,
    CareerTrekkerModule,
    ExplorerOccupationsModule,
    CareerCornerModule,
    ScoutModule,
    DiscovererModule,
    NavigatorModule,
    Ng2CarouselamosModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    StoreService,
    AudioService,
    Title,
    {
      provide: 'OCC_CARD_URL',
      useValue: environment.OCC_CARD_URL,
    },
    {
      provide: 'OCC_LARGE_CARD_URL',
      useValue: environment.OCC_LARGE_DIMENSIONS_URL,
    },
    {
      provide: 'ASSETS_MEDIA_URL',
      useValue: environment.ASSETS_MEDIA_URL,
    },
    {
      provide: 'ASSETS_MEDIA_PNG_URL',
      useValue: environment.ASSETS_MEDIA_PNG_URL,
    },
  ],
  exports: [Ng2CarouselamosModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
