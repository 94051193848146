import { Store } from "@ngrx/store";
import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  ElementRef,
  HostListener,
  OnDestroy,
  Inject,
} from "@angular/core";
import { Observable, fromEvent, takeUntil, pairwise, switchMap } from 'rxjs';
import { OnLoadPopUpService } from "../../shared/common-services/onloadpopup.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { EventdispatchService } from "../../shared/common-modal/eventdispach.service";
import { DialogopenService } from "../../shared/common-modal/modalpopup.service";
import { ApiCallClass } from "../../shared/common-services/api-call-model";
import { ApiCallService } from "../../shared/common-services/api-call-service";
import {
  AudioState,
  CareerCreatorAttrState,
  defaultAttributeDataState,
  PageDataState,
  PathFinderAttrState,
} from "../../state-management/state/main-state";
import { RouteUrls } from "../../shared/common-constants/route.constants";
import { environment } from "../../../environments/environment";
import { Utilities } from "../../shared/common-services/utilities.service";
import {
  Next,
  Pointer,
  Pointer_mobile,
} from "../../shared/common-animations/CT_animations";
import { take, takeWhile } from "rxjs/operators";
import { AudioService } from "../../shared/common-constants/audioLoading.service";

@Component({
  selector: "app-create-own-occ-attribute",
  templateUrl: "./save-own-attribute-layout.html",
  styles: [],
  animations: [Next, Pointer, Pointer_mobile],
})
export class CreateOwnOccAttributeComponent implements OnInit, OnDestroy {
  assetsUrl: string;
  showMatStepper: string;
  //The completed variable to set to true, to turn on the flip animation of the button
  completed: boolean;
  keyWords: any = [];
  attrData: any;
  id: any;
  cardsSelected = [{ 'name': '', 'icon': '', 'hollandCode': '', 'type': '', 'displayName': '' }, { 'name': '', 'displayName': '', 'icon': '', 'hollandCode': '', 'type': '' }];
  i: number = 0;
  theme_name: string;
  lang: string;
  languageChanged: boolean =false;
  savedEntireOcc = false;
  startInx = 0;
  optAniInx = 0;
  textAniInx = 0;
  medium = false;
  prevData = { 'title': '' };
  prevweight = '';
  high = false;
  low = false;
  resp1: any = [];
  highData: any = [];
  lowData: any = [];
  mediumData: any = [];
  module: any = [];
  savedDrawing = false;
  startSketch = false;
  name = '';
  canvaswidth = 600;
  canvasheight = 300;
  describeThoughts = '';
  colorsarray = [[], [], [], [], [], []];
  @ViewChild('canvas') public canvas: ElementRef;
  font = [1, 2, 3, 4, 5, 7, 9, 12, 16, 18, 20];
  imgageSrc = '';
  colorCode = '#000';
  clickcolor;
  cPushArray = new Array();
  cStep = -1;
  audioState = 'stoped';
  audionotfound = false;
  private cx: CanvasRenderingContext2D;
  buttonsText: any;
  compActive = true;
  instructionText: any = [];
  peopleTick = false;
  ideasTick = false;
  thingsTick = false;
  workValueType: string;
  pageData: any;
  language: string;
  resp1Data: any;
  categories = [];
  images = ['People', 'Ideas', 'Things'];
  categoryCard = [];
  isCareerShared: any;
  attrLength: any;


  constructor(
    private apiCall: ApiCallService,
    private apiModel: ApiCallClass,
    private apiJson: ApiCallService,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogopenService,
    public eventService: EventdispatchService,
    private CareerCreatorAttrStore: Store<CareerCreatorAttrState>,
    private pathFinderAttrStore: Store<PathFinderAttrState>,
    private router: Router,
    public snackBar: MatSnackBar,
    private store: Store<PageDataState>,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    @Inject("ASSETS_MEDIA_URL") private assetsMediaUrl: string
  ) {
    this.assetsUrl = assetsMediaUrl;
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("pageTitle", "6");
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
    this.buttonsText = JSON.parse(sessionStorage.getItem("GLOBAL_SETTINGS"));
    this.keyWords = this.oninitpopupservice.getKeyWordData("CUSTOM_OCCUPATION");
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/createOccAttribute") {
          if (e.type === "languageChanged") {
            this.cardsSelected = [
              {
                name: "",
                icon: "",
                hollandCode: "",
                type: "",
                displayName: "",
              },
              {
                name: "",
                displayName: "",
                icon: "",
                hollandCode: "",
                type: "",
              },
            ];
            this.attrData = [];
            /* */
            //After changing the language need to do empty following arrays to restart assessment
            this.highData = [];
            this.mediumData = [];
            this.lowData = [];
            this.languageChanged = true;
            /* */
            this.lang = sessionStorage.getItem("session_token");
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.stopAudio();
            this.unsavedChanges();
          }
        }
      });

    if (this.lang === "" || this.lang == null) {
      this.lang = sessionStorage.getItem("session_token");
    }
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          this.audioState = result.audioState;
          this.audionotfound = result.audionotfound;
        }
      });
  }

  ngOnInit() {
    //Setting value to 'this.keywords' in pages subscription to avoid the text mismatch on language change.
    this.store.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
      this.buttonsText = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
      this.keyWords = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
      this.instructionText = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION_ASSIGNED');
    });
    let intialVal = true;
    this.CareerCreatorAttrStore.select('careerCreaterAttrVals').pipe(takeWhile(() => this.compActive)).subscribe(careerCreatorAttrResp => {
      if (careerCreatorAttrResp['careerCreaterAttrVals'].moduleName == 'explorer' || careerCreatorAttrResp['careerCreaterAttrVals'].moduleName == 'careertrekker') {
        if (careerCreatorAttrResp['careerCreaterAttrVals'].highData ||
          careerCreatorAttrResp['careerCreaterAttrVals'].mediumData ||
          careerCreatorAttrResp['careerCreaterAttrVals'].lowData
        ) {
          this.highData = careerCreatorAttrResp['careerCreaterAttrVals'].highData;
          this.mediumData = careerCreatorAttrResp['careerCreaterAttrVals'].mediumData;
          this.lowData = careerCreatorAttrResp['careerCreaterAttrVals'].lowData;
          //Clicking the back button from 'custom-occ-detail' page, if all attributes are assigned then based on this 'intialVal' showing the arrow_forward button.
          if (intialVal == true) {
            this.savedEntireOcc = false;
          }
        }
      }
      intialVal = false;
    });
    this.startOwnOCC()
    this.pathFinderAttrStore.select('pathFinderAttrVals').pipe(takeWhile(() => this.compActive)).subscribe(pathFinderAttrResp => {
      if (pathFinderAttrResp['pathFinderAttrVals'].moduleName == 'pathfinder') {
        this.cardsSelected = pathFinderAttrResp['pathFinderAttrVals'].cardsSelected;
      }
    });
    this.apiModel.moduleName = "Favoccs";
    this.apiModel.endUrl = "/occs/categories";
    this.apiModel.method = "GET";
    try {
      this.apiCall
        .getData([this.apiModel])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((resp) => {
          this.categories = JSON.parse(resp);
          if (this.categories) {
            this.categories.forEach((element, inx) => {
              element["icon"] = this.images[inx];
            });
          }
          this.categoryCard.push(this.categories)
        });
    } catch (e) {
      this.utils.handleError(`save-own-attribute-component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }

  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp(
          "CUSTOM_OCCUPATION",
          this.module
        );
      }.bind(this)
    );
  }

  ngAfterViewInit() {
    this.infopopup();
  }
  stopAudio() {  //to stop current playing audio
    this.audioState = "stoped";
    this.audionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }
  startOwnOCC() {
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.module = params["module"];
      this.showMatStepper = params["stepper"];
      if (params["id"] != undefined && params["id"] != null) {
        this.id = params["id"];
        this.savedDrawing = true;
        this.apiModel.moduleName = "saveourownocc";
        this.apiModel.endUrl = "user/custom-occ/" + this.id;
        this.apiModel.sessionID = sessionStorage.getItem("session_token");
        this.apiModel.method = "GET";
        try {
          this.apiCall
            .getData([this.apiModel])
            .pipe(takeWhile(() => this.compActive))
            .subscribe((resp) => {
              this.keyWords =
                this.oninitpopupservice.getKeyWordData("CUSTOM_OCCUPATION");
              const data = JSON.parse(resp);
              this.imgageSrc = data.media.images.sprite;
              this.name = data.title;
              this.describeThoughts = data.description;
              this.isCareerShared = data.shared;
              this.attrData = data.attributes;
              this.keyWords =
                this.oninitpopupservice.getKeyWordData("CUSTOM_OCCUPATION");
            });
        } catch (e) {
          this.utils.handleError(`save-own-attribute-component.ts startOwnOCC: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
      }
      this.apiModel.moduleName = "saveourownocc";
      this.apiModel.endUrl = "attributes";
      this.apiModel.sessionID = sessionStorage.getItem("session_token");
      this.apiModel.method = "GET";
      try {
        this.apiCall
          .getData([this.apiModel])
          .pipe(takeWhile(() => this.compActive))
          .subscribe((resp) => {
            this.keyWords =
              this.oninitpopupservice.getKeyWordData("CUSTOM_OCCUPATION");
            const data = JSON.parse(resp);
            let type = "";
            if (this.module == "explorer") {
              type = "characteristic";
            } else if (this.module == "pathfinder") {
              type = "hollandcode";
            } else {
              type = "skill";
            }
            this.apiModel.moduleName = 'saveourownocc';
            this.apiModel.endUrl = 'attributes';
            this.apiModel.sessionID = sessionStorage.getItem('session_token');
            this.apiModel.method = 'GET';
            try {
              this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
                this.keyWords = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
                const data = JSON.parse(resp);
                let type = '';
                if (this.module == 'navigator') {
                  type = 'value'
                } else if (this.module == 'explorer') {
                  type = 'characteristic';
                } else if (this.module == 'pathfinder') {
                  type = 'hollandcode';
                } else {
                  type = 'skill';
                }
                this.resp1 = [];
                for (let i = 0; i < data.length; i++) {
                  if (data[i].type == type) {
                    this.resp1.push(data[i]);
                  }
                }
                if (this.resp1Data) {
                  this.resp1 = this.resp1Data
                }
                this.attrLength = this.resp1.length;
                this.checkStoreData();
                this.dialogService.hideLoading();
                this.keyWords = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION');
              });
            } catch (e) {
              this.utils.handleError(`save-own-attribute-component.ts saveyourownocc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
            }
            this.dialogService.hideLoading();
            this.keyWords =
              this.oninitpopupservice.getKeyWordData("CUSTOM_OCCUPATION");
          });
      } catch (e) {
        this.utils.handleError(`save-own-attribute-component.ts saveyourownocc get: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
      this.optAniInx++;
    });
  }

  checkStoreData() {
    this.CareerCreatorAttrStore.select('careerCreaterAttrVals').pipe(takeWhile(() => this.compActive)).subscribe(careerCreatorAttrResp => {
      if (careerCreatorAttrResp['careerCreaterAttrVals'].moduleName == 'explorer' || careerCreatorAttrResp['careerCreaterAttrVals'].moduleName == 'careertrekker') {
        if (careerCreatorAttrResp['careerCreaterAttrVals'].highData ||
          careerCreatorAttrResp['careerCreaterAttrVals'].mediumData ||
          careerCreatorAttrResp['careerCreaterAttrVals'].lowData
        ) {
          this.high = false;
          this.medium = false;
          this.low = false;
          this.highData = careerCreatorAttrResp['careerCreaterAttrVals'].highData;
          this.mediumData = careerCreatorAttrResp['careerCreaterAttrVals'].mediumData;
          this.lowData = careerCreatorAttrResp['careerCreaterAttrVals'].lowData;
          const lengthAttr = this.highData.length + this.mediumData.length + this.lowData.length;
          let high = []
          this.highData.forEach(obj => {
            let element = this.resp1.find(item => item.attributeId == obj.attributeId)
            high.push(element)
            this.highData = high;
          })
          let medium = []
          this.mediumData.forEach(obj => {
            let element = this.resp1.find(item => item.attributeId == obj.attributeId)
            medium.push(element)
            this.mediumData = medium;
          })
          let low = []
          this.lowData.forEach(obj => {
            let element = this.resp1.find(item => item.attributeId == obj.attributeId)
            low.push(element)
            this.lowData = low
          })
          this.startInx = lengthAttr;
          //Clicking the back button from 'custom-occ-detail' page, if all attributes are assigned then based on this 'intialVal' showing the arrow_forward button.
          if (lengthAttr == this.attrLength) {
            this.savedEntireOcc = true;
          }
          if(this.languageChanged){
            this.startInx = lengthAttr;
            this.languageChanged = false;
            this.highData.forEach(item => {
              this.resp1 = this.resp1.filter(obj => obj.attributeId != item.attributeId);
              this.resp1.unshift(item)
            })
            this.mediumData.forEach(item => {
              this.resp1 = this.resp1.filter(obj => obj.attributeId != item.attributeId);
              this.resp1.unshift(item)
            })
            this.lowData.forEach(item => {
              this.resp1 = this.resp1.filter(obj => obj.attributeId != item.attributeId);
              this.resp1.unshift(item)
            })
          }
        }
      }
    });
  }

  unsavedChanges() {
    if (this.cardsSelected[0].name == "" && this.cardsSelected[1].name == "") {
      this.utils.backRoute();
    } else if (
      this.cardsSelected[0].name !== "" ||
      this.cardsSelected[1].name !== ""
    ) {
      if (this.cardsSelected[1].name !== "") {
        this.cardsSelected[1] = {
          name: "",
          icon: "",
          hollandCode: "",
          displayName: "",
          type: "",
        };
        this.i = 1;
      } else {
        this.cardsSelected[0] = {
          name: "",
          icon: "",
          hollandCode: "",
          displayName: "",
          type: "",
        };
        this.i = 0;
      }
    }
  }
  pathfinderCardSelect(item) {
    this.stopAudio();
    if (this.i === 0 && this.cardsSelected[this.i + 1] !== item) {
      //Without this.cardsSelected[this.i + 1] !== item it is possible to add the same attribute twice to the card selection.
      this.cardsSelected[this.i] = item;
      this.i = 1;
    } else if (this.i === 1 && this.cardsSelected[this.i - 1] !== item) {
      //Without this.cardsSelected[this.i - 1] !== item it is possible to add the same attribute twice to the card selection.
      this.cardsSelected[this.i] = item;
      this.i = 0;
    }
    this.pathFinderAttrStore.dispatch({
      type: "PATHFINDER_ATTR_VAL",
      payload: {
        pathFinderAttrVals: {
          cardsSelected: this.cardsSelected,
          moduleName: this.module,
        },
      },
    });
  }
  dataPost() {
    if (this.id == undefined || this.id == null) {
      this.apiModel.moduleName = "custom-occ";
      this.apiModel.endUrl = "user/custom-occ/create";
      this.apiModel.sessionID = sessionStorage.getItem("session_token");
      this.apiModel.method = "POST";
      this.apiModel.data = {
        title: this.name,
        description: this.describeThoughts,
        shared: this.isCareerShared,
        media: {
          images: {
            title: "string",
            sprite: this.imgageSrc,
          },
        },
        tasks: {
          title: "string",
          items: ["string"],
        },
        attributes: [{}],
      };
    } else {
      this.apiModel.moduleName = "custom-occ";
      this.apiModel.endUrl = "user/custom-occ/update/" + this.id;
      this.apiModel.sessionID = sessionStorage.getItem("session_token");
      this.apiModel.method = "PUT";
      this.apiModel.data = {
        title: this.name,
        description: this.describeThoughts,
        shared: this.isCareerShared,
        media: {
          images: {
            title: "string",
            sprite: this.imgageSrc,
          },
        },
        tasks: {
          title: "string",
          items: ["string"],
        },
        attributes: this.attrData,
      };
    }
  }
  saveDrawing() {
    this.dataPost();
    try {
      this.apiJson
        .getData([this.apiModel])
        .pipe(take(1))
        .subscribe((resp) => {
          if (resp != undefined && resp != null) {
            const data = JSON.parse(resp);
            this.id = data.occId;
            this.attrData = data.attributes;
          }
        });
    } catch (e) {
      this.utils.handleError(`save-own-attribute-component.ts saveDrawing: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  dataUpload() {
    let type = "";
    const attributes = [];
    if (this.module == "explorer") {
      type = "characteristic";
    } else if (this.module == "pathfinder") {
      type = "hollandcode";
    } else if (this.module == "navigator") {
      type = "value";
    } else if (this.module == "scout") {
      type = "group";
    } else {
      type = "skill";
    }
    for (let i = 0; i < this.attrData.length; i++) {
      if (this.attrData[i].type != type) {
        attributes.push({
          type: this.attrData[i].type,
          name: this.attrData[i].name,
          weight: this.attrData[i].weight,
          icons: this.attrData[i].icon,
        });
      }
    }
    if (this.module == "explorer" || this.module == "careertrekker") {
      for (let i = 0; i < this.highData.length; i++) {
        attributes.push({
          type: this.highData[i].type,
          name: this.highData[i].name,
          weight: "high",
          icons: this.highData[i].icon,
        });
      }
      for (let i = 0; i < this.mediumData.length; i++) {
        attributes.push({
          type: this.mediumData[i].type,
          name: this.mediumData[i].name,
          weight: "medium",
          icons: this.mediumData[i].icon,
        });
      }
      for (let i = 0; i < this.lowData.length; i++) {
        attributes.push({
          type: this.lowData[i].type,
          name: this.lowData[i].name,
          weight: "low",
          icons: this.lowData[i].icon,
        });
      }
    }
    if (this.module == "pathfinder") {
      attributes.push({
        type: "hollandcode",
        name: this.cardsSelected[0].hollandCode,
        weight: "NA",
        icons: this.cardsSelected[0].icon,
      });
      attributes.push({
        type: "hollandcode",
        name: this.cardsSelected[1].hollandCode,
        weight: "NA",
        icons: this.cardsSelected[1].icon,
      });
    }
    if (this.module == "navigator") {
      attributes.push({
        type: "value",
        name: this.cardsSelected[0].name,
        weight: "NA",
        icons: this.cardsSelected[0].icon,
      });
      attributes.push({
        type: "value",
        name: this.cardsSelected[1].name,
        weight: "NA",
        icons: this.cardsSelected[1].icon,
      });
    }
    if (this.module == "scout") {
      attributes.push({
        type: "group",
        name: this.workValueType,
        weight: 'string',
        icons: 'string',
      });
    }
    this.attrData = attributes;
    this.dataPost();
    try {
      this.apiJson
        .getData([this.apiModel])
        .pipe(take(1))
        .subscribe((resp) => {
          if (resp != undefined && resp != null) {
            resp = JSON.parse(resp);
            //If theme_color is empty, The header color of Career Creator is coming in green color, when we click on back button at Career creator Report page.
            sessionStorage.setItem("pageTitle", "");
            this.audioStore.dispatch({
              type: "SET_AUDIO_STOPED",
            });
            const event = document.createEvent("CustomEvent");
            event.initEvent("themeChanged", true, true);
            this.eventService.dispatch(event);
            this.eventService.dispatchAudioStop(event);
            this.router.navigate(["/app/custom-occ-detail"], {
              relativeTo: this.activatedRoute,
              queryParams: {
                itemId: resp.occId,
              },
            });
          }
        });
    } catch (e) {
      this.utils.handleError(`save-own-attribute-component.ts dataUpload: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }
  weightSelection(weight, item) {
    if (this.prevData.title == item.title) {
      if (this.prevweight == "high") {
        this.highData.pop();
        this.textAniInx--;
      }
      if (this.prevweight == "medium") {
        this.mediumData.pop();
        this.textAniInx--;
      }
      if (this.prevweight == "low") {
        this.lowData.pop();
        this.textAniInx--;
      }
    }
    this.prevData = item;
    this.prevweight = weight;
    if (weight == "high") {
      this.highData.push(item);
      this.textAniInx++;
    }
    if (weight == "medium") {
      this.mediumData.push(item);
      this.textAniInx++;
    }
    if (weight == "low") {
      this.lowData.push(item);
      this.textAniInx++;
    }
  }
  nextAttribute() {
    this.completed = !this.completed;
    if (this.startInx < this.resp1.length - 1) {
      this.startInx++;
      this.optAniInx++;
      this.high = false;
      this.low = false;
      this.medium = false;
    } else {
      this.savedEntireOcc = true;
    }
    this.CareerCreatorAttrStore.dispatch({
      type: "CAREER_CREATOR_ATTR_VAL",
      payload: {
        careerCreaterAttrVals: {
          highData: this.highData,
          mediumData: this.mediumData,
          lowData: this.lowData,
          moduleName: this.module,
        },
      },
    });
  }
  deleteEntireOcc() {
    this.dialogService.quickpicDeletePopup({
      activeset: "",
      set: this.keyWords.dictionary.delete + " " + this.name + " ?",
    });
    this.dialogService.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result === true) {
          this.apiModel.moduleName = "OwnOcc";
          this.apiModel.endUrl = "user/custom-occ/delete/" + this.id;
          this.apiModel.sessionID = sessionStorage.getItem("session_token");
          this.apiModel.method = "DELETE";
          try {
            this.apiCall
              .getData([this.apiModel])
              .pipe(take(1))
              .subscribe((resp) => { });
            this.router.navigateByUrl("/app/landing");
          } catch (e) {
            this.utils.handleError(`save-own-attribute-component.ts OwnOcc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }
  audioClick(text, data) {
    this.audioService.audioFunction(text, data);
  }

  ngOnDestroy() {
    this.compActive = false;
  }

  setScoutCareer(type) {
    if (type.icon === 'People') {
      this.peopleTick = true;
      this.ideasTick = false;
      this.thingsTick = false;

      this.categoryCard = [];
      this.categoryCard.push(type);
    }
    else if (type.icon === 'Ideas') {
      this.peopleTick = false;
      this.ideasTick = true;
      this.thingsTick = false;

      this.categoryCard = [];
      this.categoryCard.push(type);
    }
    else if (type.icon === 'Things') {
      this.peopleTick = false;
      this.ideasTick = false;
      this.thingsTick = true;

      this.categoryCard = [];
      this.categoryCard.push(type);
    }
    this.workValueType = type.icon;
  }

  navigatorCardselection(item) {
    if (this.i === 0 && this.cardsSelected[this.i + 1] !== item) {
      //Without this.cardsSelected[this.i + 1] !== item it is possible to add the same attribute twice to the card selection.
      this.cardsSelected[this.i] = item;
      this.i = 1;
    } else if (this.i === 1 && this.cardsSelected[this.i - 1] !== item) {
      //Without this.cardsSelected[this.i - 1] !== item it is possible to add the same attribute twice to the card selection.
      this.cardsSelected[this.i] = item;
      this.i = 0;
    }
  }
}
