<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{pathFinderAssess?.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="headerAudioState"
        (click)="audioClick('inside',pathFinderAssess,'headerAudio')"></app-audio-button>
      <!-- <button id="btn1" mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && headerAudioState=='warning'" 
        (click)="audioClick('inside',pathFinderAssess,'headerAudio')">
        <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && headerAudioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && headerAudioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && headerAudioState=='warning'">warning</i> 
        </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="pathfinder-questions content-box" ngStyle.xs="padding:0;">
      <div class="question-icon" ngStyle.xs="padding:0;" *ngIf="defaultdata!=undefined">
        <div class="desktop-design-show">
          <div flex v-center h-center>
            <img *ngIf="defaultdata?.icon" src="assets/pathfinder/holland/{{defaultdata?.icon}}.svg"
              alt="{{defaultdata?.displayName}}" class="w-50 pathfinder-icon" />
            <h1 m-0 class="path-finder-name">
              &nbsp;{{defaultdata?.displayName}}
            </h1>
            <app-audio-button [audionotfound]="audionotfound" [audioState]="questionsAudioState"
              (click)="audioClick('inside',defaultdata,'questionsAudio')"></app-audio-button>
            <!-- <button id="btn2" mat-raised-button color="primary" [disabled]="audionotfound==false && questionsAudioState=='warning'"
              class="audio-box volume-up"  (click)="audioClick('inside',defaultdata,'questionsAudio')" flex v-center>
              <i class="material-icons" *ngIf="audionotfound==false && questionsAudioState=='stoped'">volume_up</i>
              <i class="material-icons" *ngIf="audionotfound==false && questionsAudioState=='playing'">stop</i>
              <div class="audio-loading" *ngIf="audionotfound==false && questionsAudioState=='loading'"></div>
              <i class="material-icons" *ngIf="audionotfound==true && questionsAudioState=='warning'">warning</i>
            </button> -->
          </div>
          <p>{{defaultdata?.description}}</p>
        </div>
        <div class="mobile-design-show moblie-item-text">
          <div class="mobile-heading" flex v-center h-center>
            <div flex v-center h-center class="w-100">
              <img *ngIf="defaultdata.icon" src="assets/pathfinder/holland/{{defaultdata.icon}}.svg"
                alt="{{defaultdata.displayName}}" class="w-20" />
              <div mr-10>
                <h1 m-0>{{defaultdata?.displayName}}</h1>
                <hr class="mobile-design-show" />
              </div>
              <app-audio-button [audionotfound]="audionotfound" [audioState]="questionsAudioState"
                (click)="audioClick('inside',defaultdata,'questionsAudio')"></app-audio-button>
              <!-- <button mat-raised-button (click)="audioClick('inside',defaultdata,'questionsAudio')" [disabled]="audionotfound==false && questionsAudioState=='warning'"
              color="primary" class="audio-box volume-up">
              <i class="material-icons" *ngIf="audionotfound==false && questionsAudioState=='stoped'">volume_up</i>
              <i class="material-icons" *ngIf="audionotfound==false && questionsAudioState=='playing'">stop</i>
              <div class="audio-loading" *ngIf="audionotfound==false && questionsAudioState=='loading'"></div>
              <i class="material-icons" *ngIf="audionotfound==true && questionsAudioState=='warning'">warning</i>
            </button> -->
            </div>
          </div>
          <p>{{defaultdata?.description}}</p>
          <mat-card class="card">
            <mat-card-title background-color="primary" class="card-header text-left">{{defaultdata?.displayName}}
            </mat-card-title>
            <mat-card-content class="card-body text-center" flex v-center h-center ngStyle.xs="justify-content:left;">
              <mat-list class="m-auto" ngStyle.xs="padding-top:0px; width:90%;">
                <mat-list-item *ngFor="let data of defaultoccsdata;let i=index"
                  ngStyle.xs="border-bottom:1px solid #d6cbcb;height:47px">
                  <div matListAvatar class="list-circle-icon" flex v-center h-center>
                    <img *ngIf="defaultdata.icon" src="assets/pathfinder/holland/{{defaultdata?.icon}}.svg"
                      class="pf-w-40" alt="{{data?.title}}" />
                  </div>
                  <h4 mat-line class="text-left" text-color="primary" (click)="modalOpen(data,i)">
                    {{data?.title}}
                  </h4>
                </mat-list-item>
                <!-- <mat-divider></mat-divider> -->
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
        <button mat-button background-color="primary" class="box-shadow pf-assessment-save-btn"
          (click)="nextQuestion('like')"
          [ngClass]="((selectedOption == 'like') && (nextIcon == 'yes'))?'question-active':''">
          <img src="{{assetsURL}}thumbs-up-outline-white.svg" alt="thumb-up icon" />
          <span class="save-text">{{keywords?.dictionary?.likeMe}}</span>
        </button>
        <button mat-button background-color="primary" class="box-shadow pf-assessment-save-btn"
          (click)="nextQuestion('unlike')"
          [ngClass]="((selectedOption == 'unlike') && (nextIcon == 'yes'))?'question-active':''">
          <img src="{{assetsURL}}thumbs-down-outline-white.svg" alt="thumb-down icon" />
          <span class="save-text">{{keywords?.dictionary?.notLikeMe}}</span>
        </button>
      </div>
      <div class="desktop-design-show" *ngIf="defaultoccsdata!=undefined&&defaultoccsdata.length<=3">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px">
          <div fxFlex="28%" fxFlex.sm="32%" fxFlex.lg="24%" fxFlex.xl="24%" class="pathfinder-cards"
            *ngFor="let data of defaultoccsdata;index as i">
            <mat-card class="card" tabindex="0" pointer flex v-center h-center *ngIf="data!=null"
              (click)="modalOpen(data,i)" (keyup.enter)="modalOpen(data,i)">
              <div>
                <img src="{{occCardAPIUrl}}{{data?.media?.images?.card}}" />
              </div>
              <div class="active-card">
                <p m-0 [ngClass]="{'active-card-bar': data.opened === true}">
                  <span>{{data?.title}}</span>
                </p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div *ngIf="nextIcon == 'yes'">
        <button class="next-level" mat-fab color="accent" (click)="save(selectedOption)" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>