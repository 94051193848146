import { animate, state, style, transition, trigger, query, keyframes, stagger } from '@angular/animations';
export const Pointer =
  trigger('pointerstate', [
    transition(':increment', [
      query('.animate', stagger('260ms', [
        animate('0.6s', keyframes([style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 0 }),
        style({ opacity: 1, transform: 'translateY(-8px) translateX(0)', offset: 0.5 }),
        style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
          // style({ opacity: 1, transform: 'translateY(5p vcx) translateX(0)', offset: 0.8 }),
          // style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
        ]))]), { optional: true }),
    ]),
    transition(':decrement', [
      query('.animate', stagger('260ms', [
        animate('0.6s', keyframes([style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 0 }),
        style({ opacity: 1, transform: 'translateY(-8px) translateX(0)', offset: 0.5 }),
        style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
          // style({ opacity: 1, transform: 'translateY(5px) translateX(0)', offset: 0.8 }),
          // style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
        ]))]), { optional: true }),
    ]),
  ]);
  export const Pointer_mobile =
  trigger('pointerstate_mobile', [
    transition(':increment', [
      query('.animate_mobile', stagger('260ms', [
        animate('0.6s', keyframes([style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 0 }),
        style({ opacity: 1, transform: 'translateY(-8px) translateX(0)', offset: 0.5 }),
        style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
          // style({ opacity: 1, transform: 'translateY(5p vcx) translateX(0)', offset: 0.8 }),
          // style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
        ]))]), { optional: true }),
    ]),
    transition(':decrement', [
      query('.animate_mobile', stagger('260ms', [
        animate('0.6s', keyframes([style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 0 }),
        style({ opacity: 1, transform: 'translateY(-8px) translateX(0)', offset: 0.5 }),
        style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
          // style({ opacity: 1, transform: 'translateY(5px) translateX(0)', offset: 0.8 }),
          // style({ opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1 }),
        ]))]), { optional: true }),
    ]),
  ]);
export const Next =
  trigger('flipAnimation', [
    transition('* <=> *', animate('800ms', keyframes([
      style({ opacity: 1, transform: 'rotateY(0deg)', offset: 0 }),
      style({ opacity: 1, transform: 'rotateY(180deg)', offset: 0.5 }),
      style({ opacity: 1, transform: 'rotateY(0deg)', offset: 1 }),
    ]))),
  ]);
export const Jump =
  trigger('jump', [
    transition('true => false', [
      animate('3s', keyframes([
        style({ opacity: 0, transform: 'rotateZ(0deg)', offset: 0 }),
        style({ opacity: 0.5, transform: 'rotateZ(180deg)', offset: 0.5 }),
        style({ opacity: 1, transform: 'rotateZ(0deg)', offset: 1 }),
      ]))
    ])
  ]);
// export const Delay =
//   trigger('delay', [
//     transition(':increment',
//       animate('3s', keyframes([
//         style({ opacity: 0, offset: 0 }),
//         style({ opacity: 0, offset: 0.2 }),
//         style({ opacity: 0, offset: 0.4 }),
//         style({ opacity: 0, offset: 0.8 }),
//         style({ opacity: 1, offset: 1 }),
//       ])))
//   ]);
export const GridAnimation = trigger('animate', [
  transition('*<=>*', [
    query('.x',
      stagger('1000ms',
        animate('10000ms',
          keyframes([
            style({ transform: 'rotateY(360deg)', offset: 0.3 }),
            style({ opacity: 0.4, offset: 0.4 }),
          ])
        )
      ), { optional: true }
    )
  ])
]);
export const VibrateAnimation = trigger('vibration', [
  transition('*<=>*', [
    query('.shake',
      stagger('100ms',
        animate('200ms',
          keyframes([
            style({ transform: 'translateX(5px)', offset: 0.2 }),
            style({ transform: 'translateX(0px)', offset: 0.4 }),
            style({ transform: 'translateX(-5px)', offset: 0.6 }),
            style({ transform: 'translateX(0px)', offset: 0.8 })
          ])
           )
      ), { optional: true }
    )
  ])
]);
export const ANIMATION_MOVE_Y =
  trigger('Animation_y', [
    transition('true=>false', [
      query('.animation-Y',
        stagger('0ms',
          animate('2000ms',
            keyframes([
              style({ transform: 'translateY(-100%)', offset: 0 }),
              style({ transform: 'translateY(0%)', offset: 1 }),
            ])
          )
        ), { optional: true }
      ),
    ])
  ]);
    export const SIDEMENUAnimation = trigger('sidemenu', [
      transition('* => true', [
        query('.sidemenu',
          stagger('200ms',
            animate('200ms',
              keyframes([
                style({ transform: 'translateY(0) translateX(100%)', offset: 0 }),
                style({ transform: 'translateY(0) translateX(75%)', offset: 0.4 }),
                style({ transform: 'translateY(0) translateX(50%)', offset: 0.6 }),
                style({ transform: 'translateY(0) translateX(25%)', offset: 0.8 }),
                style({ transform: 'translateY(0) translateX(0%)', offset: 1.0 })
              ])
            )
          ), { optional: true }
        )
      ]),
      transition('* => false', [
        query('.sidemenu',
          stagger('200ms',
            animate('200ms',
              keyframes([
                style({ transform: 'translateY(0) translateX(0%)', offset: 0 }),
                style({ transform: 'translateY(0) translateX(25%)', offset: 0.4 }),
                style({ transform: 'translateY(0) translateX(50%)', offset: 0.6 }),
                style({ transform: 'translateY(0) translateX(75%)', offset: 0.8 }),
                style({ transform: 'translateY(0) translateX(100%)', offset: 1.0 })
              ])
            )
          ), { optional: true }
        )
      ])
     ]);


export const CardFlipAnimation = trigger('CardFlip', [
  transition('true => false', [
    animate('0.5s', keyframes([
      // style({ transform: 'rotateY(-180deg)', transformStyle: 'preserve-3d'}),
      style({ transform: 'rotateY(0deg)', transformStyle: 'preserve-3d'})
    ]))
  ]),
  transition('false => true', [
    animate('0.5s', keyframes([     
      // style({ transform: 'rotateY(-180deg)',transformStyle: 'preserve-3d'}),
      style({ transform: 'rotateY(0deg)',transformStyle: 'preserve-3d'})
    ])),
  ])
]);  