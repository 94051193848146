import { Component, Inject, OnInit } from "@angular/core";
import { Next } from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";

import {
  AttributeDataState,
  PageDataState,
  AudioState,
  NavigatorTieBreakerState
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { AudioService } from "../../../shared/common-constants/audioLoading.service";

@Component({
  selector: "app-navigator-tie",
  templateUrl: "./navigator-tie.component.html",
  animations: [Next],
})
export class NavigatorTieBreakerComponent implements OnInit {
  cardsSelected = [{ 'title': '', 'src': '', 'value': '', 'audio': [] }, { 'title': '', 'src': '', 'value': '', 'audio': [] }];
  cards = [];
  like = [];
  unlike = [];
  firstItem = 0;
  secdItem = 0;
  change = [];
  delay1 = [0, 0];
  firstInx: number;
  secdInx: number;
  completed = 'start';

  // completed = true;
  compActive = true;
  pageData: any;
  attributeData: any;
  pageCode: string;
  keywords: any;
  theme_name: string;
  cardsData: any;
  lang = "";
  valuesData: any[];
  page1List: any[];
  assetsURL: string;
  headerAudioState = 'stoped';
  headerAudionotfound = false;
  listData: any;
  tieselectedPagedata: any;
  toptext: any;
  curAudio: string;

  constructor(
    public router: Router,
    private store: Store<PageDataState>,
    private attrStore: Store<AttributeDataState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public activatedRoute: ActivatedRoute,
    private navigatorTieBreakerState: Store<NavigatorTieBreakerState>,
    @Inject('ASSETS_MEDIA_URL') private assetsUrl: string
  ) {
    this.assetsURL = assetsUrl;

    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_INTRO" },
    });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/tie-breaker") {
          if (e.type === "languageChanged") {
            this.lang = sessionStorage.getItem("session_token");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          } else if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          } else if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          } else if (e.type === "backClicked") {
            this.unsavedChanges();
          }
        }
      });
    this.audioStore
      .select("audio")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive')==='false') {
          if (this.curAudio === "header") {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });

  }

  ngOnInit() {
    this.keywords = JSON.parse(sessionStorage.getItem('GLOBAL_SETTINGS'));
    this.pagesCall();

  }
  pagesCall() {
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.listData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_ASSESS"
          );
          this.pageData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_TIE"
          );
          this.tieselectedPagedata = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_TIE_SELECTED"
          );
          this.toptext = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_TOP"
          );
        }
      });
    this.navigatorTieBreakerState.select('navigatorTieData').pipe(takeWhile(() => this.compActive)).subscribe(storeresp => {
      if (storeresp['navigatorTieData'].cardsSelected.length > 0 || storeresp['navigatorTieData'].list.length > 0) {
        this.page1List = storeresp['navigatorTieData'].list;
        this.cardsSelected = storeresp['navigatorTieData'].cardsSelected;
      } else {
        this.attributeDataCall();
      }
    });
  }

  attributeDataCall() {
    this.oninitpopupservice.pageCallText(this.lang, "attributes/value");
    this.attrStore
      .select("attribute")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((res) => {
        const data = res.attribute;
        if (data[0]) {
          this.page1List = [];
          this.valuesData = [];
          this.valuesData = data.filter(val => val.type === 'value');
          if (this.valuesData.length > 0) {
            this.valuesData.map(filtval => this.page1List = [...this.page1List, ...this.pageData.values.page1[filtval.name].map(element => {
              element['value'] = filtval.name
              return element;
            })]);
            if (this.cardsSelected[0].title !== '' && this.cardsSelected[1].title !== '') {
              let changedArray = []
              this.cardsSelected.forEach(ele => {
                this.page1List.forEach(page => {
                  if (page.src === ele.src) {
                    changedArray.push(page);
                  }
                });
              });
            }
          }
        }
      });
  }

  cardSelected(card, inx) {
    if ((this.firstItem == 0 && this.cardsSelected[this.firstItem + 1] != card) || (this.firstItem == 1 && this.cardsSelected[this.firstItem - 1] != card)) {
      this.change[inx]++;
      this.delay1[inx]++;
    }
    if (this.firstItem == 0 && this.cardsSelected[this.firstItem + 1] != card) {
      this.cardsSelected[this.firstItem] = card;
      this.firstItem = 1;
      this.secdItem++;
      this.completed = 'completed';
      if (this.firstInx) {
        this.change[this.firstInx] = 0;
      }
      this.firstInx = inx;
    } else if (this.firstItem == 1 && this.cardsSelected[this.firstItem - 1] != card) {
      this.cardsSelected[this.firstItem] = card;
      this.firstItem = 0;
      this.secdItem++;
      if (this.secdInx) {
        this.change[this.secdInx] = 0;
      }
      this.secdInx = inx;
    }

    this.completed = 'start';
  }

  clickNext() {
    this.navigatorTieBreakerState.dispatch({
      type: 'NAVIGATOR_TIE_VAL'
      , payload: {
        navigatorTieData: {
          list: this.page1List,
          cardsSelected: this.cardsSelected
        }
      }
    });
    this.saveChanges(this.cardsSelected);
  }

  saveChanges(skills) {
    let data = []
    data = skills.map(ele => ele.value);
    this.apiModel.moduleName = 'navigator';
    this.apiModel.endUrl = 'user/values/result';
    this.apiModel.sessionID = sessionStorage.getItem('session_token');
    this.apiModel.method = 'POST';
    this.apiModel.data = data;
    try {
      this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
        /* This block code is commented until we get future functionality for this post call */
        if (resp) {
          this.router.navigate(["../topTwoGroups"], { relativeTo: this.activatedRoute });
        }
      });
    } catch (e) {
      this.utils.handleError(`navigator-tie.component.ts saveChanges: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  unsavedChanges() {
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(() => {
      this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_TIE");
    });
  }

  ngAfterViewInit() {
    this.infopopup()
  }
  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

}
