<div class="header-white-bg">
  <mat-toolbar class="header" color="primary">
    <mat-toolbar-row p-0>
      <div class="container" ngStyle.xs="margin-left:0;margin-right:0;width:100%;padding:0 0">
        <div fxLayout="row" fxFlexFill>
          <div fxFlex="" flex v-center>
            <div class="header-component-icon" flex v-center>
              <img class="w-100" *ngIf="pageText?.module === 'EXPLORER'" src="{{assetsSVG_URL}}globe.svg"
                alt="explorer icon" />
              <img class="w-100" *ngIf="pageText?.module === 'PATHFINDER'" src="{{assetsPNGUrl}}/compass.png"
                [ngClass]="(pageText?.module === 'PATHFINDER')? 'pathfinder-component-icon':''" alt="compass-image" />
              <img class="w-100" *ngIf="pageText?.module === 'CAREER_TREKKER'" src="{{assetsSVG_URL}}direction.svg"
                alt="direction-image" />
              <img class="w-100" *ngIf="pageText?.module === 'CAREER_LIBRARY'" src="{{assetsSVG_URL}}binoculars.svg"
                alt="binoculars-image" />
              <img class="w-100" *ngIf="pageText?.module === 'QUICKPIC'" src="{{assetsSVG_URL}}quick-pic.svg"
                alt="quick-pic-image" />
              <img class="w-100" *ngIf="pageText?.module === 'SCOUT'" src="{{assetsSVG_URL}}scout.svg"
                alt="scout-image" />
              <img class="w-100" *ngIf="pageText?.module === 'NAVIGATOR'" src="{{assetsSVG_URL}}navigator.svg"
                [ngClass]="(pageText?.module === 'NAVIGATOR')? 'navigator-component-icon':''" alt="navigator-image" />
              <img class="w-100" *ngIf="pageText?.module === 'CAREER_CORNER'" src="{{assetsSVG_URL}}career-corner.svg"
                alt="career-corner-image" />
              <img class="w-100" *ngIf="pageText?.module === 'DISCOVERER'" src="{{assetsSVG_URL}}discoverer.svg"
                alt="Discoverer-image" />
              <img class="w-100" *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'explorer-theme'"
                src="{{assetsPNGUrl}}/pencil-icon-Explorer.png" alt="explorer-image" />
              <img class="w-100"
                *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'pathfinder-theme' "
                src="{{assetsPNGUrl}}/pencil-icon-Pathfinder.png" alt="pathfinder-image" />
              <img class="w-100"
                *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'scout-theme' "
                src="{{assetsSVG_URL}}scout-pencil-icon.svg" alt="pathfinder-image" />
              <img class="w-100"
                *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'navigator-theme' "
                src="{{assetsPNGUrl}}/pencil-icon-Navigator.png" alt="pathfinder-image" />
              <img class="w-100"
                *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'discoverer-theme' "
                src="{{assetsSVG_URL}}discover-pencil-icon.svg" alt="discoverer-image" />
              <img class="w-100"
                *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'careertrekker-theme' "
                src="{{assetsPNGUrl}}/pencil-icon-Career-trek.png" alt="career-image" />
              <img class="w-100" *ngIf="pageText?.module === 'CREATE_OWN_OCCUPATION' && themeColor === 'undefined'"
                src="{{assetsPNGUrl}}/pencil-icon-green.png" alt="career-image" />
            </div>

            <p m-0 class="title">{{pageText?.menuTitle}}</p>
          </div>
          <!-- Logo design start -->
          <div fxFlex="" flex v-center h-center ngStyle.xs="justify-content:center;">
            <div class="career-trek-logo" pointer (click)="themechange()">
              <img src="./assets/images/ct-logo.png" alt="careertrekker logo" />
            </div>
          </div>
          <!-- Logo design end -->

          <div fxFlex="" flex v-center class="header-menu-list">
            <!-- Desktop button and icons design end -->
            <button *ngIf="path != 'landing'" class="back-button" flex v-center h-center mat-fab color="primary"
              (click)="backTriggered()">
              <i class="material-icons" flex v-center h-center>keyboard_backspace</i>
            </button>
            <ul flex-center>
              <li flex v-center h-center class="desktop-design-show">
                <div class="info-icon-styles">
                  <button class="first-btn" mat-button mat-primary text-color="primary"
                    *ngIf="defaultHelp==keywords?.helpOn" (click)="popupHelp(false)">
                    {{defaultHelp}}
                  </button>
                  <button class="first-btn" mat-button mat-primary text-color="primary"
                    *ngIf="defaultHelp!=keywords?.helpOn" (click)="popupHelp(true)">
                    {{defaultHelp}}
                  </button>
                  <button *ngIf="urlpath=='active'" mat-button mat-primary text-color="primary" p-0
                    (click)="callInfoModal()">
                    <i class="material-icons" text-color="primary">help_outline</i>
                  </button>
                </div>
              </li>
              <li flex (click)="callInfoModal()" *ngIf="urlpath=='active'" class="mobile-design-show">
                <i class="material-icons help-button">help_outline</i>
              </li>
              <li flex (click)="sideMenu()">
                <i class="material-icons menu-icon-button" pointer>menu</i>
              </li>
              <!-- Mobile Logout button design end -->
            </ul>

            <!-- Desktop button and icons design end -->
          </div>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<!--  side menu start  -->
<div class="side-menu-bg" [ngStyle]="SidemenuButton?{'display':'block'}:{ 'display':'none'}"
  [@sidemenu]="Sidemenuanimation">
  <div class="side-menu sidemenu">
    <div (click)="sideMenu()" class="text-right">
      <i class="material-icons" pointer> close </i>
    </div>
    <div class="profil-section">
      <div class="w-100">
        <div class="profile-img">
          <i class="material-icons" *ngIf="!myPic"> person </i>
          <div class="person-image" *ngIf="myPic">
            <img class="w-100" src="{{myPic}}" />
          </div>
        </div>
        <div class="sidemenu-btn">
          <ul class="list-style-none">
            <li (click)="profile()" pointer>
              {{profileTitle?.menuTitle}}&nbsp;
              <i class="material-icons person">account_circle</i>
            </li>
            <li>
              <button mat-button aria-haspopup="true" [matMenuTriggerFor]="menu1" class="menu-button">
                {{displayLang}}
                <i class="material-icons">arrow_drop_down</i>
              </button>
              <mat-menu #menu1="matMenu" class="lang-dropdown-menu">
                <button mat-menu-item *ngFor="let lang of languageOptions" (click)="selectLang(lang.language)">
                  <span>{{lang.display}}</span>
                </button>
              </mat-menu>
            </li>
            <li (click)="logout()" pointer>
              {{keywords?.logout}}&nbsp;
              <i class="material-icons">exit_to_app</i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  side menu end  -->