<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      {{discovererActivity.header}}
      <app-audio-button [audionotfound]="headerAudioNotFound" [audioState]="headerAudioState"
        (click)="discovererAudioClick('inside',discovererActivity)"></app-audio-button>
    </h4>
  </div>
  <div class="mobile-design-show" *ngIf="arrowShow">
    <button mat-fab color="primary" (click)="getOccCustomDetail('newOcc')" class="next-level" [@flipAnimation]="completed">
      <i class="material-icons">arrow_forward</i>
    </button>
  </div>
  <div class="container" *ngIf="activateCommunityList.communityOccs.length>1">
    <div class="career-activity discoverer-activity content-box">
      <div class="carrer-frame-block" flex v-center h-center>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(0, activateCommunityList.communityOccs[0]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[0]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-4.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_1">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[0]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[0]?.title}}</span>
            <!-- <button mat-raised-button color="primary" class="audio-box volume-up">
              <i class="material-icons">volume_up</i>
            </button> -->

            <app-audio-button [audionotfound]="audioNotFound[0]" [audioState]="audioState[0]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[0],0)"></app-audio-button>

          </div>
        </div>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(1, activateCommunityList.communityOccs[1]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[1]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-8.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_2">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[1]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[1]?.title}}</span>
            <!-- <button mat-raised-button color="primary" class="audio-box volume-up">
              <i class="material-icons">volume_up</i>
            </button> -->
            <app-audio-button [audionotfound]="audioNotFound[1]" [audioState]="audioState[1]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[1],1)"></app-audio-button>
          </div>
        </div>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(2, activateCommunityList.communityOccs[2]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[2]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-6.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_3">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[2]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[2]?.title}}</span>
            <!-- <button mat-raised-button color="primary" class="audio-box volume-up">
              <i class="material-icons">volume_up</i>
            </button> -->
            <app-audio-button [audionotfound]="audioNotFound[2]" [audioState]="audioState[2]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[2],2)"></app-audio-button>
          </div>
        </div>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(3, activateCommunityList.communityOccs[3]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[3]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-3.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_4">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[3]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[3]?.title}}</span>
            <app-audio-button [audionotfound]="audioNotFound[3]" [audioState]="audioState[3]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[3],3)"></app-audio-button>
          </div>
        </div>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(4, activateCommunityList.communityOccs[4]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[4]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-2.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_5">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[4]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[4]?.title}}</span>
            <app-audio-button [audionotfound]="audioNotFound[4]" [audioState]="audioState[4]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[4],4)"></app-audio-button>
          </div>
        </div>
        <div class="carrer-frame">
          <div class="frame-card" (click)="ImageClicked(5, activateCommunityList.communityOccs[5]?.occId)">
            <mat-checkbox [(ngModel)]="checkedCareers[5]" (click)="$event.preventDefault()" disableRipple="true">
              <img src="assets\Svg\frames\career-corner-frame-5.svg" alt="">
            </mat-checkbox>
          </div>
          <div class="career-image inner-card_6">
            <img src="{{occCardAPIUrl}}{{activateCommunityList.communityOccs[5]?.media?.images?.card}}">
          </div>
          <div class="carrer-frame-footer">
            <span mr-10>{{activateCommunityList.communityOccs[5]?.title}}</span>
            <app-audio-button [audionotfound]="audioNotFound[5]" [audioState]="audioState[5]"
              (click)="audioClick('inside',activateCommunityList.communityOccs[5],5)"></app-audio-button>
          </div>
        </div>
      </div>
      <div class="desktop-design-show" *ngIf="arrowShow">
        <button mat-fab color="primary" (click)="getOccCustomDetail('newOcc')" class="next-level"
          [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>