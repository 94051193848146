import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scout-module',
  template: `<app-static-header></app-static-header>
	<router-outlet></router-outlet>
	<app-static-footer></app-static-footer>`
})
export class ScoutModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
