import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-audio-button",
  template: `<button
    mat-raised-button
    color="primary"
    class="audio-box volume-up"
    [ngClass]="styleClass"
    [disabled]="audionotfound == true && audioState == 'warning'"
  >
    <i
      class="material-icons"
      *ngIf="audionotfound == false && audioState == 'stoped'"
      >volume_up</i
    >
    <i
      class="material-icons"
      *ngIf="audionotfound == false && audioState == 'playing'"
      >stop</i
    >
    <div
      class="audio-loading"
      *ngIf="audionotfound == false && audioState == 'loading'"
    ></div>
    <i
      class="material-icons"
      *ngIf="audionotfound == true && audioState == 'warning'"
      >warning</i
    >
  </button>`,
})
export class AudioButtonComponent implements OnInit {
  @Input() styleClass: string;
  @Input() audioState: string;
  @Input() audionotfound: boolean;
  constructor() {}

  ngOnInit() {}
}
