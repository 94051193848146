import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CareerTrekkerComponent } from './careertrekker-component';
import { StaticHeaderComponent } from '../../shared/common-header-footer/header.component';
import { StaticFooterComponent } from '../../shared/common-header-footer/footer.component';
import { CareerTrekkerIntroComponent } from './intro/careertrekker-intro-component';
import { CareerTrekkerAssessmentComponent } from './assessment/careertrekker-component';
import { CareerTrekkerTopSkillsComponent } from './top-skills/top-skills-component';
import { CareerTrekkerChallengeComponent } from './challenge/challenge-component';
import { CareerTrekkerResultComponent } from './result/careertrekker-result';
import { SharedModule } from '../../shared/common-module/shared-common-module';

const routes: Routes = [{
  path: 'app/careertrekker', component: CareerTrekkerComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: CareerTrekkerIntroComponent },
    { path: 'assessment', component: CareerTrekkerAssessmentComponent },
    { path: 'topskills', component: CareerTrekkerTopSkillsComponent },
    { path: 'result', component: CareerTrekkerResultComponent },
    { path: 'challenge', component: CareerTrekkerChallengeComponent },

  ]
}];
@NgModule({
  imports: [
    CommonModule, [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], SharedModule.forRoot()
  ],

  declarations: [CareerTrekkerComponent,
    CareerTrekkerIntroComponent,
    CareerTrekkerAssessmentComponent,
    CareerTrekkerTopSkillsComponent,
    CareerTrekkerResultComponent,
    CareerTrekkerChallengeComponent],
  providers: [],
  exports: [RouterModule]

})



export class CareerTrekkerModule { }
