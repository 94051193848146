import { Component, OnInit } from "@angular/core";
import { Next } from "../../../shared/common-animations/CT_animations";
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { environment } from "../../../../environments/environment";
import {
  AttributeDataState,
  PageDataState,
  AudioState,
  NavigatorWorkValuesState,
  NavigatorTieBreakerState,
  ProfileDataState,
} from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { debounceTime, sample, takeWhile } from "rxjs/operators";
import { ApiCallClass } from '../../../shared/common-services/api-call-model';
import { ApiCallService } from '../../../shared/common-services/api-call-service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";
import { DialogopenService } from "../../../shared/common-modal/modalpopup.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-navigator-activity",
  templateUrl: "./navigator-activity.component.html",
  animations: [Next],
})
export class NavigatorActivityComponent implements OnInit {
  completed = true;
  compActive = true;
  pageData: any;
  attributeData: any;
  pageCode: string;
  keywords: any;
  theme_name: string;
  valuesData: any;
  lang = "";
  language: any;
  selectedOptions = [];
  firstSelectionStage: boolean = true;
  curSelectedItem = { type: "", name: "", icon: "" };
  isItemSelected = true;
  page1List = [];
  page2List = [];
  selectedPage1List = [];
  selectedPage2List = [];
  emoji: any;
  curAudio = "";
  headerAudioState = 'stoped';
  headerAudioNotFound = false;
  audioState = ['stoped', 'stoped', 'stoped', 'stoped', 'stoped', 'stoped'];
  audioNotFound = [false, false, false, false, false, false];
  audioButtonIndex = -1;
  pageAnimateData = { page: "", inx: -1 };
  constructor(
    public router: Router,
    private store: Store<PageDataState>,
    private attrStore: Store<AttributeDataState>,
    private navigatorWorkValuesState: Store<NavigatorWorkValuesState>,
    private navigatorTieBreakerState: Store<NavigatorTieBreakerState>,
    public eventService: EventdispatchService,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities,
    public snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    private apiModel: ApiCallClass,
    private apiCall: ApiCallService,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public dialogService: DialogopenService,
    private profileStore: Store<ProfileDataState>,
    private titleService: Title
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem("theme_color", colors[8]);
    const event = document.createEvent("CustomEvent");
    event.initEvent("themeChanged", true, true);
    this.eventService.dispatch(event);
    sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: "TRIGGER_TEXT_CHANGE_EVENT",
      payload: { module: "NAVIGATOR", pageCode: "NAVIGATOR_ASSESS" },
    });
    //Here we got restrict the forword button creation when animate while selecting same option multiple times using debounce
    eventService
      .listen()
      .pipe(debounceTime(100), takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (e.type == "animateButton") {
          let inx = this.pageAnimateData.inx;
          if (this.pageAnimateData.page === 'page1') {
            if (this.page1List[inx] && !this.page1List[inx].selected) {
              this.page1List[inx].selected = true;
              this.selectedPage1List.push(this.page1List[inx]);
            } else if (this.page1List[inx].selected) {
              this.page1List[inx].selected = false;
              const index = this.selectedPage1List.findIndex(item => item.title === this.page1List[inx].title);
              if (index > -1) {
                this.selectedPage1List.splice(index, 1);
              }
            }
          } else if (this.pageAnimateData.page === 'page2') {
            if (this.page2List[inx] && !this.page2List[inx].selected) {
              this.page2List[inx].selected = true;
              this.selectedPage2List.push(this.page2List[inx]);
            } else if (this.page2List[inx].selected) {
              this.page2List[inx].selected = false;
              const index = this.selectedPage2List.findIndex(item => item.title === this.page2List[inx].title);
              if (index > -1) {
                this.selectedPage2List.splice(index, 1);
              }
            }
            let count = this.selectedPage1List.length + this.selectedPage2List.length;
            if (count > 1) {
              this.isItemSelected = true;
            } else {
              this.isItemSelected = false;
            }

          }
        }
      });
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem("pathurl") === "/app/navigator/activity") {
          if (e.type === "languageChanged") {
            this.lang = sessionStorage.getItem("session_token");
            this.keywords = JSON.parse(
              sessionStorage.getItem("GLOBAL_SETTINGS")
            );
            this.ngOnInit();
          }
          if (e.type === "themeChanged") {
            this.theme_name = sessionStorage.getItem("theme_color");
          }
          if (e.type === "infoModal") {
            if (sessionStorage.getItem("infopopup") == "true") {
              this.infopopup();
              sessionStorage.setItem("infopopup", "false");
            }
          }
          if (e.type === "backClicked") {
            this.stopAudio();
            this.unsavedChanges();
          }
        }
      });
    this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
      if (result && sessionStorage.getItem('isModalActive') === 'false') {
        {
        }
        if (this.curAudio === "header") {
          this.headerAudioState = result.audioState;
          this.headerAudioNotFound = result.audionotfound;
          this.audioState = ['stoped', 'stoped', 'stoped', 'stoped', 'stoped', 'stoped'];
          this.audioNotFound = [false, false, false, false, false, false];
        } else if (this.curAudio === 'career') {
          this.audioState[this.audioButtonIndex] = result.audioState;
          this.audioNotFound[this.audioButtonIndex] = result.audionotfound;
          this.headerAudioState = 'stoped'
          this.headerAudioNotFound = false;
        }

      }
    });
    this.profileStore
      .select("profileImage")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((profileState) => {
        if (profileState && profileState.profileImage) {
          try {
            const reader = new FileReader();
            reader.readAsDataURL(profileState.profileImage);
            reader.onloadend = () => {
              this.emoji = reader.result;
            };
          } catch (e) {
            this.utils.handleError(`navigator-activity.component.ts constructor: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
          }
        }
      });
  }

  ngOnInit() {
    this.store
      .select("pages")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          const data = resp.pages;
          this.pageData = data.find(
            (obj) => obj.pageCode === "NAVIGATOR_ASSESS"
          );
        }
      });
    this.navigatorWorkValuesState.select('navigatorWorkValuesData').pipe(takeWhile(() => this.compActive)).subscribe(storeresp => {
      this.page1List = storeresp['navigatorWorkValuesData'].page1List;
      this.page2List = storeresp['navigatorWorkValuesData'].page2List;
      if (this.page1List.length > 0 && this.page2List.length > 0) {
        this.firstSelectionStage = false;
        this.isItemSelected = true;
        this.language = storeresp['navigatorWorkValuesData'].language
        if (this.language != sessionStorage.getItem('language')) {
          this.changedLanguage()
        }
      } else {
        this.attributeDataCall();
      }
    });

    this.selectedPage1List = this.page1List.filter(function (a) {
      return a.selected == true;
    });
    this.selectedPage2List = this.page2List.filter(function (a) {
      return a.selected == true;
    });

    this.setNavigatorActivityTitle();
  }
  ngAfterViewInit() {
    this.infopopup();
  }

  changedLanguage() {
    let page1data = this.page1List
    let page2data = this.page2List
    this.attributeDataCall()
    if (this.page1List.length > 0 && page1data.length > 0) {
      page1data.forEach(obj => {
        if (obj.selected) {
          let index = this.page1List.findIndex(item => item.value === obj.value)
          this.page1List[index]['selected'] = true
        }
      })
    }
    if (this.page2List.length > 0 && page2data.length > 0) {
      page2data.forEach(obj => {
        if (obj.selected) {
          let index = this.page2List.findIndex(item => item.value === obj.value)
          this.page2List[index]['selected'] = true
        }
      })
    }
  }

  attributeDataCall() {
    this.dialogService.showLoading();
    this.oninitpopupservice.pageCallText(this.lang, "attributes/value");
    this.attrStore
      .select("attribute")
      .pipe(takeWhile(() => this.compActive))
      .subscribe((res) => {
        const data = res.attribute;
        if (data[0]) {
          this.valuesData = [];
          this.valuesData = data.filter(val => val.type === 'value');

        }
        if (this.page1List.length > 0 && this.page2List.length > 0) {
          this.dialogService.hideLoading();
        }

        this.page1List = [];
        this.page2List = [];
        this.valuesData.forEach((ele) => {
          this.pageData.values.page1[ele.name].forEach(element => {
            element['value'] = ele.name;
            element['selected'] = false;
            this.page1List.push(element);
          });
          this.pageData.values.page2[ele.name].forEach(element => {
            element['value'] = ele.name;
            element['selected'] = false;
            this.page2List.push(element);
          });
        });
        this.selectedOption()
      });
  }

  selectedOption() {
    if (this.selectedPage1List.length > 0) {
      this.selectedPage1List.forEach(obj => {
        let index = this.page1List.findIndex(item => item.value === obj.value)
        this.page1List[index]['selected'] = true
      })
    }
    if (this.selectedPage2List.length > 0) {
      this.selectedPage2List.forEach(obj => {
        let index = this.page2List.findIndex(item => item.value == obj.value)
        this.page2List[index]['selected'] = true
      })
    }
  }

  saveChanges(skills) {
    let data = []
    data = skills.map(ele => ele.value);
    //Condition to check whether the data has more than two value to save in the result
    if (data.length === 2) {
      this.apiModel.moduleName = 'navigator';
      this.apiModel.endUrl = 'user/values/result';
      this.apiModel.sessionID = sessionStorage.getItem('session_token');
      this.apiModel.method = 'POST';
      this.apiModel.data = data;
      try {
        this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe((resp) => {
          if (resp != undefined && resp != null) {
            this.router.navigate(["../topTwoGroups"], { relativeTo: this.activatedRoute });
          }
        });
      } catch (e) {
        this.utils.handleError(`navigator-activity.component.ts saveChanges: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      }
    } else {
      this.router.navigate(["../tie-breaker"], { relativeTo: this.activatedRoute });
    }

  }

  unsavedChanges() {
    if (!this.firstSelectionStage) {
      this.firstSelectionStage = true;
      // while click on back navigation in header to show more button.
      this.isItemSelected = true;
    } else {
      const evnt = document.createEvent("CustomEvent");
      this.eventService.dispatchAudioStop(evnt);
      this.utils.backRoute();
    }
  }

  infopopup() {
    this.stopAudio();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp("NAVIGATOR_ASSESS");
      }.bind(this)
    );
  }

  selectOption(page, inx) {
    this.pageAnimateData.page = page;
    this.pageAnimateData.inx = inx;
    const event = document.createEvent("CustomEvent");
    event.initEvent("animateButton", true, true);
    this.eventService.dispatch(event);
  }

  // For second selection
  nextStage() {
    this.stopAudio();
    let count = this.selectedPage1List.length + this.selectedPage2List.length;
    if (count > 1) {
      this.isItemSelected = true;
    } else {
      this.isItemSelected = false;
    }
    this.firstSelectionStage = !this.firstSelectionStage;
  }
  //To navigator the previous page
  previous() {
    this.stopAudio();
    this.firstSelectionStage = !this.firstSelectionStage;
    if (this.firstSelectionStage) {
      this.isItemSelected = true;
    }
  }
  //To navigator the next page
  next() {
    this.lang = this.pageData.language
    this.selectedOptions = [];
    this.selectedOptions = [...this.selectedPage1List, ... this.selectedPage2List]
    this.navigatorWorkValuesState.dispatch({
      type: 'NAVIGATOR_WORK_VAL'
      , payload: {
        navigatorWorkValuesData: {
          page1List: this.page1List,
          page2List: this.page2List,
          language: this.lang
        }
      }
    });
    this.navigatorTieBreakerState.dispatch({
      type: 'NAVIGATOR_TIE_VAL'
      , payload: {
        navigatorTieData: {
          list: [],
          cardsSelected: []
        }
      }
    });
    let array = this.findOcc(this.selectedOptions, 'value');
    let sampleArray = [];
    if (array.length > 2) {
      sampleArray = array.filter(val => val.occurrence >= 2);
      if (sampleArray.length === 2) {
        this.saveChanges(sampleArray);
      } else {
        this.router.navigate(["../tie-breaker"], { relativeTo: this.activatedRoute });
      }
    } else {
      sampleArray = array;
      this.saveChanges(sampleArray);
    }


    // }
  }

  findOcc(arr, key) {
    let arr2 = [];

    arr.forEach((x) => {

      // Checking if there is any object in arr2
      // which contains the key value
      if (arr2.some((val) => { return val[key] == x[key] })) {
        // If yes! then increase the occurrence by 1
        arr2.filter(k => k[key] === x[key]).map(k => k["occurrence"]++);
      } else {
        // If not! Then create a new object initialize 
        // it with the present iteration key's value and 
        // set the occurrence to 1
        let a = {}
        a[key] = x[key]
        a["occurrence"] = 1
        arr2.push(a);
      }
    })

    return arr2
  }

  audioClick(text, data) {
    this.curAudio = "header";
    this.audioService.audioFunction(text, data);
  }

  listAudioClick(text, data, index) {
    this.curAudio = 'career'
    this.audioButtonIndex = index;
    //Reinitialized audioState and audioNotFound arrays to make the audio states to default. 
    this.audioState = ['stoped', 'stoped', 'stoped', 'stoped', 'stoped', 'stoped'];
    this.audioNotFound = [false, false, false, false, false, false];
    this.audioService.audioFunction(text, data, true);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = "stoped";
    this.headerAudioNotFound = false;
    this.audioState = ['stoped', 'stoped', 'stoped', 'stoped', 'stoped', 'stoped'];
    this.audioNotFound = [false, false, false, false, false, false];
    this.audioStore.dispatch({
      type: "SET_AUDIO_STOPED",
    });
    const evnt = document.createEvent("CustomEvent");
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }

  private setNavigatorActivityTitle() {
    this.titleService.setTitle("Navigator Activity - CareerTrek");
  }
}
