import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Utilities } from '../../shared/common-services/utilities.service';
@Injectable()
export class StoreService implements OnDestroy {
    constructor(private dispatchStore: Store<Action>, private utils: Utilities) {

    }
    getLangChange(langVal, key, result) {
        const val = JSON.parse(sessionStorage.getItem('langDataStore'));
        if (Object.keys(val[langVal]).indexOf(key) != -1) {
            return result;
        } else {
            val[langVal][key] = result;
            sessionStorage.setItem('langDataStore', JSON.stringify(val));
            return result;
        }
    }

    commonLanguageChange(langVal, key, payload) {
        try {
            let val = JSON.parse(sessionStorage.getItem('langDataStore'));
            if (val == null && val == undefined) {
                val = {};
            }
            if (Object.keys(val).indexOf(langVal) == -1) {
                val[langVal] = {};
                sessionStorage.setItem('langDataStore', JSON.stringify(val));
                this.dispatchStore.dispatch(payload);
            } else {
                if (Object.keys(val[langVal]).indexOf(key) == -1) {
                    this.dispatchStore.dispatch(payload);
                } else {
                    if (key == 'OCC_index_list') {
                        sessionStorage.setItem('OccIndexReducerText', JSON.stringify({ commonText: val[langVal][key] }));
                    }
                }
            }
        } catch (e) {
            this.utils.handleError(`store-service.ts commonLanguageChange: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    ngOnDestroy() {
    }
}
