<div class="Career-trek-content">
	<div class="d-table w-100 h-100">
	  <div class="d-table-cell">
	    <div class="container content-box">
        <div class="explorer-information" *ngIf="data!=undefined">
          <div class="expl-tittle" *ngIf="modeldata != undefined">
            <div class="mobile-heading" *ngIf="modeldata.description != undefined">
              <div text-color="primary" class="text-center" flex v-center>
                <div m-0 class="text-center w-100" font-normal text-color="primary" *ngIf="data[0]!=undefined" flex v-center h-center>
                  <img class="mobile-design-show" src="./assets/explorer/MOBILE/{{data[0].category.categoryId}}.png" width="30px" alt="{{data[0].category.title}}">
                  <h1 flex v-center h-center> {{modeldata.description | titlecase}}</h1>
                </div>
                <div class="mobile-design-show">
                  <button mat-fab color="accent" class="next-btn" (click)="taskroute()" (keyup.enter)="taskroute()">
                    <i class="material-icons">arrow_forward</i>
                  </button>
                </div>
              </div>
            </div>
            <p class=" text-center">{{modeldata.pageText.intro}}</p>
            <p class=" text-center" *ngFor="let item of modeldata.pageText.expressions.items">{{item.text}}</p>
          </div>
          <div class="desktop-design-show">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxFlexFill class="common-row-sub" fxLayoutGap="10px" fxLayoutGap.sm="5px">
              <div fxFlex="24%" fxFlex.md="28%" fxFlex.sm="35%" *ngFor="let a of data; index as i">
                <mat-card class="card">          
                  <mat-card-content class="card-body text-center popup-image" flex v-center h-center>
                    <div class="{{a.media?.images.sprite}} w-100"></div>
                    <div class="opacity-text">
                      <p m-0>
                        <span>{{a.title}}</span>
                      </p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="mobile-design-show">
            <mat-card class="card">
              <mat-card-title class="card-header text-left" background-color="primary">{{modeldata.description}}
              </mat-card-title>
              <mat-card-content class=" card-body text-center" ngStyle.xs="justify-content:left;">
                <mat-list class="m-auto" ngStyle.xs="padding-top:0px;margin: 0px 10px;">
                  <mat-list-item *ngFor="let a of data; index as i" ngStyle.xs="border-bottom:1px solid #d6cbcb;height:45px">
                    <div matListAvatar class="list-circle-icon" flex v-center h-center>
                      <img src="./assets/explorer/MOBILE/{{a.category.categoryId}}-2.png" alt="{{a.title}}">
                    </div>
                    <h4 mat-line class="text-left" text-color="primary">{{a.title}}</h4>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
          <button class="next-level yellow desktop-design-show" mat-fab color="accent" (click)="taskroute()" (keyup.enter)="taskroute()">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>