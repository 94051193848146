<div class="Career-trek-content">
  <div class="container">
    <h4 class="landing-page-heading">
      {{pathFinderIntroText?.header}}
      <app-audio-button
        [audionotfound]="audionotfound"
        [audioState]="audioState"
        (click)="audioClick('inside',pathFinderIntroText)"
      ></app-audio-button>
      <!-- <button mat-raised-button color="primary" class="audio-box volume-up"
        [disabled]="audionotfound==true && audioState=='warning'" 
        (click)="audioClick('inside',pathFinderIntroText)">
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='stoped'">volume_up</i>
        <i class="material-icons" *ngIf="audionotfound==false && audioState=='playing'">stop</i>
        <div class="audio-loading" *ngIf="audionotfound==false && audioState=='loading'"></div>
        <i class="material-icons" *ngIf="audionotfound==true && audioState=='warning'">warning</i>    
        </button> -->
    </h4>
  </div>
  <div class="container">
    <div class="pathfinder-intro content-box">
      <div class="mobile-heading mobile-design-show" flex v-center justify-end>
        <button
          mat-fab
          color="accent"
          (click)="clickRoute(cards[0].name,cards[0].hollandCode,cards[0].attributeId)"
          [@flipAnimation]="completed"
        >
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <div
        fxLayout="row"
        fxLayoutWrap="wrap"
        fxLayoutAlign="center"
        fxLayoutGap.xs="2px"
        fxLayoutGap="10px"
      >
        <div
          fxFlex="30%"
          fxFlex.xs="47%"
          class="pathfinder-cards"
          *ngFor="let a of cards"
          [ngStyle]="{'pointer-events': 'none'}"
        >
          <mat-card class="card">
            <mat-card-title
              text-color="primary"
              class="card-header desktop-design-show"
              >{{a?.displayName}}</mat-card-title
            >
            <mat-card-content
              class="card-body text-center"
              flex
              v-center
              h-center
            >
              <div>
                <img
                  *ngIf="a.icon"
                  src="assets/pathfinder/holland/{{a?.icon}}.svg"
                  alt="{{a.displayName}}"
                />
              </div>
            </mat-card-content>
            <mat-card-footer class="card-footer mobile-design-show" text-color="primary">{{a?.displayName}}</mat-card-footer>
          </mat-card>
        </div>
      </div>
      <div class="desktop-design-show">
        <button
          mat-fab
          color="accent"
          (click)="clickRoute(cards[0].name,cards[0].hollandCode,cards[0].attributeId)"
          class="next-level"
          [@flipAnimation]="completed"
        >
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>
