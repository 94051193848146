<div class="Career-trek-content">
  <div class="container text-center">
    <h4 class="landing-page-heading">
      <!-- Let's have some fun! Think about the activities.<br>
      Pick which one you would rather do. -->
      {{navigatorChallengeText?.header}}
      <app-audio-button [audionotfound]="headerAudionotfound" [audioState]="headerAudioState"
        (click)="headerAudioClick('inside',navigatorChallengeText)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="scout-activity navigator-challenge content-box">
      <h1 text-color="primary" class="activity-header">
        {{challengeQuestions[indexVal]?.question}}
        <app-audio-button [audionotfound]="quesAudionotfound" [audioState]="quesAudioState"
          (click)="quesAudioClick('inside',challengeQuestions[indexVal])"></app-audio-button>
      </h1>
      <div class="activity-cards-body">
        <div class="mobile-design-show" *ngIf="nextIcon == 'yes'">
          <button mat-fab color="primary" (click)="clickArrowRoute()" class="next-level" [@flipAnimation]="completed">
            <i class="material-icons">arrow_forward</i>
          </button>
        </div>
        <mat-radio-group [(ngModel)]="selectedRadio">
          <div class="activity-cards inner-block"
            *ngFor="let option of challengeQuestionsOption?.options;let x = index;">
            <div>
              <mat-radio-button [value]="option.answer" (click)="addActivity(indexVal, option.answer)" >
              </mat-radio-button>
              <mat-card >
                <mat-card-content pointer class="activity-body" (click)="addActivity(indexVal, option.answer)">
                  <img class="w-100" src="{{option?.image}}" alt="">
                </mat-card-content>
                <mat-card-footer>{{option?.answer}}
                  <app-audio-button [audionotfound]="ansAudionotfound[x]" [audioState]="ansAudioState[x]"
                  (click)="ansAudioClick('inside',option.audio, x)">
                </app-audio-button>
                </mat-card-footer>
              </mat-card>
            </div>
            <div class="or-block" [ngClass]="languageChanged=='es'?'or-text-spanish':'or-block'">
              <h1 text-color="primary">{{keywords?.dictionary?.or}}</h1>
            </div>
          </div>
        </mat-radio-group>
      </div>
      <div class="desktop-design-show" *ngIf="nextIcon == 'yes'">
        <button mat-fab color="primary" (click)="clickArrowRoute()" class="next-level" [@flipAnimation]="completed">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</div>