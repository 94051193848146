import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PathFinderComponent } from './pathfinder-component';
import { StaticHeaderComponent } from '../../shared/common-header-footer/header.component';
import { StaticFooterComponent } from '../../shared/common-header-footer/footer.component';
import { PathFinderIntroComponent } from './intro/pathfinder-intro-component';
import { PathFinderAssessmentComponent } from './assessment/pathfinder-component';
import { PathFinderTopSkillsComponent } from './top-skills/top-skills-component';
import { PathFinderChallengeComponent } from './challenge/challenge-component';
import { PathFinderResultComponent } from './result/pathfinder-result';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';

const routes: Routes = [{
  path: 'app/pathfinder', component: PathFinderComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: PathFinderIntroComponent },
    { path: 'assessment', component: PathFinderAssessmentComponent },
    { path: 'topskills', component: PathFinderTopSkillsComponent },
    { path: 'result', component: PathFinderResultComponent },
    { path: 'challenge', component: PathFinderChallengeComponent },

  ]
}];
@NgModule({
  imports: [
    CommonModule, [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], SharedModule.forRoot(), FlexLayoutModule, Ng2CarouselamosModule
  ],

  declarations: [PathFinderComponent,
    PathFinderIntroComponent,
    PathFinderAssessmentComponent,
    PathFinderTopSkillsComponent,
    PathFinderResultComponent,
    PathFinderChallengeComponent],
  providers: [],
  exports: [RouterModule]

})



export class PathFinderModule { }
