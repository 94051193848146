<app-static-header> </app-static-header>
<div class="custom-occ-content landing-img Career-trek-content" *ngIf="item">
  <div class="container custom-occ-header profile-page-header desktop-design-show text-right">
    <!-- Share Button -->
    <button (click)="shareCareer();"  class="common-occ-header-button share-occ-header-btn mat-button" *ngIf="moduleName!== 'discoverer'">
      <span class="mat-button-wrapper">
        <span font-bold="">{{keyWords?.dictionary?.share}}</span>
        <img src="{{assetsUrl}}share.svg" alt="share-image" />
      </span>
    </button>
    <!-- Delete Button -->
    <button class="common-occ-header-button delete-occ-header-btn mat-button" (click)="deleteCustomOcc(moduleName)">
      <span class="mat-button-wrapper">
        <span font-bold=""> {{keyWords?.dictionary?.delete}}</span>
        <img src="assets/Svg/delete.svg" alt="delete-image" />
      </span>
    </button>
    <!-- Download Button -->
    <button class="common-occ-header-button download-pdf-btn mat-button" (click)="DownloadPDF()">
      <span class="mat-button-wrapper">
        <span font-bold="">{{keyWords?.dictionary?.download}}</span>
        <i class="material-icons">picture_as_pdf</i>
      </span>
    </button>
  </div>
  <div class="mobile-design-show">
    <button (click)="shareCareer();" pointer class="common-occ-header-button share-occ-header-btn" *ngIf="moduleName!== 'discoverer'">
      <span class="mat-button-wrapper">
        <img src="{{assetsUrl}}share.svg" alt="share-image" />
      </span>
    </button>
    <button pointer class="red common-occ-header-button delete-occ-header-btn" (click)="deleteCustomOcc(item.title)">
      <span class="mat-button-wrapper">
        <img src="assets/Svg/delete.svg" alt="delete-image" />
      </span>
    </button>
    <button pointer class="common-occ-header-button download-pdf-btn" (click)="DownloadPDF()">
      <span class="mat-button-wrapper">
        <i class="material-icons">picture_as_pdf</i>
      </span>
    </button>
  </div>
  <div class="container">
    <h4 class="landing-page-heading">
      {{globalText.header}}
      <app-audio-button [audionotfound]="audionotfound" [audioState]="audioState" [styleClass]="'custom-occ-audio'"
        (click)="audioClick('inside',headingAudio)"></app-audio-button>
    </h4>
  </div>
  <div class="container">
    <div class="create-own-occ-detail content-box">
      <div class="custom-header mt-2" v-center *ngIf="!newOcc">
        <p m-0 v-center float-left>{{item.title}}</p>
      </div>
      <div class="custom-occ-detail">
        <div fxFlex="100%" fxFlex.sm="100%">
          <mat-card class="card h-100">
            <div class="second-block">
              <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.image}}
                <button class="mat-button custom-occ-edit-btn"
                  (click)="completeSection('editImage', 'stepper1', moduleName)">
                  <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                  <img src="../../../assets/Svg/creator-pencil-icon-green.svg" alt="edit-image" />
                </button>
              </h4>
              <div flex vcenter class="custom-occ-first w-100">
                <div class="div-img" text-color="primary" m-0>
                  <img *ngIf="!newOcc" src="{{item.media?.images.sprite}}" alt="{{item.title}}" class="spriteImage" />
                  <img *ngIf="newOcc" src="assets/images/nodata-found.png" alt="newOcc" class="spriteImage" />
                </div>
                <div class="div-para" p-5 flex v-center *ngIf="!newOcc">
                  <p m-0>{{item.description}}</p>
                </div>
              </div>
              <div class="scout-career desktop-design-show" *ngIf="moduleName!== 'discoverer'">
                <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.group}}
                  <button class="mat-button custom-occ-edit-btn">
                    <i class="material-icons" *ngIf="scoutCompletionSet.length == 0"
                      (click)="completeSection('scout', 'stepper4')">lock</i>
                    <img *ngIf="scoutCompletionSet.length>0" (click)="completeSection('scout', 'stepper4')"
                      src="../../../assets/Svg/creator-pencil-icon-green.svg" alt="career-group-edit" />
                  </button>
                </h4>
                <div class="career-group-block">
                  <div fxFlexFill class="career-item" *ngIf="scoutData.length>0 && scoutData[0]">
                    <img class="career-image" src="{{assetsUrl}}{{scoutData[0].image}}.svg" alt="">
                    <span class="title" font-bold>{{scoutData[0].displayName}}</span>
                  </div>

                  <div *ngIf="scoutData.length == 0" fxFlex="">
                    <mat-card m-0 class="card">
                      <div flex v-center h-center p-5 class="careercreator-pick"
                        (click)="completeSection('scout', 'stepper4')">
                        <img pointer src="{{assetsUrl}}pencil-icon-green.svg" alt="img-quick"
                          class="image-type-emptycard-work-values" />
                        <p m-0 pointer> {{globalText?.dictionary?.pickGroup}}</p>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="navigator-career desktop-design-show" *ngIf="moduleName!== 'discoverer'">
                <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.workValues}}
                  <button class="mat-button custom-occ-edit-btn">
                    <i class="material-icons" *ngIf="navigatorCompletionSet.length === 0"
                      (click)="completeSection('navigator', 'stepper5')">lock</i>
                    <img *ngIf="navigatorCompletionSet.length>0" (click)="completeSection('navigator', 'stepper5')"
                      src="../../../assets/Svg/creator-pencil-icon-pink.svg" alt="career-group-edit" />
                  </button>
                </h4>
                <div flex class="career-work-group">
                  <div class="navigator-career-card text-center" *ngFor="let item of navigatorData">
                    <div *ngIf="navigatorData.length>0">
                      <div>
                        <img src="./assets/Svg/{{item?.icon}}.svg" alt="career-group-icon" />
                      </div>
                      <span class="title" font-bold>{{item?.displayName}}</span>
                    </div>
                  </div>
                  <div *ngIf="navigatorData.length === 0" fxFlex="">
                    <mat-card m-0 class="card">
                      <div flex v-center h-center p-5 class="careercreator-pick"
                        (click)="completeSection('navigator', 'stepper5')">
                        <img pointer src="./assets/Svg/pencil_navigator.svg" alt="img-quick"
                          class="image-type-emptycard-work-values" />&nbsp;
                        <p pointer m-0> {{globalText?.dictionary?.pickValues}}</p>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="custom-occ-second desktop-design-show" *ngIf="moduleName!== 'discoverer'">
                <!-- cahracteristics -->
                <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.characteristics}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="!(ideaScores==true && thingsScores==true && peopleScores==true)"
                      (click)="completeSection('explorer', 'stepper2')">lock</i>
                    <img *ngIf="(ideaScores==true && thingsScores==true && peopleScores==true)"
                      (click)="completeSection('explorer', 'stepper2')" src="../../../assets/Svg/creator-pencil-icon-yellow.svg"
                      alt="explorer-edit-image" />
                  </button>
                </h4>
                <div class="explorer-active">
                  <div fxLayout="row" fxFlexFill class="common-row-sub status-card" fxLayoutGap="5px"
                    fxLayoutWrap="wrap">
                    <div class=" w-100" flex
                      *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                      <div fxFlex="">
                        <mat-card m-0 class="card">
                          <div flex v-center h-center p-5 class="careercreator-pick"
                            (click)="completeSection('explorer', 'stepper2')">
                            <img pointer src="./assets/images/pencil-icon-Explorer.svg" alt="img-quick"
                              class="image-type-emptycard" />
                            <p pointer m-0>{{globalText?.dictionary?.pickChars}}</p>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="attributesDatahigh.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header green">
                          {{keyWords?.dictionary?.high}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li *ngFor="let highAttr of attributesDatahigh">
                            <div class="status-circle green m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                              <img src="assets/Svg/{{highAttr.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{highAttr.title | titlecase}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>

                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="attributesDatamedium.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header red">
                          {{keyWords?.dictionary?.medium}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li *ngFor="let mediumAttr of attributesDatamedium">
                            <div class="status-circle orange m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                              <img src="assets/Svg/{{mediumAttr.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{mediumAttr.title | titlecase}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>

                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="attributesDatalow.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header purple">
                          {{keyWords?.dictionary?.low}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li *ngFor="let low of attributesDatalow">
                            <div class="status-circle purple m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                              <img src="assets/Svg/{{low.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{low.title | titlecase}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>
                  </div>
                </div>

                <h4 m-0 class="custom-occ-edit-header">
                  {{keyWords?.dictionary?.personalityTypes}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="pathFinderComp?.length==0">lock</i>
                    <img *ngIf="pathFinderComp?.length>0" src="../../../assets/Svg/creator-pencil-icon-blue.svg"
                      (click)="completeSection('pathfinder', 'stepper3')" alt="pathfinder-edit-image" />
                  </button>
                </h4>
                <div *ngIf="pathfinder.length>0" [ngClass]="(pathfinder.length>0)? 'pathfinder-active':''">
                  <div fxLayout="row" fxFlexFill class="common-row-sub personality-block text-center" fxLayoutGap="5px">
                    <div fxFlex="" *ngFor="let attr of pathfinder">
                      <mat-card m-0 class="card">
                        <div v-center h-center p-5>
                          <div>
                            <img src="assets/pathfinder/holland/{{attr.icon}}.svg" alt="img-quick" class="image-type" />
                          </div>
                          <div>
                            <p m-0 class="title" font-bold>{{attr.displayName}}</p>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div *ngIf="pathfinder.length<=0" class="pathfinder-active">
                  <div fxLayout="row" fxFlexFill class="common-row-sub personality-block" fxLayoutGap="5px">
                    <div *ngIf="pathfinder.length<=0" class="w-100" flex>
                      <div fxFlex="">
                        <mat-card m-0 class="card">
                          <div flex v-center h-center p-5 class="careercreator-pick"
                            (click)="completeSection('pathfinder', 'stepper3')">
                            <img pointer src="./assets/images/pencil-icon-Pathfinder.svg" alt="img-quick"
                              class="image-type-emptycard" />
                            <p pointer m-0>{{globalText?.dictionary?.pickTypes}}</p>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </div>

                <h4 m-0 class="custom-occ-edit-header">
                  {{keyWords?.dictionary?.skills}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="careertrekker.length === 0"
                      (click)="completeSection('careertrekker', 'stepper2')">lock</i>
                    <img *ngIf="careertrekker.length>0" (click)="completeSection('careertrekker', 'stepper2')"
                      src="../../../assets/Svg/creator-pencil-icon-red.svg" alt="trekker-edit-image" />
                  </button>
                </h4>
                <div class="trekker-active">
                  <div fxLayout="row" fxFlexFill class="common-row-sub skill-block" fxLayoutGap="5px"
                    fxLayoutGap.sm="0px" fxLayoutWrap="wrap">
                    <div class=" w-100" flex
                      *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                      <div fxFlex="">
                        <mat-card m-0 class="card">
                          <div flex v-center h-center p-5 class="careercreator-pick"
                            (click)="completeSection('careertrekker', 'stepper2')">
                            <img pointer src="./assets/images/pencil-icon-Career-trek.svg" alt="img-quick"
                              class="image-type-emptycard" />
                            <p pointer m-0>{{globalText?.dictionary?.pickSkills}}</p>
                          </div>
                        </mat-card>
                      </div>
                    </div>

                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="CareertrekDatahigh.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header green">
                          {{keyWords?.dictionary?.high}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li class="occ-w-80" *ngFor="let highAttr of CareertrekDatahigh">
                            <div class="status-circle green m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                              <img src="{{assetsUrl}}{{highAttr.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{highAttr.title}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>

                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="CareertrekDatamedium.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header red">
                          {{keyWords?.dictionary?.medium}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li class="occ-w-80" *ngFor="let mediumAttr of CareertrekDatamedium">
                            <div class="status-circle orange m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                              <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{mediumAttr.title}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>

                    <div fxFlex="" ngStyle.sm="margin-top:5px;" *ngIf="CareertrekDatalow.length>0">
                      <mat-card m-0 class="card h-100">
                        <mat-card-title class="card-header purple">
                          {{keyWords?.dictionary?.low}}</mat-card-title>
                        <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                          <li class="occ-w-80" *ngFor="let low of CareertrekDatalow">
                            <div class="status-circle purple m-auto" flex v-center h-center>
                              <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                              <img src="{{assetsUrl}}{{low.icon}}.svg" />
                            </div>
                            <div class="img-title m-auto text-center">
                              <p>{{low.title}}</p>
                            </div>
                          </li>
                        </ul>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>
              <!--****tabs view *** -->
              <!-- career-group -->
              <div class="tabs-status-tabs mobile-design-show" *ngIf="moduleName!== 'discoverer'">
                <!-- scout -->
                <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.group}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="scoutCompletionSet.length == 0"
                      (click)="completeSection('scout', 'stepper4')">lock</i>
                    <img *ngIf="scoutCompletionSet.length>0" (click)="completeSection('scout', 'stepper4')"
                      src="{{assetsUrl}}pencil-icon-green.svg" alt="career-group-edit" />
                  </button>
                </h4>
                <div>
                  <mat-card *ngIf="scoutData.length>0" class="card w-100 career-group-block">
                    <div flex v-center h-center p-5>
                      <img mr-10 class="career-image" src="{{assetsUrl}}{{scoutData[0].image}}.svg" alt="">
                      <p class="title" style="margin-bottom: 0; color:#4e4e4e">{{scoutData[0].displayName}}</p>
                      <!-- <p mt-15 font-bold>{{scoutData[0].displayName}}</p> -->
                    </div>
                  </mat-card>

                  <div *ngIf="scoutData.length == 0" fxFlex="">
                    <mat-card m-0 class="card">
                      <div flex v-center h-center p-5 class="careercreator-pick"
                        (click)="completeSection('scout', 'stepper4')">
                        <img pointer src="{{assetsUrl}}pencil-icon-green.svg" alt="img-quick"
                          class="image-type-emptycard-work-values" />
                        <p m-0 pointer> {{globalText?.dictionary?.pickGroup}}</p>
                      </div>
                    </mat-card>
                  </div>
                </div>
                <!-- navigator -->
                <div class="navigator-career" *ngIf="moduleName!== 'discoverer'">
                  <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.workValues}}
                    <button class="mat-button custom-occ-edit-btn">
                      <i class="material-icons" *ngIf="navigatorCompletionSet.length === 0"
                        (click)="completeSection('navigator', 'stepper5')">lock</i>
                      <img *ngIf="navigatorCompletionSet.length>0" (click)="completeSection('navigator', 'stepper5')"
                        src="./assets/Svg/pencil_navigator.svg" alt="career-group-edit" />
                    </button>
                  </h4>
                  <div flex class="career-work-group">
                    <div class="navigator-career-card" *ngFor="let item of navigatorData">
                      <div class="career-work-values" *ngIf="navigatorData.length>0">
                        <img src="./assets/Svg/{{item?.icon}}.svg" alt="career-group-icon" />
                        <span class="title">{{item?.displayName}}</span>
                      </div>
                    </div>
                    <div *ngIf="navigatorData.length === 0" fxFlex="" mr-4>
                      <mat-card m-0 class="card">
                        <div flex v-center h-center p-5 class="careercreator-pick"
                          (click)="completeSection('navigator', 'stepper5')">
                          <img pointer src="./assets/Svg/pencil_navigator.svg" alt="img-quick"
                            class="image-type-emptycard-work-values" />&nbsp;
                          <p pointer m-0> {{globalText?.dictionary?.pickValues}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <!-- characteristis -->
                <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.characteristics}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="!(ideaScores==true && thingsScores==true && peopleScores==true)"
                      (click)="completeSection('explorer', 'stepper2')">lock</i>
                    <img *ngIf="(ideaScores==true && thingsScores==true && peopleScores==true)"
                      (click)="completeSection('explorer', 'stepper2')" src="{{assetsUrl}}pencil-icon-Explorer.svg"
                      alt="explorer-edit-image" />
                  </button>
                </h4>
                <div class="tabs-card w-100">
                  <div class=" w-100" flex
                    *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                    <div fxFlex="">
                      <mat-card m-0 class="card">
                        <div flex v-center h-center p-5 class="careercreator-pick"
                          (click)="completeSection('explorer', 'stepper2')">
                          <img pointer src="./assets/images/pencil-icon-Explorer.svg" alt="img-quick"
                            class="image-type-emptycard" />
                          <p pointer m-0> {{globalText?.dictionary?.pickChars}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                  <mat-tab-group class="card explorer-characteristics"
                    *ngIf="attributesDatahigh.length>0 || attributesDatamedium.length>0 || attributesDatalow.length>0">
                    <mat-tab label="Tab 1" *ngIf="attributesDatahigh.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button green" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.high}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                                   ||((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0))
                                   ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'hightab-class':''">
                        <div fxFlex="" flex h-center v-center>
                          <div class="circle-img green" *ngFor="let highAttr of attributesDatahigh">
                            <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                            <img src="assets/Svg/{{highAttr.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{highAttr.title | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 2" *ngIf="attributesDatamedium.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button red" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.medium}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))? '': 
                                 ((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0))? 'lowtab-class':'hightab-class'">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img orange" *ngFor="let mediumAttr of attributesDatamedium">
                            <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                            <img src="assets/Svg/{{mediumAttr.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{mediumAttr.title  | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 3" *ngIf="attributesDatalow.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button purple" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.low}}</span>
                        </div>
                      </ng-template>
                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                                   ||((attributesDatalow.length!=0)&&(attributesDatamedium.length!=0))
                                   ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'lowtab-class':''">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img purple" *ngFor="let low of attributesDatalow">
                            <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                            <img src="assets/Svg/{{low.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{low.title  | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab label="Tab 1"
                      *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button green" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.high}}</span>
                        </div>
                      </ng-template>
                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                      ||((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0))
                      ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'hightab-class':''">
                        <div fxFlex="" flex v-center>
                          <div class="circle-img green"></div>
                          <div class="circle-img green"></div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 2"
                      *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button red" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.medium}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img orange"></div>
                          <div class="circle-img orange"></div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 3"
                      *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button purple" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.low}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                      ||((attributesDatalow.length!=0)&&(attributesDatamedium.length!=0))
                      ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'lowtab-class':''">
                        <div fxFlex="" flex justify-end>
                          <div class="circle-img purple"></div>
                          <div class="circle-img purple"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>

                <h4 m-0 class="custom-occ-edit-header">
                  {{keyWords?.dictionary?.personalityTypes}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="pathFinderComp?.length==0"
                      (click)="completeSection('pathfinder', 'stepper3')">lock</i>
                    <img *ngIf="pathFinderComp?.length>0" src="{{assetsUrl}}pencil-icon-Pathfinder.svg"
                      (click)="completeSection('pathfinder', 'stepper3')" alt="pathfinder-edit-image" />
                  </button>
                </h4>

                <div>
                  <div fxLayout="row" fxFlexFill class="common-row-sub personality-block" fxLayoutGap="5px">
                    <div *ngIf="pathfinder.length>0" class="w-100">
                      <div fxFlex="" *ngFor="let attr of pathfinder" class="pathfinder-card">
                        <mat-card m-0 class="card pathfinder-personality">
                          <div flex v-center h-center p-5>
                            <img src="assets/pathfinder/holland/{{attr.icon}}.svg" alt="img-quick" class="image-type" />
                            <p m-0 style="color:#4e4e4e;">{{attr.displayName}}</p>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pathfinder.length<=0">
                    <div fxLayout="row" fxFlexFill class="common-row-sub personality-block" fxLayoutGap="5px">
                      <div *ngIf="pathfinder.length<=0" class="w-100" flex>
                        <div fxFlex="">
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5 class="careercreator-pick"
                              (click)="completeSection('pathfinder', 'stepper3')">
                              <img pointer src="./assets/images/pencil-icon-Pathfinder.svg" alt="img-quick"
                                class="image-type-emptycard" />
                              <p pointer m-0>{{globalText?.dictionary?.pickTypes}}</p>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h4 m-0 class="custom-occ-edit-header">
                  {{keyWords?.dictionary?.skills}}
                  <button class="mat-button custom-occ-edit-btn">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <i class="material-icons" *ngIf="careertrekker.length === 0"
                      (click)="completeSection('careertrekker',  'stepper2')">lock</i>
                    <img *ngIf="careertrekker.length>0" src="{{assetsUrl}}pencil-icon-Career-trek.svg"
                      (click)="completeSection('careertrekker',  'stepper2')" alt="trekker-edit-image" />
                  </button>
                </h4>
                <div class="tabs-card w-100">
                  <div class=" w-100" flex
                    *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                    <div fxFlex="">
                      <mat-card m-0 class="card">
                        <div flex v-center h-center p-5 class="careercreator-pick"
                          (click)="completeSection('careertrekker', 'stepper2')">
                          <img pointer src="./assets/images/pencil-icon-Career-trek.svg" alt="img-quick"
                            class="image-type-emptycard" />
                          <p pointer m-0>{{globalText?.dictionary?.pickSkills}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                  <mat-tab-group class="card trekker-skills"
                    *ngIf="CareertrekDatahigh.length>0 || CareertrekDatamedium.length>0 || CareertrekDatalow.length>0">
                    <mat-tab label="Tab 1" *ngIf="CareertrekDatahigh.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button green" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.high}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))
                                   ||((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0))
                                   ||((CareertrekDatahigh.length!=0)&&(CareertrekDatalow.length!=0))? 'hightab-class':''">
                        <div fxFlex="" flex h-center v-center>
                          <div class="circle-img green" *ngFor="let highAttr of CareertrekDatahigh">
                            <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                            <img src="{{assetsUrl}}{{highAttr.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{highAttr.title | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 2" *ngIf="CareertrekDatamedium.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button red" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.medium}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))? '':
                                  ((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0))? 'lowtab-class':'hightab-class'">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img orange" *ngFor="let mediumAttr of CareertrekDatamedium">
                            <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                            <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{mediumAttr.title | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 3" *ngIf="CareertrekDatalow.length>0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button purple" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.low}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))
                                  ||((CareertrekDatalow.length!=0)&&(CareertrekDatamedium.length!=0))
                                  ||((CareertrekDatahigh.length!=0)&&(CareertrekDatalow.length!=0))? 'lowtab-class':''">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img purple" *ngFor="let low of CareertrekDatalow">
                            <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                            <img src="{{assetsUrl}}{{low.icon}}.svg" matTooltipClass="custom-tooltip"
                              matTooltip="{{low.title | titlecase}}" />
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab label="Tab 1"
                      *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button green" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.high}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))
                      ||((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0))
                      ||((CareertrekDatahigh.length!=0)&&(CareertrekDatalow.length!=0))? 'hightab-class':''">
                        <div fxFlex="" flex v-center>
                          <div class="circle-img green"></div>
                          <div class="circle-img green"></div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 2"
                      *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button red" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.medium}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0)&&(CareertrekDatalow.length!=0))? '':
                      ((CareertrekDatahigh.length!=0)&&(CareertrekDatamedium.length!=0))? 'lowtab-class':'justify-center'">
                        <div fxFlex="" flex h-center>
                          <div class="circle-img orange"></div>
                          <div class="circle-img orange"></div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Tab 3"
                      *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                      <ng-template mat-tab-label h-center flex>
                        <div class="tab-button purple" flex v-center h-center>
                          <span> {{keyWords?.dictionary?.low}}</span>
                        </div>
                      </ng-template>

                      <div fxLayout="row" fxFlexFill class="common-row-sub" fxLayoutGap="20px" fxLayoutGap.xs="5px"
                        [ngClass]="((attributesDatahigh.length!=0)&&(attributesDatamedium.length!=0)&&(attributesDatalow.length!=0))
                      ||((attributesDatalow.length!=0)&&(attributesDatamedium.length!=0))
                      ||((attributesDatahigh.length!=0)&&(attributesDatalow.length!=0))? 'lowtab-class':''">
                        <div fxFlex="" flex justify-end>
                          <div class="circle-img purple"></div>
                          <div class="circle-img purple"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                  <!-- <div class="image_a" pointer p-5
                    *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0"
                    (click)="completeSection('careertrekker')">
                    <span>Complete Section
                    </span>
                    <i class="material-icons">launch</i>
                  </div> -->
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="hidden-page">
      <div class="create-own-occ-detail content-box print-page" id="contentToConvert1" #customOccPrint>
        <div class="custom-header mt-2" v-center *ngIf="!newOcc">
          <p m-0 v-center float-left>{{item.title}}</p>
        </div>
        <div class="custom-occ-detail">
          <div fxFlex="100%">
            <mat-card class="card h-100">
              <div class="second-block">
                <h4 m-0 class="custom-occ-edit-header">Image
                  <button class="mat-button custom-occ-edit-btn" (click)="completeSection('editImage', 'stepper1')">
                    <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                    <img src="{{assetsPNGUrl}}/pencil-icon-green.png" alt="edit-image" />
                  </button>
                </h4>
                <div flex vcenter class="custom-occ-first w-100">
                  <div class="div-img" text-color="primary" m-0>
                    <img *ngIf="!newOcc" src="{{item.media?.images.sprite}}" alt="{{item.title}}" class="spriteImage">
                    <img *ngIf="newOcc" src="assets/images/nodata-found.png" alt="newOcc" class="spriteImage">
                  </div>
                  <div class="div-para-print" p-5 flex v-center *ngIf="!newOcc">
                    <p m-0>{{item.description}}</p>
                    <!-- <button mat-button color="primary" (click)="modalOpen()" class="readmore-button" flex v-center h-center>
                      <i class="material-icons">remove_red_eye</i>
                      <span font-bold>Read More</span>
                    </button> -->
                  </div>
                </div>
                <div class="scout-career" *ngIf="moduleName!== 'discoverer'">
                  <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.group}}
                    <button class="mat-button custom-occ-edit-btn">
                      <i class="material-icons" *ngIf="scoutCompletionSet.length==0">lock</i>
                      <img *ngIf="scoutCompletionSet.length>0" src="{{assetsPNGUrl}}/pencil-icon-green.png"
                        alt="career-group-edit" />
                    </button>
                  </h4>
                  <div class="career-group-block">
                    <div fxFlexFill class="career-item" *ngIf="scoutData.length>0">
                      <img src="{{assetsPNGUrl}}/{{scoutData[0].image}}.png" alt="">
                      <span class="title-print" font-bold>{{scoutData[0].displayName}}</span>
                    </div>
                    <div *ngIf="scoutData.length ==0" fxFlex="" mr-4>
                      <mat-card m-0 class="card">
                        <div flex v-center h-center p-5 class="careercreator-pick">
                          <img src="{{assetsPNGUrl}}/pencil-icon-green.png" alt="img-quick"
                            class="image-type-emptycard-work-values" />
                          <p m-0> {{globalText?.dictionary?.pickGroup}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div class="navigator-career" *ngIf="moduleName!== 'discoverer'">
                  <h4 m-0 class="custom-occ-edit-header">{{keyWords?.dictionary?.workValues}}
                    <button class="mat-button custom-occ-edit-btn">
                      <i class="material-icons" *ngIf="navigatorCompletionSet.length === 0">lock</i>
                      <img *ngIf="navigatorCompletionSet.length>0" src="../../../assets/Svg/creator-pencil-icon-pink.svg"
                        alt="career-group-edit" />
                    </button>
                  </h4>
                  <div flex class="career-work-group-print">
                    <div class="navigator-career-card text-center" *ngFor="let item of navigatorData">
                      <div *ngIf="navigatorData.length>0">
                        <div>
                          <img src="{{assetsPNGUrl}}/{{item?.icon}}.png" alt="career-group-icon" />
                        </div>
                        
                        <span class="title-values"font-bold>{{item?.displayName}}</span>
                      </div>
                    </div>
                    <div *ngIf="navigatorData.length === 0" fxFlex="" mr-4>
                      <mat-card m-0 class="card">
                        <div flex v-center h-center p-5 class="careercreator-pick">
                          <img pointer src="{{assetsPNGUrl}}/pencil-icon-Navigator.png" alt="img-quick"
                            class="image-type-emptycard-work-values" />&nbsp;
                          <p pointer m-0> {{globalText?.dictionary?.pickValues}}</p>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div class="custom-occ-second" *ngIf="moduleName!== 'discoverer'">
                  <!--Explorer-->
                  <h4 m-0 class="custom-occ-edit-header">
                    {{keyWords?.dictionary?.characteristics}}
                    <button class="mat-button custom-occ-edit-btn">
                      <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                      <i class="material-icons"
                        *ngIf="!(ideaScores==true && thingsScores==true && peopleScores==true)">lock</i>
                      <img *ngIf="(ideaScores==true && thingsScores==true && peopleScores==true)"
                        src="{{assetsPNGUrl}}/pencil-icon-Explorer.png" alt="explorer-edit-image" />
                    </button>
                  </h4>
                  <div class="explorer-active">
                    <div fxLayout="row" fxFlexFill class="common-row-sub status-card" fxLayoutGap="10px"
                      fxLayoutWrap="wrap">
                      <div class=" w-100" flex
                        *ngIf="attributesDatahigh.length<=0 && attributesDatamedium.length<=0 && attributesDatalow.length<=0">
                        <!-- <div fxFlex="">
                            <mat-card m-0 class="empty-card card h-100">
                              <mat-card-title class="card-header green">
                                {{keyWords?.dictionary?.high}}</mat-card-title>
                              <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                                <li>
                                  <div class="status-circle green m-auto" flex v-center h-center></div>
                                  <div class="img-title m-auto text-center"></div>
                                </li>
                                <li>
                                  <div class="status-circle green m-auto" flex v-center h-center></div>
                                </li>
                              </ul>
                            </mat-card>
                          </div> -->
                        <!-- <div fxFlex="">
                            <mat-card m-0 class="empty-card card h-100">
                              <mat-card-title class="card-header red">
                                {{keyWords?.dictionary?.medium}}</mat-card-title>
                              <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                                <li>
                                  <div class="status-circle orange m-auto" flex v-center h-center></div>
                                  <div class="img-title m-auto text-center"></div>
                                </li>
                                <li>
                                  <div class="status-circle orange m-auto" flex v-center h-center></div>
                                  <div class="img-title m-auto text-center"></div>
                                </li>
                              </ul>
                            </mat-card>
                          </div> -->
                        <!-- <div fxFlex="">
                            <mat-card m-0 class="empty-card card h-100">
                              <mat-card-title class="card-header purple">
                                {{keyWords?.dictionary?.low}}</mat-card-title>
                              <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                                <li>
                                  <div class="status-circle purple m-auto" flex v-center h-center></div>
                                  <div class="img-title m-auto text-center"></div>
                                </li>
                                <li>
                                  <div class="status-circle purple m-auto" flex v-center h-center></div>
                                  <div class="img-title m-auto text-center"></div>
                                </li>
                              </ul>
                            </mat-card>
                          </div> -->
                        <div fxFlex="" mr-4>
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5 class="careercreator-pick">
                              <img src="{{assetsPNGUrl}}/pencil-icon-Explorer.png" alt="img-quick"
                                class="image-type-emptycard" />
                              <p m-0>{{globalText?.dictionary?.pickChars}}</p>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                      <div fxFlex="" *ngIf="attributesDatahigh.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header green">
                            {{keyWords?.dictionary?.high}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li *ngFor="let highAttr of attributesDatahigh">
                              <div class="status-circle green m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{highAttr.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{highAttr.title | titlecase}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>

                      <div fxFlex="" *ngIf="attributesDatamedium.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header red">
                            {{keyWords?.dictionary?.medium}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li *ngFor="let mediumAttr of attributesDatamedium">
                              <div class="status-circle orange m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{mediumAttr.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{mediumAttr.title | titlecase}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>

                      <div fxFlex="" *ngIf="attributesDatalow.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header purple">
                            {{keyWords?.dictionary?.low}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li *ngFor="let low of attributesDatalow">
                              <div class="status-circle purple m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{low.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{low.title | titlecase}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                  <!--PathFinder-->
                  <h4 m-0 class="custom-occ-edit-header">
                    {{keyWords?.dictionary?.personalityTypes}}
                    <button class="mat-button custom-occ-edit-btn">
                      <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                      <i class="material-icons" *ngIf="pathFinderComp?.length==0">lock</i>
                      <img *ngIf="pathFinderComp?.length>0" src="{{assetsPNGUrl}}/pencil-icon-Pathfinder.png"
                        alt="pathfinder-edit-image" />
                    </button>
                  </h4>
                  <div *ngIf="pathfinder.length>0" [ngClass]="(pathfinder.length>0)? 'pathfinder-active':''">
                    <div fxLayout="row" fxFlexFill class="common-row-sub personality-block text-center" fxLayoutGap="5px">
                      <div fxFlex="" *ngFor="let attr of pathfinder">
                        <mat-card m-0 class="card">
                          <div  v-center h-center p-5>
                            <div>
                              <img src="{{assetsPNGUrl}}/{{attr.icon}}.png" alt="img-quick" class="image-type" />
                            </div>
                            <div>
                              <p m-0 class="title-values" font-bold>{{attr.displayName}}</p>
                            </div>
                            
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="pathfinder.length<=0" class="pathfinder-active">
                    <div fxLayout="row" fxFlexFill class="common-row-sub personality-block" fxLayoutGap="5px">
                      <div *ngIf="pathfinder.length<=0" class=" w-100" flex>
                        <div fxFlex="" mr-4>
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5 class="careercreator-pick">
                              <img src="{{assetsPNGUrl}}/pencil-icon-Pathfinder.png" alt="img-quick"
                              class="image-type-emptycard"/>
                              <p m-0>{{globalText?.dictionary?.pickTypes}}</p>
                            </div>
                          </mat-card>
                        </div>
                        <!-- <div fxFlex="" mr-4>
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5>
                              <img src="{{assetsPNGUrl}}/human-black.png" alt="img-quick"
                                class="image-type" />
                              <p m-0></p>
                            </div>
                          </mat-card>
                        </div>
                        <div fxFlex="">
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5>
                              <img src="{{assetsPNGUrl}}/human-black.png" alt="img-quick"
                                class="image-type" />
                              <p m-0></p>
                            </div>
                          </mat-card>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <!--Trekker-->
                </div>
              </div>
            </mat-card>
          </div>
        <div>
          <!-- skills -->
        </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div class="custom-occ-detail occ-detail-margin">
          <div fxFlex="100%">
            <mat-card class="card h-100">
              <div class="second-block">
                <div class="custom-occ-second" *ngIf="moduleName!== 'discoverer'">
                  <h4 m-0 class="custom-occ-edit-header">
                    {{keyWords?.dictionary?.skills}}
                    <button class="mat-button custom-occ-edit-btn"
                      (click)="completeSection('careertrekker', 'stepper2')">
                      <!-- <img src="{{assetsUrl}}edit.svg" alt="edit-image" /> -->
                      <i class="material-icons" *ngIf="careertrekker.length === 0">lock</i>
                      <img *ngIf="careertrekker.length>0" src="{{assetsPNGUrl}}/pencil-icon-Career-trek.png"
                        alt="trekker-edit-image" />
                    </button>
                  </h4>
                  <div class="trekker-active">
                    <div fxLayout="row" fxFlexFill class="common-row-sub skill-block" fxLayoutGap="10px"
                      fxLayoutWrap="wrap">
                      <div class=" w-100" flex
                        *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0">
                        <!-- <div fxFlex="">
                          <mat-card m-0 class="empty-card card h-100">
                            <mat-card-title class="card-header green">
                              {{keyWords?.dictionary?.high}}</mat-card-title>
                            <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                              <li class="occ-w-80">
                                <div class="status-circle green m-auto" flex v-center h-center></div>
                                <div class="img-title m-auto text-center"></div>
                              </li>
                              <li class="occ-w-80">
                                <div class="status-circle green m-auto" flex v-center h-center></div>
                              </li>
                            </ul>
                          </mat-card>
                        </div> -->
                        <!-- <div fxFlex="">
                          <mat-card m-0 class="empty-card card h-100">
                            <mat-card-title class="card-header red">
                              {{keyWords?.dictionary?.medium}}</mat-card-title>
                            <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                              <li class="occ-w-80">
                                <div class="status-circle orange m-auto" flex v-center h-center></div>
                                <div class="img-title m-auto text-center"></div>
                              </li>
                              <li class="occ-w-80">
                                <div class="status-circle orange m-auto" flex v-center h-center></div>
                                <div class="img-title m-auto text-center"></div>
                              </li>
                            </ul>
                          </mat-card>
                        </div> -->
                        <!-- <div fxFlex="">
                          <mat-card m-0 class="empty-card card h-100">
                            <mat-card-title class="card-header purple">
                              {{keyWords?.dictionary?.low}}</mat-card-title>
                            <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                              <li class="occ-w-80">
                                <div class="status-circle purple m-auto" flex v-center h-center></div>
                                <div class="img-title m-auto text-center"></div>
                              </li>
                              <li class="occ-w-80">
                                <div class="status-circle purple m-auto" flex v-center h-center></div>
                                <div class="img-title m-auto text-center"></div>
                              </li>
                            </ul>
                          </mat-card>
                        </div> -->
                        <div fxFlex="" mr-4>
                          <mat-card m-0 class="card">
                            <div flex v-center h-center p-5 class="careercreator-pick">
                              <img src="{{assetsPNGUrl}}/pencil-icon-Career-trek.png" alt="img-quick"
                                class="image-type-emptycard" />
                              <p m-0>{{globalText?.dictionary?.pickSkills}}</p>
                            </div>
                          </mat-card>
                        </div>
                      </div>

                      <div fxFlex="" *ngIf="CareertrekDatahigh.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header green">
                            {{keyWords?.dictionary?.high}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li class="occ-w-80" *ngFor="let highAttr of CareertrekDatahigh">
                              <div class="status-circle green m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{highAttr.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{highAttr.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{highAttr.title}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>

                      <div fxFlex="" *ngIf="CareertrekDatamedium.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header red">
                            {{keyWords?.dictionary?.medium}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li class="occ-w-80" *ngFor="let mediumAttr of CareertrekDatamedium">
                              <div class="status-circle orange m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{mediumAttr.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{mediumAttr.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{mediumAttr.title}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>

                      <div fxFlex="" *ngIf="CareertrekDatalow.length>0">
                        <mat-card m-0 class="card h-100">
                          <mat-card-title class="card-header purple">
                            {{keyWords?.dictionary?.low}}</mat-card-title>
                          <ul class="list-style-none icon-sec status-block" fxLayoutWrap="wrap" flex h-center p-5>
                            <li class="occ-w-80" *ngFor="let low of CareertrekDatalow">
                              <div class="status-circle purple m-auto" flex v-center h-center>
                                <!-- <img src="{{assetsUrl}}{{low.icon}}.svg"> -->
                                <img src="{{assetsPNGUrl}}/{{low.icon}}.png" />
                              </div>
                              <div class="img-title m-auto text-center">
                                <p font-bold>{{low.title}}</p>
                              </div>
                            </li>
                          </ul>
                        </mat-card>
                      </div>
                    </div>
                    <!-- <div class="image_a" pointer p-5
                    *ngIf="CareertrekDatahigh.length<=0 && CareertrekDatamedium.length<=0 && CareertrekDatalow.length<=0"
                    (click)="completeSection('careertrekker')">
                    <span>Complete Section
                    </span>
                    <i class="material-icons">launch</i>
                  </div> -->
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Custom occ details Print end -->
  </div>
</div>