import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCallService } from '../../shared/common-services/api-call-service';
import { ApiCallClass } from '../../shared/common-services/api-call-model';
import { DialogopenService } from '../../shared/common-modal/modalpopup.service';
import { AttributeDataState, AudioState, CareerCreatorAttrState, completionListState, PageDataState, PathFinderAttrState } from '../../state-management/state/main-state';
import { Store } from '@ngrx/store';
import { OnLoadPopUpService } from '../../shared/common-services/onloadpopup.service';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../shared/common-services/utilities.service';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';
import { takeWhile, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AudioService } from '../../shared/common-constants/audioLoading.service';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'app-custom-occ',
	templateUrl: './custom-occ-detail-layout.html'
})

export class CustomOccDetailComponent implements OnInit, OnDestroy {

	title: string;
	languageKey = 'en';
	lang = '';
	cardsData = [];
	theme_name;
	item: any = [];
	atttributeData: any = [];
	attributesDatahigh: any = [];
	attributesDatamedium: any = [];
	attributesDatalow: any = [];
	CareertrekDatahigh: any = [];
	CareertrekDatamedium: any = [];
	CareertrekDatalow: any = [];
	pathfinder: any = [];
	keyWords: any;
	tabnames: any;
	globalText: any;
	assetsUrl: string;
	compActive = true;
	navigatorData: any = [];
	theme: number;
	themeColorChange;
	colors: any = [];
	scoutData: any = [];
	@ViewChild('customOccPrint') customOccPrint: ElementRef;
	//newOcc is defined for checking whether the career is new one or existing one. 
	newOcc = false;
	careertrekker: any = [];
	quickpicCompletion: any = [];
	pathFinderComp: any = [];
	ideaScores = false;
	peopleScores = false;
	thingsScores = false;
	UserDetails: any = [];
	launchDictionary: any;
	assetsPNGUrl: string;
	headingAudio = [];
	audioState = 'stoped';
	audionotfound = false;
	moduleName: string;
	images = ['EXPLORER_PEOPLE', 'EXPLORER_IDEAS', 'EXPLORER_THINGS'];
	scoutCompletionSet: any = [];
	navigatorCompletionSet: any = [];
	discoverer: any = [];
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private apiCall: ApiCallService,
		private apiModel: ApiCallClass,
		public dialogService: DialogopenService,
		public eventService: EventdispatchService,
		public attrStore: Store<AttributeDataState>,
		private oninitpopupservice: OnLoadPopUpService,
		private store: Store<AttributeDataState>,
		private store1: Store<PageDataState>,
		private audioStore: Store<AudioState>,
		private audioService: AudioService,
		private CareerCreatorAttrStore: Store<CareerCreatorAttrState>,
		private pathFinderAttrStore: Store<PathFinderAttrState>,
		private moduleCompletionstore: Store<completionListState>, public snackBar: MatSnackBar,
		private utils: Utilities, @Inject('ASSETS_MEDIA_URL') private assetsMediaUrl: string,
		@Inject('ASSETS_MEDIA_PNG_URL') private assetsPNG_URL: string,
		private titleService: Title) {
		this.assetsUrl = assetsMediaUrl;
		this.assetsPNGUrl = assetsPNG_URL;
		this.colors = environment.THEME_COLORS;
		this.store1.dispatch({ type: 'TRIGGER_TEXT_CHANGE_EVENT', payload: { module: 'CREATE_OWN_OCCUPATION', pageCode: 'CUSTOM_OCCUPATION_REPORT' } });
		eventService.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
			if (sessionStorage.getItem('pathurl') == '/app/custom-occ-detail') {
				if (e.type === 'languageChanged') {
					this.attributesDatahigh = [];
					this.attributesDatamedium = [];
					this.attributesDatalow = [];
					this.CareertrekDatahigh = [];
					this.CareertrekDatalow = [];
					this.CareertrekDatamedium = [];
					this.pathfinder = [];
					this.navigatorData = [];
					this.scoutData = [];
					this.lang = sessionStorage.getItem('session_token');
					this.ngOnInit();
				}
				if (e.type === 'themeChanged') {
					this.theme_name = sessionStorage.getItem('theme_color');
				}
				if (e.type === "infoModal") {
					if (sessionStorage.getItem("infopopup") == "true") {
						this.infopopup();
						sessionStorage.setItem("infopopup", "false");
					}
				}
				if (e.type === 'backClicked') {
					/*Since we are not getting the theme color of particular module when we are coming back from career creator Report page so,
					we are setting the theme colour of module from which we entered into the career creater report page*/
					sessionStorage.setItem('theme_color', this.themeColorChange);
					const evnt = document.createEvent('CustomEvent');
					this.eventService.dispatchAudioStop(evnt);
					this.unsavedChanges();
				}
			}
		});
		this.audioStore.select('audio').pipe(takeWhile(() => this.compActive)).subscribe((result) => {
			if (result && sessionStorage.getItem('isModalActive')==='false') {
				this.audioState = result.audioState;
				this.audionotfound = result.audionotfound;
			}
		})
		//Created store for module completion data
		this.moduleCompletionstore.dispatch({
			type: "GET_MODULE_COMPLETION_DATA",
			payload: {
				methodVal: "GET",
				module_Name: "explorer",
				path_params: [],
				query_params: {},
				sessionID: sessionStorage.getItem("session_token"),
				body_Params: {},
				endUrlVal: "/user/profile/provide/portfolio",
			},
		});
	}
	unsavedChanges() {
		this.utils.backRoute();
	}

	infopopup() {
		this.stopAudio();
		Promise.resolve().then(
			function () {
				this.oninitpopupservice.getOnLoadPopUp("CUSTOM_OCCUPATION_REPORT", this.moduleName);
			}.bind(this)
		);
	}
	stopAudio() {
		this.audioState = "stoped";
		this.audionotfound = false;
		this.audioStore.dispatch({
		  type: "SET_AUDIO_STOPED",
		});
		const evnt = document.createEvent("CustomEvent");
		this.eventService.dispatchAudioStop(evnt);
	  }
	ngAfterViewInit() {
		this.infopopup();
	}

	ngOnInit() {
		this.themeColorChange = sessionStorage.getItem('theme_color'); //storing the theme color from where we are entering to the career creator report page.
		this.dialogService.showLoading();
		//Had to subscribe to pages store as it was taking more time than any another api call here. Only then assigning the keyWords reflected according to the language we Changed to i:e eng or spanish.
		this.store1.select('pages').pipe(takeWhile(() => this.compActive)).subscribe((pages) => {
			if (pages) {
				this.keyWords = this.oninitpopupservice.getKeyWordData('GLOBAL_SETTINGS');
				this.globalText = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION_REPORT');
				this.headingAudio = this.oninitpopupservice.getKeyWordData('CUSTOM_OCCUPATION_REPORT');
			}
		})
		this.launchDictionary = this.oninitpopupservice.getKeyWordData('LAUNCH');
		this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
			const itemId = params['itemId'];
			const theme_color = params['theme_color'];
			this.moduleName = params['moduleName'];
			sessionStorage.setItem('theme_color', theme_color);
			const event = document.createEvent('CustomEvent');
			event.initEvent('themeChanged', true, true);
			this.eventService.dispatch(event);
			if (itemId === 'newOcc') {
				this.newOcc = true;
			} else if (this.moduleName === 'discoverer') {
				this.apiModel.moduleName = 'discoverer';
				this.apiModel.endUrl = '/user/dream-occ/' + itemId;
				this.apiModel.sessionID = sessionStorage.getItem('session_token');
				this.apiModel.method = 'GET';
				try {
					this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
						if (resp) {
							resp = JSON.parse(resp);
							this.item = resp;
							this.dialogService.hideLoading();
						}
					});
				} catch (e) {
					this.utils.handleError(`custom-occ-component.ts ngOnInit: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}
			}
			else {
				this.apiModel.moduleName = 'createownocc';
				this.apiModel.endUrl = '/user/custom-occ/' + itemId;
				this.apiModel.sessionID = sessionStorage.getItem('session_token');
				this.apiModel.method = 'GET';
				try {
					this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
						if (resp) {
							resp = JSON.parse(resp);
							this.item = resp;
							if (this.item.attributes !== undefined) {
								this.apiModel.moduleName = 'attributes';
								this.apiModel.endUrl = '/attributes';
								this.apiModel.sessionID = sessionStorage.getItem('session_token');
								this.apiModel.method = 'GET';
								this.apiCall.getData([this.apiModel]).pipe(takeWhile(() => this.compActive)).subscribe(resp => {
									if (resp) {
										resp = JSON.parse(resp);
										this.tabnames = JSON.parse(sessionStorage.getItem('librarytabnames'));
										this.atttributeData = resp;
										for (let i = 0; i < this.item.attributes.length; i++) {
											for (let j = 0; j < this.atttributeData.length; j++) {
												if (this.item.attributes[i].type === 'characteristic') {
													if (this.atttributeData[j].name === this.item.attributes[i].name && this.atttributeData[j].type === 'characteristic') {
														if (this.item.attributes[i].weight === 'high') {
															this.attributesDatahigh.push(this.atttributeData[j]);
														} else if (this.item.attributes[i].weight === 'medium') {
															this.attributesDatamedium.push(this.atttributeData[j]);
														} else if (this.item.attributes[i].weight === 'low') {
															this.attributesDatalow.push(this.atttributeData[j]);
														}
													}
												}
												if (this.item.attributes[i].type === 'skill') {
													if (this.atttributeData[j].name === this.item.attributes[i].name && this.atttributeData[j].type === 'skill') {
														if (this.item.attributes[i].weight === 'high') {
															this.CareertrekDatahigh.push(this.atttributeData[j]);
														} else if (this.item.attributes[i].weight === 'medium') {
															this.CareertrekDatamedium.push(this.atttributeData[j]);
														} else if (this.item.attributes[i].weight === 'low') {
															this.CareertrekDatalow.push(this.atttributeData[j]);
														}
													}
												}
												if (this.item.attributes[i].type === 'hollandcode') {
													if (this.atttributeData[j].hollandCode === this.item.attributes[i].name && this.atttributeData[j].type === 'hollandcode') {
														this.pathfinder.push(this.atttributeData[j]);
													}
												}
												if (this.item.attributes[i].type === 'value') {
													if (this.atttributeData[j].name === this.item.attributes[i].name && this.atttributeData[j].type === 'value') {
														this.navigatorData.push(this.atttributeData[j]);
													}
												}
												if (this.item.attributes[i].type === 'group') {
													if (this.atttributeData[j].name === this.item.attributes[i].name.toLowerCase() && this.atttributeData[j].type === 'group') {
														const data = this.atttributeData[j];
														if (this.item.attributes[i].name === 'Things')
															data['image'] = this.images[2];
														if (this.item.attributes[i].name === 'Ideas')
															data['image'] = this.images[1];
														if (this.item.attributes[i].name === 'People')
															data['image'] = this.images[0];
														this.scoutData.push(data);
													}
												}
											}
										}
									}
									this.dialogService.hideLoading();
								});
							}
						}
					});
				} catch (e) {
					this.utils.handleError(`custom-occ-component.ts ngOnInit exception: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}

			}
		});

		this.userCompletionData();
		this.setCareerCreatorReportTitle(this.moduleName);
	}

	shareCareer() {
		if(this.item.shared){
			this.snackBar.open('This Career is already shared!', '', {
				duration: 1000,
				panelClass: ['failure-snackbar']
			});
	}
		else{
		this.item.shared = true;
		this.apiModel.moduleName = "custom-occ";
		this.apiModel.endUrl = "/user/custom-occ/update/" + this.item.occId;
		this.apiModel.sessionID = sessionStorage.getItem("session_token");
		this.apiModel.method = "PUT";
		this.apiModel.data = {
			title: this.item.title,
			description: this.item.description,
			shared: this.item.shared,
			media: {
				images: {
					title: "string",
					sprite: this.item.media.images.sprite,
				},
			},
			tasks: {
				title: "string",
				items: ["string"],
			},
			attributes: this.item.attributes,
		};
		try {
			this.apiCall
				.getData([this.apiModel])
				.pipe(takeWhile(() => this.compActive))
				.subscribe((resp) => {
					if (resp != undefined && resp != null) {
						this.snackBar.open('Career got shared successfully', '', {
							duration: 1000,
							panelClass: ['success-snackbar']
						});
					}
				});
		} catch (e) {
			this.utils.handleError(`custom-occ-component.ts shareCareer: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
		}
	}
	}

	userCompletionData() {
		this.moduleCompletionstore.select('completionList').pipe(takeWhile(() => this.compActive))
			.subscribe((moduleCompListResp) => {
				if (moduleCompListResp && moduleCompListResp.completionList) {
					try {
						this.UserDetails = moduleCompListResp.completionList;
						if (this.UserDetails) {
							const data = this.UserDetails.modules.assessments.EXPLORER.secondary.data;
							if (data.length >= 1 && data[0] !== undefined) {
								for (let i = 0; i < data.length; i++) {
									if (data[i].name == 'EXPLORER_IDEAS' && this.ideaScores == false && data[i].completions.length > 0) {
										this.ideaScores = true;
									}
									else if (data[i].name == 'EXPLORER_PEOPLE' && this.peopleScores == false && data[i].completions.length > 0) {
										this.peopleScores = true;
									}
									else if (data[i].name == 'EXPLORER_THINGS' && this.thingsScores == false && data[i].completions.length > 0) {
										this.thingsScores = true;
									}
									else if (this.ideaScores == false && this.peopleScores == false && this.thingsScores == false) {
										break;
									}
								}
							}
							if (this.UserDetails.modules.assessments.PATHFINDER && this.UserDetails.modules.assessments.PATHFINDER.completions.length > 0) {
								this.pathFinderComp = this.UserDetails.modules.assessments.PATHFINDER.completions;
							}
							if (this.UserDetails.modules.assessments.TREKKER && this.UserDetails.modules.assessments.TREKKER.completions.length > 0) {
								this.careertrekker = this.UserDetails.modules.assessments.TREKKER.completions;
							}
							if (this.UserDetails.modules.assessments.QUICKPIC && this.UserDetails.modules.assessments.QUICKPIC.completions.length > 0) {
								this.quickpicCompletion = this.UserDetails.modules.assessments.QUICKPIC.completions;
							}
							if (
								this.UserDetails.modules.assessments.DISCOVERER &&
								this.UserDetails.modules.assessments.DISCOVERER.completions
									.length > 0
							) {
								this.discoverer =
									this.UserDetails.modules.assessments.DISCOVERER.completions;
							}
							if (this.UserDetails.modules.assessments.SCOUT && this.UserDetails.modules.assessments.SCOUT.completion != "") {
								this.scoutCompletionSet = [];
								this.scoutCompletionSet.push(this.UserDetails.modules.assessments.SCOUT.completion);
							}
							if (this.UserDetails.modules.assessments.NAVIGATOR && this.UserDetails.modules.assessments.NAVIGATOR.completion != "") {
								this.navigatorCompletionSet = [];
								this.navigatorCompletionSet.push(this.UserDetails.modules.assessments.NAVIGATOR.completion);
							}
							if (this.UserDetails && this.UserDetails.user && this.UserDetails.user.accessibility) {
								sessionStorage.setItem('infoPopupState', JSON.stringify(this.UserDetails.user.accessibility));
							}
						}
					} catch (e) {
						this.utils.handleError(`custom-occ-component.ts userCompletionData: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
					}
				}
			});
	}

	async DownloadPDF() {
		this.dialogService.showLoading();
		const data = this.customOccPrint.nativeElement;
		const opt = {
			filename: this.item.title + '.pdf',
			margin: 0,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2, letterRendering: true, useCORS: true },
			jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
		};
		await html2pdf(data, opt);
		this.dialogService.hideLoading()

	}
	/*below dispacth is for storing the values (explorer,pathfinder,careertrekker modules values) while clicking edit from result page 
	if the values length is zero then dispacthing the empty values otherwise storing respective module values */
	completeSection(moduleName, showMatStep, segName?:string) {
		if (moduleName == 'explorer') {
			this.CareerCreatorAttrStore.dispatch({
				type: 'CAREER_CREATOR_ATTR_VAL', payload: {
					careerCreaterAttrVals: {
						highData: undefined,
						mediumData: undefined, lowData: undefined, moduleName: ''
					}
				}
			});
			this.theme = 0;
			this.navigateToCreateOwnOcc(moduleName, showMatStep);
		} else if (moduleName == 'pathfinder') {
			this.pathFinderAttrStore.dispatch({ type: 'PATHFINDER_ATTR_VAL', payload: { pathFinderAttrVals: { cardsSelected: undefined, moduleName: '' } } });
			this.theme = 1;
			this.navigateToCreateOwnOcc(moduleName, showMatStep);
		} else if (moduleName == 'careertrekker') {
			this.CareerCreatorAttrStore.dispatch({
				type: 'CAREER_CREATOR_ATTR_VAL', payload: {
					careerCreaterAttrVals: {
						highData: undefined,
						mediumData: undefined, lowData: undefined, moduleName: ''
					}
				}
			});
			this.theme = 2;
			this.navigateToCreateOwnOcc(moduleName, showMatStep);
		} else if (moduleName == 'scout') {
			this.theme = 7;
			this.navigateToCreateOwnOcc(moduleName, showMatStep);
		} else if (moduleName == 'navigator') {
			this.theme = 8;
			this.navigateToCreateOwnOcc(moduleName, showMatStep)
		}
		else if (moduleName == 'editImage') {
			this.theme = 10;
			this.router.navigate(['/app/create'], {
				relativeTo: this.activatedRoute,
				queryParams: {
					id: this.item.occId,
					moduleName: segName
				}
			});
		}
		this.themeColorChange = this.colors[this.theme];
		sessionStorage.setItem('theme_color', this.themeColorChange);  //If theme_color is set to this.themeColorChange,
		// The header color of Career Creator will display with respect to the theme color, when we click on edit button at Career creator Report page.

	}

	navigateToCreateOwnOcc(moduleName, matStepper) {
		//The below if condition is to check whether the modules have completed set of assessments, we will allow to navigate to the attribute assigning page
		//Otherwise snackbar with information will be shown
		if ((moduleName == 'explorer' && this.ideaScores == true && this.thingsScores == true && this.peopleScores == true) ||
			(moduleName == 'pathfinder' && this.pathFinderComp.length > 0) ||
			(moduleName == 'careertrekker' && this.careertrekker.length > 0)
			|| (moduleName == 'scout' && this.scoutCompletionSet.length > 0)
			|| (moduleName == 'navigator' && this.navigatorCompletionSet.length > 0)) {
			this.router.navigate(['/app/createOccAttribute'], {
				relativeTo: this.activatedRoute,
				queryParams: {
					module: moduleName,
					id: this.item.occId,
					stepper: matStepper
				}
			});
		}
		else {
			this.snackBar.open(this.launchDictionary.dictionary.completeToUnlock, '', {
				duration: 1000,
				panelClass: ['failure-snackbar']
			});
		}
	}

	deleteCustomOcc(moduleName) {
		let endUrl = '';
		this.dialogService.quickpicDeletePopup({ activeset: '', set: this.keyWords.dictionary.delete + ' ' + this.item.title + '?' });
		if (moduleName === 'discoverer') {
			endUrl = 'dream-occ';

		}
		else {
			endUrl = 'custom-occ';

		}
		this.dialogService.dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
			if (result === true) {
				this.dialogService.showLoading();
				this.apiModel.moduleName = this.moduleName;
				this.apiModel.endUrl = `user/${endUrl}/delete/${this.item.occId}`;
				this.apiModel.sessionID = sessionStorage.getItem('session_token');
				this.apiModel.method = 'DELETE';
				try {
					this.apiCall.getData([this.apiModel]).pipe(take(1)).subscribe((resp) => {

						this.router.navigateByUrl('/app/landing');
					});
				} catch (e) {
					this.utils.handleError(`custom-occ-component.ts deleteCustomOcc: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
				}
			}
		});
	}

	audioClick(text, data) {
		this.audioService.audioFunction(text, data);

	}
	ngOnDestroy() {
		this.compActive = false;
	}

	private setCareerCreatorReportTitle(mName: string) {
		this.titleService.setTitle(this.utils.capitalizeFirstLetter(mName) + " Career Creator Report - CareerTrek")
	}
}