import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorPopUpComponent, OnInitPopUpComponent, navigateToChallengePopupPopUpComponent, QuickPicMobilePopupComponent, ScoutActivityYesModalComponent, ScoutActivityNoModalComponent, NavigatorActivitySuccessModalCompnent } from '../../shared/common-modal/modalpopup.component';
import { CongratulationsExplorerPopUpComponent, ExplorerOccupationsPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import { CongratulationsPathfinderPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import { CongratulationsCareerTrekkerPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import { PathfinderOccupationsPopUpComponent } from '../../shared/common-modal/modalpopup.component';
import {
  SessionExpireComponent,
  QuickpicDeletePopUpComponent,
  QuickpicResultPopUpComponent,
  QuickpicIntroPopUpComponent,
  LandingCreatePopUpComponent,
  CareerTrekkerFilterPopUpComponent,
  OccupationsFilterPopUpComponent,
  DetailOccupationsPopUpComponent
} from '../../shared/common-modal/modalpopup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { EventdispatchService } from '../../shared/common-modal/eventdispach.service';
import { Subscription } from 'rxjs';
@Injectable()
export class DialogopenService {
  datatransfer = [];
  occId: any;
  thumb = '';
  data: any;
  dialogRef: any;
  filterDialogRef: any;
  dialog_data: any;
  subscription = new Subscription();
  theme_name = '';
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventService: EventdispatchService,
    private spinnerService: NgxSpinnerService
  ) {
    this.theme_name = '';
    this.subscription = eventService.listen().subscribe((e) => {
      try {
        if (e.type == 'themeChanged') {
          this.theme_name = sessionStorage.getItem('theme_color');
        }
      } catch (e) {
        console.log('error in theme catch:' + e.message);
      }
    });
    if (
      sessionStorage.getItem('theme_color') != null &&
      sessionStorage.getItem('theme_color') != '' &&
      sessionStorage.getItem('theme_color') != undefined
    ) {
      this.theme_name = sessionStorage.getItem('theme_color');
    } else {
      this.theme_name = '';
    }
  }
  openmodal(component, dialog_data) {
    this.dialogRef = this.dialog.open(component, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }
  commonPopup(dialog_data) {
    this.openmodal(OnInitPopUpComponent, dialog_data);
  }
  navigateToChallengePopup(dialog_data, model_data) {
    this.dialogRef = this.dialog.open(navigateToChallengePopupPopUpComponent, {
      width: '45vw',
      data: { dialog_data: dialog_data, model_data: model_data },
      disableClose: true,
    });
  }
  explorerChallengePopup(dialog_data) {
    this.openmodal(CongratulationsExplorerPopUpComponent, dialog_data);
  }
  pathfinderChallengePopup(dialog_data, pathFinderChallengeText) {
    this.dialogRef = this.dialog.open(CongratulationsPathfinderPopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        pathfinder_data: pathFinderChallengeText,
      },
      disableClose: true,
    });
  }
  explorerOccsPopup(dialog_data, tabname, attributes, like, themeColor) {
    this.dialogRef = this.dialog.open(ExplorerOccupationsPopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        tabname: tabname,
        attributes: attributes,
        like: like,
        page_theme_color: themeColor,
      },
      disableClose: true,
    });
  }
  careerTrekkerChallengePopup(dialog_data) {
    this.openmodal(CongratulationsCareerTrekkerPopUpComponent, dialog_data);
  }
  pathfinderOccsPopUp(dialog_data, like) {
    this.dialogRef = this.dialog.open(PathfinderOccupationsPopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        page_theme_color: this.theme_name,
        like: like,
      },
      disableClose: true,
    });
  }
  scoutOccsPopUp(dialog_data, like) {
    this.dialogRef = this.dialog.open(PathfinderOccupationsPopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        page_theme_color: this.theme_name,
        like: like,
      },
      disableClose: true,
    });
  }

  careerTrekkerFilter(filterdata) {
    this.filterDialogRef = this.dialog.open(CareerTrekkerFilterPopUpComponent, {
      width: '45vw',
      data: { filterdata: filterdata, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }
  occupationsFilter(filterdata) {
    this.filterDialogRef = this.dialog.open(OccupationsFilterPopUpComponent, {
      width: '45vw',
      data: { filterdata: filterdata, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }
  sessionExpire(dialog_data) {
    this.openmodal(SessionExpireComponent, dialog_data);
  }
  errorPopup(dialog_data) {
    this.openmodal(ErrorPopUpComponent, dialog_data);
  }

  showLoading() {
    this.spinnerService.show();
  }
  hideLoading() {
    this.spinnerService.hide();
  }
  quickpicInfoPopup(dialog_data) {
    this.openmodal(QuickpicIntroPopUpComponent, dialog_data);
  }
  quickpicResultPopup(dialog_data) {
    this.dialogRef = this.dialog.open(QuickpicResultPopUpComponent, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }
  quickpicDeletePopup(dialog_data) {
    this.openmodal(QuickpicDeletePopUpComponent, dialog_data);
  }

  quickPicResultModel(dialog_data) {
    this.dialogRef = this.dialog.open(QuickPicMobilePopupComponent, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }

  DetailOccPopup(dialog_data, attributes, text, keyWords, categories?: any) {
    this.dialogRef = this.dialog.open(DetailOccupationsPopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        attributes: attributes,
        modal_text: text,
        page_theme_color: this.theme_name,
        keyWords: keyWords,
        categoriesArray: categories,
      },
      disableClose: true,
    });
  }
  landingCreatePopup(dialog_data, data, themeColor, page?: string) {
    this.dialogRef = this.dialog.open(LandingCreatePopUpComponent, {
      width: '45vw',
      data: {
        dialog_data: dialog_data,
        list: data,
        page_theme_color: themeColor,
        dream_career: page,
      },
      disableClose: true,
    });
  }

  scoutActivityYesModal(dialog_data) {
    this.dialogRef = this.dialog.open(ScoutActivityYesModalComponent, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }

  navigatorActivitySuccessModal(dialog_data) {
    this.dialogRef = this.dialog.open(NavigatorActivitySuccessModalCompnent, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }

  scoutActivityNoModal(dialog_data) {
    this.dialogRef = this.dialog.open(ScoutActivityNoModalComponent, {
      width: '45vw',
      data: { dialog_data: dialog_data, page_theme_color: this.theme_name },
      disableClose: true,
    });
  }
}
