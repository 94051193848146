import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/common-module/shared-common-module';
import { RouterModule, Routes } from '@angular/router';
import { NavigatorModuleComponent } from './navigator-module.component';
import { NavigatorChallengeComponent } from './challenge/navigator-challenge.component';
import { NavigatorActivityComponent } from './nav-activity/navigator-activity.component';
import { NavigatorIntroComponent } from './nav-intro/navigator-intro.component';
import { NavigatorResultsComponent } from './nav-results/navigator-result.component';
import { NavigatorTopTwoGroupsComponent } from './nav-top-two-groups/navigator-toptwo-groups.component';
import { NavigatorTieBreakerComponent } from './tie-breaker/navigator-tie.component';

const routes: Routes= [{
  path: 'app/navigator', component: NavigatorModuleComponent,
  children: [
    { path: '', redirectTo: 'intro', pathMatch: 'prefix' },
    { path: 'intro', component: NavigatorIntroComponent },
    { path: 'activity', component: NavigatorActivityComponent },
    { path: 'topTwoGroups', component: NavigatorTopTwoGroupsComponent },
    { path: 'challenge', component: NavigatorChallengeComponent },
    { path: 'results', component: NavigatorResultsComponent },
    { path: 'tie-breaker', component:NavigatorTieBreakerComponent}
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    SharedModule.forRoot()
  ],

  declarations: [
    NavigatorModuleComponent,
    NavigatorChallengeComponent,
    NavigatorActivityComponent,
    NavigatorIntroComponent,
    NavigatorResultsComponent,
    NavigatorTopTwoGroupsComponent,
    NavigatorTieBreakerComponent
],

  providers: [],
  exports: [RouterModule]
})

export class NavigatorModule {}