import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Next } from "../../../shared/common-animations/CT_animations";
import { environment } from "../../../../environments/environment";
import { EventdispatchService } from "../../../shared/common-modal/eventdispach.service";
import { ActivatedRoute, Router } from "@angular/router";
import { OnLoadPopUpService } from "../../../shared/common-services/onloadpopup.service";
import { Utilities } from "../../../shared/common-services/utilities.service";

import { AudioState, PageDataState } from "../../../state-management/state/main-state";
import { Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { AudioService } from "../../../shared/common-constants/audioLoading.service";

@Component({
  selector: 'app-discoverer-intro',
  templateUrl: './discoverer-intro.component.html',
  animations: [Next],
})
export class DiscovererIntroComponent implements OnInit {
  videoData: any = {};
  durationCompleted = false;
  discovererIntro: any = {};
  compActive = true;
  theme_name: string;
  keywords: any;
  languageNewToken: string;
  discovererDataPages: any = [];
  discovererIntroVideo: string;
  curAudio: string;
  headerAudioState = 'stoped';
  headerAudionotfound = false;

  videoStatus: string = 'play';
  @ViewChild('videoDiscovererId', { static: true })
  videoDiscovererId: ElementRef;
  @ViewChild('videoSourceDiscoverer', { static: true })
  videoSourceDiscoverer: ElementRef;
  completed:boolean = true;
  constructor(
    public eventService: EventdispatchService,
    public store: Store<PageDataState>,
    public router: Router,
    private audioStore: Store<AudioState>,
    private audioService: AudioService,
    public activatedRoute: ActivatedRoute,
    private oninitpopupservice: OnLoadPopUpService,
    private utils: Utilities
  ) {
    let colors = environment.THEME_COLORS;
    sessionStorage.setItem('theme_color', colors[6]);
    const event = document.createEvent('CustomEvent');
    event.initEvent('themeChanged', true, true);
    this.eventService.dispatch(event);
    sessionStorage.setItem('isModalActive', 'false');
    this.store.dispatch({
      type: 'TRIGGER_TEXT_CHANGE_EVENT',
      payload: { module: 'DISCOVERER', pageCode: 'DISCOVERER_INTRO' },
    });
    // this.videoData = {
    //   type: "video/mp4",
    //   videoSrc:
    //     "https://cdn.careeronestop.org/OccVids/OccupationVideos/13-2011.00.mp4",
    //   textSrc: "https://cdn.careeronestop.org/CaptionFiles/13-2011.00.vtt",
    // };
    this.languageNewToken = sessionStorage.getItem('session_token');
    eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (sessionStorage.getItem('pathurl') === '/app/discoverer/intro') {
          if (e.type === 'languageChanged') {
            this.languageNewToken = sessionStorage.getItem('session_token');
            this.discovererDataPages = [];
            this.discovererIntroVideo = '';
            this.oninitpopupservice.getOnLoadPopUp('GLOBAL_SETTINGS');
            // this.oninitpopupservice.pageCallText(this.lang, 'pages');
            this.ngOnInit();
          }
          if (e.type === 'themeChanged') {
            this.theme_name = sessionStorage.getItem('theme_color');
          }
          if (e.type === 'infoModal') {
            if (sessionStorage.getItem('infopopup') == 'true') {
              this.infopopup();
              sessionStorage.setItem('infopopup', 'false');
            }
          }
          if (e.type === 'backClicked') {
            this.unsavedChanges();
          }
        }
      });
    this.audioStore
      .select('audio')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((result) => {
        if (result && sessionStorage.getItem('isModalActive') === 'false') {
          if (this.curAudio === 'header') {
            this.headerAudioState = result.audioState;
            this.headerAudionotfound = result.audionotfound;
          }
        }
      });
    if (!this.languageNewToken) {
      this.languageNewToken = sessionStorage.getItem('session_token');
    }
  }

  ngOnInit() {
    this.store
      .select('pages')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((resp) => {
        if (resp.pages) {
          this.discovererDataPages = resp.pages;
          this.discovererIntro = this.discovererDataPages.find(
            (obj) => obj.pageCode === 'DISCOVERER_INTRO'
          ); //To get the quickpic intro page data.
          this.discovererIntroVideo = this.discovererIntro.media.video[0].src;
          this.videoSourceDiscoverer.nativeElement.setAttribute(
            'src',
            this.discovererIntroVideo
          );
          // this.videoDiscovererId.nativeElement.load();
        }
      });
  }

  ngAfterViewInit() {
    this.infopopup();
  }

  activityRoute() {
    this.router.navigate(['../activity'], { relativeTo: this.activatedRoute });
  }

  vidEnded() {
    this.videoDiscovererId.nativeElement.load();
    this.durationCompleted = true;
  }
  unsavedChanges() {
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
    this.utils.backRoute();
    // this.router.navigateByUrl('/app/landing');
  }

  infopopup() {
    this.stopAudio();
    this.videoStatus = 'play';
    this.videoDiscovererId.nativeElement.pause();
    Promise.resolve().then(
      function () {
        this.oninitpopupservice.getOnLoadPopUp('DISCOVERER_INTRO');
      }.bind(this)
    );
  }

  audioClick(text, data) {
    this.curAudio = 'header';
    this.videoStatus = 'play';
    this.videoDiscovererId.nativeElement.pause();
    this.audioService.audioFunction(text, data);
  }

  // Stop audio event method
  stopAudio() {
    this.headerAudioState = 'stoped';
    this.headerAudionotfound = false;
    this.audioStore.dispatch({
      type: 'SET_AUDIO_STOPED',
    });
    const evnt = document.createEvent('CustomEvent');
    this.eventService.dispatchAudioStop(evnt);
  }

  ngOnDestroy() {
    this.stopAudio();
    this.compActive = false;
  }
  playVideo() {
    var video = document.querySelector('video');
    this.stopAudio();
    video.play();
  }
  videoPaused() {
    this.videoStatus = 'play';
  }
  videoPlayed(event) {
    this.stopAudio();
    this.videoStatus = 'pause';
    setTimeout(() => {
      this.videoStatus = '';
    }, 1000);
  }
}
